
import { Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';


function OpenDateField(props) {
    const { openDate, setOpenDate, disableFields = false } = props;

    return (
        <Stack direction="row" alignItems="center" spacing={2}>

            <DatePicker
                autoOk
                label="Open Date"
                openTo="day"
                inputFormat="MM/dd/yyyy"
                value={openDate ? parseISO(openDate) : null}
                inputVariant="outlined"
                onChange={(date) => {
                    // NOTE: not strict comparison due to issues with date error response object
                    if (date && date != 'Invalid Date') {
                        setOpenDate(format(date, 'yyyy-MM-dd'));
                    } else {
                        setOpenDate(null);
                    }
                }}
                disabled={disableFields}
                renderInput={(params) => <TextField size="small" {...params} />}
                clearable
                required
                sx={{flexGrow: 1}}
            />
            
            <div />

            <div />

        </Stack>
    );
}

OpenDateField.propTypes = {
    openDate: PropTypes.string.isRequired, 
    setOpenDate: PropTypes.func.isRequired,
    disableFields: PropTypes.bool,
}

export default OpenDateField;