import _ from 'lodash';

import { ENTITY_TYPE } from 'helpers/constants';


export const getOrderMemberName = (orderMember) => {
    let path = 'member.name'; // Same path for legal_entity & user

    if (orderMember.member_type === ENTITY_TYPE.legal_entity_member) {
        path = 'member.user.name';
    }

    return _.get(orderMember, path, 'unknown');
}


export function generateOrderMemberRoleMap(orderMemberList, ignoreOrderMemberType = [ENTITY_TYPE.legal_entity], ignoreOrderMemberRole = []) {
    // NOTE: ignoreOrderMemberType is used to filter out order members that are not people (e.g. legal entities)
    // This is probably not the best place to house this business-logic but this function is primarily used in the context of document access
    // Take a list of order members and create a map of role -> role members
    const orderMemberRoleMap = {};

    _.forEach(orderMemberList, (orderMember) => {
        const { role } = orderMember;

        if (_.includes(ignoreOrderMemberRole, role)) {
            return;
        }

        if (!orderMemberRoleMap[role]) {
            orderMemberRoleMap[role] = [];
        }

        if (!_.includes(ignoreOrderMemberType, orderMember.member_type)) {
            orderMemberRoleMap[role].push(orderMember);
        }
    });

    return orderMemberRoleMap;
}

export function generateOrderMemberIdMap(orderMemberList) {
    // Take a list of order members and create a map of id -> member
    const orderMemberIdMap = {};

    _.forEach(orderMemberList, (orderMember) => {
        const { id } = orderMember;

        orderMemberIdMap[id] = orderMember;
    });

    return orderMemberIdMap;
}