import SearchIcon from '@mui/icons-material/Search';
import {
    Checkbox,
    Chip,
    FormControlLabel,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { useCreateLegalEntityMemberMutation } from 'api/legalEntity';
import { useGetUsersBySearchQuery } from 'api/user';
import { Button, FilterTextField, LoadingButton } from 'components/common/styled';
import {
    COMMERCIAL_LEGAL_ENTITIES,
    COMMERCIAL_TYPE_MEMBER_ROLE_MAP,
    LEGAL_ENTITY_TYPE_MEMBER_ROLE_MAP,
} from 'helpers/constants';

const SearchResultListItemSecondaryActions = ({
    userId,
    memberDetailsMap,
    handleMemberDetailsChange,
    selected,
    memberRoleOptions,
}) => {
    const updateRole = (role) => {
        handleMemberDetailsChange(userId, {
            role,
        });
    };

    const handleRoleChange = ({ target }) => {
        const role = target.value;
        updateRole(role);
    };

    const handleCheckboxChange = ({ target }) => {
        const signor = target.checked;
        handleMemberDetailsChange(userId, {
            signor,
        });
    };

    const userMemberDetails = _.get(memberDetailsMap, userId, {});

    if (!selected || !userMemberDetails) {
        return null;
    }

    return (
        <Stack direction="row" spacing={4} alignItems="center">
            <FormControlLabel
                label="Signor"
                control={<Checkbox name="signor" checked={userMemberDetails.signor} onChange={handleCheckboxChange} />}
                sx={{ marginRight: '0' }}
            />

            <TextField
                name="role"
                label="Role"
                value={userMemberDetails.role}
                onChange={handleRoleChange}
                sx={{ minWidth: '150px' }}
                size="small"
                select
                required
            >
                {_.map(memberRoleOptions, (memberType) => (
                    <MenuItem key={memberType} value={memberType}>
                        {_.startCase(memberType)}
                    </MenuItem>
                ))}
            </TextField>
        </Stack>
    );
};

const UserSearchResultListItem = ({
    searchResult,
    handleSelect,
    selected,
    memberDetailsMap,
    handleMemberDetailsChange,
    memberRoleOptions,
}) => {
    const handleToggle = (event) => {
        event.preventDefault();
        handleSelect(searchResult, !selected);
    };

    return (
        <ListItem
            disablePadding
            className={selected ? 'selected' : ''}
            secondaryAction={
                <SearchResultListItemSecondaryActions
                    selected={selected}
                    userId={searchResult.id}
                    memberDetailsMap={memberDetailsMap}
                    handleMemberDetailsChange={handleMemberDetailsChange}
                    memberRoleOptions={memberRoleOptions}
                />
            }
            sx={{
                '&.selected > .MuiListItemButton-root': {
                    paddingRight: (theme) => theme.spacing(36),
                },
            }}
        >
            <ListItemButton onClick={handleToggle}>
                <ListItemIcon>
                    <Checkbox edge="start" checked={selected} disableRipple />
                </ListItemIcon>
                <ListItemText
                    primary={searchResult.name}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={_.get(searchResult, 'contact.email', null)}
                    secondaryTypographyProps={{ noWrap: true }}
                />
            </ListItemButton>
        </ListItem>
    );
};

const SearchResults = ({
    userSearchResults,
    validSearchTerm,
    noSearchResults,
    selectedUserMap,
    handleSelect,
    memberDetailsMap,
    handleMemberDetailsChange,
    memberRoleOptions,
}) => {
    if (!validSearchTerm || noSearchResults) {
        return null;
    }

    const userSearchResultList = _.map(userSearchResults, (searchResult) => {
        return (
            <UserSearchResultListItem
                key={searchResult.id}
                searchResult={searchResult}
                selected={!!_.get(selectedUserMap, searchResult.id)}
                handleSelect={handleSelect}
                memberDetailsMap={memberDetailsMap}
                handleMemberDetailsChange={handleMemberDetailsChange}
                memberRoleOptions={memberRoleOptions}
            />
        );
    });

    return (
        <Stack spacing={1}>
            <Typography variant="overline">Search Results</Typography>
            <List sx={{ width: '100%', maxHeight: '300px', overflow: 'auto' }}>{userSearchResultList}</List>
        </Stack>
    );
};

const CurrentSelections = ({
    validSearchTerm,
    noSearchResults,
    selectedUserMap,
    handleSelect,
    memberDetailsMap,
    handleMemberDetailsChange,
    memberRoleOptions,
}) => {
    if (_.keys(selectedUserMap).length === 0 || (validSearchTerm && !noSearchResults)) {
        return null;
    }

    const currentSelectionList = _.map(selectedUserMap, (member) => {
        return (
            <UserSearchResultListItem
                key={member.id}
                searchResult={member}
                selected={!!_.get(selectedUserMap, member.id)}
                handleSelect={handleSelect}
                memberDetailsMap={memberDetailsMap}
                handleMemberDetailsChange={handleMemberDetailsChange}
                memberRoleOptions={memberRoleOptions}
            />
        );
    });

    return (
        <Stack spacing={1}>
            <Typography variant="overline">Current Selections</Typography>
            <List sx={{ width: '100%', maxHeight: '300px', overflow: 'auto' }}>{currentSelectionList}</List>
        </Stack>
    );
};

const AddMember = ({ legalEntity, handleCancel, handleComplete }) => {
    const [selectedUserMap, setSelectedUserMap] = useState({});
    const [memberDetailsMap, setMemberDetailsMap] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        data: userSearchResults,
        isError: userSearchError,
        isLoading: userSearchLoading,
    } = useGetUsersBySearchQuery(searchTerm);
    const [
        createLegalEntiyMember,
        { isLoading: isCreateLegalEntityMemberLoading },
    ] = useCreateLegalEntityMemberMutation();

    const memberRoleOptions = useMemo(() => {
        if (_.includes(COMMERCIAL_LEGAL_ENTITIES, legalEntity.type)) {
            return COMMERCIAL_TYPE_MEMBER_ROLE_MAP[legalEntity.commercial_type];
        }

        return LEGAL_ENTITY_TYPE_MEMBER_ROLE_MAP[legalEntity.type];
    }, [legalEntity]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelect = (selectedResult, selected) => {
        if (selected) {
            setSelectedUserMap({
                ...selectedUserMap,
                [selectedResult.id]: {
                    ...selectedResult,
                },
            });
            // set baseline member details if doesn't already exist
            handleMemberDetailsChange(selectedResult.id, { role: '', signor: false });
        } else {
            const newMap = { ...selectedUserMap };
            delete newMap[selectedResult.id];
            setSelectedUserMap(newMap);
        }
    };

    const handleMemberDetailsChange = (userId, newMemberDetails = {}) => {
        setMemberDetailsMap({
            ...memberDetailsMap,
            [userId]: {
                ..._.get(memberDetailsMap, userId, {}),
                ...newMemberDetails,
            },
        });
    };

    const handleCreateMembers = (event) => {
        event.preventDefault();

        setIsSubmitting(true);

        const resultMap = {
            success: [],
            error: [],
        };

        const promiseList = _.map(selectedUserMap, (selectedUser, selectedUserId) => {
            return submitLegalEntityMember(selectedUserId, selectedUser).then(
                (response) => {
                    if (response) {
                        resultMap.success.push(selectedUserId);
                    } else {
                        resultMap.error.push(selectedUserId);
                    }
                    return response;
                },
                (error) => {
                    console.log(error);
                    resultMap.error.push(selectedUserId);
                }
            );
        });

        Promise.all(promiseList).then((values) => {
            setIsSubmitting(false);

            handleComplete();
        });
    };

    const submitLegalEntityMember = async (selectedUserId, selectedUser) => {
        const legalEntityMemberDetails = _.get(memberDetailsMap, selectedUserId);

        if (!legalEntityMemberDetails) {
            console.warn(`${selectedUserId} not found in memberDetailsMap`, memberDetailsMap);
            return false;
        }

        const memberData = {
            legal_entity_id: legalEntity.id,
            user_id: selectedUserId,
            ...legalEntityMemberDetails,
        };

        // Verify that member data has been filled in else exit
        if (!memberData.role) {
            console.warn(`${selectedUserId} doesn't have an assigned role`);
            return false;
        }

        const { data: createLegalEntityMemberData } = await createLegalEntiyMember({
            legalEntityId: legalEntity.id,
            memberData,
        });

        if (createLegalEntityMemberData) {
            return true;
        } else {
            console.warn(`${selectedUserId} failed to be added as a member to ${legalEntity.id}`);
            return false;
        }
    };

    const validSearchTerm = _.get(searchTerm, 'length') > 2;
    const noSearchResults = _.get(userSearchResults, 'length', 0) === 0;

    return (
        <Stack spacing={4} sx={{ width: '100%' }}>
            <Stack spacing={1}>
                <Typography variant="h5">Add Members</Typography>
                <Typography variant="body1" color="text.secondary">
                    {legalEntity.name}
                </Typography>
            </Stack>

            <Stack spacing={2}>
                <FilterTextField
                    name="search"
                    type="search"
                    placeholder="Search existing users ..."
                    variant="outlined"
                    size="small"
                    onChange={handleSearch}
                    value={searchTerm}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    autoFocus
                    helperText={
                        validSearchTerm &&
                        noSearchResults && (
                            <Typography variant="caption">No results found for "{searchTerm}"</Typography>
                        )
                    }
                />

                <SearchResults
                    userSearchResults={userSearchResults}
                    validSearchTerm={validSearchTerm}
                    noSearchResults={noSearchResults}
                    selectedUserMap={selectedUserMap}
                    handleSelect={handleSelect}
                    memberDetailsMap={memberDetailsMap}
                    handleMemberDetailsChange={handleMemberDetailsChange}
                    memberRoleOptions={memberRoleOptions}
                />

                <CurrentSelections
                    validSearchTerm={validSearchTerm}
                    noSearchResults={noSearchResults}
                    selectedUserMap={selectedUserMap}
                    handleSelect={handleSelect}
                    memberDetailsMap={memberDetailsMap}
                    handleMemberDetailsChange={handleMemberDetailsChange}
                    memberRoleOptions={memberRoleOptions}
                />

                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Typography variant="overline" sx={{ fontWeight: '500', minWidth: '80px' }}>
                        {_.keys(selectedUserMap).length} selected
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
                        {_.map(selectedUserMap, (member) => {
                            return (
                                <Chip
                                    key={member.id}
                                    label={member.name}
                                    onDelete={(event) => handleSelect(member, false)}
                                    size="small"
                                />
                            );
                        })}
                    </Stack>
                </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                <Button variant="text" color="default" onClick={() => handleCancel()} disabled={isSubmitting}>
                    Cancel
                </Button>

                <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={handleCreateMembers}
                    loading={isSubmitting}
                    disableElevation
                    disabled={_.keys(selectedUserMap).length === 0}
                    sx={{
                        minWidth: '160px',
                    }}
                >
                    Add Selected
                </LoadingButton>
            </Stack>
        </Stack>
    );
};

AddMember.propTypes = {
    legalEntity: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleComplete: PropTypes.func.isRequired,
};

export default AddMember;
