import { Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { PARCEL_TYPE } from '../../../../helpers/constants';
import BalancingParcelListItem from './BalancingParcelListItem';

// TODO standardize in const
const balancingChildParcelOrder = [
    PARCEL_TYPE.balancing_contract,
    PARCEL_TYPE.balancing_earnest_money,
    PARCEL_TYPE.balancing_taxes,
    PARCEL_TYPE.balancing_hoa,
    PARCEL_TYPE.balancing_home_warranty,
    PARCEL_TYPE.balancing_commissions,
    PARCEL_TYPE.balancing_deed,
    PARCEL_TYPE.balancing_liens,
    PARCEL_TYPE.balancing_title_fees,
    PARCEL_TYPE.balancing_lender_fees,
    PARCEL_TYPE.balancing_other,
    PARCEL_TYPE.balancing_final_cd_hud,
];

function BalancingParcelList({ order, balancingParcel, handleParcelUpdate, loading }) {
    const [childParcelTypeMap, setChildParcelTypeMap] = useState({});

    useEffect(() => {
        const parentParcelId = balancingParcel.id;
        const newChildParcelTypeMap = {};

        _.forEach(order.parcels, (parcelValue, parcelId) => {
            if (parcelValue.parent_parcel_id === parentParcelId) {
                newChildParcelTypeMap[parcelValue.type] = parcelValue;
            }
        });

        setChildParcelTypeMap(newChildParcelTypeMap);
    }, [order, balancingParcel]);

    return (
        <Stack spacing={6}>
            {_.map(balancingChildParcelOrder, (childParcelType) => {
                const childParcel = childParcelTypeMap[childParcelType];

                if (childParcel) {
                    return (
                        <BalancingParcelListItem
                            key={childParcel.id}
                            order={order}
                            parentParcel={balancingParcel}
                            parcel={childParcel}
                            handleParcelUpdate={handleParcelUpdate}
                            loading={loading}
                        />
                    );
                }
            })}
        </Stack>
    );
}

BalancingParcelList.propTypes = {
    order: PropTypes.object.isRequired,
    balancingParcel: PropTypes.object.isRequired,
    handleParcelUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default BalancingParcelList;
