import { Chip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { PARCEL_STATUS } from 'helpers/constants';

// TODO decouple this from core PARCEL_STATUS and dynamically determine status colors
const STATUS_COLOR_MAP = {
    [PARCEL_STATUS.not_started]: 'default',
    [PARCEL_STATUS.in_progress]: 'info',
    [PARCEL_STATUS.waiting_for_contract]: 'default',
    [PARCEL_STATUS.waiting_for_earnest_money]: 'default',
    [PARCEL_STATUS.needs_receipt]: 'default',
    [PARCEL_STATUS.complete]: 'primary',
    [PARCEL_STATUS.canceled]: 'error',
};

function AssignmentStatusChip({ status, sx = {}, ...rest }) {
    const statusPalette = _.get(STATUS_COLOR_MAP, status, 'default');

    if (statusPalette === 'default') {
        return (
            <Chip
                label={_.startCase(status)}
                color="default"
                sx={{
                    borderRadius: (theme) => theme.spacing(0.5),
                    ...sx,
                }}
                {...rest}
            />
        );
    }

    return (
        <Chip
            label={_.startCase(status)}
            sx={{
                backgroundColor: (theme) => theme.palette[statusPalette].light,
                color: (theme) => theme.palette[statusPalette].dark,
                borderRadius: (theme) => theme.spacing(0.5),
                ...sx,
            }}
            {...rest}
        />
    );
}

AssignmentStatusChip.propTypes = {
    status: PropTypes.oneOf([..._.values(PARCEL_STATUS)]).isRequired,
};

export default AssignmentStatusChip;
