/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import BalanceIcon from '@mui/icons-material/Balance';
import BoltIcon from '@mui/icons-material/Bolt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ForumIcon from '@mui/icons-material/Forum';
import HomeIcon from '@mui/icons-material/Home';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ListIcon from '@mui/icons-material/List';
import PeopleIcon from '@mui/icons-material/People';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
    Badge,
    Box,
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { NavLink as RouterLink, matchPath, useLocation, useMatch, useParams } from 'react-router-dom';

import { useGetOrderQuery } from 'api/order';
import { getActiveAssignedActions } from 'components/Order/Requests/useGetOrderAssignedActions';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <NavigationItem {...props} />
    </div>
));

const NavigationItem = (props) => {
    let location = useLocation();
    return (
        <RouterLink
            {...props}
            className={clsx(
                props.className,
                `${`${location.pathname}${location.search}` === props.to ? 'exact-query-active' : ''}`
            )}
        />
    );
};

const SecondaryNavBadge = styled(Badge)(({ theme }) => ({}));

const SidebarNavSecondaryItem = ({ page, primaryColor }) => {
    return (
        <ListItem disableGutters disablePadding>
            <ListItemButton
                component={CustomRouterLink}
                to={page.href}
                end={page.exact}
                disabled={page.disabled === true}
                sx={{
                    color: (theme) => theme.palette.white,

                    '& .MuiSvgIcon-root': {
                        visibility: 'hidden',
                        color: (theme) => (primaryColor ? primaryColor : theme.palette.white),
                    },

                    '& .MuiListItemText-primary': {
                        opacity: 0.5,
                    },

                    '&.exact-query-active': {
                        '& .MuiListItemText-primary': {
                            opacity: 1,
                        },

                        '& .MuiSvgIcon-root': {
                            visibility: 'visible',
                        },
                    },

                    '&:hover': {
                        '& .MuiListItemText-primary': {
                            opacity: 1,
                        },
                    },
                }}
                disableRipple
            >
                <ListItemIcon
                    sx={{
                        maxWidth: (theme) => theme.spacing(3),
                        minWidth: (theme) => theme.spacing(3),
                        width: (theme) => theme.spacing(3),
                        marginRight: (theme) => theme.spacing(3),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <FiberManualRecordIcon sx={{ width: '12px', height: '12px' }} />
                </ListItemIcon>

                <ListItemText
                    primary={page.title}
                    primaryTypographyProps={{
                        color: 'inherit',
                    }}
                />

                <SecondaryNavBadge badgeContent={_.get(page, 'badgeCount')} color="default">
                    <span />
                </SecondaryNavBadge>
            </ListItemButton>
        </ListItem>
    );
};

const SidebarNavSectionCollapse = ({ primary, secondary, open }) => {
    return (
        <Collapse in={open} timeout="auto">
            <List component="div" disablePadding>
                {secondary.map((secondaryPage) => (
                    <SidebarNavSecondaryItem
                        page={secondaryPage}
                        primaryColor={primary.color}
                        key={secondaryPage.title}
                    />
                ))}
            </List>
        </Collapse>
    );
};

function useLocationMatch(primary, secondary = []) {
    // Auto check for match primary
    // Check for match secondary if specified (true/false)
    // Check for match alt if specified (string)

    let { pathname } = useLocation();
    let matchPrimary = useMatch(primary.href);

    let matchSecondary = useMemo(() => {
        return primary.matchSecondary
            ? _.find(secondary, (secondaryPage) => {
                  return matchPath(secondaryPage.href, pathname);
              })
            : null;
    }, [primary, secondary, pathname]);

    let matchAlt = useMemo(() => {
        return primary.matchAlt ? matchPath(primary.matchAlt, pathname) : null;
    }, [primary, pathname]);

    return {
        match: !!matchPrimary || !!matchSecondary || !!matchAlt,
        matchPrimary: !!matchPrimary,
        matchSecondary: !!matchSecondary,
        matchAlt: !!matchAlt,
    };
}

const SidebarNavPrimaryItem = ({ primary, secondary, open, setOpen }) => {
    const { match } = useLocationMatch(primary, secondary);

    useEffect(() => {
        setOpen(!!match);
    }, [match]);

    return (
        <ListItem
            className={clsx(`${match ? 'primary-active' : ''}`)}
            secondaryAction={
                secondary.length > 0 && (
                    <IconButton
                        onClick={() => setOpen(!open)}
                        aria-label="open"
                        sx={{
                            color: (theme) => theme.palette.white,
                        }}
                    >
                        <ExpandMore
                            sx={{
                                rotate: open ? '180deg' : '0deg',
                                transition: (theme) => theme.transitions.create('rotate'),
                            }}
                        />
                    </IconButton>
                )
            }
            sx={{
                color: (theme) => theme.palette.lightWhite,
                borderRadius: (theme) => theme.spacing(3),
                '& .MuiListItemText-primary': {
                    opacity: 0.5,
                },
                '&.primary-active': {
                    background: `linear-gradient(0deg, rgba(227, 227, 227, .12), rgba(227, 227, 227, .12)), #323232`,
                    // background: (theme) =>
                    //     primary.color
                    //         ? primary.color
                    //         : `linear-gradient(0deg, rgba(227, 227, 227, .12), rgba(227, 227, 227, .12)), #323232`,
                    // background: primary.color,

                    '& .MuiListItemText-primary': {
                        opacity: 1,
                    },
                },
                '&:hover': {
                    '& .MuiListItemText-primary': {
                        opacity: 1,
                    },
                },
            }}
            disableGutters
            disablePadding
        >
            <ListItemButton
                component={CustomRouterLink}
                to={primary.href}
                end={primary.exact}
                disabled={primary.disabled === true}
                sx={{}}
                disableRipple
            >
                <ListItemIcon
                    sx={{
                        maxWidth: (theme) => theme.spacing(3),
                        minWidth: (theme) => theme.spacing(3),
                        width: (theme) => theme.spacing(3),
                        marginRight: (theme) => theme.spacing(3),
                        textAlign: 'center',

                        '& .MuiSvgIcon-root': {
                            color: (theme) => (primary.color ? primary.color : theme.palette.white),
                        },
                    }}
                >
                    {primary.icon}
                </ListItemIcon>
                <ListItemText
                    primary={primary.title}
                    primaryTypographyProps={{
                        color: 'inherit',
                    }}
                />
            </ListItemButton>
        </ListItem>
    );
};

const SidebarNavSection = ({ section }) => {
    const { primary, secondary = [] } = section;

    const [open, setOpen] = useState(primary.defaultOpen === true);

    const primaryComp = (
        <SidebarNavPrimaryItem
            key={primary.title}
            primary={primary}
            secondary={secondary}
            open={open}
            setOpen={setOpen}
        />
    );

    const dividerComp = <Box key={`${primary.title}-divider`} sx={{ height: (theme) => theme.spacing(2) }} />;

    if (secondary.length === 0) {
        return [primaryComp, dividerComp];
    }

    return [
        primaryComp,
        <SidebarNavSectionCollapse
            key={`${primary.title}-collapse`}
            primary={primary}
            secondary={secondary}
            open={open}
        />,
        dividerComp,
    ];
};

const generateOrderSection = (orderId, order, theme) => {
    const activeRequestCount = getActiveAssignedActions(order).length;

    return {
        primary: {
            title: order.qualia_order_number,
            href: `/order/${orderId}`,
            icon: <HomeIcon />,
            matchAlt: `/order/${orderId}/*`,
            color: theme.palette[order.transaction_type].light,
        },
        secondary: [
            {
                title: 'Balancing',
                href: `/order/${orderId}/balancing`,
                icon: <BalanceIcon />,
                exactQuery: true,
            },
            {
                title: 'Title',
                href: `/order/${orderId}/title`,
                icon: <ReceiptIcon />,
                exactQuery: true,
            },
            {
                title: 'Members',
                href: `/order/${orderId}/members`,
                icon: <PeopleIcon />,
                exactQuery: true,
            },
            {
                title: 'Requests',
                href: `/order/${orderId}/requests`,
                icon: <PeopleIcon />,
                exactQuery: true,
                badgeCount: activeRequestCount,
            },
            {
                title: 'Documents',
                href: `/order/${orderId}/documents`,
                icon: <DescriptionIcon />,
                exactQuery: true,
            },
            {
                title: 'Notes',
                href: `/order/${orderId}/notes`,
                icon: <ForumIcon />,
                exactQuery: true,
            },
            {
                title: 'Logs',
                href: `/order/${orderId}/logs`,
                icon: <ListIcon />,
                exactQuery: true,
            },
        ],
    };
};

const OrderSectionGeneratorComponent = ({ section }) => {
    // Determine if there is an order id in the url
    const { order_id: orderId } = useParams();
    const theme = useTheme();

    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const orderSection = useMemo(() => {
        if (!orderId || !order) {
            return null;
        }

        return generateOrderSection(orderId, order, theme);
    }, [orderId, order, theme]);

    if (!orderId || !order || !orderSection) {
        return null;
    }

    return <SidebarNavSection key={orderSection.primary.title} section={orderSection} />;
};

const sections = [
    {
        primary: {
            title: 'Dashboard',
            href: '/',
            icon: <DashboardIcon />,
            color: '#D24376',
            matchSecondary: true,
            defaultOpen: true,
        },
        secondary: [
            {
                title: 'Closings',
                href: '/closing',
            },
            {
                title: 'Fundings',
                href: '/funding',
            },
            {
                title: 'Title',
                href: '/title',
            },
            {
                title: 'Payoffs',
                href: '/payoffs',
            },
            {
                title: 'Scheduling',
                href: '/scheduling',
            },
        ],
    },
    {
        primary: {
            title: 'Contacts',
            href: '/contacts',
            icon: <PersonSearchIcon />,
            color: '#1DB7A4',
            matchAlt: '/contact/*',
        },
        secondary: [
            {
                title: 'Users',
                href: '/contacts?contactType=user',
            },
            {
                title: 'Organizations',
                href: '/contacts?contactType=legal_entity',
            },
        ],
    },
    {
        primary: {
            title: 'Orders',
            href: '/orders',
            icon: <HomeWorkIcon />,
            color: '#EFC81D',
        },
        secondary: [
            {
                title: 'Purchases',
                href: '/orders?transactionType=purchase&orderStatus=Open',
            },
            {
                title: 'Refinances',
                href: '/orders?transactionType=refinance&orderStatus=Open',
            },
        ],
    },
    {
        primary: {
            title: 'Actions',
            href: '/actions',
            icon: <BoltIcon />,
            color: '#FE990D',
        },
        secondary: [
            {
                title: 'Checks',
                href: '/actions?parcelType=funding&actionType=send_checks',
                exactQuery: true,
            },
            {
                title: 'Wires (Setup)',
                href: '/actions?parcelType=funding&actionType=setup_wires',
                exactQuery: true,
            },
            {
                title: 'Wires (Approve)',
                href: '/actions?parcelType=funding&actionType=approve_wires',
                exactQuery: true,
            },
            {
                title: 'eRecord',
                href: '/actions?parcelType=post_closing&actionType=record_documents',
                exactQuery: true,
            },
            {
                title: 'Refund Recording',
                href: '/actions?parcelType=post_closing&actionType=refund_recording',
                exactQuery: true,
            },
            {
                title: 'Lender Packages',
                href: '/actions?parcelType=post_closing&actionType=lender_package',
                exactQuery: true,
            },
        ],
    },
    {
        generatorKey: 'specific-order-section',
        GeneratorComponent: OrderSectionGeneratorComponent,
    },
];

const SidebarNav = (props) => {
    const { className, ...rest } = props;

    return (
        <List
            className={clsx(className)}
            sx={{
                paddingLeft: (theme) => theme.spacing(1),
                paddingRight: (theme) => theme.spacing(1),
            }}
            {...rest}
        >
            {sections.map((section) => {
                const { GeneratorComponent = null } = section;

                if (GeneratorComponent) {
                    return <GeneratorComponent key={section.generatorKey} section={section} />;
                }

                return <SidebarNavSection key={section.primary.title} section={section} />;
            })}
        </List>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
};

export default SidebarNav;
