import { Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useUpdateActionMutation } from 'api/action';
import { useGetOrderQuery } from 'api/order';
import { LoadingButton } from 'components/common/styled';
import { ACTION_STATUS } from 'helpers/constants';

import {
    determineActionHasAdditionalData,
    determineActionIcon,
    determineActionIsTerminal,
} from 'components/Action/utils';
import ActionModal from './ActionModal';

// TODO ActionIconButton
// TODO ActionLoadingButton

export const ACTION_BUTTON_TYPES = {
    icon: 'icon',
    contained: 'contained',
};

function ActionButton({ action, orderId, loadingButtonProps = {}, type = ACTION_BUTTON_TYPES.icon }) {
    // NOTE: currently includes the action modal
    // This means in pages that list multiple actions, the modal will be mounted multiple times
    // This is not ideal, but it's not a huge deal for now
    const [isOpen, setIsOpen] = useState(false);

    const [updateAction, { isLoading }] = useUpdateActionMutation();
    const { isFetching: isOrderFetching } = useGetOrderQuery(orderId);

    // Determine if action is complete/canceled
    const isTerminal = determineActionIsTerminal(action.status);

    // Determine if action is a simple 'mark as done' action or if it requires additional data
    const hasAdditionalDataRequirement = determineActionHasAdditionalData(action);

    const handleClick = (event) => {
        event.preventDefault();

        if (hasAdditionalDataRequirement) {
            setIsOpen(true);
        } else {
            handleUpdateAction({
                status: ACTION_STATUS.complete,
            });
        }
    };

    const handleUpdateAction = (updateData) => {
        return submitActionUpdate(updateData).then(
            (response) => {
                if (response) {
                    if (isOpen) {
                        setIsOpen(false);
                    }
                } else {
                    console.error('Failed to update action');
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const submitActionUpdate = async (updateData) => {
        const updateActionData = { ...updateData };

        const { data: udpateResponseData } = await updateAction({
            actionId: action.id,
            actionData: updateActionData,
        });

        if (udpateResponseData) {
            return true;
        } else {
            console.warn(`Failed to be update action ${action.id}`);
            return false;
        }
    };

    const buttonTooltip = isTerminal
        ? _.startCase(action.status)
        : hasAdditionalDataRequirement
        ? 'View Request'
        : 'Mark as Done';
    const buttonIcon = determineActionIcon(action);

    const typeProps = type === ACTION_BUTTON_TYPES.contained ? { startIcon: buttonIcon } : { startIcon: null };
    const typeSx =
        type === ACTION_BUTTON_TYPES.contained
            ? {
                  pl: 2,
                  pr: 2,
                  //   height: '40px',
                  minWidth: '180px',
              }
            : {
                  padding: (theme) => theme.spacing(1),
                  minWidth: (theme) => theme.spacing(3),
              };
    const typeContent = type === ACTION_BUTTON_TYPES.contained ? action.friendly_name : buttonIcon;
    const display = type === ACTION_BUTTON_TYPES.contained ? 'initial' : 'inline-block';

    return (
        <span>
            <Tooltip title={buttonTooltip} placement="bottom" enterDelay={300}>
                <span style={{ display: display }}>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        {...typeProps}
                        {...loadingButtonProps}
                        sx={{
                            ...typeSx,
                            ..._.get(loadingButtonProps, 'sx', {}),
                        }}
                        onClick={handleClick}
                        disabled={isTerminal || isOrderFetching}
                        loading={isLoading}
                        disableElevation
                    >
                        {typeContent}
                    </LoadingButton>
                </span>
            </Tooltip>
            {hasAdditionalDataRequirement && (
                <ActionModal
                    action={action}
                    orderId={orderId}
                    isOpen={isOpen}
                    isSubmitting={isLoading}
                    handleClose={() => setIsOpen(false)}
                    handleUpdateAction={handleUpdateAction}
                />
            )}
        </span>
    );
}

ActionButton.propTypes = {
    action: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    loadingButtonProps: PropTypes.object,
};

export default ActionButton;
