import { Stack } from '@mui/material';
import { addDays, format } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ORDER_FILTER_TYPE } from '../../helpers/constants';
import WeekTimelineGridItem from './WeekTimelineGridItem';


function WeekTimelineGrid({ weekRange, weekOrderList, loading, DateOrderComponent, dateRangeFilterType = ORDER_FILTER_TYPE.closeDateRange })  {

    return (
        <Stack spacing={3}>
            {_.times(7, (index) => {
                const baseDate = weekRange[0]
                const date = addDays(baseDate, index)
                const dateFormat = format(date, 'P')
                return (
                    <WeekTimelineGridItem 
                        key={dateFormat} 
                        {...{
                            date,
                            weekOrderList,
                            loading,
                            DateOrderComponent,
                            dateRangeFilterType
                        }}
                    />
                )
            })}
        </Stack>
    )
}

WeekTimelineGrid.propTypes = {
    weekRange: PropTypes.array.isRequired,
    weekOrderList: PropTypes.array.isRequired,
    DateOrderComponent: PropTypes.elementType.isRequired,
    loading: PropTypes.bool,
    dateRangeFilterType: PropTypes.oneOf([ORDER_FILTER_TYPE.closeDateRange, ORDER_FILTER_TYPE.disbursementDateRange]),
}

export default WeekTimelineGrid;