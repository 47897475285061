import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import { Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Button, LoadingButton } from 'components/common/styled';
import DocumentAccessEmailCheckbox from './DocumentAccessEmailCheckbox';
import DocumentAccessField from './DocumentAccessField';
import DocumentTypeField from './DocumentTypeField';
import DocumentUploadAreaItem from './DocumentUploadAreaItem';

const ACCEPTED_FILE_TYPES = ['.xls', '.xlsx', '.pdf', '.png', '.jpg', '.jpeg', '.doc', '.docx'];
const JOINED_ACCEPTED_FILE_TYPES = _.join(ACCEPTED_FILE_TYPES, ', ');

function clearFileNameExtension(fileName) {
    const parts = fileName.split('.');
    parts.pop();
    return parts.join('.');
}

function SharedDocumentDetailsField({
    documentType,
    setDocumentType,
    setDocumentAccess,
    setDocumentSendAccessEmail,
    orderMemberList,
    disabled = false,
}) {
    // TODO manage local documentType state here
    const [sharedDocumentAccess, setSharedDocumentAccess] = useState([]);
    const [sharedSendAccessEmail, setSharedSendAccessEmail] = useState(false);

    const handleDocumentType = ({ value }) => {
        setDocumentType(value);
    };

    const handleDocumentAccess = (documentAccessList) => {
        // Wrapper to explicitly not provided documentId and thus apply list to all documents
        setSharedDocumentAccess(documentAccessList);
        setDocumentAccess(documentAccessList);
    };

    const handleSendAccessEmailChange = (sendAccessEmail) => {
        // Update local state
        setSharedSendAccessEmail(sendAccessEmail);

        // Pass to parent
        setDocumentSendAccessEmail(sendAccessEmail);
    };

    const hasUserAccess = sharedDocumentAccess.length > 0;

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{
                    paddingBottom: (theme) => theme.spacing(3),
                    marginBottom: (theme) => theme.spacing(2),
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
            >
                <Box
                    sx={{
                        width: '320px',
                        textAlign: 'right',
                    }}
                >
                    {/* <Typography>All Files</Typography> */}
                </Box>

                <DocumentTypeField
                    documentType={documentType}
                    setDocumentType={handleDocumentType}
                    disabled={disabled}
                    showLabel={true}
                />

                <DocumentAccessField
                    documentAccess={sharedDocumentAccess}
                    setDocumentAccess={handleDocumentAccess}
                    orderMemberList={orderMemberList}
                    disabled={disabled}
                    showLabel={true}
                />

                <DocumentAccessEmailCheckbox
                    sendAccessEmail={sharedSendAccessEmail}
                    setSendAccessEmail={handleSendAccessEmailChange}
                    disabled={disabled || !hasUserAccess}
                />
            </Stack>

            <Box sx={{ width: '80px' }} />
        </Stack>
    );
}

SharedDocumentDetailsField.propTypes = {
    documentType: PropTypes.string.isRequired,
    setDocumentType: PropTypes.func.isRequired,
    setDocumentAccess: PropTypes.func.isRequired,
    setDocumentSendAccessEmail: PropTypes.func.isRequired,
    orderMemberList: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
};

function DocumentUploadArea(props) {
    const {
        documentDataList,
        setDocumentDataList,
        orderMemberList,
        documentType,
        setDocumentType,
        setDocumentAccess,
        setDocumentName,
        handleClear,
        handleSubmit,
        isSubmitting,
        submitStatusMap,
        setDocumentSendAccessEmail,
    } = props;

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setDocumentDataList(
                    _.map(acceptedFiles, (file, index) => {
                        return {
                            id: `${file.name}-${index}`,
                            document: file,
                            documentType: documentType,
                            name: clearFileNameExtension(file.name), // strip file extension
                            access: [], // access is empty on initial document drop
                        };
                    })
                );
            }
        },
        [documentType]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: JOINED_ACCEPTED_FILE_TYPES,
    });

    if (documentDataList.length === 0) {
        return (
            <Stack
                spacing={2}
                sx={{
                    width: '100%',
                    background: '#F5F5F5',
                    borderRadius: 2,
                    border: (theme) => `2px dashed ${theme.palette.divider}`,
                }}
            >
                <Box
                    {...getRootProps()}
                    sx={{
                        padding: (theme) => theme.spacing(4),
                        height: '180px',
                        cursor: 'pointer',
                    }}
                >
                    <input {...getInputProps()} />
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-around"
                        sx={{
                            height: '100%',
                        }}
                    >
                        <Stack alignItems="center" spacing={1}>
                            <DriveFolderUploadRoundedIcon
                                color="primary"
                                sx={{
                                    fontSize: '48px',
                                }}
                            />
                            <span>
                                <Typography align="center">Drop documents here or </Typography>
                                <Typography
                                    align="center"
                                    color="text.link"
                                    sx={{
                                        '&:hover': {
                                            textDecoration: 'underline',
                                        },
                                    }}
                                >
                                    click to browse
                                </Typography>
                            </span>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        );
    }

    return (
        <Stack
            spacing={2}
            sx={{
                width: '100%',
                background: '#F5F5F5',
                borderRadius: 2,
                border: (theme) => `2px dashed ${theme.palette.divider}`,
            }}
        >
            <Box
                sx={{
                    padding: (theme) => theme.spacing(4),
                }}
            >
                <Stack alignItems="center">
                    <Stack alignItems="center" spacing={6} sx={{ paddingTop: (theme) => theme.spacing(2) }}>
                        <Stack alignItems="flex-end" spacing={2}>
                            {documentDataList.length > 1 && (
                                <SharedDocumentDetailsField
                                    documentType={documentType}
                                    setDocumentType={setDocumentType}
                                    orderMemberList={orderMemberList}
                                    setDocumentAccess={setDocumentAccess}
                                    setDocumentSendAccessEmail={setDocumentSendAccessEmail}
                                    disabled={isSubmitting || !_.isEmpty(submitStatusMap)}
                                />
                            )}

                            <Stack direction="column" spacing={4}>
                                {_.map(documentDataList, (documentData, index) => (
                                    <DocumentUploadAreaItem
                                        key={documentData.id}
                                        documentData={documentData}
                                        setDocumentType={setDocumentType}
                                        orderMemberList={orderMemberList}
                                        setDocumentAccess={setDocumentAccess}
                                        setDocumentName={setDocumentName}
                                        setDocumentSendAccessEmail={setDocumentSendAccessEmail}
                                        handleClear={() => handleClear(documentData.id)}
                                        isSubmitting={isSubmitting}
                                        submitStatusMap={submitStatusMap}
                                        showLabel={documentDataList.length === 1}
                                    />
                                ))}
                            </Stack>
                        </Stack>

                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent="flex-end"
                            sx={{
                                width: '100%',
                            }}
                        >
                            <Button
                                variant="text"
                                color="default"
                                onClick={() => handleClear()}
                                disabled={isSubmitting}
                            >
                                Clear
                            </Button>

                            <LoadingButton
                                onClick={handleSubmit}
                                variant="contained"
                                color="primary"
                                loading={isSubmitting}
                                disabled={isSubmitting || !_.isEmpty(submitStatusMap)}
                                disableElevation
                                sx={{
                                    minWidth: '180px',
                                }}
                            >
                                Upload
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    );
}

DocumentUploadArea.propTypes = {
    documentDataList: PropTypes.array.isRequired, // TODO describe shape of object - document, documentType, id
    setDocumentDataList: PropTypes.func.isRequired,
    orderMemberList: PropTypes.array.isRequired,
    documentType: PropTypes.string.isRequired,
    setDocumentType: PropTypes.func.isRequired,
    setDocumentAccess: PropTypes.func.isRequired,
    setDocumentName: PropTypes.func.isRequired,
    setDocumentSendAccessEmail: PropTypes.func.isRequired,
    handleClear: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    submitStatusMap: PropTypes.object.isRequired, // TODO describe shape of object - id: status (success, error, submitting)
};

export default DocumentUploadArea;
