import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { useGetOrdersQuery } from '../../api/order';
import { ORDER_FILTER_TYPE } from '../../helpers/constants';
import { filterOrders } from '../../helpers/utils';
import SearchBar from './components/SearchBar';
import SearchResultList from './components/SearchResultList';

const DEFAULT_FILTER_STATE = {
    [ORDER_FILTER_TYPE.search]: '',
};

const Search = ({ handleSelect }) => {
    const { data: orders, isError: ordersListError, isLoading: ordersListLoading } = useGetOrdersQuery();

    const [filters, setFilters] = useState({
        ...DEFAULT_FILTER_STATE,
    });

    const filteredOrders = useMemo(() => filterOrders(orders, filters), [orders, filters]);

    const resetFilters = () => {
        setFilters({
            ...DEFAULT_FILTER_STATE,
        });
    };

    return (
        <Stack direction="column" spacing={0} sx={{ width: '100%' }}>
            <SearchBar
                filters={filters}
                setFilters={setFilters}
                defaultFilters={DEFAULT_FILTER_STATE}
                resetFilters={resetFilters}
            />

            <SearchResultList orders={filteredOrders} handleSelect={handleSelect} />
        </Stack>
    );
};

Search.propTypes = {
    handleSelect: PropTypes.func.isRequired,
};

export default Search;
