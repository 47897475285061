import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
    display: false,
    message: '', 
    severity: null, 
    duration: null 
}

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar(state, action) {
        const { payload } = action;
        const { message, severity, duration } = payload;
        state.display = true;
        state.message = message;
        state.severity = severity;
        state.duration = duration;
    },
    closeSnackbar(state) {
        state.display = false;
    },
  },
})

export const { setSnackbar, closeSnackbar } = snackbarSlice.actions
export default snackbarSlice.reducer