import { darken, lighten } from '@mui/material/styles';

const white = '#FFFFFF';
const lightWhite = '#FAFAFA';
const black = '#121212';
const offWhite = '#A0ABBE';
const offBlack = '#454545';
const divider = '#EBEBEB'; //'#2B2B2B';

// #7582ff - purp

// https://m2.material.io/inline-tools/color/
// Calculated based off hudly green
const hudlyGreen = '#268A72'; 

const green = '#00a96f';
const backgroundGreen = '#c8e6c9';
const blue = '#00b3f0';
const lightBlue = '#91C1D8';
const backgroundBlue = '#d3f3ff';
const yellow = '#ffc22d';
const backgroundYellow = '#fff9c4';
const red = '#ff6f70';
const backgroundRed = '#f8e3e7';

// Sidebar colors
const action = '#FE990D';
const dashboard = '#D24376'
const contact = '#1DB7A4';
const order = '#EFC81D';
const refinance = '#ac85b3';
const purchase = '#458da8';


const palette = {
    // mode: "dark",
    black,
    offBlack,
    white,
    lightWhite,
    offWhite,
    blue,
    lightBlue,
    primary: {
        contrastText: lightWhite,
        main: hudlyGreen,
        light: lighten(hudlyGreen, 0.8),
        dark:  darken(hudlyGreen, 0.4),
    },
    secondary: {
        contrastText: lightWhite,
        dark: '#fafafa',
        main: '#FF6492',
        light: '#FFA2BE',
        lighten: '#FFE0E9',
    },
    success: {
        contrastText: lightWhite,
        main: green,
        light: lighten(green, 0.8),
        dark: darken(green, 0.4),
        background: backgroundGreen,
        border: '#3A724D'
    },
    info: {
        contrastText: lightWhite,
        main: blue,
        light: lighten(blue, 0.8),
        dark: darken(blue, 0.4),
        background: backgroundBlue,
    },
    warning: {
        contrastText: offBlack,
        main: yellow,
        light: lighten(yellow, 0.8),
        dark: darken(yellow, 0.4),
        background: backgroundYellow,
    },
    error: {
        contrastText: lightWhite,
        main: red,
        light: lighten(red, 0.8),
        dark: darken(red, 0.4),
        background: backgroundRed,
    },
    action: {
        contrastText: lightWhite,
        main: action,
        light: lighten(action, 0.8),
        dark:  darken(action, 0.4),
    },
    order: {
        contrastText: lightWhite,
        main: order,
        light: lighten(order, 0.8),
        dark:  darken(order, 0.4),
    },
    contact: {
        contrastText: lightWhite,
        main: contact,
        light: lighten(contact, 0.8),
        dark:  darken(contact, 0.4),
    },
    dashboard: {
        contrastText: lightWhite,
        main: dashboard,
        light: lighten(dashboard, 0.8),
        dark:  darken(dashboard, 0.4),
    },
    purchase: {
        contrastText: lightWhite,
        main: purchase,
        light: lighten(purchase, 0.8),
        dark:  darken(purchase, 0.4),
    },
    refinance: {
        contrastText: lightWhite,
        main: refinance,
        light: lighten(refinance, 0.8),
        dark:  darken(refinance, 0.4),
    },
    light: {
        contrastText: offBlack,
        dark: '#f2f2f2',
        main: '#f8f8f8',
        light: '#fff',
        border: lightWhite
    },
    default: {
        contrastText: lightWhite,
        dark: '#434845',
        main: offBlack,
        light: '#A3A3A3',
        border: '#A3A3A3'
    },
    text: {
        dark: black,
        primary: offBlack,
        secondary: offWhite,
        light: lightWhite,
        link: '#065E9C'
    },
    background: {
        default: divider, //'#F4F6F8',
        darkGray: '#f2f2f2',
        gray: lightWhite,
        paper: white,
        dark: '#323232',
    },
    paper: {
        background: '#191919',
        border: divider
    },
    icon: offWhite,
    divider: divider,
    neutral: lightWhite,
    border: divider,
};

export default palette;