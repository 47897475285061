import { Tab } from '@mui/material/';
import { styled } from '@mui/material/styles';

const StyledTab = styled((props) => <Tab disableRipple {...props} />, {
    shouldForwardProp: (prop) => prop !== 'textColor',
})(({ theme, textColor }) => ({
    textTransform: 'none',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    marginRight: theme.spacing(3),
    minWidth: 'auto',

    '&.Mui-selected': {
        color: theme?.palette?.[textColor].main,
    },
    '&:hover': {
        color: theme?.palette?.[textColor].main,
        opacity: 1,
    },
}));

export default StyledTab;
