import { Alert } from '@mui/material';
import _ from 'lodash';

import ActionButton from '../../../Action/ActionButton';
// import { LoadingButton } from '../../../common/styled';

function BalancingParcelReviewAction({ balancingParcel }) {
    const balancingActiveActions = _.get(balancingParcel, 'active_actions', []);
    if (balancingActiveActions.length === 0) {
        return null;
    }

    // Find action is balancing parcel
    // NOTE: assumed that there is only 1 active action for top-level balancing parcel
    const balancingActionId = balancingActiveActions[0];
    const balancingParcelReviewAction = balancingParcel.action_map[balancingActionId];
    if (!balancingParcelReviewAction) {
        return null;
    }

    return (
        <Alert
            variant="filled"
            severity="info"
            action={
                <ActionButton
                    action={balancingParcelReviewAction}
                    parcelId={balancingParcel.id}
                    orderId={balancingParcel.order_id}
                    loadingButtonProps={{
                        color: 'inherit',
                        variant: 'text',
                    }}
                />
            }
            sx={{
                alignItems: 'center',
            }}
        >
            All balancing items are marked as complete. Send for balancing review.
        </Alert>
    );
}

BalancingParcelReviewAction.propTypes = {};

export default BalancingParcelReviewAction;
