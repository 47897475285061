import { Avatar, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { useGetLienStatusesQuery } from 'api/lien';
import { useGetOrdersQuery } from 'api/order';
import { LIEN_CLEARED_STATUS, ORDER_FILTER_TYPE, ORDER_STATUS, ORDER_TITLE_FILTER_TYPE } from 'helpers/constants';
import { filterOrders } from 'helpers/utils';
import OrderListFilters from '../OrderList/components/OrderListFilters';
import TitleListDataGrid from './components/TitleListDataGrid';
import TitleListFilters from './components/TitleListFilters';

const DEFAULT_FILTER_STATE = {
    [ORDER_FILTER_TYPE.search]: '',
    [ORDER_FILTER_TYPE.transactionType]: '',
    [ORDER_FILTER_TYPE.orderType]: [],
    [ORDER_FILTER_TYPE.workflowType]: '',
    [ORDER_FILTER_TYPE.orderStatus]: [ORDER_STATUS.open],
    [ORDER_FILTER_TYPE.closeDateStatus]: '',
    [ORDER_FILTER_TYPE.closeDateRange]: [null, null],
};

const DEFAULT_TITLE_FILTER_STATE = {
    [ORDER_TITLE_FILTER_TYPE.lienType]: [],
    [ORDER_TITLE_FILTER_TYPE.lienStatus]: '',
    [ORDER_TITLE_FILTER_TYPE.clearedStatus]: LIEN_CLEARED_STATUS.not_cleared,
};

function TitleList() {
    const { data: orders, isError: orderError, isLoading: orderLoading } = useGetOrdersQuery();
    const {
        data: lienStatusMap,
        isError: lienStatusMapError,
        isLoading: lienStatusMapLoading,
    } = useGetLienStatusesQuery();

    const [orderList, setOrderList] = useState([]);
    const [activeTitleIssueCount, setActiveTitleIssueCount] = useState(0);
    const [openLienModel, setOpenLienModal] = useState(false);
    const [activeActionId, setActiveActionId] = useState();

    // Base filters for orders
    const [filters, setFilters] = useState({
        ...DEFAULT_FILTER_STATE,
    });

    // Filters for lien/payoffs in orders
    const [titleFilters, setTitleFilters] = useState({
        ...DEFAULT_TITLE_FILTER_STATE,
    });

    const filteredOrders = useMemo(() => filterOrders(orders, filters), [orders, filters]);

    const resetFilters = () => {
        setFilters({
            ...DEFAULT_FILTER_STATE,
        });
    };

    const resetTitleFilters = () => {
        setTitleFilters({
            ...DEFAULT_TITLE_FILTER_STATE,
        });
    };

    const resetAllFilters = () => {
        resetFilters();
        resetTitleFilters();
    };

    useEffect(() => {
        // TODO this will be an expensive operation as the number of orders grows
        // TODO look into memoizing this similar to how we do with filteredOrders

        const newOrderList = [];
        let titleIssueCount = 0;

        _.forEach(filteredOrders, (order) => {
            // 'active' refers to title issues that fit the current title filter criteria
            // a title issue might be complete & cleared but still be active if filtering for cleared issues
            const activeTitleIssuesMap = {};

            if (order.liens.length === 0) {
                // Ignore order if no liens
                return;
            }

            _.forEach(order.liens, (lien) => {
                if (titleFilters.lienType.length > 0 && !_.includes(titleFilters.lienType, lien.type)) {
                    // Ignore lien if type doesn't match
                    return;
                }

                if (titleFilters.lienStatus !== '' && lien.status !== titleFilters.lienStatus) {
                    // Ignore lien if status doesn't match
                    return;
                }

                if (titleFilters.clearedStatus !== '') {
                    const clearedBool = titleFilters.clearedStatus === LIEN_CLEARED_STATUS.cleared;
                    const lienTerminalStatuses = _.get(lienStatusMap, '_terminal', []);
                    const lienCleared = _.includes(lienTerminalStatuses, lien.status);
                    if (clearedBool !== lienCleared) {
                        // Ignore lien if cleared status doesn't match
                        return;
                    }
                }

                activeTitleIssuesMap[lien.id] = { ...lien };
                titleIssueCount += 1;
            });

            if (_.keys(activeTitleIssuesMap).length > 0) {
                // Iterate through payoffs and add any relevent to activeTitleIssues
                _.forEach(order.payoffs, (payoff) => {
                    if (_.has(activeTitleIssuesMap, payoff.lien_id)) {
                        activeTitleIssuesMap[payoff.lien_id]['payoff'] = payoff;
                    }
                });

                newOrderList.push({
                    ...order,
                    activeTitleIssues: _.values(activeTitleIssuesMap),
                });
            }
        });

        if (newOrderList.length === orderList.length) {
            // Potentially no change
            // We need to verify each order
            const overlap = _.intersection(newOrderList, orderList);
            if (overlap.length !== newOrderList.length) {
                setOrderList(newOrderList);
            }
        } else {
            setOrderList(newOrderList);
        }

        setActiveTitleIssueCount(titleIssueCount);
    }, [filteredOrders, titleFilters, lienStatusMap]);

    return (
        <Stack spacing={3} sx={{ height: '100%', padding: (theme) => theme.spacing(3), maxWidth: '1520px' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h1">Title</Typography>

                <Stack direction="row" alignItems="center">
                    <Avatar
                        sx={{
                            backgroundColor: (theme) =>
                                activeTitleIssueCount > 0
                                    ? theme.palette.primary.main
                                    : theme.palette.background.default,
                            color: (theme) =>
                                activeTitleIssueCount > 0
                                    ? theme.palette.primary.contrastText
                                    : theme.palette.text.primary,
                            marginRight: (theme) => theme.spacing(1),
                            height: (theme) => theme.spacing(4),
                            width: (theme) => theme.spacing(4),
                            fontSize: (theme) => theme.spacing(2),
                            fontWeight: 600,
                        }}
                    >
                        {activeTitleIssueCount}
                    </Avatar>
                    <Typography variant="h6"> {`Title Issue${activeTitleIssueCount === 1 ? '' : 's'}`} </Typography>
                </Stack>
            </Stack>

            <OrderListFilters
                defaultFilters={DEFAULT_FILTER_STATE}
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetAllFilters}
            />
            <TitleListFilters
                filters={titleFilters}
                defaultFilters={DEFAULT_TITLE_FILTER_STATE}
                setFilters={setTitleFilters}
            />

            <div style={{ minHeight: '250px' }}>
                <TitleListDataGrid orderList={orderList} loading={orderLoading} />
            </div>
        </Stack>
    );
}

export default TitleList;
