// import AttachEmailIcon from '@mui/icons-material/AttachEmail';
// import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
// import EmailIcon from '@mui/icons-material/Email';
import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { useSendDocumentAccessNotificationMutation } from 'api/document';
import { generateOrderMemberRoleMap, getOrderMemberName } from 'components/Order/Members/utils';
import { Button, LoadingButton } from 'components/common/styled';

function DocumentAccessMemberItem({ orderMember, documentAccess, isChecked, handleChange }) {
    return (
        <FormControlLabel
            control={<Checkbox checked={isChecked} name={documentAccess.id} onChange={handleChange} />}
            label={getOrderMemberName(orderMember)}
        />
    );
}

DocumentAccessRoleGroup.propTypes = {
    orderMember: PropTypes.object.isRequired,
    documentAccess: PropTypes.object.isRequired,
    isChecked: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
};

function DocumentAccessRoleGroup({
    role,
    roleOrderMembers,
    documentAccessMemberIdMap,
    documentAccessNotificationList,
    handleChange,
}) {
    return (
        <Stack direction="column" spacing={1} key={role} sx={{ marginBottom: (theme) => theme.spacing(3) }}>
            <Typography variant="sectionHeader" color="text.primary">
                {_.startCase(role)}
            </Typography>
            {_.map(roleOrderMembers, (orderMember) => {
                const documentAccess = documentAccessMemberIdMap[orderMember.id];
                return (
                    <DocumentAccessMemberItem
                        key={orderMember.id}
                        orderMember={orderMember}
                        documentAccess={documentAccess}
                        handleChange={handleChange}
                        isChecked={_.includes(documentAccessNotificationList, documentAccess.id)}
                    />
                );
            })}
        </Stack>
    );
}

DocumentAccessRoleGroup.propTypes = {
    role: PropTypes.string.isRequired,
    roleOrderMembers: PropTypes.array.isRequired,
    documentAccessMemberIdMap: PropTypes.object.isRequired,
    documentAccessNotificationList: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
};

function DocumentAccessNotificationForm({ orderId, document, orderMembers, handleSubmit }) {
    const [sendDocumentAccessNotification] = useSendDocumentAccessNotificationMutation();

    const orderMemberRoleMap = useMemo(() => generateOrderMemberRoleMap(orderMembers), [orderMembers]);
    const documentAccessMemberIdMap = useMemo(() => _.keyBy(document.access, 'order_member_id'), [document.access]);

    const [documentAccessNotificationList, setDocumentAccessNotificationList] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    const handleSendDocumentAccessNotification = (event) => {
        event.preventDefault();

        if (documentAccessNotificationList.length === 0) {
            // No changes - ignore submit request
            return null;
        }

        // Submit document access notifications
        setSubmitting(true);

        return submitDocumentAccessNotification(documentAccessNotificationList).then((response) => {
            setSubmitting(false);
            handleSubmit();
        });
    };

    const submitDocumentAccessNotification = async (documentAccessIds) => {
        const { data } = await sendDocumentAccessNotification({
            documentId: document.id,
            documentAccessIds,
        });

        if (data) {
            return true;
        } else {
            console.warn(`Failed to send notifications for document ${document.id}`);
            return false;
        }
    };

    const handleChange = (event) => {
        const documentAccessId = event.target.name;
        const checked = event.target.checked;

        const alreadyContainsAccess = _.includes(documentAccessNotificationList, documentAccessId);
        if (alreadyContainsAccess && checked) {
            // Do nothing
            return;
        }

        if (!alreadyContainsAccess && !checked) {
            // Do nothing
            return;
        }

        if (alreadyContainsAccess && !checked) {
            // Remove from list
            setDocumentAccessNotificationList(
                _.filter(documentAccessNotificationList, (id) => id !== documentAccessId).sort()
            );
            return;
        }

        if (!alreadyContainsAccess && checked) {
            // Add to list
            setDocumentAccessNotificationList([...documentAccessNotificationList, documentAccessId].sort());
        }
    };

    if (!orderId) {
        return null;
    }

    return (
        <form onSubmit={handleSendDocumentAccessNotification}>
            <Stack spacing={4}>
                <FormGroup>
                    {_.map(orderMemberRoleMap, (roleOrderMembers, role) => {
                        return (
                            <DocumentAccessRoleGroup
                                key={role}
                                role={role}
                                roleOrderMembers={roleOrderMembers}
                                documentAccessMemberIdMap={documentAccessMemberIdMap}
                                documentAccessNotificationList={documentAccessNotificationList}
                                handleChange={handleChange}
                            />
                        );
                    })}
                </FormGroup>

                <Stack direction="row" spacing={2} alignItems="center">
                    <Box flexGrow={1} />

                    <Button onClick={handleSubmit} color="default" disabled={submitting}>
                        Cancel
                    </Button>

                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        disableElevation
                        loading={submitting}
                        disabled={submitting || documentAccessNotificationList.length === 0}
                        sx={{
                            minWidth: '160px',
                        }}
                    >
                        Send
                    </LoadingButton>
                </Stack>
            </Stack>
        </form>
    );
}

DocumentAccessNotificationForm.propTypes = {
    orderId: PropTypes.string.isRequired,
    document: PropTypes.object.isRequired,
    orderMembers: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default DocumentAccessNotificationForm;
