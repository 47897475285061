import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Checkbox, FormControlLabel, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import { useDeleteLegalEntityMemberMutation, useUpdateLegalEntityMemberMutation } from 'api/legalEntity';
import { Button, LoadingButton } from 'components/common/styled';
import {
    COMMERCIAL_LEGAL_ENTITIES,
    COMMERCIAL_TYPE_MEMBER_ROLE_MAP,
    LEGAL_ENTITY_TYPE_MEMBER_ROLE_MAP,
} from 'helpers/constants';

const EditMember = ({ legalEntity, member, handleCancel, handleComplete }) => {
    const [roleCopy, setRoleCopy] = useState('');
    const [signorCopy, setSignorCopy] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [
        updateLegalEntityMember,
        { isLoading: isUpdateLegalEntityMemberLoading },
    ] = useUpdateLegalEntityMemberMutation();
    const [
        deleteLegalEntityMember,
        { isLoading: isDeleteLegalEntityMemberLoading },
    ] = useDeleteLegalEntityMemberMutation();

    const memberRoleOptions = useMemo(() => {
        if (_.includes(COMMERCIAL_LEGAL_ENTITIES, legalEntity.type)) {
            return COMMERCIAL_TYPE_MEMBER_ROLE_MAP[legalEntity.commercial_type];
        }

        return LEGAL_ENTITY_TYPE_MEMBER_ROLE_MAP[legalEntity.type];
    }, [legalEntity]);

    useEffect(() => {
        // Create local copy of role/signor to be editted
        setRoleCopy(member.role);
        setSignorCopy(member.signor);
    }, [member]);

    const handleRoleChange = (event) => {
        const newRole = event.target.value;
        setRoleCopy(newRole);
    };

    const handleSignorChange = ({ target }) => {
        const newSignor = target.checked;
        setSignorCopy(newSignor);
    };

    const handleDeleteMember = (event) => {
        // TODO
        event.preventDefault();

        setIsSubmitting(true);

        return submitLegalEntityMemberDelete(member).then(
            (response) => {
                setIsSubmitting(false);

                if (response) {
                    handleComplete();
                } else {
                    console.error('Failed to delete legal entity member');
                }
            },
            (error) => {
                console.log(error);
                setIsSubmitting(false);
            }
        );
    };

    const submitLegalEntityMemberDelete = async (member) => {
        const { data: deleteLegalEntityMemberData } = await deleteLegalEntityMember({
            legalEntityId: member.legal_entity_id,
            memberId: member.id,
        });

        if (deleteLegalEntityMemberData) {
            return true;
        } else {
            console.warn(`Failed to be delete legal entity member ${member.id}`);
            return false;
        }
    };

    const handleUpdateMember = (event) => {
        event.preventDefault();

        setIsSubmitting(true);

        return submitLegalEntityMemberUpdate(member, roleCopy, signorCopy).then(
            (response) => {
                setIsSubmitting(false);

                if (response) {
                    handleComplete();
                } else {
                    console.error('Failed to update legal entity member');
                }
            },
            (error) => {
                console.log(error);
                setIsSubmitting(false);
            }
        );
    };

    const submitLegalEntityMemberUpdate = async (member, role, signor) => {
        const updateLegalEntityMemberData = {
            role,
            signor,
        };

        const { data: updateLegalEntityMemberRequestData } = await updateLegalEntityMember({
            legalEntityId: member.legal_entity_id,
            memberId: member.id,
            memberData: updateLegalEntityMemberData,
        });

        if (updateLegalEntityMemberRequestData) {
            return true;
        } else {
            console.warn(`Failed to be update legal entity member ${member.id}`);
            return false;
        }
    };

    return (
        <Stack spacing={4} sx={{ width: '100%' }}>
            <Stack spacing={1}>
                <Typography variant="h5">Edit Member</Typography>
                <Typography variant="body1" color="text.secondary">
                    {legalEntity.name}
                </Typography>
            </Stack>

            <Stack direction="column" spacing={2}>
                <Typography variant="body1" color="text.primary">
                    {member.user.name}
                </Typography>

                <Stack direction="row" alignItems="center" spacing={2}>
                    <TextField
                        name="role"
                        label="Role"
                        value={roleCopy}
                        onChange={handleRoleChange}
                        sx={{ minWidth: '150px' }}
                        size="small"
                        select
                        required
                    >
                        {_.map(memberRoleOptions, (memberType) => (
                            <MenuItem key={memberType} value={memberType}>
                                {_.startCase(memberType)}
                            </MenuItem>
                        ))}
                    </TextField>

                    <FormControlLabel
                        label="Signor"
                        control={<Checkbox name="signor" checked={signorCopy} onChange={handleSignorChange} />}
                        sx={{ marginRight: '0' }}
                    />

                    <Box style={{ flexGrow: 1, textAlign: 'right' }}>
                        <IconButton onClick={handleDeleteMember} disabled={isSubmitting}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </Box>
                </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                <Button variant="text" color="default" onClick={() => handleCancel()} disabled={isSubmitting}>
                    Cancel
                </Button>

                <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={handleUpdateMember}
                    loading={isSubmitting}
                    disableElevation
                    disabled={member.role === roleCopy && member.signor === signorCopy}
                    sx={{
                        minWidth: '160px',
                    }}
                >
                    Update
                </LoadingButton>
            </Stack>
        </Stack>
    );
};

EditMember.propTypes = {
    legalEntity: PropTypes.object.isRequired,
    member: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleComplete: PropTypes.func.isRequired,
};

export default EditMember;
