import { Stack } from '@mui/material';
import ContactList from '../../components/ContactList';

function ContactListPage() {
    return (
        <Stack style={{ height: '100%' }}>
            <ContactList />
        </Stack>
    );
}

export default ContactListPage;
