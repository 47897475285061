import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ORDER_ROLE_TYPE } from '../../helpers/constants';
import CloseDateField from '../common/form/order/CloseDateField';
import FundDateField from '../common/form/order/FundDateField';
import DollarFieldMask from '../common/mask/DollarFieldMask';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    spacer: {
        marginBottom: theme.spacing(4),
    },
}));

function PurchaseFormCont(props) {
    const classes = useStyles();

    const { fields, error, errorMessage, disableFields } = props;
    const {
        titlePolicyPayer,
        setTitlePolicyPayer,
        surveyException,
        setSurveyException,
        titlePeriod,
        setTitlePeriod,
        survey,
        setSurvey,
        surveyPayer,
        setSurveyPayer,
        surveyPeriod,
        setSurveyPeriod,
        objectionPeriod,
        setObjectionPeriod,
        hoa,
        setHoa,
        hoaPeriod,
        setHoaPeriod,
        hoaCredit,
        setHoaCredit,
        homeWarrantyCredit,
        setHomeWarrantyCredit,
        openDate,
        setOpenDate,
        closeDate,
        setCloseDate,
        confirmedCloseDate,
        setConfirmedCloseDate,
        fundDate,
        setFundDate,
        sellerCredit,
        setSellerCredit,
    } = fields;

    return (
        <Stack
            spacing={3}
            sx={{
                overflow: 'auto',
            }}
        >
            <FormControl component="fieldset" disabled={disableFields}>
                <FormLabel id="title-policy-payer-group-label">Title Policy Payer</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="title-policy-payer-group-label"
                    name="title-policy-payer-radio-buttons-group"
                    value={titlePolicyPayer}
                    onChange={({ target }) => setTitlePolicyPayer(target.value)}
                    required
                >
                    <FormControlLabel
                        value={ORDER_ROLE_TYPE.seller}
                        control={<Radio />}
                        label={_.startCase(ORDER_ROLE_TYPE.seller)}
                    />
                    <FormControlLabel
                        value={ORDER_ROLE_TYPE.buyer}
                        control={<Radio />}
                        label={_.startCase(ORDER_ROLE_TYPE.buyer)}
                    />
                </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" disabled={disableFields}>
                <FormLabel id="survey-exception-group-label">Survey Exception</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="survey-exception-group-label"
                    name="survey-exception-radio-buttons-group"
                    value={surveyException}
                    onChange={({ target }) => setSurveyException(target.value)}
                    disabled={disableFields}
                    required
                >
                    <FormControlLabel value={''} control={<Radio />} label="None" />
                    <FormControlLabel
                        value={ORDER_ROLE_TYPE.seller}
                        control={<Radio />}
                        label={_.startCase(ORDER_ROLE_TYPE.seller)}
                    />
                    <FormControlLabel
                        value={ORDER_ROLE_TYPE.buyer}
                        control={<Radio />}
                        label={_.startCase(ORDER_ROLE_TYPE.buyer)}
                    />
                </RadioGroup>
            </FormControl>

            <TextField
                label="Title Period"
                value={titlePeriod}
                onChange={({ target }) => setTitlePeriod(target.value)}
                variant="outlined"
                type="number"
                size="small"
                InputProps={{
                    endAdornment: <span>days</span>,
                    inputProps: { min: 0 },
                }}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                disabled={disableFields}
                required
            />

            <div className={classes.spacer} />

            <FormControl component="fieldset" disabled={disableFields}>
                <FormLabel id="survey-group-label">Survey</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="survey-group-label"
                    name="survey-radio-buttons-group"
                    value={survey}
                    onChange={({ target }) => setSurvey(target.value)}
                    disabled={disableFields}
                    required
                >
                    <FormControlLabel value={''} control={<Radio />} label="Not Required" />
                    <FormControlLabel value={'existing'} control={<Radio />} label="Existing" />
                    <FormControlLabel value={'new'} control={<Radio />} label="New" />
                    <FormControlLabel value={'TBD'} control={<Radio />} label="TBD" />
                </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" disabled={disableFields}>
                <FormLabel id="survey-group-label">Survey Payer</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="survey-group-label"
                    name="survey-radio-buttons-group"
                    value={surveyPayer}
                    onChange={({ target }) => setSurveyPayer(target.value)}
                    disabled={disableFields}
                    required
                >
                    <FormControlLabel value={ORDER_ROLE_TYPE.seller} control={<Radio />} label="Seller Paid" />
                    <FormControlLabel value={ORDER_ROLE_TYPE.buyer} control={<Radio />} label="Buyer Paid" />
                    <FormControlLabel value={''} control={<Radio />} label="TBD" />
                </RadioGroup>
            </FormControl>

            <TextField
                label="Survey Period"
                value={surveyPeriod}
                onChange={({ target }) => setSurveyPeriod(target.value)}
                variant="outlined"
                type="number"
                size="small"
                InputProps={{
                    endAdornment: <span>days</span>,
                    inputProps: { min: 0 },
                }}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                disabled={disableFields}
                required
            />

            <TextField
                label="Objection Period"
                value={objectionPeriod}
                onChange={({ target }) => setObjectionPeriod(target.value)}
                variant="outlined"
                type="number"
                size="small"
                InputProps={{
                    endAdornment: <span>days</span>,
                    inputProps: { min: 0 },
                }}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                disabled={disableFields}
                required
            />

            <div className={classes.spacer} />

            <FormControl component="fieldset" disabled={disableFields}>
                <FormLabel id="hoa-group-label">HOA</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="hoa-group-label"
                    name="hoa-radio-buttons-group"
                    value={hoa}
                    onChange={({ target }) => setHoa(target.value)}
                    disabled={disableFields}
                    required
                >
                    <FormControlLabel value={''} control={<Radio />} label="No HOA" />
                    <FormControlLabel value={ORDER_ROLE_TYPE.seller} control={<Radio />} label="Seller Paid" />
                    <FormControlLabel value={ORDER_ROLE_TYPE.buyer} control={<Radio />} label="Buyer Paid" />
                </RadioGroup>
            </FormControl>

            <TextField
                label="HOA Period"
                value={hoaPeriod}
                onChange={({ target }) => setHoaPeriod(target.value)}
                variant="outlined"
                type="number"
                size="small"
                InputProps={{
                    endAdornment: <span>days</span>,
                    inputProps: { min: 0 },
                }}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                disabled={disableFields}
                required
            />

            <TextField
                label="HOA Credit"
                value={hoaCredit}
                onChange={({ target }) => setHoaCredit(target.value)}
                variant="outlined"
                size="small"
                InputProps={{
                    inputComponent: DollarFieldMask,
                }}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                disabled={disableFields}
                required
            />

            <TextField
                label="Home Warranty Credit"
                value={homeWarrantyCredit}
                onChange={({ target }) => setHomeWarrantyCredit(target.value)}
                variant="outlined"
                size="small"
                InputProps={{
                    inputComponent: DollarFieldMask,
                }}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                disabled={disableFields}
                required
            />

            <div className={classes.spacer} />

            <DatePicker
                autoOk
                label="Executed (Open) Date"
                openTo="day"
                inputFormat="MM/dd/yyyy"
                value={openDate ? parseISO(openDate) : null}
                inputVariant="outlined"
                onChange={(date) => {
                    // NOTE: not strict comparison due to issues with date error response object
                    if (date && date != 'Invalid Date') {
                        setOpenDate(format(date, 'yyyy-MM-dd'));
                    } else {
                        setOpenDate(null);
                    }
                }}
                disabled={disableFields}
                renderInput={(params) => <TextField size="small" {...params} />}
                // disablePast
                clearable
                required
            />

            <CloseDateField
                openDate={openDate}
                closeDate={closeDate}
                setCloseDate={setCloseDate}
                confirmedCloseDate={confirmedCloseDate}
                setConfirmedCloseDate={setConfirmedCloseDate}
                disableFields={disableFields}
            />

            <FundDateField
                closeDate={closeDate}
                fundDate={fundDate}
                setFundDate={setFundDate}
                defaultFundDatePeriod={1}
                disableFields={disableFields}
            />

            <div className={classes.spacer} />

            <TextField
                label="Seller Credit"
                value={sellerCredit}
                onChange={({ target }) => setSellerCredit(target.value)}
                variant="outlined"
                size="small"
                InputProps={{
                    inputComponent: DollarFieldMask,
                }}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                disabled={disableFields}
                required
            />
        </Stack>
    );
}

PurchaseFormCont.propTypes = {
    fields: PropTypes.shape({
        titlePolicyPayer: PropTypes.string.isRequired,
        setTitlePolicyPayer: PropTypes.func.isRequired,
        surveyException: PropTypes.string.isRequired,
        setSurveyException: PropTypes.func.isRequired,
        titlePeriod: PropTypes.string.isRequired,
        setTitlePeriod: PropTypes.func.isRequired,
        survey: PropTypes.string.isRequired,
        setSurvey: PropTypes.func.isRequired,
        surveyPayer: PropTypes.string.isRequired,
        setSurveyPayer: PropTypes.func.isRequired,
        surveyPeriod: PropTypes.string.isRequired,
        setSurveyPeriod: PropTypes.func.isRequired,
        objectionPeriod: PropTypes.string.isRequired,
        setObjectionPeriod: PropTypes.func.isRequired,
        hoa: PropTypes.string.isRequired,
        setHoa: PropTypes.func.isRequired,
        hoaPeriod: PropTypes.string.isRequired,
        setHoaPeriod: PropTypes.func.isRequired,
        hoaCredit: PropTypes.string.isRequired,
        setHoaCredit: PropTypes.func.isRequired,
        homeWarrantyCredit: PropTypes.string.isRequired,
        setHomeWarrantyCredit: PropTypes.func.isRequired,
        openDate: PropTypes.string.isRequired,
        setOpenDate: PropTypes.func.isRequired,
        closeDate: PropTypes.string, // NOTE: not required to avoid null error
        setCloseDate: PropTypes.func.isRequired,
        confirmedCloseDate: PropTypes.bool.isRequired,
        setConfirmedCloseDate: PropTypes.func.isRequired,
        fundDate: PropTypes.string, // NOTE: not required to avoid null error
        setFundDate: PropTypes.func.isRequired,
        sellerCredit: PropTypes.string.isRequired,
        setSellerCredit: PropTypes.func.isRequired,
    }).isRequired,
    disableFields: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.object.isRequired,
};

export default PurchaseFormCont;
