import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { IconButton, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { formatISODate } from '../../../helpers/utils';
import RichTextEditor from '../../RichTextEditor';
import ConfirmDialog from '../../common/ConfirmDialog';
import UserAvatar from '../../common/UserAvatar';
import { Button, LoadingButton } from '../../common/styled';

import { useDeleteNoteMutation, useUpdateNoteMutation } from '../../../api/note';

/*
    TODO
    - Add edit note functionality
*/

function BaseNote({ note, users = {}, options = {} }) {
    const { allowEdit = true, allowDelete = true, allowPin = true } = options;

    const [updateNote, { isLoading: isUpdateNoteLoading }] = useUpdateNoteMutation();
    const [deleteNote, { isLoading: isDeleteNoteLoading }] = useDeleteNoteMutation();

    const user = _.get(users, note.user_id, null);

    const [isEditing, setIsEditing] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [pinnedCopy, setPinnedCopy] = useState(note.pinned);
    const [contentCopy, setContentCopy] = useState(note.content);
    const [richContentCopy, setRichContentCopy] = useState(JSON.stringify(note.rich_content));
    const [initialContent, setInitialContent] = useState(richContentCopy);
    const [prevNote, setPrevNote] = useState(note);

    const syncNoteState = () => {
        // Reset state when note changes
        setPinnedCopy(note.pinned);
        setContentCopy(note.content);

        const richContent = JSON.stringify(note.rich_content);
        setRichContentCopy(richContent);
        setInitialContent(richContent);
        setPrevNote(note);
    };

    const resetNoteState = () => {
        syncNoteState();

        // Reset edit state
        setIsEditing(false);
        setConfirmDelete(false);
    };

    if (note.id !== prevNote.id) {
        resetNoteState();
    } else if (note !== prevNote) {
        // Note reference has changed, but not the id
        syncNoteState();
    }

    const handlePin = () => {
        const pinnedStatus = !note.pinned;

        // Update copy immediately to reflect in UI
        setPinnedCopy(pinnedStatus);

        return submitUpdate({
            pinned: pinnedStatus,
        }).then((responeData) => {
            if (!responeData) {
                // Revert pinned copy if update fails
                setPinnedCopy(note.pinned);
            }
        });
    };

    const handleSave = () => {
        return submitUpdate({
            content: contentCopy,
            rich_content: JSON.parse(richContentCopy),
        }).then((responeData) => {
            if (responeData) {
                setIsEditing(false);
            }

            // TODO - need to update rich content component

            // TODO - Handle error
        });
    };

    const handleDelete = () => {
        deleteNote({
            orderId: note.order_id,
            noteId: note.id,
        });

        // TODO
        // - if success, component will unmount
        // - if error, notify user
    };

    const handleCancel = () => {
        // Reset contentCopy back to original note content
        resetNoteState();
    };

    const submitUpdate = async (updatePayload) => {
        const updateNoteFullPayload = {
            orderId: note.order_id,
            noteId: note.id,
            noteData: updatePayload,
        };

        const { data } = await updateNote(updateNoteFullPayload);

        if (data) {
            return true;
        } else {
            console.warn(`Failed to be update note ${note.id}`);
            return false;
        }
    };

    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={2} alignItems="center">
                {user && <UserAvatar user={user} />}
                <Stack direction="column" spacing={0}>
                    <Typography variant="body1" color="text.primary">
                        {user ? `${user.first_name} ${user.last_name}` : 'Unknown'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {formatISODate(note.created_datetime, 'MMMM d, yyyy @ h:mm a')}
                    </Typography>
                </Stack>

                <Stack spacing={2} direction="row-reverse" alignItems="center" sx={{ flexGrow: 1 }}>
                    {allowEdit && (
                        <IconButton aria-label="edit" onClick={() => (isEditing ? handleCancel() : setIsEditing(true))}>
                            {isEditing ? <CloseIcon /> : <EditIcon />}
                        </IconButton>
                    )}

                    {allowPin && (
                        <IconButton aria-label="pinned" onClick={() => handlePin()} sx={{}}>
                            {pinnedCopy ? <StarIcon /> : <StarBorderIcon />}
                        </IconButton>
                    )}
                </Stack>
            </Stack>

            <Stack
                spacing={2}
                sx={{
                    marginLeft: (theme) => `${theme.spacing(7)} !important`,
                }}
            >
                <RichTextEditor
                    initialContent={initialContent}
                    setRichTextContent={setRichContentCopy}
                    setPlainTextContent={setContentCopy}
                    editable={isEditing}
                    updatedDate={note.updated_datetime}
                />

                {isEditing && (
                    <Stack direction="row-reverse" spacing={2} alignItems="center" justifyContent="space-between">
                        <Stack spacing={2} direction="row" alignItems="center">
                            <Button
                                variant="text"
                                onClick={() => handleCancel()}
                                disabled={isUpdateNoteLoading || isDeleteNoteLoading}
                                color="default"
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                onClick={() => handleSave()}
                                loading={isUpdateNoteLoading || isDeleteNoteLoading}
                                disabled={note.content === contentCopy}
                                disableElevation
                                sx={{ minWidth: '160px' }}
                            >
                                Save
                            </LoadingButton>
                        </Stack>

                        {allowDelete && (
                            <IconButton
                                onClick={() => setConfirmDelete(true)}
                                disabled={isUpdateNoteLoading || isDeleteNoteLoading}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Stack>
                )}
            </Stack>

            <ConfirmDialog
                open={!!confirmDelete}
                title="Delete Note"
                body={`Are you sure you want to delete this note?`}
                handleConfirm={() => handleDelete()}
                handleClose={() => setConfirmDelete(false)}
            />
        </Stack>
    );
}

BaseNote.propTypes = {
    note: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    options: PropTypes.object,
};

export default BaseNote;
