import SendIcon from '@mui/icons-material/Send';
import { Box, Divider, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useCreateNoteMutation, useUpdateNoteMutation } from '../../../api/note';
import { ENTITY_TYPE } from '../../../helpers/constants';
import RichTextEditor from '../../RichTextEditor';
import useNoteData from '../../common/hooks/useNoteData';
import { LoadingButton } from '../../common/styled';
import ActiveNoteReply from './ActiveNoteReply';
import BaseNote from './BaseNote';
import NoteTag from './NoteTag';

function ActiveNote({ note }) {
    const { order, entities, entityName, replyNotes, users } = useNoteData(note);

    const [createNote, { isLoading: isCreateNoteLoading }] = useCreateNoteMutation();
    const [updateNote, { isLoading: isUpdateNoteLoading }] = useUpdateNoteMutation();

    const [replyContent, setReplyContent] = useState('');
    const [replyRichContent, setReplyRichContent] = useState(null);
    const [replyDate, setReplyDate] = useState(new Date()); // Changing causes the editor to reset content to match initial content value
    const [tag, setTag] = useState(note ? { entity_id: note.entity_id, entity_type: note.entity_type } : null);

    useEffect(() => {
        setTag(!!_.get(note, 'entity_id', null) ? { entity_id: note.entity_id, entity_type: note.entity_type } : null);
    }, [note]);

    const handleTagChange = (newTag) => {
        if (newTag && newTag.entity_id !== note.entity_id) {
            // Update the note's entity_id and entity_type
            handleTagUpdate(newTag.entity_id, newTag.entity_type);
        } else if (!newTag && !!note.entity_id) {
            // Remove the note's entity_id and entity_type
            handleTagUpdate(null, null);
        }

        setTag(newTag);
    };

    const handleCreateReply = () => {
        const notePayload = {
            order_id: note.order_id,
            content: replyContent,
            rich_content: JSON.parse(replyRichContent),
            entity_type: ENTITY_TYPE.note,
            entity_id: note.id,
        };

        return submitCreateNote(notePayload).then((responseData) => {
            if (responseData) {
                resetReplyContent();
            }
            // TODO - Handle error
        });
    };

    const submitCreateNote = async (notePayload) => {
        const { data } = await createNote({
            orderId: note.order_id,
            noteData: notePayload,
        });

        if (data.note_id) {
            return data;
        }

        return false;
    };

    const resetReplyContent = () => {
        setReplyContent('');
        setReplyRichContent(null);
        setReplyDate(new Date());
    };

    const handleTagUpdate = (entity_id, entity_type) => {
        return submitUpdate({
            entity_id,
            entity_type,
        }).then((responeData) => {
            // if (responeData) {
            // }
            // TODO - Handle error
        });
    };

    const submitUpdate = async (updatePayload) => {
        const updateNoteFullPayload = {
            orderId: note.order_id,
            noteId: note.id,
            noteData: updatePayload,
        };

        const { data } = await updateNote(updateNoteFullPayload);

        if (data) {
            return true;
        } else {
            console.warn(`Failed to be update note ${note.id}`);
            return false;
        }
    };

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
                width: '100%',
            }}
        >
            <Stack spacing={3}>
                <BaseNote note={note} users={users} />
                <Box
                    sx={{
                        paddingLeft: (theme) => theme.spacing(7),
                    }}
                >
                    <NoteTag orderId={order.id} tag={tag} setTag={handleTagChange} editable={true} />
                </Box>
            </Stack>

            <Box>
                <Divider textAlign="center">
                    <Typography variant="overline">
                        {replyNotes.length} {replyNotes.length === 1 ? 'Reply' : 'Replies'}
                    </Typography>
                </Divider>
            </Box>

            <Stack
                spacing={6}
                sx={{
                    flexGrow: 1,
                    paddingBottom: (theme) => theme.spacing(3),
                    overflow: 'auto',
                    height: '0px',
                }}
            >
                {_.map(replyNotes, (replyNote) => (
                    <ActiveNoteReply key={replyNote.id} note={replyNote} users={users} />
                ))}
            </Stack>

            <Stack direction="row" spacing={2}>
                <Box sx={{ flexGrow: 1 }}>
                    <RichTextEditor
                        initialContent={null}
                        setRichTextContent={setReplyRichContent}
                        setPlainTextContent={setReplyContent}
                        updatedDate={replyDate}
                    />
                </Box>

                <Stack direction="column" justifyContent="flex-end">
                    <LoadingButton
                        onClick={() => handleCreateReply()}
                        endIcon={<SendIcon />}
                        loading={isCreateNoteLoading}
                        disabled={!replyContent || replyContent.length === 0}
                        loadingPosition="end"
                        variant="contained"
                        disableElevation
                    >
                        Reply
                    </LoadingButton>
                </Stack>
            </Stack>
        </Stack>
    );
}

ActiveNote.propTypes = {
    note: PropTypes.object.isRequired,
};

export default ActiveNote;
