import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DocumentAccessEmailCheckbox from './DocumentAccessEmailCheckbox';
import DocumentAccessField from './DocumentAccessField';
import DocumentNameField from './DocumentNameField';
import DocumentTypeField from './DocumentTypeField';

function DocumentUploadAreaItem(props) {
    const {
        documentData,
        setDocumentType,
        setDocumentAccess,
        setDocumentName,
        setDocumentSendAccessEmail,
        orderMemberList,
        handleClear,
        isSubmitting,
        submitStatusMap,
        showLabel = false,
    } = props;

    const handleDocumentType = ({ value }) => {
        setDocumentType(value, documentData.id);
    };

    const handleDocumentAccess = (documentAccessList) => {
        setDocumentAccess(documentAccessList, documentData.id);
    };

    const handleDocumentName = (documentName) => {
        setDocumentName(documentName, documentData.id);
    };

    const submitStatus = _.get(submitStatusMap, documentData.id, null);
    const hasUserAccess = _.get(documentData, 'access.length', []) > 0;

    return (
        <Stack direction="column" spacing={2} alignItems="flex-start">
            <Typography fontWeight="500">{documentData.document.name}</Typography>

            <Stack direction="row" alignItems="center" spacing={2}>
                <DocumentNameField
                    documentName={documentData.name}
                    setDocumentName={handleDocumentName}
                    disabled={isSubmitting || !!submitStatus}
                />

                <DocumentTypeField
                    documentType={documentData.documentType}
                    setDocumentType={handleDocumentType}
                    disabled={isSubmitting || !!submitStatus}
                    showLabel={showLabel}
                />

                <DocumentAccessField
                    documentAccess={documentData.access}
                    setDocumentAccess={handleDocumentAccess}
                    orderMemberList={orderMemberList}
                    disabled={isSubmitting || !!submitStatus}
                    showLabel={showLabel}
                />

                <DocumentAccessEmailCheckbox
                    sendAccessEmail={_.get(documentData, 'sendAccessEmail', false)}
                    setSendAccessEmail={setDocumentSendAccessEmail}
                    documentId={_.get(documentData, 'id')}
                    disabled={isSubmitting || !hasUserAccess}
                />

                <Stack direction="row-reverse" alignItems="center" sx={{ width: '80px' }}>
                    {submitStatus === 'submitting' && <CircularProgress size={24} />}

                    {submitStatus === 'success' && (
                        <IconButton
                            color="primary"
                            disabled={true}
                            size="small"
                            sx={{
                                color: (theme) => `${theme.palette.primary.main} !important`,
                            }}
                        >
                            <DoneIcon />
                        </IconButton>
                    )}

                    {submitStatus === 'error' && (
                        <IconButton
                            color="error"
                            disabled={true}
                            size="small"
                            sx={{
                                color: (theme) => `${theme.palette.error.main} !important`,
                            }}
                        >
                            <ErrorIcon />
                        </IconButton>
                    )}

                    {!isSubmitting && !submitStatus && (
                        <IconButton onClick={() => handleClear(documentData.id)} disabled={isSubmitting} size="small">
                            <CloseIcon />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
}

DocumentUploadAreaItem.propTypes = {
    documentData: PropTypes.object.isRequired, // TODO describe shape of object - document, documentType, id
    setDocumentType: PropTypes.func.isRequired,
    setDocumentAccess: PropTypes.func.isRequired,
    setDocumentName: PropTypes.func.isRequired,
    setDocumentSendAccessEmail: PropTypes.func.isRequired,
    orderMemberList: PropTypes.array.isRequired,
    handleClear: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    submitStatusMap: PropTypes.object.isRequired, // TODO describe shape of object - id: status (success, error, submitting)
    showLabel: PropTypes.bool,
};

export default DocumentUploadAreaItem;
