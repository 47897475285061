import { Box, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import LienActionList from './LienActionList';
import LienDetailsCore from './LienDetailsCore';
import LienMemberList from './LienMemberList';
import LienNoteList from './LienNoteList';
import LienPayoff from './LienPayoff';
import LienTriggerList from './LienTriggerList';

function LienDetails({ lien, order }) {
    // TODO look into including documents section once we solidify document association logic
    return (
        <Stack spacing={3}>
            <LienDetailsCore lien={lien} />

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <Stack direction="column" spacing={3}>
                            <LienActionList lien={lien} order={order} />

                            <LienTriggerList lien={lien} order={order} />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <Stack direction="column" spacing={3}>
                            <LienMemberList lien={lien} order={order} />

                            {/* View lien documents */}

                            <LienNoteList lien={lien} order={order} />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <LienPayoff lien={lien} order={order} />
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
}

LienDetails.propTypes = {
    lien: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
};

export default LienDetails;
