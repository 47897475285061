import AddTaskIcon from '@mui/icons-material/AddTask';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { addBusinessDays, format, subBusinessDays } from 'date-fns';
import _ from 'lodash';

import { ACTION_STATUS, ACTION_TERMINAL_STATUSES } from 'helpers/constants';


export function determineActionStatusColor(action_status) {
    if (action_status === ACTION_STATUS.complete) {
        return 'success';
    } else if (action_status === ACTION_STATUS.canceled) {
        return 'warning';
    } else {
        return 'default';
    }
};


export function determineActionIsTerminal(action_status) {
    return _.includes(ACTION_TERMINAL_STATUSES, action_status);
}


export function determineActionHasAdditionalData(action) {
    return !_.isEmpty(action.additional_data_schema.json_schema);
}


export function determineActionIcon(action) {
    if (action.status === ACTION_STATUS.complete) {
        return <CheckCircleIcon />;
    } else if (action.status === ACTION_STATUS.canceled) {
        return <NotInterestedIcon />;
    } else if (determineActionHasAdditionalData(action)) {
        return <AddTaskIcon />;
    } else {
        return <TaskAltIcon />;
    }
}


export function generateDateSeedValue(seedValue, sourceEntityMap) {
    /*
        "ui:seed": {
            "type": "date",
            "source": "parcel",
            "path": "additional_data.hoa_eta",
            "base": "today",
            "offset": 1,
        }

        base options:
            * today

        response format:
            string iso date or null
    */

    // Generate default seed date
    const baseDate = new Date();
    let seedDate = null;
    if (seedValue.offset && seedValue.offset > 0) {
        seedDate = addBusinessDays(baseDate, seedValue.offset);
    } else if (seedValue.offset && seedValue.offset < 0) {
        seedDate = subBusinessDays(baseDate, seedValue.offset);
    }

    // Override seed date if source exists and is not null
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceData = _.get(sourceEntity, seedValue.path);
        if (sourceData) {
            return format(sourceData, 'yyyy-MM-dd');
        }
    }

    return seedDate ? format(seedDate, 'yyyy-MM-dd') : null;
};

export function generateDateTimeSeedValue(seedValue, sourceEntityMap) {
    /*
        "ui:seed": {
            "type": "date-time",
            "source": "parcel",
            "path": "additional_data.seller_transfer_time",
        }

        response format:
            string iso datetime or null
    */

    // Override seed date if source exists and is not null
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceDateTime = _.get(sourceEntity, seedValue.path);
        if (sourceDateTime) {
            // Note: we don't need to format as the found date should already be in ison format string
            return sourceDateTime;
        }
    }

    return null;
};

export function generateNumberSeedValue(seedValue, sourceEntityMap) {
    /*
        "ui:seed": {
            "type": "number",
            "source": "parcel",
            "path": "additional_data.hoa_period",
            "default": 10,
        }
    */
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceData = _.get(sourceEntity, seedValue.path);
        if (sourceData) {
            return sourceData;
        }
    }

    return seedValue.default || null;
};

export function generateAddressSeedValue(seedValue, sourceEntityMap) {
    /*
        "ui:seed": {
            "type": "address",
            "source": "parcel",
            "path": "additional_data.hoa_period",
        }
    */
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceData = _.get(sourceEntity, seedValue.path);
        if (sourceData) {
            return sourceData;
        }
    }

    return null;
};

export function generateStringSeedValue(seedValue, sourceEntityMap) {
    /*
        "ui:seed": {
            "type": "string",
            "source": "parcel",
            "path": "additional_data.hoa_period",
        }
    */
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceData = _.get(sourceEntity, seedValue.path);
        if (sourceData) {
            return sourceData;
        }
    }

    return null;
};

export function generateProfileSeedValue(seedValue, sourceEntityMap) {
    /*
        "ui:seed": {
            "type": "profile",
            "source": "user",
            "path": "",
        }
    */
    const source = seedValue.source;

    if (source) {
        const sourceEntity = sourceEntityMap[source];

        const path = _.get(seedValue, 'path');
        // Allow empty path to return the entire source entity
        const sourceData = !!path ? _.get(sourceEntity, path) : sourceEntity;
        if (sourceData) {
            return sourceData;
        }
    }

    return null;
};

export function generateContactSeedValue(seedValue, sourceEntityMap) {
    /*
        "ui:seed": {
            "type": "contact",
            "source": "user",
            "path": "contact",
        }
    */
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceData = _.get(sourceEntity, seedValue.path);
        if (sourceData) {
            return sourceData;
        }
    }

    return null;
};

export function generateSeedValue(seedValue, sourceEntityMap) {
    const seedValueType = seedValue.type;
    // TODO enum all cases
    switch (seedValueType) {
        case 'date':
            return generateDateSeedValue(seedValue, sourceEntityMap);
        case 'date-time':
            return generateDateTimeSeedValue(seedValue, sourceEntityMap);
        case 'number':
            return generateNumberSeedValue(seedValue, sourceEntityMap);
        case 'address':
            return generateAddressSeedValue(seedValue, sourceEntityMap);
        case 'profile':
            return generateProfileSeedValue(seedValue, sourceEntityMap);
        case 'contact':
            return generateContactSeedValue(seedValue, sourceEntityMap);
        case 'string':
            return generateStringSeedValue(seedValue, sourceEntityMap);
        default:
            return null;
    }
};