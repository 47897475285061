import { Box, Chip, Divider, Paper, Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useGetOrderMembersQuery } from 'api/order';
import { getOrderMemberName } from 'components/Order/Members/utils';
import OrderTransactionChip from 'components/Order/components/OrderTransactionChip';
import {
    ORDER_ROLE_TYPE,
    PARCEL_TYPE,
    SIGNING_LOCATION_NAME_MAP,
    SIGNING_METHOD,
    TRANSACTION_ORDER_TYPE,
    TRANSACTION_TYPE,
} from 'helpers/constants';
import { findOrderParcelByType } from 'helpers/utils';
import OutstandingActionList from './OutstandingActionList';

function FundingOutstandingActionSection({ order }) {
    const [fundingParcel, setFundingParcel] = useState();
    const [closingParcel, setClosingParcel] = useState();

    useEffect(() => {
        setFundingParcel(findOrderParcelByType(order, PARCEL_TYPE.funding));
        setClosingParcel(findOrderParcelByType(order, PARCEL_TYPE.closing));
    }, [order]);

    return (
        <Stack
            spacing={1}
            sx={{
                padding: (theme) => theme.spacing(3),
            }}
        >
            <Typography variant="overline" display="block" align="left" gutterBottom>
                Outstanding
            </Typography>

            <OutstandingActionList order={order} closingParcel={closingParcel} fundingParcel={fundingParcel} />
        </Stack>
    );
}

function OrderRoleSectionMember({ member }) {
    const [name, setName] = useState('');

    useEffect(() => {
        setName(getOrderMemberName(member));
    }, [member]);

    return (
        <Typography variant="body1" display="block">
            {name}
        </Typography>
    );
}

function LenderSection({ order, members, loading }) {
    const [lenderMembers, setLenderMembers] = useState([]);

    useEffect(() => {
        const newLenderMembers = [];

        _.forEach(members, (member) => {
            if (member.role === ORDER_ROLE_TYPE.lender) {
                newLenderMembers.push(member);
            }
        });

        setLenderMembers(newLenderMembers);
    }, [members]);

    return (
        <Stack
            direction="column"
            spacing={1}
            sx={{
                flex: '1 1 0',
                width: 0,
            }}
        >
            <Box
                sx={{
                    backgroundColor: '#DCB57E',
                    padding: (theme) => theme.spacing(1, 2),
                    textAlign: 'center',
                }}
            >
                <Typography
                    variant="overline"
                    sx={{
                        fontWeight: '600',
                        color: (theme) => theme.palette.primary.contrastText,
                    }}
                >
                    {_.startCase(ORDER_ROLE_TYPE.lender)}
                </Typography>
            </Box>
            <Stack
                spacing={3}
                sx={{
                    padding: 3,
                }}
            >
                <Stack spacing={1}>
                    {_.map(lenderMembers, (member, index) => {
                        if (index < 2) {
                            return <OrderRoleSectionMember member={member} key={member.id} />;
                        }

                        return null;
                    })}
                    {lenderMembers.length > 2 && (
                        <Typography variant="body1" display="block">
                            ...({lenderMembers.length - 2})
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
}

function OrderRoleSection({ order, orderRoleType, members, loading }) {
    const [roleMembers, setRoleMembers] = useState([]);
    const [roleAgents, setRoleAgents] = useState([]);

    useEffect(() => {
        const newRoleMembers = [];
        const newRoleAgents = [];

        _.forEach(members, (member) => {
            if (member.party !== orderRoleType) {
                return;
            }

            if (member.role === orderRoleType) {
                // NOTE: party can also be role
                // ex: buyer party can include buyer role or other roles associated with party
                if (member.on_title) {
                    newRoleMembers.push(member);
                }
            } else if (_.includes([ORDER_ROLE_TYPE.listing_agent, ORDER_ROLE_TYPE.selling_agent], member.role)) {
                newRoleAgents.push(member);
            }
        });

        setRoleMembers(newRoleMembers);
        setRoleAgents(newRoleAgents);
    }, [members]);

    const closingParcel = findOrderParcelByType(order, `closing_${orderRoleType}`);
    if (!closingParcel) {
        return null;
    }

    const parcelAdditionalData = closingParcel.additional_data;
    const linkUrl = `/order/${order.id}/parcel/${closingParcel.id}`;
    const signingMethod = parcelAdditionalData.signing_method;
    const includeLocation = !!parcelAdditionalData.signing_location;
    const locationText = includeLocation ? SIGNING_LOCATION_NAME_MAP[parcelAdditionalData.signing_location] : '';
    const timeText = parcelAdditionalData.signing_time && format(new Date(parcelAdditionalData.signing_time), 'M/d p');
    const orderRoleColor =
        orderRoleType === ORDER_ROLE_TYPE.buyer
            ? '#4579A8'
            : orderRoleType === ORDER_ROLE_TYPE.seller
            ? '#89599F'
            : '#222';

    return (
        <Stack
            direction="column"
            spacing={1}
            sx={{
                flex: '1 1 0',
                width: 0,
            }}
        >
            <Box
                sx={{
                    backgroundColor: orderRoleColor,
                    padding: (theme) => theme.spacing(1, 2),
                    textAlign: 'center',
                }}
            >
                <Typography
                    variant="overline"
                    sx={{
                        fontWeight: '600',
                        color: (theme) => theme.palette.primary.contrastText,
                    }}
                >
                    {_.startCase(orderRoleType)}
                </Typography>
            </Box>
            <Stack
                spacing={3}
                sx={{
                    padding: 3,
                }}
            >
                <Stack spacing={1}>
                    {_.map(roleMembers, (member, index) => {
                        if (index < 2) {
                            return <OrderRoleSectionMember member={member} key={member.id} />;
                        }

                        return null;
                    })}
                    {roleMembers.length > 2 && (
                        <Typography variant="body1" display="block">
                            ...({roleMembers.length - 2})
                        </Typography>
                    )}
                </Stack>

                <Stack spacing={1}>
                    {roleAgents.length > 0 && (
                        <Typography variant="overline" display="block">
                            {_.startCase(roleAgents[0].role)}
                        </Typography>
                    )}
                    {_.map(roleAgents, (member) => (
                        <OrderRoleSectionMember member={member} key={member.id} />
                    ))}
                </Stack>

                {signingMethod && (
                    <Link
                        to={linkUrl}
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        <Box
                            sx={{
                                background: (theme) => theme.palette.info.light,
                                borderLeft: (theme) => `solid 4px ${theme.palette.info.main}`,
                                padding: (theme) => theme.spacing(1, 2),
                                borderRadius: '4px',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                            >
                                {signingMethod === SIGNING_METHOD.in_house
                                    ? locationText
                                    : _.startCase(parcelAdditionalData.signing_method)}

                                {timeText && ` @ ${timeText}`}
                            </Typography>
                        </Box>
                    </Link>
                )}
            </Stack>
        </Stack>
    );
}

function FundingListGridItemOrder(props) {
    const { order } = props;

    const { data: members, isError: membersError, isLoading: membersLoading } = useGetOrderMembersQuery(order.id);

    return (
        <Paper
            elevation={2}
            sx={{
                width: '100%',
                maxWidth: '560px',
                padding: '0',
            }}
        >
            <Stack
                spacing={0}
                sx={{
                    padding: (theme) => theme.spacing(3),
                }}
            >
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <Link
                        to={`/order/${order.id}`}
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 600,
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {order.qualia_order_number}
                        </Typography>
                    </Link>

                    <Stack sx={{ maxWidth: '300px' }}>
                        <Typography variant="subtitle1" align="right">
                            {order.property_address.street}
                            {order.property_address.street_additional && ` ${order.property_address.street_additional}`}
                        </Typography>
                        <Typography variant="subtitle1" align="right">
                            {order.property_address.city}, {order.property_address.state}{' '}
                            {order.property_address.zipcode}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <OrderTransactionChip transactionType={order.transaction_type} size="small" />

                    {order.order_type !== TRANSACTION_ORDER_TYPE[order.transaction_type].residential && (
                        <Chip label={_.startCase(order.order_type)} size="small" />
                    )}

                    <Typography
                        variant="subtitle2"
                        sx={{
                            marginLeft: 'auto !important',
                        }}
                    >
                        Close Date: {format(parseISO(order.close_date), 'M/d')}
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction="row" spacing={0} divider={<Divider orientation="vertical" flexItem />}>
                {order.transaction_type === TRANSACTION_TYPE.purchase && (
                    <OrderRoleSection
                        order={order}
                        orderRoleType={ORDER_ROLE_TYPE.buyer}
                        members={members}
                        loading={membersLoading}
                    />
                )}

                {order.transaction_type === TRANSACTION_TYPE.purchase && (
                    <OrderRoleSection
                        order={order}
                        orderRoleType={ORDER_ROLE_TYPE.seller}
                        members={members}
                        loading={membersLoading}
                    />
                )}

                {order.transaction_type === TRANSACTION_TYPE.refinance && (
                    <OrderRoleSection
                        order={order}
                        orderRoleType={ORDER_ROLE_TYPE.borrower}
                        members={members}
                        loading={membersLoading}
                    />
                )}

                {order.transaction_type === TRANSACTION_TYPE.refinance && (
                    <LenderSection order={order} members={members} loading={membersLoading} />
                )}
            </Stack>

            <Divider orientation="horizontal" flexItem />

            <FundingOutstandingActionSection order={order} />
        </Paper>
    );
}

FundingListGridItemOrder.propTypes = {
    order: PropTypes.object.isRequired,
};

export default FundingListGridItemOrder;
