import { Box, MenuItem, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { ENTITY_TYPE } from '../../../helpers/constants';
import { Button, FilterTextField as TextField } from '../../common/styled';

const ALLOWED_TAG_ENTITY_TYPES = [ENTITY_TYPE.parcel, ENTITY_TYPE.lien, ENTITY_TYPE.payoff];

function TagForm({ order, tag, setTag }) {
    const [entityType, setEntityType] = useState(tag?.entity_type || '');
    const [entityId, setEntityId] = useState(tag?.entity_id || '');
    const [orderEntityTypeOptions, setOrderEntityTypeOptions] = useState([]);

    useEffect(() => {
        if (entityType && order) {
            let entityTypeOptions = [];

            if (entityType === ENTITY_TYPE.parcel) {
                entityTypeOptions = _.map(order.parcels, (parcel) => {
                    return {
                        entity: parcel,
                        label: parcel.name,
                        value: parcel.id,
                    };
                });
            } else if (entityType === ENTITY_TYPE.lien) {
                entityTypeOptions = _.map(order.liens, (lien) => {
                    return {
                        entity: lien,
                        label: `${_.startCase(lien.type)} Lien`,
                        value: lien.id,
                    };
                });
            } else if (entityType === ENTITY_TYPE.payoff) {
                entityTypeOptions = _.map(order.payoffs, (payoff) => {
                    const payoffLien = _.find(order.liens, (l) => l.id === payoff.lien_id);
                    return {
                        entity: payoff,
                        label: `${_.startCase(payoffLien.type)} Payoff`,
                        value: payoff.id,
                    };
                });
            }
            // TODO - add documents

            setOrderEntityTypeOptions(entityTypeOptions);
        } else {
            setOrderEntityTypeOptions([]);
        }
    }, [entityType, order]);

    const changeEntityType = (newEntityType) => {
        setEntityType(newEntityType);
        setEntityId('');
    };

    const handleSubmit = () => {
        if (entityType && entityId) {
            setTag({
                entity_type: entityType,
                entity_id: entityId,
            });
        } else {
            setTag(null);
        }
    };

    return (
        <Stack spacing={3} sx={{ width: '100%' }}>
            <Typography variant="h5">Associate Note With Entity</Typography>

            <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
                <TextField
                    label="Entity Type"
                    value={entityType}
                    onChange={(e) => changeEntityType(e.target.value)}
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    select
                >
                    {_.map(ALLOWED_TAG_ENTITY_TYPES, (allowedEntityType) => {
                        return (
                            <MenuItem key={allowedEntityType} value={allowedEntityType}>
                                {_.startCase(allowedEntityType)}
                            </MenuItem>
                        );
                    })}
                </TextField>

                <TextField
                    label="Entity"
                    value={entityId}
                    onChange={(e) => setEntityId(e.target.value)}
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    select
                    disabled={!entityType}
                >
                    {_.map(orderEntityTypeOptions, (entityTypeOption) => {
                        return (
                            <MenuItem key={entityTypeOption.value} value={entityTypeOption.value}>
                                {entityTypeOption.label}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Stack>

            <Box sx={{ textAlign: 'right' }}>
                <Button
                    variant="contained"
                    disableElevation
                    disabled={!entityType || !entityId}
                    onClick={handleSubmit}
                    sx={{ minWidth: '80px' }}
                >
                    Tag
                </Button>
            </Box>
        </Stack>
    );
}

TagForm.propTypes = {
    order: PropTypes.object,
    tag: PropTypes.object,
    setTag: PropTypes.func.isRequired,
};

export default TagForm;
