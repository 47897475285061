import LinkIcon from '@mui/icons-material/Link';
import { IconButton, Link as MUILink, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import { useGetOrderQuery } from 'api/order';
import ActionStatusChip from 'components/Action/ActionStatusChip';
import { DataGrid } from 'components/common/styled';
import { findParcelInOrder, formatISODate } from 'helpers/utils';
import ActionColumn from './ActionColumn';
import OrderMemberColumn from './OrderMemberColumn';

export const ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE = {
    orderId: 'order_id',
    parcelId: 'parcel_id',
    actionId: 'action_id',
    orderMemberId: 'order_member_id',

    actionType: 'type',
    actionStatus: 'status',
    actionData: 'action_data',
    actionDatetime: 'updated_datetime',
};

const OrderColumn = ({ orderId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/order/${orderId}`}
        >
            {_.get(order, 'qualia_order_number', '')}
        </MUILink>
    );
};

const ParcelColumn = ({ orderId, parcelId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    if (!order) {
        return <span />;
    }
    const parcel = findParcelInOrder(order, parcelId);

    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/order/${orderId}/parcel/${parcelId}`}
        >
            {_.get(parcel, 'name', '')}
        </MUILink>
    );
};

const generateAssignedActionListColumns = (hideColumns, setLinkedId) => {
    return [
        {
            field: ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.orderId,
            headerName: 'Order',
            minWidth: 160,
            hide: _.includes(hideColumns, ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.orderId),
            renderCell: (params) => <OrderColumn orderId={params.value} />,
        },

        {
            field: ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.actionStatus,
            headerName: 'Status',
            minWidth: 80,
            width: 120,
            hide: _.includes(hideColumns, ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.actionStatus),
            renderCell: (params) => <ActionStatusChip status={params.value} size="small" />,
        },

        {
            field: ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.actionType,
            headerName: 'Type',
            minWidth: 240,
            flex: 1,
            hide: _.includes(hideColumns, ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.actionType),
            renderCell: (params) => (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body1" color="text.primary">
                        {params.row.friendly_name}
                    </Typography>

                    {params.row.linked_id && (
                        <Tooltip title="Show Linked" placement="bottom" enterDelay={300}>
                            <IconButton size="small" onClick={() => setLinkedId(params.row.linked_id)}>
                                <LinkIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            ),
        },

        {
            field: ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.orderMemberId,
            headerName: 'Assigned',
            minWidth: 160,
            flex: 1,
            hide: _.includes(hideColumns, ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.orderMemberId),
            renderCell: (params) => <OrderMemberColumn orderId={params.row.order_id} memberId={params.value} />,
        },

        {
            field: ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.actionDatetime,
            headerName: 'Date/Time',
            minWidth: 80,
            width: 160,
            hide: _.includes(hideColumns, ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.actionDatetime),
            renderCell: (params) => (
                <Typography variant="body2" color="text.secondary">
                    {formatISODate(params.value, 'P p')}
                </Typography>
            ),
        },

        {
            field: ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.actionData,
            headerName: '',
            minWidth: 120,
            flex: 2,
            align: 'right',
            hide: _.includes(hideColumns, ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.actionData),
            renderCell: (params) => <ActionColumn orderId={params.row.order_id} action={params.row} />,
        },
    ];
};

function AssignedActionListGrid({ assignedActions, loading, hideColumns = [], refetch, setLinkedId }) {
    const [pageSize, setPageSize] = useState(10);

    const assignedActionListColumns = useMemo(() => generateAssignedActionListColumns(hideColumns, setLinkedId), [
        hideColumns,
        setLinkedId,
    ]);

    return (
        <DataGrid
            columns={assignedActionListColumns}
            rows={assignedActions}
            loading={loading}
            refetch={refetch}
            getRowId={(row) => row.id}
            density="comfortable"
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'updated_datetime', sort: 'desc' }],
                },
            }}
            pagination
            autoHeight
            disableColumnMenu
            disableColumnResize
            disableSelectionOnClick
        />
    );
}

AssignedActionListGrid.propTypes = {
    assignedActions: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    hideColumns: PropTypes.arrayOf(PropTypes.string),
    refetch: PropTypes.func,
    setLinkedId: PropTypes.func,
};

export default AssignedActionListGrid;
