import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useSendActionNotificationMutation } from 'api/action';
import { useGetOrderMemberQuery } from 'api/order';
import ActionButton from 'components/Action/ActionButton';
import ActionTriggers from 'components/Action/ActionTriggers';
import AdditionalDataPopover from 'components/common/AdditionalDataPopover';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { getOrderMemberName } from 'components/Order/Members/utils';
import { ACTION_STATUS, ACTION_TERMINAL_STATUSES } from 'helpers/constants';

const ActionEmail = ({ orderId, action }) => {
    const [sendActionNotification, { isLoading }] = useSendActionNotificationMutation();
    const { data: orderMember } = useGetOrderMemberQuery({ orderId, memberId: action.order_member_id });
    const [confirmActionNotification, setConfirmActionNotification] = useState(false);

    const handleSendEmail = () => {
        // console.log('fake send notification');
        sendActionNotification({ actionId: action.id });
    };

    if (!action || !orderMember) {
        return null;
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ position: 'relative' }}>
            <Tooltip title="Send Notification" placement="bottom" enterDelay={300}>
                <IconButton onClick={() => setConfirmActionNotification(true)} disabled={!action.order_member_id}>
                    <ForwardToInboxIcon />
                </IconButton>
            </Tooltip>

            <ConfirmDialog
                open={confirmActionNotification}
                title="Send Notification"
                subTitle={_.get(action, 'friendly_name')}
                body={
                    <Box>
                        <Typography component="span" color="text.secondary">
                            Are you sure you want to send a notification to{' '}
                        </Typography>

                        <Typography component="span" color="text.primary" fontWeight="500">
                            {getOrderMemberName(orderMember)}
                        </Typography>

                        <Typography component="span" color="text.secondary">
                            ?
                        </Typography>
                    </Box>
                }
                handleConfirm={() => handleSendEmail()}
                handleClose={() => setConfirmActionNotification(false)}
                loading={isLoading}
            />
        </Stack>
    );
};

const ActionColumn = ({ orderId, action }) => {
    if (!action) {
        return false;
    }

    if (!_.includes(ACTION_TERMINAL_STATUSES, action.status)) {
        return (
            <Stack direction="row" alignItems="center" spacing={2}>
                <ActionButton action={action} orderId={orderId} />
                <ActionEmail action={action} orderId={orderId} />
                <ActionTriggers action={action} orderId={orderId} />
            </Stack>
        );
    }

    if (action.status === ACTION_STATUS.canceled) {
        return false;
    }

    return (
        <Box>
            <AdditionalDataPopover
                additionalData={action.additional_data}
                additionalDataSchema={action.additional_data_schema}
            />
        </Box>
    );
};

ActionColumn.propTypes = {
    orderId: PropTypes.string.isRequired,
    action: PropTypes.object.isRequired,
};

export default ActionColumn;
