import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { rootApi } from './root';

export const NOTE_TAG = _.toUpper(ENTITY_TYPE.note);

export const noteApi = rootApi.injectEndpoints({
    reducerPath: 'noteApi',
    endpoints: (builder) => ({
        getOrderNotes: builder.query({
            query: (orderId) => `order/${orderId}/notes`,
            providesTags: [NOTE_TAG],
        }),
        createNote: builder.mutation({
            query: ({orderId, noteData}) => ({
                url: `order/${orderId}/notes`,
                method: 'POST',
                body: noteData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [NOTE_TAG],
        }),
        updateNote: builder.mutation({
            query: ({orderId, noteId, noteData}) => ({
                url: `order/${orderId}/note/${noteId}`,
                method: 'PUT',
                body: noteData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [NOTE_TAG],
        }),
        deleteNote: builder.mutation({
            query: ({orderId, noteId}) => ({
                url: `order/${orderId}/note/${noteId}`,
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [NOTE_TAG],
        }),
    }),
    overrideExisting: false,
});

export function useGetOrderNotesByEntityIdListQuery({orderId, entityIdList}) {
    const { useGetOrderNotesQuery } = noteApi;
    return useGetOrderNotesQuery(orderId, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((orderNote) => _.includes(entityIdList, orderNote.entity_id)),
            error,
            isLoading
        }),
    });
}


export const { useGetOrderNotesQuery, useCreateNoteMutation, useUpdateNoteMutation, useDeleteNoteMutation } = noteApi