import AssignmentIcon from '@mui/icons-material/Assignment';
import LinkIcon from '@mui/icons-material/Link';
import { Box, IconButton, Link as MUILink, Paper, Popover, Stack, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import { useGetAdminUserQuery } from 'api/adminUser';
import { useGetOrderDocumentQuery } from 'api/document';
import { useGetOrderMemberQuery, useGetOrderQuery } from 'api/order';
import { useGetUserQuery } from 'api/user';
import { DataGrid } from 'components/common/styled';
import { ENTITY_TYPE } from 'helpers/constants';
import {
    findActionInOrder,
    findLienInOrder,
    findParcelInOrder,
    findPayoffInOrder,
    formatDocumentType,
    formatISODate,
} from 'helpers/utils';

export const EVENT_LOG_LIST_GRID_COLUMN_TYPE = {
    orderId: 'order_id',
    entityType: 'entity_type',
    entityId: 'entity_id',

    eventType: 'event_type',
    eventData: 'event_data',
    eventDatetime: 'event_datetime',

    userId: 'user_id',
    adminId: 'admin_id',
};

const OrderColumn = ({ orderId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    if (!order) {
        return <span />;
    }

    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/order/${orderId}`}
        >
            {_.get(order, 'qualia_order_number', '')}
        </MUILink>
    );
};

const ParcelColumn = ({ orderId, parcelId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    if (!order) {
        return <span />;
    }
    const parcel = findParcelInOrder(order, parcelId);
    if (!parcel) {
        return <span />;
    }

    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/order/${orderId}/parcel/${parcelId}`}
        >
            {_.get(parcel, 'name', '')}
        </MUILink>
    );
};

const ActionColumn = ({ orderId, actionId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    if (!order) {
        return <span />;
    }

    const action = findActionInOrder(order, actionId);
    if (!action) {
        return <span />;
    }

    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/order/${orderId}/parcel/${action.parcel_id}`}
        >
            {_.get(action, 'friendly_name', '')}
        </MUILink>
    );
};

const LienColumn = ({ orderId, lienId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    if (!order) {
        return <span />;
    }
    const lien = findLienInOrder(order, lienId);
    if (!lien) {
        return <span />;
    }

    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/order/${orderId}/title/${lienId}`}
        >
            {_.get(lien, 'name', '')}
        </MUILink>
    );
};

const PayoffColumn = ({ orderId, payoffId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    if (!order) {
        return <span />;
    }

    const payoff = findPayoffInOrder(order, payoffId);
    if (!payoff) {
        return <span />;
    }

    const lien = findLienInOrder(order, payoff.lien_id);
    if (!lien) {
        return <span />;
    }

    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/order/${orderId}/title/${payoff.lien_id}`}
        >
            {_.get(lien, 'name', '')} Payoff
        </MUILink>
    );
};

const DocumentColumn = ({ orderId, documentId }) => {
    const { data: document, isError: documentError, isLoading: documentLoading } = useGetOrderDocumentQuery({
        orderId,
        documentId,
    });
    if (!document) {
        return <span />;
    }

    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/order/${orderId}/documents`}
        >
            {formatDocumentType(document.type)}
        </MUILink>
    );
};

const OrderMemberColumn = ({ orderId, memberId }) => {
    const { data: orderMember, isError: documentError, isLoading: documentLoading } = useGetOrderMemberQuery({
        orderId,
        memberId,
    });
    if (!orderMember) {
        return <span />;
    }

    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/order/${orderId}/members`}
        >
            {_.get(orderMember, 'member.name', '')}
        </MUILink>
    );
};

const EntityColumn = ({ orderId, entityType, entityId }) => {
    switch (entityType) {
        case ENTITY_TYPE.order:
            return <OrderColumn orderId={orderId} />;
        case ENTITY_TYPE.parcel:
            return <ParcelColumn orderId={orderId} parcelId={entityId} />;
        case ENTITY_TYPE.action:
            return <ActionColumn orderId={orderId} actionId={entityId} />;
        case ENTITY_TYPE.lien:
            return <LienColumn orderId={orderId} lienId={entityId} />;
        case ENTITY_TYPE.payoff:
            return <PayoffColumn orderId={orderId} payoffId={entityId} />;
        case ENTITY_TYPE.document:
            return <DocumentColumn orderId={orderId} documentId={entityId} />;
        case ENTITY_TYPE.order_member:
            return <OrderMemberColumn orderId={orderId} memberId={entityId} />;
        default:
            return <span>{entityId}</span>;
    }
};

const AdminColumn = ({ adminId }) => {
    const { data: adminUser, isError: adminUserError, isLoading: adminUserLoading } = useGetAdminUserQuery(adminId);
    if (!adminUser) {
        return <span />;
    }

    return (
        <span>
            {adminUser.first_name} {adminUser.last_name}
        </span>
    );
};

const UserColumn = ({ userId }) => {
    const { data: user, isError: userError, isLoading: userLoading } = useGetUserQuery(userId);
    if (!user) {
        return <span />;
    }

    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/contact/user/${userId}`}
        >
            {_.get(user, 'name', '')}
        </MUILink>
    );
};

const InitiatedByColumn = ({ adminId, userId }) => {
    if (adminId) {
        return <AdminColumn adminId={adminId} />;
    } else if (userId) {
        return <UserColumn userId={userId} />;
    } else {
        return <span>System</span>;
    }
};

const EventDataColumn = ({ eventData }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'event-data-popper' : undefined;

    if (!eventData) {
        return null;
    }

    return (
        <Box>
            <Tooltip title="View Event Data" placement="bottom" enterDelay={300}>
                <span style={{ display: 'inline-block' }}>
                    <IconButton onClick={handleClick} size="small">
                        <AssignmentIcon fontSize="small" />
                    </IconButton>
                </span>
            </Tooltip>

            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper sx={{ p: 3, minWidth: '240px', maxWidth: '480px' }}>
                    <Box>
                        <pre>{JSON.stringify(eventData, null, 4)}</pre>
                    </Box>
                </Paper>
            </Popover>
        </Box>
    );
};

const generateEventLogListColumns = (hideColumns, setTransactionId) => {
    return [
        {
            field: EVENT_LOG_LIST_GRID_COLUMN_TYPE.orderId,
            headerName: 'Order',
            minWidth: 160,
            hide: _.includes(hideColumns, EVENT_LOG_LIST_GRID_COLUMN_TYPE.orderId),
            renderCell: (params) => <OrderColumn orderId={params.value} />,
        },
        {
            field: EVENT_LOG_LIST_GRID_COLUMN_TYPE.eventType,
            headerName: _.startCase(EVENT_LOG_LIST_GRID_COLUMN_TYPE.eventType),
            minWidth: 80,
            hide: _.includes(hideColumns, EVENT_LOG_LIST_GRID_COLUMN_TYPE.eventType),
            valueFormatter: ({ value }) => _.startCase(value),
        },
        {
            field: EVENT_LOG_LIST_GRID_COLUMN_TYPE.entityType,
            headerName: _.startCase(EVENT_LOG_LIST_GRID_COLUMN_TYPE.entityType),
            minWidth: 160,
            hide: _.includes(hideColumns, EVENT_LOG_LIST_GRID_COLUMN_TYPE.entityType),
            valueFormatter: ({ value }) => _.startCase(value),
        },
        {
            field: EVENT_LOG_LIST_GRID_COLUMN_TYPE.entityId,
            headerName: 'Entity',
            minWidth: 240,
            hide: _.includes(hideColumns, EVENT_LOG_LIST_GRID_COLUMN_TYPE.entityId),
            renderCell: (params) => (
                <EntityColumn
                    orderId={params.row.order_id}
                    entityType={params.row.entity_type}
                    entityId={params.value}
                />
            ),
        },
        {
            field: EVENT_LOG_LIST_GRID_COLUMN_TYPE.eventDatetime,
            headerName: 'Date/Time',
            type: 'date',
            minWidth: 150,
            hide: _.includes(hideColumns, EVENT_LOG_LIST_GRID_COLUMN_TYPE.eventDatetime),
            valueFormatter: ({ value }) => formatISODate(value, 'P p'),
        },
        {
            field: EVENT_LOG_LIST_GRID_COLUMN_TYPE.adminId,
            headerName: 'Initiated By',
            minWidth: 160,
            hide: _.includes(hideColumns, EVENT_LOG_LIST_GRID_COLUMN_TYPE.adminId), // TODO also check userId
            renderCell: (params) => <InitiatedByColumn adminId={params.value} userId={params.row.user_id} />,
        },
        {
            field: EVENT_LOG_LIST_GRID_COLUMN_TYPE.eventData,
            headerName: '',
            minWidth: 68,
            flex: 1,
            align: 'right',
            hide: _.includes(hideColumns, EVENT_LOG_LIST_GRID_COLUMN_TYPE.eventData),
            renderCell: (params) => (
                <Stack direction="row" alignItems="center" spacing={1}>
                    {params.row.transaction_id && (
                        <Tooltip title="Show Transaction" placement="bottom" enterDelay={300}>
                            <IconButton size="small" onClick={() => setTransactionId(params.row.transaction_id)}>
                                <LinkIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}

                    <EventDataColumn eventData={params.value} />
                </Stack>
            ),
        },
    ];
};

function EventLogListDataGrid({ eventLogs, loading, hideColumns = [], setTransactionId }) {
    const [pageSize, setPageSize] = useState(10);

    const eventLogListColumns = useMemo(() => generateEventLogListColumns(hideColumns, setTransactionId), [
        hideColumns,
        setTransactionId,
    ]);

    return (
        <DataGrid
            columns={eventLogListColumns}
            rows={eventLogs}
            loading={loading}
            getRowId={(row) => row.id}
            autoHeight
            disableColumnMenu
            disableColumnResize
            disableSelectionOnClick
            density="comfortable"
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50, 100]}
            pagination
        />
    );
}

EventLogListDataGrid.propTypes = {
    eventLogs: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    hideColumns: PropTypes.arrayOf(PropTypes.string),
    setTransactionId: PropTypes.func,
};

export default EventLogListDataGrid;
