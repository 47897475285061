export const generateBackgroundColorSvg = (lightColor = '#458da8', darkColor = '#275060') => {
    return `
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="1920"
            height="720"
            viewBox="0 0 1920 720"
        >
            <defs>
                <radialGradient id="a" cx="0.5" cy="0.5" r="0.5" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="${lightColor}" />
                    <stop offset="1" stop-color="${darkColor}" />
                </radialGradient>
                <linearGradient id="b" x1="-0.037" y1="0.163" x2="0.797" y2="1.464" xlink:href="#a" />
            </defs>
            <g transform="translate(0 -80)">
                <rect width="1920" height="720" transform="translate(0 80)" fill="url(#a)" />
                <path
                    d="M14662.918,17732.732l-36.757-189.105,1221.354-237.4V17533.4l-1025.494,199.336Zm-735.4-505.242,656.5-127.611,42.55,218.906-699.051,135.881Zm1022.292-214.756h897.708v20.471l-861.19,167.4Z"
                    transform="translate(-13927.515 -16932.732)"
                    opacity="0.34"
                    fill="url(#b)"
                />
            </g>
        </svg>
    `;
};

export const generateBackgroundColorSvgUrl = (lightColor = '#458da8', darkColor = '#275060') => {
    const svg = generateBackgroundColorSvg(lightColor, darkColor);
    return `data:image/svg+xml;base64,${window.btoa(svg)}`;
};

const BackgroundColorSvg = ({ lightColor = '#458da8', darkColor = '#275060' }) => {
    // TODO remove as we are only currently using generate functions instead of component itself
    return false;
};

export default BackgroundColorSvg;
