import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { TRANSACTION_ORDER_TYPE, WORKFLOW_TRANSACTION_MAP } from '../../helpers/constants';
import AddressForm from '../common/form/address/AddressForm';
import OrderTypeField from '../common/form/order/OrderTypeField';
import QualiaOrderNumberField from '../common/form/order/QualiaOrderNumberField';
import TransactionTypeField from '../common/form/order/TransactionTypeField';

function TransactionForm(props) {
    const { fields, error, errorMessage } = props;
    const {
        isDuplicate,
        setIsDuplicate,
        qualiaOrderNumber,
        setQualiaOrderNumber,
        transactionType,
        setTransactionType,
        titleWorkflow,
        setTitleWorkflow,
        orderType,
        setOrderType,
        address,
        setAddress,
    } = fields;

    const handleTransactionChange = (newTransactionType) => {
        setTransactionType(newTransactionType);

        // React to transaction type change
        setTitleWorkflow(WORKFLOW_TRANSACTION_MAP[newTransactionType]);
        setOrderType(TRANSACTION_ORDER_TYPE[newTransactionType].residential);
    };

    return (
        <Stack spacing={3}>
            <QualiaOrderNumberField
                qualiaOrderNumber={qualiaOrderNumber}
                setQualiaOrderNumber={setQualiaOrderNumber}
                isDuplicate={isDuplicate}
                setIsDuplicate={setIsDuplicate}
            />

            <TransactionTypeField transactionType={transactionType} setTransactionType={handleTransactionChange} />

            <OrderTypeField orderType={orderType} setOrderType={setOrderType} transactionType={transactionType} />

            <AddressForm addressData={address} setAddressData={setAddress} showLabel={true} required={true} />
        </Stack>
    );
}

TransactionForm.propTypes = {
    fields: PropTypes.shape({
        isDuplicate: PropTypes.bool.isRequired,
        setIsDuplicate: PropTypes.func.isRequired,
        qualiaOrderNumber: PropTypes.string.isRequired,
        setQualiaOrderNumber: PropTypes.func.isRequired,
        transactionType: PropTypes.string.isRequired,
        setTransactionType: PropTypes.func.isRequired,
        titleWorkflow: PropTypes.string.isRequired,
        setTitleWorkflow: PropTypes.func.isRequired,
        orderType: PropTypes.string.isRequired,
        setOrderType: PropTypes.func.isRequired,
        address: PropTypes.shape({
            street: PropTypes.string.isRequired,
            street_additional: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            zipcode: PropTypes.string.isRequired,
            country: PropTypes.string.isRequired,
        }).isRequired,
        setAddress: PropTypes.func.isRequired,
    }).isRequired,
    disableFields: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.object.isRequired,
};

export default TransactionForm;
