import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Link,
    ListSubheader,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import {
    useGetLegalEntityMemberQuery,
    useGetLegalEntityQuery,
    useUpdateLegalEntityMutation,
} from '../../../../api/legalEntity';
import {
    useDeleteOrderMemberMutation,
    useGetOrderMemberQuery,
    useUpdateOrderMemberMutation,
} from '../../../../api/order';
import { useGetUserQuery } from '../../../../api/user';
import {
    ENTITY_TYPE,
    ORDER_ROLE_TYPE,
    TRANSACTION_TYPE_PARTY_MAP,
    USER_RELATIONSHIP_TYPE_INVERSE_MAP,
} from '../../../../helpers/constants';
import {
    copyToClipboard,
    formatAddress,
    formatISODate,
    formatPhone,
    generateUpdateObject,
} from '../../../../helpers/utils';
import AddLegalEntityMember from '../../../LegalEntity/components/AddMember';
import EditLegalEntityMember from '../../../LegalEntity/components/EditMember';
import LegalEntityForm from '../../../LegalEntity/components/LegalEntityForm';
import AddUserRelationship from '../../../User/components/AddRelationship';
import EditUserRelationship from '../../../User/components/EditRelationship';
import EditUser from '../../../User/components/EditUser';
import BaseDialog from '../../../common/BaseDialog';
import { Button, LoadingButton } from '../../../common/styled';

const RENDER_MODE = {
    view: 'view',
    edit: 'edit',
    addRelationship: 'addRelationship',
    editRelationship: 'editRelationship',
    addMember: 'addMember',
    editMember: 'editMember',
};

const GridItemLabel = ({ label }) => {
    return (
        <Grid
            item
            xs={4}
            sx={{
                textAlign: 'right',
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    fontWeight: '400',
                    color: 'text.secondary',
                }}
            >
                {label}
            </Typography>
        </Grid>
    );
};

const GridItemContent = ({ content }) => {
    const [copyText, setCopyText] = useState('Copy');

    const handleCopy = (event, text) => {
        event.stopPropagation();
        event.preventDefault();
        copyToClipboard(text);
        setCopyText('Copied!');
    };
    return (
        <Grid item xs={8}>
            <div>
                <Tooltip title={copyText} onClose={() => setCopyText('Copy')} placement="right" enterDelay={300}>
                    <Typography variant="body1" component="span" onClick={(event) => handleCopy(event, content)}>
                        {content || '—'}
                    </Typography>
                </Tooltip>
            </div>
        </Grid>
    );
};

const RelationshipList = ({ relationships, setRenderMode, handleOpenEditRelationship }) => {
    return (
        <Grid item xs={8}>
            <Stack spacing={1}>
                {_.map(relationships, (relationship) => {
                    return (
                        <Stack key={relationship.id} direction="row" alignItems="center" spacing={1}>
                            <Link
                                onClick={() => handleOpenEditRelationship(relationship)}
                                underline="hover"
                                sx={{
                                    color: (theme) => theme.palette.text.link,
                                    cursor: 'pointer',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: 'inherit',
                                    }}
                                >
                                    {relationship.related_user.name}
                                </Typography>
                            </Link>
                            <Typography variant="body1">
                                (
                                {_.startCase(
                                    _.get(
                                        USER_RELATIONSHIP_TYPE_INVERSE_MAP,
                                        relationship.relation,
                                        relationship.relation
                                    )
                                )}
                                )
                            </Typography>
                        </Stack>
                    );
                })}
                <div>
                    <IconButton
                        onClick={() => setRenderMode(RENDER_MODE.addRelationship)}
                        size="small"
                        sx={{
                            marginLeft: '-11px',
                            marginTop: '-5px',
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </div>
            </Stack>
        </Grid>
    );
};

const MemberList = ({ members, setRenderMode, handleOpenEditMember }) => {
    return (
        <Grid item xs={8}>
            <Stack spacing={1}>
                {_.map(members, (member) => {
                    return (
                        <Stack key={member.id} direction="row" alignItems="center" spacing={1}>
                            <Link
                                onClick={() => handleOpenEditMember(member)}
                                underline="hover"
                                sx={{
                                    color: (theme) => theme.palette.text.link,
                                    cursor: 'pointer',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: 'inherit',
                                    }}
                                >
                                    {member.user.name}
                                </Typography>
                            </Link>
                            <Typography variant="body1">
                                ({_.startCase(member.role)}
                                {member.signor && ', Signor'})
                            </Typography>
                        </Stack>
                    );
                })}
                <div>
                    <IconButton
                        onClick={() => setRenderMode(RENDER_MODE.addMember)}
                        size="small"
                        sx={{
                            marginLeft: '-11px',
                            marginTop: '-5px',
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </div>
            </Stack>
        </Grid>
    );
};

const UserOrderMemberDetails = ({ order, orderMemberData, isSavingMember, setOrderMemberData }) => {
    const [showTitleCheckbox, setShowTitleCheckbox] = useState(false);
    const [showLoanCheckbox, setShowLoanCheckbox] = useState(false);

    useEffect(() => {
        if (_.includes([ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.borrower], orderMemberData.role)) {
            setShowTitleCheckbox(true);
            setShowLoanCheckbox(true);
        } else if (orderMemberData.role === ORDER_ROLE_TYPE.seller) {
            setShowTitleCheckbox(true);
            setShowLoanCheckbox(false);
        } else {
            setShowTitleCheckbox(false);
            setShowLoanCheckbox(false);
        }
    }, [orderMemberData.role]);

    const handleCheckboxChange = ({ target }) => {
        setOrderMemberData({
            [target.name]: target.checked,
        });
    };

    const handleRoleChange = ({ target }) => {
        const role = target.value;
        let new_party = null;

        // Find what party this role belongs to
        _.forEach(TRANSACTION_TYPE_PARTY_MAP[order.transaction_type], (roles, party) => {
            if (_.includes(roles, role)) {
                new_party = party;
            }
        });

        const flagMap = {
            on_title: orderMemberData.on_title,
            on_loan: orderMemberData.on_loan,
        };

        if (_.includes([ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.borrower], role)) {
            // Don't change any flags
        } else if (role === ORDER_ROLE_TYPE.seller) {
            // Force on_loan to false
            flagMap.on_loan = false;
        } else {
            // Force all flags to false
            flagMap.on_title = false;
            flagMap.on_loan = false;
        }

        setOrderMemberData({
            role,
            party: new_party,
            ...flagMap,
        });
    };

    return (
        <Grid
            container
            // direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            columnSpacing={4}
            sx={{
                width: '100%',
            }}
        >
            <GridItemLabel label="Party" />
            <Grid item xs={8}>
                <TextField
                    name="role"
                    label="Role"
                    value={orderMemberData.role}
                    onChange={handleRoleChange}
                    style={{ minWidth: '150px' }}
                    size="small"
                    select
                    required
                    disabled={isSavingMember}
                >
                    {_.map(TRANSACTION_TYPE_PARTY_MAP[order.transaction_type], (roles, party) => [
                        <ListSubheader key={party}>{_.startCase(party)}</ListSubheader>,
                        roles.map((role) => (
                            <MenuItem key={role} value={role}>
                                {_.startCase(role)}
                            </MenuItem>
                        )),
                    ])}
                </TextField>
            </Grid>

            <GridItemLabel label="Order" />
            <Grid item xs={8}>
                <Stack direction="row" spacing={2}>
                    <FormControlLabel
                        label="Title"
                        control={
                            <Checkbox
                                name="on_title"
                                checked={orderMemberData.on_title}
                                onChange={handleCheckboxChange}
                            />
                        }
                        disabled={!showTitleCheckbox || isSavingMember}
                    />

                    <FormControlLabel
                        label="Loan"
                        control={
                            <Checkbox
                                name="on_loan"
                                checked={orderMemberData.on_loan}
                                onChange={handleCheckboxChange}
                            />
                        }
                        disabled={!showLoanCheckbox || isSavingMember}
                    />
                </Stack>
            </Grid>
        </Grid>
    );
};

const EditLegalEntity = ({ legalEntity, setRenderMode, isLegalEntityLoading }) => {
    const [legalEntityCopy, setLegalEntityCopy] = useState({
        ...legalEntity,
        contact: {
            email: legalEntity.contact?.email || '',
            phone: legalEntity.contact?.phone || '',
        },
        address: {
            street: _.get(legalEntity, 'address.street', ''),
            street_additional: _.get(legalEntity, 'address.street_additional', ''),
            city: _.get(legalEntity, 'address.city', ''),
            state: _.get(legalEntity, 'address.state', ''),
            zipcode: _.get(legalEntity, 'address.zipcode', ''),
            country: _.get(legalEntity, 'address.country', ''),
        },
    });
    const [updatePayload, setUpdatePayload] = useState();
    const [updateLegalEntity, { isLoading: isLegalEntitySaving }] = useUpdateLegalEntityMutation();

    const handleEditLegalEntity = (event) => {
        event.preventDefault();

        handleUpdateLegalEntity();
    };

    const formatLegalEntityUpdatePayload = () => {
        const formattedUpdateData = { ...updatePayload };

        _.forEach(updatePayload, (value, key) => {
            if (key === 'address') {
                // Verify address has been added/changed/removed
                const validOriginalAddress = !!legalEntity.address;
                const validCurrentAddress = !!legalEntityCopy.address.street;

                if (validOriginalAddress && !validCurrentAddress) {
                    // remove address
                    formattedUpdateData['address'] = null; // TODO verify this will remove
                } else if (!validOriginalAddress && validCurrentAddress) {
                    // add new address
                    formattedUpdateData['address'] = {
                        ...legalEntityCopy.address,
                        street_additional: legalEntityCopy.address.street_additional || null,
                    };
                } else if (validOriginalAddress && validCurrentAddress) {
                    // update address
                    formattedUpdateData['address'] = {
                        ...legalEntityCopy.address,
                        street_additional: legalEntityCopy.address.street_additional || null,
                    };
                } else if (!validOriginalAddress && !validCurrentAddress) {
                    // do nothing
                }
            } else if (key === 'contact') {
                // Verify contact has been added/changed/removed
                const validCurrentEmail = !!legalEntityCopy.contact.email;
                const validCurrentPhone = !!legalEntityCopy.contact.phone;

                if (validCurrentEmail || validCurrentPhone) {
                    // update existing contact details
                    formattedUpdateData['contact'] = {
                        ...(validCurrentEmail && { email: legalEntityCopy.contact.email }),
                        ...(validCurrentPhone && { phone: legalEntityCopy.contact.phone }),
                    };
                } else if (!validCurrentEmail && !validCurrentPhone) {
                    formattedUpdateData['contact'] = {
                        email: null,
                        phone: null,
                    };
                }
            } else if (value === '') {
                // Convert empty strigns to null
                formattedUpdateData[key] = null;
            }
        });

        return { legalEntityId: legalEntity.id, legalEntityData: formattedUpdateData };
    };

    const handleUpdateLegalEntity = async () => {
        const { data } = await updateLegalEntity(formatLegalEntityUpdatePayload());
        if (data) {
            setRenderMode(RENDER_MODE.view);
        } else {
            console.error('Error updating legal entity');
        }
    };

    const generateUpdatePayload = (originalLegalEntity, updatedLegalEntity) => {
        // Compare original user data with local form state
        const baseUpdateObject = generateUpdateObject(
            originalLegalEntity,
            updatedLegalEntity,
            _.keys(originalLegalEntity)
        );

        // Analyzing address and contact separately as these are nested object
        // Using userCopy to generate key list as base user contact/address may be null
        baseUpdateObject['address'] = generateUpdateObject(
            originalLegalEntity.address,
            updatedLegalEntity.address,
            _.keys(updatedLegalEntity.address)
        );
        baseUpdateObject['contact'] = generateUpdateObject(
            originalLegalEntity.contact,
            updatedLegalEntity.contact,
            _.keys(updatedLegalEntity.contact)
        );

        // json stringify will automatically remove undefined values from objects
        // however, we want to do that preimptively to calculate if an update should be submitted
        _.forEach(baseUpdateObject, (value, key) => {
            if (value === undefined) {
                delete baseUpdateObject[key];
            } else if (_.includes(['address', 'contact'], key)) {
                _.forEach(value, (childValue, childKey) => {
                    // Remove undefined values from nested objects
                    if (childValue === undefined) {
                        delete baseUpdateObject[key][childKey];
                    } else if (
                        childValue === '' &&
                        (originalLegalEntity[key] === null || originalLegalEntity[key][childKey] === null)
                    ) {
                        // Empty string is used in userCopy to properly mark an input as controlled while still being empty
                        // However, the backend expects null for empty values
                        // Therefore, we need to remove all instances where an empty string is used in userCopy when the original value was null
                        delete baseUpdateObject[key][childKey];
                    }
                });

                // If the address or contact object is empty, we need to remove it from the update payload
                if (_.isEmpty(baseUpdateObject[key])) {
                    delete baseUpdateObject[key];
                }
            }
        });

        return baseUpdateObject;
    };

    const handleUpdatePayload = (originalLegalEntity, updatedLegalEntity) => {
        const newUpdatePayload = generateUpdatePayload(originalLegalEntity, updatedLegalEntity);
        setUpdatePayload(newUpdatePayload);
    };

    const debouncedHandleUpdatePayload = useMemo(
        () =>
            _.debounce(
                (originalLegalEntity, updatedLegalEntity) =>
                    handleUpdatePayload(originalLegalEntity, updatedLegalEntity),
                300
            ),
        []
    );

    useEffect(() => {
        if (legalEntityCopy) {
            debouncedHandleUpdatePayload(legalEntity, legalEntityCopy);
        }

        // Stop the invocation of the debounced function
        // after unmounting
        return () => {
            debouncedHandleUpdatePayload.cancel();
        };
    }, [legalEntity, legalEntityCopy]);

    return (
        <form onSubmit={handleEditLegalEntity}>
            <Stack spacing={4} sx={{ width: '100%' }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h4">{legalEntity.name}</Typography>
                    <ChevronRightIcon fontSize="small" />
                    <Typography variant="h6">Edit</Typography>
                </Stack>

                <LegalEntityForm legalEntityData={legalEntityCopy} setLegalEntityData={setLegalEntityCopy} />

                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} sx={{ width: '100%' }}>
                    <Button variant="text" color="default" onClick={() => setRenderMode(RENDER_MODE.view)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={isLegalEntityLoading || isLegalEntitySaving}
                        disabled={_.keys(updatePayload).length === 0}
                        disableElevation
                        sx={{
                            minWidth: '160px',
                        }}
                    >
                        Save
                    </LoadingButton>
                </Stack>
            </Stack>
        </form>
    );
};

const ViewLegalEntity = ({
    order,
    member,
    legalEntity,
    handleMemberUpdate,
    handleMemberDelete,
    isSavingMember,
    isDeletingMember,
    setRenderMode,
    handleOpenEditMember,
}) => {
    return (
        <Stack spacing={4} sx={{ width: '100%' }}>
            <Typography variant="h4">{legalEntity.name}</Typography>

            <Grid
                container
                // direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                columnSpacing={4}
                sx={{
                    width: '100%',
                }}
            >
                <GridItemLabel label="Legal Type" />
                <GridItemContent content={_.startCase(legalEntity.type)} />

                <GridItemLabel label="Commercial Type" />
                <GridItemContent content={_.startCase(legalEntity.commercial_type)} />

                <GridItemLabel label="State of Incorporation" />
                <GridItemContent content={_.startCase(legalEntity.state_of_incorporation)} />

                <GridItemLabel label="Tax Id" />
                <GridItemContent content={legalEntity.tax_id} />

                <GridItemLabel label="Phone" />
                <GridItemContent content={legalEntity.contact?.phone ? formatPhone(legalEntity.contact.phone) : null} />

                <GridItemLabel label="Email" />
                <GridItemContent content={legalEntity.contact?.email} />

                <GridItemLabel label="Address" />
                <GridItemContent content={legalEntity.address?.street ? formatAddress(legalEntity.address) : null} />

                <GridItemLabel label="Members" />
                <MemberList
                    members={legalEntity.members}
                    setRenderMode={setRenderMode}
                    handleOpenEditMember={handleOpenEditMember}
                />
            </Grid>

            <UserOrderMemberDetails
                order={order}
                orderMemberData={member}
                setOrderMemberData={handleMemberUpdate}
                isSavingMember={isSavingMember}
            />

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                sx={{ width: '100%' }}
            >
                <Button
                    color="error"
                    onClick={() => handleMemberDelete()}
                    disabled={isSavingMember || isDeletingMember}
                >
                    Remove From Order
                </Button>
                <Button
                    variant="outlined"
                    color="default"
                    onClick={() => setRenderMode(RENDER_MODE.edit)}
                    disabled={isSavingMember || isDeletingMember}
                    sx={{
                        minWidth: '80px',
                    }}
                >
                    Edit
                </Button>
            </Stack>
        </Stack>
    );
};

const LegalEntityMode = ({
    order,
    member,
    handleClose,
    isSavingMember,
    handleMemberUpdate,
    isDeletingMember,
    handleMemberDelete,
}) => {
    const { data: legalEntity, isError: legalEntityError, isLoading: legalEntityLoading } = useGetLegalEntityQuery(
        member.member_id
    );

    const [renderMode, setRenderMode] = useState(RENDER_MODE.view);
    const [viewLegalEntityMember, setViewLegalEntityMember] = useState();

    const handleOpenEditMember = (legalEntityMember) => {
        setViewLegalEntityMember(legalEntityMember);
        setRenderMode(RENDER_MODE.editMember);
    };

    const handleCloseEditMember = () => {
        setRenderMode(RENDER_MODE.view);
        setViewLegalEntityMember();
    };

    if (legalEntityLoading) {
        return <div>Loading...</div>;
    }

    if (legalEntityError) {
        return <div>Error loading legal entity {member.member_id}</div>;
    }

    if (renderMode === RENDER_MODE.addMember) {
        return (
            <AddLegalEntityMember
                legalEntity={legalEntity}
                handleCancel={() => setRenderMode(RENDER_MODE.view)}
                handleComplete={() => setRenderMode(RENDER_MODE.view)}
            />
        );
    }

    if (renderMode === RENDER_MODE.editMember) {
        return (
            <EditLegalEntityMember
                legalEntity={legalEntity}
                member={viewLegalEntityMember}
                handleCancel={handleCloseEditMember}
                handleComplete={handleCloseEditMember}
            />
        );
    }

    if (renderMode === RENDER_MODE.edit) {
        return (
            <EditLegalEntity
                member={member}
                legalEntity={legalEntity}
                setRenderMode={setRenderMode}
                isLegalEntityLoading={legalEntityLoading}
            />
        );
    }

    return (
        <ViewLegalEntity
            order={order}
            member={member}
            legalEntity={legalEntity}
            handleMemberUpdate={handleMemberUpdate}
            handleMemberDelete={handleMemberDelete}
            isSavingMember={isSavingMember}
            isDeletingMember={isDeletingMember}
            setRenderMode={setRenderMode}
            isLegalEntityLoading={legalEntityLoading}
            handleOpenEditMember={handleOpenEditMember}
        />
    );
};

// TODO replace with ViewUser component from src/components/User/components/ViewUser.jsx
const ViewUser = ({
    order,
    member,
    user,
    handleMemberUpdate,
    handleMemberDelete,
    isSavingMember,
    isDeletingMember,
    setRenderMode,
    handleOpenEditRelationship,
}) => {
    return (
        <Stack spacing={4}>
            <Typography variant="h4">{user.name}</Typography>

            <Grid
                container
                // direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                columnSpacing={4}
                sx={{
                    marginBottom: '48px',
                    width: '100%',
                }}
            >
                <GridItemLabel label="Date of Birth" />
                <GridItemContent content={user.date_of_birth ? formatISODate(user.date_of_birth) : null} />

                <GridItemLabel label="Gender" />
                <GridItemContent content={_.startCase(user.gender)} />

                <GridItemLabel label="Marital Status" />
                <GridItemContent content={_.startCase(user.marital_status)} />

                <GridItemLabel label="Phone" />
                <GridItemContent content={user.contact?.phone ? formatPhone(user.contact.phone) : null} />

                <GridItemLabel label="Email" />
                <GridItemContent content={user.contact?.email} />

                <GridItemLabel label="Address" />
                <GridItemContent content={user.address?.street ? formatAddress(user.address) : null} />

                <GridItemLabel label="Relationships" />
                <RelationshipList
                    relationships={user.relationships}
                    setRenderMode={setRenderMode}
                    handleOpenEditRelationship={handleOpenEditRelationship}
                />
            </Grid>

            <UserOrderMemberDetails
                order={order}
                orderMemberData={member}
                setOrderMemberData={handleMemberUpdate}
                isSavingMember={isSavingMember}
            />

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                sx={{ width: '100%' }}
            >
                <Button
                    color="error"
                    onClick={() => handleMemberDelete()}
                    disabled={isSavingMember || isDeletingMember}
                >
                    Remove From Order
                </Button>
                <Button
                    variant="outlined"
                    color="default"
                    onClick={() => setRenderMode(RENDER_MODE.edit)}
                    disabled={isSavingMember || isDeletingMember}
                    sx={{
                        minWidth: '80px',
                    }}
                >
                    Edit
                </Button>
            </Stack>
        </Stack>
    );
};

const UserMode = ({
    order,
    userId,
    member,
    handleClose,
    isSavingMember,
    isDeletingMember,
    handleMemberUpdate,
    handleMemberDelete,
}) => {
    const { data: user, isError: userError, isLoading: userLoading } = useGetUserQuery(userId);

    const [renderMode, setRenderMode] = useState(RENDER_MODE.view);
    const [relationship, setRelationship] = useState();

    const handleOpenEditRelationship = (userRelationship) => {
        setRelationship(userRelationship);
        setRenderMode(RENDER_MODE.editRelationship);
    };

    const handleCloseEditRelationship = () => {
        setRenderMode(RENDER_MODE.view);
        setRelationship();
    };

    if (userLoading) {
        return <div>Loading...</div>;
    }

    if (userError) {
        return <div>Error loading user {member.member_id}</div>;
    }

    if (renderMode === RENDER_MODE.addRelationship) {
        return (
            <AddUserRelationship
                user={user}
                handleCancel={() => setRenderMode(RENDER_MODE.view)}
                handleComplete={() => setRenderMode(RENDER_MODE.view)}
            />
        );
    }

    if (renderMode === RENDER_MODE.editRelationship) {
        return (
            <EditUserRelationship
                user={user}
                relationship={relationship}
                handleCancel={handleCloseEditRelationship}
                handleComplete={handleCloseEditRelationship}
            />
        );
    }

    if (renderMode === RENDER_MODE.edit) {
        return (
            <Stack spacing={4}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h4">{user.name}</Typography>
                    <ChevronRightIcon fontSize="small" />
                    <Typography variant="h6">Edit</Typography>
                </Stack>

                <EditUser
                    user={user}
                    handleCancel={() => setRenderMode(RENDER_MODE.view)}
                    handleComplete={() => setRenderMode(RENDER_MODE.view)}
                />
            </Stack>
        );
    }

    return (
        <ViewUser
            order={order}
            member={member}
            user={user}
            handleMemberUpdate={handleMemberUpdate}
            handleMemberDelete={handleMemberDelete}
            isSavingMember={isSavingMember}
            isDeletingMember={isDeletingMember}
            setRenderMode={setRenderMode}
            handleOpenEditRelationship={handleOpenEditRelationship}
        />
    );
};

const LegalEntityMemberMode = (props) => {
    const { member } = props;
    // Shallow wrapper of user mode
    // Only purpose is to load the legal entity member and pass its associated userId on to UserMode component
    const {
        data: legalEntityMember,
        isError: legalEntityMemberError,
        isLoading: legalEntityMemberLoading,
    } = useGetLegalEntityMemberQuery(member.member_id);

    if (legalEntityMemberLoading) {
        return <div>loading legal entity member</div>;
    }

    if (legalEntityMemberError) {
        return <div>error loading legal entity member</div>;
    }

    return <UserMode userId={legalEntityMember.user_id} {...props} />;
};

const EditMemberModal = ({ order, memberId, handleClose }) => {
    const { data: member, isError: memberError, isLoading: memberLoading } = useGetOrderMemberQuery(
        { orderId: order.id, memberId },
        {
            skip: !order || !memberId,
        }
    );
    const [updateOrderMember, { isLoading: updateMemberLoading }] = useUpdateOrderMemberMutation();
    const [deleteOrderMember, { isLoading: deleteMemberLoading }] = useDeleteOrderMemberMutation();

    const handleMemberUpdate = async (updatedMemberData) => {
        const updateMemberPayload = {
            orderId: order.id,
            memberId: memberId,
            memberData: updatedMemberData,
        };

        const { data } = await updateOrderMember(updateMemberPayload);
    };

    const handleMemberDelete = async () => {
        const deleteMemberPayload = {
            orderId: order.id,
            memberId: memberId,
        };

        const { data } = await deleteOrderMember(deleteMemberPayload);
        if (data) {
            handleClose();
        }
    };

    const handleSubmit = () => {
        handleClose();
    };

    return (
        <BaseDialog
            open={!!member}
            setOpen={() => handleClose(false)}
            DialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
            }}
        >
            {member?.member_type === ENTITY_TYPE.user && (
                <UserMode
                    order={order}
                    userId={member.member_id}
                    member={member}
                    handleClose={handleClose}
                    isSavingMember={updateMemberLoading}
                    isDeletingMember={deleteMemberLoading}
                    handleMemberUpdate={handleMemberUpdate}
                    handleMemberDelete={handleMemberDelete}
                />
            )}

            {member?.member_type === ENTITY_TYPE.legal_entity_member && (
                <LegalEntityMemberMode
                    order={order}
                    member={member}
                    handleClose={handleClose}
                    isSavingMember={updateMemberLoading}
                    isDeletingMember={deleteMemberLoading}
                    handleMemberUpdate={handleMemberUpdate}
                    handleMemberDelete={handleMemberDelete}
                />
            )}

            {member?.member_type === ENTITY_TYPE.legal_entity && (
                <LegalEntityMode
                    order={order}
                    member={member}
                    handleClose={handleClose}
                    isSavingMember={updateMemberLoading}
                    isDeletingMember={deleteMemberLoading}
                    handleMemberUpdate={handleMemberUpdate}
                    handleMemberDelete={handleMemberDelete}
                />
            )}
        </BaseDialog>
    );
};

EditMemberModal.propTypes = {
    memberId: PropTypes.string, // nullable so it acts as open switch
    handleClose: PropTypes.func.isRequired,
};

export default EditMemberModal;
