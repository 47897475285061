import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Divider, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetNotificationEventLogCountQuery, useGetNotificationEventLogsQuery } from '../../api/eventLog';
import { LoadingButton } from '../common/styled';
import NotificationList from './components/NotificationList';

const QUERY_LIMIT = 8;
const MAX_LIST_HEIGHT = 67 * QUERY_LIMIT + 53;

const Notification = ({ handleClose, unreadCount }) => {
    const navigate = useNavigate();
    // NOTE: not 'true' pagination, just increasing the total query limit by a set amount
    const [page, setPage] = useState(1);
    const limit = QUERY_LIMIT * page;

    const {
        data: notificationEventLogs,
        isError: notificationEventLogsError,
        isLoading: notificationEventLogsLoading,
        isFetching: notificationEventLogsFetching,
    } = useGetNotificationEventLogsQuery({ limit });

    const { data: notificationEventLogCount } = useGetNotificationEventLogCountQuery();

    const handleLoadMore = () => {
        setPage(page + 1);
    };

    const handleNavigation = (linkUrl) => {
        if (!linkUrl) {
            return;
        }

        navigate(linkUrl);

        if (handleClose) {
            handleClose();
        }
    };

    const showLoadMore =
        notificationEventLogCount && notificationEventLogCount > limit && !notificationEventLogsLoading;

    return (
        <Stack direction="column" spacing={0} sx={{ width: '100%' }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}
            >
                <Typography variant="h5">Notifications</Typography>

                <Box>
                    <IconButton edge="end" disabled>
                        <SettingsIcon />
                    </IconButton>
                </Box>
            </Stack>

            <Box>
                {notificationEventLogsFetching && <LinearProgress sx={{ mt: '-4px' }} />}
                <Divider />
            </Box>

            <Box sx={{ overflow: 'auto', flexGrow: 1, maxHeight: `${MAX_LIST_HEIGHT}px` }}>
                <NotificationList
                    notificationEventLogs={notificationEventLogs}
                    handleNavigation={handleNavigation}
                    unreadCount={unreadCount}
                />

                {showLoadMore && (
                    <Box sx={{ pt: 1, pb: 1, pl: 2, pr: 2, textAlign: 'center' }}>
                        <LoadingButton
                            loading={notificationEventLogsLoading}
                            onClick={handleLoadMore}
                            variant="text"
                            color="default"
                            sx={{
                                minWidth: '160px',
                            }}
                            disableElevation
                        >
                            View More
                        </LoadingButton>
                    </Box>
                )}
            </Box>
        </Stack>
    );
};

Notification.propTypes = {
    handleClose: PropTypes.func,
    unreadCount: PropTypes.number,
};

export default Notification;
