import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import PropTypes from 'prop-types';

import customFields from './fields';
import customWidgets from './widgets';

const AdditionalDataForm = ({
    additionalData,
    additionalDataSchema,
    handleChange,
    handleSubmit,
    readOnly = false,
    children,
}) => {
    const { json_schema: jsonSchema, ui_schema } = additionalDataSchema;

    const uiSchema = { ...ui_schema, 'ui:readonly': readOnly };

    return (
        <Form
            schema={jsonSchema}
            uiSchema={uiSchema}
            validator={validator}
            fields={customFields}
            widgets={customWidgets}
            formData={additionalData || {}}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onError={(e) => console.log('errors', e)}
            omitExtraData={true}
            // liveOmit={true}
            formContext={{
                customItem: true,
            }}
        >
            {children && children}
        </Form>
    );
};

AdditionalDataForm.propTypes = {
    additionalData: PropTypes.object.isRequired,
    additionalDataSchema: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

export default AdditionalDataForm;
