import { Chip, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';

const customDisplayMap = {
    [PARCEL_TYPE.balancing_home_warranty]: {
        [PARCEL_TYPE.home_warranty]: {
            order: [
                // "status",
                // "required",
                'credit',
            ],
            fields: {
                status: 'current_status.status',
                credit: 'additional_data.home_warranty_credit',
                required: 'additional_data.home_warranty_required',
            },
        },
    },
    [PARCEL_TYPE.balancing_taxes]: {
        [PARCEL_TYPE.tax_cert]: {
            order: ['received_date'],
            fields: {
                received_date: 'additional_data.tax_cert_received_date',
            },
        },
    },
    [PARCEL_TYPE.balancing_hoa]: {
        [PARCEL_TYPE.hoa]: {
            order: [
                // "required",
                'credit',
                'received_date',
            ],
            fields: {
                credit: 'additional_data.hoa_credit',
                received_date: 'additional_data.hoa_received_date',
                required: 'additional_data.hoa_required',
            },
        },
    },
};

function BalancingParcelCustomDisplay(props) {
    const { order, parcel } = props;

    const [customDisplayData, setCustomDisplayData] = useState({});

    useEffect(() => {
        const customParcelDisplay = customDisplayMap[parcel.type];
        const customParcelDisplayData = {};
        if (customParcelDisplay) {
            _.forEach(customParcelDisplay, (referenceParcelDisplay, referenceParcelType) => {
                // 'key' is reference parcel type
                // grab other parcel from order
                const referenceParcel = findOrderParcelByType(order, referenceParcelType);
                if (!referenceParcel) {
                    return;
                }

                _.forEach(referenceParcelDisplay.order, (fieldKey) => {
                    customParcelDisplayData[fieldKey] = _.get(referenceParcel, referenceParcelDisplay.fields[fieldKey]);
                });
            });

            // console.log(customParcelDisplayData);

            setCustomDisplayData(customParcelDisplayData);
        }
    }, [order, parcel]);

    if (!customDisplayMap[parcel.type] || customDisplayData.length === 0) {
        return null;
    }

    return (
        <Stack direction="row" spacing={1}>
            {_.map(customDisplayData, (displayDataValue, displayDataField) => {
                return (
                    <Chip
                        key={displayDataField}
                        label={`${_.startCase(displayDataField)}: ${displayDataValue}`}
                        variant="outlined"
                        size="small"
                        sx={{
                            borderRadius: (theme) => theme.spacing(0.5),
                        }}
                    />
                );
            })}
        </Stack>
    );
}

BalancingParcelCustomDisplay.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default BalancingParcelCustomDisplay;
