import PropTypes from 'prop-types';
import React from 'react';
import BaseDialog from '../../common/BaseDialog';

import EditMember from './EditMember';

const EditMemberModal = ({ legalEntity, member, handleClose }) => {
    return (
        <BaseDialog
            open={!!member}
            setOpen={() => handleClose()}
            DialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
            }}
        >
            {member && (
                <EditMember
                    legalEntity={legalEntity}
                    member={member}
                    handleCancel={() => handleClose()}
                    handleComplete={() => handleClose()}
                />
            )}
        </BaseDialog>
    );
};

EditMemberModal.propTypes = {
    legalEntity: PropTypes.object.isRequired,
    member: PropTypes.object, // not required as null value is used to close modal
    handleClose: PropTypes.func.isRequired,
};

export default EditMemberModal;
