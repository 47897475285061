import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import PauseIcon from '@mui/icons-material/Pause';
import { Avatar, Stack, Typography } from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { ORDER_STATUS } from 'helpers/constants';

const themeBackgroundColorMap = (colorString, theme) => {
    switch (colorString) {
        case 'success':
            return theme.palette.primary.main;
        case 'error':
            return theme.palette.error.main;
        case 'warning':
            return theme.palette.warning.main;
        case 'default':
            return theme.palette.background.default;
        default:
            return theme.palette.background.default;
    }
};

const themeTextColorMap = (colorString, theme) => {
    switch (colorString) {
        case 'success':
            return theme.palette.primary.contrastText;
        case 'error':
            return theme.palette.error.contrastText;
        case 'warning':
            return theme.palette.text.primary;
        case 'default':
            return theme.palette.text.primary;
        default:
            return theme.palette.text.primary;
    }
};

function ClosingAvatar({ status, closeDate, color = null }) {
    const [daysToClosing, setDaysToClosing] = useState(null);
    const [avatarColor, setAvatarColor] = useState(null);
    const [avatarIcon, setAvatarIcon] = useState(null);
    const [avatarHelperText, setAvatarHelperText] = useState('Days to Close');

    useEffect(() => {
        if (closeDate) {
            const now = new Date();
            const closeDateObj = parseISO(closeDate);
            setDaysToClosing(differenceInDays(closeDateObj, now));
        }
    }, [closeDate]);

    useEffect(() => {
        if (status === ORDER_STATUS.closed) {
            setAvatarColor('success');
            setAvatarIcon(<CheckIcon />);
            setAvatarHelperText('Closed');
        } else if (status === ORDER_STATUS.canceled) {
            setAvatarColor('error');
            setAvatarIcon(<ClearIcon />);
            setAvatarHelperText('Canceled');
        } else if (status === ORDER_STATUS.on_hold) {
            setAvatarColor('warning');
            setAvatarIcon(<PauseIcon />);
            setAvatarHelperText('On Hold');
        } else {
            // order still in progress
            if (daysToClosing <= 0) {
                // error/red
                setAvatarColor('error');
            } else if (daysToClosing <= 5) {
                // warning/yellow
                setAvatarColor('warning');
            } else {
                // grey
                setAvatarColor('default');
            }

            setAvatarIcon(null);
            setAvatarHelperText('Days to Close');
        }
    }, [status, daysToClosing]);

    // Maybe temp but essentially only show component this if the order is currently open
    if (status !== ORDER_STATUS.open) {
        return null;
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
                sx={{
                    backgroundColor: (theme) => themeBackgroundColorMap(avatarColor, theme),
                    color: (theme) => themeTextColorMap(avatarColor, theme),
                    height: (theme) => theme.spacing(4),
                    width: (theme) => theme.spacing(4),
                    fontSize: (theme) => theme.spacing(2),
                    fontWeight: 400,
                }}
            >
                {daysToClosing >= 0 ? daysToClosing : '0'}
                {/* {avatarIcon ? avatarIcon : daysToClosing} */}
            </Avatar>
            <Typography
                variant="body1"
                sx={{
                    fontWeight: 400,
                    color: (theme) => (color ? color : theme.palette.text.primary),
                }}
            >
                {avatarHelperText}
            </Typography>
        </Stack>
    );
}

ClosingAvatar.propTypes = {
    status: PropTypes.oneOf([..._.values(ORDER_STATUS)]).isRequired,
    closeDate: PropTypes.string.isRequired,
};

export default ClosingAvatar;
