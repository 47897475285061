import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { USDollar, formatAddress, formatISODate } from 'helpers/utils';

function getAdditionalDataItem(entity, propertyKey) {
    const propertyJsonSchema = _.get(entity, `additional_data_schema.json_schema.properties.${propertyKey}`, {});
    const propertyUISchema = _.get(entity, `additional_data_schema.ui_schema.${propertyKey}`, {});

    const formattedLabel = !!propertyJsonSchema.title ? propertyJsonSchema.title : _.startCase(propertyKey);

    const value = _.get(entity, `additional_data.${propertyKey}`, null);
    let formattedValue = '—';

    if (value !== null) {
        if (propertyJsonSchema.format === 'date') {
            formattedValue = formatISODate(value);
        } else if (propertyJsonSchema.format === 'date-time') {
            formattedValue = formatISODate(value, 'P p');
        } else if (!!propertyJsonSchema.oneOf) {
            // Find value title in oneOf
            _.forEach(propertyJsonSchema.oneOf, (enumOption) => {
                if (enumOption.const === value) {
                    formattedValue = enumOption.title;
                }
            });
        } else if (propertyUISchema['ui:format'] === 'USD') {
            formattedValue = USDollar.format(value);
        } else if (propertyUISchema['ui:format'] === 'days') {
            formattedValue = `${value} Days`;
        } else if (propertyUISchema['ui:field'] === 'AddressField') {
            formattedValue = formatAddress(value, true);
        } else {
            formattedValue = value;
        }
    } else {
        formattedValue = '—';
    }

    return {
        label: formattedLabel,
        value: formattedValue,
    };
}

function useAdditionalDataItem(entity, propertyKey) {
    const { label, value } = useMemo(() => getAdditionalDataItem(entity, propertyKey), [entity, propertyKey]);

    return { label, value };
}

useAdditionalDataItem.propTypes = {
    entity: PropTypes.object.isRequired,
    propertyKey: PropTypes.string.isRequired,
};

export default useAdditionalDataItem;
