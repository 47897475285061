import { TextField } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function QuickEditField({ name, value, onChange, selectOnFocus = true, ...rest }) {
    // NOTE: onChange is only fired after the user has finished editing the field by clicking away & the value itself has changed

    const [localValue, setLocalValue] = useState(value);
    const [localMask, setLocalMask] = useState(null);
    const [inputSize, setInputSize] = useState(value ? value.length : 10);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const handleLocalChange = ({ target }) => {
        setLocalValue(target.value);
        const maskValue = _.get(target, 'mask.value', null);
        setLocalMask(maskValue);

        // Match the input size to the number of characters in the masked value
        setInputSize(maskValue ? maskValue.length : target.value.length);
    };

    const handleBlur = (event) => {
        // Won't catch all cases (such as numerical string equality between 'xxx' and 'xxx.00'), but should be good enough for now
        if (localValue !== value) {
            onChange(name, localValue);
        }
    };

    const handleKeyPress = (event) => {
        // Blur input on enter key
        if (event.key === 'Enter') {
            event.preventDefault();
            event.target.blur();
        }
    };

    const handleSelectOnFocus = (event) => {
        // NOTE: timeout to get around various browser event issues
        const target = event.target;
        setTimeout(() => target.select(), 0);
    };

    return (
        <TextField
            name={name}
            label="" // Empty label to remove label from field
            onChange={handleLocalChange}
            onFocus={selectOnFocus ? handleSelectOnFocus : () => {}}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            value={localValue}
            sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0)',
                },
                ..._.get(rest, 'sx', {}),
            }}
            {...rest}
            InputProps={{
                ..._.get(rest, 'InputProps', {}),
                inputProps: {
                    ..._.get(rest, 'InputProps.inputProps', {}),
                    size: inputSize,
                },
            }}
        />
    );
}

QuickEditField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func, // NOTE: marked as not required because initial render in some parent components doesn't pass down onChange prop
};

export default QuickEditField;
