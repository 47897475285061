import { Switch, Tooltip } from '@mui/material';

import { useUpdateOrderMutation } from 'api/order';

function CloseDateToggle({ orderId, confirmedCloseDate }) {
    const [updateOrder, { isLoading: updateOrderLoading }] = useUpdateOrderMutation();

    const handleToggle = () => {
        submitOrderChange(!confirmedCloseDate);
    };

    const submitOrderChange = async (newConfirmedValue) => {
        const updateOrderPayload = {
            orderId: orderId,
            orderData: {
                confirmed_close_date: newConfirmedValue,
            },
        };

        const { data } = await updateOrder(updateOrderPayload);
        return data;
    };

    return (
        <Tooltip title={confirmedCloseDate ? 'Confirmed' : 'Tentative'} placement="bottom" enterDelay={300}>
            <Switch
                checked={confirmedCloseDate}
                onChange={() => handleToggle()}
                color="success"
                sx={{
                    '& .MuiSwitch-switchBase': {
                        '& .MuiSwitch-thumb': {
                            bgcolor: 'warning.main',

                            '&::before': {
                                content: "''",
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                left: 0,
                                top: 0,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundImage: (theme) =>
                                    `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                                        theme.palette.getContrastText(theme.palette.warning.main)
                                    )}" d="M2 15s0-6 6-6c4 0 4.5 3.5 7.5 3.5c4 0 4-3.5 4-3.5H22s0 6-6 6c-4 0-5.5-3.5-7.5-3.5c-4 0-4 3.5-4 3.5z" /></svg>')`,
                            },
                        },

                        '& + .MuiSwitch-track': {
                            bgcolor: 'warning.dark',
                        },

                        '&.Mui-checked': {
                            '& .MuiSwitch-thumb': {
                                bgcolor: 'success.main',

                                '&::before': {
                                    backgroundImage: (theme) =>
                                        `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                                            theme.palette.getContrastText(theme.palette.success.main)
                                        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                                },
                            },

                            '& + .MuiSwitch-track': {
                                bgcolor: 'success.dark',
                            },
                        },
                    },
                }}
            />
        </Tooltip>
    );
}

export default CloseDateToggle;
