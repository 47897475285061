import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Box, IconButton, Paper, Popover, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import AdditionalDataForm from './form/rjsf/AdditionalDataForm';

function AdditionalDataPopover({ additionalData, additionalDataSchema }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    if (!additionalData || !additionalDataSchema) {
        return null;
    }

    return (
        <Box>
            <Tooltip title="View Response" placement="bottom" enterDelay={300}>
                <IconButton onClick={handleClick}>
                    <AssignmentTurnedInIcon />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper sx={{ p: 3, minWidth: '240px', maxWidth: '480px' }}>
                    <AdditionalDataForm
                        additionalData={additionalData}
                        additionalDataSchema={additionalDataSchema}
                        handleChange={() => null}
                        handleSubmit={() => null}
                        readOnly={true}
                    >
                        <Box />
                    </AdditionalDataForm>
                </Paper>
            </Popover>
        </Box>
    );
}

AdditionalDataPopover.propTypes = {
    additionalData: PropTypes.object,
    additionalDataSchema: PropTypes.object,
};

export default AdditionalDataPopover;
