import { Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useCreateOrderMemberMutation } from '../../../../api/order';
import { useCreateUserMutation } from '../../../../api/user';
import { ENTITY_TYPE } from '../../../../helpers/constants';
import UserForm, { INITIAL_USER_STATE } from '../../../User/components/UserForm';
import { formatAddressPayload } from '../../../common/form/address/AddressForm';
import { formatContactPayload } from '../../../common/form/contact/ContactForm';
import { Button, LoadingButton } from '../../../common/styled';
import OrderMemberForm, { generateInitialOrderMemberState } from './OrderMemberForm';

const NewUserForm = ({ order, handleSubmit, seededRole, switchToSearch }) => {
    const [createUser, { isLoading: isUserLoading }] = useCreateUserMutation();
    const [createOrderMember, { isLoading: isOrderMemberLoading }] = useCreateOrderMemberMutation();
    const [userData, setUserData] = useState({ ...INITIAL_USER_STATE });
    const [orderMemberData, setOrderMemberData] = useState(generateInitialOrderMemberState(order, seededRole));

    const handleCreateUser = (event) => {
        event.preventDefault();

        createUserAndAddToOrder();
    };

    const formatUserPayload = () => {
        // Form user data payload that strips out any empty/null values
        const userPayload = {};
        _.forEach(userData, (value, key) => {
            // contact/address are nested objects that need to be handled explicitly
            if (key === 'contact') {
                userPayload[key] = formatContactPayload(value);
            } else if (key === 'address') {
                userPayload[key] = formatAddressPayload(value);
            } else if (!_.isNil(value) && value !== '') {
                userPayload[key] = value;
            }
        });

        return userPayload;
    };

    const createUserAndAddToOrder = async () => {
        const { data } = await createUser(formatUserPayload());
        if (data) {
            // Add user to order as a member
            const orderMember = {
                ...orderMemberData,
                member_id: data.user_id,
                member_type: ENTITY_TYPE.user,
                order_id: order.id,
            };
            const { data: createOrderMemberData } = await createOrderMember({
                orderId: order.id,
                memberData: orderMember,
            });
            if (createOrderMemberData) {
                handleSubmit();
            }
        } else {
            console.error('Error creating user');
        }
    };

    return (
        <form onSubmit={handleCreateUser}>
            <Stack spacing={4}>
                <Stack spacing={2}>
                    <Typography variant="sectionHeader">User Details</Typography>

                    <UserForm userData={userData} setUserData={setUserData} />
                </Stack>

                <OrderMemberForm
                    order={order}
                    orderMemberData={orderMemberData}
                    setOrderMemberData={setOrderMemberData}
                />

                <Box>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="flex-end"
                        sx={{ width: '100%' }}
                    >
                        <Button
                            variant="text"
                            color="default"
                            onClick={() => switchToSearch()}
                            disabled={isUserLoading || isOrderMemberLoading}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={isUserLoading || isOrderMemberLoading}
                            disableElevation
                            sx={{ minWidth: '160px' }}
                        >
                            Create
                        </LoadingButton>
                    </Stack>
                </Box>
            </Stack>
        </form>
    );
};

NewUserForm.propTypes = {
    order: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    switchToSearch: PropTypes.func.isRequired,
    seededRole: PropTypes.string,
};

export default NewUserForm;
