import { useMemo } from 'react';

import { useGetOrderAssignmentsQuery } from 'api/assignment';
import { useGetOrderQuery } from 'api/order';
import { PARCEL_TYPE } from 'helpers/constants';
import { findOrderParcelByType } from 'helpers/utils';

function useAssignmentListData(orderId) {
    const { data: order, isError: orderError, isLoading: orderLoading, isFetching: orderFetching } = useGetOrderQuery(
        orderId
    );

    // NOTE: the parcels here are hydrated with assignment member data
    const {
        data: assignmentContractParcels,
        isError: assignmentContractParcelsError,
        isLoading: assignmentContractParcelsLoading,
        isFetching: assignmentContractParcelsFetching,
    } = useGetOrderAssignmentsQuery(orderId);

    const assignmentMetaParcel = useMemo(() => {
        // Find the assignment meta parcel in the order
        if (!order) {
            return null;
        }

        return findOrderParcelByType(order, PARCEL_TYPE.assignment);
    }, [order]);

    const isError = orderError || assignmentContractParcelsError;
    const isLoading = orderLoading || assignmentContractParcelsLoading;
    const isFetching = orderFetching || assignmentContractParcelsFetching;

    return {
        order,
        assignmentMetaParcel,
        assignmentContractParcels,
        isError,
        isLoading,
        isFetching,
    };
}

export default useAssignmentListData;
