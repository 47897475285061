import { Breadcrumbs, Link as MUILink, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

function LienBreadcrumbs({ lien }) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <MUILink component={Link} underline="hover" color="inherit" to={`/order/${lien.order_id}/title`}>
                Title
            </MUILink>
            <Typography color="text.primary">{lien.name}</Typography>
        </Breadcrumbs>
    );
}

LienBreadcrumbs.propTypes = {
    lien: PropTypes.object.isRequired,
};

export default LienBreadcrumbs;
