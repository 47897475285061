import { Chip } from '@mui/material';
import { TRANSACTION_TYPE } from 'helpers/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';

function OrderTransactionChip({ transactionType, sx = {}, ...rest }) {
    return (
        <Chip
            label={_.startCase(transactionType)}
            sx={{
                backgroundColor: (theme) => theme.palette[transactionType].light,
                color: (theme) => theme.palette[transactionType].dark,
                borderRadius: (theme) => theme.spacing(0.5),
                ...sx,
            }}
            {...rest}
        />
    );
}

OrderTransactionChip.propTypes = {
    transactionType: PropTypes.oneOf([..._.values(TRANSACTION_TYPE)]).isRequired,
};

export default OrderTransactionChip;
