import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

import { Stack, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { ASSIGNMENT_MEMBER_TYPE } from 'helpers/constants';

const AssignmentIconEarnestMoney = ({ assignment }) => {
    // const hasEarnestMoneyAmount = assignment.additional_data.earnest_money_amount !== null;
    const hasEarnestMoney = assignment.additional_data.earnest_money_received_date !== null;

    const title = hasEarnestMoney ? 'Has Earnest Money' : 'Needs Earnest Money';
    const color = hasEarnestMoney ? 'text.primary' : 'text.secondary';
    const icon = hasEarnestMoney ? (
        <PaidRoundedIcon sx={{ color: color }} />
    ) : (
        <PaidOutlinedIcon sx={{ color: color }} />
    );
    return (
        <Tooltip title={title} enterDelay={300} placement="bottom">
            {icon}
        </Tooltip>
    );
};

AssignmentIconEarnestMoney.propTypes = {
    assignment: PropTypes.object.isRequired,
};

const AssignmentIconContract = ({ assignment }) => {
    const hasContract = assignment.additional_data.contract_received_date !== null;

    const title = hasContract ? 'Has Contract' : 'Needs Contract';
    const color = hasContract ? 'text.primary' : 'text.secondary';
    const icon = hasContract ? (
        <AssignmentRoundedIcon sx={{ color: color }} />
    ) : (
        <AssignmentOutlinedIcon sx={{ color: color }} />
    );
    return (
        <Tooltip title={title} enterDelay={300} placement="bottom">
            {icon}
        </Tooltip>
    );
};

AssignmentIconContract.propTypes = {
    assignment: PropTypes.object.isRequired,
};

const AssignmentIconMembers = ({ assignment, assignmentMembers }) => {
    const assigneeTypeMembers = useMemo(() => _.filter(assignmentMembers, { type: ASSIGNMENT_MEMBER_TYPE.assignee }), [
        assignmentMembers,
    ]);
    const assignorTypeMembers = useMemo(() => _.filter(assignmentMembers, { type: ASSIGNMENT_MEMBER_TYPE.assignor }), [
        assignmentMembers,
    ]);

    const hasAssignee = assigneeTypeMembers.length > 0;
    const hasAssignor = assignorTypeMembers.length > 0;

    const title =
        hasAssignee && hasAssignor
            ? 'Has Members'
            : hasAssignor
            ? 'Needs Assignee'
            : hasAssignee
            ? 'Needs Assignor'
            : 'Needs Assignor and Assignee';
    const color = hasAssignee && hasAssignor ? 'text.primary' : 'text.secondary';
    const icon =
        hasAssignee && hasAssignor ? (
            <PeopleAltRoundedIcon sx={{ color: color }} />
        ) : (
            <PeopleAltOutlinedIcon sx={{ color: color }} />
        );
    return (
        <Tooltip title={title} enterDelay={300} placement="bottom">
            {icon}
        </Tooltip>
    );
};

AssignmentIconMembers.propTypes = {
    assignment: PropTypes.object.isRequired,
    assignmentMembers: PropTypes.array.isRequired,
};

const AssignmentContractIconList = ({ assignment, assignmentMembers }) => {
    if (!assignment) {
        return null;
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ flex: 1, minWidth: '120px', maxWidth: '240px' }}
        >
            <AssignmentIconMembers assignment={assignment} assignmentMembers={assignmentMembers} />
            <AssignmentIconContract assignment={assignment} />
            <AssignmentIconEarnestMoney assignment={assignment} />
        </Stack>
    );
};

AssignmentContractIconList.propTypes = {
    assignment: PropTypes.object.isRequired,
    assignmentMembers: PropTypes.array.isRequired,
};

export default AssignmentContractIconList;
