import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useUpdateLienMutation } from '../../api/lien';
import { generateUpdateObject } from '../../helpers/utils';
import EditLienAdditionalData from './EditLienAdditionalData';
import EditLienCoreData from './EditLienCoreData';

const EditLien = ({ lien, onSuccess }) => {
    const [updateLien, { isLoading }] = useUpdateLienMutation();
    const [lienCoreData, setLienCoreData] = useState();
    const [lienAdditionalData, setLienAdditionalData] = useState();
    const [updateCoreDataPayload, setUpdateCoreDataPayload] = useState({});
    const [updateAdditionalDataPayload, setUpdateAdditionalDataPayload] = useState({});

    useEffect(() => {
        // Listen to form field changes and generate update payload object
        if (lienAdditionalData) {
            setUpdateAdditionalDataPayload(generateUpdatePayload(lien.additional_data, lienAdditionalData));
        }
    }, [lienAdditionalData, lien.additional_data]);

    useEffect(() => {
        // Listen to form field changes and generate update payload object
        if (lienCoreData) {
            setUpdateCoreDataPayload(generateUpdatePayload(lien, lienCoreData));
        }
    }, [lienCoreData, lien]);

    const generateUpdatePayload = (baseEntity, updateEntity) => {
        // Compare original lien additional data with local form state
        const baseUpdateObject = generateUpdateObject(baseEntity, updateEntity, _.keys(updateEntity));

        // json stringify will automatically remove undefined values from objects
        // however, we want to do that preimptively to calculate if an update should be submitted
        _.forEach(baseUpdateObject, (value, key) => {
            if (value === undefined) {
                delete baseUpdateObject[key];
            }
        });
        return baseUpdateObject;
    };

    const handleLienUpdate = async (updatedLienData) => {
        const updateLienPayload = {
            lienId: lien.id,
            lienData: updatedLienData,
        };

        const { data } = await updateLien(updateLienPayload);
        return !!data;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const willUpdateCoreData = _.keys(updateCoreDataPayload).length > 0;
        const willUpdateAdditionalData = _.keys(updateAdditionalDataPayload).length > 0;
        if (willUpdateCoreData || willUpdateAdditionalData) {
            // Only includes fields that have changed
            let updateLienData = {};

            if (willUpdateCoreData) {
                updateLienData = {
                    ...updateLienData,
                    ...updateCoreDataPayload,
                };
            }

            if (willUpdateAdditionalData) {
                updateLienData = {
                    ...updateLienData,
                    additional_data: {
                        ...updateAdditionalDataPayload,
                    },
                };
            }

            handleLienUpdate(updateLienData).then((success) => {
                if (success) {
                    onSuccess();
                }
            });
        }
    };

    const changedCoreData = _.keys(updateCoreDataPayload).length > 0;
    const changedAdditionalData = _.keys(updateAdditionalDataPayload).length > 0;
    const canUpdate = changedCoreData || changedAdditionalData;

    return (
        <form onSubmit={handleSubmit}>
            <EditLienCoreData lien={lien} lienCoreData={lienCoreData} setLienCoreData={setLienCoreData} />

            <EditLienAdditionalData
                lien={lien}
                lienAdditionalData={lienAdditionalData}
                setLienAdditionalData={setLienAdditionalData}
            />

            <Box
                sx={{
                    textAlign: 'right',
                    paddingTop: (theme) => theme.spacing(3),
                }}
            >
                <LoadingButton
                    color="primary"
                    type="submit"
                    variant="contained"
                    sx={{
                        minWidth: '120px',
                    }}
                    loading={isLoading}
                    disabled={!canUpdate}
                    disableElevation
                >
                    Update
                </LoadingButton>
            </Box>
        </form>
    );
};

EditLien.propTypes = {
    lien: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default EditLien;
