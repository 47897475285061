import { Checkbox, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';

function DocumentAccessEmailCheckbox({ sendAccessEmail, setSendAccessEmail, documentId = null, disabled = false }) {
    const handleCheckboxChange = ({ target }) => {
        const updatedSendAccessEmail = target.checked;
        setSendAccessEmail(updatedSendAccessEmail, documentId);
    };

    return (
        <FormControlLabel
            label="Send Email"
            control={<Checkbox name="sendAccessEmail" checked={sendAccessEmail} onChange={handleCheckboxChange} />}
            disabled={disabled}
            sx={{ marginRight: '0' }}
        />
    );
}

DocumentAccessEmailCheckbox.propTypes = {
    sendAccessEmail: PropTypes.bool.isRequired,
    setSendAccessEmail: PropTypes.func.isRequired,
    documentId: PropTypes.string,
    disabled: PropTypes.bool,
};

export default DocumentAccessEmailCheckbox;
