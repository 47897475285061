import PropTypes from 'prop-types';
import React from 'react';
import BaseDialog from '../../common/BaseDialog';
import AddRelationship from './AddRelationship';

const AddRelationshipModal = ({ user, open, handleClose }) => {
    const handleCloseLocal = (openValue) => {
        handleClose(openValue);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleCloseLocal(false)}
            DialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
            }}
        >
            <AddRelationship
                user={user}
                handleCancel={() => handleCloseLocal(false)}
                handleComplete={() => handleCloseLocal(false)}
            />
        </BaseDialog>
    );
};

AddRelationshipModal.propTypes = {
    user: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default AddRelationshipModal;
