import PropTypes from 'prop-types';
import BaseDialog from '../../common/BaseDialog';
import CreateContact from './CreateContact';

const CreateContactModal = ({ open, handleClose }) => {
    const handleSubmit = () => {
        // TODO - navigate to new contact after creation?
        handleCloseLocal(false);
    };

    const handleCloseLocal = (open) => {
        handleClose(open);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleCloseLocal(false)}
            DialogProps={{
                maxWidth: 'md',
                fullWidth: false,
            }}
        >
            <CreateContact handleSubmit={handleSubmit} />
        </BaseDialog>
    );
};

CreateContactModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CreateContactModal;
