import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';

import { rootApi } from 'api/root';
import { calculateShouldInvalidateTagList } from 'api/socketListenerEffect';
import useSocket from './useSocket';

function useSocketCacheManager() {
    const store = useStore();
    const dispatch = useDispatch();

    const { messages, lastMessage, connectionStatus } = useSocket();

    useEffect(() => {
        // React to latest messages from the websocket connection
        // Use message data to determine if any cache should be invalidated
        if (lastMessage !== null) {
            const tagsToInvalidate = calculateShouldInvalidateTagList(lastMessage, store.getState());
            if (tagsToInvalidate.length > 0) {
                // https://redux-toolkit.js.org/rtk-query/api/created-api/api-slice-utils#invalidatetags
                dispatch(rootApi.util.invalidateTags(tagsToInvalidate));
            }
        }
    }, [lastMessage]);

    return {
        connectionStatus,
        lastMessage,
        messages,
    };
}

useSocketCacheManager.propTypes = {};

export default useSocketCacheManager;
