import { Box, List, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import NotificationListItem from './NotificationListItem';

const NotificationList = ({ notificationEventLogs, handleNavigation, unreadCount = 0 }) => {
    if (!notificationEventLogs || notificationEventLogs.length === 0) {
        return (
            <Box p={2}>
                <Typography variant="body1" color="text.secondary" fontStyle="italic">
                    No current notifications
                </Typography>
            </Box>
        );
    }

    return (
        <List disablePadding>
            {_.map(notificationEventLogs, (notificationEventLog, index) => (
                <NotificationListItem
                    key={notificationEventLog.id}
                    notificationEventLog={notificationEventLog}
                    handleNavigation={handleNavigation}
                    unread={index < unreadCount}
                />
            ))}
        </List>
    );
};

NotificationList.propTypes = {
    notificationEventLogs: PropTypes.array,
    handleNavigation: PropTypes.func.isRequired,
    unreadCount: PropTypes.number,
};

export default NotificationList;
