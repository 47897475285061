import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { EVENT_LOG_FILTER_TYPE } from 'helpers/constants';
import { filterEventLogs } from 'helpers/utils';
import EventLogListFilters, { INITIAL_EVENT_LOG_FILTERS } from './components/EventLogListFilters';
import EventLogListDataGrid from './components/EventLogListGrid';

// TODO default filterTypeList and columnTypeList

const DEFAULT_HIDE_FILTER_TYPE_LIST = [];
const DEFAULT_HIDE_COLUMN_TYPE_LIST = [];
const DEFAULT_INITIAL_EVENT_LOG_FILTERS = {
    ...INITIAL_EVENT_LOG_FILTERS,
};

const EventLogList = ({
    eventLogs,
    hideFilterTypeList = DEFAULT_HIDE_FILTER_TYPE_LIST,
    hideColumnTypeList = DEFAULT_HIDE_COLUMN_TYPE_LIST,
    defaultFilters = DEFAULT_INITIAL_EVENT_LOG_FILTERS,
    pageTitle = 'Event Logs',
    loading = false,
}) => {
    const [filters, setFilters] = useState({
        ...defaultFilters,
    });

    const filteredEventLogs = useMemo(() => filterEventLogs(eventLogs, filters), [eventLogs, filters]);

    const resetFilters = () => {
        setFilters({
            ...defaultFilters, // TODO
        });
    };

    const setTransactionId = (transactionId) => {
        setFilters({
            ...filters,
            [EVENT_LOG_FILTER_TYPE.transactionId]: transactionId,
        });
    };

    return (
        <Stack spacing={3}>
            {pageTitle && <Typography variant="h1">{pageTitle}</Typography>}

            <Box>
                <EventLogListFilters
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                    defaultFilters={defaultFilters}
                    hideFilters={hideFilterTypeList}
                />
            </Box>

            <Box>
                <EventLogListDataGrid
                    eventLogs={filteredEventLogs}
                    hideColumns={hideColumnTypeList}
                    loading={loading}
                    setTransactionId={setTransactionId}
                />
            </Box>
        </Stack>
    );
};

EventLogList.propTypes = {
    eventLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
    hideFilterTypeList: PropTypes.arrayOf(PropTypes.string),
    hideColumnTypeList: PropTypes.arrayOf(PropTypes.string),
    pageTitle: PropTypes.string,
    loading: PropTypes.bool,
};

export default EventLogList;
