import { Box, Divider, Stack, Typography } from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { PARCEL_STATUS, PARCEL_TYPE } from '../../../../helpers/constants';
import { formatISODate } from '../../../../helpers/utils';

export const generateParcelClass = (parcel) => {
    /*
        parcel complete: green,
        parcel in progress:
            if due date is within 7 days: yellow
            if due date is within 3 days: red
        default: gray
    */

    const defaultClass = 'default';

    if (parcel.current_status.status === PARCEL_STATUS.complete) {
        return 'success';
    }

    if (!parcel.required_complete_date) {
        return defaultClass;
    }

    const currentDate = new Date();
    const requiredCompleteDate = parseISO(parcel.required_complete_date);
    const daysUntilDue = differenceInDays(requiredCompleteDate, currentDate);

    if (daysUntilDue <= 7) {
        if (daysUntilDue <= 3) {
            return 'error';
        }

        return 'warning';
    }

    return defaultClass;
};

export const parcelDueDateBackgroundColor = (parcel, theme) => {
    /*
        parcel complete: green,
        parcel in progress:
            if due date is within 7 days: yellow
            if due date is within 3 days: red
        default: gray
    */
    // const defaultColor = theme.palette.default.main;
    const defaultColor = theme.palette.default.light;

    if (parcel.current_status.status === PARCEL_STATUS.complete) {
        return theme.palette.success.main;
    }

    if (!parcel.required_complete_date) {
        return defaultColor;
    }

    const currentDate = new Date();
    const requiredCompleteDate = parseISO(parcel.required_complete_date);
    const daysUntilDue = differenceInDays(requiredCompleteDate, currentDate);

    if (daysUntilDue <= 7) {
        if (daysUntilDue <= 3) {
            // return theme.palette.error.main;
            return theme.palette.error.light;
        }

        // return theme.palette.warning.main;
        return theme.palette.warning.light;
    }

    return defaultColor;
};

export const parcelDueDateTextColor = (parcel, theme) => {
    /*
        parcel in progress:
            if due date is within 7 days: black
        default: white
    */
    const defaultColor = theme.palette.default.contrastText;

    if (parcel.current_status.status === PARCEL_STATUS.complete) {
        return defaultColor;
    }

    if (!parcel.required_complete_date) {
        return defaultColor;
    }

    const currentDate = new Date();
    const requiredCompleteDate = parseISO(parcel.required_complete_date);
    const daysUntilDue = differenceInDays(requiredCompleteDate, currentDate);

    if (daysUntilDue <= 7 && daysUntilDue > 3) {
        return theme.palette.text.primary;
    }

    return defaultColor;
};

function ParcelCardDueDate({ parcel }) {
    // const parcelClass = useMemo(() => generateParcelClass(parcel), [parcel]);

    if (
        !parcel.required_complete_date ||
        _.includes(
            [
                PARCEL_TYPE.balancing,
                PARCEL_TYPE.cda,
                PARCEL_TYPE.closing_buyer,
                PARCEL_TYPE.closing_borrower,
                PARCEL_TYPE.closing_seller,
                PARCEL_TYPE.funding,
                PARCEL_TYPE.post_closing,
                PARCEL_TYPE.keys,
            ],
            parcel.type
        )
    ) {
        return <Divider sx={{ borderColor: (theme) => theme.palette.border }} />;
    }

    return (
        <Box
            // className={parcelClass}
            sx={{
                padding: (theme) => theme.spacing(2, 0, 0),
                // borderTop: (theme) => `solid 1px ${theme.palette.border}`,
                borderTop: (theme) => `solid 1px ${theme.palette.border}`,
                // backgroundColor: (theme) => parcelDueDateBackgroundColor(parcel, theme),

                '&.success': {
                    '& .parcel-due-date': {
                        color: (theme) => theme.palette.success.main,
                    },
                },
                '&.warning': {
                    '& .parcel-due-date': {
                        color: (theme) => theme.palette.warning.main,
                    },
                },
                '&.error': {
                    '& .parcel-due-date': {
                        color: (theme) => theme.palette.error.main,
                    },
                },
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Typography fontSize="12px" fontWeight="500" color="text.secondary">
                    Due:
                </Typography>

                <Typography
                    className="parcel-due-date"
                    fontSize="12px"
                    fontWeight="500"
                    sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'right',
                    }}
                >
                    {formatISODate(parcel.required_complete_date)}
                </Typography>
            </Stack>
        </Box>
    );
}

ParcelCardDueDate.propTypes = {
    parcel: PropTypes.object.isRequired,
};

export default ParcelCardDueDate;
