import Avatar from '@mui/material/Avatar';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getInitials } from '../../helpers/utils';

const UserAvatar = ({ user, avatarProps = {} }) => {
    const avatarUrl = _.get(user, 'profile.avatar');

    if (!user) {
        return <Avatar {...avatarProps} />;
    }

    if (avatarUrl) {
        return <Avatar {...avatarProps} alt={user.first_name} src={user.profile.avatar} />;
    }

    return (
        <Avatar
            {...avatarProps}
            // sx={{
            //     background: '#439398',  // TODO - generate color based on user name
            // }}
        >
            {getInitials(user.first_name, user.last_name)}
        </Avatar>
    );
};

UserAvatar.propTypes = {
    user: PropTypes.object,
    avatarProps: PropTypes.object,
};

export default UserAvatar;
