import axios from 'axios';
import Config from '../../config';

const getUser = () => {
    const url = `${Config.api_base}/get_user`;
    return axios
        .get(url, { withCredentials: true })
        .then((response) => {
            return {
                success: true,
                data: response.data,
            };
        })
        .catch((err) => {
            return {
                success: false,
                err,
            };
        });
};

const createSession = (code) => {
    const requestPayload = {
        provider: 'google-oauth2',
        code,
    };

    const requestUrl = `${Config.api_base}/login/social/session/`;
    return axios
        .post(requestUrl, requestPayload)
        .then((response) => {
            // TODO handle failure
            return {
                success: true,
                data: response.data,
            };
        })
        .catch((err) => {
            return {
                success: false,
                err,
            };
        });
};

const logoutSession = () => {
    const requestUrl = `${Config.api_base}/logout`;
    return axios
        .get(requestUrl)
        .then((response) => {
            // TODO handle failure
            return {
                success: true,
            };
        })
        .catch((err) => {
            return {
                success: false,
                err,
            };
        });
};

const getUsersList = () => {
    const url = `${Config.api_base}/users`;
    return axios
        .get(url, { withCredentials: true })
        .then((response) => {
            return {
                success: true,
                data: response.data,
            };
        })
        .catch((err) => {
            return {
                success: false,
                err,
            };
        });
};

const userService = {
    getUser,
    createSession,
    logoutSession,
    getUsersList,
};

export default userService;
