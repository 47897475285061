import InfoIcon from '@mui/icons-material/Info';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Link as MULink, Stack, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { SIGNING_LOCATION_NAME_MAP, TRANSACTION_TYPE } from '../../../helpers/constants';
import { formatAddress, formatISODate } from '../../../helpers/utils';
import { DataGrid } from '../../common/styled';

const INVALID_CELL_CLASS = 'invalid-cell';

function getSchedulingParcelStatus(parcel) {
    const parcelAdditionalData = parcel.additional_data;
    if (parcelAdditionalData.signing_method) {
        return 'Confirmed';
    } else {
        return 'Not Scheduled';
    }
}

function getSchedulingParcelMethod(parcel) {
    const parcelAdditionalData = parcel.additional_data;
    if (parcelAdditionalData.signing_method) {
        return _.startCase(parcelAdditionalData.signing_method);
    } else {
        return '—';
    }
}

function SchedulingMethodCellTooltip(props) {
    const { parcelAdditionalData } = props;

    const includeLocation = !!parcelAdditionalData.signing_location;
    const locationText = includeLocation ? SIGNING_LOCATION_NAME_MAP[parcelAdditionalData.signing_location] : '';
    const timeText = format(new Date(parcelAdditionalData.signing_time), 'P p');

    return (
        <div>
            {locationText && <div>{locationText}</div>}
            <div>{timeText}</div>
        </div>
    );
}

function SchedulingMethodCell(props) {
    const { value, parcel } = props;

    if (!parcel) {
        return <>{value}</>;
    }

    const parcelAdditionalData = parcel.additional_data;
    const includeTooltip = !!parcelAdditionalData.signing_time;

    return (
        <Tooltip
            title={includeTooltip ? <SchedulingMethodCellTooltip parcelAdditionalData={parcelAdditionalData} /> : ''}
            placement="bottom"
            enterDelay={300}
        >
            <Stack direction="row" alignItems="center">
                {value}
                {includeTooltip && (
                    <InfoIcon fontSize="small" sx={{ marginLeft: (theme) => theme.spacing(1), fontSize: '18px' }} />
                )}
            </Stack>
        </Tooltip>
    );
}

const OrderColumnList = [
    {
        field: 'qualia_order_number',
        headerName: 'Order #',
        width: 150,
        valueGetter: (params) => {
            return params.row.qualia_order_number;
        },
        renderCell: (params) => <Link to={`/order/${params.row.id}`}>{params.value}</Link>,
    },
    {
        field: 'property_address',
        headerName: 'Address',
        renderCell: (params) => {
            const address = formatAddress(params.value);
            const shortAddress = params.value.street;
            return (
                <MULink
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`}
                    target="_blank"
                    underline="hover"
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                        // fontWeight: '500',
                        '& .MuiSvgIcon-root': {
                            visibility: 'hidden',
                        },
                        '&:hover .MuiSvgIcon-root': {
                            visibility: 'visible',
                        },
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <span>{shortAddress}</span>
                        <OpenInNewOutlinedIcon
                            sx={{
                                fontSize: '14px',
                            }}
                        />
                    </Stack>
                </MULink>
            );
        },
        width: 250,
    },
    {
        field: 'transaction_type',
        headerName: 'Transaction Type',
        valueGetter: (params) => {
            return params.row.transaction_type;
        },
        valueFormatter: (params) => _.startCase(params.value),
        width: 150,
    },
    // {
    //     field: 'order_type',
    //     headerName: "Order Type",
    //     valueGetter: (params) => {
    //         return params.row.order_type;
    //     },
    //     valueFormatter: (params) => _.startCase(params.value),
    //     width: 150,
    // },
    {
        field: 'close_date',
        headerName: 'Close Date',
        valueFormatter: ({ value }) => formatISODate(value),
        width: 120,
    },
];

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
    },
    ...OrderColumnList,
    {
        field: 'buyerClosingStatus',
        headerName: 'Buyer (Status)',
        valueGetter: (params) => {
            const parcel =
                params.row.transaction_type === TRANSACTION_TYPE.purchase
                    ? params.row.closingBuyerParcel
                    : params.row.closingBorrowerParcel;
            return getSchedulingParcelStatus(parcel);
        },
        renderCell: (params) => {
            const parcel =
                params.row.transaction_type === TRANSACTION_TYPE.purchase
                    ? params.row.closingBuyerParcel
                    : params.row.closingBorrowerParcel;
            return (
                <>
                    {parcel ? (
                        <Link to={`/order/${params.row.id}/parcel/${parcel.id}`}>{params.value}</Link>
                    ) : (
                        params.value
                    )}
                </>
            );
        },
        width: 150,
    },
    {
        field: 'buyerClosingMethod',
        headerName: 'Buyer (Method)',
        valueGetter: (params) => {
            const parcel =
                params.row.transaction_type === TRANSACTION_TYPE.purchase
                    ? params.row.closingBuyerParcel
                    : params.row.closingBorrowerParcel;
            return getSchedulingParcelMethod(parcel);
        },
        renderCell: (params) => {
            const parcel =
                params.row.transaction_type === TRANSACTION_TYPE.purchase
                    ? params.row.closingBuyerParcel
                    : params.row.closingBorrowerParcel;
            return <SchedulingMethodCell value={params.value} parcel={parcel} />;
        },
        width: 150,
    },
    {
        field: 'sellerClosingStatus',
        headerName: 'Seller (Status)',
        valueGetter: (params) => {
            const parcel = params.row.closingSellerParcel;
            if (!parcel) {
                return 'N/A';
            }

            return getSchedulingParcelStatus(parcel);
        },
        renderCell: (params) => {
            const parcel = params.row.closingSellerParcel;
            return (
                <>
                    {parcel ? (
                        <Link to={`/order/${params.row.id}/parcel/${parcel.id}`}>{params.value}</Link>
                    ) : (
                        params.value
                    )}
                </>
            );
        },
        width: 150,
    },
    {
        field: 'sellerClosingMethod',
        headerName: 'Seller (Method)',
        valueGetter: (params) => {
            const parcel = params.row.closingSellerParcel;
            if (!parcel) {
                return 'N/A';
            }

            return getSchedulingParcelMethod(parcel);
        },
        renderCell: (params) => {
            const parcel = params.row.closingSellerParcel;

            return <SchedulingMethodCell value={params.value} parcel={parcel} />;
        },
        width: 150,
    },
];

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell {
        &.${INVALID_CELL_CLASS} {
            color: red;
        }
    },
`;

function SchedulingListGrid(props) {
    const { schedulingList, loading } = props;

    return (
        <div>
            <StyledDataGrid
                rows={schedulingList}
                columns={columns}
                pageSize={10}
                loading={loading}
                density="comfortable"
                disableColumnMenu
                disableColumnFilter
                autoHeight
            />
        </div>
    );
}

SchedulingListGrid.propTypes = {
    schedulingList: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};

export default SchedulingListGrid;
