import _ from 'lodash';
import { useMemo } from 'react';

import { useGetLienStatusesQuery } from 'api/lien';
import { useGetOrdersQuery } from 'api/order';
import {
    ORDER_FILTER_TYPE,
    ORDER_STATUS,
    PARCEL_STATUS,
    PARCEL_TYPE,
    TRANSACTION_ORDER_TYPE,
    TRANSACTION_TYPE,
} from 'helpers/constants';
import { ORDER_FILTER_FUNC_MAP, findOrderParcelByType } from 'helpers/utils';

function generateDashboardCountMap(orders, lienStatusMap, thisWeekRange, nextWeekRange) {
    const dashboardCount = {
        orders: _.get(orders, 'length', 0),
        purchases: 0,
        refinances: 0,
        open: {
            purchases: 0,
            refinances: 0,
            titleIssues: 0,
        },
        thisWeek: {
            dateRange: thisWeekRange,
            closings: 0,
            closed: 0,
            fundings: 0,
            funded: 0,
            titleIssues: 0,
            closingPurchases: 0,
            closingRefinances: 0,
            closingTraditionalRefinances: 0,
            closingCashoutRefinances: 0,
        },
        nextWeek: {
            dateRange: nextWeekRange,
            closings: 0,
            fundings: 0,
            titleIssues: 0,
            closingPurchases: 0,
            closingRefinances: 0,
            closingTraditionalRefinances: 0,
            closingCashoutRefinances: 0,
        },
    };

    if (!orders || !lienStatusMap) {
        return dashboardCount;
    }

    _.forEach(orders, (order) => {
        let isOpen = false;
        let isClosed = false;
        let isPurchase = false;
        let isCashoutRefinance = false;
        let isTraditionalRefinance = false;
        let isClosingThisWeek = false;
        let isClosingNextWeek = false;

        // Ensure order is being worked on
        // TODO can include pre-open status as well?
        // TODO need to include 'closed' status as well but verify that it was closed this week and not earlier
        if (ORDER_FILTER_FUNC_MAP[ORDER_FILTER_TYPE.orderStatus](order, ORDER_STATUS.open)) {
            isOpen = true;
        }
        // } else if (ORDER_FILTER_FUNC_MAP[ORDER_FILTER_TYPE.orderStatus](order, ORDER_STATUS.closed)) {
        //     isClosed = true;
        // }

        if (!isOpen && !isClosed) {
            return;
        }

        // Total purchase count
        if (ORDER_FILTER_FUNC_MAP[ORDER_FILTER_TYPE.transactionType](order, TRANSACTION_TYPE.purchase)) {
            dashboardCount.open.purchases += 1;
            isPurchase = true;
        }

        // Total refinance count
        if (ORDER_FILTER_FUNC_MAP[ORDER_FILTER_TYPE.transactionType](order, TRANSACTION_TYPE.refinance)) {
            dashboardCount.open.refinances += 1;

            if (order.order_type === TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.refinance].cash_out) {
                isCashoutRefinance = true;
            } else {
                // 'traditional' just means non cash out so residential, investment, and commercial are included here
                isTraditionalRefinance = true;
            }
        }

        // Total thisWeek. closing count
        if (ORDER_FILTER_FUNC_MAP[ORDER_FILTER_TYPE.closeDateRange](order, thisWeekRange)) {
            isClosingThisWeek = true;

            dashboardCount.thisWeek.closings += 1;

            if (isPurchase) {
                dashboardCount.thisWeek.closingPurchases += 1;
            } else if (isCashoutRefinance) {
                dashboardCount.thisWeek.closingRefinances += 1;
                dashboardCount.thisWeek.closingCashoutRefinances += 1;
            } else if (isTraditionalRefinance) {
                dashboardCount.thisWeek.closingRefinances += 1;
                dashboardCount.thisWeek.closingTraditionalRefinances += 1;
            }

            // Check if order has already closed (parcel is complete)
            const orderClosingParcel = findOrderParcelByType(order, PARCEL_TYPE.closing);
            if (orderClosingParcel.current_status.status === PARCEL_STATUS.complete) {
                dashboardCount.thisWeek.closed += 1;
            }
        } else if (
            // Next week closing count
            ORDER_FILTER_FUNC_MAP[ORDER_FILTER_TYPE.closeDateRange](order, nextWeekRange)
        ) {
            isClosingNextWeek = true;
            dashboardCount.nextWeek.closings += 1;

            if (isPurchase) {
                dashboardCount.nextWeek.closingPurchases += 1;
            } else if (isCashoutRefinance) {
                dashboardCount.nextWeek.closingRefinances += 1;
                dashboardCount.nextWeek.closingCashoutRefinances += 1;
            } else if (isTraditionalRefinance) {
                dashboardCount.nextWeek.closingRefinances += 1;
                dashboardCount.nextWeek.closingTraditionalRefinances += 1;
            }
        }

        // Total thisWeek. funding count
        if (ORDER_FILTER_FUNC_MAP[ORDER_FILTER_TYPE.disbursementDateRange](order, thisWeekRange)) {
            dashboardCount.thisWeek.fundings += 1;

            // Check if order has already funded (parcel is complete)
            const orderFundingParcel = findOrderParcelByType(order, PARCEL_TYPE.funding);
            if (orderFundingParcel.current_status.status === PARCEL_STATUS.complete) {
                dashboardCount.thisWeek.funded += 1;
            }
        } else if (
            // Next week funding count
            ORDER_FILTER_FUNC_MAP[ORDER_FILTER_TYPE.disbursementDateRange](order, nextWeekRange)
        ) {
            dashboardCount.nextWeek.fundings += 1;
        }

        // Open title issues count
        const terminalLienStatuses = _.get(lienStatusMap, '_terminal');
        if (terminalLienStatuses) {
            _.forEach(order.liens, (lien) => {
                if (!_.includes(terminalLienStatuses, lien.status)) {
                    dashboardCount.open.titleIssues += 1;

                    if (isClosingThisWeek) {
                        dashboardCount.thisWeek.titleIssues += 1;
                    } else if (isClosingNextWeek) {
                        dashboardCount.nextWeek.titleIssues += 1;
                    }
                }
            });
        }
    });

    return dashboardCount;
}

function useDashboardCount({ thisWeekRange, nextWeekRange }) {
    const { data: orders, isError: orderError, isLoading: orderLoading } = useGetOrdersQuery();
    const {
        data: lienStatusMap,
        isError: lienStatusMapError,
        isLoading: lienStatusMapLoading,
    } = useGetLienStatusesQuery();

    const loading = orderLoading || lienStatusMapLoading;
    const error = orderError || lienStatusMapError;

    const dashboardCount = useMemo(() => {
        return generateDashboardCountMap(orders, lienStatusMap, thisWeekRange, nextWeekRange);
    }, [orders, lienStatusMap, thisWeekRange, nextWeekRange]);

    return [
        dashboardCount,
        {
            loading,
            error,
        },
    ];
}

export default useDashboardCount;
