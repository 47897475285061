import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { ORDER_TAG } from './order';
import { rootApi } from './root';

export const PAYOFF_TAG = _.toUpper(ENTITY_TYPE.payoff);

export const payoffApi = rootApi.injectEndpoints({
    reducerPath: 'payoffApi',
    endpoints: (builder) => ({
        updatePayoff: builder.mutation({
            query: ({payoffId, payoffData}) => ({
                url: `payoff/${payoffId}`,
                method: 'PUT',
                body: payoffData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [PAYOFF_TAG, ORDER_TAG],
        }),
    }),
    overrideExisting: false,
})



export const { useUpdatePayoffMutation } = payoffApi