import { Box, Collapse, List, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import OrderMembershipListItem from 'components/User/components/OrderMembershipListItem';
import ShowAllButton from 'components/common/ShowAllButton';

// TODO move this file outside of user as it is used in legal entities as well
const MAX_ITEMS = 5;

const OrderMembershipList = ({ orderMemberships }) => {
    const [showAll, setShowAll] = useState(false);

    const sortedOrderMemberships = useMemo(() => {
        return _.orderBy(orderMemberships, ['created_datetime'], ['desc']);
    }, [orderMemberships]);

    if (!orderMemberships || orderMemberships.length === 0) {
        return (
            <Box sx={{ padding: 3, paddingTop: 0 }}>
                <Typography variant="body1" color="text.secondary" fontStyle="italic">
                    No current order memberships
                </Typography>
            </Box>
        );
    }

    if (sortedOrderMemberships.length <= MAX_ITEMS) {
        return (
            <List
                sx={{
                    '& .order-membership-list-item': {
                        paddingLeft: 3,
                        paddingRight: 3,
                    },
                }}
                disablePadding
            >
                {_.map(sortedOrderMemberships, (orderMembership) => {
                    return <OrderMembershipListItem key={orderMembership.id} orderMembership={orderMembership} />;
                })}
            </List>
        );
    }

    // If there are more than MAX_ITEMS, show the first MAX_ITEMS and a "Show More" link
    const initialMembershipSlice = _.slice(sortedOrderMemberships, 0, MAX_ITEMS);
    const remainingMembershipSlice = _.slice(sortedOrderMemberships, MAX_ITEMS);

    return (
        <List
            sx={{
                '& .order-membership-list-item': {
                    paddingLeft: 3,
                    paddingRight: 3,
                },
            }}
            disablePadding
        >
            {_.map(initialMembershipSlice, (orderMembership) => {
                return <OrderMembershipListItem key={orderMembership.id} orderMembership={orderMembership} />;
            })}

            <Box
                sx={{
                    marginBottom: 1,
                    textAlign: 'center',
                }}
            >
                <Collapse
                    in={showAll}
                    sx={{
                        marginBottom: (theme) => (showAll ? theme.spacing(1) : theme.spacing(0)),
                    }}
                >
                    <Stack>
                        {_.map(remainingMembershipSlice, (orderMembership) => {
                            return (
                                <OrderMembershipListItem key={orderMembership.id} orderMembership={orderMembership} />
                            );
                        })}
                    </Stack>
                </Collapse>

                <ShowAllButton showAll={showAll} setShowAll={setShowAll} />
            </Box>
        </List>
    );
};

OrderMembershipList.propTypes = {
    orderMemberships: PropTypes.array.isRequired,
};

export default OrderMembershipList;
