import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Link as MULink, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isAfter, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { PAYOFF_STATUS } from '../../../helpers/constants';
import { formatAddress, formatISODate } from '../../../helpers/utils';
import { DataGrid } from '../../common/styled';

const INVALID_CELL_CLASS = 'invalid-cell';

const OrderColumnList = [
    {
        field: 'qualia_order_number',
        headerName: 'Order #',
        width: 150,
        valueGetter: (params) => {
            return params.row.order?.qualia_order_number;
        },
        renderCell: (params) => <Link to={`/order/${params.row.order?.id}`}>{params.value}</Link>,
    },
    {
        field: 'property_address',
        headerName: 'Property',
        valueGetter: (params) => {
            return params.row.order?.property_address;
        },
        renderCell: (params) => {
            const address = formatAddress(params.value);
            const shortAddress = params.value.street;
            return (
                <MULink
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`}
                    target="_blank"
                    underline="hover"
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                        // fontWeight: '500',
                        '& .MuiSvgIcon-root': {
                            visibility: 'hidden',
                        },
                        '&:hover .MuiSvgIcon-root': {
                            visibility: 'visible',
                        },
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <span>{shortAddress}</span>
                        <OpenInNewOutlinedIcon
                            sx={{
                                fontSize: '14px',
                            }}
                        />
                    </Stack>
                </MULink>
            );
        },
        width: 250,
    },
    {
        field: 'transaction_type',
        headerName: 'Transaction Type',
        valueGetter: (params) => {
            return params.row.order?.transaction_type;
        },
        valueFormatter: (params) => _.startCase(params.value),
        width: 150,
    },
    // {
    //     field: 'order_type',
    //     headerName: "Order Type",
    //     valueGetter: (params) => {
    //         return params.row.order?.order_type;
    //     },
    //     valueFormatter: (params) => _.startCase(params.value),
    //     width: 150,
    // },
    {
        field: 'close_date',
        headerName: 'Close Date',
        valueGetter: (params) => {
            return params.row.order?.close_date;
        },
        valueFormatter: (params) => (params.value ? formatISODate(params.value) : '—'),
        width: 120,
    },
];

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
    },
    ...OrderColumnList,
    {
        field: 'name',
        headerName: 'Name',
        renderCell: (params) => <Link to={`/order/${params.row.order?.id}/title`}>{params.value}</Link>,
        width: 150,
    },
    {
        field: 'status',
        headerName: 'Status',
        valueFormatter: (params) => _.startCase(params.value),
        renderCell: (params) => {
            const rowData = params.row;
            const payoffEta = rowData.additional_data.payoff_eta;
            return (
                <div>
                    <span>{_.startCase(params.value)}</span>
                    {rowData.status === PAYOFF_STATUS.waiting && <div>ETA: {formatISODate(payoffEta)}</div>}
                </div>
            );
        },
        cellClassName: (params) => {
            const rowData = params.row;

            if (rowData.status !== PAYOFF_STATUS.waiting) {
                return;
            }

            // Highlight cell if payoff_eta is after the order close_date
            const payoffEta = rowData.additional_data.payoff_eta;
            if (payoffEta && isAfter(parseISO(payoffEta), parseISO(rowData.order.close_date))) {
                return INVALID_CELL_CLASS;
            }
        },
        width: 150,
    },
    {
        field: 'good_through_date',
        headerName: 'Good Through Date',
        valueFormatter: (params) => (params.value ? formatISODate(params.value) : ''),
        width: 150,
        cellClassName: (params) => {
            const rowData = params.row;

            // Highlight cell if payoff good_through_date is before the order close_date
            const isPayoffStillValid = isAfter(parseISO(rowData.good_through_date), parseISO(rowData.order.close_date));
            if (!isPayoffStillValid) {
                return INVALID_CELL_CLASS;
            }
        },
    },
];

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell {
        &.${INVALID_CELL_CLASS} {
            color: red;
        }
    },
`;

function PayoffListGrid(props) {
    const { payoffList, loading } = props;

    return (
        <div>
            <StyledDataGrid
                rows={payoffList}
                columns={columns}
                pageSize={10}
                loading={loading}
                density="comfortable"
                disableColumnMenu
                disableColumnFilter
                autoHeight
            />
        </div>
    );
}

PayoffListGrid.propTypes = {
    payoffList: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};

export default PayoffListGrid;
