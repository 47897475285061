import { MenuItem, TextField } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DOCUMENT_FILTER_TYPE, DOCUMENT_TYPE } from '../../../helpers/constants';
import { formatDocumentType } from '../../../helpers/utils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
};

function DocumentTypeField({ documentType, setDocumentType, disabled = false, showLabel = true }) {
    return (
        <TextField
            name={DOCUMENT_FILTER_TYPE.documentType}
            label={showLabel ? 'Document Type' : ''}
            onChange={(event) => setDocumentType(event.target)}
            value={documentType}
            variant="outlined"
            size="small"
            sx={{
                width: '200px',
            }}
            disabled={disabled}
            select
            SelectProps={{
                MenuProps,
            }}
        >
            {_.map(DOCUMENT_TYPE, (optionsValue) => {
                return (
                    <MenuItem key={optionsValue} value={optionsValue}>
                        {formatDocumentType(optionsValue)}
                    </MenuItem>
                );
            })}
        </TextField>
    );
}

DocumentTypeField.propTypes = {
    documentType: PropTypes.string.isRequired,
    setDocumentType: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    showLabel: PropTypes.bool,
};

export default DocumentTypeField;
