import { Box, Breadcrumbs, Link as MUILink, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useGetUserQuery } from '../../api/user';
import UserAccessLogList from './UserAccessLogList';
import UserEmailLogList from './UserEmailLogList';

const USER_LOG_TYPE = {
    email: 'email',
    access: 'access',
};

function UserLogList({ userId }) {
    const { data: user, isError: userError, isLoading: userLoading } = useGetUserQuery(userId);

    const [logType, setLogType] = useState(USER_LOG_TYPE.email);

    const handleChange = (event, newValue) => {
        setLogType(newValue);
    };

    if (!user || userLoading) {
        return (
            <Stack
                spacing={3}
                sx={{
                    height: '100%',
                    padding: (theme) => theme.spacing(3),
                }}
            >
                <Typography>Loading ...</Typography>
            </Stack>
        );
    }

    if (userError) {
        return (
            <Stack
                spacing={3}
                sx={{
                    height: '100%',
                    padding: (theme) => theme.spacing(3),
                }}
            >
                <Typography>User ({userId}) not found</Typography>
            </Stack>
        );
    }

    const logComponent =
        logType === USER_LOG_TYPE.email ? <UserEmailLogList userId={userId} /> : <UserAccessLogList userId={userId} />;

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
                padding: (theme) => theme.spacing(3),
            }}
        >
            <Breadcrumbs aria-label="breadcrumb">
                <MUILink component={Link} underline="hover" color="inherit" to="/contacts">
                    Contacts
                </MUILink>
                <MUILink component={Link} underline="hover" color="inherit" to="/contacts?contactType=user">
                    Users
                </MUILink>
                <MUILink component={Link} underline="hover" color="inherit" to={`/contact/user/${user.id}`}>
                    {user.name}
                </MUILink>
                <Typography color="text.primary">Logs</Typography>
            </Breadcrumbs>

            <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={logType} onChange={handleChange} textColor="primary" indicatorColor="primary">
                    <Tab value={USER_LOG_TYPE.email} label="Email" />
                    <Tab value={USER_LOG_TYPE.access} label="Access" />
                </Tabs>
            </Box>

            {logComponent}
        </Stack>
    );
}

export default UserLogList;
