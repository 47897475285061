import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { ORDER_TAG } from './order';
import { rootApi } from './root';

export const LIEN_TAG = _.toUpper(ENTITY_TYPE.lien);
export const LIEN_STATUS_TAG = `${LIEN_TAG}_STATUS`
export const LIEN_MEMBER_TAG = `${LIEN_TAG}_MEMBER`

export const lienApi = rootApi.injectEndpoints({
    reducerPath: 'lienApi',
    endpoints: (builder) => ({
        createLien: builder.mutation({
            query: ({orderId, lienData}) => ({
                url: `order/${orderId}/liens`,
                method: 'POST',
                body: lienData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [LIEN_TAG, ORDER_TAG],
        }),
        updateLien: builder.mutation({
            query: ({lienId, lienData}) => ({
                url: `lien/${lienId}`,
                method: 'PUT',
                body: lienData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [LIEN_TAG, ORDER_TAG],
        }),
        getLienStatuses: builder.query({
            query: () => 'liens/statuses',
            keepUnusedDataFor: 86400, // 1 day in seconds - this is an enum sync endpoint so data can be cached for a long time
            providesTags: [LIEN_STATUS_TAG],
        }),
        getAllLienMembers: builder.query({
            query: () => 'liens/members',
            providesTags: [LIEN_MEMBER_TAG],
        }),
        createLienMember: builder.mutation({
            query: ({lienId, memberData}) => ({
                url: `lien/${lienId}/members`,
                method: 'POST',
                body: memberData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [LIEN_MEMBER_TAG],
        }),
        updateLienMember: builder.mutation({
            query: ({lienId, memberId, memberData}) => ({
                url: `lien/${lienId}/member/${memberId}`,
                method: 'PUT',
                body: memberData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [LIEN_MEMBER_TAG],
        }),
        deleteLienMember: builder.mutation({
            query: ({lienId, memberId}) => ({
                url: `lien/${lienId}/member/${memberId}`,
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [LIEN_MEMBER_TAG],
        }),
    }),
    overrideExisting: false,
})


export function useGetLienMembersQuery(lienId) {
    const { useGetAllLienMembersQuery } = lienApi;
    return useGetAllLienMembersQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((lienMember) => lienMember.lien_id === lienId),
            error,
            isLoading
        }),
    });
}



export const { 
    useCreateLienMutation, 
    useUpdateLienMutation, 
    useGetLienStatusesQuery, 
    useGetAllLienMembersQuery, 
    useCreateLienMemberMutation, 
    useUpdateLienMemberMutation,
    useDeleteLienMemberMutation,
} = lienApi