import { Stack, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CloseDateField from '../common/form/order/CloseDateField';
import FundDateField from '../common/form/order/FundDateField';
import DollarFieldMask from '../common/mask/DollarFieldMask';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    spacer: {
        marginBottom: theme.spacing(4),
    },
}));

function RefinanceForm(props) {
    const classes = useStyles();

    const { fields, error, errorMessage, disableFields } = props;
    const {
        loanAmount,
        setLoanAmount,
        loanNumber,
        setLoanNumber,
        openDate,
        closeDate,
        setCloseDate,
        confirmedCloseDate,
        setConfirmedCloseDate,
        fundDate,
        setFundDate,
    } = fields;

    return (
        <Stack spacing={3}>
            <TextField
                label="Loan Amount"
                value={loanAmount}
                onChange={({ target }) => setLoanAmount(target.value)}
                variant="outlined"
                size="small"
                InputProps={{
                    inputComponent: DollarFieldMask,
                }}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                required
            />

            <TextField
                label="Loan Number"
                value={loanNumber}
                onChange={({ target }) => setLoanNumber(target.value)}
                variant="outlined"
                size="small"
                InputProps={{
                    startAdornment: <span>#</span>,
                    inputProps: { minLength: 0 },
                }}
            />

            <div className={classes.spacer} />

            <CloseDateField
                openDate={openDate}
                closeDate={closeDate}
                setCloseDate={setCloseDate}
                confirmedCloseDate={confirmedCloseDate}
                setConfirmedCloseDate={setConfirmedCloseDate}
                disableFields={disableFields}
            />

            <FundDateField
                closeDate={closeDate}
                fundDate={fundDate}
                setFundDate={setFundDate}
                defaultFundDatePeriod={3}
                disableFields={disableFields}
            />
        </Stack>
    );
}

RefinanceForm.propTypes = {
    fields: PropTypes.shape({
        loanAmount: PropTypes.string.isRequired,
        setLoanAmount: PropTypes.func.isRequired,
        loanNumber: PropTypes.string.isRequired,
        setLoanNumber: PropTypes.func.isRequired,
        openDate: PropTypes.string,
        closeDate: PropTypes.string, // NOTE: not required to avoid null error
        setCloseDate: PropTypes.func.isRequired,
        confirmedCloseDate: PropTypes.bool.isRequired,
        setConfirmedCloseDate: PropTypes.func.isRequired,
        fundDate: PropTypes.string, // NOTE: not required to avoid null error
        setFundDate: PropTypes.func.isRequired,
    }).isRequired,
    disableFields: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.object.isRequired,
};

export default RefinanceForm;
