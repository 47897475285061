import PropTypes from 'prop-types';
import React from 'react';

import { useGetOrderEventLogsQuery } from '../../../api/eventLog';
import { EVENT_LOG_FILTER_TYPE } from '../../../helpers/constants';
import EventLogList from '../../Log/EventLogList';
import { EVENT_LOG_LIST_GRID_COLUMN_TYPE } from '../../Log/EventLogList/components/EventLogListGrid';

const ORDER_HIDE_FILTER_TYPE_LIST = [EVENT_LOG_FILTER_TYPE.orderId];
const ORDER_HIDE_COLUMN_TYPE_LIST = [EVENT_LOG_LIST_GRID_COLUMN_TYPE.orderId];

const OrderEventLogList = ({ orderId }) => {
    const {
        data: orderEventLogs,
        isError: orderEventLogsError,
        isLoading: orderEventLogsLoading,
    } = useGetOrderEventLogsQuery(orderId);

    return (
        <EventLogList
            eventLogs={orderEventLogs || []}
            hideFilterTypeList={ORDER_HIDE_FILTER_TYPE_LIST}
            hideColumnTypeList={ORDER_HIDE_COLUMN_TYPE_LIST}
            loading={orderEventLogsLoading}
            pageTitle=""
        />
    );
};

OrderEventLogList.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderEventLogList;
