import PropTypes from 'prop-types';
import { useState } from 'react';

import { useUpdateActionMutation } from '../../../../api/action';
import ActionModal from '../../../Action/ActionModal';

function TitleActionModal({ order, parcel, actionId, handleClose }) {
    // NOTE: wrapper for bridging interface between order title content & action modal

    const [updateAction] = useUpdateActionMutation();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleUpdateAction = (updateData) => {
        setIsSubmitting(true);

        return submitActionUpdate(updateData).then(
            (response) => {
                setIsSubmitting(false);

                if (response) {
                    handleClose();
                } else {
                    console.error('Failed to update action');
                }
            },
            (error) => {
                console.log(error);
                setIsSubmitting(false);
            }
        );
    };

    const submitActionUpdate = async (updateData) => {
        const updateActionData = { ...updateData };

        const { data: udpateResponseData } = await updateAction({
            actionId: actionId,
            actionData: updateActionData,
        });

        if (udpateResponseData) {
            return true;
        } else {
            console.warn(`Failed to be update action ${actionId}`);
            return false;
        }
    };

    if (!actionId) {
        // Would prefer to keep modal mounted & hidden when no actionId is provided,
        // However, the base action modal component does not support this behavior atm
        return null;
    }

    const activeAction = parcel.action_map[actionId];

    return (
        <ActionModal
            action={activeAction}
            orderId={order.id}
            isOpen={!!actionId}
            isSubmitting={isSubmitting}
            handleClose={handleClose}
            handleUpdateAction={handleUpdateAction}
        />
    );
}

TitleActionModal.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    actionId: PropTypes.string, // NOTE: not required to allow null value
};

export default TitleActionModal;
