import {
    FormControl,
    FormControlLabel, FormLabel, Radio,
    RadioGroup
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { TRANSACTION_ORDER_TYPE } from '../../../../helpers/constants';


function OrderTypeField({orderType, setOrderType, transactionType, disabled}) {
    // NOTE: if transaction type changes, order type should be reset to residential
    // this is handled at a higher level because it is orchistration logic between multiple fields

    return (

        <FormControl component="fieldset">
            <FormLabel id="order-type-group-label">Order Type ({_.capitalize(transactionType)})</FormLabel>
            <RadioGroup
                row
                aria-labelledby="order-type-group-label"
                name="order-type-radio-buttons-group"
                value={orderType}
                onChange={({ target }) => setOrderType(target.value)}
                disabled={disabled}
                required
            >
                {_.map(TRANSACTION_ORDER_TYPE[transactionType], (transactionOrderValue) => {
                    return <FormControlLabel key={transactionOrderValue} value={transactionOrderValue} control={<Radio />} label={_.startCase(transactionOrderValue)} />
                })}
            </RadioGroup>
        </FormControl>
                
    );
}

OrderTypeField.propTypes = {
    orderType: PropTypes.string.isRequired,
    setOrderType: PropTypes.func.isRequired,
    transactionType: PropTypes.string.isRequired, 
    disabled: PropTypes.bool,
};

export default OrderTypeField;
