import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetOrderNotesQuery } from 'api/note';
import { useGetOrderQuery } from 'api/order';
import OrderBreadcrumbs from 'components/Order/OrderDetails/components/OrderBreadcrumbs';
import { ENTITY_TYPE, NOTE_FILTER_TYPE } from 'helpers/constants';
import ActiveNote from './components/ActiveNote';
import CreateNote from './components/CreateNote';
import NoteListFilters from './components/NoteListFilters';
import NoteListStack from './components/NoteListStack';

const DEFAULT_FILTER_STATE = {
    [NOTE_FILTER_TYPE.search]: '',
};

const filterNotes = (order, notes, noteFilters) => {
    const filteredNotes = [];

    _.forEach(notes, (note) => {
        // Exclude notes that are replies
        if (note.entity_type === ENTITY_TYPE.note) {
            return;
        }

        const searchFilter = noteFilters[NOTE_FILTER_TYPE.search];
        if (searchFilter.length > 3) {
            if (!_.includes(_.toLower(note.content), _.toLower(searchFilter))) {
                return;
            }
        }

        filteredNotes.push(note);
    });

    return filteredNotes;
};

function NoteList({ orderId }) {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    const { data: notes, isError: notesError, isLoading: notesLoading } = useGetOrderNotesQuery(orderId);

    const [domNode, setDomNode] = useState(null);
    const [offsetTop, setOffsetTop] = useState(0);

    const onRefChange = useCallback((node) => {
        setDomNode(node);
    }, []);

    // Base filters for notes
    const [filters, setFilters] = useState({
        ...DEFAULT_FILTER_STATE,
    });

    const [activeNoteId, setActiveNoteId] = useState(null);

    // Find active note by activeNoteId
    const activeNote = useMemo(() => {
        if (!activeNoteId) {
            return null;
        }

        return _.find(notes, { id: activeNoteId });
    }, [notes, activeNoteId]);

    // Filter notes based on filters
    const filteredNotes = useMemo(() => filterNotes(order, notes, filters), [order, notes, filters]);

    const resetFilters = () => {
        setFilters({
            ...DEFAULT_FILTER_STATE,
        });
    };

    useEffect(() => {
        if (domNode) {
            setOffsetTop(domNode.offsetTop);
        }
    }, [domNode]);

    if (orderLoading || notesLoading) {
        return <div ref={onRefChange}>Loading...</div>;
    }

    return (
        <Stack
            ref={onRefChange}
            spacing={3}
            sx={{
                height: `calc(100vh - ${offsetTop}px - 24px)`,

                '& .note': {
                    bgcolor: 'background.gray',
                    transition: (theme) =>
                        theme.transitions.create('background', {
                            duration: theme.transitions.duration.shortest,
                        }),

                    '&.active': {
                        bgcolor: 'background.darkGray',
                    },
                },
            }}
        >
            <OrderBreadcrumbs orderId={orderId} />

            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h1">Notes</Typography>

                <Fab
                    color="primary"
                    aria-label="add order note"
                    // onClick={() => setOpenDialog(true)}
                    onClick={() => setActiveNoteId(null)}
                    size="small"
                    sx={{
                        zIndex: 0,
                    }}
                >
                    <AddIcon />
                </Fab>
            </Stack>

            <Stack
                direction="row"
                spacing={0}
                sx={{
                    flex: 1,
                    borderRadius: (theme) => theme.spacing(0.5),
                }}
            >
                <Stack
                    spacing={0}
                    sx={{
                        width: '100%',
                        maxWidth: '400px',
                        height: '100%',
                        ml: -3,
                    }}
                >
                    <Box sx={{ p: 3 }}>
                        <NoteListFilters
                            defaultFilters={DEFAULT_FILTER_STATE}
                            filters={filters}
                            setFilters={setFilters}
                            resetFilters={resetFilters}
                        />
                    </Box>

                    <Box sx={{ flexGrow: 1, overflow: 'auto', height: '0px' }}>
                        <NoteListStack
                            notes={filteredNotes}
                            activeNoteId={activeNoteId}
                            setActiveNoteId={setActiveNoteId}
                        />
                    </Box>
                </Stack>

                <Stack
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        border: (theme) => `solid 1px ${theme.palette.divider}`,
                        borderRadius: (theme) => theme.spacing(0.5),
                        background: (theme) => theme.palette.background.paper,
                    }}
                >
                    {activeNote ? (
                        <ActiveNote note={activeNote} />
                    ) : (
                        <CreateNote orderId={orderId} handleSuccess={(newNoteId) => setActiveNoteId(newNoteId)} />
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
}

export default NoteList;
