import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { rootApi } from './root';

export const EVENT_LOG_TAG = _.toUpper(ENTITY_TYPE.event_log);
export const NOTIFICATION_EVENT_LOG_TAG = `NOTIFICATION_${EVENT_LOG_TAG}`;
export const NOTIFICATION_EVENT_LOG_COUNT_TAG = `NOTIFICATION_${EVENT_LOG_TAG}_COUNT`;
export const ORDER_EVENT_LOG_TAG = `ORDER_${EVENT_LOG_TAG}`;

export const eventLogApi = rootApi.injectEndpoints({
    reducerPath: 'eventLogApi',
    endpoints: (builder) => ({
        // TODO notification endpoints
        // TODO enable when endpoint is added
        getNotificationEventLogs: builder.query({
            query: ({limit}) => {
                return {
                    url: 'event/notification/logs',
                    params: limit ? { limit } : {},
                };
            },
            providesTags: [NOTIFICATION_EVENT_LOG_TAG],
        }),
        getNotificationEventLogCount: builder.query({
            query: () => 'event/notification/count',
            providesTags: [NOTIFICATION_EVENT_LOG_COUNT_TAG],
        }),
        getOrderEventLogs: builder.query({
            query: (orderId) => `order/${orderId}/event/logs`,
            providesTags: [ORDER_EVENT_LOG_TAG],
        }),
    }),
    overrideExisting: false,
})


export const { useGetNotificationEventLogsQuery, useGetNotificationEventLogCountQuery, useGetOrderEventLogsQuery } = eventLogApi