import React from 'react';
import { IMaskInput } from 'react-imask';
import { unmaskPhone } from '../../../helpers/utils';

const PhoneFieldMask = React.forwardRef(function PhoneFieldMask(props, ref) {
    // TODO - unmask removes the static +1 from value so we need to add it back in manually on accept
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+1 (#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            // unmask={true}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value: unmaskPhone(value) } })}
            overwrite
        />
    );
});

export default PhoneFieldMask;
