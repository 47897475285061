import AddIcon from '@mui/icons-material/Add';
import { Fab, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { PARCEL_STATUS, PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import CreateLienModal from '../../../Liens/CreateLienModal';
import EditLienModal from '../../../Liens/EditLienModal';
import TitleActionModal from './TitleActionModal';
import TitleParcelList from './TitleParcelList';
// import ActionModal from '../../../ActionList/components/ActionModal';
/*
action, parcel, order, isOpen, isSubmitting, handleClose, handleUpdateAction 
*/

function OrderTitleContent({ order, titleIssues, loading, allowCreateLien = false }) {
    const [titleParcel, setTitleParcel] = useState();
    const [seededTitleIssues, setSeededTitleIssues] = useState([]);
    const [openLienModel, setOpenLienModal] = useState(false);
    const [editLienId, setEditLienId] = useState(null);
    const [activeActionId, setActiveActionId] = useState();

    const updateActiveActionId = (actionId) => {
        setActiveActionId(actionId);
    };

    useEffect(() => {
        if (order) {
            const parcel = findOrderParcelByType(order, PARCEL_TYPE.title_clearing);

            setTitleParcel(parcel);
        }
    }, [order]);

    useEffect(() => {
        setSeededTitleIssues(
            _.map(titleIssues, (issue) => {
                return {
                    ...issue,
                    titleParcel,
                    lienMembers: [], // TODO
                    // Pass down function references in each 'issue' to be available in the data grid cell renderer
                    updateActiveActionId,
                    setEditLienId,
                };
            })
        );
    }, [titleIssues, titleParcel]);

    const editLien = useMemo(() => _.find(_.get(order, 'liens', []), { id: editLienId }), [order, editLienId]);

    if (!order && loading) {
        return <div>Loading...</div>;
    }

    if (!titleParcel) {
        return <div>No title parcel found</div>;
    }

    return (
        <Stack spacing={3}>
            {allowCreateLien && (
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => setOpenLienModal(true)}
                    size="small"
                    sx={{
                        marginLeft: 'auto !important',
                    }}
                >
                    <AddIcon />
                </Fab>
            )}

            {titleParcel.current_status.status !== PARCEL_STATUS.not_started && (
                <TitleParcelList titleIssues={seededTitleIssues} loading={loading} />
            )}

            {allowCreateLien && (
                <CreateLienModal order={order} open={openLienModel} handleClose={() => setOpenLienModal(false)} />
            )}

            <EditLienModal lien={editLien} open={!!editLienId} handleClose={() => setEditLienId(null)} />

            <TitleActionModal
                order={order}
                parcel={titleParcel}
                actionId={activeActionId}
                handleClose={() => setActiveActionId(null)}
            />
        </Stack>
    );
}

OrderTitleContent.propTypes = {
    order: PropTypes.object.isRequired,
    titleIssues: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};

export default OrderTitleContent;
