import { Stack, Typography } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { LoadingButton } from 'components/common/styled';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { UserAction } from '../../stores/user';

const LoginButton = ({ onSuccess, onFailure, disabled = false, loading = false }) => {
    const login = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: onSuccess,
        onFailure: onFailure,
        hosted_domain: 'hudlytitle.com',
    });

    return (
        <LoadingButton
            onClick={() => login()}
            disabled={disabled}
            loading={loading}
            variant="contained"
            color="light"
            fullWidth
        >
            Log In
        </LoadingButton>
    );
};

function Login() {
    const location = useLocation();
    const user = useSelector((store) => store.user);
    const { loading, isAuthenticated } = user;
    const dispatch = useDispatch();

    const { from } = location.state || { from: { pathname: '/orders' } };

    const handleLogin = (googleResponse) => {
        dispatch(UserAction.createSession(googleResponse));
    };

    const handleFailure = (error) => {
        console.log(error);
    };

    useEffect(() => {
        dispatch(UserAction.getUser());
    }, [dispatch]);

    if (isAuthenticated) {
        return (
            <Navigate
                to={{
                    pathname: from.pathname,
                    search: from.search,
                }}
            />
        );
    }

    return (
        <Stack
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                backgroundImage: 'url(/hudly-background-green.svg)',
                backgroundPosition: 'top center',
                backgroundSize: 'cover',
                minHeight: '100vh',
                width: '100%',
            }}
        >
            <Stack spacing={3}>
                <Stack spacing={1} alignItems="center">
                    <img width="160" src="/logo-white-transparent.png" />
                    <Typography variant="sectionHeader">Admin</Typography>
                </Stack>

                <LoginButton onSuccess={handleLogin} onFailure={handleFailure} loading={loading} />
            </Stack>
        </Stack>
    );
}

export default Login;
