import EditIcon from '@mui/icons-material/Edit';
import { IconButton, ListItem, ListItemButton, ListItemText } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const MembershipListItem = ({ member, handleEditMembership }) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(`/contact/user/${member.user.id}`);
    };

    return (
        <ListItem
            className="legal-entity-membership-list-item"
            secondaryAction={
                <IconButton aria-label="edit" onClick={() => handleEditMembership(member)} size="small">
                    <EditIcon fontSize="small" />
                </IconButton>
            }
            disablePadding
        >
            <ListItemButton className="legal-entity-membership-list-item-button" onClick={handleNavigate}>
                <ListItemText
                    primary={member.user.name}
                    secondary={`${_.startCase(member.role)}${member.signor && ' • Signor'}`}
                />
            </ListItemButton>
        </ListItem>
    );
};

MembershipListItem.propTypes = {
    member: PropTypes.object.isRequired,
    handleEditMembership: PropTypes.func.isRequired,
};

export default MembershipListItem;
