import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetUserQuery } from '../../api/user';
import UserBreadcrumbs from './components/UserBreadcrumbs';
import UserHeader from './components/UserHeader';
import UserSectionTabs from './components/UserSectionTabs';

function User({ userId, edit }) {
    const { data: user, isError: userError, isLoading: userLoading } = useGetUserQuery(userId);
    // TODO figure out better way to handle user error / not found as currently useGetUserQuery will not return error/loading state

    if (!user || userLoading) {
        return (
            <Stack
                spacing={3}
                sx={{
                    height: '100%',
                    padding: (theme) => theme.spacing(3),
                }}
            >
                <Typography>Loading ...</Typography>
            </Stack>
        );
    }

    if (userError) {
        return (
            <Stack
                spacing={3}
                sx={{
                    height: '100%',
                    padding: (theme) => theme.spacing(3),
                }}
            >
                <Typography>User ({userId}) not found</Typography>
            </Stack>
        );
    }

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
                padding: (theme) => theme.spacing(3),
            }}
        >
            <UserBreadcrumbs user={user} />

            <UserHeader user={user} />

            <UserSectionTabs user={user} />
        </Stack>
    );
}

User.propTypes = {
    userId: PropTypes.string.isRequired,
    edit: PropTypes.bool,
};

export default User;
