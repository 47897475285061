// import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, Link as MUILink, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { DataGrid } from '../../../components/common/styled';
import { formatISODate } from '../../../helpers/utils';

const HIGH_PRIORITY_ROW_CLASSNAME = 'high-priority-row';

const baseColumns = [
    {
        field: 'qualia_order_number',
        headerName: 'Order #',
        minWidth: 125,
        renderCell: (params) => (
            <MUILink
                component={Link}
                to={`/order/${params.row.id}`}
                underline="hover"
                sx={{ color: (theme) => theme.palette.text.link }}
            >
                {params.value}
            </MUILink>
        ),
    },
    {
        field: 'property_address',
        headerName: 'Address',
        flex: 1,
        minWidth: 100,
        valueFormatter: ({ value }) => value.street,
        // renderCell: (params) => <Tooltip title={formatAddress(params.row.property_address)}>{params.value}</Tooltip>,
        sortable: false,
    },
];

const closeDateColumns = [
    ...baseColumns,
    {
        field: 'close_date',
        type: 'date',
        headerName: 'Close Date',
        minWidth: 120,
        // valueFormatter: ({ value }) => formatISODate(value),
        renderCell: ({ value, row }) => {
            const formattedValue = value ? formatISODate(value) : '—';
            return (
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <span>{formattedValue}</span>
                    {row.high_priority && <PriorityHighIcon color="error" />}
                </Stack>
            );
        },
    },
];

const etaDateColumns = [
    ...baseColumns,
    {
        field: 'eta_date',
        type: 'date',
        headerName: 'ETA Date',
        minWidth: 120,
        // valueFormatter: ({ value }) => (value ? formatISODate(value) : ''),
        renderCell: ({ value, row }) => {
            const formattedValue = value ? formatISODate(value) : '—';
            return (
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <span>{formattedValue}</span>
                    {row.high_priority && <PriorityHighIcon color="error" />}
                </Stack>
            );
        },
    },
];

const columnTypeMap = {
    close_date: closeDateColumns,
    eta_date: etaDateColumns,
};

function SummaryTable({ label, orderList, loading, columnType = 'close_date' }) {
    const columns = columnTypeMap[columnType];

    return (
        <Stack
            spacing={1}
            sx={{
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: 0,
                boxSizing: 'border-box',
            }}
        >
            <Typography variant="sectionHeader">{label}</Typography>

            {orderList.length > 0 || loading ? (
                <DataGrid
                    columns={columns}
                    rows={orderList}
                    loading={loading}
                    getRowId={(row) => row.id}
                    getRowClassName={(params) => params.row.high_priority && HIGH_PRIORITY_ROW_CLASSNAME}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: columnType, sort: 'asc' }],
                        },
                    }}
                    // density="compact"
                    autoHeight
                    disableColumnMenu
                    disableColumnResize
                    disableSelectionOnClick
                    hideFooter
                    sx={{
                        [`& .MuiDataGrid-row.${HIGH_PRIORITY_ROW_CLASSNAME}`]: {
                            '& a': {},
                        },
                    }}
                />
            ) : (
                <Box
                    sx={{
                        height: '80px',
                        color: (theme) => theme.palette.primary.main,
                        background: '#f5f5f5',
                        borderRadius: (theme) => theme.spacing(0.5),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Stack direction="row" spacing={3} alignItems="center">
                        <DoneIcon color="success" fontSize="small" />
                        <Stack spacing={0} alignItems="center">
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                fontStyle="italic"
                                fontWeight="400"
                                textTransform="uppercase"
                            >
                                No Outstanding Items
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            )}
        </Stack>
    );
}

SummaryTable.propTypes = {
    label: PropTypes.string.isRequired,
    orderList: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    columnType: PropTypes.oneOf(_.keys(columnTypeMap)),
};

export default SummaryTable;
