import { useDroppable } from '@dnd-kit/core';
import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import { Box, IconButton, List, Paper, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import { useGetLegalEntityQuery } from '../../../../api/legalEntity';
import { ENTITY_TYPE, ORDER_ROLE_TYPE } from '../../../../helpers/constants';
import { copyToClipboard } from '../../../../helpers/utils';
import MemberPartyRoleListItem from './MemberPartyRoleListItem';

function MemberPartyRoleLegalEntityGroup(props) {
    const {
        member,
        orderMembers,
        setEditMemberId,
        handleSyncMemberEmail,
        roleLegalEntityMembers,
        handleGroupedLegalEntityMember,
    } = props;

    // TODO rethink as this call is also made inside of MemberPartyRoleListItem
    const { data: legalEntity, isError: legalEntityError, isLoading: legalEntityLoading } = useGetLegalEntityQuery(
        member.member_id
    );
    const [groupedLegalEntityMembers, setGroupedLegalEntityMembers] = useState({});

    // TODO rework this to move out of useEffect
    useEffect(() => {
        // For each member of the legal entity, check if they are also associated with this order
        const newGroupedLegalEntityMembers = {};
        _.forEach(legalEntity.members, (legalEntityMember) => {
            if (_.has(roleLegalEntityMembers, legalEntityMember.id)) {
                newGroupedLegalEntityMembers[legalEntityMember.id] = roleLegalEntityMembers[legalEntityMember.id];
            }
        });

        setGroupedLegalEntityMembers({
            ...newGroupedLegalEntityMembers,
        });

        handleGroupedLegalEntityMember(legalEntity.id, _.keys(newGroupedLegalEntityMembers));

        // Callback to 'wipe' legal entity from parent context on unmount
        return () => {
            handleGroupedLegalEntityMember(legalEntity.id, []);
        };
    }, [legalEntity, roleLegalEntityMembers]);

    return (
        <Box>
            <MemberPartyRoleListItem
                member={member}
                orderMembers={orderMembers}
                setEditMemberId={setEditMemberId}
                handleSyncMemberEmail={handleSyncMemberEmail}
            />
            {_.map(groupedLegalEntityMembers, (legalEntityMember) => {
                return (
                    <MemberPartyRoleListItem
                        key={legalEntityMember.id}
                        member={legalEntityMember}
                        orderMembers={orderMembers}
                        setEditMemberId={setEditMemberId}
                        handleSyncMemberEmail={handleSyncMemberEmail}
                        nested
                    />
                );
            })}
        </Box>
    );
}

MemberPartyRoleLegalEntityGroup.propTypes = {
    member: PropTypes.object.isRequired,
    orderMembers: PropTypes.array.isRequired,
    setEditMemberId: PropTypes.func.isRequired,
    handleSyncMemberEmail: PropTypes.func.isRequired,
    roleLegalEntityMembers: PropTypes.object.isRequired,
    handleGroupedLegalEntityMember: PropTypes.func.isRequired,
};

function MemberPartyRoleList({
    partyType,
    role,
    members,
    setEditMemberId,
    setPartyRoleEmailList,
    handleOpen,
    orderMembers,
}) {
    const { isOver, setNodeRef } = useDroppable({
        id: role,
    });

    const [roleUsers, setRoleUsers] = useState({});
    const [roleLegalEntities, setRoleLegalEntities] = useState({});
    const [roleLegalEntityMembers, setRoleLegalEntityMembers] = useState({});
    // Legal entities items are provided all roleLegalEntities and compare them to their member list.
    // If an associated member is matched/claimed, this info bubbles up to this component for sorting/grouping purposes
    const [groupedLegalEntityMembers, setGroupedLegalEntityMembers] = useState({});
    const [roleEmailMap, setRoleEmailMap] = useState({});

    const roleEmailList = useMemo(() => {
        return _.map(roleEmailMap, (roleListEmail) => roleListEmail);
    }, [role, roleEmailMap]);

    useEffect(() => {
        // Sort all role members into their various entity types
        // This is used to decide whether to list legal entity members together under an associated legal entity
        // Or list them standalone

        const memberIdList = [];
        const users = {};
        const legalEntities = {};
        const legalEntityMembers = {};

        _.forEach(members, (member) => {
            if (member.member_type === ENTITY_TYPE.user) {
                users[member.member_id] = member;
            } else if (member.member_type === ENTITY_TYPE.legal_entity) {
                legalEntities[member.member_id] = member;
            } else if (member.member_type === ENTITY_TYPE.legal_entity_member) {
                legalEntityMembers[member.member_id] = member;
            }

            memberIdList.push(member.id);
        });

        setRoleUsers(users);
        setRoleLegalEntities(legalEntities);
        setRoleLegalEntityMembers(legalEntityMembers);
    }, [members]);

    useEffect(() => {
        // Pass up the list of emails to the parent component
        setPartyRoleEmailList(role, roleEmailList);
    }, [role, roleEmailList]);

    const handleClick = () => {
        handleOpen(role);
    };

    // TODO super janky - relies on child component passing the email up to this component
    // When changing the role of a member, this value is not properly updated to remove the old email
    const handleSyncMemberEmail = (memberId, memberEmail) => {
        setRoleEmailMap((prevData) => {
            const newEmailMap = { ...prevData };

            if (!memberEmail) {
                delete newEmailMap[memberId];
            } else {
                newEmailMap[memberId] = memberEmail;
            }

            return newEmailMap;
        });
    };

    const handleCopyRoleListEmails = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (roleEmailList.length > 0) {
            const emailListStr = roleEmailList.join(', ');
            copyToClipboard(emailListStr);
        }
    };

    const handleGroupedLegalEntityMember = (legalEntityId, legalEntityMemberIdList) => {
        // TODO handle scenario:
        // Legal entity is removed from order - need to reset all related claimed members
        // TODO change schema to be legalEntityId: [<list of grouped member ids>]
        // that way any changes to legal entity will reset it's associated 'grouped' member list
        // NOTE: we will still have to handle scenario of legal entity being removed as it will still live in local component state

        setGroupedLegalEntityMembers({
            ...groupedLegalEntityMembers,
            [legalEntityId]: _.map(legalEntityMemberIdList, (legalEntityMemberId) => {
                return legalEntityMemberId;
            }),
        });
    };

    const roleLegalEntityIds = _.keys(roleLegalEntities);
    const roleLegalEntityValues = _.values(roleLegalEntities);
    const roleLegalEntityMemberIds = _.keys(roleLegalEntityMembers);
    const roleLegalEntityMemberValues = _.values(roleLegalEntityMembers);
    const roleUserIds = _.keys(roleUsers);
    const roleUserValues = _.values(roleUsers);
    const groupedLegalEntityMembersValues = _.flatten(
        _.map(groupedLegalEntityMembers, (groupedLegalEntityMemberList) => {
            return groupedLegalEntityMemberList;
        })
    );

    const isEmpty = members.length === 0;
    const showSectionColumnHeader =
        !isEmpty && _.includes([ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.seller, ORDER_ROLE_TYPE.borrower], role);

    return (
        <List
            ref={setNodeRef}
            component={Paper}
            variant="outlined"
            subheader={
                <Stack direction="row" alignItems="center" spacing={2} sx={{ p: 3 }}>
                    <Typography variant="sectionHeader">{_.startCase(role)}s</Typography>

                    <Box sx={{ flexGrow: 1 }} />

                    <Tooltip
                        title={
                            roleEmailList.length > 0
                                ? `Copy all ${_.lowerCase(role)} emails (${roleEmailList.length})`
                                : ''
                        }
                        placement="bottom"
                        enterDelay={300}
                    >
                        <span style={{ display: 'inline-block' }}>
                            <IconButton
                                onClick={handleCopyRoleListEmails}
                                disabled={roleEmailList.length === 0}
                                sx={{ color: (theme) => theme.palette.text.primary }}
                            >
                                <EmailIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Tooltip title={`Add new ${_.lowerCase(role)}s`} placement="bottom" enterDelay={300}>
                        <span style={{ display: 'inline-block' }}>
                            <IconButton onClick={handleClick} sx={{ color: (theme) => theme.palette.text.primary }}>
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Stack>
            }
            sx={{
                position: 'relative',
                p: 0,
            }}
        >
            {isOver && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: (theme) => theme.palette.background.default,
                        opacity: 0.5,
                        zIndex: 1,
                    }}
                />
            )}

            {showSectionColumnHeader && (
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    sx={{ padding: (theme) => theme.spacing(0, 3, 1) }}
                >
                    <Box sx={{ flexGrow: 1 }} />

                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        sx={{ paddingRight: (theme) => theme.spacing(4) }}
                    >
                        <Typography
                            variant="caption"
                            align="center"
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                                width: '38px', // match the width of the checkbox
                                fontWeight: '500',
                            }}
                        >
                            Title
                        </Typography>

                        <Typography
                            variant="caption"
                            align="center"
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                                display: role === ORDER_ROLE_TYPE.seller ? 'none' : 'block',
                                width: '38px', // match the width of the checkbox
                                fontWeight: '500',
                            }}
                        >
                            Loan
                        </Typography>
                    </Stack>

                    <Box sx={{ width: '36px' }} />
                    <Box sx={{ width: '36px' }} />
                </Stack>
            )}

            {isEmpty && (
                <Box sx={{ pl: 3, pr: 3, pb: 3 }}>
                    <Typography variant="body1" color="text.secondary" fontStyle="italic">
                        No current {_.lowerCase(role)}s
                    </Typography>
                </Box>
            )}

            {_.map(roleLegalEntityValues, (member, index) => {
                return (
                    <MemberPartyRoleLegalEntityGroup
                        key={member.id}
                        member={member}
                        orderMembers={orderMembers}
                        setEditMemberId={setEditMemberId}
                        handleSyncMemberEmail={handleSyncMemberEmail}
                        roleLegalEntityMembers={roleLegalEntityMembers}
                        handleGroupedLegalEntityMember={handleGroupedLegalEntityMember}
                    />
                );
            })}

            {_.map(roleLegalEntityMemberValues, (member, index) => {
                if (_.includes(groupedLegalEntityMembersValues, member.member_id)) {
                    // legal entity member already rendered as part of associated legal entity
                    return null;
                }

                return (
                    <MemberPartyRoleListItem
                        key={member.id}
                        member={member}
                        orderMembers={orderMembers}
                        setEditMemberId={setEditMemberId}
                        handleSyncMemberEmail={handleSyncMemberEmail}
                    />
                );
            })}

            {_.map(roleUserValues, (member, index) => {
                return (
                    <MemberPartyRoleListItem
                        key={member.id}
                        member={member}
                        orderMembers={orderMembers}
                        setEditMemberId={setEditMemberId}
                        handleSyncMemberEmail={handleSyncMemberEmail}
                    />
                );
            })}
        </List>
    );
}

MemberPartyRoleList.propTypes = {
    role: PropTypes.string.isRequired,
    members: PropTypes.array.isRequired,
    partyType: PropTypes.string.isRequired,
    orderMembers: PropTypes.array.isRequired,
    setEditMemberId: PropTypes.func.isRequired,
    setPartyRoleEmailList: PropTypes.func.isRequired,
    handleOpen: PropTypes.func.isRequired,
};

export default MemberPartyRoleList;
