import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

function ScrollToTop(props) {
    let location = useLocation();
    const { children } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {children}
        </>
    )
}

export default ScrollToTop;
