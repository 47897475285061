import { useGetAssignmentMembersQuery } from 'api/assignment';

import useParcelData from 'components/common/hooks/useParcelData';

function useAssignmentData(orderId, assignmentId) {
    /*
        parcelData format
        {
            order,
            parcel,
            childParcels,
            parentParcel,
            isLoading,
            isFetching,
        }
        
        NOTE: parcel is the assignment, parentParcel is the assignment meta parcel
    */
    const parcelData = useParcelData(orderId, assignmentId);

    const {
        data: assignmentMembers,
        isLoading: isAssignmentMembersLoading,
        isFetching: isAssignmentMembersFetching,
    } = useGetAssignmentMembersQuery({ orderId, assignmentId });

    const isLoading = parcelData.isLoading || isAssignmentMembersLoading;
    const isFetching = parcelData.isFetching || isAssignmentMembersFetching;

    return {
        ...parcelData,
        assignmentMembers,
        isLoading,
        isFetching,
    };
}

export default useAssignmentData;
