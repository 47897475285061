import DoneIcon from '@mui/icons-material/Done';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import StopIcon from '@mui/icons-material/Stop';
import { IconButton, Stack, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useUpdateActionMutation } from 'api/action';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { useTriggers } from 'components/common/hooks/useTriggers';
// import { ACTION_STATUS, ACTION_TERMINAL_STATUSES } from 'helpers/constants';
// import { LoadingButton } from 'components/common/styled';

// TODO centralize this and unify for all entities
const ACTION_TRIGGER_ICON_MAP = {
    play: <PlayArrowIcon />,
    stop: <StopIcon />,
    pause: <PauseIcon />,
    restart: <RestartAltIcon />,
    done: <DoneIcon />,
    generate: <NoteAddIcon />,
    cancel: <NotInterestedIcon />,
    default: <DoneIcon />,
};

function ActionTriggers({ action, orderId }) {
    const { triggers, activeTriggers } = useTriggers(action);
    const [updateAction, { isLoading }] = useUpdateActionMutation();

    const [confirmTriggerType, setConfirmTriggerType] = useState(null);

    if (!triggers || triggers.length === 0 || activeTriggers.length === 0) {
        return null;
    }

    const handleSubmit = (triggerType) => {
        const trigger = _.get(triggers, triggerType);
        const { payload } = trigger;
        submitActionUpdate(payload);
    };

    const submitActionUpdate = async (updateData) => {
        const { data: updateResponseData } = await updateAction({
            actionId: action.id,
            actionData: { ...updateData },
        });

        if (updateResponseData) {
            return true;
        } else {
            console.warn(`Failed to submit trigger for action ${action.id}`);
            return false;
        }
    };

    return (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ position: 'relative' }}>
            {_.map(activeTriggers, (triggerType) => {
                const trigger = _.get(triggers, triggerType);
                const { color, icon } = trigger.button;
                const triggerIcon = _.get(ACTION_TRIGGER_ICON_MAP, icon, ACTION_TRIGGER_ICON_MAP.default);

                return (
                    <Tooltip key={triggerType} title={trigger.text} placement="bottom" enterDelay={300}>
                        <span style={{ display: 'inline-block' }}>
                            <IconButton
                                color={color}
                                onClick={() => setConfirmTriggerType(triggerType)}
                                disabled={isLoading}
                            >
                                {triggerIcon}
                            </IconButton>
                        </span>
                    </Tooltip>
                );
            })}

            <ConfirmDialog
                open={!!confirmTriggerType}
                title={`${_.get(triggers, `${confirmTriggerType}.text`, '')} Action`}
                subTitle={_.get(action, 'friendly_name')}
                body={`Are you sure you want to ${confirmTriggerType || ''} this action?`}
                handleConfirm={() => handleSubmit(confirmTriggerType)}
                handleClose={() => setConfirmTriggerType(null)}
            />
        </Stack>
    );
}

ActionTriggers.propTypes = {
    action: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
};

export default ActionTriggers;
