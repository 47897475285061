import Grid from '@mui/material/Unstable_Grid2';
import { differenceInCalendarDays, isBefore, parseISO } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { useGetOrdersQuery } from 'api/order';
import { ORDER_FILTER_TYPE, ORDER_STATUS, PARCEL_STATUS, PARCEL_TYPE } from 'helpers/constants';
import { ORDER_FILTER_FUNC_MAP, findOrderParcelByType } from 'helpers/utils';

import SummaryTable from './SummaryTable';

// TODO make useDashboardTable hook
function DashboardTableStack() {
    const { data: orders, isError: orderError, isLoading: orderLoading } = useGetOrdersQuery();

    const [earnestMoneyList, setEarnestMoneyList] = useState([]);
    const [titleExamLateList, setTitleExamLateList] = useState([]);
    const [titleExamAgedList, setTitleExamAgedList] = useState([]);
    const [hoaNotReceivedList, setHoaNotReceivedList] = useState([]);
    const [newSurveyList, setNewSurveyList] = useState([]);
    const [existingSurveyNotReceivedList, setExistingSurveyNotReceivedList] = useState([]);
    const [homeWarrantyList, setHomeWarrantyList] = useState([]);
    const [lenderPackageList, setLenderPackageList] = useState([]);
    const [closedDatePassedList, setClosedDatePassedList] = useState([]);

    useEffect(() => {
        if (orders) {
            const earnestMoneyOrders = [];
            const titleExamLateOrders = [];
            const titleExamAgedOrders = [];
            const hoaNotReceivedOrders = [];
            const newSurveyOrders = [];
            const existingSurveyNotReceivedOrders = [];
            const homeWarrantyOrders = [];
            const lenderPackageOrders = [];
            const closedDatePassedOrders = [];

            _.forEach(orders, (order) => {
                // Ensure order is being worked on
                if (!ORDER_FILTER_FUNC_MAP[ORDER_FILTER_TYPE.orderStatus](order, ORDER_STATUS.open)) {
                    return;
                }

                // Baseline slice of order data
                // Specific tables might require additional/calculated data
                const baseOrderData = {
                    id: order.id,
                    qualia_order_number: order.qualia_order_number,
                    property_address: order.property_address,
                    close_date: order.close_date,
                    high_priority: false,
                };

                const now = new Date();
                const closeDate = parseISO(order.close_date);
                const daysUntilClose = differenceInCalendarDays(closeDate, now);

                // Earnest Money
                const orderEarnestMoneyParcel = findOrderParcelByType(order, PARCEL_TYPE.earnest_money);
                if (orderEarnestMoneyParcel && orderEarnestMoneyParcel.status !== PARCEL_STATUS.complete) {
                    earnestMoneyOrders.push(baseOrderData);
                }

                // Title Exam
                const orderTitleExamParcel = findOrderParcelByType(order, PARCEL_TYPE.title_exam);
                if (orderTitleExamParcel) {
                    if (orderTitleExamParcel.current_status.status !== PARCEL_STATUS.complete) {
                        // Check if eta date is today/earlier or close date is tomorrow or earlier
                        const etaDate = orderTitleExamParcel.additional_data.title_exam_eta;
                        if ((etaDate && isBefore(parseISO(etaDate), now)) || daysUntilClose <= 1) {
                            // Mark orders that are 7 days from closing and title exam not complete as high priority
                            titleExamLateOrders.push({
                                ...baseOrderData,
                                high_priority: daysUntilClose <= 7,
                                eta_date: etaDate,
                            });
                        }
                    } else {
                        // Parcel is complete
                        // Check if received date is greater than 30 days from closing date
                        const receivedDate = orderTitleExamParcel.additional_data.title_exam_received_date;
                        if (receivedDate) {
                            const daysAged = differenceInCalendarDays(closeDate, parseISO(receivedDate));
                            if (daysAged > 30) {
                                titleExamAgedOrders.push(baseOrderData);
                            }
                        }
                    }
                }

                // HOA
                const orderHOAParcel = findOrderParcelByType(order, PARCEL_TYPE.hoa);
                if (
                    orderHOAParcel &&
                    !_.includes([PARCEL_STATUS.received, PARCEL_STATUS.complete], orderHOAParcel.current_status.status)
                ) {
                    const etaDate = orderHOAParcel.additional_data.hoa_eta;

                    // Only show if close date is within 7 days
                    // Mark as high priority if eta date is 3 days before close date or later
                    if (daysUntilClose <= 7) {
                        hoaNotReceivedOrders.push({
                            ...baseOrderData,
                            high_priority: etaDate
                                ? differenceInCalendarDays(closeDate, parseISO(etaDate)) <= 3
                                : false,
                            eta_date: etaDate,
                        });
                    }
                }

                // Survey
                const orderSurveyParcel = findOrderParcelByType(order, PARCEL_TYPE.survey);
                if (orderSurveyParcel) {
                    if (orderSurveyParcel.current_status.status === PARCEL_STATUS.waiting_for_existing_survey) {
                        // Existing survey has been ordered but not received
                        existingSurveyNotReceivedOrders.push(baseOrderData);
                    } else if (
                        _.includes(
                            [PARCEL_STATUS.quotes_requested, PARCEL_STATUS.ordered],
                            orderSurveyParcel.current_status.status
                        )
                    ) {
                        // New survey or survey quotes have been requested but not received
                        // TODO mark as high prioirty if eta date is 1 day before close date or later
                        // TODO we don't currently collect eta date for new survey
                        // const etaDate = orderSurveyParcel.additional_data.hoa_eta;
                        newSurveyOrders.push(baseOrderData);
                    }
                }

                // Home Warranty
                const orderHomeWarrantyParcel = findOrderParcelByType(order, PARCEL_TYPE.home_warranty);
                if (
                    orderHomeWarrantyParcel &&
                    orderHomeWarrantyParcel.current_status.status !== PARCEL_STATUS.complete
                ) {
                    homeWarrantyOrders.push(baseOrderData);
                }

                // Lender Package
                const orderPostClosingParcel = findOrderParcelByType(order, PARCEL_TYPE.post_closing);
                if (
                    orderPostClosingParcel &&
                    orderPostClosingParcel.current_status.status === PARCEL_STATUS.in_progress
                ) {
                    // Only show if ActionLenderPackage action exists but is not complete
                    _.forEach(orderPostClosingParcel.active_actions, (actionId) => {
                        const action = orderPostClosingParcel.action_map[actionId];
                        // TODO turn to ACTION_TYPE constant
                        if (action.type === 'lender_package') {
                            lenderPackageOrders.push(baseOrderData);
                        }
                    });
                }

                // Closed Date Passed
                if (daysUntilClose <= 0) {
                    closedDatePassedOrders.push(baseOrderData);
                }
            });

            setEarnestMoneyList(earnestMoneyOrders);
            setTitleExamLateList(titleExamLateOrders);
            setTitleExamAgedList(titleExamAgedOrders);
            setHoaNotReceivedList(hoaNotReceivedOrders);
            setNewSurveyList(newSurveyOrders);
            setExistingSurveyNotReceivedList(existingSurveyNotReceivedOrders);
            setHomeWarrantyList(homeWarrantyOrders);
            setLenderPackageList(lenderPackageOrders);
            setClosedDatePassedList(closedDatePassedOrders);
        }
    }, [orders]);

    return (
        <Grid
            container
            spacing={3}
            sx={{
                marginLeft: '-12px !important', // TODO figure out better way to offset grid margins
            }}
        >
            <Grid md={12} lg={6} xl={4}>
                <SummaryTable label="Earnest Money" orderList={earnestMoneyList} loading={orderLoading} />
            </Grid>

            <Grid md={12} lg={6} xl={4}>
                <SummaryTable
                    label="Title Exam (Late)"
                    orderList={titleExamLateList}
                    loading={orderLoading}
                    columnType="eta_date"
                />
            </Grid>

            <Grid md={12} lg={6} xl={4}>
                <SummaryTable label="Title Exam (Aged)" orderList={titleExamAgedList} loading={orderLoading} />
            </Grid>

            <Grid md={12} lg={6} xl={4}>
                <SummaryTable
                    label="HOA Not Received (7 Days)"
                    orderList={hoaNotReceivedList}
                    loading={orderLoading}
                    columnType="eta_date"
                />
            </Grid>

            <Grid md={12} lg={6} xl={4}>
                <SummaryTable label="New Survey" orderList={newSurveyList} loading={orderLoading} />
            </Grid>

            <Grid md={12} lg={6} xl={4}>
                <SummaryTable
                    label="Existing Survey Not Received"
                    orderList={existingSurveyNotReceivedList}
                    loading={orderLoading}
                />
            </Grid>

            <Grid md={12} lg={6} xl={4}>
                <SummaryTable label="Home Warranty" orderList={homeWarrantyList} loading={orderLoading} />
            </Grid>

            <Grid md={12} lg={6} xl={4}>
                <SummaryTable label="Lender Package" orderList={lenderPackageList} loading={orderLoading} />
            </Grid>

            <Grid md={12} lg={6} xl={4}>
                <SummaryTable label="Close Date Passed" orderList={closedDatePassedList} loading={orderLoading} />
            </Grid>
        </Grid>
    );
}

export default DashboardTableStack;
