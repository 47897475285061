import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Link as MULink, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { DataGrid } from 'components/common/styled';
import OrderStatusChip from 'components/Order/components/OrderStatusChip';
import OrderTransactionChip from 'components/Order/components/OrderTransactionChip';
import { PARCEL_TYPE } from 'helpers/constants';
import { formatAddress, formatISODate } from 'helpers/utils';

const ParcelColumnList = [
    {
        field: PARCEL_TYPE.title_exam,
        headerName: 'Title Exam',
        valueFormatter: (params) => {
            // TODO show eta tag if available else show parcel status
            return _.startCase(_.get(params, 'value.current_status.status'));
        },
    },
    {
        field: PARCEL_TYPE.title_clearing,
        headerName: 'Title Clearing',
        valueFormatter: (params) => {
            // TODO show cleared tag if available else show parcel status
            return _.startCase(_.get(params, 'value.current_status.status'));
        },
    },
    // {
    //     field: PARCEL_TYPE.payoffs,
    //     headerName: 'Payoffs',
    //     valueFormatter: (params) => {
    //         //  TODO show cleared tag if available else show parcel status
    //         return _.startCase(_.get(params, 'value.current_status.status'));
    //     }
    // },
    {
        field: PARCEL_TYPE.hoa,
        headerName: 'HOA',
        valueFormatter: (params) => {
            //  TODO show eta tag if available else show parcel status
            return _.startCase(_.get(params, 'value.current_status.status'));
        },
    },
];

function getParcelByName(params) {
    return _.find(params.row.parcels, (parcel) => parcel.type === params.field);
}

function getParcelStatus(params) {
    return _.startCase(_.get(params, 'value.current_status.status'));
}

const columns = [
    {
        field: 'status',
        headerName: 'Status',
        valueFormatter: (params) => _.startCase(params.value),
        renderCell: (params) => <OrderStatusChip status={params.value} size="small" />,
        width: 120,
    },
    {
        field: 'qualia_order_number',
        headerName: 'Order #',
        width: 150,
        renderCell: (params) => (
            <MULink
                component={Link}
                to={`/order/${params.row.id}`}
                underline="hover"
                // fontSize="14px"
                sx={{
                    color: (theme) => theme.palette.text.link,
                }}
            >
                {params.value}
            </MULink>
        ),
    },
    {
        field: 'property_address',
        headerName: 'Address',
        renderCell: (params) => {
            const address = formatAddress(params.value);
            return (
                <MULink
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`}
                    target="_blank"
                    underline="hover"
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                        // fontWeight: '500',
                        '& .MuiSvgIcon-root': {
                            visibility: 'hidden',
                        },
                        '&:hover .MuiSvgIcon-root': {
                            visibility: 'visible',
                        },
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <span>{address}</span>
                        <OpenInNewOutlinedIcon
                            sx={{
                                fontSize: '14px',
                            }}
                        />
                    </Stack>
                </MULink>
            );
        },
        width: 350,
    },
    {
        field: 'transaction_type',
        headerName: 'Transction Type',
        valueFormatter: (params) => _.startCase(params.value),
        renderCell: (params) => <OrderTransactionChip transactionType={params.value} size="small" />,
        width: 150,
    },
    {
        field: 'order_type',
        headerName: 'Order Type',
        valueFormatter: (params) => _.startCase(params.value),
        width: 150,
    },
    {
        field: 'close_date',
        headerName: 'Close Date',
        width: 150,
        renderCell: (params) => {
            return (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body2" color="text.primary" sx={{ minWidth: '68px' }}>
                        {formatISODate(params.value)}
                    </Typography>
                    <Tooltip
                        title={params.row.confirmed_close_date ? 'Confirmed' : 'Tentative'}
                        placement="bottom"
                        enterDelay={300}
                    >
                        <FiberManualRecordIcon
                            fontSize="small"
                            sx={{
                                color: (theme) =>
                                    params.row.confirmed_close_date
                                        ? theme.palette.success.main
                                        : theme.palette.warning.main,
                                width: '16px',
                                height: '16px',
                            }}
                        />
                    </Tooltip>
                </Stack>
            );
        },
    },
    /* Parcels */
    ..._.map(ParcelColumnList, (parcelCol) => {
        return {
            field: parcelCol.field,
            headerName: parcelCol.headerName,
            valueGetter: getParcelByName,
            valueFormatter: parcelCol.valueFormatter || getParcelStatus,
            width: 200,
        };
    }),
];

function OrderListGrid({ orderList, loading, refetch }) {
    const [pageSize, setPageSize] = useState(10);

    return (
        <div>
            <DataGrid
                rows={orderList}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
                pagination
                loading={loading}
                refetch={refetch}
                density="comfortable"
                getRowId={(row) => row.id}
                disableColumnMenu
                disableColumnFilter
                disableSelectionOnClick
                autoHeight
            />
        </div>
    );
}

OrderListGrid.propTypes = {
    orderList: PropTypes.array.isRequired,
    refetch: PropTypes.func,
    loading: PropTypes.bool,
};

export default OrderListGrid;
