import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useGetOrderMembersQuery } from 'api/order';
import DocumentAccessNotificationForm from './DocumentAccessNotificationForm';

function ManageDocumentAccessNotification({ orderId, document, handleClose }) {
    const { data: orderMembers, isLoading: membersLoading } = useGetOrderMembersQuery(orderId);

    const documentAccessMemberIdMap = useMemo(
        () => (document?.access ? _.keyBy(document.access, 'order_member_id') : {}),
        [document]
    );

    const orderMemberOptions = useMemo(() => {
        return _.filter(orderMembers, (member) => _.includes(_.keys(documentAccessMemberIdMap), member.id));
    }, [orderMembers, documentAccessMemberIdMap]);

    if (!orderId || !document) {
        return null;
    }

    return (
        <Stack spacing={4} sx={{ width: '100%' }}>
            <Stack spacing={1}>
                <Typography variant="h5">Send Document Access Notifications</Typography>
                <Typography variant="body1" color="text.secondary">
                    {document.file_name}
                </Typography>
            </Stack>

            <DocumentAccessNotificationForm
                orderId={orderId}
                document={document}
                orderMembers={orderMemberOptions}
                handleSubmit={handleClose}
            />
        </Stack>
    );
}

ManageDocumentAccessNotification.propTypes = {
    handleClose: PropTypes.func.isRequired,
    orderId: PropTypes.string.isRequired,
    document: PropTypes.object,
};

export default ManageDocumentAccessNotification;
