import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import BaseDialog from '../common/BaseDialog';
import EditLien from './EditLien';
import EditLienMembers from './EditLienMembers';

/* 
    split form into 2 tabs:
    1. base lien data (amount, description) and additional data (json schema)
    2. lien members
*/

const LIEN_FORM_TAB = {
    details: 'details',
    members: 'members',
};

const EditLienModal = ({ lien, open, handleClose }) => {
    const [activeFormTab, setActiveFormTab] = useState(LIEN_FORM_TAB.details);

    const handleChange = (event, newActiveTab) => {
        setActiveFormTab(newActiveTab);
    };

    const handleCloseLocal = () => {
        handleClose();

        // Reset to default tab
        setActiveFormTab(LIEN_FORM_TAB.details);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleCloseLocal()}
            DialogProps={{
                maxWidth: 'md',
                fullWidth: false,
                sx: {
                    '& .MuiDialog-container': {
                        alignItems: 'flex-start',
                    },
                },
            }}
        >
            {lien && (
                <Box
                    sx={{
                        padding: (theme) => theme.spacing(6, 8),
                        width: '480px',
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ marginBottom: (theme) => theme.spacing(5) }}
                    >
                        <Typography variant="h4">Update {lien.name}</Typography>

                        <ToggleButtonGroup
                            exclusive
                            // color="primary"
                            size="small"
                            value={activeFormTab}
                            onChange={handleChange}
                        >
                            <ToggleButton value={LIEN_FORM_TAB.details}>Details</ToggleButton>
                            <ToggleButton value={LIEN_FORM_TAB.members}>Members</ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>

                    {activeFormTab === LIEN_FORM_TAB.details && <EditLien lien={lien} onSuccess={handleCloseLocal} />}

                    {activeFormTab === LIEN_FORM_TAB.members && <EditLienMembers lien={lien} />}
                </Box>
            )}
        </BaseDialog>
    );
};

EditLienModal.propTypes = {
    lien: PropTypes.object,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default EditLienModal;
