import StarIcon from '@mui/icons-material/Star';
import { Badge, Box, IconButton, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useUpdateNoteMutation } from 'api/note';
import useNoteData from 'components/common/hooks/useNoteData';
import UserAvatar from 'components/common/UserAvatar';
import { generateRelativeTime } from 'helpers/utils';
import NoteTag from './NoteTag';

function truncateText(text, size) {
    return text.length > size ? text.slice(0, size - 1) + '…' : text;
}

/*
    TODO:
    - Reverse style of 'title' & 'subtitle' in CardHeader
    - Add tags & reply avatars to bottom of card
    - Add menu for the header action
    - Verify onClick doesn't interfere with the menu click events
    - Implement lexicon for note content & parse plaintext for preview text
*/

function NoteListStackItem({ note, setActiveNoteId, isActive = false, showTag = true }) {
    const { order, entities, entityName, replyNotes, users, primaryUser } = useNoteData(note);

    const [updateNote, { isLoading: isUpdateNoteLoading }] = useUpdateNoteMutation();

    const handleUnpin = (event) => {
        event.preventDefault();

        return submitUpdate({
            pinned: false,
        });
    };

    const submitUpdate = async (updatePayload) => {
        const updateNoteFullPayload = {
            orderId: note.order_id,
            noteId: note.id,
            noteData: updatePayload,
        };

        const { data } = await updateNote(updateNoteFullPayload);

        if (data) {
            return true;
        } else {
            console.warn(`Failed to be update note ${note.id}`);
            return false;
        }
    };

    // TODO calculate in note hook and use 'latest' date from note and all replies
    const relativeTime = useMemo(() => generateRelativeTime(note.created_datetime), [note.created_datetime]);

    const replyCount = replyNotes.length;
    const userCount = _.keys(users).length;
    const showReplyCount = replyCount > 0;
    const showReplyAvatar = showReplyCount && userCount > 0;
    const showFooter = showReplyAvatar || (showTag && !!entityName) || note.pinned;

    return (
        <Box
            className={clsx('note', isActive ? 'active' : '')}
            onClick={() => setActiveNoteId(note.id)}
            sx={{
                overflow: 'visible',
                p: 3,
            }}
        >
            <Stack direction="row" alignItems="center" spacing={2}>
                <Badge
                    badgeContent={replyCount}
                    overlap="circular"
                    // color="primary"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <UserAvatar user={primaryUser} />
                </Badge>

                <Stack direction="column" spacing={3} flexGrow="1">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Typography variant="body1" color="text.primary">
                            {truncateText(note.content, 60)}
                        </Typography>

                        <Typography variant="body2" fontWeight={400} textAlign="right" sx={{ minWidth: '48px' }}>
                            {relativeTime}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            {showFooter && (
                <Stack direction="row" spacing={2} sx={{ pt: 2 }}>
                    <Box sx={{ minWidth: '40px', width: '40px' }} />

                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%' }}
                    >
                        <Box flexGrow="1" />

                        {note.pinned && (
                            <IconButton aria-label="pinned" onClick={(e) => handleUnpin(e)}>
                                <StarIcon />
                            </IconButton>
                        )}

                        {showTag && (
                            <NoteTag
                                orderId={order.id}
                                tag={{ entity_id: note.entity_id, entity_type: note.entity_type }}
                                setTag={() => console.warn('Cannot update tag here')}
                                editable={false}
                            />
                        )}
                    </Stack>
                </Stack>
            )}
        </Box>
    );
}

NoteListStackItem.propTypes = {
    note: PropTypes.object.isRequired,
    setActiveNoteId: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
};

export default NoteListStackItem;
