import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import _ from 'lodash';
import React from 'react';

const CustomDateTimeWidget = (props) => {
    const handleChange = (value) => {
        props.onChange(value ? value.toISOString() : null);
    };

    return (
        <DateTimePicker
            variant="inline"
            label={_.get(props, 'schema.title', '')}
            value={props.value}
            onChange={handleChange}
            minutesStep={5}
            renderInput={(params) => <TextField {...params} />}
            disabled={_.get(props, 'readonly', false)}
            // maxDate={}   # TODO
            // disablePast // TODO - figure out how to impliment this using schema params
        />
    );
};

export default CustomDateTimeWidget;
