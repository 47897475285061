import React from 'react';
import { IMaskInput } from 'react-imask';

// TODO add custom rjsf widget using this mask

const DollarFieldMask = React.forwardRef(function DollarFieldMask(props, ref) {
    const { value, onChange, ...other } = props;

    // Since this is a 'dollar' field we can expect the value to be a numerical string
    // Additionally, the outgoing value is also a string

    const localChangeHandler = (newValue, mask) => {
        const floatValue = parseFloat(newValue);
        
        // Ensure the value is a number - handles null/empty cases when the entire input value is removed
        if (isNaN(floatValue)) {
            onChange({ target: { name: props.name, value: '0', mask } });
            return;
        }

        // Compare inbound value against the outbound value when both are cast as floats
        // Only update if there is a difference
        if (parseFloat(value) !== floatValue) {
            onChange({ target: { name: props.name, value: newValue, mask } });
        }
    }

    // If the value is a fraction, ensure it has two decimal places
    // Otherwise, don't pad
    // const isInteger = Number.isInteger(parseFloat(value));

    return (
      <IMaskInput
        {...other}
        value={value}
        mask={'$amount'}
        lazy={false}
        blocks={{
            amount: {
                mask: Number,
                radix: ".",
                thousandsSeparator: ',',
                padFractionalZeros: false,
                // padFractionalZeros: !isInteger,
                min: 0,
                max: 1000000000,    // We probably aren't going to have any orders over a billion dollars
            },
        }}
        inputRef={ref}
        onAccept={localChangeHandler}
        unmask
        overwrite={false}
      />
    );
});

export default DollarFieldMask;