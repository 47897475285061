import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { rootApi } from './root';

export const DOCUMENT_TAG = _.toUpper(ENTITY_TYPE.document);
export const DOCUMENT_ACCESS_TAG = _.toUpper(ENTITY_TYPE.document_access);

export const documentApi = rootApi.injectEndpoints({
    reducerPath: 'documentApi',
    endpoints: (builder) => ({
        getOrderDocuments: builder.query({
            query: (orderId) => `order/${orderId}/documents`,
            providesTags: [DOCUMENT_TAG],
        }),
        createDocument: builder.mutation({
            query: ({orderId, documentData}) => ({
                url: `order/${orderId}/documents`,
                method: 'POST',
                body: documentData,
                // NOTE: there is a bug atm when content type 'multipart/form-data' is directly specified
                // headers: {
                //     'Content-Type': 'multipart/form-data',
                // },
            }),
            invalidatesTags: [DOCUMENT_TAG],
        }),
        updateDocument: builder.mutation({
            query: ({documentId, documentData}) => ({
                url: `document/${documentId}`,
                method: 'PUT',
                body: documentData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [DOCUMENT_TAG],
        }),
        deleteDocument: builder.mutation({
            query: ({documentId}) => ({
                url: `document/${documentId}`,
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [DOCUMENT_TAG],
        }),
        getDocumentAccess: builder.query({
            query: (documentId) => `document/${documentId}/access`,
            providesTags: [DOCUMENT_ACCESS_TAG],
        }),
        createDocumentAccess: builder.mutation({
            query: ({documentId, documentAccessData}) => ({
                url: `document/${documentId}/access`,
                method: 'POST',
                body: documentAccessData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [DOCUMENT_TAG, DOCUMENT_ACCESS_TAG],
        }),
        bulkUpdateDocumentAccess: builder.mutation({
            query: ({documentId, documentAccessData}) => ({
                url: `document/${documentId}/access`,
                method: 'PUT',
                body: documentAccessData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [DOCUMENT_TAG, DOCUMENT_ACCESS_TAG],
        }),
        deleteDocumentAccess: builder.mutation({
            query: ({documentId, documentAccessId}) => ({
                url: `document/${documentId}/access/${documentAccessId}`,
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [DOCUMENT_TAG, DOCUMENT_ACCESS_TAG],
        }),
        sendDocumentAccessNotification: builder.mutation({
            query: ({documentId, documentAccessIds}) => ({
                url: `document/${documentId}/access/notification`,
                method: 'POST',
                body: documentAccessIds,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
        }),
    }),
    overrideExisting: false,
})


export function useGetOrderDocumentsByAssociatedParcelQuery({orderId, parcelId}) {
    const { useGetOrderDocumentsQuery } = documentApi;
    return useGetOrderDocumentsQuery(orderId, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((orderDocument) => _.find(orderDocument.associations, (association) => association.parcel_id === parcelId)),
            error,
            isLoading
        }),
    });
}

// TODO get specific order document from documents list
export function useGetOrderDocumentsByAssociatedParcelTypeQuery({orderId, parcelType}) {
    const { useGetOrderDocumentsQuery } = documentApi;
    return useGetOrderDocumentsQuery(orderId, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((orderDocument) => orderDocument.associated_parcel_type === parcelType),
            error,
            isLoading
        }),
    });
}

export function useGetOrderDocumentQuery({orderId, documentId}) {
    const { useGetOrderDocumentsQuery } = documentApi;
    return useGetOrderDocumentsQuery(orderId, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.find((orderDocument) => orderDocument.id === documentId),
            error,
            isLoading
        }),
    });
}

export const { 
    useGetOrderDocumentsQuery, 
    useCreateDocumentMutation, 
    useUpdateDocumentMutation, 
    useDeleteDocumentMutation, 
    useGetDocumentAccessQuery, 
    useCreateDocumentAccessMutation, 
    useBulkUpdateDocumentAccessMutation,
    useDeleteDocumentAccessMutation,
    useSendDocumentAccessNotificationMutation,
} = documentApi