import AddIcon from '@mui/icons-material/Add';
import { Fab, List, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import LienMemberListItem from './LienMemberListItem';

const LienMemberList = ({ lien, lienMembers, orderMembers, viewAdd }) => {
    return (
        <Stack spacing={0}>
            <Typography variant="overline">Lien Members ({lienMembers.length})</Typography>

            <List>
                {_.map(lienMembers, (lienMember) => {
                    return (
                        <LienMemberListItem
                            key={lienMember.id}
                            lien={lien}
                            lienMember={lienMember}
                            orderMembers={orderMembers}
                        />
                    );
                })}
            </List>

            <Stack direction="row" justifyContent="flex-end" sx={{ paddingTop: (theme) => theme.spacing(2) }}>
                <Fab size="small" color="primary" aria-label="add" onClick={() => viewAdd()}>
                    <AddIcon />
                </Fab>
            </Stack>
        </Stack>
    );
};

LienMemberList.propTypes = {
    lien: PropTypes.object.isRequired,
    lienMembers: PropTypes.array.isRequired,
    orderMembers: PropTypes.array.isRequired,
    viewAdd: PropTypes.func.isRequired,
};

export default LienMemberList;
