import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Grid, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { copyToClipboard, formatAddress, formatPhone } from '../../../helpers/utils';
import EditLegalEntity from './EditLegalEntity';

function LegalEntityDetailsLabel({ label }) {
    return (
        <Typography
            variant="body2"
            sx={{
                fontWeight: '400',
                color: 'text.secondary',
            }}
        >
            {label}
        </Typography>
    );
}

function LegalEntityDetailsContent({ content, typographyProps = {} }) {
    const [copyText, setCopyText] = useState('Copy');

    const handleCopy = (event, text) => {
        event.stopPropagation();
        event.preventDefault();
        copyToClipboard(text);
        setCopyText('Copied!');
    };

    return (
        <Box>
            {content ? (
                <Tooltip title={copyText} onClose={() => setCopyText('Copy')} placement="right" enterDelay={300}>
                    <Typography
                        variant="body1"
                        component="span"
                        onClick={(event) => handleCopy(event, content)}
                        {...typographyProps}
                    >
                        {content || '—'}
                    </Typography>
                </Tooltip>
            ) : (
                <Typography variant="body1" component="span">
                    {'—'}
                </Typography>
            )}
        </Box>
    );
}

const RowParams = {
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
};

function LegalEntityDetailsSection({ label, content, row = false, typographyProps = {} }) {
    const stackParams = row ? RowParams : {};

    return (
        <Grid item xs={12}>
            <Stack {...stackParams}>
                <LegalEntityDetailsLabel label={label} />
                <LegalEntityDetailsContent content={content} typographyProps={typographyProps} />
            </Stack>
        </Grid>
    );
}

function LegalEntityDetailsGrid({ legalEntity }) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <Grid container alignItems="flex-start" spacing={2}>
            <LegalEntityDetailsSection label="Legal Type" content={_.get(legalEntity, 'type')} row={matches} />

            <LegalEntityDetailsSection
                label="Commercial Type"
                content={_.startCase(legalEntity.commercial_type)}
                row={matches}
            />

            <LegalEntityDetailsSection
                label="State of Incorporation"
                content={_.startCase(legalEntity.state_of_incorporation)}
                row={matches}
            />

            <LegalEntityDetailsSection label="Tax ID" content={_.startCase(legalEntity.tax_id)} row={matches} />

            <LegalEntityDetailsSection
                label="Phone"
                content={legalEntity.contact?.phone ? formatPhone(legalEntity.contact.phone) : null}
                row={matches}
            />

            <LegalEntityDetailsSection label="Email" content={_.get(legalEntity, 'contact.email')} row={matches} />

            <LegalEntityDetailsSection
                label="Address"
                content={legalEntity.address?.street ? formatAddress(legalEntity.address, true) : null}
                row={matches}
                typographyProps={{
                    whiteSpace: 'pre-wrap',
                    textAlign: matches ? 'right' : 'left',
                    display: 'inline-block',
                }}
            />
        </Grid>
    );
}

function LegalEntityDetailsCore({ legalEntity }) {
    const [editMode, setEditMode] = useState(false);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Details</Typography>
                <IconButton size="small" onClick={() => toggleEditMode()}>
                    {editMode ? <CloseIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                </IconButton>
            </Stack>

            {editMode ? (
                <EditLegalEntity
                    legalEntity={legalEntity}
                    handleCancel={() => toggleEditMode()}
                    handleComplete={() => toggleEditMode()}
                />
            ) : (
                <LegalEntityDetailsGrid legalEntity={legalEntity} />
            )}
        </Paper>
    );
}

LegalEntityDetailsCore.propTypes = {
    legalEntity: PropTypes.object.isRequired,
};

export default LegalEntityDetailsCore;
