
import { Stack, Switch, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { addBusinessDays, differenceInBusinessDays, format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


function CloseDateField(props) {
    const { openDate, closeDate, setCloseDate, confirmedCloseDate, setConfirmedCloseDate, disableFields = false, editMode = false, defaultCloseDatePeriod = '20' } = props;

    const [relativeCloseDatePeriod, setRelativeCloseDatePeriod] = useState(defaultCloseDatePeriod);

    // useEffect(() => {
    //     if (closeDate && openDate) {
    //         // Calculate 'relative' close date period in days when close date changes
    //         const dayDiff = differenceInDays(parseISO(closeDate), parseISO(openDate));
            
    //         if (dayDiff && dayDiff !== parseInt(relativeCloseDatePeriod)) {
    //             setRelativeCloseDatePeriod(dayDiff.toString());
    //         }
    //     }
    // }, [closeDate]);

    // useEffect(() => {
    //     // Calculate close date when 'relative' close date period or open date changes
    //     if (relativeCloseDatePeriod && openDate) {
    //         const newCloseDate = add(parseISO(openDate), { days: parseInt(relativeCloseDatePeriod) });
    //         const newCloseDateString = format(newCloseDate, 'yyyy-MM-dd');
            
    //         if (newCloseDateString !== closeDate) {
    //             setCloseDate(newCloseDateString);
    //         }
    //     }
    // }, [relativeCloseDatePeriod, openDate])

    useEffect(() => {
        if (openDate && !editMode) {
            // Re-calculate close date when open date changes when not in edit mode
            const newCloseDate = calculateCloseDate(openDate, relativeCloseDatePeriod);
            const newCloseDateString = format(newCloseDate, 'yyyy-MM-dd');
            
            if (newCloseDateString !== closeDate) {
                setCloseDate(newCloseDateString);
            }
        } else if (openDate && editMode) {
            // Re-calculate 'relative' close date period in days when open date changes in edit mode
            const dayDiff = calculateRelativePeriod(openDate, closeDate);
            setRelativeCloseDatePeriod(dayDiff.toString());
        }
    }, [openDate]);

    const calculateCloseDate = (startDate, relativePeriod) => {
        return addBusinessDays(parseISO(startDate), parseInt(relativePeriod));
    }


    const calculateRelativePeriod = (startDate, endDate) => {
        return differenceInBusinessDays(parseISO(endDate), parseISO(startDate));
    }

    const handleCloseDateChange = (newDate) => {
        // Handle direct changes to close date input field
        
        if (openDate) {
            // Calculate 'relative' close date period in days when close date changes
            const dayDiff = calculateRelativePeriod(openDate, newDate);
            setRelativeCloseDatePeriod(dayDiff.toString());
        }

        // Update close date
        setCloseDate(newDate);
    }


    const handleRelativePeriodChange = (newPeriod) => {
        // Handle direct changes to relative period input field
        
         // Recalculate close date
        if (openDate) {
            const newCloseDate = calculateCloseDate(openDate, newPeriod);
            const newCloseDateString = format(newCloseDate, 'yyyy-MM-dd');
            setCloseDate(newCloseDateString);
        }

        // Update relative period
        setRelativeCloseDatePeriod(newPeriod);
    }

    return (
        <Stack direction="row" alignItems="center" spacing={2}>

            <DatePicker
                autoOk
                label="Close Date"
                openTo="day"
                inputFormat="MM/dd/yyyy"
                value={closeDate ? parseISO(closeDate) : null}
                inputVariant="outlined"
                onChange={(date) => {
                    // NOTE: not strict comparison due to issues with date error response object
                    if (date && date != 'Invalid Date') {
                        handleCloseDateChange(format(date, 'yyyy-MM-dd'));
                    } else {
                        handleCloseDateChange(null);
                    }
                }}
                disabled={disableFields}
                renderInput={(params) => <TextField size="small" {...params} />}
                disablePast={!editMode}
                clearable
                required
                sx={{flexGrow: 1}}
            />
            
            <TextField
                label=""
                value={relativeCloseDatePeriod}
                onChange={({ target }) => handleRelativePeriodChange(target.value)}
                variant='outlined'
                type='number'
                size="small"
                InputProps={{
                    endAdornment: (<span>days</span>),
                    inputProps: {min: "0", step: "1"}
                }}
                style={{ maxWidth: "96px" }}
                disabled={disableFields}
                required
            />

            <Stack direction="row" alignItems="center" spacing={1}>
                <Switch
                    color="primary"
                    checked={confirmedCloseDate}
                    onChange={() => setConfirmedCloseDate(!confirmedCloseDate)}
                />
                <Typography>{ confirmedCloseDate ? 'Confirmed' : 'Tentative' }</Typography>
            </Stack>

        </Stack>
    );
}

CloseDateField.propTypes = {
    openDate: PropTypes.string, 
    closeDate: PropTypes.string, 
    setCloseDate: PropTypes.func.isRequired,
    confirmedCloseDate: PropTypes.bool.isRequired, 
    setConfirmedCloseDate: PropTypes.func.isRequired,
    disableFields: PropTypes.bool.isRequired,
}

export default CloseDateField;