import SearchIcon from '@mui/icons-material/Search';
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useCreateLienMemberMutation } from '../../../../api/lien';
import { useGetOrderMembersQuery } from '../../../../api/order';
import { ORDER_ROLE_TYPE } from '../../../../helpers/constants';
import { Button, FilterTextField, LoadingButton } from '../../../common/styled';

const LIEN_MEMBER_FILTER_TYPE = {
    search: 'search',
    role: 'role',
};

const DEFAULT_FILTER_STATE = {
    [LIEN_MEMBER_FILTER_TYPE.search]: '',
    [LIEN_MEMBER_FILTER_TYPE.role]: [ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.seller, ORDER_ROLE_TYPE.borrower],
};

const filterOrderMembers = (orderMembers, filters) => {
    const filteredOrderMembers = [];

    _.forEach(orderMembers, (orderMember) => {
        if (!_.includes(filters.role, orderMember.role)) {
            return;
        }

        if (filters.search.length > 3) {
            // Searching member name field as this is the same for legal entites and users
            const memberName = _.get(orderMember, 'member.name', '');

            if (!_.includes(_.toLower(memberName), _.toLower(filters.search))) {
                return;
            }
        }

        filteredOrderMembers.push(orderMember);
    });

    return filteredOrderMembers;
};

const CreateLienMember = ({ lien, handleCancel, handleComplete }) => {
    const searchInputRef = useRef();
    const [createLienMember, { isLoading: isCreatingLienMember }] = useCreateLienMemberMutation();

    // TODO load current lien members as well
    const { data: orderMembers, isError: membersError, isLoading: membersLoading } = useGetOrderMembersQuery(
        lien.order_id
    );

    const [filters, setFilters] = useState({
        ...DEFAULT_FILTER_STATE,
    });
    const [selectedOrderMemberId, setSelectedOrderMemberId] = useState(null);

    const filteredOrderMembers = useMemo(() => filterOrderMembers(orderMembers, filters), [orderMembers, filters]);

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string isn't valid for some filters
        };

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));

        // Reset selected order member
        setSelectedOrderMemberId(null);
    };

    const changeHandler = (event) => {
        handleFilter(event);
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 300), []);

    const handleAddLienMember = async (lienMemberData) => {
        const addLienMemberPayload = {
            lienId: lien.id,
            memberData: lienMemberData,
        };

        const { data } = await createLienMember(addLienMemberPayload);
        return !!data;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const lienMemberData = {
            type: 'lienee', // hardcoded for now as it is only option
        };

        if (selectedOrderMemberId) {
            lienMemberData.order_member_id = selectedOrderMemberId;
        } else {
            lienMemberData.name = filters.search;
        }

        handleAddLienMember(lienMemberData).then((success) => {
            if (success) {
                handleComplete();
            }
        });
    };

    useEffect(() => {
        // Stop the invocation of the debounced function after unmounting
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Typography variant="h6">New Lien Member</Typography>

            <FilterTextField
                name={LIEN_MEMBER_FILTER_TYPE.search}
                label=""
                type="search"
                variant="outlined"
                placeholder="Search order members ..."
                onChange={debouncedChangeHandler}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                inputRef={searchInputRef}
                autoFocus
            />

            <FormControl
                sx={{
                    padding: (theme) => theme.spacing(2, 0),
                }}
            >
                <FormLabel id="order-members-radio-label">
                    <Typography variant="overline">Order Members ({filteredOrderMembers.length})</Typography>
                </FormLabel>
                <RadioGroup
                    aria-labelledby="order-members-radio-label"
                    name="radio-buttons-group"
                    value={selectedOrderMemberId}
                    onChange={(e) => setSelectedOrderMemberId(e.target.value)}
                >
                    {_.map(filteredOrderMembers, (orderMember) => {
                        return (
                            <FormControlLabel
                                key={orderMember.id}
                                value={orderMember.id}
                                control={<Radio />}
                                label={`${_.get(orderMember, 'member.name')} (${_.startCase(orderMember.role)})`}
                            />
                        );
                    })}
                </RadioGroup>
            </FormControl>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                <Button variant="text" color="default" onClick={() => handleCancel()} disableElevation>
                    Cancel
                </Button>

                <LoadingButton
                    color="primary"
                    variant="contained"
                    sx={{
                        minWidth: '120px',
                    }}
                    onClick={handleSubmit}
                    loading={isCreatingLienMember}
                    disabled={!selectedOrderMemberId && filters.search.length < 3}
                    disableElevation
                >
                    {selectedOrderMemberId && 'Add Selected Member'}
                    {!selectedOrderMemberId && filters.search.length >= 3 && `Add "${filters.search}"`}
                    {!selectedOrderMemberId && filters.search.length < 3 && 'Add'}
                </LoadingButton>
            </Stack>
        </Stack>
    );
};

CreateLienMember.propTypes = {
    lien: PropTypes.object.isRequired,
    handleComplete: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};

export default CreateLienMember;
