import { Box } from '@mui/material';

import useSocketCacheManager from 'components/common/hooks/useSocketCacheManager';
import { Main } from './layouts';


const AppLayout = ({ children }) => {
    // Initialize socket cache manager here since this component is only rendered after successful auth check
    // This ensures that the socket cache manager is only initialized after successful auth check
    // And ensures that the socket itself is cleaned up on logout/session invalidation
    const socket = useSocketCacheManager();

    return (
        <Main>
            <Box 
                sx={{
                    position: 'relative'
                }}
            >
                {children}
            </Box>
        </Main>
    );
};

export default AppLayout;
