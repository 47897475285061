import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { useDeleteDocumentMutation, useGetOrderDocumentsQuery } from 'api/document';
import { useGetOrderQuery } from 'api/order';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { LoadingButton } from 'components/common/styled';
import { filterDocuments, requestDocumentDownload } from 'helpers/utils';

import OrderBreadcrumbs from 'components/Order/OrderDetails/components/OrderBreadcrumbs';
import DocumentAccessDialog from './components/DocumentAccessDialog';
import DocumentAccessNotificationDialog from './components/DocumentAccessNotificationDialog';
import DocumentListFilters, { INITIAL_DOCUMENT_FILTERS } from './components/DocumentListFilters';
import DocumentListGrid from './components/DocumentListGrid';
import DocumentUpload from './components/DocumentUpload';

const DocumentList = ({ orderId }) => {
    const { data: order } = useGetOrderQuery(orderId);
    const { data: documents, isFetching: documentsFetching, refetch: refetchDocuments } = useGetOrderDocumentsQuery(
        orderId
    );
    const [deleteDocument, { isLoading: deleteDocumentLoading }] = useDeleteDocumentMutation();
    const [confirmDeleteId, setConfirmDeleteId] = useState(null);
    const [updateAccessId, setUpdateAccessId] = useState(null);
    const [notificationAccessId, setNotificationAccessId] = useState(null);
    const [confirmBulkDelete, setConfirmBulkDelete] = useState(false);
    const [selectedDocumentIdList, setSelecetedDocumentIdList] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [filters, setFilters] = useState({
        ...INITIAL_DOCUMENT_FILTERS,
    });

    const filteredDocuments = useMemo(() => filterDocuments(documents, filters), [documents, filters]);
    const documentMap = useMemo(() => _.keyBy(documents, 'id'), [documents]);

    const resetFilters = () => {
        setFilters({
            ...INITIAL_DOCUMENT_FILTERS,
        });
    };

    const resetData = () => {
        refetchDocuments();
    };

    const handleConfirm = (documentId) => {
        setConfirmDeleteId(documentId);
    };

    const handleDelete = (documentId) => {
        setIsDeleting(true);

        submitDelete(documentId).finally(() => {
            setIsDeleting(false);
        });
    };

    const handleUpdateAccess = (documentId) => {
        setUpdateAccessId(documentId);
    };

    const handleAccessNotification = (documentId) => {
        setNotificationAccessId(documentId);
    };

    const handleBulkDelete = () => {
        setIsDeleting(true);

        submitBulkDelete(selectedDocumentIdList).finally(() => {
            setIsDeleting(false);
        });
    };

    const submitDelete = async (documentId) => {
        const { data } = await deleteDocument({ documentId });
        return !!data;
    };

    const submitBulkDelete = async (documentIdList) => {
        // Syncronously delete each document in the list as a chain of promises
        // Can potentially be done in parallel using Promise.all() but impacts ux/design
        const response = await documentIdList.reduce(async (promiseChain, currentDocumentId, index) => {
            await promiseChain;

            return submitDelete(currentDocumentId);
        }, Promise.resolve());
        return response;
    };

    const handleBulkDownload = () => {
        setIsDownloading(true);

        // Either use selected list or all filteredDocuments
        const downloadIdList =
            selectedDocumentIdList.length > 0 ? selectedDocumentIdList : _.map(filteredDocuments, 'id');

        submitBulkDownload(downloadIdList).finally(() => {
            setIsDownloading(false);
        });
    };

    const submitDownload = async (documentId) => {
        const response = await requestDocumentDownload(documentId);
        return response;
    };

    const submitBulkDownload = async (downloadIdList) => {
        // Syncronously download each document in the list as a chain of promises
        // Can potentially be done in parallel using Promise.all() but impacts ux/design
        const response = await downloadIdList.reduce(async (promiseChain, currentDocumentId, index) => {
            await promiseChain;

            return submitDownload(currentDocumentId);
        }, Promise.resolve());
        return response;
    };

    const loading = documentsFetching || deleteDocumentLoading || isDeleting || isDownloading;

    return (
        <Stack spacing={3}>
            <OrderBreadcrumbs orderId={orderId} />

            <Typography variant="h1">Documents</Typography>

            <DocumentUpload orderId={orderId} />

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                sx={{ paddingTop: (theme) => theme.spacing(2) }}
            >
                <Box sx={{ flexGrow: 1, maxWidth: '560px' }}>
                    <DocumentListFilters
                        orderId={orderId}
                        filters={filters}
                        setFilters={setFilters}
                        resetFilters={resetFilters}
                    />
                </Box>

                <Stack direction="row" alignItems="center" spacing={2}>
                    <LoadingButton
                        variant="outlined"
                        color="default"
                        onClick={() => handleBulkDownload()}
                        startIcon={<DownloadIcon />}
                        loading={isDownloading}
                        disabled={filteredDocuments.length === 0}
                        loadingPosition="start"
                        sx={{
                            borderRadius: (theme) => theme.spacing(3),
                        }}
                    >
                        Download{' '}
                        {selectedDocumentIdList.length === 0 ? 'All' : `Selected (${selectedDocumentIdList.length})`}
                    </LoadingButton>
                    <LoadingButton
                        variant="outlined"
                        color="default"
                        onClick={() => setConfirmBulkDelete(true)}
                        startIcon={<DeleteIcon />}
                        loading={deleteDocumentLoading || isDeleting}
                        disabled={selectedDocumentIdList.length === 0}
                        loadingPosition="start"
                        sx={{
                            borderRadius: (theme) => theme.spacing(3),
                        }}
                    >
                        Delete Selected ({selectedDocumentIdList.length})
                    </LoadingButton>
                </Stack>
            </Stack>

            <Box>
                <DocumentListGrid
                    documentList={filteredDocuments}
                    setSelecetedDocumentIdList={setSelecetedDocumentIdList}
                    loading={loading}
                    refetch={resetData}
                    handleDelete={handleConfirm}
                    handleUpdateAccess={handleUpdateAccess}
                    handleAccessNotification={handleAccessNotification}
                />
            </Box>

            <ConfirmDialog
                open={!!confirmDeleteId}
                title="Delete Document"
                body="Are you sure you want to delete this document?"
                handleConfirm={() => handleDelete(confirmDeleteId)}
                handleClose={() => setConfirmDeleteId(null)}
            />

            <ConfirmDialog
                open={!!confirmBulkDelete}
                title="Delete Selected Documents"
                body={`Are you sure you want to delete the ${selectedDocumentIdList.length} selected documents?`}
                handleConfirm={() => handleBulkDelete()}
                handleClose={() => setConfirmBulkDelete(false)}
            />

            <DocumentAccessDialog
                open={!!updateAccessId}
                orderId={orderId}
                document={updateAccessId ? documentMap[updateAccessId] : null}
                handleClose={() => setUpdateAccessId(null)}
            />

            <DocumentAccessNotificationDialog
                open={!!notificationAccessId}
                orderId={orderId}
                document={notificationAccessId ? documentMap[notificationAccessId] : null}
                handleClose={() => setNotificationAccessId(null)}
            />
        </Stack>
    );
};

DocumentList.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default DocumentList;
