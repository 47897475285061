import AddIcon from '@mui/icons-material/Add';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useGetAllOrderMembersByUserQuery } from '../../../api/order';
import OrderMembershipList from './OrderMembershipList';

// view contact - determine if contact is user or legal entity and render appropriate component
// TODO add to order modal/options

function UserDetailsOrderMembershipList({ user }) {
    const { data: orderMemberships } = useGetAllOrderMembersByUserQuery(user.id);

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(0) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ padding: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Orders</Typography>

                <IconButton size="small" disabled>
                    <AddIcon />
                </IconButton>
            </Stack>

            <OrderMembershipList orderMemberships={orderMemberships || []} />
        </Paper>
    );
}

UserDetailsOrderMembershipList.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserDetailsOrderMembershipList;
