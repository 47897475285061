import AddIcon from '@mui/icons-material/Add';
import { IconButton, List, Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useGetLienMembersQuery } from '../../../../api/lien';
import { useGetOrderMembersQuery } from '../../../../api/order';
import CreateLienMemberModal from './CreateLienMemberModal';
import LienMemberListItem from './LienMemberListItem';

function MemberList({ lien, lienMembers, orderMembers }) {
    if (!lienMembers || lienMembers.length === 0) {
        return (
            <Typography variant="body1" color="text.secondary" fontStyle="italic">
                No current members
            </Typography>
        );
    }

    return (
        <List disablePadding spacing={2}>
            {_.map(lienMembers, (lienMember) => {
                return (
                    <LienMemberListItem
                        key={lienMember.id}
                        lien={lien}
                        lienMember={lienMember}
                        orderMembers={orderMembers}
                    />
                );
            })}
        </List>
    );
}

function LienMemberList({ order, lien }) {
    const [openDialog, setOpenDialog] = useState(false);

    // TODO add/remove members
    const { data: orderMembers, isError: membersError, isLoading: membersLoading } = useGetOrderMembersQuery(order.id);
    const { data: lienMembers, isError: lienMembersError, isLoading: lienMembersLoading } = useGetLienMembersQuery(
        lien.id
    );

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Members</Typography>

                <IconButton size="small" onClick={() => setOpenDialog(true)}>
                    <AddIcon fontSize="small" />
                </IconButton>
            </Stack>

            <MemberList lien={lien} lienMembers={lienMembers || []} orderMembers={orderMembers || []} />

            <CreateLienMemberModal open={openDialog} lien={lien} handleClose={() => setOpenDialog(false)} />
        </Paper>
    );
}

LienMemberList.propTypes = {
    order: PropTypes.object.isRequired,
    lien: PropTypes.object.isRequired,
};

export default LienMemberList;
