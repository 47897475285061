import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Grid, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import useAdditionalDataItem from 'components/common/hooks/useAdditionalDataItem';
import { copyToClipboard } from 'helpers/utils';
import EditParcel from './EditParcel';

function ParcelDetailsLabel({ label }) {
    return (
        <Typography
            variant="body2"
            sx={{
                fontWeight: '400',
                color: 'text.secondary',
            }}
        >
            {label}
        </Typography>
    );
}

function ParcelDetailsContent({ content, typographyProps = {} }) {
    const [copyText, setCopyText] = useState('Copy');

    const handleCopy = (event, text) => {
        event.stopPropagation();
        event.preventDefault();
        copyToClipboard(text);
        setCopyText('Copied!');
    };

    return (
        <Box>
            {content ? (
                <Tooltip title={copyText} onClose={() => setCopyText('Copy')} placement="right" enterDelay={300}>
                    <Typography
                        variant="body1"
                        component="span"
                        onClick={(event) => handleCopy(event, content)}
                        {...typographyProps}
                    >
                        {content || '—'}
                    </Typography>
                </Tooltip>
            ) : (
                <Typography variant="body1" component="span">
                    {'—'}
                </Typography>
            )}
        </Box>
    );
}

const RowParams = {
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
};

function ParcelDetailsSection({ parcel, propertyKey, row = false }) {
    const { label, value } = useAdditionalDataItem(parcel, propertyKey);

    const stackParams = row ? RowParams : {};
    const typographyProps = {
        whiteSpace: 'pre-wrap',
        textAlign: row ? 'right' : 'left',
        display: 'inline-block',
    };

    return (
        <Grid item xs={12}>
            <Stack {...stackParams}>
                <ParcelDetailsLabel label={label} />
                <ParcelDetailsContent content={value} typographyProps={typographyProps} />
            </Stack>
        </Grid>
    );
}

function ParcelDetailsGrid({ parcel }) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const additionalDataChunks = _.get(parcel, 'additional_data_schema.ui_schema["ui:order"]', []);

    return (
        <Grid container alignItems="flex-start" spacing={2}>
            {_.map(additionalDataChunks, (propertyKey) => (
                <ParcelDetailsSection key={propertyKey} parcel={parcel} propertyKey={propertyKey} row={matches} />
            ))}
        </Grid>
    );
}

function ParcelDetails({ order, parcel }) {
    const [editMode, setEditMode] = useState(false);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Details</Typography>

                <Tooltip title={editMode ? 'Cancel' : 'Edit'} enterDelay={300} placement="bottom">
                    <span>
                        <IconButton size="small" onClick={() => toggleEditMode()}>
                            {editMode ? <CloseIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                        </IconButton>
                    </span>
                </Tooltip>
            </Stack>

            {editMode ? (
                <EditParcel
                    parcel={parcel}
                    handleCancel={() => toggleEditMode()}
                    handleComplete={() => toggleEditMode()}
                />
            ) : (
                <ParcelDetailsGrid parcel={parcel} />
            )}
        </Paper>
    );
}

ParcelDetails.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default ParcelDetails;
