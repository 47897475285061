import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Chip, IconButton, InputAdornment, MenuItem, Stack, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';

import FilterTextField from 'components/common/styled/FilterTextField';
import { ENTITY_ACTION, ENTITY_TYPE, EVENT_LOG_FILTER_TYPE } from 'helpers/constants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
};

// NOTE: we only log events for a subset of all entity types
const EVENT_LOG_ENTITY_TYPES = [
    ENTITY_TYPE.order,
    ENTITY_TYPE.parcel,
    ENTITY_TYPE.lien,
    ENTITY_TYPE.payoff,
    ENTITY_TYPE.action,
    ENTITY_TYPE.document,
    ENTITY_TYPE.order_member,
];

export const INITIAL_EVENT_LOG_FILTERS = {
    [EVENT_LOG_FILTER_TYPE.search]: '',
    [EVENT_LOG_FILTER_TYPE.orderId]: '',
    [EVENT_LOG_FILTER_TYPE.entityId]: '',
    [EVENT_LOG_FILTER_TYPE.entityType]: '',
    [EVENT_LOG_FILTER_TYPE.eventType]: '',
    [EVENT_LOG_FILTER_TYPE.adminId]: '',
    [EVENT_LOG_FILTER_TYPE.userId]: '',
    [EVENT_LOG_FILTER_TYPE.transactionId]: '',
};

function EventLogListFilters({ filters, setFilters, resetFilters, defaultFilters, hideFilters = [] }) {
    const searchInputRef = useRef();

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string may not be valid for some filters
        };

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const changeHandler = (event) => {
        handleFilter(event);
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 300), []);

    const setSearchInput = (value) => {
        searchInputRef.current.value = value;
    };

    const resetAllFilters = () => {
        // Reset local uncontrolled search input
        setSearchInput('');

        // Reset parent filters
        resetFilters();
    };

    const clearTransactionChip = () => {
        setFilters((prevValue) => ({
            ...prevValue,
            [EVENT_LOG_FILTER_TYPE.transactionId]: '',
        }));
    };

    useEffect(() => {
        // Stop the invocation of the debounced function after unmounting
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <FilterTextField
                name={EVENT_LOG_FILTER_TYPE.search}
                label=""
                type="search"
                variant="outlined"
                placeholder="Search event logs ..."
                onChange={debouncedChangeHandler}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                inputRef={searchInputRef}
                size="small"
                sx={{
                    flexGrow: 1,
                    minWidth: '320px',
                    maxWidth: '480px',
                    ...(_.includes(hideFilters, EVENT_LOG_FILTER_TYPE.search) && { display: 'none' }),
                }}
            />

            <FilterTextField
                name={EVENT_LOG_FILTER_TYPE.entityType}
                label="Entity Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters[EVENT_LOG_FILTER_TYPE.entityType]}
                size="small"
                sx={{
                    minWidth: '160px',
                    ...(_.includes(hideFilters, EVENT_LOG_FILTER_TYPE.entityType) && { display: 'none' }),
                }}
                select
                SelectProps={{
                    MenuProps,
                }}
            >
                <MenuItem value="">All</MenuItem>
                {_.map(EVENT_LOG_ENTITY_TYPES, (entityTypeValue) => {
                    return (
                        <MenuItem key={entityTypeValue} value={entityTypeValue}>
                            {_.startCase(entityTypeValue)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <FilterTextField
                name={EVENT_LOG_FILTER_TYPE.eventType}
                label="Event Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters[EVENT_LOG_FILTER_TYPE.eventType]}
                size="small"
                sx={{
                    minWidth: '160px',
                    ...(_.includes(hideFilters, EVENT_LOG_FILTER_TYPE.eventType) && { display: 'none' }),
                }}
                select
                SelectProps={{
                    MenuProps,
                }}
            >
                <MenuItem value="">All</MenuItem>
                {_.map(ENTITY_ACTION, (entityActionTypeValue) => {
                    return (
                        <MenuItem key={entityActionTypeValue} value={entityActionTypeValue}>
                            {_.startCase(entityActionTypeValue)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            {filters[EVENT_LOG_FILTER_TYPE.transactionId] && (
                <Chip
                    label="Transaction"
                    color="default"
                    size="medium"
                    onDelete={clearTransactionChip}
                    deleteIcon={<CloseIcon />}
                />
            )}

            <Box flexGrow="1" />

            <Tooltip title="Reset Filters" placement="bottom" enterDelay={300}>
                <IconButton
                    onClick={() => {
                        resetAllFilters();
                    }}
                >
                    <RestartAltIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

EventLogListFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    defaultFilters: PropTypes.object.isRequired,
    hideFilters: PropTypes.arrayOf(PropTypes.string),
};

export default EventLogListFilters;
