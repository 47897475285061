import EmailIcon from '@mui/icons-material/Email';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { TRANSACTION_TYPE_PARTY_MAP } from '../../../../helpers/constants';
import { copyToClipboard } from '../../../../helpers/utils';
import MemberPartyRoleList from './MemberPartyRoleList';

function MemberPartyList(props) {
    const { partyType, orderMembers, order, setEditMemberId, handleOpen } = props;

    const [partyMemberRoleMap, setPartyMemberRoleMap] = useState();
    const [partyMemberEmailMap, setPartyMemberEmailMap] = useState({});

    useEffect(() => {
        const memberRoleMap = {};
        const memberEmailMap = {};
        const transactionTypeParties = TRANSACTION_TYPE_PARTY_MAP[order?.transaction_type];

        _.forEach(transactionTypeParties[partyType], (roleType) => {
            memberRoleMap[roleType] = [];
            memberEmailMap[roleType] = [];
        });

        _.forEach(orderMembers, (member) => {
            if (member.party === partyType) {
                memberRoleMap[member.role].push(member);
            }
        });

        setPartyMemberRoleMap(memberRoleMap);
        // setPartyMemberEmailMap(memberEmailMap);
    }, [order, orderMembers]);

    const setPartyRoleEmailList = (role, emailList) => {
        setPartyMemberEmailMap((prevState) => {
            return {
                ...prevState,
                [role]: emailList,
            };
        });
    };

    const handleCopyPartyEmails = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const combinedEmailList = _.flatten(_.values(partyMemberEmailMap));

        if (combinedEmailList.length > 0) {
            const emailListStr = combinedEmailList.join(', ');
            copyToClipboard(emailListStr);
        }
    };

    const emailListLength = _.flatten(_.values(partyMemberEmailMap)).length;
    const noEmails = emailListLength === 0;

    return (
        <Stack
            alignItems="center"
            spacing={2}
            sx={{
                minWidth: '450px',
                maxWidth: '650px',
                width: '100%',
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ width: '100%' }}
            >
                <Box sx={{ width: '36px' }} />
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Box>
                        <Typography variant="h5" textTransform="uppercase" color="text.primary" fontWeight="500">
                            {_.startCase(partyType)}
                        </Typography>
                    </Box>
                </Box>
                <Tooltip
                    title={!noEmails ? `Copy all ${_.lowerCase(partyType)} party emails (${emailListLength})` : ''}
                    placement="bottom"
                    enterDelay={300}
                >
                    <span style={{ display: 'inline-block' }}>
                        <IconButton
                            onClick={handleCopyPartyEmails}
                            disabled={noEmails}
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                            }}
                        >
                            <EmailIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>
            </Stack>

            <Stack spacing={3} sx={{ width: '100%' }}>
                {_.map(partyMemberRoleMap, (members, role) => {
                    return (
                        <MemberPartyRoleList
                            key={role}
                            partyType={partyType}
                            role={role}
                            members={members}
                            orderMembers={orderMembers}
                            setEditMemberId={setEditMemberId}
                            setPartyRoleEmailList={setPartyRoleEmailList}
                            handleOpen={handleOpen}
                        />
                    );
                })}
            </Stack>
        </Stack>
    );
}

MemberPartyList.propTypes = {
    partyType: PropTypes.string.isRequired,
    orderMembers: PropTypes.array.isRequired,
    order: PropTypes.object.isRequired,
    setEditMemberId: PropTypes.func.isRequired,
    handleOpen: PropTypes.func.isRequired,
};

export default MemberPartyList;
