import _ from 'lodash';

import { useGetOrderMembersQuery, useGetOrderQuery } from 'api/order';
import { useGetUserQuery } from 'api/user';
import { ENTITY_TYPE } from 'helpers/constants';
import { findParcelInOrder } from 'helpers/utils';

// TODO move to action dir
function useActionData(orderId, action) {
    const { data: order, isLoading: isOrderLoading, isFetching: isOrderFetching } = useGetOrderQuery(orderId);
    const {
        data: orderMembers,
        isLoading: isOrderMembersLoading,
        isFetching: isOrderMembersFetching,
    } = useGetOrderMembersQuery(orderId);

    const parcel = order ? findParcelInOrder(order, action.parcel_id) : null;
    const parentParcel = _.get(parcel, 'parent_parcel_id') ? findParcelInOrder(order, parcel.parent_parcel_id) : null;
    const orderMember =
        orderMembers && !!action.order_member_id ? _.find(orderMembers, { id: action.order_member_id }) : null;
    const userId =
        _.get(orderMember, 'member.entity_type') === ENTITY_TYPE.user
            ? orderMember.member.id
            : _.get(orderMember, 'member.user_id', null);

    const { data: user, isLoading: isUserLoading, isFetching: isUserFetching } = useGetUserQuery(userId);

    const isLoading = isOrderLoading || isOrderMembersLoading || isUserLoading;
    const isFetching = isOrderFetching || isOrderMembersFetching || isUserFetching;

    return {
        order,
        parcel,
        parentParcel,
        orderMembers,
        orderMember,
        user: user || null, // user lookup will return undefined if not found
        isLoading,
        isFetching,
    };
}

export default useActionData;
