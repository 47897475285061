import SearchIcon from '@mui/icons-material/Search';
import {
    Checkbox,
    Chip,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useCreateUserRelationshipMutation, useGetUsersBySearchQuery } from 'api/user';
import { Button, FilterTextField, LoadingButton } from 'components/common/styled';
import { USER_RELATIONSHIP_TYPE } from 'helpers/constants';

const SearchResultListItemSecondaryActions = ({
    userId,
    relationshipDetailsMap,
    handleRelationshipDetailsChange,
    selected,
}) => {
    const updateRelation = (relation) => {
        handleRelationshipDetailsChange(userId, {
            relation,
        });
    };

    const handleRelationChange = ({ target }) => {
        const relation = target.value;
        updateRelation(relation);
    };

    const userRelationDetails = _.get(relationshipDetailsMap, userId);

    if (!selected || !userRelationDetails) {
        return null;
    }

    return (
        <Stack direction="row" spacing={4} alignItems="center">
            <TextField
                name="relation"
                label="Relation"
                value={userRelationDetails.relation}
                onChange={handleRelationChange}
                sx={{ minWidth: '150px' }}
                size="small"
                select
                required
            >
                {_.map(USER_RELATIONSHIP_TYPE, (relationType) => (
                    <MenuItem key={relationType} value={relationType}>
                        {_.startCase(relationType)}
                    </MenuItem>
                ))}
            </TextField>
        </Stack>
    );
};

const UserSearchResultListItem = ({
    searchResult,
    handleSelect,
    selected,
    relationshipDetailsMap,
    handleRelationshipDetailsChange,
}) => {
    const handleToggle = (event) => {
        event.preventDefault();
        handleSelect(searchResult, !selected);
    };

    return (
        <ListItem
            disablePadding
            className={selected ? 'selected' : ''}
            secondaryAction={
                <SearchResultListItemSecondaryActions
                    selected={selected}
                    userId={searchResult.id}
                    relationshipDetailsMap={relationshipDetailsMap}
                    handleRelationshipDetailsChange={handleRelationshipDetailsChange}
                />
            }
            sx={{
                '&.selected > .MuiListItemButton-root': {
                    paddingRight: (theme) => theme.spacing(22),
                },
            }}
        >
            <ListItemButton onClick={handleToggle}>
                <ListItemIcon>
                    <Checkbox edge="start" checked={selected} disableRipple />
                </ListItemIcon>
                <ListItemText
                    primary={searchResult.name}
                    primaryTypographyProps={{ noWrap: true, color: 'text.primary' }}
                    secondary={_.get(searchResult, 'contact.email', null)}
                    secondaryTypographyProps={{ noWrap: true }}
                />
            </ListItemButton>
        </ListItem>
    );
};

const SearchResults = ({
    userSearchResults,
    validSearchTerm,
    noSearchResults,
    selectedUserMap,
    handleSelect,
    relationshipDetailsMap,
    handleRelationshipDetailsChange,
}) => {
    if (!validSearchTerm || noSearchResults) {
        return null;
    }

    const userSearchResultList = _.map(userSearchResults, (searchResult) => {
        return (
            <UserSearchResultListItem
                key={searchResult.id}
                searchResult={searchResult}
                selected={!!_.get(selectedUserMap, searchResult.id)}
                handleSelect={handleSelect}
                relationshipDetailsMap={relationshipDetailsMap}
                handleRelationshipDetailsChange={handleRelationshipDetailsChange}
            />
        );
    });

    return (
        <Stack spacing={1}>
            <Typography variant="overline">Search Results</Typography>
            <List sx={{ width: '100%', maxHeight: '300px', overflow: 'auto' }}>{userSearchResultList}</List>
        </Stack>
    );
};

const CurrentSelections = ({
    validSearchTerm,
    noSearchResults,
    selectedUserMap,
    handleSelect,
    relationshipDetailsMap,
    handleRelationshipDetailsChange,
}) => {
    if (_.keys(selectedUserMap).length === 0 || (validSearchTerm && !noSearchResults)) {
        return null;
    }

    const currentSelectionList = _.map(selectedUserMap, (member) => {
        return (
            <UserSearchResultListItem
                key={member.id}
                searchResult={member}
                selected={!!_.get(selectedUserMap, member.id)}
                handleSelect={handleSelect}
                relationshipDetailsMap={relationshipDetailsMap}
                handleRelationshipDetailsChange={handleRelationshipDetailsChange}
            />
        );
    });

    return (
        <Stack spacing={1}>
            <Typography variant="overline">Current Selections</Typography>
            <List sx={{ width: '100%', maxHeight: '300px', overflow: 'auto' }}>{currentSelectionList}</List>
        </Stack>
    );
};

const AddRelationship = ({ user, handleCancel, handleComplete }) => {
    // TODO prevent main/parent user from showing in search results

    const [selectedUserMap, setSelectedUserMap] = useState({});
    const [relationshipDetailsMap, setRelationshipDetailsMap] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        data: userSearchResults,
        isError: userSearchError,
        isLoading: userSearchLoading,
    } = useGetUsersBySearchQuery(searchTerm);
    const [
        createUserRelationship,
        { isLoading: isCreateUserRelationshipLoading },
    ] = useCreateUserRelationshipMutation();

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelect = (selectedResult, selected) => {
        if (selected) {
            setSelectedUserMap({
                ...selectedUserMap,
                [selectedResult.id]: {
                    ...selectedResult,
                },
            });
            // set baseline relationship details if doesn't already exist
            handleRelationshipDetailsChange(selectedResult.id, { relation: '' });
        } else {
            const newMap = { ...selectedUserMap };
            delete newMap[selectedResult.id];
            setSelectedUserMap(newMap);
        }
    };

    const handleRelationshipDetailsChange = (userId, newRelationshipDetails = {}) => {
        setRelationshipDetailsMap({
            ...relationshipDetailsMap,
            [userId]: {
                ..._.get(relationshipDetailsMap, userId, {}),
                ...newRelationshipDetails,
            },
        });
    };

    const handleCreateRelationships = (event) => {
        event.preventDefault();

        setIsSubmitting(true);

        const resultMap = {
            success: [],
            error: [],
        };

        const promiseList = _.map(selectedUserMap, (selectedUser, selectedUserId) => {
            return submitUserRelationship(selectedUserId, selectedUser).then(
                (response) => {
                    if (response) {
                        resultMap.success.push(selectedUserId);
                    } else {
                        resultMap.error.push(selectedUserId);
                    }
                    return response;
                },
                (error) => {
                    console.error(error);
                    resultMap.error.push(selectedUserId);
                }
            );
        });

        Promise.all(promiseList).then((values) => {
            setIsSubmitting(false);

            handleComplete();
        });
    };

    const submitUserRelationship = async (selectedUserId, selectedUser) => {
        const userRelationshipDetails = _.get(relationshipDetailsMap, selectedUserId);

        if (!userRelationshipDetails) {
            console.warn(`${selectedUserId} not found in relationshipDetailsMap`, relationshipDetailsMap);
            return false;
        }

        const relationshipData = {
            user_id: user.id,
            related_user_id: selectedUserId,
            ...userRelationshipDetails,
        };

        // Verify that relation has been filled in else exit
        if (!relationshipData.relation) {
            console.warn(`${selectedUserId} doesn't have an assigned relation`);
            return false;
        }

        const { data: createUserRelationshipData } = await createUserRelationship({
            userId: user.id,
            relationshipData,
        });

        if (createUserRelationshipData) {
            return true;
        } else {
            console.warn(`${selectedUserId} failed to be added as a relation to ${user.id}`);
            return false;
        }
    };

    const validSearchTerm = _.get(searchTerm, 'length') > 2;
    const noSearchResults = _.get(userSearchResults, 'length', 0) === 0;

    return (
        <Stack spacing={4} sx={{ width: '100%' }}>
            <Stack spacing={1}>
                <Typography variant="h5">Add Relationships</Typography>
                <Typography variant="body1" color="text.secondary">
                    {user.name}
                </Typography>
            </Stack>

            <Stack spacing={2}>
                <FilterTextField
                    name="search"
                    type="search"
                    placeholder="Search existing users ..."
                    size="small"
                    variant="outlined"
                    onChange={handleSearch}
                    value={searchTerm}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    autoFocus
                    fullWidth
                    helperText={
                        validSearchTerm &&
                        noSearchResults && (
                            <Typography variant="caption">No results found for "{searchTerm}"</Typography>
                        )
                    }
                />

                <SearchResults
                    userSearchResults={userSearchResults}
                    validSearchTerm={validSearchTerm}
                    noSearchResults={noSearchResults}
                    selectedUserMap={selectedUserMap}
                    handleSelect={handleSelect}
                    relationshipDetailsMap={relationshipDetailsMap}
                    handleRelationshipDetailsChange={handleRelationshipDetailsChange}
                />

                <CurrentSelections
                    validSearchTerm={validSearchTerm}
                    noSearchResults={noSearchResults}
                    selectedUserMap={selectedUserMap}
                    handleSelect={handleSelect}
                    relationshipDetailsMap={relationshipDetailsMap}
                    handleRelationshipDetailsChange={handleRelationshipDetailsChange}
                />

                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Typography variant="overline" sx={{ fontWeight: '500', minWidth: '80px' }}>
                        {_.keys(selectedUserMap).length} selected
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
                        {_.map(selectedUserMap, (member) => {
                            return (
                                <Chip
                                    key={member.id}
                                    label={member.name}
                                    onDelete={(event) => handleSelect(member, false)}
                                    size="small"
                                />
                            );
                        })}
                    </Stack>
                </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                <Button variant="text" color="default" onClick={() => handleCancel()} disabled={isSubmitting}>
                    Cancel
                </Button>

                <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={handleCreateRelationships}
                    disableElevation
                    loading={isSubmitting}
                    disabled={_.keys(selectedUserMap).length === 0}
                    sx={{
                        minWidth: '160px',
                    }}
                >
                    Add Selected
                </LoadingButton>
            </Stack>
        </Stack>
    );
};

AddRelationship.propTypes = {
    user: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleComplete: PropTypes.func.isRequired,
};

export default AddRelationship;
