import { Tabs } from '@mui/material/';
import { styled } from '@mui/material/styles';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .MuiTabs-indicator': {
        height: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
    },
}));

export default StyledTabs;
