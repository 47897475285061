import { List, ListItem, ListItemText, Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// import Form from '@rjsf/mui';

import ActionButton from 'components/Action/ActionButton';
import { findChildParcelsInOrder } from 'helpers/utils';

function ActionListItem({ action }) {
    const actionData = action.action;
    return (
        <ListItem
            disableGutters
            secondaryAction={
                <ActionButton action={actionData} orderId={action.orderId} loadingButtonProps={{ color: 'primary' }} />
            }
            sx={
                {
                    // padding: (theme) => theme.spacing(3),
                    // bgcolor: 'primary.light',
                    // borderColor: 'primary.light',
                }
            }
        >
            <ListItemText primary={actionData.friendly_name} secondary={actionData.description} />
        </ListItem>
    );
}

function ActionList({ actions }) {
    if (!actions || actions.length === 0) {
        return (
            <Typography variant="body1" color="text.secondary" fontStyle="italic">
                No active actions
            </Typography>
        );
    }

    return (
        <List disablePadding spacing={2}>
            {_.map(actions, (action) => {
                return <ActionListItem key={action.id} action={action} />;
            })}
        </List>
    );
}

function ParcelActionList({ order, parcel }) {
    const childParcels = useMemo(() => {
        if (order) {
            return findChildParcelsInOrder(order, parcel.id);
        }

        return [];
    }, [order, parcel.id]);

    const actionList = useMemo(() => {
        if (parcel) {
            const parcelActionList = [];
            const combinedParcelList = [parcel, ...childParcels];

            _.forEach(combinedParcelList, (parcelItem) => {
                _.forEach(parcelItem.active_actions, (actionId) => {
                    parcelActionList.push({
                        id: actionId,
                        orderId: parcel.order_id,
                        parcelId: parcelItem.id,
                        parcelName: parcelItem.name,
                        action: parcelItem.action_map[actionId],
                    });
                });
            });

            return parcelActionList;
        }

        return [];
    }, [parcel, childParcels]);

    return (
        <Paper
            variant="outlined"
            sx={{
                padding: (theme) => theme.spacing(3),
                // bgcolor: 'primary.light',
                // borderColor: 'primary.light',
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Actions</Typography>
            </Stack>

            <ActionList actions={actionList} />
        </Paper>
    );
}

ParcelActionList.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default ParcelActionList;
