import { Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import { useUpdateUserMutation } from '../../../api/user';
import { generateUpdateObject } from '../../../helpers/utils';
import Button from '../../common/styled/Button';
import LoadingButton from '../../common/styled/LoadingButton';
import UserForm from './UserForm';

// TODO - prevent email from being removed if not empty

const EditUser = ({ user, handleCancel, handleComplete }) => {
    const [userCopy, setUserCopy] = useState({
        ...user, // Add all top level user fields
        // Override specific fields to handle null values
        gender: user.gender || '',
        marital_status: user.marital_status || '',
        date_of_birth: user.date_of_birth || '',
        contact: {
            email: _.get(user, 'contact.email', '') || '',
            phone: _.get(user, 'contact.phone', '') || '',
            tier: _.get(user, 'contact.tier', '') || '',
        },
        address: {
            street: _.get(user, 'address.street', ''),
            street_additional: _.get(user, 'address.street_additional', '') || '', // Street additional is only 'nullable' field in address
            city: _.get(user, 'address.city', ''),
            state: _.get(user, 'address.state', ''),
            zipcode: _.get(user, 'address.zipcode', ''),
            country: _.get(user, 'address.country', ''),
        },
    });
    const [updatePayload, setUpdatePayload] = useState({});
    const [updateUser, { isLoading: isUserSaving }] = useUpdateUserMutation();

    const handleEditUser = (event) => {
        event.preventDefault();

        handleUpdateUser();
    };

    const formatUserUpdatePayload = () => {
        const updateData = { ...updatePayload };

        _.forEach(updatePayload, (value, key) => {
            if (key === 'address') {
                // Verify address has been added/changed/removed
                const validOriginalAddress = !!user.address;
                const validCurrentAddress = !!userCopy.address.street;

                if (validOriginalAddress && !validCurrentAddress) {
                    // remove address
                    updateData['address'] = null; // TODO verify this will remove
                } else if (!validOriginalAddress && validCurrentAddress) {
                    // add new address
                    updateData['address'] = {
                        ...userCopy.address,
                        street_additional: userCopy.address.street_additional || null,
                    };
                } else if (validOriginalAddress && validCurrentAddress) {
                    // update address
                    updateData['address'] = {
                        ...userCopy.address,
                        street_additional: userCopy.address.street_additional || null,
                    };
                }
            } else if (key === 'contact') {
                // Verify contact has been added/changed/removed
                const validCurrentEmail = !!userCopy.contact.email;
                const validCurrentPhone = !!userCopy.contact.phone;
                const validCurrentTier = !!userCopy.contact.tier;

                if (validCurrentEmail || validCurrentPhone) {
                    // update existing contact details
                    updateData['contact'] = {
                        ...(validCurrentEmail && { email: userCopy.contact.email }),
                        ...(validCurrentPhone && { phone: userCopy.contact.phone }),
                    };
                } else if (!validCurrentEmail && !validCurrentPhone) {
                    updateData['contact'] = {
                        email: null,
                        phone: null,
                    };
                }

                if (validCurrentTier) {
                    updateData['contact']['tier'] = userCopy.contact.tier;
                }
            } else if (value === '') {
                updateData[key] = null;
            }
        });

        return { userId: user.id, userData: updateData };
    };

    const handleUpdateUser = async () => {
        const { data } = await updateUser(formatUserUpdatePayload());
        if (data) {
            handleComplete();
        } else {
            console.error('Error updating user');
        }
    };

    const generateUpdatePayload = (originalUser, updatedUser) => {
        // Compare original user data with local form state
        const baseUpdateObject = generateUpdateObject(originalUser, updatedUser, _.keys(originalUser));

        // Analyzing address and contact separately as these are nested object
        // Using userCopy to generate key list as base user contact/address may be null
        baseUpdateObject['address'] = generateUpdateObject(
            originalUser.address,
            updatedUser.address,
            _.keys(updatedUser.address)
        );
        baseUpdateObject['contact'] = generateUpdateObject(
            originalUser.contact,
            updatedUser.contact,
            _.keys(updatedUser.contact)
        );

        // json stringify will automatically remove undefined values from objects
        // however, we want to do that preimptively to calculate if an update should be submitted
        _.forEach(baseUpdateObject, (value, key) => {
            if (value === undefined) {
                delete baseUpdateObject[key];
            } else if (value === '' && originalUser[key] === null) {
                delete baseUpdateObject[key];
            } else if (_.includes(['address', 'contact'], key)) {
                _.forEach(value, (childValue, childKey) => {
                    // Remove undefined values from nested objects
                    if (childValue === undefined) {
                        delete baseUpdateObject[key][childKey];
                    } else if (
                        childValue === '' &&
                        (originalUser[key] === null || originalUser[key][childKey] === null)
                    ) {
                        // Empty string is used in userCopy to properly mark an input as controlled while still being empty
                        // However, the backend expects null for empty values
                        // Therefore, we need to remove all instances where an empty string is used in userCopy when the original value was null
                        delete baseUpdateObject[key][childKey];
                    }
                });

                // If the address or contact object is empty, we need to remove it from the update payload
                if (_.isEmpty(baseUpdateObject[key])) {
                    delete baseUpdateObject[key];
                }
            }
        });

        return baseUpdateObject;
    };

    const handleUpdatePayload = (originalUser, updatedUser) => {
        const newUpdatePayload = generateUpdatePayload(originalUser, updatedUser);
        setUpdatePayload(newUpdatePayload);
    };

    const debouncedHandleUpdatePayload = useMemo(
        () => _.debounce((originalUser, updatedUser) => handleUpdatePayload(originalUser, updatedUser), 300),
        []
    );

    useEffect(() => {
        if (userCopy) {
            debouncedHandleUpdatePayload(user, userCopy);
        }

        // Stop the invocation of the debounced function
        // after unmounting
        return () => {
            debouncedHandleUpdatePayload.cancel();
        };
    }, [user, userCopy]);

    return (
        <Stack alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
            <form onSubmit={handleEditUser}>
                <Stack spacing={4} sx={{ maxWidth: '800px', paddingTop: (theme) => theme.spacing(2) }}>
                    <UserForm userData={userCopy} setUserData={setUserCopy} />

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={2}
                        sx={{ width: '100%' }}
                    >
                        <Button variant="text" color="default" onClick={() => handleCancel()} disableElevation>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={isUserSaving}
                            disabled={_.keys(updatePayload).length === 0}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                        >
                            Update
                        </LoadingButton>
                    </Stack>
                </Stack>
            </form>
        </Stack>
    );
};

EditUser.propTypes = {
    user: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleComplete: PropTypes.func.isRequired,
};

export default EditUser;
