import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import BaseDialog from './BaseDialog';
import { Button, LoadingButton } from './styled';

function ConfirmDialog(props) {
    const {
        open,
        title,
        subTitle = false,
        body,
        cancelText = 'Cancel',
        handleClose,
        confirmText = 'Confirm',
        handleConfirm,
        loading = false,
    } = props;

    return (
        <BaseDialog open={open} setOpen={() => handleClose()} DialogProps={{ maxWidth: 'sm' }}>
            <Stack direction="column" spacing={3}>
                <Stack spacing={0}>
                    <Typography variant="h5">{title}</Typography>
                    {!!subTitle && (
                        <Typography variant="h6" color="text.secondary">
                            {subTitle}
                        </Typography>
                    )}
                </Stack>

                <Box sx={{ color: (theme) => theme.palette.text.secondary, fontSize: '14px' }}>{body}</Box>

                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} sx={{ pt: 3 }}>
                    <Button onClick={handleClose} color="default" variant="text">
                        {cancelText}
                    </Button>
                    <LoadingButton
                        onClick={() => {
                            handleConfirm();
                            handleClose();
                        }}
                        color="primary"
                        variant="contained"
                        disableElevation
                        sx={{
                            minWidth: '160px',
                        }}
                        loading={loading}
                    >
                        {confirmText}
                    </LoadingButton>
                </Stack>
            </Stack>
        </BaseDialog>
    );
}

ConfirmDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    subTitle: PropTypes.string,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    loading: PropTypes.bool,
};

export default ConfirmDialog;
