import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import PauseIcon from '@mui/icons-material/Pause';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useUpdateOrderMutation } from '../../../../api/order';
import { ORDER_STATUS } from '../../../../helpers/constants';
import ConfirmDialog from '../../../common/ConfirmDialog';

const themeBackgroundColorMap = (colorString, theme) => {
    switch (colorString) {
        case 'success':
            return theme.palette.primary.main;
        case 'error':
            return theme.palette.error.main;
        case 'warning':
            return theme.palette.warning.main;
        case 'default':
            return theme.palette.background.default;
        default:
            return theme.palette.background.default;
    }
};

const themeTextColorMap = (colorString, theme) => {
    switch (colorString) {
        case 'success':
            return theme.palette.primary.contrastText;
        case 'error':
            return theme.palette.error.contrastText;
        case 'warning':
            return theme.palette.text.primary;
        case 'default':
            return theme.palette.text.primary;
        default:
            return theme.palette.text.primary;
    }
};

function OrderStatusIcon({ status }) {
    const getStatusIcon = (status) => {
        switch (status) {
            case ORDER_STATUS.on_hold:
                return <PauseIcon />;
            case ORDER_STATUS.closed:
                return <VerifiedIcon />;
            case ORDER_STATUS.canceled:
                return <BlockIcon />;
            case ORDER_STATUS.pre_open:
                return <PostAddOutlinedIcon />;
            case ORDER_STATUS.open:
                return <CheckIcon />;
            default:
                return <CheckIcon />;
        }
    };

    return getStatusIcon(status);
}

function OrderStatusSelect({ order, color = 'default' }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmChangeStatus, setConfirmChangeStatus] = useState(null);
    const [updateOrder, { isLoading }] = useUpdateOrderMutation();

    const handleOrderUpdateStatus = async (newStatus) => {
        const updateOrderPayload = {
            orderId: order.id,
            orderData: {
                status: newStatus,
            },
        };

        const { data } = await updateOrder(updateOrderPayload);
        return !!data;
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (newStatus) => {
        setConfirmChangeStatus(newStatus);

        handleClose();
    };

    const handleSubmit = (newStatus) => {
        if (newStatus !== order.status) {
            handleOrderUpdateStatus(newStatus);
        }
    };

    return (
        <>
            <LoadingButton
                variant="outlined"
                color={color}
                onClick={handleClick}
                loading={isLoading}
                // startIcon={<OrderStatusIcon status={order.status} />}
                endIcon={<ArrowDropDownIcon />}
            >
                {_.startCase(order.status)}
            </LoadingButton>
            <Menu id="order-status-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} keepMounted>
                {_.map(ORDER_STATUS, (status) => (
                    <MenuItem key={status} onClick={() => handleChange(status)} selected={status === order.status}>
                        {_.startCase(status)}
                    </MenuItem>
                ))}
            </Menu>
            <ConfirmDialog
                open={!!confirmChangeStatus}
                title={`Change Order Status`}
                body={
                    <Box>
                        <Typography component="span" color="text.secondary">
                            Are you sure you want to change the status of the order from{' '}
                        </Typography>
                        <Typography component="span" color="text.primary" fontWeight={500}>
                            {_.startCase(order.status)}
                        </Typography>
                        <Typography component="span" color="text.secondary">
                            {' '}
                            to{' '}
                        </Typography>
                        <Typography component="span" color="text.primary" fontWeight={500}>
                            {_.startCase(confirmChangeStatus)}
                        </Typography>
                        <Typography component="span" color="text.secondary">
                            ?
                        </Typography>
                    </Box>
                }
                handleConfirm={() => handleSubmit(confirmChangeStatus)}
                handleClose={() => setConfirmChangeStatus(null)}
            />
        </>
    );
}

OrderStatusSelect.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OrderStatusSelect;
