import { fromUnixTime } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ORDER_FILTER_TYPE } from '../../../helpers/constants';
import { convertDateRangeToTimestamp } from '../../../helpers/utils';

function useFilterQueryParams(filters, defaultFilters, config = {}) {
    const { multiArgFilterTypes = [], dateRangeFilterTypes = [ORDER_FILTER_TYPE.closeDateRange] } = config;

    let [searchParams, setSearchParams] = useSearchParams();
    const [initSearchParams, setInitSearchParams] = useState(false);
    const [syncFilterMap, setSyncFilterMap] = useState({});

    useEffect(() => {
        // If the initial sync of component filters with query params has completed,
        // sync query params with component filters whenever the filters change
        if (initSearchParams) {
            const newParams = {};
            const removedParams = [];

            _.forEach(filters, (value, key) => {
                if (value) {
                    // If array is empty or only contains null items (date range) - remove param
                    // NOTE: empty array will return true for any version of _.every
                    if (_.isArray(value) && _.every(value, (item) => item === null)) {
                        // Only remove param if it currently exists in search params
                        if (searchParams.has(key)) {
                            removedParams.push(key);
                        }
                        return; // continue
                    }

                    const filterRangeKey = _.includes(dateRangeFilterTypes, key);
                    const filterMultiArgKey = _.includes(multiArgFilterTypes, key);
                    if (filterRangeKey) {
                        // TODO - check equality of date ranges to avoid unnecessary updates
                        if (value[0] && value[1]) {
                            newParams[key] = convertDateRangeToTimestamp(value);
                        }
                    } else if (filterMultiArgKey) {
                        // Check equality of multi args to avoid unnecessary updates
                        const currentParamValue = searchParams.get(key);
                        const currentParamArray = currentParamValue ? currentParamValue.split(',') : [];
                        if (!_.isEqual(currentParamArray, value)) {
                            newParams[key] = value;
                        }
                    } else {
                        // Check equality of values to avoid unnecessary updates
                        const currentParamValue = searchParams.get(key);
                        if (!_.isEqual(currentParamValue, value)) {
                            newParams[key] = value;
                        }
                    }
                } else {
                    // if value is empty, remove the param
                    // Only remove param if it currently exists in search params
                    if (searchParams.has(key)) {
                        removedParams.push(key);
                    }
                }
            });

            if (!_.isEmpty(newParams) || !_.isEmpty(removedParams)) {
                setSearchParams((prevSearchParams) => {
                    // Annoyingly, we have to grab the current search params directly from the window object
                    // because the current & prevSearchParams may be out of date if multiple components are updating the search params
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);

                    // Modify the search params in place
                    // allows for multiple filter groups to independently update the search params without overwriting each other
                    _.forEach(newParams, (value, key) => {
                        urlParams.set(key, value);
                    });

                    _.forEach(removedParams, (key) => {
                        urlParams.delete(key);
                    });

                    return urlParams;
                });
            }
        }
    }, [filters]);

    useEffect(() => {
        // If search params are updated but the filters don't match, update the filters
        // check each filter to see if it matches
        const searchParamFilters = {};
        const newSyncFilters = {};

        _.forEach(filters, (value, key) => {
            const filterRangeKey = _.includes(dateRangeFilterTypes, key);
            const filterMultiArgKey = _.includes(multiArgFilterTypes, key);

            if (filterRangeKey || filterMultiArgKey) {
                // Handle potentially multiple values
                // NOTE: this could be either a single value with comma-separated string or an array of values
                const searchParamValue = searchParams.getAll(key);

                if (searchParamValue.length > 0) {
                    const splitValues = _.map(searchParamValue, (value) => {
                        return value.split(',');
                    });
                    const flattenedValues = _.flatten(splitValues);

                    // If filterParamValue is a date, convert it to a date object from timestamp string
                    if (filterRangeKey) {
                        searchParamFilters[key] = _.map(flattenedValues, (date) => fromUnixTime(date));
                    } else {
                        searchParamFilters[key] = flattenedValues;
                    }
                }
            } else {
                // Handle single value
                const filterParamValue = searchParams.get(key);
                if (filterParamValue) {
                    searchParamFilters[key] = filterParamValue;
                }
            }

            if (searchParamFilters[key] !== undefined) {
                newSyncFilters[key] = searchParamFilters[key];
            }
        });

        // Check if any of the specific filters have changed
        // NOTE: this doesn't currently handle edge cases with date equality
        // NOTE: empty dict for newSyncFilters will always return true
        const areFiltersEqual = _.every(newSyncFilters, (syncFilterValue, filterKey) =>
            _.isEqual(syncFilterValue, filters[filterKey])
        );

        if (!areFiltersEqual) {
            setSyncFilterMap({
                ...defaultFilters,
                ...newSyncFilters,
            });
        }

        if (!initSearchParams) {
            // After initial sync of query params with component filters, set initSearchParams to true
            setInitSearchParams(true);
        }
    }, [searchParams]);

    return [syncFilterMap, searchParams];
}

export default useFilterQueryParams;
