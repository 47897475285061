const userType = {
    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',

    CREATE_USER_SESSION_REQUEST: 'CREATE_USER_SESSION_REQUEST',
    CREATE_USER_SESSION_SUCCESS: 'CREATE_USER_SESSION_SUCCESS',
    CREATE_USER_SESSION_FAILURE: 'CREATE_USER_SESSION_FAILURE',

    LOGOUT_USER_SESSION_REQUEST: 'LOGOUT_USER_SESSION_REQUEST',
    LOGOUT_USER_SESSION_SUCCESS: 'LOGOUT_USER_SESSION_SUCCESS',
    LOGOUT_USER_SESSION_FAILURE: 'LOGOUT_USER_SESSION_FAILURE',

    GET_USERS_LIST_REQUEST: 'GET_USERS_LIST_REQUEST',
    GET_USERS_LIST_SUCCESS: 'GET_USERS_LIST_SUCCESS',
    GET_USERS_LIST_FAILURE: 'GET_USERS_LIST_FAILURE',
};

export default userType;
