import { Stack } from '@mui/material';
import NotificationIconButton from 'components/Notification/NotificationIconButton';
import SearchIconButton from 'components/Search/SearchIconButton';
import { useState } from 'react';
import CreateEntityIconButton from './CreateEntityIconButton';

const SIDEBAR_MODAL_TYPE = {
    create: 'create',
    search: 'search',
    notification: 'notification',
};

const ActionGroup = () => {
    const [openModal, setOpenModal] = useState(null);

    const openCreateModal = (open) => {
        setOpenModal(open ? SIDEBAR_MODAL_TYPE.create : null);
    };

    const openSearchModal = (open) => {
        setOpenModal(open ? SIDEBAR_MODAL_TYPE.search : null);
    };

    const openNotificationModal = (open) => {
        setOpenModal(open ? SIDEBAR_MODAL_TYPE.notification : null);
    };

    return (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <CreateEntityIconButton open={openModal === SIDEBAR_MODAL_TYPE.create} setOpen={openCreateModal} />

            <SearchIconButton open={openModal === SIDEBAR_MODAL_TYPE.search} setOpen={openSearchModal} />

            <NotificationIconButton
                open={openModal === SIDEBAR_MODAL_TYPE.notification}
                setOpen={openNotificationModal}
            />
        </Stack>
    );
};

export default ActionGroup;
