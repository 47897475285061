import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import UserAccessLogList from '../../UserLogList/UserAccessLogList';
import UserEmailLogList from '../../UserLogList/UserEmailLogList';
import { Tab, Tabs } from '../../common/styled';
import UserDetails from './UserDetails';

const USER_TAB_TYPE = {
    details: 'details',
    email: 'email',
    access: 'access',
};

const USER_TAB_COMPONENT_MAP = {
    [USER_TAB_TYPE.details]: UserDetails,
    [USER_TAB_TYPE.email]: UserEmailLogList,
    [USER_TAB_TYPE.access]: UserAccessLogList,
};

function UserSectionTabContent({ userId, activeTab }) {
    const TabContentComp = USER_TAB_COMPONENT_MAP[activeTab];
    return <TabContentComp userId={userId} />;
}

function UserSectionTabs({ user }) {
    const [activeTab, setActiveTab] = useState(USER_TAB_TYPE.details);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const hasAccount = !!user.external_user_id;

    return (
        <Stack spacing={3}>
            <Box sx={{ width: '100%' }}>
                <Tabs value={activeTab} onChange={handleChange} textColor="primary" indicatorColor="primary">
                    <Tab value={USER_TAB_TYPE.details} label="Details" />

                    <Tab value={USER_TAB_TYPE.email} label="Email Logs" disabled={!hasAccount} />

                    <Tab value={USER_TAB_TYPE.access} label="Access Logs" disabled={!hasAccount} />
                </Tabs>
            </Box>

            <UserSectionTabContent userId={user.id} activeTab={activeTab} />
        </Stack>
    );
}

UserSectionTabs.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserSectionTabs;
