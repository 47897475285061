import { useDraggable } from '@dnd-kit/core';
// import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    ListItemButton,
    ListItemText,
    Paper,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useGetLegalEntityMemberQuery, useGetLegalEntityQuery } from 'api/legalEntity';
import { useGetOrderMemberQuery, useUpdateOrderMemberMutation } from 'api/order';
import { useGetUserQuery } from 'api/user';
import { ENTITY_TYPE, ORDER_ROLE_TYPE } from 'helpers/constants';
import { copyToClipboard } from 'helpers/utils';

function UserItem({
    member,
    orderMembers,
    handleChange,
    showTitleCheckbox,
    showLoanCheckbox,
    updateMemberLoading,
    handleSyncMemberEmail,
}) {
    const { data: user, isError: userError, isLoading: userLoading } = useGetUserQuery(member.member_id);

    const [orderMemberUserIdList, setOrderMemberUserIdList] = useState([]); // List of all order member userIds
    const [relatedUserList, setRelatedUserList] = useState([]); // List of all related users who exist in order member userId list

    useEffect(() => {
        const newOrderMemberUserIdList = [];

        _.forEach(orderMembers, (orderMember) => {
            if (orderMember.member_type === ENTITY_TYPE.user) {
                newOrderMemberUserIdList.push(orderMember.member_id);
            }
        });

        setOrderMemberUserIdList(newOrderMemberUserIdList);
    }, [orderMembers]);

    useEffect(() => {
        const newRelatedUserList = [];

        if (user.relationships.length > 0) {
            _.forEach(user.relationships, (userRelationship) => {
                if (_.includes(orderMemberUserIdList, userRelationship.user_id)) {
                    newRelatedUserList.push(userRelationship);
                }
            });
        }

        setRelatedUserList(newRelatedUserList);
    }, [orderMemberUserIdList, user.relationships]);

    useEffect(() => {
        // Pass email up to parent components to be copied in aggregate
        handleSyncMemberEmail(member.id, user.contact?.email || null);
    }, [user.contact?.email]);

    const handleCopy = (event, text) => {
        event.stopPropagation();
        event.preventDefault();
        copyToClipboard(text);
    };

    if (userLoading) {
        return <div>Loading user ...</div>;
    }

    if (userError) {
        return <div>Error loading user {member.member_id}</div>;
    }

    return (
        <Stack direction="row" alignItems="center" width="100%" spacing={2}>
            <ListItemText
                primary={user.name}
                secondary={
                    <>
                        {_.map(relatedUserList, (relatedUser) => {
                            // Exclude any relationships that aren't related to order members
                            if (!_.includes(orderMemberUserIdList, relatedUser.related_user_id)) {
                                return null;
                            }
                            return (
                                <span key={relatedUser.id} style={{ display: 'block' }}>
                                    {_.startCase(relatedUser.relation)} of {relatedUser.related_user.first_name}
                                </span>
                            );
                        })}
                    </>
                }
            />

            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ paddingRight: (theme) => theme.spacing(4) }}
                data-no-dnd="true"
            >
                <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    control={
                        <Tooltip title="On title" placement="bottom" enterDelay={300}>
                            <Checkbox name="on_title" checked={member.on_title} onChange={handleChange} size="small" />
                        </Tooltip>
                    }
                    disabled={!showTitleCheckbox || updateMemberLoading}
                    sx={{
                        display: showTitleCheckbox ? 'inline-flex' : 'none',
                        margin: 0,
                    }}
                />

                <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    control={
                        <Tooltip title="On loan" placement="bottom" enterDelay={300}>
                            <Checkbox name="on_loan" checked={member.on_loan} onChange={handleChange} size="small" />
                        </Tooltip>
                    }
                    disabled={!showLoanCheckbox || updateMemberLoading}
                    sx={{
                        display: showLoanCheckbox ? 'inline-flex' : 'none',
                        margin: 0,
                    }}
                />
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2} data-no-dnd="true">
                <Tooltip
                    title={user.contact?.email ? `Copy: ${user.contact.email}` : ''}
                    placement="bottom"
                    enterDelay={300}
                >
                    <span style={{ display: 'inline-block' }}>
                        <IconButton
                            aria-label="email"
                            disabled={!user.contact?.email}
                            onClick={(event) => handleCopy(event, user.contact?.email)}
                            sx={{ color: (theme) => theme.palette.text.primary }}
                        >
                            <EmailIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip
                    title={user.contact?.phone ? `Copy: ${user.contact.phone}` : ''}
                    placement="bottom"
                    enterDelay={300}
                >
                    <span style={{ display: 'inline-block' }}>
                        <IconButton
                            aria-label="phone"
                            disabled={!user.contact?.phone}
                            onClick={(event) => handleCopy(event, user.contact?.phone)}
                            sx={{ color: (theme) => theme.palette.text.primary }}
                        >
                            <PhoneAndroidIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>
            </Stack>
        </Stack>
    );
}

function LegalEntityMemberItem({
    member,
    handleChange,
    showTitleCheckbox,
    showLoanCheckbox,
    updateMemberLoading,
    handleSyncMemberEmail,
    nested,
}) {
    const {
        data: legalEntityMember,
        isError: legalEntityMemberError,
        isLoading: legalEntityMemberLoading,
    } = useGetLegalEntityMemberQuery(member.member_id);
    const {
        data: legalEntity,
        isError: legalEntityError,
        isLoading: legalEntityLoading,
    } = useGetLegalEntityQuery(legalEntityMember?.legal_entity_id, { skip: !legalEntityMember });
    const { data: user, isError: userError, isLoading: userLoading } = useGetUserQuery(legalEntityMember?.user_id, {
        skip: !legalEntityMember,
    });

    useEffect(() => {
        // Pass email up to parent components to be copied in aggregate
        handleSyncMemberEmail(member.id, user.contact?.email || null);
    }, [user?.contact?.email]);

    const handleCopy = (event, text) => {
        event.stopPropagation();
        event.preventDefault();
        copyToClipboard(text);
    };

    if (legalEntityMemberLoading) {
        return <div>Loading legal entity member ...</div>;
    }

    if (legalEntityMemberError) {
        return <div>Error loading legal entity member {member.member_id}</div>;
    }

    const roleText = `${_.startCase(legalEntityMember.role)}${!nested ? ` of ${legalEntity.name}` : ''}`;
    const signorText = legalEntityMember.signor ? ' • Signor' : '';
    const secondaryText = `${roleText}${signorText}`;

    return (
        <Stack direction="row" alignItems="center" width="100%" spacing={2}>
            {nested && (
                <Box
                    sx={{
                        width: '4px',
                        alignSelf: 'stretch',
                        borderRadius: (theme) => theme.spacing(3),
                        // background: (theme) => getPartyColor(member.party),
                        background: (theme) => theme.palette.background.dark,
                        marginRight: (theme) => `${theme.spacing(1)} !important`,
                    }}
                />
            )}

            <ListItemText primary={legalEntityMember.name} secondary={secondaryText} />

            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ paddingRight: (theme) => theme.spacing(4) }}
                data-no-dnd="true"
            >
                <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    control={
                        <Tooltip title="On Title" placement="bottom" enterDelay={300}>
                            <Checkbox name="on_title" checked={member.on_title} onChange={handleChange} size="small" />
                        </Tooltip>
                    }
                    disabled={!showTitleCheckbox || updateMemberLoading}
                    sx={{
                        display: showTitleCheckbox && !nested ? 'inline-flex' : 'none',
                        margin: 0,
                    }}
                />

                <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    control={
                        <Tooltip title="On Loan" placement="bottom" enterDelay={300}>
                            <Checkbox name="on_loan" checked={member.on_loan} onChange={handleChange} size="small" />
                        </Tooltip>
                    }
                    disabled={!showLoanCheckbox || updateMemberLoading}
                    style={{
                        display: showLoanCheckbox && !nested ? 'inline-flex' : 'none',
                        margin: 0,
                    }}
                />
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2} data-no-dnd="true">
                <Tooltip
                    title={user?.contact?.email ? `Copy: ${user.contact.email}` : ''}
                    placement="bottom"
                    enterDelay={300}
                >
                    <span style={{ display: 'inline-block' }}>
                        <IconButton
                            aria-label="email"
                            disabled={!user?.contact?.email}
                            onClick={(event) => handleCopy(event, user?.contact?.email)}
                            sx={{ color: (theme) => theme.palette.text.primary }}
                        >
                            <EmailIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip
                    title={user?.contact?.phone ? `Copy: ${user.contact.phone}` : ''}
                    placement="bottom"
                    enterDelay={300}
                >
                    <span style={{ display: 'inline-block' }}>
                        <IconButton
                            aria-label="phone"
                            disabled={!user?.contact?.phone}
                            onClick={(event) => handleCopy(event, user?.contact?.phone)}
                            sx={{ color: (theme) => theme.palette.text.primary }}
                        >
                            <PhoneAndroidIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>
            </Stack>
        </Stack>
    );
}

function LegalEntityItem({
    member,
    handleChange,
    showTitleCheckbox,
    showLoanCheckbox,
    updateMemberLoading,
    handleSyncMemberEmail,
}) {
    const { data: legalEntity, isError: legalEntityError, isLoading: legalEntityLoading } = useGetLegalEntityQuery(
        member.member_id
    );

    const handleCopy = (event, text) => {
        event.stopPropagation();
        event.preventDefault();
        copyToClipboard(text);
    };

    useEffect(() => {
        // Pass email up to parent components to be copied in aggregate
        handleSyncMemberEmail(member.id, legalEntity.contact?.email || null);
    }, [legalEntity.contact?.email]);

    if (legalEntityLoading) {
        return <div>Loading legal entity ... </div>;
    }

    if (legalEntityError) {
        return <div>Error loading legal entity {member.member_id}</div>;
    }

    const typeText = _.startCase(_.get(legalEntity, 'type', ''));
    const commercialTypeText = _.startCase(_.get(legalEntity, 'commercial_type', ''));
    const secondaryText = `${typeText}${commercialTypeText ? ` • ${commercialTypeText}` : ''}`;

    return (
        <Stack direction="row" alignItems="center" width="100%" spacing={2}>
            <ListItemText primary={legalEntity.name} secondary={secondaryText} />

            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ paddingRight: (theme) => theme.spacing(4) }}
                data-no-dnd="true"
            >
                <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    control={
                        <Tooltip title="On Title" placement="bottom" enterDelay={300}>
                            <Checkbox name="on_title" checked={member.on_title} onChange={handleChange} size="small" />
                        </Tooltip>
                    }
                    disabled={!showTitleCheckbox || updateMemberLoading}
                    sx={{
                        display: showTitleCheckbox ? 'inline-flex' : 'none',
                        margin: 0,
                    }}
                />

                <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    control={
                        <Tooltip title="On Loan" placement="bottom" enterDelay={300}>
                            <Checkbox name="on_loan" checked={member.on_loan} onChange={handleChange} size="small" />
                        </Tooltip>
                    }
                    disabled={!showLoanCheckbox || updateMemberLoading}
                    sx={{
                        display: showLoanCheckbox ? 'inline-flex' : 'none',
                        margin: 0,
                    }}
                />
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2} data-no-dnd="true">
                <Tooltip
                    title={legalEntity.contact?.email ? `Copy: ${legalEntity.contact?.email}` : ''}
                    placement="bottom"
                    enterDelay={300}
                >
                    <span style={{ display: 'inline-block' }}>
                        <IconButton
                            aria-label="email"
                            disabled={!legalEntity.contact?.email}
                            onClick={(event) => handleCopy(event, legalEntity.contact?.email)}
                            sx={{ color: (theme) => theme.palette.text.primary }}
                        >
                            <EmailIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip
                    title={legalEntity.contact?.phone ? `Copy: ${legalEntity.contact.phone}` : ''}
                    placement="bottom"
                    enterDelay={300}
                >
                    <span style={{ display: 'inline-block' }}>
                        <IconButton
                            aria-label="phone"
                            disabled={!legalEntity.contact?.phone}
                            onClick={(event) => handleCopy(event, legalEntity.contact?.phone)}
                            sx={{ color: (theme) => theme.palette.text.primary }}
                        >
                            <PhoneAndroidIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>
            </Stack>
        </Stack>
    );
}

function MemberPartyRoleListItem({ member, orderMembers, setEditMemberId, handleSyncMemberEmail, nested = false }) {
    const [updateOrderMember, { isLoading: updateMemberLoading }] = useUpdateOrderMemberMutation();

    const [showTitleCheckbox, setShowTitleCheckbox] = useState(false);
    const [showLoanCheckbox, setShowLoanCheckbox] = useState(false);

    useEffect(() => {
        if (_.includes([ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.borrower], member.role)) {
            setShowTitleCheckbox(true);
            setShowLoanCheckbox(true);
        } else if (member.role === ORDER_ROLE_TYPE.seller) {
            setShowTitleCheckbox(true);
        } else {
            setShowTitleCheckbox(false);
            setShowLoanCheckbox(false);
        }
    }, [member]);

    useEffect(() => {
        // Cleanup parent email map on unmount
        return () => handleSyncMemberEmail(member.id, null);
    }, []);

    const handleChange = async (event) => {
        event.stopPropagation();

        const updateMemberPayload = {
            orderId: member.order_id,
            memberId: member.id,
            memberData: {
                [event.target.name]: event.target.checked,
            },
        };

        const { data } = await updateOrderMember(updateMemberPayload);
    };

    const toggleModal = () => {
        setEditMemberId(member.id);
    };

    return (
        <ListItemButton onClick={toggleModal} sx={{ pl: 3, pr: 3, pt: 2, pb: 2 }}>
            {member.member_type === ENTITY_TYPE.user && (
                <UserItem
                    member={member}
                    orderMembers={orderMembers}
                    handleChange={handleChange}
                    showTitleCheckbox={showTitleCheckbox}
                    showLoanCheckbox={showLoanCheckbox}
                    updateMemberLoading={updateMemberLoading}
                    handleSyncMemberEmail={handleSyncMemberEmail}
                />
            )}

            {member.member_type === ENTITY_TYPE.legal_entity_member && (
                <LegalEntityMemberItem
                    member={member}
                    // orderMembers={orderMembers}
                    handleChange={handleChange}
                    showTitleCheckbox={showTitleCheckbox}
                    showLoanCheckbox={showLoanCheckbox}
                    updateMemberLoading={updateMemberLoading}
                    handleSyncMemberEmail={handleSyncMemberEmail}
                    nested={nested}
                />
            )}

            {member.member_type === ENTITY_TYPE.legal_entity && (
                <LegalEntityItem
                    member={member}
                    // orderMembers={orderMembers}
                    handleChange={handleChange}
                    showTitleCheckbox={showTitleCheckbox}
                    showLoanCheckbox={showLoanCheckbox}
                    updateMemberLoading={updateMemberLoading}
                    handleSyncMemberEmail={handleSyncMemberEmail}
                />
            )}
        </ListItemButton>
    );
}

MemberPartyRoleListItem.propTypes = {
    member: PropTypes.object.isRequired,
    orderMembers: PropTypes.array.isRequired,
    setEditMemberId: PropTypes.func.isRequired,
    handleSyncMemberEmail: PropTypes.func.isRequired,
    nested: PropTypes.bool,
};

function DraggableMemberPartyRoleListItem(props) {
    const { attributes, listeners, setNodeRef } = useDraggable({
        id: props.member.id,
    });

    return (
        <div ref={setNodeRef} {...listeners} {...attributes}>
            <MemberPartyRoleListItem {...props} />
        </div>
    );
}

export function DragOverlayMember({ orderId, memberId }) {
    const { data: member, isError: memberError, isLoading: memberLoading } = useGetOrderMemberQuery({
        orderId,
        memberId,
    });

    return (
        <Paper
            // variant="outlined"
            elevation={3}
            sx={{
                p: 3,
                pt: 2,
                pb: 2,
            }}
        >
            <Typography variant="body1">{_.get(member, 'member.name')}</Typography>
        </Paper>
    );
}

export default DraggableMemberPartyRoleListItem;
