import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import ErrorIcon from '@mui/icons-material/Error';
// import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Card, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ParcelTriggerIconButton } from 'components/Order/Parcel/components/ParcelTriggerList';
import { useTriggers } from 'components/common/hooks/useTriggers';
import { CLOSING_PARCEL_TYPES, PARCEL_STATUS, PARCEL_TERMINAL_STATUSES, PARCEL_TYPE } from 'helpers/constants';
import { findChildParcelsInOrder } from 'helpers/utils';
import ParcelCardAction from './ParcelCardAction';
import ParcelCardContent from './ParcelCardContent';

export const generateParcelClass = (parcel) => {
    /*
        parcel complete: green,
        parcel in progress:
            if due date is within 7 days: yellow
            if due date is within 3 days: red
        default: gray
    */

    const defaultClass = 'default';

    if (parcel.current_status.status === PARCEL_STATUS.complete) {
        return { parcelClass: 'success', daysUntilDue: null };
    }

    if (!parcel.required_complete_date) {
        return { parcelClass: defaultClass, daysUntilDue: null };
    }

    const currentDate = new Date();
    const requiredCompleteDate = parseISO(parcel.required_complete_date);
    const daysUntilDue = differenceInDays(requiredCompleteDate, currentDate);

    if (daysUntilDue <= 7) {
        if (daysUntilDue <= 3) {
            return { parcelClass: 'error', daysUntilDue: daysUntilDue };
        }

        return { parcelClass: 'warning', daysUntilDue: daysUntilDue };
    }

    return { parcelClass: defaultClass, daysUntilDue: daysUntilDue };
};

function determineNotRequiredStatus(parcel) {
    // if parcel is terminal status and parcel is marked as not required in additional data
    const isParcelComplete = _.includes(PARCEL_TERMINAL_STATUSES, parcel.current_status.status);

    if (parcel.type === PARCEL_TYPE.keys) {
        // specific to keys: additionalData.needs_transfer === false
        const isKeysNotRequired = _.get(parcel, 'additional_data.needs_transfer', null) === false;
        return isParcelComplete && isKeysNotRequired;
    }

    const isParcelNotRequired = _.get(parcel, `additional_data.${parcel.type}_required`, null) === false;
    return isParcelComplete && isParcelNotRequired;
}

function ParcelCard({ order, parcel }) {
    const navigate = useNavigate();
    const { triggers, triggerOrder, activeTriggers } = useTriggers(parcel);
    const [childParcels, setChildParcels] = useState([]);

    useEffect(() => {
        setChildParcels(findChildParcelsInOrder(order, parcel.id));
    }, [order, parcel.id]);

    const handleNavigate = (event) => {
        event.preventDefault();
        navigate(`/order/${order.id}/parcel/${parcel.id}`);
    };

    const { parcelClass, daysUntilDue } = useMemo(() => generateParcelClass(parcel), [parcel]);

    // Determine if parcel has been marked as 'not required'
    const isNotRequired = useMemo(() => determineNotRequiredStatus(parcel), [parcel]);

    return (
        <Card
            elevation={0}
            sx={{
                backgroundColor: (theme) => theme.palette.white,
                border: (theme) => `1px solid ${theme.palette.border}`,
                borderRadius: (theme) => theme.spacing(0.5),
                padding: (theme) => theme.spacing(3),
                width: '100%',
                maxWidth: '640px',
                height: isNotRequired ? 'auto' : '100%',
                boxSizing: 'border-box',
                display: 'flex',
            }}
        >
            <Stack
                spacing={2}
                sx={{
                    flex: 1,
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ minHeight: '30px' }}>
                    <Typography
                        variant="overline"
                        fontWeight="600"
                        fontSize="12px"
                        color={(theme) => theme.palette.text.primary}
                        onClick={handleNavigate}
                        sx={{
                            '&:hover': {
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            },
                        }}
                    >
                        {parcel.name}
                    </Typography>

                    {isNotRequired ? (
                        <Chip
                            size="small"
                            label="Not Required"
                            color="default"
                            sx={{
                                borderRadius: (theme) => theme.spacing(0.5),
                            }}
                        />
                    ) : (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {parcelClass === 'success' && (
                                <Tooltip title="Complete" placement="bottom" enterDelay={300}>
                                    <DoneIcon color="success" />
                                </Tooltip>
                            )}
                            {parcelClass === 'warning' && (
                                <Tooltip title={`Due in ${daysUntilDue} Days`} placement="bottom" enterDelay={300}>
                                    <WarningAmberIcon color="warning" />
                                </Tooltip>
                            )}
                            {parcelClass === 'error' && (
                                <Tooltip
                                    title={daysUntilDue > 0 ? `Due in ${daysUntilDue} Days` : 'Passed Due Date'}
                                    placement="bottom"
                                    enterDelay={300}
                                >
                                    <ErrorOutlineIcon color="error" />
                                </Tooltip>
                            )}

                            {_.includes(activeTriggers, 'not_required') && (
                                <Tooltip title={'Mark as Not Required'} placement="bottom" enterDelay={300}>
                                    <span style={{ display: 'inline-block' }}>
                                        <ParcelTriggerIconButton
                                            trigger={triggers.not_required}
                                            parcel={parcel}
                                            icon={<CloseIcon fontSize="small" />}
                                            buttonProps={{
                                                size: 'small',
                                                variant: 'text',
                                                color: 'default',
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </Stack>
                    )}
                </Stack>

                <Stack
                    spacing={3}
                    sx={{
                        display: isNotRequired ? 'none' : 'flex',
                        flexGrow: 1,
                    }}
                >
                    <Stack
                        spacing={2}
                        sx={{
                            flexGrow: 1,
                            paddingTop: (theme) => theme.spacing(3),
                            // paddingBottom: (theme) => theme.spacing(4),
                            borderTop: (theme) => `1px solid ${theme.palette.border}`,
                            minHeight: '160px',
                            // maxHeight: '360px',
                            // overflowY: 'auto',
                        }}
                    >
                        <ParcelCardContent order={order} parcel={parcel} />
                    </Stack>

                    {_.includes(CLOSING_PARCEL_TYPES, parcel.type) ? (
                        _.map(childParcels, (childParcel) => {
                            if (!_.includes(PARCEL_TERMINAL_STATUSES, childParcel.current_status.status)) {
                                return <ParcelCardAction key={childParcel.id} order={order} parcel={childParcel} />;
                            } else {
                                return null;
                            }
                        })
                    ) : (
                        <ParcelCardAction order={order} parcel={parcel} />
                    )}
                </Stack>
            </Stack>
        </Card>
    );
}

ParcelCard.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default ParcelCard;
