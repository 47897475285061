import { LoadingButton } from '@mui/lab';

import { styled } from '@mui/material/styles';

import { generateCoreButtonStyles } from './Button';

// Should match the styles for Button
const StyledLoadingButton = styled(LoadingButton)(generateCoreButtonStyles);

export default StyledLoadingButton;
