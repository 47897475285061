import { Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import NoteListStackItem from './NoteListStackItem';

function NoteListStack({ notes, activeNoteId, setActiveNoteId, showTag = true }) {
    return (
        <Stack
            sx={{
                borderRight: 0,
                width: '100%',
            }}
            spacing={0}
        >
            {_.map(notes, (note) => {
                return (
                    <NoteListStackItem
                        key={note.id}
                        note={note}
                        isActive={activeNoteId === note.id}
                        setActiveNoteId={setActiveNoteId}
                        showTag={showTag}
                    />
                );
            })}
        </Stack>
    );
}

NoteListStack.propTypes = {
    notes: PropTypes.array.isRequired,
    activeNoteId: PropTypes.string, // not required to allow for null/undefined
    setActiveNoteId: PropTypes.func.isRequired,
};

export default NoteListStack;
