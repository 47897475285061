import { Stack } from '@mui/material';
import React from 'react';
import TitleList from '../../components/TitleList/TitleList';

function TitleListPage() {

    return (
         <Stack style={{ height: '100%'}}>
            <TitleList />
        </Stack>
    );
}

export default TitleListPage;
