import EventIcon from '@mui/icons-material/Event';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import TodayIcon from '@mui/icons-material/Today';
import {
    Box,
    Checkbox,
    Divider,
    IconButton,
    InputAdornment,
    ListItemText,
    ListSubheader,
    MenuItem,
    Stack,
    Tooltip,
} from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { getUnixTime } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';

import useFilterQueryParams from 'components/common/hooks/useFilterQueryParams';
import FilterTextField from 'components/common/styled/FilterTextField';
import {
    DATE_RANGE_MAP,
    ORDER_FILTER_TYPE,
    ORDER_STATUS,
    TRANSACTION_ORDER_TYPE,
    TRANSACTION_TYPE,
} from 'helpers/constants';
import { calculateDateRangePreset } from 'helpers/utils';

function OrderListFilters({ filters, setFilters, resetFilters, defaultFilters, hideFilters = [] }) {
    const searchInputRef = useRef();

    const [syncFilterMap, searchParams] = useFilterQueryParams(filters, defaultFilters, {
        multiArgFilterTypes: [ORDER_FILTER_TYPE.orderType, ORDER_FILTER_TYPE.orderStatus],
    });

    const [dateRangePreset, setDateRangePreset] = useState('');

    const setDateRange = (newDateRange) => {
        const filterUpdate = {
            [ORDER_FILTER_TYPE.closeDateRange]: [...newDateRange],
        };

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string isn't valid for some filters
        };

        if (filterName === ORDER_FILTER_TYPE.transactionType) {
            // Order type categories are related to transaction type
            // Reset order type category filter if transaction type is changed
            filterUpdate[ORDER_FILTER_TYPE.orderType] = defaultFilters[ORDER_FILTER_TYPE.orderType];
        }

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const changeHandler = (event) => {
        handleFilter(event);
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 300), []);

    const setSearchInput = (value) => {
        searchInputRef.current.value = value;
    };

    const handleDateRangePresetChange = (dateRangePresetValue) => {
        setDateRangePreset(dateRangePresetValue);

        // Calculate date range from preset value
        const newDateRange = calculateDateRangePreset(dateRangePresetValue);
        setDateRange(newDateRange);
    };

    const resetAllFilters = () => {
        // Reset local filters
        setDateRangePreset('');

        // Reset local uncontrolled search input
        setSearchInput('');

        // Reset parent filters
        resetFilters();
    };

    useEffect(() => {
        // Stop the invocation of the debounced function after unmounting
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    useEffect(() => {
        if (_.keys(syncFilterMap).length > 0) {
            const updatedFilterMap = {
                ...defaultFilters,
                ...syncFilterMap,
            };

            // Update local filters
            setFilters(updatedFilterMap);

            // Sync uncontrolled search input ref
            setSearchInput(_.get(updatedFilterMap, ORDER_FILTER_TYPE.search, ''));
        }
    }, [syncFilterMap]);

    useEffect(() => {
        // Compare and sync local dateRangePreset to match close date range
        const closeDateRange = filters[ORDER_FILTER_TYPE.closeDateRange];

        if (_.every(closeDateRange, (item) => item === null)) {
            // If every item in close date range is null, reset date range preset
            if (dateRangePreset !== '') {
                setDateRangePreset('');
            }
        } else {
            // Date range is not empty
            if (dateRangePreset !== '') {
                // If date range preset is not empty, compare date range preset to close date range
                const newDateRangePreset = calculateDateRangePreset(dateRangePreset);

                // NOTE: we are using getUnixTime() as it returns the number of seconds since the Unix Epoch
                // If we use other date-fns functions like isEqual or getTime, minor differences in milliseconds will cause the comparison to fail
                if (
                    !_.every(
                        newDateRangePreset,
                        (dateRangeValue, index) => getUnixTime(dateRangeValue) === getUnixTime(closeDateRange[index])
                    )
                ) {
                    // Date range preset doesn't match close date range, reset date range preset
                    setDateRangePreset('');
                }
            }
        }
    }, [filters[ORDER_FILTER_TYPE.closeDateRange]]);

    return (
        <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <FilterTextField
                    name={ORDER_FILTER_TYPE.search}
                    label=""
                    type="search"
                    variant="outlined"
                    placeholder="Search orders ..."
                    onChange={debouncedChangeHandler}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    inputRef={searchInputRef}
                    size="small"
                    sx={{
                        flexGrow: 1,
                        maxWidth: '480px',
                        ...(_.includes(hideFilters, ORDER_FILTER_TYPE.search) && { display: 'none' }),
                    }}
                />
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2}>
                <FilterTextField
                    name={ORDER_FILTER_TYPE.transactionType}
                    label="Transaction Type"
                    variant="outlined"
                    onChange={handleFilter}
                    value={filters.transactionType}
                    size="small"
                    sx={{
                        minWidth: '160px',
                        ...(_.includes(hideFilters, ORDER_FILTER_TYPE.transactionType) && { display: 'none' }),
                    }}
                    select
                >
                    <MenuItem value="">All</MenuItem>
                    {_.map(TRANSACTION_TYPE, (transactionValue) => {
                        return (
                            <MenuItem key={transactionValue} value={transactionValue}>
                                {_.startCase(transactionValue)}
                            </MenuItem>
                        );
                    })}
                </FilterTextField>

                <FilterTextField
                    name={ORDER_FILTER_TYPE.orderType}
                    label="Order Type"
                    variant="outlined"
                    onChange={handleFilter}
                    value={filters.orderType}
                    size="small"
                    sx={{
                        minWidth: '160px',
                        ...(_.includes(hideFilters, ORDER_FILTER_TYPE.orderType) && { display: 'none' }),
                    }}
                    select
                    SelectProps={{
                        renderValue: (selected) => _.map(selected, (item) => _.startCase(item)).join(', '),
                        multiple: true,
                    }}
                >
                    {/* <MenuItem value="">All</MenuItem> */}
                    <ListSubheader>{_.startCase(TRANSACTION_TYPE.purchase)}</ListSubheader>
                    {_.map(TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.purchase], (orderValue) => {
                        return (
                            <MenuItem
                                key={orderValue}
                                value={orderValue}
                                disabled={filters.transactionType === TRANSACTION_TYPE.refinance}
                            >
                                <Checkbox checked={_.includes(filters.orderType, orderValue)} />
                                <ListItemText primary={_.startCase(orderValue)} />
                            </MenuItem>
                        );
                    })}
                    <ListSubheader>{_.startCase(TRANSACTION_TYPE.refinance)}</ListSubheader>
                    {_.map(TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.refinance], (orderValue) => {
                        return (
                            <MenuItem
                                key={orderValue}
                                value={orderValue}
                                disabled={filters.transactionType === TRANSACTION_TYPE.purchase}
                            >
                                <Checkbox checked={_.includes(filters.orderType, orderValue)} />
                                <ListItemText primary={_.startCase(orderValue)} />
                            </MenuItem>
                        );
                    })}
                </FilterTextField>

                <FilterTextField
                    name={ORDER_FILTER_TYPE.orderStatus}
                    label="Order Status"
                    variant="outlined"
                    onChange={handleFilter}
                    value={filters.orderStatus}
                    size="small"
                    sx={{
                        minWidth: '160px',
                        ...(_.includes(hideFilters, ORDER_FILTER_TYPE.orderStatus) && { display: 'none' }),
                    }}
                    select
                    SelectProps={{
                        renderValue: (selected) => _.map(selected, (item) => _.startCase(item)).join(', '),
                        multiple: true,
                    }}
                >
                    {/* <MenuItem value="">All</MenuItem> */}
                    {_.map(ORDER_STATUS, (orderStatusValue) => {
                        return (
                            <MenuItem key={orderStatusValue} value={orderStatusValue}>
                                <Checkbox checked={_.includes(filters.orderStatus, orderStatusValue)} />
                                <ListItemText primary={_.startCase(orderStatusValue)} />
                            </MenuItem>
                        );
                    })}
                </FilterTextField>

                <FilterTextField
                    name={ORDER_FILTER_TYPE.closeDateRange}
                    label="Close Date Range"
                    variant="outlined"
                    onChange={({ target }) => handleDateRangePresetChange(target.value)}
                    value={dateRangePreset}
                    size="small"
                    sx={{
                        minWidth: '160px',
                        ...(_.includes(hideFilters, ORDER_FILTER_TYPE.closeDateRange) && { display: 'none' }),
                    }}
                    select
                >
                    <MenuItem value="">All</MenuItem>
                    <Divider />
                    <MenuItem value={DATE_RANGE_MAP.this_week}>This Week</MenuItem>
                    <MenuItem value={DATE_RANGE_MAP.next_week}>Next Week</MenuItem>
                    <MenuItem value={DATE_RANGE_MAP.last_week}>Last Week</MenuItem>
                    <Divider />
                    <MenuItem value={DATE_RANGE_MAP.this_month}>This Month</MenuItem>
                    <MenuItem value={DATE_RANGE_MAP.next_month}>Next Month</MenuItem>
                    <MenuItem value={DATE_RANGE_MAP.last_month}>Last Month</MenuItem>
                </FilterTextField>

                {!_.includes(hideFilters, ORDER_FILTER_TYPE.closeDateRange) && (
                    <DateRangePicker
                        name={ORDER_FILTER_TYPE.closeDateRange}
                        value={filters[ORDER_FILTER_TYPE.closeDateRange]}
                        onChange={(newValue) => {
                            setDateRange(newValue);
                            // setDateRangePreset('');
                        }}
                        renderInput={(startProps, endProps) => (
                            <>
                                <FilterTextField
                                    {...startProps}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment disablePointerEvents={false} position="end">
                                                {filters[ORDER_FILTER_TYPE.closeDateRange][0] ? (
                                                    <EventAvailableIcon />
                                                ) : (
                                                    <TodayIcon />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    size="small"
                                    sx={{
                                        maxWidth: '160px',
                                    }}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <FilterTextField
                                    {...endProps}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment disablePointerEvents={false} position="end">
                                                {filters[ORDER_FILTER_TYPE.closeDateRange][1] ? (
                                                    <EventAvailableIcon />
                                                ) : (
                                                    <EventIcon />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    size="small"
                                    sx={{
                                        maxWidth: '160px',
                                    }}
                                />
                            </>
                        )}
                        clearable
                    />
                )}

                <Box flexGrow="1" />

                <Tooltip title="Reset Filters" placement="bottom" enterDelay={300}>
                    <IconButton
                        onClick={() => {
                            resetAllFilters();
                        }}
                    >
                        <RestartAltIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Stack>
    );
}

OrderListFilters.propTypes = {
    defaultFilters: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    hideFilters: PropTypes.array,
};

export default OrderListFilters;
