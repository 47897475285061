import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ENTITY_TYPE } from '../../../helpers/constants';
import NewLegalEntityForm from '../../LegalEntity/components/NewLegalEntityForm';
import NewUserForm from '../../User/components/NewUserForm';
import { Tab, Tabs } from '../../common/styled';

const CreateContact = ({ handleSubmit }) => {
    const [contactType, setContactType] = useState(ENTITY_TYPE.user);

    const handleChange = (event, newContactType) => {
        setContactType(newContactType);
    };

    return (
        <Stack spacing={4} sx={{ width: '480px' }}>
            <Stack direction="column" spacing={2}>
                <Typography variant="h4">New Contact</Typography>

                <Tabs value={contactType} onChange={handleChange} textColor="primary" indicatorColor="primary">
                    <Tab value={ENTITY_TYPE.user} label="User" />
                    <Tab value={ENTITY_TYPE.legal_entity} label="Organization" />
                </Tabs>
            </Stack>

            {contactType === ENTITY_TYPE.user && <NewUserForm handleSubmit={handleSubmit} />}
            {contactType === ENTITY_TYPE.legal_entity && <NewLegalEntityForm handleSubmit={handleSubmit} />}
        </Stack>
    );
};

CreateContact.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export default CreateContact;
