import { Box, MenuItem, Stack, TextField } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useCreateParcelActionMutation } from 'api/parcel';
import { LoadingButton } from 'components/common/styled';
import MemberSelectField from 'components/Order/Members/components/MemberSelectField';

const INITIAL_ACTION_STATE = {
    type: '',
    parcel_id: '',
    order_member_ids: [],
};

const AssignActionForm = ({ actionTypeOptions, orderMemberOptions, handleSubmit }) => {
    const [createAction, { isLoading: isActionLoading }] = useCreateParcelActionMutation();
    const [actionData, setActionData] = useState({ ...INITIAL_ACTION_STATE });

    const handleOrderMembersChange = (selectedMembers) => {
        setActionData({
            ...actionData,
            order_member_ids: selectedMembers,
        });
    };

    const handleActionTypeChange = (event) => {
        const { value } = event.target;
        const foundActionType = _.find(actionTypeOptions, { type: value });
        // Ensure that the parcel id is also set
        // NOTE: this might cause issues if the same action type is available for multiple parcels
        setActionData({
            ...actionData,
            type: foundActionType.type,
            parcel_id: foundActionType.parcel_id,
        });
    };

    const handleCreateAction = async (event) => {
        event.preventDefault();

        const submitted = await submitAction();
        if (submitted) {
            handleSubmit();
        }
    };

    const formatActionPayload = () => {
        const actionPayload = {
            parcelId: actionData.parcel_id,
            actionData: actionData,
        };

        return actionPayload;
    };

    const submitAction = async () => {
        const { data } = await createAction(formatActionPayload());
        return !!data;
    };

    return (
        <form onSubmit={handleCreateAction}>
            <Stack spacing={4}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <MemberSelectField
                        selectedOrderMembers={actionData.order_member_ids}
                        setSelectedOrderMembers={handleOrderMembersChange}
                        orderMembers={orderMemberOptions}
                        label="Order Members"
                        showLabel={true}
                        multiple={true}
                        TextFieldProps={{
                            sx: {
                                flex: 1,
                            },
                            required: true,
                        }}
                    />

                    <TextField
                        name="type"
                        label="Action Type"
                        onChange={handleActionTypeChange}
                        value={actionData.type}
                        sx={{ flex: 1 }}
                        size="small"
                        required
                        select
                    >
                        {_.map(actionTypeOptions, (actionType) => (
                            <MenuItem key={actionType.type} value={actionType.type}>
                                {actionType.friendly_name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Stack>

                <Box>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%' }}
                    >
                        <Box />
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={isActionLoading}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                        >
                            Assign
                        </LoadingButton>
                    </Stack>
                </Box>
            </Stack>
        </form>
    );
};

AssignActionForm.propTypes = {
    actionTypeOptions: PropTypes.array.isRequired,
    orderMemberOptions: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default AssignActionForm;
