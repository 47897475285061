import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Breadcrumbs, Link as MUILink, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function OrderBreadcrumbs({ orderId }) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <MUILink
                component={Link}
                underline="hover"
                to={`/order/${orderId}`}
                variant="body1"
                color="text.secondary"
                fontWeight="400"
            >
                <Stack direction="row" alignItems="center" spacing={1}>
                    <KeyboardBackspaceOutlinedIcon fontSize="small" />
                    <span>Order</span>
                </Stack>
            </MUILink>
        </Breadcrumbs>
    );
}

OrderBreadcrumbs.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderBreadcrumbs;
