import { Box, Dialog, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useUpdateOrderMutation } from '../../../../api/order';
import { TRANSACTION_ORDER_TYPE, TRANSACTION_TYPE, WORKFLOW_TRANSACTION_MAP } from '../../../../helpers/constants';
import { generateUpdateObject } from '../../../../helpers/utils';
import AddressForm, { formatAddressPayload } from '../../../common/form/address/AddressForm';
import CloseDateField from '../../../common/form/order/CloseDateField';
import FundDateField from '../../../common/form/order/FundDateField';
import OpenDateField from '../../../common/form/order/OpenDateField';
import OrderTypeField from '../../../common/form/order/OrderTypeField';
import QualiaOrderNumberField from '../../../common/form/order/QualiaOrderNumberField';
import TransactionTypeField from '../../../common/form/order/TransactionTypeField';
import DollarFieldMask from '../../../common/mask/DollarFieldMask';
import { LoadingButton } from '../../../common/styled';

const BaseOrderFormFields = (props) => {
    const {
        qualiaOrderNumber,
        setQualiaOrderNumber,
        isDuplicate,
        setIsDuplicate,
        transactionType,
        setTransactionType,
        orderType,
        setOrderType,
        setTitleWorkflow,
        address,
        setAddress,
    } = props;

    const handleTransactionChange = (newTransactionType) => {
        setTransactionType(newTransactionType);

        // React to transaction type change
        setTitleWorkflow(WORKFLOW_TRANSACTION_MAP[newTransactionType]);
        setOrderType(TRANSACTION_ORDER_TYPE[newTransactionType].residential);
    };

    return (
        <Stack spacing={3}>
            <QualiaOrderNumberField
                qualiaOrderNumber={qualiaOrderNumber}
                setQualiaOrderNumber={setQualiaOrderNumber}
                isDuplicate={isDuplicate}
                setIsDuplicate={setIsDuplicate}
            />

            <TransactionTypeField
                transactionType={transactionType}
                setTransactionType={handleTransactionChange}
                disabled={true}
            />

            <OrderTypeField orderType={orderType} setOrderType={setOrderType} transactionType={transactionType} />

            <AddressForm addressData={address} setAddressData={setAddress} showLabel={true} required={true} />
        </Stack>
    );
};

const TransactionOrderFormFields = (props) => {
    const {
        transactionType,
        salesPrice,
        setSalesPrice,
        loanAmount,
        setLoanAmount,
        openDate,
        setOpenDate,
        closeDate,
        setCloseDate,
        confirmedCloseDate,
        setConfirmedCloseDate,
        fundDate,
        setFundDate,
    } = props;

    const handleLocalSalesPriceChange = ({ target }) => {
        const floatValue = parseFloat(target.value);
        const originalFloatValue = parseFloat(salesPrice);

        if (isNaN(floatValue)) {
            setSalesPrice('0');
        } else if (floatValue !== originalFloatValue) {
            setSalesPrice(target.value);
        }
    };

    const handleLocalLoanAmountChange = ({ target }) => {
        const floatValue = parseFloat(target.value);
        const originalFloatValue = parseFloat(loanAmount);

        if (isNaN(floatValue)) {
            setLoanAmount('0');
        } else if (floatValue !== originalFloatValue) {
            setLoanAmount(target.value);
        }
    };

    return (
        <Stack spacing={3}>
            <div />

            {transactionType === TRANSACTION_TYPE.purchase && (
                <TextField
                    label="Sales Price"
                    value={`${salesPrice || '0'}`}
                    onChange={handleLocalSalesPriceChange}
                    onFocus={(event) => {
                        // NOTE: timeout to get around various browser event issues
                        const target = event.target;
                        setTimeout(() => target.select(), 0);
                    }}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        inputComponent: DollarFieldMask,
                    }}
                    required
                />
            )}

            <TextField
                label="Loan Amount"
                value={`${loanAmount || '0'}`}
                onChange={handleLocalLoanAmountChange}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                variant="outlined"
                size="small"
                InputProps={{
                    inputComponent: DollarFieldMask,
                }}
                required
            />

            <div />

            <OpenDateField openDate={openDate} setOpenDate={setOpenDate} disableFields={false} />

            <CloseDateField
                openDate={openDate}
                closeDate={closeDate}
                setCloseDate={setCloseDate}
                confirmedCloseDate={confirmedCloseDate}
                setConfirmedCloseDate={setConfirmedCloseDate}
                disableFields={false}
                editMode={true}
            />

            <FundDateField
                closeDate={closeDate}
                fundDate={fundDate}
                setFundDate={setFundDate}
                // defaultFundDatePeriod={3}
                disableFields={false}
                editMode={true}
            />
        </Stack>
    );
};

const EditOrderModal = ({ order, open, handleClose }) => {
    const [updateOrder, { isLoading: updateOrderLoading }] = useUpdateOrderMutation();

    // Core fields
    const [qualiaOrderNumber, setQualiaOrderNumber] = useState(order.qualia_order_number);
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [invalidQualiaOrderNumber, setInvalidQualiaOrderNumber] = useState(false);
    const [transactionType, setTransactionType] = useState(order.transaction_type);
    const [orderType, setOrderType] = useState(order.order_type);
    const [titleWorkflow, setTitleWorkflow] = useState(order.title_workflow);
    const [address, setAddress] = useState(order.property_address);

    // Transaction fields
    const [salesPrice, setSalesPrice] = useState(order.purchase_price); // TODO map name
    const [loanAmount, setLoanAmount] = useState(order.loan_amount);
    const [openDate, setOpenDate] = useState(order.contract_start_date);
    const [closeDate, setCloseDate] = useState(order.close_datet);
    const [confirmedCloseDate, setConfirmedCloseDate] = useState(order.confirmed_close_date);
    const [fundDate, setFundDate] = useState(order.disbursement_date); // TODO map name

    const [updatePayload, setUpdatePayload] = useState({});

    useEffect(() => {
        if (order && open) {
            setQualiaOrderNumber(order.qualia_order_number);
            setTransactionType(order.transaction_type);
            setOrderType(order.order_type);
            setTitleWorkflow(order.title_workflow);
            setAddress({
                ...order.property_address,
                street_additional: order.property_address.street_additional || '',
            });
            setSalesPrice(order.purchase_price);
            setLoanAmount(order.loan_amount);
            setOpenDate(order.contract_start_date);
            setCloseDate(order.close_date);
            setConfirmedCloseDate(order.confirmed_close_date);
            setFundDate(order.disbursement_date);
        }
    }, [order, open]);

    useEffect(() => {
        // Verify if the qualia order number is a duplicate and not the original order number
        if (qualiaOrderNumber !== order.qualia_order_number && isDuplicate) {
            setInvalidQualiaOrderNumber(true);
        } else {
            setInvalidQualiaOrderNumber(false);
        }
    }, [order.qualia_order_number, qualiaOrderNumber, isDuplicate]);

    useEffect(() => {
        // Listen to all form field changes and generate update payload object
        setUpdatePayload(generateUpdatePayload());
    }, [
        qualiaOrderNumber,
        transactionType,
        orderType,
        titleWorkflow,
        address,
        salesPrice,
        loanAmount,
        openDate,
        closeDate,
        confirmedCloseDate,
        fundDate,
    ]);

    const handleOrderUpdate = async (updatedOrderData) => {
        const updateOrderPayload = {
            orderId: order.id,
            orderData: updatedOrderData,
        };

        const { data } = await updateOrder(updateOrderPayload);
        if (data) {
            handleClose();
        }
    };

    const generateUpdatePayload = () => {
        const localOrderFields = {
            qualia_order_number: qualiaOrderNumber,
            transaction_type: transactionType,
            order_type: orderType,
            title_workflow: titleWorkflow,
            property_address: formatAddressPayload(address),
            purchase_price: salesPrice,
            loan_amount: loanAmount,
            contract_start_date: openDate,
            close_date: closeDate,
            confirmed_close_date: confirmedCloseDate,
            disbursement_date: fundDate,
        };

        const baseUpdateObject = generateUpdateObject(order, localOrderFields, _.keys(localOrderFields));

        // json stringify will automatically remove undefined values from objects
        // however, we want to do that preimptively to calculate if an update should be submitted
        _.forEach(baseUpdateObject, (value, key) => {
            if (value === undefined) {
                delete baseUpdateObject[key];
            }
        });
        return baseUpdateObject;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (_.keys(updatePayload).length > 0) {
            // Submit update
            handleOrderUpdate(updatePayload);
        }
    };

    return (
        <Dialog
            open={!!open}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
        >
            <Box
                sx={{
                    padding: (theme) => theme.spacing(6, 8),
                    width: '480px',
                }}
            >
                <Typography variant="h4" sx={{ marginBottom: (theme) => theme.spacing(6) }}>
                    Update Order
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <BaseOrderFormFields
                            qualiaOrderNumber={qualiaOrderNumber}
                            setQualiaOrderNumber={setQualiaOrderNumber}
                            isDuplicate={invalidQualiaOrderNumber}
                            setIsDuplicate={setIsDuplicate}
                            transactionType={transactionType}
                            setTransactionType={setTransactionType}
                            orderType={orderType}
                            setOrderType={setOrderType}
                            setTitleWorkflow={setTitleWorkflow}
                            address={address}
                            setAddress={setAddress}
                        />

                        <TransactionOrderFormFields
                            transactionType={transactionType}
                            salesPrice={salesPrice}
                            setSalesPrice={setSalesPrice}
                            loanAmount={loanAmount}
                            setLoanAmount={setLoanAmount}
                            openDate={openDate}
                            setOpenDate={setOpenDate}
                            closeDate={closeDate}
                            setCloseDate={setCloseDate}
                            confirmedCloseDate={confirmedCloseDate}
                            setConfirmedCloseDate={setConfirmedCloseDate}
                            fundDate={fundDate}
                            setFundDate={setFundDate}
                        />

                        <Box sx={{ textAlign: 'right', paddingTop: (theme) => theme.spacing(3) }}>
                            <LoadingButton
                                color="primary"
                                type="submit"
                                variant="contained"
                                disabled={invalidQualiaOrderNumber || _.keys(updatePayload).length === 0}
                                sx={{
                                    minWidth: '160px',
                                }}
                                loading={updateOrderLoading}
                                disableElevation
                            >
                                Update
                            </LoadingButton>
                        </Box>
                    </Stack>
                </form>
            </Box>
        </Dialog>
    );
};

EditOrderModal.propTypes = {
    order: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default EditOrderModal;
