import { useParams } from 'react-router-dom';

import Assignment from 'components/Assignment';

function AssignmentPage({ orderId }) {
    let { assignment_id: assignmentId } = useParams();

    return <Assignment orderId={orderId} assignmentId={assignmentId} />;
}

export default AssignmentPage;
