import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { rootApi } from './root';

export const ACCESS_LOG_TAG = _.toUpper(ENTITY_TYPE.access_log);
export const USER_ACCESS_LOG_TAG = `USER_${ACCESS_LOG_TAG}`;

export const accessLogApi = rootApi.injectEndpoints({
    reducerPath: 'accessLogApi',
    endpoints: (builder) => ({
        getUserAccessLogs: builder.query({
            query: (userId) => `user/external/${userId}/access/logs`,
            providesTags: [USER_ACCESS_LOG_TAG],
        }),
    }),
    overrideExisting: false,
})


export const { useGetUserAccessLogsQuery } = accessLogApi