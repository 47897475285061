import { Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import DollarFieldMask from '../../../common/mask/DollarFieldMask';

// TODO work to integrate this into the create lien modal as well
const EditLienCoreData = ({ lien, lienCoreData, setLienCoreData }) => {
    useEffect(() => {
        if (lien) {
            setLienCoreData({
                amount: lien.amount,
                description: lien.description,
            });
        }
    }, [lien]);

    const handleChange = ({ target }) => {
        const { name, value } = target;

        if (name === 'description' && value.length === 0) {
            setLienCoreData({
                ...lienCoreData,
                [target.name]: null,
            });
            return;
        }

        if (name === 'amount' && value === '0') {
            setLienCoreData({
                ...lienCoreData,
                [target.name]: null,
            });
            return;
        }

        setLienCoreData({
            ...lienCoreData,
            [target.name]: target.value,
        });
    };

    if (!lienCoreData) {
        return null;
    }

    return (
        <form
            // onSubmit={handleSubmit}
            onError={(e) => console.log('errors', e)}
        >
            <Stack direction="column" spacing={2}>
                <TextField
                    name="amount"
                    label="Amount"
                    value={lienCoreData.amount}
                    InputProps={{
                        inputComponent: DollarFieldMask,
                    }}
                    onFocus={(event) => {
                        // NOTE: timeout to get around various browser event issues
                        const target = event.target;
                        setTimeout(() => target.select(), 0);
                    }}
                    onChange={handleChange}
                    required={false}
                />

                <TextField
                    name="description"
                    label="Description"
                    multiline
                    value={lienCoreData.description || ''}
                    onChange={handleChange}
                    required={false}
                />
            </Stack>
        </form>
    );
};

EditLienCoreData.propTypes = {
    lien: PropTypes.object.isRequired,
    lienCoreData: PropTypes.object, // not required as it can be null
    setLienCoreData: PropTypes.func.isRequired,
};

export default EditLienCoreData;
