import { ENTITY_TYPE, PARCEL_TYPE } from 'helpers/constants';
import _ from 'lodash';
import { rootApi } from './root';

export const ASSIGNMENT_TAG = _.toUpper(PARCEL_TYPE.assignment);
export const ASSIGNMENT_CONTRACT_TAG = _.toUpper(PARCEL_TYPE.assignment_contract);
export const ASSINGMENT_MEMBER_TAG = _.toUpper(ENTITY_TYPE.assignment_member);

export const assignmentApi = rootApi.injectEndpoints({
    reducerPath: 'assignmentApi',
    endpoints: (builder) => ({
        getOrderAssignments: builder.query({
            query: (orderId) => `order/${orderId}/assignments`,
            providesTags: [ASSIGNMENT_TAG, ASSIGNMENT_CONTRACT_TAG],
        }),
        createAssignment: builder.mutation({
            query: ({orderId, assignmentData}) => ({
                url: `order/${orderId}/assignments`,
                method: 'POST',
                body: assignmentData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [ASSIGNMENT_TAG, ASSIGNMENT_CONTRACT_TAG],
        }),
        updateAssignment: builder.mutation({
            query: ({orderId, assignmentId, assignmentData}) => ({
                url: `order/${orderId}/assignment/${assignmentId}`,
                method: 'PUT',
                body: assignmentData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [ASSIGNMENT_TAG, ASSIGNMENT_CONTRACT_TAG],
        }),
        getAssignmentMembers: builder.query({
            query: ({orderId, assignmentId}) => `order/${orderId}/assignment/${assignmentId}/members`,
            providesTags: [ASSINGMENT_MEMBER_TAG],
        }),
        createAssignmentMember: builder.mutation({
            query: ({orderId, assignmentId, assignmentMemberData}) => ({
                url: `order/${orderId}/assignment/${assignmentId}/members`,
                method: 'POST',
                body: assignmentMemberData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [ASSINGMENT_MEMBER_TAG],
        }),
        updateAssignmentMember: builder.mutation({
            query: ({orderId, assignmentId, assignmentMemberId, assignmentMemberData}) => ({
                url: `order/${orderId}/assignment/${assignmentId}/member/${assignmentMemberId}`,
                method: 'PUT',
                body: assignmentMemberData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [ASSINGMENT_MEMBER_TAG],
        }),
        deleteAssignmentMember: builder.mutation({
            query: ({orderId, assignmentId, assignmentMemberId}) => ({
                url: `order/${orderId}/assignment/${assignmentId}/member/${assignmentMemberId}`,
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [ASSINGMENT_MEMBER_TAG],
        }),
    }),
    overrideExisting: false,
});


export const { 
    useGetOrderAssignmentsQuery, 
    useCreateAssignmentMutation, 
    useUpdateAssignmentMutation, 
    useGetAssignmentMembersQuery,
    useCreateAssignmentMemberMutation,
    useUpdateAssignmentMemberMutation,
    useDeleteAssignmentMemberMutation
} = assignmentApi