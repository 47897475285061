import HomeIcon from '@mui/icons-material/Home';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';

const OrderAvatar = ({ transactionType, avatarProps = {} }) => {
    const { sx = {}, ...rest } = avatarProps;

    return (
        <Avatar
            {...avatarProps}
            sx={{
                backgroundColor: (theme) => theme.palette[transactionType].light,
                color: (theme) => theme.palette[transactionType].dark,
                ...sx,
            }}
            {...rest}
        >
            <HomeIcon />
        </Avatar>
    );
};

OrderAvatar.propTypes = {
    transactionType: PropTypes.string.isRequired,
    avatarProps: PropTypes.object,
};

export default OrderAvatar;
