import { Avatar, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useGetOrderMemberQuery } from 'api/order';
import { getPartyColor } from 'helpers/utils';

const OrderMemberColumn = ({ orderId, memberId }) => {
    const { data: orderMember, isError: orderMemberError, isLoading: orderMemberLoading } = useGetOrderMemberQuery({
        orderId,
        memberId,
    });

    if (!orderMember) {
        return false;
    }

    const name = _.get(orderMember, 'member.name', 'Unknown');
    const role = orderMember.role;
    const initials = name
        .split(' ')
        .map((n) => n[0])
        .join('');
    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={2} alignItems="center">
                {false && (
                    <Avatar
                        key={orderMember.id}
                        sx={{
                            width: '36px',
                            height: '36px',
                            fontSize: '16px',
                            bgcolor: getPartyColor(orderMember.party),
                        }}
                    >
                        {initials}
                    </Avatar>
                )}

                <Stack spacing={0}>
                    <Typography variant="body1">{name}</Typography>
                    <Typography variant="body2" color="text.secondary">
                        {_.startCase(role)}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

OrderMemberColumn.propTypes = {
    orderId: PropTypes.string.isRequired,
    memberId: PropTypes.string.isRequired,
};

export default OrderMemberColumn;
