import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import { useGetOrdersQuery } from 'api/order';
import CreateOrderDialog from 'components/CreateOrder/CreateOrderDialog';
import { ORDER_FILTER_TYPE, ORDER_STATUS } from 'helpers/constants';
import { filterOrders } from 'helpers/utils';
import OrderListFilters from './components/OrderListFilters';
import OrderListGrid from './components/OrderListGrid';

const DEFAULT_FILTER_STATE = {
    [ORDER_FILTER_TYPE.search]: '',
    [ORDER_FILTER_TYPE.transactionType]: '',
    [ORDER_FILTER_TYPE.orderType]: [],
    [ORDER_FILTER_TYPE.workflowType]: '',
    [ORDER_FILTER_TYPE.orderStatus]: [ORDER_STATUS.open],
    [ORDER_FILTER_TYPE.closeDateStatus]: '',
    [ORDER_FILTER_TYPE.closeDateRange]: [null, null],
};

function OrderList() {
    const { data: orders, isError: orderError, isLoading: orderLoading, refetch: refetchOrders } = useGetOrdersQuery();

    const [openModal, setOpenModal] = useState(false);

    const [filters, setFilters] = useState({
        ...DEFAULT_FILTER_STATE,
    });

    const filteredOrders = useMemo(() => filterOrders(orders, filters), [orders, filters]);

    const resetFilters = () => {
        setFilters({
            ...DEFAULT_FILTER_STATE,
        });
    };

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
                padding: (theme) => theme.spacing(3),
            }}
        >
            <Stack spacing={3}>
                <Stack direction="row" alignItems="center" spacing={3} justifyContent="space-between">
                    <Typography variant="h1">Orders</Typography>

                    <Fab color="primary" aria-label="add order" onClick={() => setOpenModal(true)} size="small">
                        <AddIcon />
                    </Fab>
                </Stack>

                <OrderListFilters
                    defaultFilters={DEFAULT_FILTER_STATE}
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                />
            </Stack>

            <Box>
                <Box>
                    <OrderListGrid orderList={filteredOrders} loading={orderLoading} refetch={refetchOrders} />
                </Box>
            </Box>

            <CreateOrderDialog open={!!openModal} handleClose={setOpenModal} />
        </Stack>
    );
}

export default OrderList;
