import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

const HEARTBEAT_INTERVAL = 45000; // 45 seconds
let heartbeatIntervalId;

function useSocket() {
    const [messages, setMessages] = useState([]);

    const { lastJsonMessage, sendMessage, readyState } = useWebSocket(window.ENV.socket_url, {
        share: true,
        onOpen: (event) => handleOpen(event),
        onClose: (event) => handleClose(event),
        // onError: (event) => console.log('socket error', event),
        // onMessage: (event) => console.log('socket message', event),
        shouldReconnect: (closeEvent) => {
            return true;
        },
    });

    const handleOpen = (event) => {
        setupHeartbeat();
    };

    const handleClose = (event) => {
        cleanupHeartbeat();
    };

    const setupHeartbeat = () => {
        // NOTE: The built-in heartbeat feature of react-use-websocket is not working as expected
        // so we implement our own heartbeat mechanism here
        if (!heartbeatIntervalId) {
            heartbeatIntervalId = setInterval(() => {
                sendMessage('ping');
            }, HEARTBEAT_INTERVAL);
        }

        return heartbeatIntervalId;
    };

    const cleanupHeartbeat = () => {
        if (heartbeatIntervalId) {
            clearInterval(heartbeatIntervalId);
            heartbeatIntervalId = null;
        }
    };

    useEffect(() => {
        // Start heartbeat interval
        setupHeartbeat();

        // Clean up interval on component unmount
        return () => cleanupHeartbeat();
    }, [sendMessage]);

    useEffect(() => {
        if (lastJsonMessage !== null) {
            setMessages((prev) => prev.concat(lastJsonMessage));
        }
    }, [lastJsonMessage]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    return { messages, lastMessage: lastJsonMessage, connectionStatus };
}

export default useSocket;
