import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, Card, CardActionArea, Stack, Typography } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import clsx from 'clsx';
import { format, isBefore, isSameDay, isWeekend, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ORDER_FILTER_TYPE } from '../../helpers/constants';

const ORDER_FILTER_KEY_MAP = {
    [ORDER_FILTER_TYPE.closeDateRange]: 'close_date',
    [ORDER_FILTER_TYPE.disbursementDateRange]: 'disbursement_date',
};

function WeekTimelineGridItemContent({ dateOrderList, collapse, hide, DateOrderComponent }) {
    const [currentContentIndex, setCurrentContentIndex] = useState(false);

    const handleChange = (event, newValue) => {
        setCurrentContentIndex(newValue);
    };

    if (dateOrderList.length === 0) {
        return null;
    }

    return (
        <Tabs
            value={currentContentIndex}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            visibleScrollbar
            className={clsx({
                hide: hide || collapse,
            })}
            sx={{
                paddingTop: (theme) => theme.spacing(3),
                paddingBottom: (theme) => theme.spacing(6),
                [`& .${tabsClasses.scrollButtons}`]: {
                    width: (theme) => theme.spacing(10),
                    '& .MuiSvgIcon-root': {
                        fontSize: '2em',
                    },
                },
                '& .MuiTabs-indicator': {
                    display: 'none',
                },
                '& .MuiTabs-flexContainer': {
                    margin: (theme) => theme.spacing(0, 0, 3, 0),
                },
                '&.hide': {
                    display: 'none',
                },
                '& .MuiTabs-flexContainer > *': {
                    flex: '1 0 auto',
                    margin: '2px 24px 2px 2px',
                    '&:last-child': {
                        marginRight: '2px',
                    },
                },
            }}
        >
            {_.map(dateOrderList, (dateOrder, index) => {
                return <DateOrderComponent key={dateOrder.id} value={index} order={dateOrder} />;
            })}
        </Tabs>
    );
}

function WeekTimelineGridItem({
    date,
    weekOrderList,
    loading,
    DateOrderComponent,
    dateRangeFilterType = ORDER_FILTER_TYPE.closeDateRange,
}) {
    const [dateOrderList, setDateOrderList] = useState([]);
    const [collapse, setCollapse] = useState(false);
    const [hide, setHide] = useState(false);

    useEffect(() => {
        // auto-collapse component if date is in the past or if there are no orders for the day
        const now = new Date();
        const shouldCollapse = (isBefore(date, now) && !isSameDay(date, now)) || dateOrderList.length === 0;
        setCollapse(shouldCollapse);
    }, [dateOrderList, date]);

    useEffect(() => {
        // auto-hide component if date is on the weekend and has no orders for the day
        const shouldHide = isWeekend(date) && dateOrderList.length === 0;
        setHide(shouldHide);
    }, [dateOrderList, date]);

    useEffect(() => {
        // Find all orders associated with the provided date
        if (date && weekOrderList) {
            const newDateOrderList = _.filter(weekOrderList, (dateOrder) => {
                const orderFilterKey = ORDER_FILTER_KEY_MAP[dateRangeFilterType];
                return isSameDay(date, parseISO(dateOrder[orderFilterKey]));
            });
            setDateOrderList(newDateOrderList);
        }
    }, [date, weekOrderList, dateRangeFilterType]);

    const toggleCollapse = () => {
        setCollapse(!collapse);
    };

    const handleActionAreaClick = (event) => {
        event.preventDefault();
        toggleCollapse();
    };

    return (
        <Card
            elevation={0}
            className={clsx({
                hide,
            })}
            sx={{
                background: '#f5f5f5',
                borderRadius: (theme) => theme.spacing(1),
                '&.hide': {
                    display: 'none',
                },
            }}
        >
            <CardActionArea onClick={handleActionAreaClick} disabled={dateOrderList.length === 0}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    sx={{
                        padding: (theme) => theme.spacing(3),
                    }}
                >
                    <Avatar
                        sx={{
                            backgroundColor: (theme) =>
                                dateOrderList.length > 0
                                    ? theme.palette.primary.main
                                    : theme.palette.background.default,
                            color: (theme) =>
                                dateOrderList.length > 0
                                    ? theme.palette.primary.contrastText
                                    : theme.palette.text.primary,
                            height: (theme) => theme.spacing(4),
                            width: (theme) => theme.spacing(4),
                            fontSize: (theme) => theme.spacing(2),
                            fontWeight: 400,
                        }}
                    >
                        {dateOrderList.length}
                    </Avatar>

                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
                            {date && format(date, 'EEE')}
                        </Typography>

                        <Typography variant="h6" color="text.secondary">
                            {date && format(date, 'M/d')}
                        </Typography>
                    </Stack>

                    <ExpandMoreIcon
                        className={clsx({
                            hide: dateOrderList.length === 0,
                        })}
                        sx={{
                            transform: collapse ? 'rotate(0deg)' : 'rotate(180deg)',
                            marginLeft: 'auto',
                            transition: (theme) =>
                                theme.transitions.create('transform', {
                                    duration: theme.transitions.duration.shortest,
                                }),
                            '&.hide': {
                                display: 'none',
                            },
                        }}
                    />
                </Stack>
            </CardActionArea>

            <WeekTimelineGridItemContent
                {...{
                    dateOrderList,
                    collapse,
                    hide,
                    DateOrderComponent,
                }}
            />
        </Card>
    );
}

WeekTimelineGridItem.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    weekOrderList: PropTypes.array.isRequired,
    DateOrderComponent: PropTypes.elementType.isRequired,
    loading: PropTypes.bool,
    dateRangeFilterType: PropTypes.oneOf([ORDER_FILTER_TYPE.closeDateRange, ORDER_FILTER_TYPE.disbursementDateRange]),
};

export default WeekTimelineGridItem;
