import PropTypes from 'prop-types';
import React from 'react';

import { ENTITY_TYPE } from '../../helpers/constants';
import LegalEntity from '../LegalEntity';
import User from '../User';

function Contact({ contactType, contactId, edit }) {
    // view contact - determine if contact is user or legal entity and render appropriate component
    if (contactType === ENTITY_TYPE.user) {
        return <User userId={contactId} edit={edit} />;
    }

    if (contactType === ENTITY_TYPE.legal_entity) {
        return <LegalEntity legalEntityId={contactId} edit={edit} />;
    }

    // TODO handle any situation where contactType isn't user or legal entity
    return null;
}

Contact.propTypes = {
    contactType: PropTypes.oneOf([ENTITY_TYPE.user, ENTITY_TYPE.legal_entity]).isRequired,
    contactId: PropTypes.string.isRequired,
    edit: PropTypes.bool,
};

export default Contact;
