import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputAdornment, MenuItem, Stack, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';

import FilterTextField from 'components/common/styled/FilterTextField';
import { ACCESS_DOMAIN_TYPE, ACCESS_EVENT_TYPE, ACCESS_LOG_FILTER_TYPE } from 'helpers/constants';
// import useFilterQueryParams from '../../../common/hooks/useFilterQueryParams';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
};

export const INITIAL_ACCESS_LOG_FILTERS = {
    [ACCESS_LOG_FILTER_TYPE.search]: '',
    [ACCESS_LOG_FILTER_TYPE.userId]: '',
    [ACCESS_LOG_FILTER_TYPE.domainType]: '',
    [ACCESS_LOG_FILTER_TYPE.eventType]: '',
};

function AccessLogListFilters({ filters, setFilters, resetFilters, defaultFilters, hideFilters = [] }) {
    const searchInputRef = useRef();

    // NOTE - re-enable if we want to sync filters with query params
    // const [syncFilterMap, searchParams] = useFilterQueryParams(filters, defaultFilters);

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string may not be valid for some filters
        };

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const changeHandler = (event) => {
        handleFilter(event);
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 300), []);

    const setSearchInput = (value) => {
        searchInputRef.current.value = value;
    };

    const resetAllFilters = () => {
        // Reset local uncontrolled search input
        setSearchInput('');

        // Reset parent filters
        resetFilters();
    };

    useEffect(() => {
        // Stop the invocation of the debounced function after unmounting
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    // NOTE - re-enable if we want to sync filters with query params
    // useEffect(() => {
    //     if (_.keys(syncFilterMap).length > 0) {
    //         const updatedFilterMap = {
    //             ...defaultFilters,
    //             ...syncFilterMap,
    //         };

    //         // Update local filters
    //         setFilters(updatedFilterMap);

    //         // Sync uncontrolled search input ref
    //         setSearchInput(_.get(updatedFilterMap, ACCESS_LOG_FILTER_TYPE.search, ''));
    //     }
    // }, [syncFilterMap]);

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <FilterTextField
                name={ACCESS_LOG_FILTER_TYPE.search}
                label=""
                type="search"
                variant="outlined"
                placeholder="Search access logs ..."
                onChange={debouncedChangeHandler}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                inputRef={searchInputRef}
                size="small"
                sx={{
                    flexGrow: 1,
                    minWidth: '320px',
                    maxWidth: '480px',
                    ...(_.includes(hideFilters, ACCESS_LOG_FILTER_TYPE.search) && { display: 'none' }),
                }}
            />

            <FilterTextField
                name={ACCESS_LOG_FILTER_TYPE.domainType}
                label="Domain Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters[ACCESS_LOG_FILTER_TYPE.domainType]}
                size="small"
                sx={{
                    minWidth: '160px',
                    ...(_.includes(hideFilters, ACCESS_LOG_FILTER_TYPE.domainType) && { display: 'none' }),
                }}
                select
                SelectProps={{
                    MenuProps,
                }}
            >
                <MenuItem value="">All</MenuItem>
                {_.map(ACCESS_DOMAIN_TYPE, (domainTypeValue) => {
                    return (
                        <MenuItem key={domainTypeValue} value={domainTypeValue}>
                            {_.startCase(domainTypeValue)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <FilterTextField
                name={ACCESS_LOG_FILTER_TYPE.eventType}
                label="Event Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters[ACCESS_LOG_FILTER_TYPE.eventType]}
                size="small"
                sx={{
                    minWidth: '160px',
                    ...(_.includes(hideFilters, ACCESS_LOG_FILTER_TYPE.eventType) && { display: 'none' }),
                }}
                select
                SelectProps={{
                    MenuProps,
                }}
            >
                <MenuItem value="">All</MenuItem>
                {_.map(ACCESS_EVENT_TYPE, (accessEventTypeValue) => {
                    return (
                        <MenuItem key={accessEventTypeValue} value={accessEventTypeValue}>
                            {_.startCase(accessEventTypeValue)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <Box flexGrow="1" />

            <Tooltip title="Reset Filters" placement="bottom" enterDelay={300}>
                <IconButton
                    onClick={() => {
                        resetAllFilters();
                    }}
                >
                    <RestartAltIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

AccessLogListFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    defaultFilters: PropTypes.object.isRequired,
    hideFilters: PropTypes.arrayOf(PropTypes.string),
};

export default AccessLogListFilters;
