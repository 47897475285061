import { Avatar, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useGetOrdersQuery } from '../../api/order';
import { DATE_RANGE_MAP, ORDER_FILTER_TYPE } from '../../helpers/constants';
import { calculateDateRangePreset, filterOrders } from '../../helpers/utils';
import WeekTimelineFilters from '../common/WeekTimelineFilters';
import WeekTimelineGrid from '../common/WeekTimelineGrid';
import FundingListGridItemOrder from './components/FundingListGridItemOrder';

const DEFAULT_FILTER_STATE = {
    [ORDER_FILTER_TYPE.transactionType]: '',
    [ORDER_FILTER_TYPE.orderType]: [],
    [ORDER_FILTER_TYPE.disbursementDateRange]: [null, null],
};

function generateDefaultFilterState() {
    return {
        ...DEFAULT_FILTER_STATE,
        [ORDER_FILTER_TYPE.disbursementDateRange]: calculateDateRangePreset(DATE_RANGE_MAP.this_week),
    };
}

function FundingList() {
    const { data: orders, isError: orderError, isLoading: orderLoading } = useGetOrdersQuery();

    const [filters, setFilters] = useState(
        generateDefaultFilterState({
            ...generateDefaultFilterState(),
        })
    );

    const filteredOrders = useMemo(() => filterOrders(orders, filters), [orders, filters]);

    const resetFilters = () => {
        setFilters(generateDefaultFilterState());
    };

    // TODO handle socket change

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
                padding: (theme) => theme.spacing(3),
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h1">Fundings</Typography>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar
                        sx={{
                            backgroundColor: (theme) =>
                                filteredOrders.length > 0
                                    ? theme.palette.primary.main
                                    : theme.palette.background.default,
                            color: (theme) =>
                                filteredOrders.length > 0
                                    ? theme.palette.primary.contrastText
                                    : theme.palette.text.primary,
                            height: (theme) => theme.spacing(4),
                            width: (theme) => theme.spacing(4),
                            fontSize: (theme) => theme.spacing(2),
                            fontWeight: 400,
                        }}
                    >
                        {filteredOrders.length}
                    </Avatar>
                    <Typography variant="h6" fontWeight="400">
                        This Week
                    </Typography>
                </Stack>
            </Stack>

            <WeekTimelineFilters
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                defaultFilters={generateDefaultFilterState()}
                dateRangeFilterType={ORDER_FILTER_TYPE.disbursementDateRange}
            />

            <WeekTimelineGrid
                weekRange={filters[ORDER_FILTER_TYPE.disbursementDateRange]}
                weekOrderList={filteredOrders}
                loading={orderLoading}
                dateRangeFilterType={ORDER_FILTER_TYPE.disbursementDateRange}
                DateOrderComponent={FundingListGridItemOrder}
            />
        </Stack>
    );
}

export default FundingList;
