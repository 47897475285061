import { Dialog, Stack, Typography } from '@mui/material';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { generateSeedValue } from 'components/Action/utils';
import customFields from 'components/common/form/rjsf/fields';
import customWidgets from 'components/common/form/rjsf/widgets';
import useActionData from 'components/common/hooks/useActionData';
import { LoadingButton } from 'components/common/styled';
import { ACTION_STATUS } from 'helpers/constants';
import ActionOrderMember from './ActionOrderMember';

const UI_SCHEMA_SEED_KEY = 'ui:seed';

const ActionModal = ({ action, orderId, isOpen, isSubmitting, handleClose, handleUpdateAction }) => {
    const { order, parcel, parentParcel, orderMember, orderMembers, user } = useActionData(orderId, action);
    const [actionAdditionalData, setActionAdditionalData] = useState({});

    useEffect(() => {
        if (action && isOpen) {
            const sourceEntityMap = {
                parcel,
                order,
                parentParcel,
                orderMember,
                user,
            };

            const newActionCopy = JSON.parse(JSON.stringify(action));

            const formData = newActionCopy.additional_data;
            const uiSchema = action.additional_data_schema.ui_schema;

            _.forEach(uiSchema, (uiSchemaField, uiSchemaKey) => {
                if (_.has(uiSchemaField, UI_SCHEMA_SEED_KEY)) {
                    const seedValue = generateSeedValue(uiSchemaField[UI_SCHEMA_SEED_KEY], sourceEntityMap);

                    if (seedValue !== null) {
                        formData[uiSchemaKey] = seedValue;
                    }
                }
            });

            setActionAdditionalData(formData);
        }
    }, [isOpen, action, order, parcel, parentParcel, orderMember, user]);

    const handleChange = (event) => {
        const { formData } = event;
        setActionAdditionalData(_.isEmpty(formData) ? null : formData);
    };

    const handleSubmit = (event) => {
        const { formData } = event;

        const updateActionData = {
            status: ACTION_STATUS.complete,
        };

        if (!_.isEmpty(formData)) {
            // Only add formData if it is not empty
            // This allows us to support 'mark' as done actions with no form fields in the modal
            // And allows parent component to decide whether or not to launch modal in this scenario
            updateActionData['additional_data'] = formData;
        }

        handleUpdateAction(updateActionData);
    };

    // Determine if action is a simple 'mark as done' action or if it requires additional data
    const hasAdditionalDataRequirement = !_.isEmpty(action.additional_data_schema.json_schema);

    return (
        <Dialog open={isOpen} onClose={() => handleClose()} maxWidth="sm" fullWidth>
            <Stack
                spacing={3}
                sx={{
                    padding: (theme) => theme.spacing(8),

                    '& .form-group-label': {
                        fontSize: '12px',
                        fontWeight: '600',
                        textTransform: 'uppercase',
                    },
                }}
            >
                <Stack spacing={1}>
                    <Typography variant="h5">{action.friendly_name}</Typography>
                    <Typography variant="body1" color="text.secondary">
                        {action.description}
                    </Typography>
                </Stack>

                <Form
                    schema={action.additional_data_schema.json_schema}
                    uiSchema={action.additional_data_schema.ui_schema}
                    validator={validator}
                    fields={customFields}
                    widgets={customWidgets}
                    formData={actionAdditionalData}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    onError={(e) => console.log('errors', e)}
                    omitExtraData={true}
                    // liveOmit={true}
                    formContext={{
                        order,
                        parcel,
                        parentParcel,
                        orderMember,
                        orderMembers,
                        user,
                        rootFormData: actionAdditionalData,
                    }}
                >
                    <Stack
                        direction="row-reverse"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={3}
                        sx={{
                            marginTop: (theme) => theme.spacing(6),
                        }}
                    >
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            type="submit"
                            loading={isSubmitting}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                        >
                            {hasAdditionalDataRequirement ? 'Submit' : 'Complete'}
                        </LoadingButton>

                        {orderMember && user && <ActionOrderMember orderMember={orderMember} user={user} />}
                    </Stack>
                </Form>
            </Stack>
        </Dialog>
    );
};

ActionModal.propTypes = {
    action: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleUpdateAction: PropTypes.func.isRequired,
};

export default ActionModal;
