import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetLegalEntityQuery } from '../../api/legalEntity';
import LegalEntityBreadcrumbs from './components/LegalEntityBreadcrumbs';
import LegalEntityDetails from './components/LegalEntityDetails';
import LegalEntityHeader from './components/LegalEntityHeader';

function LegalEntity({ legalEntityId, edit }) {
    const navigate = useNavigate();
    const { data: legalEntity, isError: legalEntityError, isLoading: legalEntityLoading } = useGetLegalEntityQuery(
        legalEntityId
    );
    // TODO figure out better way to handle legalEntity error / not found as currently useGetLegalEntityQuery will not return error/loading state

    const handleNavigateToView = () => {
        navigate(`/contact/legal_entity/${legalEntityId}`);
    };

    const handleNavigateToEdit = () => {
        navigate(`/contact/legal_entity/${legalEntityId}/edit`);
    };

    const handleToggleEdit = () => {
        if (edit) {
            handleNavigateToView();
        } else {
            handleNavigateToEdit();
        }
    };

    if (!legalEntity || legalEntityLoading) {
        return (
            <Stack
                spacing={3}
                sx={{
                    height: '100%',
                    padding: (theme) => theme.spacing(3),
                }}
            >
                <Typography>Loading ...</Typography>
            </Stack>
        );
    }

    if (legalEntityError) {
        return (
            <Stack
                spacing={3}
                sx={{
                    height: '100%',
                    padding: (theme) => theme.spacing(3),
                }}
            >
                <Typography>Legal entity ({legalEntityId}) not found</Typography>
            </Stack>
        );
    }

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
                padding: (theme) => theme.spacing(3),
            }}
        >
            <LegalEntityBreadcrumbs legalEntity={legalEntity} />

            <LegalEntityHeader legalEntity={legalEntity} />

            <LegalEntityDetails legalEntityId={legalEntityId} />
        </Stack>
    );
}

LegalEntity.propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    edit: PropTypes.bool,
};

export default LegalEntity;
