import GppBadIcon from '@mui/icons-material/GppBad';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Link as MUILink, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { DataGrid } from 'components/common/styled';
import { ENTITY_TYPE } from 'helpers/constants';
import { formatPhone } from 'helpers/utils';

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        width: 240,
        renderCell: (params) => (
            <MUILink
                component={Link}
                to={`/contact/${params.row.entity_type}/${params.row.id}`}
                underline="hover"
                sx={{
                    color: (theme) => theme.palette.text.link,
                }}
            >
                {params.value}
            </MUILink>
        ),
    },
    {
        field: 'entity_type',
        headerName: 'Type',
        width: 150,
        valueFormatter: (params) => {
            return _.startCase(params.value);
        },
    },
    {
        field: 'contact.email',
        headerName: 'Email',
        width: 240,
        valueGetter: (params) => {
            return _.get(params, 'row.contact.email');
        },
        valueFormatter: (params) => {
            return params.value ? params.value : '—';
        },
    },
    {
        field: 'contact.phone',
        headerName: 'Phone',
        width: 150,
        valueGetter: (params) => {
            return _.get(params, 'row.contact.phone');
        },
        valueFormatter: (params) => {
            return params.value ? formatPhone(params.value) : '—';
        },
    },
    {
        field: 'type',
        headerName: 'Legal Type',
        width: 150,
        valueFormatter: (params) => {
            return params.value ? _.startCase(params.value) : '—';
        },
    },
    {
        field: 'commercial_type',
        headerName: 'Commercial Type',
        width: 150,
        valueFormatter: (params) => {
            return params.value ? _.startCase(params.value) : '—';
        },
    },
    {
        field: 'orderMembershipCount',
        headerName: 'Orders',
        valueGetter: (params) => {
            return params.row.orderMembership ? params.row.orderMembership.length : 0;
        },
    },
    {
        field: 'account',
        headerName: '',
        flex: 1,
        align: 'right',
        renderCell: (params) => {
            if (params.row.entity_type !== ENTITY_TYPE.user) {
                return null;
            }

            const hasAccount = !!_.get(params, 'row.external_user_id');
            const isBlocked = _.get(params, 'row.blocked', false);

            if (!hasAccount) {
                return (
                    <Tooltip title="No Account" placement="left" enterDelay={300}>
                        <GppBadIcon
                            color="warning"
                            sx={{
                                width: '22px',
                                height: '22px',
                                color: 'offWhite',
                            }}
                        />
                    </Tooltip>
                );
            }

            if (isBlocked) {
                return (
                    <Tooltip title="Blocked Account" placement="left" enterDelay={300}>
                        <RemoveModeratorIcon color="error" fontSize="small" />
                    </Tooltip>
                );
            }

            return (
                <Tooltip title="Active Account" placement="left" enterDelay={300}>
                    <VerifiedUserIcon color="primary" fontSize="small" />
                </Tooltip>
            );
        },
    },
];

function ContactListGrid({ contactList, refetch, loading }) {
    const [pageSize, setPageSize] = useState(10);

    return (
        <DataGrid
            rows={contactList}
            columns={columns}
            pageSize={pageSize}
            refetch={refetch}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            pagination
            loading={loading}
            density="comfortable"
            getRowId={(row) => row.id}
            disableColumnMenu
            disableColumnFilter
            disableSelectionOnClick
            autoHeight
        />
    );
}

ContactListGrid.propTypes = {
    contactList: PropTypes.array.isRequired,
    refetch: PropTypes.func,
    loading: PropTypes.bool,
};

export default ContactListGrid;
