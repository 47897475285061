import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import { useEffect, useState } from 'react';

/*
    This plugin is used to toggle the editor between editable and non-editable states
    and to reset the editor state when the editable prop changes (cancel) or when the updatedDate prop changes (save)
*/
function EditTogglePlugin({ initialContent, editable, updatedDate = null }) {
    const [editor] = useLexicalComposerContext();
    const [prevUpdatedDate, setPrevUpdatedDate] = useState(updatedDate);

    const resetEditor = () => {
        if (initialContent) {
            // content exists - update editor state
            const newEditorState = editor.parseEditorState(initialContent);
            editor.setEditorState(newEditorState);
        } else {
            // content doesn't exist - clear editor state
            editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        }
    };

    if (updatedDate && prevUpdatedDate !== updatedDate) {
        resetEditor();

        setPrevUpdatedDate(updatedDate);
    }

    useEffect(() => {
        const isEditable = editor.isEditable();

        // If editor state doesn't match editable prop, update
        if (isEditable !== editable) {
            editor.setEditable(editable);

            const currentContent = JSON.stringify(editor.getEditorState());
            if (!editable && initialContent !== currentContent) {
                resetEditor();
            }
        }
    }, [editor, editable]);

    return null;
}

export default EditTogglePlugin;
