import { Stack } from '@mui/material';
import _ from 'lodash';
import { useLocation, useParams } from 'react-router-dom';
import Contact from '../../components/Contact';
import UserLogList from '../../components/UserLogList/UserLogList';

function ContactPage() {
    let { contact_type: contactType, contact_id: contactId } = useParams();
    let { pathname } = useLocation();

    const edit = _.includes(pathname, '/edit');
    const logs = _.includes(pathname, '/logs'); // TODO need to rethink this now that we have deeper routes

    if (logs) {
        return (
            <Stack style={{ height: '100%' }}>
                <UserLogList userId={contactId} />
            </Stack>
        );
    }

    return (
        <Stack style={{ height: '100%' }}>
            <Contact contactType={contactType} contactId={contactId} edit={edit} />
        </Stack>
    );
}

export default ContactPage;
