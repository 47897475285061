import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

function DocumentNameField({ documentName, setDocumentName, disabled = false }) {
    return (
        <TextField
            name="name"
            label="Document Name"
            onChange={(event) => setDocumentName(event.target.value)}
            value={documentName || ''}
            variant="outlined"
            size="small"
            sx={{
                width: '320px',
            }}
            disabled={disabled}
        />
    );
}

DocumentNameField.propTypes = {
    documentName: PropTypes.string,
    setDocumentName: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default DocumentNameField;
