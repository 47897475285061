import SendIcon from '@mui/icons-material/Send';
import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useCreateNoteMutation } from '../../../api/note';
import RichTextEditor from '../../RichTextEditor';
import { LoadingButton } from '../../common/styled';
import NoteTag from './NoteTag';

function CreateNote({ orderId, handleSuccess, seedTag = null }) {
    const [createNote, { isLoading: isCreateNoteLoading }] = useCreateNoteMutation();
    const [noteContent, setNoteContent] = useState('');
    const [noteRichTextContent, setNoteRichTextContent] = useState(null);
    const [tag, setTag] = useState(seedTag);

    const handleCreateNote = () => {
        const tagPayload = tag ? tag : {};

        const notePayload = {
            order_id: orderId,
            content: noteContent,
            rich_content: JSON.parse(noteRichTextContent),
            ...tagPayload,
        };

        return submitCreateNote(notePayload).then((responseData) => {
            if (responseData) {
                resetNoteContent();
                handleSuccess(responseData.note_id);
            }
            // TODO - Handle error
        });
    };

    const submitCreateNote = async (notePayload) => {
        const { data } = await createNote({
            orderId: orderId,
            noteData: notePayload,
        });

        if (data.note_id) {
            return data;
        }

        return false;
    };

    const resetNoteContent = () => {
        setNoteContent('');
        setNoteRichTextContent(null);
    };

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
                width: '100%',
            }}
        >
            <Typography variant="h5" fontSize={24} fontWeight={400} lineHeight="38px">
                New Note
            </Typography>

            <Stack direction="column" spacing={2}>
                <Box sx={{ flexGrow: 1 }}>
                    <RichTextEditor
                        initialContent={noteRichTextContent}
                        setRichTextContent={setNoteRichTextContent}
                        setPlainTextContent={setNoteContent}
                    />
                </Box>

                <Stack direction="row" justifyContent="space-between">
                    <NoteTag orderId={orderId} tag={tag} setTag={setTag} editable={true} />

                    <LoadingButton
                        onClick={() => handleCreateNote()}
                        endIcon={<SendIcon />}
                        loading={isCreateNoteLoading}
                        disabled={!noteContent || noteContent.length === 0}
                        loadingPosition="end"
                        variant="contained"
                        disableElevation
                        sx={{ minWidth: '160px' }}
                    >
                        Create
                    </LoadingButton>
                </Stack>
            </Stack>
        </Stack>
    );
}

CreateNote.propTypes = {
    orderId: PropTypes.string.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    seedTag: PropTypes.object,
};

export default CreateNote;
