import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useGetLegalEntityQuery } from 'api/legalEntity';

const LegalEntityMembershipListItem = ({ legalEntityMembership }) => {
    const navigate = useNavigate();

    const { data: legalEntity } = useGetLegalEntityQuery(legalEntityMembership.legal_entity_id);

    const handleNavigate = () => {
        navigate(`/contact/legal_entity/${legalEntityMembership.legal_entity_id}`);
    };

    return (
        <ListItem className="legal-entity-membership-list-item" disablePadding>
            <ListItemButton className="legal-entity-membership-list-item-button" onClick={handleNavigate}>
                <ListItemText
                    primary={legalEntity?.name}
                    secondary={`${_.startCase(legalEntityMembership.role)}${
                        legalEntityMembership.signor && ' • Signor'
                    }`}
                />
            </ListItemButton>
        </ListItem>
    );
};

LegalEntityMembershipListItem.propTypes = {
    legalEntityMembership: PropTypes.object.isRequired,
};

export default LegalEntityMembershipListItem;
