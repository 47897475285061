
import { Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { addBusinessDays, differenceInBusinessDays, format, isWeekend, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


function FundDateField(props) {
    const { closeDate, fundDate, setFundDate, disableFields = false, editMode = false, defaultFundDatePeriod = '1' } = props;

    const [relativeFundDatePeriod, setRelativeFundDatePeriod] = useState(defaultFundDatePeriod);

    useEffect(() => {
        if (closeDate && !editMode) {
            // Re-calculate fund date when close date changes when not in edit mode
            const newFundDate = calculateFundDate(closeDate, relativeFundDatePeriod);
            const newFundDateString = format(newFundDate, 'yyyy-MM-dd');
            
            if (newFundDateString !== fundDate) {
                setFundDate(newFundDateString);
            }
        } else if (closeDate && editMode) {
            // Re-calculate 'relative' fund date period in days when close date changes in edit mode
            const dayDiff = calculateRelativePeriod(closeDate, fundDate);
            setRelativeFundDatePeriod(dayDiff.toString());
        }
    }, [closeDate]);


    const calculateFundDate = (startDate, relativePeriod) => {
        return addBusinessDays(parseISO(startDate), parseInt(relativePeriod));
    }


    const calculateRelativePeriod = (startDate, endDate) => {
        return differenceInBusinessDays(parseISO(endDate), parseISO(startDate));
    }

    const handleFundDateChange = (newDate) => {
        // Handle direct changes to fund date input field
        
        if (closeDate) {
            // Calculate 'relative' fund date period in days when fund date changes
            const dayDiff = calculateRelativePeriod(closeDate, newDate);
            setRelativeFundDatePeriod(dayDiff.toString());
        }

        // Update fund date
        setFundDate(newDate);
    }


    const handleRelativePeriodChange = (newPeriod) => {
        // Handle direct changes to relative period input field
        
         // Recalculate fund date
        if (closeDate) {
            const newFundDate = calculateFundDate(closeDate, newPeriod);
            const newFundDateString = format(newFundDate, 'yyyy-MM-dd');
            setFundDate(newFundDateString);
        }

        // Update relative period
        setRelativeFundDatePeriod(newPeriod);
    }

    return (
        <Stack direction="row" alignItems="center" spacing={2}>

            <DatePicker
                autoOk
                label="Fund Date"
                openTo="day"
                inputFormat="MM/dd/yyyy"
                value={fundDate ? parseISO(fundDate) : null}
                inputVariant="outlined"
                onChange={(date) => {
                    // NOTE: not strict comparison due to issues with date error response object
                    if (date && date != 'Invalid Date') {
                        handleFundDateChange(format(date, 'yyyy-MM-dd'));
                    } else {
                        handleFundDateChange(null);
                    }
                }}
                disabled={disableFields}
                renderInput={(params) => <TextField size="small" {...params} />}
                minDate={parseISO(closeDate)}
                shouldDisableDate={(date) => {
                    return isWeekend(date);
                }}
                disablePast={!editMode}
                clearable
                required
            />
            
            <TextField
                label=""
                value={relativeFundDatePeriod}
                onChange={({ target }) => handleRelativePeriodChange(target.value)}
                variant='outlined'
                type='number'
                size="small"
                InputProps={{
                    endAdornment: (<span>days</span>),
                    inputProps: {min: "0", step: "1"}
                }}
                style={{ maxWidth: "96px" }}
                disabled={disableFields}
                required
            />

            <div />

        </Stack>
    );
}

FundDateField.propTypes = {
    closeDate: PropTypes.string, 
    fundDate: PropTypes.string, 
    setFundDate: PropTypes.func.isRequired,
    disableFields: PropTypes.bool,
    defaultFundDatePeriod: PropTypes.number
}

export default FundDateField;