import {
    Checkbox,
    FormControlLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import {
    generateOrderMemberIdMap,
    generateOrderMemberRoleMap,
    getOrderMemberName,
} from 'components/Order/Members/utils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
};

function MemberSelectField({
    selectedOrderMembers,
    setSelectedOrderMembers,
    orderMembers,
    label = 'Order Members',
    showLabel = true,
    multiple = true,
    TextFieldProps = {},
}) {
    // TODO allow for null/empty menu option to be passed in

    const orderMemberRoleMap = useMemo(() => generateOrderMemberRoleMap(orderMembers), [orderMembers]);
    const orderMemberIdMap = useMemo(() => generateOrderMemberIdMap(orderMembers), [orderMembers]);

    const handleSelectedMembersChange = (selectedMembers) => {
        if (multiple) {
            // If multiple, selectedMembers is an array
            setSelectedOrderMembers(selectedMembers);
        } else {
            // If single, selectedMembers is a string so we convert to single item array
            setSelectedOrderMembers([selectedMembers]);
        }
    };

    const handleSelectRole = (event, roleMemberIds) => {
        const checked = event.target.checked;
        if (checked) {
            selectAllRoleMembers(roleMemberIds);
        } else {
            removeAllRoleMembers(roleMemberIds);
        }
    };

    const selectAllRoleMembers = (roleMemberIds) => {
        setSelectedOrderMembers(_.union(selectedOrderMembers, roleMemberIds));
    };

    const removeAllRoleMembers = (roleMemberIds) => {
        setSelectedOrderMembers(_.difference(selectedOrderMembers, roleMemberIds));
    };

    if (!orderMembers || orderMembers.length === 0) {
        return null;
    }

    return (
        <TextField
            name="orderMembers"
            label={showLabel ? label : ''}
            onChange={(event) => handleSelectedMembersChange(event.target.value)}
            value={selectedOrderMembers}
            variant="outlined"
            size="small"
            select
            SelectProps={{
                renderValue: (selected) => {
                    return _.map(selected, (selectedMemberId) =>
                        getOrderMemberName(orderMemberIdMap[selectedMemberId])
                    ).join(', ');
                },
                multiple,
                MenuProps,
            }}
            {...TextFieldProps}
            sx={{
                minWidth: '200px',
                ..._.get(TextFieldProps, 'sx', {}),
            }}
        >
            {_.map(orderMemberRoleMap, (roleMemberList, role) => {
                const roleMemberIds = _.map(roleMemberList, 'id');
                const nonSelectedRoleMembers = _.difference(roleMemberIds, selectedOrderMembers);
                const allRoleMembersSelected = nonSelectedRoleMembers.length === 0;
                const someRoleMembersSelected =
                    !allRoleMembersSelected && nonSelectedRoleMembers.length < roleMemberIds.length;

                return [
                    <ListSubheader>
                        {multiple ? (
                            <FormControlLabel
                                label={_.startCase(role)}
                                control={
                                    <Checkbox
                                        checked={allRoleMembersSelected}
                                        indeterminate={someRoleMembersSelected}
                                        onChange={(event) => handleSelectRole(event, roleMemberIds)}
                                    />
                                }
                                slotProps={{ typography: { variant: 'sectionHeader', color: 'text.secondary' } }}
                            />
                        ) : (
                            <Typography variant="sectionHeader" color="text.secondary">
                                {_.startCase(role)}
                            </Typography>
                        )}
                    </ListSubheader>,
                    _.map(roleMemberList, (orderMember) => {
                        return (
                            <MenuItem key={orderMember.id} value={orderMember.id}>
                                {multiple && <Checkbox checked={_.includes(selectedOrderMembers, orderMember.id)} />}
                                <ListItemText primary={getOrderMemberName(orderMember)} />
                            </MenuItem>
                        );
                    }),
                ];
            })}
        </TextField>
    );
}

MemberSelectField.propTypes = {
    selectedOrderMembers: PropTypes.array.isRequired,
    setSelectedOrderMembers: PropTypes.func.isRequired,
    orderMembers: PropTypes.array.isRequired,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    multiple: PropTypes.bool,
    TextFieldProps: PropTypes.object,
};

export default MemberSelectField;
