import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import OrderAvatar from 'components/Order/components/OrderAvatar';
import OrderStatusChip from 'components/Order/components/OrderStatusChip';
import { formatAddress } from 'helpers/utils';

const SearchResultListItem = ({ order, handleSelect }) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(`/order/${order.id}`);
        handleSelect();
    };

    return (
        <ListItemButton onClick={handleNavigate}>
            <ListItemAvatar>
                <OrderAvatar transactionType={order.transaction_type} />
            </ListItemAvatar>

            <ListItemText
                primary={order.qualia_order_number}
                secondary={formatAddress(order.property_address)}
                secondaryTypographyProps={{ noWrap: true }}
            />

            <OrderStatusChip status={order.status} size="small" />
        </ListItemButton>
    );
};

SearchResultListItem.propTypes = {
    order: PropTypes.object.isRequired,
    handleSelect: PropTypes.func.isRequired,
};

export default SearchResultListItem;
