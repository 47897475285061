import EastRoundedIcon from '@mui/icons-material/EastRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IconButton, List, ListItem, ListItemText, Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';

import {
    useGetOrderDocumentsByAssociatedParcelQuery,
    useGetOrderDocumentsByAssociatedParcelTypeQuery,
} from 'api/document';
import { Button } from 'components/common/styled';
import { formatDocumentType } from 'helpers/utils';
import Config from '../../../../config';

function DocumentListItem({ document }) {
    const baseDocumentUrl = `${Config.api_base}/document/${document.id}/file`;
    return (
        <ListItem
            disableGutters
            secondaryAction={
                <IconButton href={`${baseDocumentUrl}?attachment`} size="small">
                    <FileDownloadOutlinedIcon />
                </IconButton>
            }
        >
            <ListItemText primary={document.file_name} secondary={formatDocumentType(document.type)} />
        </ListItem>
    );
}

function DocumentList({ documents }) {
    if (!documents || documents.length === 0) {
        return (
            <Typography variant="body1" color="text.secondary" fontStyle="italic">
                No associated documents
            </Typography>
        );
    }

    return (
        <List disablePadding spacing={2}>
            {_.map(documents, (document) => {
                return <DocumentListItem key={document.id} document={document} />;
            })}
        </List>
    );
}

function ParcelDocumentList({ order, parcel }) {
    // NOTE: implicit associations for 'obvious' things (ex: parcel hoa -> document hoa_supporting_docs)
    const {
        data: implicitParcelDocuments,
        isLoading: implicitParcelDocumentsLoading,
    } = useGetOrderDocumentsByAssociatedParcelTypeQuery({ orderId: order.id, parcelType: parcel.type });

    // NOTE: explicit associations manually or automatically set (ex: using a parcel's action to upload a new document)
    const { data: parcelDocuments, isLoading: parcelDocumentsLoading } = useGetOrderDocumentsByAssociatedParcelQuery({
        orderId: order.id,
        parcelId: parcel.id,
    });

    // TODO unique merge implicitParcelDocuments and parcelDocuments together into single list

    // TODO add loading state indecator internal to main render
    if (parcelDocumentsLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Documents</Typography>
            </Stack>

            <DocumentList documents={parcelDocuments} />

            <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ pt: 3 }}>
                <Button
                    component={RouterLink}
                    to={`/order/${order.id}/documents`}
                    variant="outlined"
                    color="default"
                    size="small"
                    endIcon={<EastRoundedIcon />}
                >
                    View All
                </Button>
            </Stack>
        </Paper>
    );
}

ParcelDocumentList.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default ParcelDocumentList;
