import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { rootApi } from './root';

export const USER_TAG = _.toUpper(ENTITY_TYPE.user);

// TODO switch url path from users/external - > users
export const userApi = rootApi.injectEndpoints({
    reducerPath: 'userApi',
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => 'users/external',
            providesTags: [USER_TAG],
        }),
        createUser: builder.mutation({
            query: (user) => ({
                url: 'users/external',
                method: 'POST',
                body: user,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [USER_TAG],
        }),
        updateUser: builder.mutation({
            query: ({userId, userData}) => ({
                url: `user/external/${userId}`,
                method: 'PUT',
                body: userData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [USER_TAG],
        }),
        createUserRelationship: builder.mutation({
            query: ({userId, relationshipData}) => ({
                url: `user/external/${userId}/relationships`,
                method: 'POST',
                body: relationshipData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [USER_TAG],
        }),
        updateUserRelationship: builder.mutation({
            query: ({userId, relationshipId, relationshipData}) => ({
                url: `user/external/${userId}/relationship/${relationshipId}`,
                method: 'PUT',
                body: relationshipData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [USER_TAG],
        }),
        deleteUserRelationship: builder.mutation({
            query: ({userId, relationshipId}) => ({
                url: `user/external/${userId}/relationship/${relationshipId}`,
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [USER_TAG],
        }),
        createUserPortalAccess: builder.mutation({
            query: ({userId}) => ({
                url: `user/external/${userId}/access`,
                method: 'POST',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [USER_TAG],
        }),
        updateUserPortalAccess: builder.mutation({
            query: ({userId, accessData}) => ({
                url: `user/external/${userId}/access`,
                method: 'PUT',
                body: accessData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [USER_TAG],
        }),
    }),
    overrideExisting: false,
})

export function useGetUserQuery(userId) {
    const { useGetUsersQuery } = userApi;
    return useGetUsersQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.find((user) => user.id === userId),
            error,
            isLoading
        }),
    });
}


export function useGetUsersBySearchQuery(searchTerm) {
    const { useGetUsersQuery } = userApi;
    return useGetUsersQuery(undefined, {
        skip: searchTerm.length < 3,
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((user) => {
                return _.includes(_.lowerCase(`${user.first_name} ${user.last_name} ${user.contact?.email || ''}`), _.lowerCase(searchTerm))
            }),
            error,
            isLoading
        }),
    });
}


export const { 
    useGetUsersQuery, 
    useCreateUserMutation, 
    useUpdateUserMutation, 
    useCreateUserRelationshipMutation, 
    useUpdateUserRelationshipMutation, 
    useDeleteUserRelationshipMutation,
    useCreateUserPortalAccessMutation,
    useUpdateUserPortalAccessMutation,
} = userApi