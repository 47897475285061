import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { useGetOrderMembersQuery, useGetOrderQuery } from 'api/order';
import OrderBreadcrumbs from 'components/Order/OrderDetails/components/OrderBreadcrumbs';
import { ASSIGNED_ACTION_FILTER_TYPE } from 'helpers/constants';
import { filterAssignedActions } from 'helpers/utils';
import AddAssignedActionModalDialog from './components/AddAssignedActionModal';
import AssignedActionListFilters from './components/AssignedActionListFilters';
import AssignedActionListGrid, { ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE } from './components/AssignedActionListGrid';
import useGetOrderAssignedActions from './useGetOrderAssignedActions';

const DEFAULT_ACTION_FILTER_STATE = {
    [ASSIGNED_ACTION_FILTER_TYPE.actionType]: '',
    [ASSIGNED_ACTION_FILTER_TYPE.actionStatus]: '',
    [ASSIGNED_ACTION_FILTER_TYPE.orderMemberId]: '',
    [ASSIGNED_ACTION_FILTER_TYPE.linkedId]: '',
};

function AssignedActionList({ orderId }) {
    const { data: orderData, isFetching: orderFetching, refetch: refetchOrder } = useGetOrderQuery(orderId);
    const { data: membersData, isFetching: membersFetching, refetch: refetchMembers } = useGetOrderMembersQuery(
        orderId
    );

    const [openDialog, setOpenDialog] = useState(false);

    // Base filters
    const [filters, setFilters] = useState({
        ...DEFAULT_ACTION_FILTER_STATE,
    });

    // Get all assigned actions for the order
    const assignedActions = useGetOrderAssignedActions(orderData);

    // Reduce assigned actions based on filters
    const filteredAssignedActions = useMemo(() => filterAssignedActions(assignedActions, filters), [
        assignedActions,
        filters,
    ]);

    const setLinkedId = (linkedId) => {
        setFilters({
            ...filters,
            [ASSIGNED_ACTION_FILTER_TYPE.linkedId]: linkedId,
        });
    };

    const resetData = () => {
        refetchOrder();
        refetchMembers();
    };

    const resetFilters = () => {
        setFilters({
            ...DEFAULT_ACTION_FILTER_STATE,
        });
    };

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const loading = orderFetching || membersFetching;
    const actionTypeOptions = _.uniqBy(assignedActions, 'type');

    return (
        <Stack spacing={3}>
            <OrderBreadcrumbs orderId={orderId} />

            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h1">Requests</Typography>

                <Box>
                    <Fab
                        color="primary"
                        aria-label="add new request"
                        onClick={() => handleOpen()}
                        size="small"
                        sx={{
                            zIndex: 0,
                        }}
                    >
                        <AddIcon />
                    </Fab>
                </Box>
            </Stack>

            <Box>
                <AssignedActionListFilters
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                    defaultFilters={DEFAULT_ACTION_FILTER_STATE}
                    orderMembers={membersData || []}
                    actionTypeOptions={actionTypeOptions}
                />
            </Box>

            <Box>
                <AssignedActionListGrid
                    assignedActions={filteredAssignedActions}
                    loading={loading}
                    hideColumns={[ASSIGNED_ACTION_LIST_GRID_COLUMN_TYPE.orderId]}
                    refetch={resetData}
                    setLinkedId={setLinkedId}
                />
            </Box>

            <AddAssignedActionModalDialog
                open={openDialog}
                handleClose={handleClose}
                order={orderData}
                orderMembers={membersData || []}
            />
        </Stack>
    );
}

AssignedActionList.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default AssignedActionList;
