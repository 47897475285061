import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { rootApi } from './root';

export const LEGAL_ENTITY_TAG = _.toUpper(ENTITY_TYPE.legal_entity);
export const LEGAL_ENTITY_MEMBER_TAG = _.toUpper(ENTITY_TYPE.legal_entity_member);


export const legalEntityApi = rootApi.injectEndpoints({
    reducerPath: 'legalEntityApi',
    endpoints: (builder) => ({
        getLegalEntities: builder.query({
            query: () => 'legal_entities',
            providesTags: [LEGAL_ENTITY_TAG],
        }),
        createLegalEntity: builder.mutation({
            query: (legalEntityData) => ({
                url: 'legal_entities',
                method: 'POST',
                body: legalEntityData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [LEGAL_ENTITY_TAG],
        }),
        updateLegalEntity: builder.mutation({
            query: ({legalEntityId, legalEntityData}) => ({
                url: `legal_entity/${legalEntityId}`,
                method: 'PUT',
                body: legalEntityData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [LEGAL_ENTITY_TAG],
        }),
        getAllLegalEntityMembers: builder.query({
            query: () => 'legal_entities/members',
            providesTags: [LEGAL_ENTITY_MEMBER_TAG],
        }),
        createLegalEntityMember: builder.mutation({
            query: ({legalEntityId, memberData}) => ({
                url: `legal_entity/${legalEntityId}/members`,
                method: 'POST',
                body: memberData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [LEGAL_ENTITY_TAG, LEGAL_ENTITY_MEMBER_TAG],
        }),
        updateLegalEntityMember: builder.mutation({
            query: ({legalEntityId, memberId, memberData}) => ({
                url: `legal_entity/${legalEntityId}/member/${memberId}`,
                method: 'PUT',
                body: memberData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [LEGAL_ENTITY_TAG, LEGAL_ENTITY_MEMBER_TAG],
        }),
        deleteLegalEntityMember: builder.mutation({
            query: ({legalEntityId, memberId}) => ({
                url: `legal_entity/${legalEntityId}/member/${memberId}`,
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [LEGAL_ENTITY_TAG, LEGAL_ENTITY_MEMBER_TAG],
        }),
    }),
    overrideExisting: false,
})

export function useGetLegalEntityQuery(legalEntityId) {
    const { useGetLegalEntitiesQuery } = legalEntityApi;
    return useGetLegalEntitiesQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.find((legalEntity) => legalEntity.id === legalEntityId),
            error,
            isLoading
        }),
    });
}

export function useGetLegalEntitiesBySearchQuery(searchTerm) {
    const { useGetLegalEntitiesQuery } = legalEntityApi;
    return useGetLegalEntitiesQuery(undefined, {
        skip: searchTerm.length < 3,
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((legalEntity) => {
                return _.includes(_.lowerCase(`${legalEntity.name} ${legalEntity.contact?.email || ''}`), _.lowerCase(searchTerm))
            }),
            error,
            isLoading
        }),
    });
}

export function useGetLegalEntityMemberQuery(legalEntityMemberId) {
    const { useGetAllLegalEntityMembersQuery } = legalEntityApi;
    return useGetAllLegalEntityMembersQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.find((legalEntityMember) => legalEntityMember.id === legalEntityMemberId),
            error,
            isLoading
        }),
    });
}

export function useGetLegalEntityMembersBySearchQuery(searchTerm) {
    const { useGetAllLegalEntityMembersQuery } = legalEntityApi;
    return useGetAllLegalEntityMembersQuery(undefined, {
        skip: searchTerm.length < 3,
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((legalEntityMember) => {
                return _.includes(_.lowerCase(legalEntityMember.user.name), _.lowerCase(searchTerm))
            }),
            error,
            isLoading
        }),
    });
}

export function useGetLegalEntityMembersByUserQuery(userId) {
    const { useGetAllLegalEntityMembersQuery } = legalEntityApi;
    return useGetAllLegalEntityMembersQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((legalEntityMember) => legalEntityMember.user_id === userId),
            error,
            isLoading
        }),
    });
}


export const { 
    useGetLegalEntitiesQuery, 
    useCreateLegalEntityMutation, 
    useUpdateLegalEntityMutation, 
    useCreateLegalEntityMemberMutation, 
    useUpdateLegalEntityMemberMutation, 
    useDeleteLegalEntityMemberMutation,
    useGetAllLegalEntityMembersQuery
} = legalEntityApi