import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Sidebar } from './components';

const sidebarWidth = 30;
const topbarHeight = 0; //89;
const sidebarWidthPx = `${sidebarWidth * 8}px`; // multiple by 8 to convert spacing unit to px
const topbarHeightPx = `${topbarHeight * 8}px`; // multiple by 8 to convert spacing unit to px

const Main = (props) => {
    const { children } = props;

    return (
        <Box
            sx={{
                paddingTop: topbarHeight,
                height: '100%',
            }}
        >
            <Sidebar open onClose={() => console.log('close sidebar')} variant="persistent" />
            <Box
                component="main"
                sx={{
                    height: '100%',
                    minHeight: `calc(100vh - ${topbarHeightPx})`,
                    paddingLeft: sidebarWidth,
                    background: (theme) => theme.palette.background.gray,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

Main.propTypes = {
    children: PropTypes.node,
};

export default Main;
