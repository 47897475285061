import PropTypes from 'prop-types';
import React from 'react';
import BaseDialog from '../../common/BaseDialog';

import TagForm from './TagForm';

const TagDialog = ({ order, open, handleClose, tag, setTag }) => {
    const handleSubmit = (newTag) => {
        setTag(newTag);
        handleClose(true);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleClose(false)}
            DialogProps={{
                maxWidth: 'xs',
                fullWidth: true,
            }}
        >
            {open && <TagForm order={order} tag={tag} setTag={handleSubmit} />}
        </BaseDialog>
    );
};

TagDialog.propTypes = {
    order: PropTypes.object,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    tag: PropTypes.object,
    setTag: PropTypes.func.isRequired,
};

export default TagDialog;
