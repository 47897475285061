import { Chip, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import UITriggers from 'components/Parcel/UITriggers';
import { PARCEL_STATUS, PARCEL_TYPE } from 'helpers/constants';
import { findOrderParcelByType, generateUpdateObject } from 'helpers/utils';
import BalancingParcelList from './components/BalancingParcelList';
import BalancingParcelReviewAction from './components/BalancingParcelReviewAction';

import { useGetOrderQuery } from 'api/order';
import { useUpdateParcelMutation } from 'api/parcel';
import ParcelBreadcrumbs from 'components/Order/Parcel/components/ParcelBreadcrumbs';

function Balancing({ orderId }) {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    const [updateParcel, { isLoading: updateParcelLoading }] = useUpdateParcelMutation();

    const [balancingParcel, setBalancingParcel] = useState();
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (order) {
            const parcel = findOrderParcelByType(order, PARCEL_TYPE.balancing);

            setBalancingParcel(parcel);
        }
    }, [order]);

    const handleParcelUpdate = async (parcelId, updatedParcelData) => {
        const updateParcelPayload = {
            orderId: orderId,
            parcelId: parcelId,
            parcelData: updatedParcelData,
        };

        const { data } = await updateParcel(updateParcelPayload);
        return !!data;
    };

    const saveParcel = (originalParcel, updatedParcel) => {
        setIsSaving(true);

        const updateData = generateUpdateObject(originalParcel, updatedParcel, ['current_status.status', 'trigger'], {
            'current_status.status': 'status',
        });

        handleParcelUpdate(originalParcel.id, updateData).finally(() => {
            setIsSaving(false);
        });
    };

    if (!order && orderLoading) {
        return <div>Loading...</div>;
    }

    if (!balancingParcel) {
        return <div>No balancing parcel found</div>;
    }

    return (
        <Stack spacing={3}>
            <ParcelBreadcrumbs parcel={balancingParcel} />

            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h1">Balancing</Typography>

                <UITriggers
                    dataEntity={balancingParcel}
                    saveEntity={saveParcel}
                    isSaving={isSaving}
                    sx={{ minWidth: '160px' }}
                />
            </Stack>

            {balancingParcel.current_status.status !== PARCEL_STATUS.not_started && (
                <Stack spacing={3}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Typography variant="body2" fontWeight="500">
                            {balancingParcel.current_status.status === PARCEL_STATUS.in_progress
                                ? 'Started: '
                                : 'Completed: '}
                            {format(new Date(balancingParcel.current_status.created_datetime), 'P')}
                        </Typography>

                        <Chip
                            label={_.startCase(balancingParcel.current_status.status)}
                            color={
                                balancingParcel.current_status.status === PARCEL_STATUS.complete ? 'primary' : 'default'
                            }
                            sx={{
                                borderRadius: (theme) => theme.spacing(0.5),
                            }}
                        />
                    </Stack>

                    <BalancingParcelReviewAction balancingParcel={balancingParcel} />

                    <BalancingParcelList
                        order={order}
                        balancingParcel={balancingParcel}
                        handleParcelUpdate={handleParcelUpdate}
                        loading={orderLoading}
                    />
                </Stack>
            )}
        </Stack>
    );
}

Balancing.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default Balancing;
