import { Box, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { BALANCING_CHILD_PARCEL_TYPES, PARCEL_TYPE, SIGNING_PARCEL_TYPES } from 'helpers/constants';
import { formatISODate } from 'helpers/utils';

import ParcelBreadcrumbs from 'components/Order/Parcel/components/ParcelBreadcrumbs';
import ParcelActionList from './components/ParcelActionList';
import ParcelDetails from './components/ParcelDetails';
import ParcelDocumentList from './components/ParcelDocumentList';
import ParcelNoteList from './components/ParcelNoteList';
import ParcelStatusSelect from './components/ParcelStatusSelect';
import ParcelTimeline from './components/ParcelTimeline';
import ParcelTriggerList from './components/ParcelTriggerList';

import useParcelData from 'components/common/hooks/useParcelData';

function Parcel({ orderId, parcelId }) {
    const { order, parcel, childParcels, parentParcel, isLoading } = useParcelData(orderId, parcelId);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!parcel) {
        return (
            <div>
                parcel ({parcelId}) not found in order ({orderId})
            </div>
        );
    }

    // Hard redirects for parcel types with custom views:
    if (parcel.type === PARCEL_TYPE.title_clearing) {
        return <Navigate to={`/order/${orderId}/title`} />;
    }

    if (parcel.type === PARCEL_TYPE.assignment) {
        return <Navigate to={`/order/${orderId}/assignments`} />;
    }

    if (parcel.type === PARCEL_TYPE.assignment_contract) {
        return <Navigate to={`/order/${orderId}/assignment/${parcelId}`} />;
    }

    if (_.includes([PARCEL_TYPE.balancing, ...BALANCING_CHILD_PARCEL_TYPES], parcel.type)) {
        return <Navigate to={`/order/${orderId}/balancing`} />;
    }

    // Hard redirect for parcel types that should be managed through their parent parcel
    if (_.includes([PARCEL_TYPE.scheduling, ...SIGNING_PARCEL_TYPES], parcel.type)) {
        // Navigate to the parent parcel to manage scheduling & signing together
        return <Navigate to={`/order/${orderId}/parcel/${parcel.parent_parcel_id}`} />;
    }

    return (
        <Stack spacing={3}>
            <ParcelBreadcrumbs parcel={parcel} />

            <Stack spacing={0}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h1">{parcel.name}</Typography>

                    <Stack direction="row" spacing={2} alignItems="center">
                        {parcel.required_complete_date && (
                            <Typography variant="body2" color="text.primary" fontWeight={500}>
                                Due: {formatISODate(parcel.required_complete_date)}
                            </Typography>
                        )}

                        <ParcelStatusSelect parcel={parcel} />
                    </Stack>
                </Stack>
            </Stack>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                    <Grid item md={12} lg={5} xl={4}>
                        <Stack spacing={3}>
                            <ParcelDetails order={order} parcel={parcel} />
                        </Stack>
                    </Grid>

                    <Grid item md={12} lg={7} xl={8}>
                        <Grid container spacing={3} sx={{ flexGrow: 1 }}>
                            <Grid item md={12} lg={12} xl={6}>
                                <Stack spacing={3}>
                                    <ParcelActionList order={order} parcel={parcel} />

                                    <ParcelTriggerList order={order} parcel={parcel} />

                                    <ParcelDocumentList order={order} parcel={parcel} />

                                    <ParcelNoteList order={order} parcel={parcel} />
                                </Stack>
                            </Grid>

                            <Grid item md={12} lg={12} xl={6}>
                                <ParcelTimeline order={order} parcel={parcel} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
}

Parcel.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default Parcel;
