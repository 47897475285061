import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGridPro, GridPagination } from '@mui/x-data-grid-pro';
import _ from 'lodash';

function DataGridFooter({ refetch, loading }) {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{
                borderTop: (theme) => `1px solid ${theme.palette.border}`,
            }}
        >
            {!!refetch && (
                <Tooltip title="Refresh Data" placement="bottom" enterDelay={300}>
                    <span style={{ display: 'inline-block' }}>
                        <IconButton onClick={() => refetch()} color="default" disabled={loading}>
                            <RefreshIcon sx={{ color: (theme) => theme.palette.text.primary }} />
                        </IconButton>
                    </span>
                </Tooltip>
            )}

            <Box />

            <GridPagination />
        </Stack>
    );
}

const StyledDataGrid = styled(({ refetch, loading = false, ...rest }) => (
    <DataGridPro
        {...rest}
        loading={loading}
        components={{
            Footer: DataGridFooter,
            ..._.get(rest, 'components', {}),
        }}
        componentsProps={{
            footer: { refetch, loading },
            ..._.get(rest, 'componentsProps', {}),
        }}
    />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.border}`,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-cellCheckbox:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeaders': {
        borderBottomColor: theme.palette.border,
    },

    '& .MuiDataGrid-columnHeader': {
        // borderBottomColor: theme.palette.border,
        // '&:first-of-type': {
        //     paddingLeft: theme.spacing(2),
        // },
        // '&:last-of-type': {
        //     paddingRight: theme.spacing(2),
        // },
    },

    '& .MuiDataGrid-cell': {
        borderBottomColor: theme.palette.border,

        // '&:first-of-type': {
        //     paddingLeft: theme.spacing(2),
        // },

        // '&:last-of-type': {
        //     paddingRight: theme.spacing(2),
        // },
    },

    '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
        borderTop: `1px solid ${theme.palette.border}`,
    },
    '& .MuiDataGrid-row--detailPanelExpanded': {
        backgroundColor: '#f5f5f5',

        '& .MuiDataGrid-cell': {
            borderBottomColor: '#f5f5f5',
        },
    },
    '& .MuiDataGrid-detailPanel': {
        backgroundColor: '#f5f5f5',
    },
}));

export default StyledDataGrid;
