import DoneIcon from '@mui/icons-material/Done';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import StopIcon from '@mui/icons-material/Stop';
import { List, ListItem, ListItemText, Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useUpdateParcelMutation } from '../../../../api/parcel';
import { findChildParcelsInOrder } from '../../../../helpers/utils';
import { getTriggers, useEvalTriggerConditions, useTriggers } from '../../../common/hooks/useTriggers';
import { LoadingButton } from '../../../common/styled';

const PARCEL_TRIGGER_ICON_MAP = {
    play: <PlayArrowIcon />,
    stop: <StopIcon />,
    pause: <PauseIcon />,
    restart: <RestartAltIcon />,
    done: <DoneIcon />,
    generate: <NoteAddIcon />,
    default: <DoneIcon />,
};

function useParcelTriggerAction({ trigger, parcel }) {
    const [updateParcel, { isLoading }] = useUpdateParcelMutation();

    const handleUpdate = (event) => {
        event.preventDefault();

        // NOTE: currently we are ignoring the trigger type & action keys - assume update action
        const updateData = {};
        _.forEach(trigger.payload, (updateValue, updatePath) => {
            _.set(updateData, updatePath, updateValue);
        });

        submitUpdate(updateData);
    };

    const submitUpdate = async (updateData) => {
        const updatePayload = {
            parcelId: parcel.id,
            parcelData: updateData,
        };

        const { data } = await updateParcel(updatePayload);
    };

    return [handleUpdate, { isLoading }];
}

export const ParcelTriggerIconButton = ({ parcel, trigger, icon = null, buttonProps = {} }) => {
    const [handleUpdate, { isLoading }] = useParcelTriggerAction({ trigger, parcel });

    const buttonIcon = icon || PARCEL_TRIGGER_ICON_MAP[_.get(trigger, 'button.icon', 'default')];
    const buttonVariant = buttonProps.variant || _.get(trigger, 'button.variant', 'contained');
    const buttonColor = buttonProps.color || _.get(trigger, 'button.color', 'default');

    return (
        <LoadingButton
            color={buttonColor}
            variant={buttonVariant}
            onClick={handleUpdate}
            loading={isLoading}
            disableElevation
            {...buttonProps}
            sx={{
                padding: (theme) => theme.spacing(1),
                minWidth: (theme) => theme.spacing(3),
                ..._.get(buttonProps, 'sx', {}),
            }}
        >
            {buttonIcon}
        </LoadingButton>
    );
};

ParcelTriggerIconButton.propTypes = {
    trigger: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
    icon: PropTypes.node,
    buttonProps: PropTypes.object,
};

export const ParcelTriggerButton = ({ trigger, parcel, buttonProps = {} }) => {
    const [handleUpdate, { isLoading }] = useParcelTriggerAction({ trigger, parcel });
    const showTrigger = useEvalTriggerConditions({ dataEntity: parcel, trigger });

    if (!showTrigger) {
        return null;
    }

    return (
        <LoadingButton variant="contained" onClick={handleUpdate} loading={isLoading} disableElevation {...buttonProps}>
            {trigger.text}
        </LoadingButton>
    );
};

ParcelTriggerButton.propTypes = {
    trigger: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
    buttonProps: PropTypes.object,
};

function TriggerListItem({ parcel, trigger }) {
    const showTrigger = useEvalTriggerConditions({ dataEntity: parcel, trigger });

    if (!showTrigger) {
        return null;
    }

    return (
        <ListItem
            disableGutters
            secondaryAction={
                <ParcelTriggerIconButton
                    parcel={parcel}
                    trigger={trigger}
                    buttonProps={{ color: 'default', variant: 'outlined' }}
                />
            }
        >
            <ListItemText primary={trigger.text} secondary={trigger.description} />
        </ListItem>
    );
}

const TriggerList = ({ parcel }) => {
    const { triggers, activeTriggers } = useTriggers(parcel);

    if (!triggers || triggers.length === 0 || activeTriggers.length === 0) {
        return null;
    }

    return (
        <List disablePadding spacing={2}>
            {_.map(triggers, (trigger, triggerKey) => {
                return <TriggerListItem key={triggerKey} parcel={parcel} trigger={trigger} />;
            })}
        </List>
    );
};

const ParcelTriggerList = ({ order, parcel }) => {
    const childParcels = useMemo(() => {
        if (order) {
            return findChildParcelsInOrder(order, parcel.id);
        }

        return [];
    }, [order, parcel.id]);

    const parcelActiveTriggerMap = useMemo(() => {
        // Check the main parcel and each child parcel for active triggers
        const activeTriggerMap = {};

        _.forEach([parcel, ...childParcels], (parcelEntity) => {
            const { activeTriggers } = getTriggers(parcelEntity);
            if (activeTriggers && activeTriggers.length > 0) {
                activeTriggerMap[parcelEntity.id] = activeTriggers;
            }
        });

        return activeTriggerMap;
    });

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Triggers</Typography>
            </Stack>

            {_.keys(parcelActiveTriggerMap).length === 0 && (
                <Typography variant="body1" color="text.secondary" fontStyle="italic">
                    No active triggers
                </Typography>
            )}

            <TriggerList parcel={parcel} />

            {_.map(childParcels, (childParcel) => (
                <TriggerList key={childParcel.id} parcel={childParcel} />
            ))}
        </Paper>
    );
};

ParcelTriggerList.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default ParcelTriggerList;
