import { Box, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { useGetLegalEntityQuery } from 'api/legalEntity';
import LegalEntityDetailsCore from './LegalEntityDetailsCore';
import LegalEntityDetailsMembershipList from './LegalEntityDetailsMembershipList';
import LegalEntityDetailsOrderMembershipList from './LegalEntityDetailsOrderMembershipList';
import LegalEntityDetailsRelationshipList from './LegalEntityDetailsRelationshipList';

function LegalEntityDetails({ legalEntityId }) {
    const { data: legalEntity, isError: legalEntityError, isLoading: legalEntityLoading } = useGetLegalEntityQuery(
        legalEntityId
    );

    return (
        <Stack spacing={3}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                    <Grid item md={12} lg={5} xl={4}>
                        <LegalEntityDetailsCore legalEntity={legalEntity} />
                    </Grid>

                    <Grid item md={12} lg={7} xl={8}>
                        <Grid container spacing={3} sx={{ flexGrow: 1 }}>
                            <Grid item xs={12} lg={12} xl={6}>
                                <Stack spacing={3}>
                                    <LegalEntityDetailsMembershipList legalEntity={legalEntity} />

                                    <LegalEntityDetailsRelationshipList legalEntity={legalEntity} />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} lg={12} xl={6}>
                                <LegalEntityDetailsOrderMembershipList legalEntity={legalEntity} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
}

LegalEntityDetails.propTypes = {
    legalEntityId: PropTypes.string.isRequired,
};

export default LegalEntityDetails;
