import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';

import { ORDER_FILTER_TYPE } from 'helpers/constants';

const SearchBar = ({ filters, setFilters, defaultFilters, resetFilters }) => {
    const searchInputRef = useRef();

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string isn't valid for some filters
        };

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const changeHandler = (event) => {
        handleFilter(event);
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 300), []);

    const setSearchInput = (value) => {
        searchInputRef.current.value = value;
    };

    const resetAllFilters = () => {
        // Reset local uncontrolled search input
        setSearchInput(defaultFilters[ORDER_FILTER_TYPE.search]);

        // Reset parent filters
        resetFilters();
    };

    useEffect(() => {
        // Stop the invocation of the debounced function after unmounting
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    return (
        <TextField
            name={ORDER_FILTER_TYPE.search}
            label=""
            type="search"
            variant="standard"
            placeholder="Search orders ..."
            onChange={debouncedChangeHandler}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" sx={{ ml: 1 }}>
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            inputRef={searchInputRef}
            autoFocus
            fullWidth
            sx={{
                '& .MuiInput-root': {
                    pt: '10px',
                    pb: '10px',
                    pl: 2,
                    pr: 2,

                    transition: (theme) => theme.transitions.create(['background-color'], {}),

                    '&.MuiInput-underline:before': {
                        borderBottomColor: (theme) => theme.palette.divider,
                    },

                    '&.Mui-focused': {
                        backgroundColor: (theme) => theme.palette.background.darkGray,
                    },

                    '& .MuiInput-input': {
                        pl: 2,
                    },

                    '& [type="search"]::-webkit-search-cancel-button': {
                        webkitAppearance: 'none',
                        appearance: 'none',
                        height: '10px',
                        width: '10px',
                        backgroundImage:
                            'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=)',
                        backgroundSize: '10px 10px',
                    },
                },
            }}
        />
    );
};

SearchBar.propTypes = {
    defaultFilters: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
};

export default SearchBar;
