import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';
import _ from 'lodash';

import { SNACKBAR_SEVERITY } from '../helpers/constants';
import { setSnackbar } from '../stores/snackbar';
import { UserAction } from '../stores/user';


const generateSnackbarMessage = (action, rejected=false) => {
    const messagePath = rejected ? 'payload.data.message' : 'payload.message';
    const payloadMessage = _.get(action, messagePath);

    if (payloadMessage) {
        return payloadMessage;
    }

    // If action is mutation use endpointName to generate message
    const isMutation = _.get(action, 'meta.arg.type') === 'mutation';
    if (isMutation) {
        const endpointName = _.get(action, 'meta.arg.endpointName', ''); // "updateUser"
        const endpointNameStartCase = _.startCase(endpointName); // "Update User"
        const endpointNameWords = endpointNameStartCase.split(" "); // ["Update", "User"]

        if (rejected) {
            endpointNameWords.push('Failed'); // ["Update", "User", "Failed"]
            return endpointNameWords.join(' '); // "Update User Failed"
        } else {
            // All mutation verbs end in "e" (create, update, delete) so we can just add a "d" to the end
            endpointNameWords[0] = `${endpointNameWords[0]}d`; // ["Updated", "User"]
            return endpointNameWords.join(' '); // "Updated User"
        }
    } else {
        const endpointName = _.get(action, 'meta.arg.endpointName', ''); // "getOrders"
        const endpointNameStartCase = _.startCase(endpointName); // "Get Orders"
        const endpointNameWords = endpointNameStartCase.split(" "); // ["Get", "Orders"]

        if (rejected) {
            endpointNameWords.push('Failed'); // ["Get", "Orders", "Failed"]
            return endpointNameWords.join(' '); // "Get Orders Failed"
        } else {
            // We don't want to show a success snackbar for a query but that logic is handled in the middleware
            return '';
        }
    }
}


export const queryResponseMiddleware = (api) => (next) => (action) => {
    /*
        Handle secondary behavior for all fulfilled and rejected api calls from RTK Query:
        - Launch success snackbar if resolved mutation (post/put/delete)
        - Launch error snackbar if rejected mutation (post/put/delete) or query (get)
        - Clear session if any call is rejected with 403 status

        NOTE: 
        Being a redux middleware, this is called for every action and not just rtk-query api calls
        We implicitly filter for rtk-query actions by checking isFulfilled and isRejectedWithValue

        NOTE 2:
        Successful query/mutations will have response data mapped directly to action.payload
        However, rejected query/mutations will have response data in action.payload.data 
        as other details like request status are also provided in the base payload object
    */

    // console.log('queryResponseMiddleware', action);

    if (isFulfilled(action)) {
        // Launch success snackbar if mutation (post/put/delete)
        // Ignore query
        if (_.get(action, 'meta.arg.type') === 'mutation') {
            const message = generateSnackbarMessage(action);
            if (message) {
                api.dispatch(setSnackbar({ message, severity: SNACKBAR_SEVERITY.success }));
            }
        }
    } else if (isRejectedWithValue(action)) {
        // isRejected(action) - TODO switch to include isRejected but determine how to ignore cancelled requests (like requests midflight after navigation)
        console.warn('Rejected action', action)

        if (_.get(action, 'payload.status') === 403) {
            api.dispatch(UserAction.logoutSession());

            // Launch info snackbar - session expired
            api.dispatch(setSnackbar({ message: 'Session expired', severity: SNACKBAR_SEVERITY.info }))
        } else {
            const message = generateSnackbarMessage(action, true);
            if (message) {
                api.dispatch(setSnackbar({ message, severity: SNACKBAR_SEVERITY.error }));
            }
        }
    }
  
    return next(action)
}


export default queryResponseMiddleware;