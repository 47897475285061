import { Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import DollarFieldMask from '../../../common/mask/DollarFieldMask';

const EditPayoffCoreData = ({ payoff, payoffCoreData, setPayoffCoreData }) => {
    useEffect(() => {
        if (payoff) {
            setPayoffCoreData({
                amount: payoff.amount,
                loan_number: payoff.loan_number,
                payee_name: payoff.payee_name,
            });
        }
    }, [payoff]);

    const handleChange = ({ target }) => {
        const { name, value } = target;

        if (name === 'payee_name' && value.length === 0) {
            setPayoffCoreData({
                ...payoffCoreData,
                [target.name]: null,
            });
            return;
        }

        if (name === 'loan_number' && value.length === 0) {
            setPayoffCoreData({
                ...payoffCoreData,
                [target.name]: null,
            });
            return;
        }

        if (name === 'amount' && value === '0') {
            setPayoffCoreData({
                ...payoffCoreData,
                [target.name]: null,
            });
            return;
        }

        setPayoffCoreData({
            ...payoffCoreData,
            [target.name]: target.value,
        });
    };

    if (!payoffCoreData) {
        return null;
    }

    return (
        <Stack direction="column" spacing={2}>
            <TextField
                name="amount"
                label="Amount"
                value={payoffCoreData.amount}
                InputProps={{
                    inputComponent: DollarFieldMask,
                }}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                onChange={handleChange}
                required={false}
            />

            <TextField
                name="loan_number"
                label="Loan Number"
                value={payoffCoreData.loan_number || ''}
                onChange={handleChange}
                required={false}
            />

            <TextField
                name="payee_name"
                label="Payee Name"
                value={payoffCoreData.payee_name || ''}
                onChange={handleChange}
                required={false}
            />
        </Stack>
    );
};

EditPayoffCoreData.propTypes = {
    payoff: PropTypes.object.isRequired,
    payoffCoreData: PropTypes.object, // not required as it can be null
    setPayoffCoreData: PropTypes.func.isRequired,
};

export default EditPayoffCoreData;
