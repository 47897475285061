import PropTypes from 'prop-types';

import BaseDialog from 'components/common/BaseDialog';
import ManageDocumentAccessNotification from './ManageDocumentAccessNotification';

function DocumentAccessNotificationDialog({ open, handleClose, orderId, document }) {
    return (
        <BaseDialog
            open={open}
            setOpen={() => handleClose(false)}
            DialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
            }}
        >
            <ManageDocumentAccessNotification orderId={orderId} document={document} handleClose={handleClose} />
        </BaseDialog>
    );
}

DocumentAccessNotificationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    orderId: PropTypes.string.isRequired,
    document: PropTypes.object,
};

export default DocumentAccessNotificationDialog;
