import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { rootApi } from './root';

export const ADMIN_USER_TAG = `ADMIN_${_.toUpper(ENTITY_TYPE.user)}`;
export const CURRENT_ADMIN_USER_TAG = `CURRENT_${ADMIN_USER_TAG}`;

// TODO create new session api and move getCurrentAdminUser into it
export const adminUserApi = rootApi.injectEndpoints({
    reducerPath: 'adminUserApi',
    endpoints: (builder) => ({
        getCurrentAdminUser: builder.query({
            query: () => 'get_user',
            providesTags: [ADMIN_USER_TAG],
        }),
        getAdminUsers: builder.query({
            query: () => 'users',
            providesTags: [ADMIN_USER_TAG],
        }),
    }),
    overrideExisting: false,
})

export function useGetAdminUserQuery(userId) {
    const { useGetAdminUsersQuery } = adminUserApi;
    return useGetAdminUsersQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.find((user) => user.id === userId),
            error,
            isLoading
        }),
    });
}


export const { useGetCurrentAdminUserQuery, useGetAdminUsersQuery } = adminUserApi