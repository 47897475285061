import { Chip } from '@mui/material';
import { ORDER_STATUS } from 'helpers/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';

const STATUS_COLOR_MAP = {
    [ORDER_STATUS.pre_open]: 'default',
    [ORDER_STATUS.open]: 'default',
    [ORDER_STATUS.on_hold]: 'warning',
    [ORDER_STATUS.canceled]: 'error',
    [ORDER_STATUS.closed]: 'primary',
};

function OrderStatusChip({ status, sx = {}, ...rest }) {
    const statusPalette = _.get(STATUS_COLOR_MAP, status, 'default');

    if (statusPalette === 'default') {
        return (
            <Chip
                label={_.startCase(status)}
                color="default"
                sx={{
                    borderRadius: (theme) => theme.spacing(0.5),
                    ...sx,
                }}
                {...rest}
            />
        );
    }

    return (
        <Chip
            label={_.startCase(status)}
            color="default"
            sx={{
                backgroundColor: (theme) => theme.palette[statusPalette].light,
                color: (theme) => theme.palette[statusPalette].dark,
                borderRadius: (theme) => theme.spacing(0.5),
                ...sx,
            }}
            {...rest}
        />
    );
}

OrderStatusChip.propTypes = {
    status: PropTypes.oneOf([..._.values(ORDER_STATUS)]).isRequired,
};

export default OrderStatusChip;
