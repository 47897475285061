import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import AddressForm, { INITIAL_ADDRESS_DATA } from '../../common/form/address/AddressForm';
import ContactForm, { INITIAL_CONTACT_DATA } from '../../common/form/contact/ContactForm';
import ProfileForm, { INITIAL_PROFILE_DATA } from '../../common/form/profile/ProfileForm';

export const INITIAL_USER_STATE = {
    ...INITIAL_PROFILE_DATA,
    contact: { ...INITIAL_CONTACT_DATA },
    address: { ...INITIAL_ADDRESS_DATA },
};

const UserForm = ({ userData, setUserData }) => {
    const handleProfileChange = (newProfile) => {
        setUserData({
            ...userData,
            ...newProfile,
        });
    };

    const handleContactChange = (newContact) => {
        setUserData({
            ...userData,
            contact: {
                ...userData.contact,
                ...newContact,
            },
        });
    };

    const handleAddressChange = (newAddress) => {
        setUserData({
            ...userData,
            address: { ...newAddress },
        });
    };

    return (
        <Stack
            spacing={4}
            sx={{
                '& .form-group-label': {
                    fontSize: '12px',
                    fontWeight: '600',
                    textTransform: 'uppercase',
                },
            }}
        >
            <ProfileForm profileData={userData} setProfileData={handleProfileChange} />

            <ContactForm contactData={userData.contact} setContactData={handleContactChange} showLabel={true} />

            <AddressForm
                addressData={userData.address}
                setAddressData={handleAddressChange}
                showLabel={true}
                label="Address"
            />
        </Stack>
    );
};

UserForm.propTypes = {
    userData: PropTypes.object.isRequired,
    setUserData: PropTypes.func.isRequired,
};

export default UserForm;
