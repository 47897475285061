import { Box, Typography } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const StyledBackgroundProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
}));

const StyledForegroundProgress = styled(CircularProgress)(({ theme }) => ({
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
    [`& .${circularProgressClasses.circle}`]: {
        strokeLinecap: 'round',
    },
}));

function StyledCircularProgress(props) {
    const { children, ...rest } = props;
    return (
        <Box sx={{ position: 'relative' }}>
            <StyledBackgroundProgress variant="determinate" size={40} thickness={4} {...rest} value={100} />
            <StyledForegroundProgress disableShrink size={40} thickness={4} {...rest} />
            {children && (
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {children}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default StyledCircularProgress;
