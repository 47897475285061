import { Checkbox, ListItemText, MenuItem, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useGetLienStatusesQuery } from '../../../api/lien';
import { LIEN_CLEARED_STATUS, LIEN_TYPE, ORDER_TITLE_FILTER_TYPE } from '../../../helpers/constants';
import FilterTextField from '../../common/styled/FilterTextField';
import useFilterQueryParams from '../../common/hooks/useFilterQueryParams';

function TitleListFilters({ filters, defaultFilters, setFilters }) {
    const {
        data: lienStatusMap,
        isError: lienStatusMapError,
        isLoading: lienStatusMapLoading,
    } = useGetLienStatusesQuery();

    const [syncFilterMap, searchParams] = useFilterQueryParams(filters, defaultFilters, {
        multiArgFilterTypes: [ORDER_TITLE_FILTER_TYPE.lienType],
    });

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string isn't valid for some filters
        };

        if (filterName === ORDER_TITLE_FILTER_TYPE.lienType) {
            // Lien statuses are related to lien type
            // Reset lien status if parcel type is changed
            filterUpdate[ORDER_TITLE_FILTER_TYPE.lienStatus] = defaultFilters[ORDER_TITLE_FILTER_TYPE.lienStatus];
        }

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    useEffect(() => {
        if (_.keys(syncFilterMap).length > 0) {
            const updatedFilterMap = {
                ...defaultFilters,
                ...syncFilterMap,
            };
            setFilters(updatedFilterMap);
        }
    }, [syncFilterMap]);

    const lienTypeFilter = filters[ORDER_TITLE_FILTER_TYPE.lienType];
    // Only show possible statuses if a single lien type is selected
    const lienStatusOptions = lienTypeFilter.length === 1 ? _.get(lienStatusMap, lienTypeFilter[0], []) : [];

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <FilterTextField
                name={ORDER_TITLE_FILTER_TYPE.lienType}
                label="Lien Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters[ORDER_TITLE_FILTER_TYPE.lienType]}
                size="small"
                sx={{
                    minWidth: '200px',
                }}
                select
                SelectProps={{
                    renderValue: (selected) => _.map(selected, (item) => _.startCase(item)).join(', '),
                    multiple: true,
                    MenuProps: {
                        PaperProps: {
                            style: {
                                maxHeight: '400px',
                            },
                        },
                    },
                }}
            >
                {/* <MenuItem value="">All</MenuItem> */}
                {_.map(LIEN_TYPE, (lienTypeOption) => {
                    return (
                        <MenuItem key={lienTypeOption} value={lienTypeOption}>
                            <Checkbox checked={_.includes(filters[ORDER_TITLE_FILTER_TYPE.lienType], lienTypeOption)} />
                            <ListItemText primary={_.startCase(lienTypeOption)} />
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <FilterTextField
                name={ORDER_TITLE_FILTER_TYPE.lienStatus}
                label="Lien Status"
                variant="outlined"
                onChange={handleFilter}
                value={filters[ORDER_TITLE_FILTER_TYPE.lienStatus]}
                size="small"
                sx={{
                    minWidth: '200px',
                }}
                select
            >
                <MenuItem value="">All</MenuItem>
                {_.map(lienStatusOptions, (lienStatusOption) => {
                    return (
                        <MenuItem key={lienStatusOption} value={lienStatusOption}>
                            {_.startCase(lienStatusOption)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <FilterTextField
                name={ORDER_TITLE_FILTER_TYPE.clearedStatus}
                label="Cleared Status"
                variant="outlined"
                onChange={handleFilter}
                value={filters[ORDER_TITLE_FILTER_TYPE.clearedStatus]}
                size="small"
                sx={{
                    minWidth: '200px',
                }}
                select
            >
                <MenuItem value="">All</MenuItem>
                {_.map(LIEN_CLEARED_STATUS, (lienClearedOption) => {
                    return (
                        <MenuItem key={lienClearedOption} value={lienClearedOption}>
                            {_.startCase(lienClearedOption)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>
        </Stack>
    );
}

TitleListFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    defaultFilters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default TitleListFilters;
