import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputAdornment, MenuItem, Stack, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';

import FilterTextField from 'components/common/styled/FilterTextField';
import { EMAIL_EVENT_TYPE, EMAIL_LOG_FILTER_TYPE, EMAIL_TYPE } from 'helpers/constants';
// import useFilterQueryParams from '../../../common/hooks/useFilterQueryParams';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
};

export const INITIAL_EMAIL_LOG_FILTERS = {
    [EMAIL_LOG_FILTER_TYPE.search]: '',
    [EMAIL_LOG_FILTER_TYPE.userId]: '',
    [EMAIL_LOG_FILTER_TYPE.orderId]: '',
    [EMAIL_LOG_FILTER_TYPE.messageId]: '',
    [EMAIL_LOG_FILTER_TYPE.recipient]: '',
    [EMAIL_LOG_FILTER_TYPE.emailType]: '',
    [EMAIL_LOG_FILTER_TYPE.eventType]: '',
};

// TODO provide list of messages
// TODO provide list of order options
// TODO provide list of users options
// TODO provide list of recipients options

// TODO create message filter (single or multi-select?)
// TODO create user filter (single or multi-select?)
// TODO create order filter (single or multi-select?)
// TODO create recipient filter (single or multi-select?)

function EmailLogListFilters({ filters, setFilters, resetFilters, defaultFilters, hideFilters = [] }) {
    const searchInputRef = useRef();

    // NOTE - re-enable if we want to sync filters with query params
    // const [syncFilterMap, searchParams] = useFilterQueryParams(filters, defaultFilters);

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string may not be valid for some filters
        };

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const changeHandler = (event) => {
        handleFilter(event);
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 300), []);

    const setSearchInput = (value) => {
        searchInputRef.current.value = value;
    };

    const resetAllFilters = () => {
        // Reset local uncontrolled search input
        setSearchInput('');

        // Reset parent filters
        resetFilters();
    };

    useEffect(() => {
        // Stop the invocation of the debounced function after unmounting
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    // NOTE - re-enable if we want to sync filters with query params
    // useEffect(() => {
    //     if (_.keys(syncFilterMap).length > 0) {
    //         const updatedFilterMap = {
    //             ...defaultFilters,
    //             ...syncFilterMap,
    //         };

    //         // Update local filters
    //         setFilters(updatedFilterMap);

    //         // Sync uncontrolled search input ref
    //         setSearchInput(_.get(updatedFilterMap, EMAIL_LOG_FILTER_TYPE.search, ''));
    //     }
    // }, [syncFilterMap]);

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <FilterTextField
                name={EMAIL_LOG_FILTER_TYPE.search}
                label=""
                type="search"
                variant="outlined"
                placeholder="Search email logs ..."
                onChange={debouncedChangeHandler}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                inputRef={searchInputRef}
                size="small"
                sx={{
                    flexGrow: 1,
                    minWidth: '320px',
                    maxWidth: '480px',
                    ...(_.includes(hideFilters, EMAIL_LOG_FILTER_TYPE.search) && { display: 'none' }),
                }}
            />

            <FilterTextField
                name={EMAIL_LOG_FILTER_TYPE.emailType}
                label="Email Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters[EMAIL_LOG_FILTER_TYPE.emailType]}
                size="small"
                sx={{
                    minWidth: '160px',
                    ...(_.includes(hideFilters, EMAIL_LOG_FILTER_TYPE.emailType) && { display: 'none' }),
                }}
                select
                SelectProps={{
                    MenuProps,
                }}
            >
                <MenuItem value="">All</MenuItem>
                {_.map(EMAIL_TYPE, (emailTypeValue) => {
                    return (
                        <MenuItem key={emailTypeValue} value={emailTypeValue}>
                            {_.startCase(emailTypeValue)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <FilterTextField
                name={EMAIL_LOG_FILTER_TYPE.eventType}
                label="Event Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters[EMAIL_LOG_FILTER_TYPE.eventType]}
                size="small"
                sx={{
                    minWidth: '160px',
                    ...(_.includes(hideFilters, EMAIL_LOG_FILTER_TYPE.eventType) && { display: 'none' }),
                }}
                select
                SelectProps={{
                    MenuProps,
                }}
            >
                <MenuItem value="">All</MenuItem>
                {_.map(EMAIL_EVENT_TYPE, (emailEventTypeValue) => {
                    return (
                        <MenuItem key={emailEventTypeValue} value={emailEventTypeValue}>
                            {_.startCase(emailEventTypeValue)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <Box flexGrow="1" />

            <Tooltip title="Reset Filters" placement="bottom" enterDelay={300}>
                <IconButton
                    onClick={() => {
                        resetAllFilters();
                    }}
                >
                    <RestartAltIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

EmailLogListFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    defaultFilters: PropTypes.object.isRequired,
    hideFilters: PropTypes.arrayOf(PropTypes.string),
};

export default EmailLogListFilters;
