import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import AddIcon from '@mui/icons-material/Add';
// import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Divider, Fab, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useGetAllLegalEntityMembersQuery, useGetLegalEntitiesQuery } from 'api/legalEntity';
import { useGetOrderMembersQuery, useGetOrderQuery, useUpdateOrderMemberMutation } from 'api/order';
import { useGetUsersQuery } from 'api/user';
import OrderBreadcrumbs from 'components/Order/OrderDetails/components/OrderBreadcrumbs';
import { TRANSACTION_TYPE_PARTY_MAP } from 'helpers/constants';
import { findPartyByRole } from 'helpers/utils';
import CreateMemberModal from './components/CreateMemberModal';
import EditMemberModal from './components/EditMemberModal';
import MemberPartyList from './components/MemberPartyList';
import { DragOverlayMember } from './components/MemberPartyRoleListItem';

// TODO move this to a separate file if we end up using drag and drop in other places
export class ExtendedPointerSensor extends PointerSensor {
    static activators = [
        {
            eventName: 'onPointerDown',
            handler: ({ nativeEvent: event }) => {
                return shouldHandleEvent(event.target);
            },
        },
    ];
}

function shouldHandleEvent(element) {
    // Checks if the prop data-no-dnd="true" is set on the element or any of its parents
    let cur = element;

    while (cur) {
        if (cur.dataset && cur.dataset.noDnd) {
            return false;
        }
        cur = cur.parentElement;
    }

    return true;
}

// TODO a lot of mixed concerns in this component, should be broken up
function Members({ orderId }) {
    const pointerSensor = useSensor(ExtendedPointerSensor, {
        activationConstraint: {
            // distance: 10, // must drag 10 pixels before activation
            delay: 250, // must press mouse for 250ms before activation
            tolerance: 5, // can move mouse up to 5 pixels of movement tolerance for delay hold
        },
    });
    const sensors = useSensors(pointerSensor);

    // Loading baseline users & legal entities here to ensure model is available for lower-level components
    const {
        data: legalEntitiesData,
        isError: legalEntitiesError,
        isLoading: legalEntitiesLoading,
    } = useGetLegalEntitiesQuery();
    const {
        data: legalEntityMembersData,
        isError: legalEntityMembersError,
        isLoading: legalEntityMembersLoading,
    } = useGetAllLegalEntityMembersQuery();
    const { data: usersData, isError: usersError, isLoading: usersLoading } = useGetUsersQuery();
    const { data: orderData, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    const { data: membersData, isError: membersError, isLoading: membersLoading } = useGetOrderMembersQuery(orderId);
    const [updateOrderMember, { isLoading: updateMemberLoading }] = useUpdateOrderMemberMutation();

    const [activeDragId, setActiveDragId] = useState(null);
    const [orderPartyMap, setOrderPartyMap] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [editMemberId, setEditMemberId] = useState();
    const [seededRole, setSeededRole] = useState(null);

    function handleDragStart(event) {
        const { active } = event;
        const { id } = active;
        // console.log('handleDragStart', id);
        setActiveDragId(id);
    }

    const handleDragEnd = (event) => {
        const dropRole = _.get(event, 'over.id');
        // console.log('handleDragEnd', dropRole);
        if (!dropRole) {
            // return early
            setActiveDragId(null);
            return;
        }

        const activeMember = _.find(membersData, { id: activeDragId });

        if (activeMember.role !== dropRole) {
            // console.log('change to new role', dropRole);
            handleMemberUpdateRole(activeDragId, dropRole);
        }

        setActiveDragId(null);
    };

    const handleDragCancel = (event) => {
        // console.log('handleDragCancel', event);

        setActiveDragId(null);
    };

    useEffect(() => {
        setOrderPartyMap(TRANSACTION_TYPE_PARTY_MAP[orderData?.transaction_type]);
    }, [orderData]);

    const handleOpen = (seededRole = null) => {
        setSeededRole(seededRole);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setSeededRole(null);
    };

    const handleCloseEdit = () => {
        setEditMemberId();
    };

    const handleMemberUpdateRole = async (memberId, newRole) => {
        const newParty = findPartyByRole(orderData.transaction_type, newRole);

        const updateMemberPayload = {
            orderId,
            memberId,
            memberData: {
                role: newRole,
                party: newParty,
            },
        };

        const { data } = await updateOrderMember(updateMemberPayload);
    };

    if (orderLoading || usersLoading || membersLoading || legalEntitiesLoading || legalEntityMembersLoading) {
        return <div>Loading...</div>;
    }

    if (orderError) {
        return <div>Error loading order</div>;
    }

    if (usersError) {
        return <div>Error loading users</div>;
    }

    if (legalEntitiesError) {
        return <div>Error loading legal entities</div>;
    }

    if (legalEntityMembersError) {
        return <div>Error loading legal entity members</div>;
    }

    if (membersError) {
        return <div>Error loading members</div>;
    }

    // TEMP: Show drop animation only when we aren't updating a member to animate the 'cancel' action
    // Currently due to how member items are mounted and unmounted, the drop animation isn't working well during an update
    const dropAnimation = !updateMemberLoading
        ? {
              duration: 250,
              easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
          }
        : null;

    return (
        <Stack spacing={3}>
            <OrderBreadcrumbs orderId={orderId} />

            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h1">Members</Typography>

                <div>
                    <Fab
                        color="primary"
                        aria-label="add order member"
                        onClick={() => handleOpen(null)}
                        size="small"
                        sx={{
                            zIndex: 0,
                        }}
                    >
                        <AddIcon />
                    </Fab>
                </div>
            </Stack>

            <DndContext
                sensors={sensors}
                // collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
            >
                <Stack
                    direction="row"
                    spacing={6}
                    divider={
                        <Divider orientation="vertical" flexItem>
                            <SwapHorizIcon />
                        </Divider>
                    }
                >
                    {orderPartyMap &&
                        _.map(_.keys(orderPartyMap), (party) => {
                            return (
                                <MemberPartyList
                                    key={party}
                                    order={orderData}
                                    partyType={party}
                                    orderMembers={membersData}
                                    setEditMemberId={setEditMemberId}
                                    handleOpen={handleOpen}
                                />
                            );
                        })}
                </Stack>
                <DragOverlay dropAnimation={dropAnimation}>
                    {activeDragId ? <DragOverlayMember orderId={orderId} memberId={activeDragId} /> : null}
                </DragOverlay>
            </DndContext>

            <CreateMemberModal open={openDialog} handleClose={handleClose} order={orderData} seededRole={seededRole} />
            <EditMemberModal memberId={editMemberId} handleClose={handleCloseEdit} order={orderData} />
        </Stack>
    );
}

Members.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default Members;
