// import AddTaskIcon from '@mui/icons-material/AddTask';
import PropTypes from 'prop-types';

import ActionButton from '../../Action/ActionButton';
import { DataGrid } from '../../common/styled';

const ActionTableColumns = [
    {
        field: 'parcelName',
        headerName: 'Parcel',
        minWidth: 200,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        disableReorder: true,
        groupable: true,
    },
    {
        field: 'actionName',
        headerName: 'Action',
        flex: 1,
        minWidth: 200,
        valueGetter: (params) => {
            return params.row.parcelAction.friendly_name;
        },
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        disableReorder: true,
    },
    {
        field: 'actionButton',
        headerName: '',
        minWidth: 50,
        align: 'right',
        sortable: false,
        disableReorder: true,
        valueGetter: (params) => {
            return params.row.parcelAction.additional_data_schema;
        },
        renderCell: (params) => {
            const parcelId = params.row.parcelId;
            const orderId = params.row.orderId;
            const action = params.row.parcelAction;

            return <ActionButton action={action} parcelId={parcelId} orderId={orderId} />;
        },
    },
];

const ROW_HEIGHT = 67;
const HEADER_HEIGHT = 72;
const MAX_ROWS = 10;
const BORDER_BUFFER = 2;

function ActionTable({ orderParcelActionList }) {
    const tableHeight =
        BORDER_BUFFER +
        HEADER_HEIGHT +
        ROW_HEIGHT * (orderParcelActionList.length < MAX_ROWS ? orderParcelActionList.length : MAX_ROWS);
    return (
        <DataGrid
            columns={ActionTableColumns}
            rows={orderParcelActionList}
            getRowId={(row) => row.parcelAction.id}
            // Premium mui plan only
            // experimentalFeatures={{ rowGrouping: true }}
            // initialState={{
            //     rowGrouping: {
            //         model: ['parcelName'],
            //     }
            // }}
            disableColumnMenu
            disableColumnResize
            disableSelectionOnClick
            showColumnRightBorder={false}
            density="comfortable"
            hideFooter
            sx={{
                height: tableHeight,
                '& .MuiDataGrid-row > .MuiDataGrid-cell:first-of-type': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: (theme) => theme.palette.border,
                },
            }}
        />
    );
}

ActionTable.propTypes = {
    orderParcelActionList: PropTypes.array.isRequired,
};

export default ActionTable;
