import React from 'react';
import { IMaskInput } from 'react-imask';

const TaxIdFieldMask = React.forwardRef(function PhoneFieldMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="#0-0000000"
        definitions={{
          '#': /[1-9]/,
        }}
        unmask={false} // keeps the '-' character
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
});

export default TaxIdFieldMask;