import { useMemo } from 'react';

import { useGetAdminUserQuery } from '../../../api/adminUser';
import { useGetOrderQuery } from '../../../api/order';
import { ENTITY_TYPE } from '../../../helpers/constants';
import { findActionInOrder, findLienInOrder, findParcelInOrder, findPayoffInOrder } from '../../../helpers/utils';

export function grabEntities(order, entityType, entityId) {
    const entityMap = {
        primary: null,
        secondary: {},
        parent: null,
    };

    if (!entityType || !entityId) {
        return entityMap;
    }

    if (entityType === ENTITY_TYPE.parcel) {
        const parcel = findParcelInOrder(order, entityId);
        entityMap.primary = parcel;

        if (!!parcel.parent_parcel_id) {
            const parentParcel = findParcelInOrder(order, parcel.parent_parcel_id);
            entityMap.parent = parentParcel;
        }
    } else if (entityType === ENTITY_TYPE.lien) {
        const lien = findLienInOrder(order, entityId);
        entityMap.primary = lien;
    } else if (entityType === ENTITY_TYPE.payoff) {
        const payoff = findPayoffInOrder(order, entityId);
        const payoffLien = findLienInOrder(order, payoff.lien_id);
        entityMap.primary = payoff;
        entityMap.secondary[ENTITY_TYPE.lien] = payoffLien;
    } else if (entityType === ENTITY_TYPE.action) {
        const action = findActionInOrder(order, entityId);
        const actionParcel = findParcelInOrder(order, action.parcel_id);
        // TODO if action is part of 'title' parcel then also attempt to find the related lien/payoff
        entityMap.primary = action;
        entityMap.secondary[ENTITY_TYPE.parcel] = actionParcel;
    }

    return entityMap;
}

function useEventLogEntityData(eventLog) {
    // Hydrates the event log by fetching all related data
    const { data: order } = useGetOrderQuery(eventLog.order_id);
    const { data: admin } = useGetAdminUserQuery(eventLog.admin_id);
    const user = null; // TODO

    const { primary: entity, secondary: relatedEntityMap, parent: parent } = useMemo(
        () => grabEntities(order, eventLog.entity_type, eventLog.entity_id),
        [order, eventLog]
    );

    return {
        order,
        entity,
        relatedEntityMap,
        parent,
        admin,
        user,
    };
}

export default useEventLogEntityData;
