import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { evaluateConditional } from '../../../helpers/utils';

export function shouldShowTrigger(dataEntity, trigger) {
    const triggerConditions = _.get(trigger, 'conditions', []);
    let showTrigger = true;

    if (triggerConditions.length > 0) {
        _.forEach(triggerConditions, (condition) => {
            const conditionValue = _.get(dataEntity, condition.path);
            if (!evaluateConditional(conditionValue, condition.value, condition.operator)) {
                showTrigger = false;
                // Break out of loop
                return false;
            }
        });
    }

    return showTrigger;
}

export function getTriggers(dataEntity) {
    const { trigger_order, triggers } = _.get(dataEntity, 'ui_addon_schema', {
        trigger_order: [],
        triggers: {},
    });

    const activeTriggers = [];
    _.forEach(triggers, (trigger, triggerKey) => {
        if (shouldShowTrigger(dataEntity, trigger)) {
            activeTriggers.push(triggerKey);
        }
    });

    return {
        triggers,
        triggerOrder: trigger_order,
        activeTriggers,
    };
}

export function useEvalTriggerConditions({ dataEntity, trigger }) {
    const showTrigger = useMemo(() => shouldShowTrigger(dataEntity, trigger), [dataEntity, trigger]);

    return showTrigger;
}

useEvalTriggerConditions.propTypes = {
    dataEntity: PropTypes.object.isRequired,
    trigger: PropTypes.object.isRequired,
};

export function useTriggers(dataEntity) {
    const { triggers, triggerOrder, activeTriggers } = useMemo(() => getTriggers(dataEntity), [dataEntity]);

    return { triggers, triggerOrder, activeTriggers };
}

useTriggers.propTypes = {
    dataEntity: PropTypes.object.isRequired,
};

export default useTriggers;
