import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SNACKBAR_SEVERITY } from '../../../helpers/constants';
import { closeSnackbar, setSnackbar } from '../../../stores/snackbar';

function useSnackbar() {
    const snackbarState = useSelector((state) => state.snackbar); // { message, severity, duration }
    const dispatch = useDispatch();

    const setSnackbarLocal = useCallback((message, severity = SNACKBAR_SEVERITY.success, duration = 6000) => {
        dispatch(setSnackbar({ message, severity, duration }));
    }, []);

    const closeSnackbarLocal = useCallback(() => {
        dispatch(closeSnackbar());
    }, []);

    return {
        state: snackbarState,
        setSnackbar: setSnackbarLocal,
        closeSnackbar: closeSnackbarLocal,
    };
}

export default useSnackbar;
