import { Box, Divider, MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useCreateLienMutation } from '../../api/lien';
import { LIEN_TYPE } from '../../helpers/constants';
import DollarFieldMask from '../common/mask/DollarFieldMask';
import { LoadingButton } from '../common/styled';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
};

const initialLienState = {
    // name: '',
    // amount: '',
    type: LIEN_TYPE.generic,
    // description: '',
    additional_data: {},
};

const lienFormFieldTypes = {
    amount: 'amount',
    description: 'description',
    lender: 'lender',
    lienDate: 'lienDate',
};

const defaultLienFormFields = [lienFormFieldTypes.description];

const defaultLienForm = {
    fields: [...defaultLienFormFields],
    required: [],
};

const lienTypeForm = {
    [LIEN_TYPE.generic]: {
        fields: [...defaultLienFormFields],
        required: [...defaultLienFormFields],
    },
    [LIEN_TYPE.mortgage]: {
        fields: [
            lienFormFieldTypes.lienDate,
            lienFormFieldTypes.amount,
            lienFormFieldTypes.lender,
            ...defaultLienFormFields,
        ],
        required: [],
    },
    [LIEN_TYPE.tax_suit]: {
        fields: [lienFormFieldTypes.amount, ...defaultLienFormFields],
        required: [],
    },
    [LIEN_TYPE.HELOC]: {
        fields: [lienFormFieldTypes.lienDate, lienFormFieldTypes.amount, lienFormFieldTypes.lender],
        required: [],
    },
};

const LienFormField = ({
    order,
    lienData,
    fieldType,
    lienType,
    handleChangeData,
    handleChangeAdditionalData,
    handleChangeDate,
    required,
}) => {
    switch (fieldType) {
        case lienFormFieldTypes.amount:
            return (
                <TextField
                    name="amount"
                    label="Amount"
                    value={lienData.amount}
                    InputProps={{
                        inputComponent: DollarFieldMask,
                    }}
                    onFocus={(event) => {
                        // NOTE: timeout to get around various browser event issues
                        const target = event.target;
                        setTimeout(() => target.select(), 0);
                    }}
                    onChange={handleChangeData}
                    required={required}
                />
            );

        case lienFormFieldTypes.description:
            return (
                <TextField
                    name="description"
                    label="Description"
                    multiline
                    value={lienData.description}
                    onChange={handleChangeData}
                    required={required}
                />
            );

        case lienFormFieldTypes.lender:
            return (
                <TextField
                    name="lender"
                    label="Lender"
                    value={lienData.additional_data.lender || ''}
                    onChange={handleChangeAdditionalData}
                    required={required}
                />
            );

        case lienFormFieldTypes.lienDate:
            return (
                <DatePicker
                    autoOk
                    label="Lien Date"
                    openTo="day"
                    inputFormat="MM/dd/yyyy"
                    value={lienData.additional_data.lien_date ? parseISO(lienData.additional_data.lien_date) : null}
                    inputVariant="outlined"
                    onChange={(date) => {
                        // NOTE: not strict comparison due to issues with date error response object
                        if (date && date != 'Invalid Date') {
                            handleChangeDate(format(date, 'yyyy-MM-dd'));
                        } else {
                            handleChangeDate(null);
                        }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    clearable
                    required={required}
                />
            );

        default:
            return null;
    }
};

LienFormField.propTypes = {
    order: PropTypes.object.isRequired,
    lienData: PropTypes.object.isRequired,
    fieldType: PropTypes.string.isRequired,
    lienType: PropTypes.string.isRequired,
    handleChangeData: PropTypes.func.isRequired,
    handleChangeAdditionalData: PropTypes.func.isRequired,
};

const NewLienForm = ({ order, handleSubmit }) => {
    const [createLien, { isLoading }] = useCreateLienMutation();

    const [lienData, setLienData] = useState({ ...initialLienState });

    const handleChangeType = ({ target }) => {
        // Reset lien data on type change
        console.log('Changing lien type', target.value);
        console.log('initialLienState', initialLienState);
        setLienData({
            ...initialLienState,
            [target.name]: target.value,
        });
    };

    const handleChange = ({ target }) => {
        setLienData({
            ...lienData,
            [target.name]: target.value,
        });
    };

    const handleChangeAdditionalData = ({ target }) => {
        setLienData({
            ...lienData,
            additional_data: {
                ...lienData.additional_data,
                [target.name]: target.value,
            },
        });
    };

    const handleChangeDate = (dateString) => {
        setLienData({
            ...lienData,
            additional_data: {
                ...lienData.additional_data,
                lien_date: dateString,
            },
        });
    };

    const handleCreateLien = (event) => {
        event.preventDefault();

        const submitted = submitCreateLien(lienData);
        if (submitted) {
            handleSubmit();
        }
    };

    const submitCreateLien = async (lienData) => {
        const createLienData = { ...lienData };

        const { data } = await createLien({
            orderId: order.id,
            lienData: createLienData,
        });

        if (data) {
            return true;
        } else {
            console.warn(`Failed to create lien for order ${order.id}`);
            return false;
        }
    };

    const lienTypeFormObject = _.get(lienTypeForm, lienData.type, defaultLienForm);
    const lienTypeFields = lienTypeFormObject.fields;
    const lienTypeRequiredFields = lienTypeFormObject.required;

    return (
        <form onSubmit={handleCreateLien}>
            <Stack spacing={4}>
                <Stack spacing={4}>
                    <TextField
                        name="type"
                        label="Lien Type"
                        value={lienData.type}
                        onChange={handleChangeType}
                        SelectProps={{
                            MenuProps,
                        }}
                        select
                        required
                    >
                        {_.map(LIEN_TYPE, (lienType, lienTypeKey) => {
                            return (
                                <MenuItem key={lienTypeKey} value={lienType}>
                                    {_.startCase(lienType)}
                                </MenuItem>
                            );
                        })}
                    </TextField>

                    <Divider />

                    <Stack spacing={2}>
                        {_.map(lienTypeFields, (fieldType) => {
                            return (
                                <LienFormField
                                    key={fieldType}
                                    order={order}
                                    lienData={lienData}
                                    fieldType={fieldType}
                                    lienType={lienData.type}
                                    handleChangeData={handleChange}
                                    handleChangeAdditionalData={handleChangeAdditionalData}
                                    handleChangeDate={handleChangeDate}
                                    required={_.includes(lienTypeRequiredFields, fieldType)}
                                />
                            );
                        })}
                    </Stack>
                </Stack>

                <Box>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%' }}
                    >
                        <Box />
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={isLoading}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                        >
                            Create
                        </LoadingButton>
                    </Stack>
                </Box>
            </Stack>
        </form>
    );
};

NewLienForm.propTypes = {
    order: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default NewLienForm;
