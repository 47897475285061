import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Popover, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const CreateEntityPopover = ({ open, handleClose, anchorEl, handleOpenOrderModal, handleOpenContactModal }) => {
    return (
        <Popover
            id="create-entity-popover"
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: -16,
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    sx: { width: '320px', maxWidth: '480px', borderRadius: (theme) => theme.spacing(1) },
                },
            }}
        >
            <Stack direction="column" spacing={0} divider={<Divider />} sx={{ width: '100%' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}
                >
                    <Typography variant="h5">Add New</Typography>
                </Stack>

                {false && (
                    <List disablePadding>
                        <ListItemButton onClick={() => handleOpenOrderModal(true)}>
                            <ListItemIcon sx={{ color: 'order.main' }}>
                                <AddHomeWorkIcon />
                            </ListItemIcon>

                            <ListItemText primary="Order" />
                        </ListItemButton>

                        <ListItemButton onClick={() => handleOpenContactModal(true)}>
                            <ListItemIcon sx={{ color: 'contact.main' }}>
                                <PersonAddIcon />
                            </ListItemIcon>

                            <ListItemText primary="Contact" />
                        </ListItemButton>
                    </List>
                )}

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-around"
                    spacing={2}
                    sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}
                >
                    <Stack
                        alignItems="center"
                        spacing={1}
                        onClick={() => handleOpenOrderModal(true)}
                        sx={{
                            cursor: 'pointer',
                            borderRadius: '16px',
                            padding: '8px 24px',
                            '&:hover': {
                                bgcolor: 'background.darkGray',
                            },
                        }}
                    >
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                bgcolor: 'order.main',
                                color: 'order.light',
                                width: '48px',
                                height: '48px',
                                borderRadius: '24px',
                            }}
                        >
                            <AddHomeWorkIcon />
                        </Stack>

                        <Typography variant="body1">Order</Typography>
                    </Stack>

                    <Stack
                        alignItems="center"
                        spacing={1}
                        onClick={() => handleOpenContactModal(true)}
                        sx={{
                            cursor: 'pointer',
                            borderRadius: '16px',
                            padding: '8px 24px',
                            '&:hover': {
                                bgcolor: 'background.darkGray',
                            },
                        }}
                    >
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                bgcolor: 'contact.main',
                                color: 'contact.light',
                                width: '48px',
                                height: '48px',
                                borderRadius: '24px',
                            }}
                        >
                            <PersonAddIcon />
                        </Stack>

                        <Typography variant="body1">Contact</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Popover>
    );
};

CreateEntityPopover.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleOpenOrderModal: PropTypes.func.isRequired,
    handleOpenContactModal: PropTypes.func.isRequired,
    anchorEl: PropTypes.object,
};

export default CreateEntityPopover;
