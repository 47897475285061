import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { IconButton, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { differenceInBusinessDays, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUpdatePayoffMutation } from '../../../../api/payoff';
import { ACTION_STATUS, PARCEL_STATUS, PAYOFF_STATUS } from '../../../../helpers/constants';
import { USDollar, formatISODate } from '../../../../helpers/utils';

import { useGetLienMembersQuery } from '../../../../api/lien';
import { useGetOrderMembersQuery } from '../../../../api/order';
import { Button, DataGrid } from '../../../common/styled';

const LienMemberListItemField = ({ lienMember, orderMembers }) => {
    const orderMember = useMemo(() => {
        return _.find(orderMembers, { id: lienMember.order_member_id });
    }, [lienMember, orderMembers]);

    const name = orderMember ? _.get(orderMember, 'member.name') : lienMember.name;

    return <Typography variant="caption">{name}</Typography>;
};

const LienMemberListField = ({ lienId, orderId }) => {
    const { data: orderMembers, isError: membersError, isLoading: membersLoading } = useGetOrderMembersQuery(orderId);
    const { data: lienMembers, isError: lienMembersError, isLoading: lienMembersLoading } = useGetLienMembersQuery(
        lienId
    );

    if (membersLoading || lienMembersLoading) {
        return null;
    }

    return (
        <Stack spacing={1}>
            {_.map(lienMembers, (lienMember) => (
                <LienMemberListItemField key={lienMember.id} lienMember={lienMember} orderMembers={orderMembers} />
            ))}
        </Stack>
    );
};

const DescriptionField = ({ description }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'description-popover' : undefined;

    return (
        <Stack direction="row" spacing={0} alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
            <Typography variant="caption" noWrap title={description}>
                Description:{' '}
            </Typography>
            <IconButton onClick={handleClick} size="small">
                <TextSnippetIcon fontSize="small" />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2, whiteSpace: 'pre-wrap' }}>{description}</Typography>
            </Popover>
        </Stack>
    );
};

const GenericField = ({ label, value }) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography variant="caption">{label}: </Typography>
            <Typography fontSize="12px">{value}</Typography>
        </Stack>
    );
};

const RestartPayoffButton = ({ payoff }) => {
    const [updatePayoff, { isLoading: updatePayoffLoading }] = useUpdatePayoffMutation();
    const [loading, setLoading] = useState(false);

    const handlePayoffUpdate = async (updatedPayoffData) => {
        const updatePayoffPayload = {
            payoffId: payoff.id,
            payoffData: updatedPayoffData,
        };

        const { data } = await updatePayoff(updatePayoffPayload);
        return data;
    };

    const handleRestartPayoff = () => {
        setLoading(true);

        const updates = _.get(payoff.ui_addon_schema, 'triggers.reorder.payload');

        handlePayoffUpdate(updates).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Tooltip title="Restart Payoff" placement="right" enterDelay={300}>
            <span style={{ display: 'inline-block' }}>
                <IconButton onClick={handleRestartPayoff} disabled={loading}>
                    <RestartAltIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
};

const ClearedIconField = ({ lien }) => {
    /*
        if cleared & no payoff - color is green not matter what
        if cleared & payoff - color depends on payoff good_through_date vs required_complete_date
        if not cleared & no payoff - color depends on required_complete_date vs today
        if not cleared & payoff
            - if payoff not started - color depends on required_complete_date vs today
            - if payoff started - color depends on required_complete_date vs eta_date 
    */
    const { cleared, titleParcel, payoff } = lien;
    const today = new Date();
    const required_complete_date = titleParcel ? parseISO(titleParcel.required_complete_date) : null;

    const determineIconColor = (days_diff, default_color = 'default') => {
        if (days_diff <= 0) {
            return 'error';
        } else if (days_diff <= 5) {
            return 'warning';
        }

        return default_color;
    };

    if (cleared) {
        // cleared
        let color = 'primary';
        if (payoff) {
            // cleared and payoff exists so compare to good_through_date
            // TODO use today instead of  required_complete_date if today is after required_complete_date
            // but only if parent parcel is not complete
            const days_diff = differenceInBusinessDays(parseISO(payoff.good_through_date), required_complete_date);
            color = determineIconColor(days_diff, 'primary');
        }

        if (color !== 'primary') {
            return (
                <Tooltip title="Good-through date is near the required complete date" placement="left" enterDelay={300}>
                    <CheckIcon
                        color={color}
                        sx={{
                            ...(color === 'warning'
                                ? {
                                      color: (theme) => theme.palette.warning.main,
                                  }
                                : {}),
                        }}
                    />
                </Tooltip>
            );
        }

        return <CheckIcon color={color} />;
    }

    // not cleared
    if (payoff) {
        // not cleared and payoff exists
        if (payoff.status === PARCEL_STATUS.not_started) {
            // payoff not started
            const days_diff = differenceInBusinessDays(required_complete_date, today);
            return <ErrorIcon color={determineIconColor(days_diff)} />;
        } else {
            // payoff in progress
            const days_diff = differenceInBusinessDays(
                required_complete_date,
                parseISO(payoff.additional_data.payoff_eta)
            );
            const color = determineIconColor(days_diff);
            const tooltipText = color === 'warning' ? 'ETA is near the required complete date' : '';
            return (
                <Tooltip title={tooltipText} placement="left" enterDelay={300}>
                    <WatchLaterIcon
                        color={color}
                        sx={{
                            ...(color === 'warning'
                                ? {
                                      color: (theme) => theme.palette.warning.main,
                                  }
                                : {}),
                        }}
                    />
                </Tooltip>
            );
        }
    }

    // not cleared and no payoff
    const days_diff = differenceInBusinessDays(required_complete_date, today);

    if (lien.status === PARCEL_STATUS.not_started) {
        // lien not started
        return <ErrorIcon color={determineIconColor(days_diff)} />;
    } else {
        // lien in progress
        return <WatchLaterIcon color={determineIconColor(days_diff)} />;
    }
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
    },
    {
        field: 'type',
        headerName: 'Lien Type',
        width: 200,
        renderCell: (params) => {
            return <Link to={`/order/${params.row.order_id}/title/${params.row.id}`}>{_.startCase(params.value)}</Link>;
            // return (
            //     <Link
            //         onClick={() => params.row.setEditLienId(params.row.id)}
            //         underline="hover"
            //         sx={{
            //             color: (theme) => theme.palette.text.link,
            //             cursor: 'pointer',
            //         }}
            //     >
            //         <Typography
            //             variant="body2"
            //             sx={{
            //                 color: 'inherit',
            //             }}
            //         >
            //             {_.startCase(params.value)}
            //         </Typography>
            //     </Link>
            // );
        },
    },
    {
        field: 'status',
        headerName: 'Lien Status',
        width: 200,
        valueFormatter: ({ value }) => {
            return _.startCase(value);
        },
    },
    {
        field: 'additional_data',
        headerName: 'Lien Details',
        width: 150,
        sortable: false,
        renderCell: (params) => {
            return (
                <Stack spacing={0} sx={{ width: '100%' }}>
                    {params.row.amount && <GenericField label="Amount" value={USDollar.format(params.row.amount)} />}

                    {params.row.description && <DescriptionField description={params.row.description} />}

                    {_.get(params.row, 'additional_data.lien_date') && (
                        <GenericField label="Date" value={formatISODate(params.row.additional_data.lien_date)} />
                    )}
                </Stack>
            );
        },
    },
    {
        field: 'members',
        headerName: 'Lien Members',
        width: 150,
        sortable: false,
        renderCell: (params) => {
            const lienId = params.row.id;
            const orderId = params.row.order_id;

            return <LienMemberListField lienId={lienId} orderId={orderId} />;
        },
    },
    {
        field: 'payoff.status',
        headerName: 'Payoff Status',
        width: 150,
        align: 'left',
        headerAlign: 'left',
        valueGetter: (params) => {
            return _.get(params.row, 'payoff.status');
        },
        valueFormatter: ({ value }) => {
            return _.startCase(value);
        },
    },
    {
        field: 'payoff.additional_data',
        headerName: 'Payoff Details',
        width: 180,
        sortable: false,
        renderCell: (params) => {
            const payoff = _.get(params.row, 'payoff');
            return (
                <Stack spacing={0} sx={{ width: '100%' }}>
                    {_.get(payoff, 'ordered_date') && payoff.status !== PAYOFF_STATUS.complete && (
                        <GenericField label="Ordered" value={formatISODate(payoff.ordered_date)} />
                    )}

                    {_.get(payoff, 'additional_data.payoff_eta') && payoff.status !== PAYOFF_STATUS.complete && (
                        <GenericField label="ETA" value={formatISODate(payoff.additional_data.payoff_eta)} />
                    )}

                    {_.get(payoff, 'received_date') && payoff.status === PAYOFF_STATUS.complete && (
                        <GenericField label="Received" value={formatISODate(payoff.received_date)} />
                    )}

                    {_.get(payoff, 'good_through_date') && payoff.status === PAYOFF_STATUS.complete && (
                        <GenericField label="Good Through" value={formatISODate(payoff.good_through_date)} />
                    )}
                </Stack>
            );
        },
    },
    {
        field: 'actions',
        headerName: 'Next Action',
        minWidth: 200,
        flex: 1,
        // align: 'right',
        // headerAlign: 'right',
        valueGetter: (params) => {
            // Try to grab next active action from lien
            let nextActionId;
            if (params.row.actions) {
                const nextAction = _.find(params.row.actions, { status: ACTION_STATUS.not_started });
                if (nextAction) {
                    nextActionId = nextAction.action_id;
                }
            }

            // If no active actions in lien, check payoff
            const payoffActions = _.get(params.row, 'payoff.actions', []);
            if (!nextActionId && payoffActions.length > 0) {
                const nextAction = _.find(payoffActions, { status: ACTION_STATUS.not_started });
                if (nextAction) {
                    nextActionId = nextAction.action_id;
                }
            }

            const titleParcel = _.get(params.row, 'titleParcel');
            const action = _.get(titleParcel, `action_map.${nextActionId}`);

            return action;
        },
        renderCell: (params) => {
            // If outstanding actions exist
            if (params.value) {
                return (
                    <Button
                        size="small"
                        variant="outlined"
                        elevation={0}
                        color="default"
                        onClick={() => params.row.updateActiveActionId(params.value.id)}
                        endIcon={<NavigateNextIcon />}
                    >
                        {params.value.friendly_name}
                    </Button>
                );
            }

            // If lien is cleared and has completed payoff, allow payoff to be restarted
            if (_.get(params.row, 'payoff.status') === PAYOFF_STATUS.complete) {
                return <RestartPayoffButton payoff={params.row.payoff} />;
            }

            return <></>;
        },
    },
    {
        field: 'cleared',
        headerName: 'Cleared',
        width: 100,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
            return <ClearedIconField lien={params.row} />;
        },
    },
];

function TitleParcelList({ titleIssues, loading }) {
    return (
        <div>
            <DataGrid
                rows={titleIssues}
                columns={columns}
                loading={loading}
                density="comfortable"
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnReorder
                autoHeight
                hideFooter
            />
        </div>
    );
}

TitleParcelList.propTypes = {
    titleIssues: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default TitleParcelList;
