import { Popover } from '@mui/material';
import PropTypes from 'prop-types';

import Notification from './Notification';

const NotificationPopover = ({ open, handleClose, anchorEl, unreadCount }) => {
    return (
        <Popover
            id="notification-popover"
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: -16,
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    sx: { width: '480px', maxWidth: '480px', borderRadius: (theme) => theme.spacing(1) },
                },
            }}
        >
            <Notification handleClose={handleClose} unreadCount={unreadCount} />
        </Popover>
    );
};

NotificationPopover.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.object,
    unreadCount: PropTypes.number,
};

export default NotificationPopover;
