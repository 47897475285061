import { useSelector } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import AppLayout from './AppLayout';
import ScrollToTop from './ScrollToTop';
import {
    ActionListPage, ClosingListPage,
    ContactListPage,
    ContactPage,
    Dashboard, FundingListPage, Login,
    Order, OrderListPage, PayoffListPage,
    SchedulingListPage,
    TitleListPage
} from './pages';


function RequireAuth({ children }) {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    let location = useLocation();
  
    if (!isAuthenticated) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} />;
    }
  
    return <AppLayout children={children} />;
}

function AppRouter() {
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={
                        <RequireAuth>
                            <Dashboard />
                        </RequireAuth>
                    } />

                    <Route path="/orders" element={
                        <RequireAuth>
                            <OrderListPage />
                        </RequireAuth>
                    } />

                    <Route path="/title" element={
                        <RequireAuth>
                            <TitleListPage />
                        </RequireAuth>
                    } />

                    <Route path="/payoffs" element={
                        <RequireAuth>
                            <PayoffListPage />
                        </RequireAuth>
                    } />

                    <Route path="/scheduling" element={
                        <RequireAuth>
                            <SchedulingListPage />
                        </RequireAuth>
                    } />

                    <Route path="/closing" element={
                        <RequireAuth>
                            <ClosingListPage />
                        </RequireAuth>
                    } />

                    <Route path="/funding" element={
                        <RequireAuth>
                            <FundingListPage />
                        </RequireAuth>
                    } />

                    <Route path="/actions" element={
                        <RequireAuth>
                            <ActionListPage />
                        </RequireAuth>
                    } />

                    <Route path="/order/:order_id/*" element={
                        <RequireAuth>
                            <Order />
                        </RequireAuth>
                    } />

                    <Route path="/contacts" element={
                        <RequireAuth>
                            <ContactListPage />
                        </RequireAuth>
                    } />

                    <Route path="/contact/:contact_type/:contact_id/*" element={
                        <RequireAuth>
                            <ContactPage />
                        </RequireAuth>
                    } />

                </Routes>
            </ScrollToTop>
        </Router>
    );
}

export default AppRouter;
