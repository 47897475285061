import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Breadcrumbs, Link as MUILink, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function AssignmentBreadcrumbs({ assignment, assignmentParent }) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <MUILink
                component={Link}
                underline="hover"
                to={`/order/${assignment.order_id}/assignments`}
                variant="body1"
                color="text.secondary"
                fontWeight="400"
            >
                <Stack direction="row" alignItems="center" spacing={1}>
                    <KeyboardBackspaceOutlinedIcon fontSize="small" />
                    <span>{assignmentParent.name}</span>
                </Stack>
            </MUILink>
        </Breadcrumbs>
    );
}

AssignmentBreadcrumbs.propTypes = {
    assignment: PropTypes.object.isRequired,
    assignmentParent: PropTypes.object.isRequired,
};

export default AssignmentBreadcrumbs;
