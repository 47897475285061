import { Link as MUILink } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { formatISODate } from '../../../../helpers/utils';
import { DataGrid } from '../../../common/styled';

export const ACCESS_LOG_LIST_GRID_COLUMN_TYPE = {
    user: 'user',
    eventDomain: 'event_domain',
    eventType: 'event_type',
    eventDatetime: 'event_datetime',
};

const UserColumn = ({ user }) => {
    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/contact/user/${user.id}`}
        >
            {user.name}
        </MUILink>
    );
};

const generateAccessLogListColumns = (hideColumns) => {
    return [
        {
            field: ACCESS_LOG_LIST_GRID_COLUMN_TYPE.user,
            headerName: 'Contact',
            minWidth: 160,
            hide: _.includes(hideColumns, ACCESS_LOG_LIST_GRID_COLUMN_TYPE.user),
            // TODO sort?
            renderCell: (params) => <UserColumn user={params.value} />,
        },
        {
            field: ACCESS_LOG_LIST_GRID_COLUMN_TYPE.eventDomain,
            headerName: 'Domain',
            minWidth: 160,
            hide: _.includes(hideColumns, ACCESS_LOG_LIST_GRID_COLUMN_TYPE.eventDomain),
            valueFormatter: ({ value }) => _.startCase(value),
        },
        {
            field: ACCESS_LOG_LIST_GRID_COLUMN_TYPE.eventType,
            headerName: _.startCase(ACCESS_LOG_LIST_GRID_COLUMN_TYPE.eventType),
            minWidth: 160,
            hide: _.includes(hideColumns, ACCESS_LOG_LIST_GRID_COLUMN_TYPE.eventType),
            valueFormatter: ({ value }) => _.startCase(value),
        },
        {
            field: ACCESS_LOG_LIST_GRID_COLUMN_TYPE.eventDatetime,
            headerName: 'Date/Time',
            type: 'date',
            minWidth: 150,
            hide: _.includes(hideColumns, ACCESS_LOG_LIST_GRID_COLUMN_TYPE.eventDatetime),
            valueFormatter: ({ value }) => formatISODate(value, 'P p'),
        },
    ];
};

function AccessLogListDataGrid({ accessLogs, loading, hideColumns = [] }) {
    const [pageSize, setPageSize] = useState(20);

    const accessLogListColumns = useMemo(() => generateAccessLogListColumns(hideColumns), [hideColumns]);

    // TODO look into grouping rows around message_id - requires premium mui license for grouping

    return (
        <DataGrid
            columns={accessLogListColumns}
            rows={accessLogs}
            loading={loading}
            getRowId={(row) => row.id}
            autoHeight
            disableColumnMenu
            disableColumnResize
            disableSelectionOnClick
            density="comfortable"
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
        />
    );
}

AccessLogListDataGrid.propTypes = {
    accessLogs: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    hideColumns: PropTypes.arrayOf(PropTypes.string),
};

export default AccessLogListDataGrid;
