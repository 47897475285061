import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, ListSubheader, MenuItem, Stack, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';

import { useGetOrderMembersQuery } from 'api/order';
import { generateOrderMemberRoleMap, getOrderMemberName } from 'components/Order/Members/utils';
import FilterTextField from 'components/common/styled/FilterTextField';
import { DOCUMENT_FILTER_TYPE, DOCUMENT_TYPE } from 'helpers/constants';
import { formatDocumentType } from 'helpers/utils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
};

export const INITIAL_DOCUMENT_FILTERS = {
    [DOCUMENT_FILTER_TYPE.search]: '',
    [DOCUMENT_FILTER_TYPE.documentType]: '',
    [DOCUMENT_FILTER_TYPE.access]: '',
};

function DocumentAccessFilterField({ orderId, value, name, onChange }) {
    const { data: orderMemberList, isError: membersError, isLoading: membersLoading } = useGetOrderMembersQuery(
        orderId
    );

    const orderMemberRoleMap = useMemo(() => generateOrderMemberRoleMap(orderMemberList), [orderMemberList]);

    if (!orderMemberList || orderMemberList.length === 0) {
        return null;
    }

    return (
        <FilterTextField
            name={name}
            label="Document Access"
            variant="outlined"
            onChange={onChange}
            value={value}
            size="small"
            sx={{
                minWidth: '160px',
            }}
            select
            SelectProps={{
                MenuProps,
            }}
        >
            <MenuItem value="">All</MenuItem>
            {_.map(orderMemberRoleMap, (roleMemberList, role) => {
                return [
                    <ListSubheader>{_.startCase(role)}</ListSubheader>,
                    _.map(roleMemberList, (orderMember) => {
                        return (
                            <MenuItem key={orderMember.id} value={orderMember.id}>
                                {getOrderMemberName(orderMember)}
                            </MenuItem>
                        );
                    }),
                ];
            })}
        </FilterTextField>
    );
}

function DocumentListFilters({ orderId, filters, setFilters, resetFilters }) {
    const searchInputRef = useRef();

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string may not be valid for some filters
        };

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const changeHandler = (event) => {
        handleFilter(event);
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 300), []);

    const setSearchInput = (value) => {
        searchInputRef.current.value = value;
    };

    const resetAllFilters = () => {
        // Reset local uncontrolled search input
        setSearchInput('');

        // Reset parent filters
        resetFilters();
    };

    useEffect(() => {
        // Stop the invocation of the debounced function after unmounting
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    // TODO look into completing useFilterQueryParams hook to handle syncing filters with query params

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <FilterTextField
                name={DOCUMENT_FILTER_TYPE.search}
                label=""
                type="search"
                variant="outlined"
                placeholder="Search documents ..."
                onChange={debouncedChangeHandler}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                inputRef={searchInputRef}
                size="small"
                sx={{
                    flexGrow: 1,
                    minWidth: '320px',
                }}
            />

            <FilterTextField
                name={DOCUMENT_FILTER_TYPE.documentType}
                label="Document Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters[DOCUMENT_FILTER_TYPE.documentType]}
                size="small"
                sx={{
                    minWidth: '160px',
                }}
                select
                SelectProps={{
                    MenuProps,
                }}
            >
                <MenuItem value="">All</MenuItem>
                {_.map(DOCUMENT_TYPE, (documentTypeValue) => {
                    return (
                        <MenuItem key={documentTypeValue} value={documentTypeValue}>
                            {formatDocumentType(documentTypeValue)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <DocumentAccessFilterField
                orderId={orderId}
                name={DOCUMENT_FILTER_TYPE.access}
                value={filters[DOCUMENT_FILTER_TYPE.access]}
                onChange={handleFilter}
            />

            <Tooltip title="Reset Filters" placement="bottom" enterDelay={300}>
                <IconButton
                    onClick={() => {
                        resetAllFilters();
                    }}
                >
                    <RestartAltIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

DocumentListFilters.propTypes = {
    orderId: PropTypes.string.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
};

export default DocumentListFilters;
