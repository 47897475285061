import _ from 'lodash';

import { evaluateConditional } from '../../helpers/utils';
import { LoadingButton } from '../common/styled';

// TODO very rudimentary - improve/flesh out
const UITrigger = ({ dataEntity, uiTrigger, saveEntity, isSaving, ...rest }) => {
    // Handle conditions
    const uiTriggerConditions = _.get(uiTrigger, 'conditions', []);
    if (uiTriggerConditions.length > 0) {
        let showTrigger = true;
        _.forEach(uiTriggerConditions, (uiCondition) => {
            const conditionValue = _.get(dataEntity, uiCondition.path);
            if (!evaluateConditional(conditionValue, uiCondition.value, uiCondition.operator)) {
                showTrigger = false;
                // Break out of loop
                return false;
            }
        });

        if (!showTrigger) {
            return null;
        }
    }

    const handleUpdate = (event) => {
        event.preventDefault();

        const entityCopy = JSON.parse(JSON.stringify(dataEntity));
        // console.log(uiTrigger)
        _.forEach(uiTrigger.payload, (update_value, update_path) => {
            console.log(update_path);
            if (update_path === 'status') {
                // TODO hacky - need to fix
                _.set(entityCopy, 'current_status.status', update_value);
            } else {
                _.set(entityCopy, update_path, update_value);
            }
        });
        // console.log(entityCopy);
        saveEntity(dataEntity, entityCopy);
    };

    return (
        <LoadingButton
            variant="contained"
            // color="primary"
            // fontSize="large"
            loading={isSaving}
            disableElevation
            disabled={isSaving}
            onClick={handleUpdate}
            {...rest}
        >
            {uiTrigger.text}
        </LoadingButton>
    );
};

const UITriggers = ({ dataEntity, saveEntity, isSaving, classes, ...rest }) => {
    const uiAddonSchema = _.get(dataEntity, 'ui_addon_schema');

    if (!uiAddonSchema) {
        return null;
    }

    return (
        <div>
            {_.map(_.get(uiAddonSchema, 'trigger_order', []), (uiTriggerName) => {
                const uiTriggerData = _.get(uiAddonSchema, `triggers.${uiTriggerName}`);

                if (!uiTriggerData) {
                    console.error('A ui trigger was named in the order list but not defined');
                    return null;
                }

                return (
                    <UITrigger
                        key={uiTriggerName}
                        dataEntity={dataEntity}
                        uiTrigger={uiTriggerData}
                        saveEntity={saveEntity}
                        isSaving={isSaving}
                        {...rest}
                    />
                );
            })}
        </div>
    );
};

export default UITriggers;
