import { Avatar, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useGetAllLegalEntityMembersQuery, useGetLegalEntitiesQuery } from '../../api/legalEntity';
import { useGetOrdersQuery } from '../../api/order';
import { useGetUsersQuery } from '../../api/user';

import { DATE_RANGE_MAP, ORDER_FILTER_TYPE } from '../../helpers/constants';
import { calculateDateRangePreset, filterOrders } from '../../helpers/utils';
import WeekTimelineFilters from '../common/WeekTimelineFilters';
import WeekTimelineGrid from '../common/WeekTimelineGrid';
import ClosingListGridItemOrder from './components/ClosingListGridItemOrder';

const DEFAULT_FILTER_STATE = {
    // TODO filter on
    // tentative/confirmed close date
    [ORDER_FILTER_TYPE.transactionType]: '',
    [ORDER_FILTER_TYPE.orderType]: [],
    [ORDER_FILTER_TYPE.closeDateRange]: [null, null],
};

function generateDefaultFilterState() {
    return {
        ...DEFAULT_FILTER_STATE,
        [ORDER_FILTER_TYPE.closeDateRange]: calculateDateRangePreset(DATE_RANGE_MAP.this_week),
    };
}

function ClosingList() {
    // Loading baseline users & legal entities here to ensure model is available for lower-level components
    const { data: legalEntitiesData } = useGetLegalEntitiesQuery();
    const { data: legalEntityMembersData } = useGetAllLegalEntityMembersQuery();
    const { data: usersData } = useGetUsersQuery();
    const { data: orders, isError: orderError, isLoading: orderLoading } = useGetOrdersQuery();

    const [filters, setFilters] = useState({
        ...generateDefaultFilterState(),
    });

    const filteredOrders = useMemo(() => filterOrders(orders, filters), [orders, filters]);

    // TODO handle socket change

    const resetFilters = () => {
        setFilters(generateDefaultFilterState());
    };

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
                padding: (theme) => theme.spacing(3),
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h1">Closings</Typography>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar
                        sx={{
                            backgroundColor: (theme) =>
                                filteredOrders.length > 0
                                    ? theme.palette.primary.main
                                    : theme.palette.background.default,
                            color: (theme) =>
                                filteredOrders.length > 0
                                    ? theme.palette.primary.contrastText
                                    : theme.palette.text.primary,
                            height: (theme) => theme.spacing(4),
                            width: (theme) => theme.spacing(4),
                            fontSize: (theme) => theme.spacing(2),
                            fontWeight: 400,
                        }}
                    >
                        {filteredOrders.length}
                    </Avatar>
                    <Typography variant="h6" fontWeight="400">
                        This Week
                    </Typography>
                </Stack>
            </Stack>

            <WeekTimelineFilters
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                defaultFilters={generateDefaultFilterState()}
            />

            <WeekTimelineGrid
                weekRange={filters[ORDER_FILTER_TYPE.closeDateRange]}
                weekOrderList={filteredOrders}
                loading={orderLoading}
                DateOrderComponent={ClosingListGridItemOrder}
            />
        </Stack>
    );
}

export default ClosingList;
