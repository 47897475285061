import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Checkbox, IconButton, ListItemText, ListSubheader, MenuItem, Stack, Typography } from '@mui/material';
import { addDays, format } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ORDER_FILTER_TYPE, TRANSACTION_ORDER_TYPE, TRANSACTION_TYPE } from '../../helpers/constants';
import useFilterQueryParams from '../common/hooks/useFilterQueryParams';
import FilterTextField from '../common/styled/FilterTextField';

function calculateNewWeek(weekList, daysChanged) {
    return [addDays(weekList[0], daysChanged), addDays(weekList[1], daysChanged)];
}

function WeekTimelineFilters({
    filters,
    setFilters,
    resetFilters,
    defaultFilters,
    dateRangeFilterType = ORDER_FILTER_TYPE.closeDateRange,
}) {
    const [syncFilterMap, searchParams] = useFilterQueryParams(filters, defaultFilters, {
        multiArgFilterTypes: [ORDER_FILTER_TYPE.orderType],
        dateRangeFilterTypes: [dateRangeFilterType],
    });

    const [dateRangePreset, setDateRangePreset] = useState('');

    const changeWeek = (forward = false) => {
        const currentWeekList = filters[dateRangeFilterType];
        const daysChanged = forward ? 7 : -7;
        const newWeekList = calculateNewWeek(currentWeekList, daysChanged);
        setWeekFilter(newWeekList);
    };

    const setWeekFilter = (weekList) => {
        setFilters({
            ...filters,
            [dateRangeFilterType]: weekList,
        });
    };

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string isn't valid for some filters
        };

        if (filterName === ORDER_FILTER_TYPE.transactionType) {
            // Order type categories are related to transaction type
            // Reset order type category filter if transaction type is changed
            filterUpdate[ORDER_FILTER_TYPE.orderType] = defaultFilters[ORDER_FILTER_TYPE.orderType];
        }

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    useEffect(() => {
        if (_.keys(syncFilterMap).length > 0) {
            const updatedFilterMap = {
                ...defaultFilters,
                ...syncFilterMap,
            };
            setFilters(updatedFilterMap);

            // Reset local dateRangePreset
            setDateRangePreset('');
        }
    }, [syncFilterMap]);

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <FilterTextField
                name={ORDER_FILTER_TYPE.transactionType}
                label="Transaction Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters.transactionType}
                sx={{
                    minWidth: '200px',
                }}
                size="small"
                select
            >
                <MenuItem value="">All</MenuItem>
                {_.map(TRANSACTION_TYPE, (transactionValue) => {
                    return (
                        <MenuItem key={transactionValue} value={transactionValue}>
                            {_.startCase(transactionValue)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <FilterTextField
                name={ORDER_FILTER_TYPE.orderType}
                label="Order Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters.orderType}
                sx={{
                    minWidth: '200px',
                }}
                size="small"
                select
                SelectProps={{
                    renderValue: (selected) => _.map(selected, (item) => _.startCase(item)).join(', '),
                    multiple: true,
                }}
            >
                {/* <MenuItem value="">All</MenuItem> */}
                <ListSubheader>{_.startCase(TRANSACTION_TYPE.purchase)}</ListSubheader>
                {_.map(TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.purchase], (orderValue) => {
                    return (
                        <MenuItem
                            key={orderValue}
                            value={orderValue}
                            disabled={filters.transactionType === TRANSACTION_TYPE.refinance}
                        >
                            <Checkbox checked={_.includes(filters.orderType, orderValue)} />
                            <ListItemText primary={_.startCase(orderValue)} />
                        </MenuItem>
                    );
                })}
                <ListSubheader>{_.startCase(TRANSACTION_TYPE.refinance)}</ListSubheader>
                {_.map(TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.refinance], (orderValue) => {
                    return (
                        <MenuItem
                            key={orderValue}
                            value={orderValue}
                            disabled={filters.transactionType === TRANSACTION_TYPE.purchase}
                        >
                            <Checkbox checked={_.includes(filters.orderType, orderValue)} />
                            <ListItemText primary={_.startCase(orderValue)} />
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
                sx={{
                    borderRadius: (theme) => theme.spacing(3),
                    background: (theme) => theme.palette.white,
                    border: (theme) => `solid 1px rgba(0, 0, 0, 0.23)`,

                    '&:hover': {
                        // borderColor: (theme) => theme.palette.text.primary,
                    },
                }}
            >
                <IconButton
                    onClick={() => changeWeek()}
                    sx={{
                        maxHeight: '38px',
                        maxWidth: '38px',
                        boxSizing: 'border-box',
                    }}
                >
                    <NavigateBeforeIcon />
                </IconButton>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body2" fontSize={14} fontWeight={400} color="text.primary">
                        {format(filters[dateRangeFilterType][0], 'P')}
                    </Typography>
                    <Typography variant="body2">—</Typography>
                    <Typography variant="body2" fontSize={14} fontWeight={400} color="text.primary">
                        {format(filters[dateRangeFilterType][1], 'P')}
                    </Typography>
                </Stack>

                <IconButton
                    onClick={() => changeWeek(true)}
                    sx={{
                        maxHeight: '38px',
                        maxWidth: '38px',
                        boxSizing: 'border-box',
                    }}
                >
                    <NavigateNextIcon />
                </IconButton>
            </Stack>

            <IconButton
                onClick={() => {
                    resetFilters();
                }}
            >
                <RestartAltIcon />
            </IconButton>
        </Stack>
    );
}

WeekTimelineFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    defaultFilters: PropTypes.object.isRequired,
    dateRangeFilterType: PropTypes.oneOf([ORDER_FILTER_TYPE.closeDateRange, ORDER_FILTER_TYPE.disbursementDateRange]),
};

export default WeekTimelineFilters;
