import PropTypes from 'prop-types';
import React from 'react';
import BaseDialog from '../../common/BaseDialog';

import ActiveNote from './ActiveNote';

const ActiveNoteDialog = ({ note, handleClose }) => {
    return (
        <BaseDialog
            open={!!note}
            setOpen={() => handleClose()}
            DialogProps={{
                maxWidth: 'md',
                fullWidth: true,
            }}
            containerProps={{
                sx: {
                    height: 'calc(100vh - 64px)',
                },
            }}
        >
            {!!note && <ActiveNote note={note} handleClose={handleClose} />}
        </BaseDialog>
    );
};

ActiveNoteDialog.propTypes = {
    note: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
};

export default ActiveNoteDialog;
