import { useParams } from 'react-router-dom';

import Lien from 'components/Order/Lien';

function LienPage() {
    let { order_id: orderId, lien_id: lienId } = useParams();

    return <Lien orderId={orderId} lienId={lienId} />;
}

export default LienPage;
