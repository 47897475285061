import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useGetLienMembersQuery } from '../../api/lien';
import { useGetOrderMembersQuery } from '../../api/order';
import AddLienMember from './AddLienMember';
import LienMemberList from './LienMemberList';

const LIEN_MEMBER_VIEW_MODE = {
    list: 'list',
    add: 'add',
};

const EditLienMembers = ({ lien }) => {
    const { data: orderMembers, isError: membersError, isLoading: membersLoading } = useGetOrderMembersQuery(
        lien.order_id
    );
    const { data: lienMembers, isError: lienMembersError, isLoading: lienMembersLoading } = useGetLienMembersQuery(
        lien.id
    );

    const [viewMode, setViewMode] = useState(LIEN_MEMBER_VIEW_MODE.list);

    // TODO pass lien members into add lien member component to filter out existing members (prevent duplicates)

    return (
        <Stack>
            {viewMode === LIEN_MEMBER_VIEW_MODE.add && (
                <AddLienMember
                    lien={lien}
                    orderMembers={orderMembers}
                    onBack={() => setViewMode(LIEN_MEMBER_VIEW_MODE.list)}
                />
            )}

            {viewMode === LIEN_MEMBER_VIEW_MODE.list && (
                <LienMemberList
                    lien={lien}
                    lienMembers={lienMembers || []}
                    orderMembers={orderMembers || []}
                    viewAdd={() => setViewMode(LIEN_MEMBER_VIEW_MODE.add)}
                />
            )}
        </Stack>
    );
};

EditLienMembers.propTypes = {
    lien: PropTypes.object.isRequired,
};

export default EditLienMembers;
