import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useGetOrdersQuery } from '../../api/order';
import { ORDER_FILTER_TYPE, ORDER_STATUS, PARCEL_STATUS, TRANSACTION_TYPE } from '../../helpers/constants';
import { filterOrders, findOrderParcelByType } from '../../helpers/utils';
import SchedulingListGrid from './components/SchedulingListGrid';

const DEFAULT_FILTER_STATE = {
    [ORDER_FILTER_TYPE.orderStatus]: ORDER_STATUS.open,
};

function generateDefaultFilterState() {
    return {
        ...DEFAULT_FILTER_STATE,
        [ORDER_FILTER_TYPE.closeDateGreaterThan]: new Date(), // Default to today
    };
}

function SchedulingList() {
    const { data: orders, isError: orderError, isLoading: orderLoading } = useGetOrdersQuery();

    const [filters, setFilters] = useState(generateDefaultFilterState);
    const [schedulingList, setSchedulingList] = useState([]);

    const filteredOrders = useMemo(() => filterOrders(orders, filters), [orders, filters]);

    const resetFilters = () => {
        setFilters(generateDefaultFilterState());
    };

    useEffect(() => {
        // Update the payoff list when either payoffs (store) change or filtered payoffs list changes
        const newSchedulingList = [];

        _.forEach(filteredOrders, (order) => {
            // NOTE: we apply scheduling filters here since the original scheduling list filters based on order context

            // TODO pull & format scheduling data from order parcels here
            // TODO: multiple loops here which is inefficient - look to optimize
            // Only include orders with scheduling parcel that is 'in_progress'
            /*
                Note: have 3 potential parcels in closing:
                * closing_seller
                * closing_buyer
                * closing_borrower
                
                Will need to check for each of these depending on order transaction type
                For table organization, we will check for closing_borrower for refinances, but fill it in this table as closing_buyer

                For each closing party, we will first need to check the scheduling parcel as it will always exist 
                but a signing parcel will only exist if scheduling is complete

                1. check status of scheduling parcel
                2. if status is 'complete' then find signing parcel (NOTE: there might be multiple if signing was setup and then rescheduled)
            */
            // const schedulingParcel = findOrderParcelByType(order, PARCEL_TYPE.scheduling);
            // if (schedulingParcel.current_status.status !== PARCEL_STATUS.in_progress) {
            //     return;
            // }

            const closingBuyerParcel = findOrderParcelByType(order, 'closing_buyer');
            const closingSellerParcel = findOrderParcelByType(order, 'closing_seller');
            const closingBorrowerParcel = findOrderParcelByType(order, 'closing_borrower');
            const schedulingListItem = {
                ...order,
                closingBuyerParcel,
                closingSellerParcel,
                closingBorrowerParcel,
            };

            // If purchase transaction, only include row if closing_buyer and/or closing_seller parcel is in_progress
            if (
                order.transaction_type === TRANSACTION_TYPE.purchase &&
                (closingBuyerParcel.current_status.status === PARCEL_STATUS.in_progress ||
                    closingSellerParcel.current_status.status === PARCEL_STATUS.in_progress)
            ) {
                newSchedulingList.push(schedulingListItem);
            } else if (
                order.transaction_type === TRANSACTION_TYPE.refinance &&
                closingBorrowerParcel.current_status.status === PARCEL_STATUS.in_progress
            ) {
                // If refinance transaction, only include row if closing_borrower is in_progress
                newSchedulingList.push(schedulingListItem);
            }
        });

        setSchedulingList(newSchedulingList);
    }, [filteredOrders]);

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
                padding: (theme) => theme.spacing(3),
            }}
        >
            <Typography variant="h1">Scheduling</Typography>

            <SchedulingListGrid schedulingList={schedulingList} loading={orderLoading} />
        </Stack>
    );
}

export default SchedulingList;
