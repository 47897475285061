import AddIcon from '@mui/icons-material/Add';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import { useGetOrderNotesByEntityIdListQuery } from '../../../../api/note';
import { ENTITY_TYPE } from '../../../../helpers/constants';
import { findChildParcelsInOrder } from '../../../../helpers/utils';
import ActiveNoteDialog from '../../../NoteList/components/ActiveNoteDialog';
import CreateNoteDialog from '../../../NoteList/components/CreateNoteDialog';
import NoteListStack from '../../../NoteList/components/NoteListStack';
import { Button } from '../../../common/styled';

function NoteList({ notes, activeNoteId, setActiveNoteId }) {
    if (!notes || notes.length === 0) {
        return (
            <Box sx={{ pl: 3, pr: 3, pt: 3 }}>
                <Typography variant="body1" color="text.secondary" fontStyle="italic">
                    No associated notes
                </Typography>
            </Box>
        );
    }

    return (
        <Stack sx={{ height: '100%', maxHeight: '480px', overflow: 'auto' }}>
            <NoteListStack
                notes={notes}
                activeNoteId={activeNoteId}
                setActiveNoteId={setActiveNoteId}
                showTag={false}
            />
        </Stack>
    );
}

function ParcelNoteList({ order, parcel }) {
    const [openNewNoteDialog, setOpenNewNoteDialog] = useState(false);
    const [activeNoteId, setActiveNoteId] = useState(null);

    const childParcels = useMemo(() => {
        if (order) {
            return findChildParcelsInOrder(order, parcel.id);
        }

        return [];
    }, [order, parcel.id]);

    const { data: parcelNotes, isLoading: parcelNotesLoading } = useGetOrderNotesByEntityIdListQuery({
        orderId: order.id,
        entityIdList: [parcel.id, ..._.map(childParcels, 'id')],
    });

    // Find active note by activeNoteId
    const activeNote = useMemo(() => {
        if (!activeNoteId) {
            return null;
        }

        return _.find(parcelNotes, { id: activeNoteId });
    }, [parcelNotes, activeNoteId]);

    // TODO add loading state indecator internal to main render
    if (parcelNotesLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Paper variant="outlined">
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ p: 3, pb: 0 }}>
                <Typography variant="sectionHeader">Notes</Typography>

                <IconButton size="small" onClick={() => setOpenNewNoteDialog(true)}>
                    <AddIcon />
                </IconButton>
            </Stack>

            <NoteList notes={parcelNotes || []} activeNoteId={activeNoteId} setActiveNoteId={setActiveNoteId} />

            <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ p: 3 }}>
                <Button
                    component={RouterLink}
                    to={`/order/${order.id}/notes`}
                    variant="outlined"
                    color="default"
                    size="small"
                    endIcon={<EastRoundedIcon />}
                    // sx={{ minWidth: '120px' }}
                >
                    View All
                </Button>
            </Stack>

            <CreateNoteDialog
                orderId={order.id}
                open={openNewNoteDialog}
                handleClose={() => setOpenNewNoteDialog(false)}
                handleSuccess={() => null}
                seedTag={{
                    entity_type: ENTITY_TYPE.parcel,
                    entity_id: parcel.id,
                }}
            />

            <ActiveNoteDialog note={activeNote} handleClose={() => setActiveNoteId(null)} />
        </Paper>
    );
}

ParcelNoteList.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default ParcelNoteList;
