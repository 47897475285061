import AddIcon from '@mui/icons-material/Add';
import { IconButton, List, Paper, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useGetOrderMembersQuery } from 'api/order';
import AssignmentMemberListItem from './AssignmentMemberListItem';
import CreateAssignmentMemberModal from './CreateAssignmentMemberModal';

function AssignmentMemberListContent({ assignment, assignmentMembers, orderMembers }) {
    if (!assignmentMembers || assignmentMembers.length === 0) {
        return (
            <Typography variant="body1" color="text.secondary" fontStyle="italic">
                No associated members
            </Typography>
        );
    }

    return (
        <List disablePadding>
            {_.map(assignmentMembers, (assignmentMember) => {
                return (
                    <AssignmentMemberListItem
                        key={assignmentMember.id}
                        assignment={assignment}
                        assignmentMember={assignmentMember}
                        orderMembers={orderMembers}
                    />
                );
            })}
        </List>
    );
}

AssignmentMemberList.propTypes = {
    assignment: PropTypes.object.isRequired,
    assignmentMembers: PropTypes.array.isRequired,
    orderMembers: PropTypes.array.isRequired,
};

function AssignmentMemberList({ assignment, assignmentMembers }) {
    const { data: orderMembers, isError: orderMembersError, isLoading: orderMembersLoading } = useGetOrderMembersQuery(
        assignment.order_id
    );
    // TODO sort assignment members by type
    // TODO add new assignment member
    const [openCreateAssignmentMemberModal, setOpenCreateAssignmentMemberModal] = useState(false);

    return (
        <Paper
            variant="outlined"
            sx={{
                padding: (theme) => theme.spacing(3),
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Members</Typography>

                <Tooltip title="Add Member" enterDelay={300} placement="bottom">
                    <span>
                        <IconButton onClick={() => setOpenCreateAssignmentMemberModal(true)} size="small">
                            <AddIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </Stack>

            <AssignmentMemberListContent
                assignment={assignment}
                assignmentMembers={assignmentMembers}
                orderMembers={orderMembers}
            />

            <CreateAssignmentMemberModal
                assignment={assignment}
                assignmentMembers={assignmentMembers}
                orderMembers={orderMembers}
                open={openCreateAssignmentMemberModal}
                handleClose={() => setOpenCreateAssignmentMemberModal(false)}
            />
        </Paper>
    );
}

AssignmentMemberList.propTypes = {
    assignment: PropTypes.object.isRequired,
    assignmentMembers: PropTypes.array.isRequired,
};

export default AssignmentMemberList;
