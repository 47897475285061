import React from 'react';
import ClosingList from '../../components/ClosingList/ClosingList';

function ClosingListPage() {

    return (
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
            <ClosingList />
        </div>
    );
}

export default ClosingListPage;
