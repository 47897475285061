import {
    FormControl,
    FormControlLabel, FormLabel, Radio,
    RadioGroup
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { TRANSACTION_TYPE } from '../../../../helpers/constants';


function TransactionTypeField({transactionType, setTransactionType, disabled = false}) {

    const handleTransactionChange = ({ target }) => {
        // NOTE: title workflow & order type are coupled to transaction type
        // ensure these are changed at a higher-level
        const newTransactionType = target.value;
        setTransactionType(newTransactionType);
    };

    return (
        <FormControl component="fieldset" disabled={disabled}>
            <FormLabel id="transaction-type-group-label">Transaction Type</FormLabel>
            <RadioGroup
                row
                aria-labelledby="transaction-type-group-label"
                name="transaction-type-radio-buttons-group"
                value={transactionType}
                onChange={handleTransactionChange}
                required
            >
                <FormControlLabel value={TRANSACTION_TYPE.purchase} control={<Radio />} label={_.capitalize(TRANSACTION_TYPE.purchase)} />
                <FormControlLabel value={TRANSACTION_TYPE.refinance} control={<Radio />} label={_.capitalize(TRANSACTION_TYPE.refinance)} />
            </RadioGroup>
        </FormControl>
    );
}

TransactionTypeField.propTypes = {
    transactionType: PropTypes.string.isRequired, 
    setTransactionType: PropTypes.func.isRequired, 
    disabled: PropTypes.bool,
};

export default TransactionTypeField;
