import PropTypes from 'prop-types';

import BaseDialog from 'components/common/BaseDialog';
import AddMember from './AddMember';

const AddMemberModal = ({ legalEntity, open, handleClose }) => {
    const handleCloseLocal = (openValue) => {
        handleClose(openValue);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleCloseLocal(false)}
            DialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
            }}
        >
            <AddMember
                legalEntity={legalEntity}
                handleCancel={() => handleCloseLocal(false)}
                handleComplete={() => handleCloseLocal(false)}
            />
        </BaseDialog>
    );
};

AddMemberModal.propTypes = {
    legalEntity: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default AddMemberModal;
