import { Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useCreateDocumentMutation, useGetOrderDocumentsQuery } from 'api/document';
import { DOCUMENT_TYPE } from 'helpers/constants';
import DocumentListItem from './DocumentListItem';
import DocumentUploadArea from './DocumentUploadArea';

function DocumentForm({ orderId }) {
    const [
        createDocument,
        { loading: createDocumentLoading, error: createDocumentError },
    ] = useCreateDocumentMutation();
    const { data: documents, isError: documentsError, isLoading: documentsLoading } = useGetOrderDocumentsQuery(
        orderId
    );

    const [documentData, setDocumentData] = useState();
    const [documentType, setDocumentType] = useState(DOCUMENT_TYPE.other);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClear = () => {
        setDocumentData(null);
    };

    const handleSubmit = (event) => {
        setIsSubmitting(true);

        submitDocument(documentData)
            .then((response) => {
                if (response) {
                    handleClear();
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const submitDocument = async (documentData) => {
        // Generate document form data
        const formData = new FormData();
        formData.append('file', documentData.document);
        formData.append('type', documentType);
        formData.append('order_id', orderId);
        formData.append('access', JSON.stringify([])); // Note: array must be json serialized for FormData api request
        formData.append('send_access_email', false);

        // Conditionally include document name
        const documentName = _.get(documentData, 'name', null);
        if (documentName && documentName.length > 0) {
            formData.append('name', documentName);
        }

        const { data } = await createDocument({
            orderId,
            documentData: formData,
        });

        return !!data;
    };

    return (
        <Stack spacing={3}>
            <DocumentUploadArea
                orderId={orderId}
                documentData={documentData}
                setDocumentData={setDocumentData}
                documentType={documentType}
                setDocumentType={setDocumentType}
                handleClear={handleClear}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            />
            <div>
                {_.map(documents, (document) => (
                    <DocumentListItem key={document.id} document={document} />
                ))}
            </div>
        </Stack>
    );
}

DocumentForm.propTypes = {
    orderId: PropTypes.string.isRequired,
    error: PropTypes.bool,
    errorMessage: PropTypes.object,
};

export default DocumentForm;
