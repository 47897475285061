import { Box, Stack } from '@mui/material';
// import { useMemo } from 'react';

// import { generateBackgroundColorSvgUrl } from 'components/common/BackgroundColorSvg';
import DashboardBoxStack from 'components/Dashboard/components/DashboardBoxStack';
import DashboardTableStack from 'components/Dashboard/components/DashboardTableStack';

function Dashboard() {
    // const theme = useTheme();

    // const backgroundUrl = useMemo(
    //     () => generateBackgroundColorSvgUrl(theme.palette.dashboard.main, theme.palette.background.gray),
    //     [theme]
    // );

    return (
        <Stack spacing={3} sx={{ height: '100%', padding: (theme) => theme.spacing(3) }}>
            {/* <Typography variant="h1">Dashboard</Typography> */}

            <Box
            // sx={{
            //     padding: (theme) => theme.spacing(3),
            //     // paddingBottom: (theme) => theme.spacing(12),
            //     margin: (theme) => `${theme.spacing(-3, -3, 0, -3)} !important`,
            //     background: (theme) => theme.palette.background.dark,
            //     backgroundImage: `url(${backgroundUrl})`,
            //     backgroundPosition: 'top center',
            //     backgroundSize: 'cover',
            // }}
            >
                <DashboardBoxStack />
            </Box>

            <Box>
                <Box>
                    <DashboardTableStack />
                </Box>
            </Box>
        </Stack>
    );
}

export default Dashboard;
