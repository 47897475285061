import { MenuItem, Stack, TextField } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { COMMERCIAL_ENTITY_TYPE, LEGAL_ENTITY_TYPE, US_STATES } from '../../../helpers/constants';
import AddressForm, { INITIAL_ADDRESS_DATA } from '../../common/form/address/AddressForm';
import ContactForm, { CONTACT_FIELDS, INITIAL_CONTACT_DATA } from '../../common/form/contact/ContactForm';
import TaxIdFieldMask from '../../common/mask/TaxIdFieldMask';

export const INITIAL_LEGAL_ENTITY_STATE = {
    name: '',
    type: '',
    commercial_type: '',
    tax_id: '',
    state_of_incorporation: 'Texas',
    contact: { ...INITIAL_CONTACT_DATA },
    address: { ...INITIAL_ADDRESS_DATA },
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 240,
        },
    },
};

const LegalEntityForm = ({ legalEntityData, setLegalEntityData }) => {
    const handleChange = ({ target }) => {
        const fieldName = target.name;
        const fieldValue = target.value;

        const updatedData = {
            ...legalEntityData,
            [fieldName]: fieldValue,
        };

        if (fieldName === 'type' && _.includes([LEGAL_ENTITY_TYPE.estate, LEGAL_ENTITY_TYPE.trust], fieldValue)) {
            // determine if commercial_type needs to be reset
            updatedData['commercial_type'] = '';
        }

        setLegalEntityData(updatedData);
    };

    const handleContactChange = ({ target }) => {
        setLegalEntityData({
            ...legalEntityData,
            contact: {
                ...legalEntityData.contact,
                [target.name]: target.value,
            },
        });
    };

    const handleAddressChange = (newAddress) => {
        setLegalEntityData({
            ...legalEntityData,
            address: { ...newAddress },
        });
    };

    /*
        TODO tax id mask?
    */

    return (
        <Stack
            spacing={4}
            sx={{
                '& .form-group-label': {
                    fontSize: '12px',
                    fontWeight: '600',
                    textTransform: 'uppercase',
                },
            }}
        >
            <TextField
                name="name"
                label="Name"
                onChange={handleChange}
                value={legalEntityData.name}
                size="small"
                fullWidth
                required
            />

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <TextField
                    name="type"
                    label="Legal Type"
                    onChange={handleChange}
                    value={legalEntityData.type}
                    sx={{ flexGrow: 1 }}
                    size="small"
                    required
                    select
                >
                    {_.map(LEGAL_ENTITY_TYPE, (legalEntityOption) => (
                        <MenuItem key={legalEntityOption} value={legalEntityOption}>
                            {legalEntityOption !== LEGAL_ENTITY_TYPE.LLC
                                ? _.startCase(legalEntityOption)
                                : legalEntityOption}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    name="commercial_type"
                    label="Commercial Type"
                    onChange={handleChange}
                    value={legalEntityData.commercial_type}
                    sx={{ flexGrow: 1 }}
                    disabled={_.includes([LEGAL_ENTITY_TYPE.estate, LEGAL_ENTITY_TYPE.trust], legalEntityData.type)}
                    size="small"
                    select
                    SelectProps={{
                        MenuProps,
                    }}
                >
                    {_.map(COMMERCIAL_ENTITY_TYPE, (commercialEntityOption) => (
                        <MenuItem key={commercialEntityOption} value={commercialEntityOption}>
                            {commercialEntityOption !== COMMERCIAL_ENTITY_TYPE.iBuyer
                                ? _.startCase(commercialEntityOption)
                                : commercialEntityOption}
                        </MenuItem>
                    ))}
                </TextField>
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <TextField
                    name="state_of_incorporation"
                    label="State of Incorporation"
                    onChange={handleChange}
                    value={legalEntityData.state_of_incorporation}
                    sx={{ flexGrow: 1, minWidth: '180px' }}
                    size="small"
                    select
                    SelectProps={{
                        MenuProps,
                    }}
                >
                    {_.map(US_STATES, (stateOption) => (
                        <MenuItem key={stateOption} value={stateOption}>
                            {_.startCase(stateOption)}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    name="tax_id"
                    label="Tax ID"
                    onChange={handleChange}
                    value={legalEntityData.tax_id}
                    fullWidth
                    size="small"
                    InputProps={{
                        inputComponent: TaxIdFieldMask,
                    }}
                />
            </Stack>

            <ContactForm
                contactData={legalEntityData.contact}
                setContactData={handleContactChange}
                hideFields={[CONTACT_FIELDS.tier]}
                showLabel={true}
            />

            <AddressForm
                addressData={legalEntityData.address}
                setAddressData={handleAddressChange}
                showLabel={true}
                label="Address"
            />
        </Stack>
    );
};

LegalEntityForm.propTypes = {
    legalEntityData: PropTypes.object.isRequired,
    setLegalEntityData: PropTypes.func.isRequired,
};

export default LegalEntityForm;
