import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { ENTITY_TYPE } from 'helpers/constants';
import AssignActionForm from './AssignActionForm';

function AddAssignedAction({ order, orderMembers, handleClose }) {
    const assignableActions = useMemo(() => {
        const actions = [];
        _.forEach(_.get(order, 'parcels', []), (parcel) => {
            _.forEach(parcel.potential_actions, (potentialAction) => {
                if (potentialAction.assignable && potentialAction.creatable) {
                    actions.push({
                        ...potentialAction,
                        parcel_id: parcel.id,
                    });
                }
            });
        });
        return actions;
    }, [order]);

    const orderMemberOptions = useMemo(() => {
        return _.filter(orderMembers, (member) => member.member_type !== ENTITY_TYPE.legal_entity);
    }, [orderMembers]);

    return (
        <Stack spacing={4} sx={{ width: '100%' }}>
            <Stack spacing={1}>
                <Typography variant="h5">Assign Action</Typography>
                <Typography variant="body1" color="text.secondary">
                    Request data from an order member
                </Typography>
            </Stack>

            <AssignActionForm
                actionTypeOptions={assignableActions}
                orderMemberOptions={orderMemberOptions}
                handleSubmit={handleClose}
            />
        </Stack>
    );
}

AddAssignedAction.propTypes = {
    order: PropTypes.object,
    orderMembers: PropTypes.array.isRequired,
    handleClose: PropTypes.func,
};

export default AddAssignedAction;
