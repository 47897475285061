import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { Button } from 'components/common/styled';
import SearchPopover from './SearchPopover';

const SearchIconButton = ({ open, setOpen }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <Box>
            <Button
                variant="contained"
                sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    minWidth: '36px',
                }}
                color="primary"
                onClick={handleClick}
            >
                <SearchIcon />
            </Button>

            <SearchPopover open={open} handleClose={handleClose} anchorEl={anchorEl} />
        </Box>
    );
};

SearchIconButton.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default SearchIconButton;
