import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useDeleteUserRelationshipMutation, useUpdateUserRelationshipMutation } from '../../../api/user';
import { USER_RELATIONSHIP_TYPE } from '../../../helpers/constants';
import { Button, LoadingButton } from '../../common/styled';

const EditRelationship = ({ user, relationship, handleCancel, handleComplete }) => {
    const [relationCopy, setRelationCopy] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [
        updateUserRelationship,
        { isLoading: isUpdateUserRelationshipLoading },
    ] = useUpdateUserRelationshipMutation();
    const [
        deleteUserRelationship,
        { isLoading: isDeleteUserRelationshipLoading },
    ] = useDeleteUserRelationshipMutation();

    useEffect(() => {
        // Create local copy of relation to be editted
        setRelationCopy(relationship.relation);
    }, [relationship]);

    const handleRelationChange = (event) => {
        const newRelation = event.target.value;
        setRelationCopy(newRelation);
    };

    const handleDeleteRelationship = (event) => {
        event.preventDefault();

        setIsSubmitting(true);

        return submitUserRelationshipDelete(relationship).then(
            (response) => {
                setIsSubmitting(false);

                if (response) {
                    handleComplete();
                } else {
                    console.error('Failed to delete user relationship');
                }
            },
            (error) => {
                console.log(error);
                setIsSubmitting(false);
            }
        );
    };

    const submitUserRelationshipDelete = async (relationship) => {
        const { data: deleteUserRelationshipData } = await deleteUserRelationship({
            userId: relationship.user_id,
            relationshipId: relationship.id,
        });

        if (deleteUserRelationshipData) {
            return true;
        } else {
            console.warn(`Failed to be delete user relationship ${relationship.id}`);
            return false;
        }
    };

    const handleUpdateRelationship = (event) => {
        event.preventDefault();

        setIsSubmitting(true);

        return submitUserRelationshipUpdate(relationship, relationCopy).then(
            (response) => {
                setIsSubmitting(false);

                if (response) {
                    handleComplete();
                } else {
                    console.error('Failed to update user relationship');
                }
            },
            (error) => {
                console.log(error);
                setIsSubmitting(false);
            }
        );
    };

    const submitUserRelationshipUpdate = async (relationship, relation) => {
        const updateRelationshipData = {
            relation,
        };

        const { data: updateUserRelationshipData } = await updateUserRelationship({
            userId: relationship.user_id,
            relationshipId: relationship.id,
            relationshipData: updateRelationshipData,
        });

        if (updateUserRelationshipData) {
            return true;
        } else {
            console.warn(`Failed to be update user relationship ${relationship.id}`);
            return false;
        }
    };

    return (
        <Stack spacing={4} sx={{ width: '100%' }}>
            <Stack spacing={1}>
                <Typography variant="h5">Edit Relationship</Typography>
                <Typography variant="body1" color="text.secondary">
                    {user.name}
                </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                    name="relation"
                    label="Relation"
                    value={relationCopy}
                    onChange={handleRelationChange}
                    sx={{ minWidth: '150px' }}
                    size="small"
                    select
                    required
                >
                    {_.map(USER_RELATIONSHIP_TYPE, (relationType) => (
                        <MenuItem key={relationType} value={relationType}>
                            {_.startCase(relationType)}
                        </MenuItem>
                    ))}
                </TextField>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body1" color="text.secondary">
                        of
                    </Typography>
                    <Typography variant="body1">{relationship.related_user.name}</Typography>
                </Stack>

                <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
                    <IconButton onClick={handleDeleteRelationship} disabled={isSubmitting}>
                        <DeleteForeverIcon />
                    </IconButton>
                </Box>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                <Button variant="text" color="default" onClick={() => handleCancel()} disabled={isSubmitting}>
                    Cancel
                </Button>

                <LoadingButton
                    variant="contained"
                    onClick={handleUpdateRelationship}
                    loading={isSubmitting}
                    disableElevation
                    disabled={relationship.relation === relationCopy}
                    sx={{ minWidth: '160px' }}
                >
                    Update
                </LoadingButton>
            </Stack>
        </Stack>
    );
};

EditRelationship.propTypes = {
    user: PropTypes.object.isRequired,
    relationship: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleComplete: PropTypes.func.isRequired,
};

export default EditRelationship;
