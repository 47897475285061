import { Box, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useCreateUserMutation } from '../../../api/user';
import { formatAddressPayload } from '../../common/form/address/AddressForm';
import { formatContactPayload } from '../../common/form/contact/ContactForm';
import { LoadingButton } from '../../common/styled';
import UserForm, { INITIAL_USER_STATE } from './UserForm';

const NewUserForm = ({ handleSubmit }) => {
    const [createUser, { isLoading: isUserLoading }] = useCreateUserMutation();
    const [userData, setUserData] = useState({ ...INITIAL_USER_STATE });

    const handleCreateUser = (event) => {
        event.preventDefault();

        const submitted = submitUser();
        if (submitted) {
            handleSubmit();
        }
    };

    const formatUserPayload = () => {
        // Form user data payload that strips out any empty/null values
        const userPayload = {};
        _.forEach(userData, (value, key) => {
            // contact/address are nested objects that need to be handled explicitly
            if (key === 'contact') {
                userPayload[key] = formatContactPayload(value);
            } else if (key === 'address') {
                userPayload[key] = formatAddressPayload(value);
            } else if (!_.isNil(value) && value !== '') {
                userPayload[key] = value;
            }
        });

        return userPayload;
    };

    const submitUser = async () => {
        const { data } = await createUser(formatUserPayload());
        return !!data;
    };

    return (
        <form onSubmit={handleCreateUser}>
            <Stack spacing={4}>
                <UserForm userData={userData} setUserData={setUserData} />

                <Box>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%' }}
                    >
                        <div />
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={isUserLoading}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                        >
                            Create
                        </LoadingButton>
                    </Stack>
                </Box>
            </Stack>
        </form>
    );
};

NewUserForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export default NewUserForm;
