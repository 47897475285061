import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Grid, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { copyToClipboard, USDollar } from '../../../../helpers/utils';
import useAdditionalDataItem from '../../../common/hooks/useAdditionalDataItem';
import EditLien from './EditLien';

function LienDetailsLabel({ label }) {
    return (
        <Typography
            variant="body2"
            sx={{
                fontWeight: '400',
                color: 'text.secondary',
            }}
        >
            {label}
        </Typography>
    );
}

function LienDetailsContent({ content }) {
    const [copyText, setCopyText] = useState('Copy');

    const handleCopy = (event, text) => {
        event.stopPropagation();
        event.preventDefault();
        copyToClipboard(text);
        setCopyText('Copied!');
    };

    return (
        <Box>
            {content ? (
                <Tooltip title={copyText} onClose={() => setCopyText('Copy')} placement="right" enterDelay={300}>
                    <Typography variant="body1" component="span" onClick={(event) => handleCopy(event, content)}>
                        {content || '—'}
                    </Typography>
                </Tooltip>
            ) : (
                <Typography variant="body1" component="span">
                    {'—'}
                </Typography>
            )}
        </Box>
    );
}

function LienDetailsSection({ label, content }) {
    return (
        <Grid item xs={12} md={6} lg={4}>
            <LienDetailsLabel label={label} />
            <LienDetailsContent content={content} />
        </Grid>
    );
}

function LienDetailsAdditionalDataSection({ lien, propertyKey }) {
    const { label, value } = useAdditionalDataItem(lien, propertyKey);

    return <LienDetailsSection label={label} content={value} />;
}

function LienDetailsGrid({ lien }) {
    const additionalDataChunks = _.get(lien, 'additional_data_schema.ui_schema["ui:order"]', []);

    return (
        <Grid container alignItems="flex-start" spacing={2}>
            <LienDetailsSection label="Amount" content={lien.amount ? USDollar.format(lien.amount) : null} />

            <LienDetailsSection label="Description" content={lien.description} />

            {_.map(additionalDataChunks, (propertyKey) => (
                <LienDetailsAdditionalDataSection key={propertyKey} lien={lien} propertyKey={propertyKey} />
            ))}
        </Grid>
    );
}

function LienDetailsCore({ lien }) {
    const [editMode, setEditMode] = useState(false);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Details</Typography>
                <IconButton size="small" onClick={() => toggleEditMode()}>
                    {editMode ? <CloseIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                </IconButton>
            </Stack>

            {editMode ? (
                <EditLien lien={lien} handleCancel={() => toggleEditMode()} handleComplete={() => toggleEditMode()} />
            ) : (
                <LienDetailsGrid lien={lien} />
            )}
        </Paper>
    );
}

LienDetailsCore.propTypes = {
    lien: PropTypes.object.isRequired,
};

export default LienDetailsCore;
