import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import AddressForm, { INITIAL_ADDRESS_DATA, formatAddressPayload } from '../../address/AddressForm';

const generateInitialAddress = (formData) => {
    if (!formData) {
        return { ...INITIAL_ADDRESS_DATA };
    }

    return {
        ...INITIAL_ADDRESS_DATA,
        ...formData,
    };
};

const CustomAddressField = (props) => {
    // NOTE: this field wraps the standard address form and handles interoperability with the parent rjsf form
    // Because the address interface is fairly standardized, we do not pass in much of the schema or uiSchema
    // formData can be null or object with address fields
    // uses props.formData and props.onChange to update the parent form's address data
    // uses props.schema.title for the label
    // uses props.uiSchema.ui:options.required to determine if the address fields should be required

    const [addressData, setAddressData] = useState(generateInitialAddress(props.formData));

    const handleUpdateFormData = (newFormData) => {
        props.onChange(formatAddressPayload(newFormData));
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(handleUpdateFormData, 500), []);

    const handleUpdateAddress = (newAddressData) => {
        setAddressData(newAddressData);
        debouncedChangeHandler(newAddressData);
    };

    // Defaults to enforce required fields - must be explicitly set to false to disable
    const required = _.get(props, 'uiSchema.ui:options.required', true);
    const label = _.get(props, 'schema.title');
    const disabled = _.get(props, 'readonly', false);

    return (
        <AddressForm
            addressData={addressData}
            setAddressData={handleUpdateAddress}
            label={label}
            showLabel={true}
            required={required}
            disabled={disabled}
        />
    );
};

export default CustomAddressField;
