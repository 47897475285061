import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { rootApi } from './root';

export const ORDER_TAG = _.toUpper(ENTITY_TYPE.order);
export const ORDER_MEMBER_TAG = _.toUpper(ENTITY_TYPE.order_member);

export const orderApi = rootApi.injectEndpoints({
    reducerPath: 'orderApi',
    endpoints: (builder) => ({
        // TODO transition all orders api calls to use this
        getOrders: builder.query({
            query: () => 'orders',
            providesTags: [ORDER_TAG],
        }),
        createOrder: builder.mutation({
            query: ({orderData}) => ({
                url: `orders`,
                method: 'POST',
                body: orderData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [ORDER_TAG],
        }),
        updateOrder: builder.mutation({
            query: ({orderId, orderData}) => ({
                url: `order/${orderId}`,
                method: 'PUT',
                body: orderData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            async onQueryStarted({ orderId, orderData }, { dispatch, queryFulfilled }) {
                const optimisticUpdateResult = dispatch(
                    orderApi.util.updateQueryData('getOrders', undefined, (draft) => {
                        const order = draft.find((order) => order.id === orderId);
                        Object.assign(order, {
                            ...order,
                            ...orderData,
                        })
                    })
                )

                try {
                    await queryFulfilled
                } catch {
                    optimisticUpdateResult.undo()
                }
            },
            invalidatesTags: [ORDER_TAG],
        }),
        getOrderMembers: builder.query({
            query: (orderId) => `order/${orderId}/members`,
            providesTags: [ORDER_MEMBER_TAG],
        }),
        createOrderMember: builder.mutation({
            query: ({orderId, memberData}) => ({
                url: `order/${orderId}/members`,
                method: 'POST',
                body: memberData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [ORDER_MEMBER_TAG],
        }),
        updateOrderMember: builder.mutation({
            query: ({orderId, memberId, memberData}) => ({
                url: `order/${orderId}/member/${memberId}`,
                method: 'PUT',
                body: memberData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            async onQueryStarted({ orderId, memberId, memberData }, { dispatch, queryFulfilled }) {
                const optimisticUpdateResult = dispatch(
                    orderApi.util.updateQueryData('getOrderMembers', orderId, (draft) => {
                        const member = draft.find((member) => member.id === memberId)
                        Object.assign(member, {
                            ...member,
                            ...memberData,
                        })
                    })
                )

                try {
                    await queryFulfilled
                } catch {
                    optimisticUpdateResult.undo()
                }
            },
            invalidatesTags: [ORDER_MEMBER_TAG],
        }),
        deleteOrderMember: builder.mutation({
            query: ({orderId, memberId}) => ({
                url: `order/${orderId}/member/${memberId}`,
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [ORDER_MEMBER_TAG],
        }),
        getAllOrderMembers: builder.query({
            query: () => 'orders/members',
            providesTags: [ORDER_MEMBER_TAG],
        }),
    }),
    overrideExisting: false,
})


export function useGetOrderQuery(orderId) {
    const { useGetOrdersQuery } = orderApi;
    return useGetOrdersQuery(undefined, {
        selectFromResult: ({ data, error, isLoading, isFetching }) => ({
            data: data?.find((order) => order.id === orderId),
            error,
            isLoading,
            isFetching
        }),
    });
}

export function useGetOrderByQualiaNumberQuery(qualiaOrderNumber) {
    const { useGetOrdersQuery } = orderApi;
    return useGetOrdersQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.find((order) => order.qualia_order_number === qualiaOrderNumber),
            error,
            isLoading
        }),
    });
}

export function useGetOrdersBySearchQuery(searchTerm) {
    const { useGetOrdersQuery } = orderApi;
    return useGetOrdersQuery(undefined, {
        skip: searchTerm.length < 3,
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((order) => {
                // TODO add more aspects to search such as address
                return _.includes(_.lowerCase(`${order.qualia_order_number}`), _.lowerCase(searchTerm))
            }),
            error,
            isLoading
        }),
    });
}

export function useGetOrderMemberQuery({orderId, memberId}) {
    const { useGetOrderMembersQuery } = orderApi;
    return useGetOrderMembersQuery(orderId, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.find((member) => member.id === memberId),
            error,
            isLoading
        }),
    });
}


export function useGetAllOrderMembersByEntityQuery(entityId) {
    const { useGetAllOrderMembersQuery } = orderApi;
    return useGetAllOrderMembersQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((member) => member.member_id === entityId),
            error,
            isLoading
        }),
    });
}


export function useGetAllOrderMembersByUserQuery(userId) {
    // matches member_id directly when member_type is user
    // matches nested user_id when member_type is legal_entity_member
    const { useGetAllOrderMembersQuery } = orderApi;
    return useGetAllOrderMembersQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((member) => {
                if (member.member_type === ENTITY_TYPE.user) {
                    return member.member_id === userId
                } else if (member.member_type === ENTITY_TYPE.legal_entity_member) {
                    return member.member.user_id === userId
                } else {
                    return false;
                }
            }),
            error,
            isLoading
        }),
    });
}

export const { useGetOrdersQuery, useCreateOrderMutation, useUpdateOrderMutation, useGetOrderMembersQuery, useCreateOrderMemberMutation, useUpdateOrderMemberMutation, useDeleteOrderMemberMutation, useGetAllOrderMembersQuery } = orderApi