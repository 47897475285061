import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useUpdateParcelMutation } from 'api/parcel';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { PARCEL_STATUS } from 'helpers/constants';

const themeBackgroundColorMap = (colorString, theme) => {
    switch (colorString) {
        case 'success':
            return theme.palette.primary.light;
        case 'error':
            return theme.palette.error.light;
        case 'warning':
            return theme.palette.warning.light;
        case 'default':
            return theme.palette.background.default;
        default:
            return theme.palette.background.default;
    }
};

const themeTextColorMap = (colorString, theme) => {
    switch (colorString) {
        case 'success':
            return theme.palette.primary.dark;
        case 'error':
            return theme.palette.error.dark;
        case 'warning':
            return theme.palette.warning.dark;
        case 'default':
            return theme.palette.text.primary;
        default:
            return theme.palette.text.primary;
    }
};

const statusColorStringMap = (parcelStatus) => {
    switch (parcelStatus) {
        case PARCEL_STATUS.complete:
            return 'success';
        case PARCEL_STATUS.canceled:
            return 'error';
        case PARCEL_STATUS.on_hold:
            return 'warning';
        default:
            return 'default';
    }
};

function ParcelStatusSelect({ parcel }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmChangeStatus, setConfirmChangeStatus] = useState(null);
    const [updateParcel, { isLoading }] = useUpdateParcelMutation();

    const handleParcelUpdateStatus = async (newStatus) => {
        const updateParcelPayload = {
            orderId: parcel.order_id,
            parcelId: parcel.id,
            parcelData: {
                status: newStatus,
            },
        };

        const { data } = await updateParcel(updateParcelPayload);
        return !!data;
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (newStatus) => {
        setConfirmChangeStatus(newStatus);

        handleClose();
    };

    const handleSubmit = (newStatus) => {
        handleParcelUpdateStatus(newStatus);
    };

    const colorString = statusColorStringMap(parcel.current_status.status);

    return (
        <>
            <LoadingButton
                variant="outlined"
                color="default"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                loading={isLoading}
                disableElevation
                sx={{
                    backgroundColor: (theme) => themeBackgroundColorMap(colorString, theme),
                    color: (theme) => themeTextColorMap(colorString, theme),
                    '&:hover': {
                        color: (theme) => theme.palette.text.primary,
                    },
                }}
            >
                {_.startCase(parcel.current_status.status)}
            </LoadingButton>
            <Menu
                id="parcel-status-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                // anchorOrigin={{
                //     vertical: 'bottom',
                //     horizontal: 'right',
                // }}
                // transformOrigin={{
                //     vertical: 'top',
                //     horizontal: 'right',
                // }}
                keepMounted
            >
                {_.map(parcel.potential_statuses, (status) => (
                    <MenuItem
                        key={status}
                        onClick={() => handleChange(status)}
                        selected={status === parcel.current_status.status}
                    >
                        {_.startCase(status)}
                    </MenuItem>
                ))}
            </Menu>
            <ConfirmDialog
                open={!!confirmChangeStatus}
                title={`Change ${parcel.name} Status`}
                body={
                    <Box>
                        <Typography component="span" color="text.secondary">
                            Are you sure you want to change the status of {parcel.name} from{' '}
                        </Typography>
                        <Typography component="span" color="text.primary" fontWeight={500}>
                            {_.startCase(parcel.current_status.status)}
                        </Typography>
                        <Typography component="span" color="text.secondary">
                            {' '}
                            to{' '}
                        </Typography>
                        <Typography component="span" color="text.primary" fontWeight={500}>
                            {_.startCase(confirmChangeStatus)}
                        </Typography>
                        <Typography component="span" color="text.secondary">
                            ?
                        </Typography>
                    </Box>
                }
                handleConfirm={() => handleSubmit(confirmChangeStatus)}
                handleClose={() => setConfirmChangeStatus(null)}
            />
        </>
    );
}

ParcelStatusSelect.propTypes = {
    parcel: PropTypes.object.isRequired,
};

export default ParcelStatusSelect;
