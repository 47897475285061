import { Box, Stack } from '@mui/material';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useUpdateParcelMutation } from '../../../../api/parcel';
import { generateUpdateObject } from '../../../../helpers/utils';
import customFields from '../../../common/form/rjsf/fields';
import customWidgets from '../../../common/form/rjsf/widgets';
import { Button, LoadingButton } from '../../../common/styled';

const EditParcel = ({ parcel, handleCancel, handleComplete }) => {
    const [updateParcel, { isLoading }] = useUpdateParcelMutation();
    const [parcelAdditionalData, setParcelAdditionalData] = useState();
    const [jsonSchema, setJsonSchema] = useState();
    const [uiSchema, setUiSchema] = useState();
    const [updatePayload, setUpdatePayload] = useState({});

    useEffect(() => {
        if (parcel) {
            const newParcelCopy = JSON.parse(JSON.stringify(parcel));
            setParcelAdditionalData(newParcelCopy.additional_data);

            const baseParcelSchema = parcel.additional_data_schema.json_schema;
            if (_.isEmpty(baseParcelSchema)) {
                setJsonSchema(null);
            } else {
                setJsonSchema({
                    ...baseParcelSchema,
                    // Override required fields list so that we can submit the form without filling all fields
                    // We do this here instead of in the backend because the backend validator uses the required list to ensure that the core interface doesn't change
                    required: [],
                });
            }

            setUiSchema(parcel.additional_data_schema.ui_schema);
        }
    }, [parcel]);

    useEffect(() => {
        // Listen to form field changes and generate update payload object
        if (parcelAdditionalData) {
            setUpdatePayload(generateUpdatePayload());
        }
    }, [parcelAdditionalData, parcel.additional_data]);

    const generateUpdatePayload = () => {
        // Compare original parcel additional data with local form state
        const baseUpdateObject = generateUpdateObject(
            parcel.additional_data,
            parcelAdditionalData,
            _.keys(parcel.additional_data)
        );

        // json stringify will automatically remove undefined values from objects
        // however, we want to do that preimptively to calculate if an update should be submitted
        _.forEach(baseUpdateObject, (value, key) => {
            if (value === undefined) {
                delete baseUpdateObject[key];
            }
        });
        return baseUpdateObject;
    };

    const handleChange = (event) => {
        const { formData } = event;

        setParcelAdditionalData(_.isEmpty(formData) ? null : formData);
    };

    const handleParcelUpdate = async (updatedParcelData) => {
        const updateParcelPayload = {
            orderId: parcel.order_id,
            parcelId: parcel.id,
            parcelData: updatedParcelData,
        };

        const { data } = await updateParcel(updateParcelPayload);
        return !!data;
    };

    const handleSubmit = (event) => {
        if (_.keys(updatePayload).length > 0) {
            // Only includes fields that have changed
            const updateParcelData = {
                additional_data: updatePayload,
            };

            handleParcelUpdate(updateParcelData).then((success) => {
                if (success) {
                    handleComplete();
                }
            });
        }
    };

    if (!jsonSchema) {
        return null;
    }

    return (
        <Stack container="row" alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
            <Box sx={{ maxWidth: '480px' }}>
                <Form
                    schema={jsonSchema}
                    uiSchema={uiSchema}
                    validator={validator}
                    fields={customFields}
                    widgets={customWidgets}
                    formData={parcelAdditionalData || {}}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    onError={(e) => console.log('errors', e)}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={2}
                        sx={{ width: '100%', paddingTop: (theme) => theme.spacing(1) }}
                    >
                        <Button variant="text" color="default" onClick={() => handleCancel()} disableElevation>
                            Cancel
                        </Button>
                        <LoadingButton
                            color="primary"
                            type="submit"
                            variant="contained"
                            sx={{
                                minWidth: '160px',
                            }}
                            loading={isLoading}
                            disabled={_.keys(updatePayload).length === 0}
                            disableElevation
                        >
                            Update
                        </LoadingButton>
                    </Stack>
                </Form>
            </Box>
        </Stack>
    );
};

EditParcel.propTypes = {
    parcel: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleComplete: PropTypes.func.isRequired,
};

export default EditParcel;
