import { useParams } from 'react-router-dom';

import Parcel from 'components/Order/Parcel';

function ParcelPage({ orderId }) {
    let { parcel_id: parcelId } = useParams();

    return <Parcel orderId={orderId} parcelId={parcelId} />;
}

export default ParcelPage;
