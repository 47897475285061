import _ from 'lodash';
import { useMemo, useState } from 'react';

import DocumentMetaForm, { formatDocumentMetaPayload } from 'components/DocumentList/components/DocumentMetaForm';

const CustomDocumentMetaField = (props) => {
    // NOTE: this field wraps the standard document form and handles interoperability with the parent rjsf form
    // Because the document interface is fairly standardized, we do not pass in much of the schema or uiSchema
    // formData can be null or object with document fields
    // uses props.formData and props.onChange to update the parent form's document data
    // uses props.schema.title for the label
    // uses props.uiSchema.ui:options.required to determine if the document fields should be required
    const [documentMetaData, setDocumentMetaData] = useState({});

    const handleUpdateFormData = (newFormData) => {
        props.onChange(formatDocumentMetaPayload(newFormData));
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(handleUpdateFormData, 500), []);

    const handleUpdateDocumentMeta = (newDocumentMetaData) => {
        setDocumentMetaData(newDocumentMetaData);
        debouncedChangeHandler(newDocumentMetaData);
    };

    // Defaults to enforce required fields - must be explicitly set to false to disable
    // const formData = _.get(props, 'formData', {});
    const formContext = _.get(props, 'formContext', {});
    const uiSchema = _.get(props, 'uiSchema', {});
    const required = _.get(uiSchema, 'ui:options.required', false);
    const label = _.get(props, 'schema.title');
    const disabled = _.get(props, 'readonly', false);
    const requiredFields = _.get(props, 'schema.required', []);
    const disabledFields = [];
    _.forEach(uiSchema, (value, key) => {
        if (_.get(value, 'ui:readonly', false)) {
            disabledFields.push(key);
        }
    });

    return (
        <DocumentMetaForm
            documentMetaData={documentMetaData}
            setDocumentMetaData={handleUpdateDocumentMeta}
            label={label}
            showLabel={false}
            required={required}
            disabled={disabled}
            requiredFields={requiredFields}
            disabledFields={disabledFields}
            formContext={formContext}
        />
    );
};

export default CustomDocumentMetaField;
