import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { formatISODate } from 'helpers/utils';

import ParcelBreadcrumbs from 'components/Order/Parcel/components/ParcelBreadcrumbs';
import ParcelStatusSelect from 'components/Order/Parcel/components/ParcelStatusSelect';
import useAssignmentListData from 'components/common/hooks/useAssignmentListData';

import AssignmentContractList from './components/AssignmentContractList';

function Assignments({ orderId }) {
    const {
        order,
        assignmentMetaParcel,
        assignmentContractParcels,
        isError,
        isLoading,
        isFetching,
    } = useAssignmentListData(orderId);

    // const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    // const {
    //     data: assignmentContractParcels,
    //     isError: assignmentParcelsError,
    //     isLoading: assignmentParcelsLoading,
    // } = useGetOrderAssignmentsQuery(orderId);

    // const assignmentMetaParcel = useMemo(() => {
    //     // Find the assignment meta parcel in the order
    //     if (!order) {
    //         return null;
    //     }

    //     return findOrderParcelByType(order, PARCEL_TYPE.assignment);
    // }, [order]);

    // // TODO maybe manage in child component?
    // const assignmentContractParcelsOLD = useMemo(() => {
    //     // Find all child parcels of the assignment meta parcel
    //     if (!order || !assignmentMetaParcel) {
    //         return [];
    //     }

    //     return findChildParcelsInOrder(order, assignmentMetaParcel.id);
    // }, [order, assignmentMetaParcel]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!assignmentMetaParcel) {
        return <div>Assignment not found in order ({orderId})</div>;
    }

    return (
        <Stack spacing={3}>
            <ParcelBreadcrumbs parcel={assignmentMetaParcel} />

            <Stack spacing={0}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h1">{assignmentMetaParcel.name}</Typography>

                    <Stack direction="row" spacing={2} alignItems="center">
                        {assignmentMetaParcel.required_complete_date && (
                            <Typography variant="body2" color="text.primary" fontWeight={500}>
                                Due: {formatISODate(assignmentMetaParcel.required_complete_date)}
                            </Typography>
                        )}

                        <ParcelStatusSelect parcel={assignmentMetaParcel} />
                    </Stack>
                </Stack>
            </Stack>

            <Box sx={{ flexGrow: 1 }}>
                <AssignmentContractList
                    order={order}
                    assignmentMetaParcel={assignmentMetaParcel}
                    assignmentContractParcels={assignmentContractParcels}
                />
            </Box>
        </Stack>
    );
}

Assignments.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default Assignments;
