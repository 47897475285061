import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { createSearchParams } from 'react-router-dom';

import useDashboardCount from 'components/Dashboard/hooks/useDashboardCount';
import {
    DATE_RANGE_MAP,
    LIEN_CLEARED_STATUS,
    ORDER_FILTER_TYPE,
    ORDER_STATUS,
    ORDER_TITLE_FILTER_TYPE,
    TRANSACTION_TYPE,
} from 'helpers/constants';
import { calculateDateRangePreset, convertDateRangeToTimestamp, pluralize } from 'helpers/utils';
import SummaryBox from './SummaryBox';

function DashboardBoxStack() {
    const [thisWeekRange, setThisWeekRange] = useState(calculateDateRangePreset(DATE_RANGE_MAP.this_week));
    const [nextWeekRange, setNextWeekRange] = useState(calculateDateRangePreset(DATE_RANGE_MAP.next_week));

    const [dashboardCount, { loading: countLoading, error: countError }] = useDashboardCount({
        thisWeekRange,
        nextWeekRange,
    });

    return (
        <Stack spacing={3}>
            <Stack direction="column" spacing={1}>
                <Typography variant="sectionHeader">Open</Typography>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <SummaryBox
                        mainCount={dashboardCount.open.purchases}
                        mainText={pluralize('Purchase', dashboardCount.open.purchases)}
                        secondaryText="Open"
                        navLink={{
                            pathname: '/orders',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.transactionType]: TRANSACTION_TYPE.purchase,
                                [ORDER_FILTER_TYPE.orderStatus]: ORDER_STATUS.open,
                            })}`,
                        }}
                        // activeColor="purchase.light"
                        // activeBackground="purchase.dark"
                        // activeColor="purchase.dark"
                        // activeBackground="purchase.light"
                    />

                    <SummaryBox
                        mainCount={dashboardCount.open.refinances}
                        mainText={pluralize('Refinance', dashboardCount.open.refinances)}
                        secondaryText="Open"
                        navLink={{
                            pathname: '/orders',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.transactionType]: TRANSACTION_TYPE.refinance,
                                [ORDER_FILTER_TYPE.orderStatus]: ORDER_STATUS.open,
                            })}`,
                        }}
                        // activeColor="refinance.light"
                        // activeBackground="refinance.dark"
                        // activeColor="refinance.dark"
                        // activeBackground="refinance.light"
                    />

                    <SummaryBox
                        mainCount={dashboardCount.open.titleIssues}
                        mainText={`Title ${pluralize('Issue', dashboardCount.open.titleIssues)}`}
                        secondaryText="Unresolved"
                        navLink="/title"
                        // activeColor="error.light"
                        // activeBackground="error.dark"
                        // activeColor="error.dark"
                        // activeBackground="error.light"
                    />
                </Stack>
            </Stack>

            <Stack direction="column" spacing={1}>
                <Typography variant="sectionHeader">This Week</Typography>

                <Stack direction="row" alignItems="center" spacing={3}>
                    <SummaryBox
                        mainCount={dashboardCount.thisWeek.closingPurchases}
                        mainText={pluralize('Purchase', dashboardCount.thisWeek.closingPurchases)}
                        secondaryText="Closing This Week"
                        navLink={{
                            pathname: '/closing',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.transactionType]: TRANSACTION_TYPE.purchase,
                                [ORDER_FILTER_TYPE.closeDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.thisWeek.dateRange
                                ),
                            })}`,
                        }}
                        // activeColor="purchase.light"
                        // activeBackground="purchase.dark"
                        // activeColor="purchase.dark"
                        // activeBackground="purchase.light"
                    />

                    <SummaryBox
                        mainCount={dashboardCount.thisWeek.closingRefinances}
                        mainText={pluralize('Refinance', dashboardCount.thisWeek.closingRefinances)}
                        secondaryText="Closing This Week"
                        navLink={{
                            pathname: '/closing',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.transactionType]: TRANSACTION_TYPE.refinance,
                                [ORDER_FILTER_TYPE.closeDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.thisWeek.dateRange
                                ),
                            })}`,
                        }}
                        // activeColor="refinance.light"
                        // activeBackground="refinance.dark"
                        // activeColor="refinance.dark"
                        // activeBackground="refinance.light"
                    />

                    <SummaryBox
                        mainCount={dashboardCount.thisWeek.titleIssues}
                        mainText={`Title ${pluralize('Issue', dashboardCount.thisWeek.titleIssues)}`}
                        secondaryText="Unresolved"
                        navLink={{
                            pathname: '/title',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.orderStatus]: ORDER_STATUS.open,
                                [ORDER_FILTER_TYPE.closeDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.thisWeek.dateRange
                                ),
                                [ORDER_TITLE_FILTER_TYPE.clearedStatus]: LIEN_CLEARED_STATUS.not_cleared,
                            })}`,
                        }}
                        // activeColor="error.light"
                        // activeBackground="error.dark"
                        // activeColor="error.dark"
                        // activeBackground="error.light"
                    />

                    <SummaryBox
                        mainCount={dashboardCount.thisWeek.fundings}
                        mainText={`${pluralize('Funding', dashboardCount.thisWeek.fundings)}`}
                        secondaryCount={dashboardCount.thisWeek.funded}
                        secondaryText="Funded"
                        navLink={{
                            pathname: '/funding',
                        }}
                        // activeColor="primary.light"
                        // activeBackground="primary.dark"
                        // activeColor="primary.dark"
                        // activeBackground="primary.light"
                    />

                    {/* <SummaryBox
                        mainCount={dashboardCount.thisWeek.closings}
                        mainText={`${pluralize('Closing', dashboardCount.thisWeek.closings)}`}
                        secondaryCount={dashboardCount.thisWeek.closed}
                        secondaryText="Closed"
                        navLink={{
                            pathname: '/closing',
                        }}
                        activeColor="primary.contrastText"
                        // activeBackground="primary.main"
                        activeBackground="#8d2248"
                    /> */}

                    {/* <SummaryBox
                        mainCount={dashboardCount.thisWeek.closingTraditionalRefinances}
                        mainText={`Rate Term ${pluralize(
                            'Refi',
                            dashboardCount.thisWeek.closingTraditionalRefinances
                        )}`}
                        secondaryText="Closing This Week"
                        navLink={{
                            pathname: '/closing',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.transactionType]: TRANSACTION_TYPE.refinance,
                                [ORDER_FILTER_TYPE.orderType]: [
                                    TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.refinance].residential,
                                    TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.refinance].investment,
                                    TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.refinance].commercial,
                                ],
                                [ORDER_FILTER_TYPE.closeDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.thisWeek.dateRange
                                ),
                            })}`,
                        }}
                        activeColor="text.light"
                        activeBackground="refinance.contrastText"
                    />

                    <SummaryBox
                        mainCount={dashboardCount.thisWeek.closingCashoutRefinances}
                        mainText={`Cash-out ${pluralize('Refi', dashboardCount.thisWeek.closingCashoutRefinances)}`}
                        secondaryText="Closing This Week"
                        navLink={{
                            pathname: '/closing',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.transactionType]: TRANSACTION_TYPE.refinance,
                                [ORDER_FILTER_TYPE.orderType]:
                                    TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.refinance].cash_out,
                                [ORDER_FILTER_TYPE.closeDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.thisWeek.dateRange
                                ),
                            })}`,
                        }}
                        activeColor="text.light"
                        activeBackground="refinance.contrastText"
                    /> */}
                </Stack>
            </Stack>

            <Stack direction="column" spacing={1}>
                <Typography variant="sectionHeader">Next Week</Typography>

                <Stack direction="row" alignItems="center" spacing={3}>
                    <SummaryBox
                        mainCount={dashboardCount.nextWeek.closingPurchases}
                        mainText={pluralize('Purchase', dashboardCount.nextWeek.closingPurchases)}
                        secondaryText="Closing Next Week"
                        navLink={{
                            pathname: '/closing',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.transactionType]: TRANSACTION_TYPE.purchase,
                                [ORDER_FILTER_TYPE.closeDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.nextWeek.dateRange
                                ),
                            })}`,
                        }}
                        // activeColor="purchase.light"
                        // activeBackground="purchase.dark"
                        // activeColor="purchase.dark"
                        // activeBackground="purchase.light"
                    />

                    <SummaryBox
                        mainCount={dashboardCount.nextWeek.closingRefinances}
                        mainText={pluralize('Refinance', dashboardCount.nextWeek.closingRefinances)}
                        secondaryText="Closing Next Week"
                        navLink={{
                            pathname: '/closing',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.transactionType]: TRANSACTION_TYPE.refinance,
                                [ORDER_FILTER_TYPE.closeDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.nextWeek.dateRange
                                ),
                            })}`,
                        }}
                        // activeColor="refinance.light"
                        // activeBackground="refinance.dark"
                        // activeColor="refinance.dark"
                        // activeBackground="refinance.light"
                    />

                    <SummaryBox
                        mainCount={dashboardCount.nextWeek.titleIssues}
                        mainText={`Title ${pluralize('Issue', dashboardCount.nextWeek.titleIssues)}`}
                        secondaryText="Unresolved"
                        navLink={{
                            pathname: '/title',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.orderStatus]: ORDER_STATUS.open,
                                [ORDER_FILTER_TYPE.closeDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.nextWeek.dateRange
                                ),
                                [ORDER_TITLE_FILTER_TYPE.clearedStatus]: LIEN_CLEARED_STATUS.not_cleared,
                            })}`,
                        }}
                        // activeColor="error.light"
                        // activeBackground="error.dark"
                        // activeColor="error.dark"
                        // activeBackground="error.light"
                    />

                    <SummaryBox
                        mainCount={dashboardCount.nextWeek.fundings}
                        mainText={pluralize('Funding', dashboardCount.nextWeek.fundings)}
                        secondaryText="Next Week"
                        navLink={{
                            pathname: '/funding',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.disbursementDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.nextWeek.dateRange
                                ),
                            })}`,
                        }}
                        // activeColor="primary.light"
                        // activeBackground="primary.dark"
                        // activeColor="primary.dark"
                        // activeBackground="primary.light"
                    />

                    {/* <SummaryBox
                        mainCount={dashboardCount.nextWeek.closings}
                        mainText={pluralize('Closing', dashboardCount.nextWeek.closings)}
                        secondaryText="Next Week"
                        navLink={{
                            pathname: '/closing',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.closeDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.nextWeek.dateRange
                                ),
                            })}`,
                        }}
                        activeColor="primary.contrastText"
                        // activeBackground="primary.main"
                        activeBackground="#8d2248"
                    /> */}

                    {/* <SummaryBox
                        mainCount={dashboardCount.nextWeek.closingTraditionalRefinances}
                        mainText={`Rate Term ${pluralize(
                            'Refi',
                            dashboardCount.thisWeek.closingTraditionalRefinances
                        )}`}
                        secondaryText="Closing Next Week"
                        navLink={{
                            pathname: '/closing',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.transactionType]: TRANSACTION_TYPE.refinance,
                                [ORDER_FILTER_TYPE.orderType]: [
                                    TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.refinance].residential,
                                    TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.refinance].investment,
                                    TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.refinance].commercial,
                                ],
                                [ORDER_FILTER_TYPE.closeDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.thisWeek.dateRange
                                ),
                            })}`,
                        }}
                        activeColor="text.light"
                        activeBackground="refinance.contrastText"
                    />

                    <SummaryBox
                        mainCount={dashboardCount.nextWeek.closingCashoutRefinances}
                        mainText={`Cash-out ${pluralize('Refi', dashboardCount.nextWeek.closingCashoutRefinances)}`}
                        secondaryText="Closing Next Week"
                        navLink={{
                            pathname: '/closing',
                            search: `?${createSearchParams({
                                [ORDER_FILTER_TYPE.transactionType]: TRANSACTION_TYPE.refinance,
                                [ORDER_FILTER_TYPE.orderType]:
                                    TRANSACTION_ORDER_TYPE[TRANSACTION_TYPE.refinance].cash_out,
                                [ORDER_FILTER_TYPE.closeDateRange]: convertDateRangeToTimestamp(
                                    dashboardCount.nextWeek.dateRange
                                ),
                            })}`,
                        }}
                        activeColor="text.light"
                        activeBackground="refinance.contrastText"
                    /> */}
                </Stack>
            </Stack>
        </Stack>
    );
}

export default DashboardBoxStack;
