import BoltIcon from '@mui/icons-material/Bolt';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DoneIcon from '@mui/icons-material/Done';
import FlagIcon from '@mui/icons-material/Flag';
import LabelIcon from '@mui/icons-material/Label';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useGetParcelStatusHistoryQuery } from 'api/parcel';
import AdditionalDataPopover from 'components/common/AdditionalDataPopover';
import { ACTION_STATUS, ENTITY_TYPE, PARCEL_STATUS, PARCEL_TERMINAL_STATUSES } from 'helpers/constants';
import { findChildParcelsInOrder, formatISODate } from 'helpers/utils';

function ParcelTimelineItem({ timelineItem, last = false }) {
    return (
        <TimelineItem>
            <TimelineOppositeContent color="textSecondary" fontSize={12}>
                <Stack direction="column" spacing={0}>
                    <Typography color="inherit" fontSize="inherit">
                        {formatISODate(timelineItem.timestamp, 'P')}
                    </Typography>
                    <Typography color="inherit" fontSize="inherit">
                        {formatISODate(timelineItem.timestamp, 'p')}
                    </Typography>
                </Stack>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot sx={timelineItem.sx}>{timelineItem.icon}</TimelineDot>
                {!last && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Stack direction="column" spacing={0}>
                        <Typography variant="body1" component="span">
                            {timelineItem.contentPrimary}
                        </Typography>
                        <Typography variant="body2">{timelineItem.contentSecondary}</Typography>
                    </Stack>

                    <AdditionalDataPopover
                        additionalData={timelineItem.additionalData}
                        additionalDataSchema={timelineItem.additionalDataSchema}
                    />
                </Stack>
            </TimelineContent>
        </TimelineItem>
    );
}

function ParcelTimeline({ order, parcel }) {
    // TODO get core parcel dates (created, due, current status)
    // TODO get status history
    const { data: parcelStatusHistory, isLoading: parcelStatusHistoryLoading } = useGetParcelStatusHistoryQuery(
        parcel.id
    );

    const transactionType = order.transaction_type;

    const childParcels = useMemo(() => {
        if (order) {
            return findChildParcelsInOrder(order, parcel.id);
        }

        return [];
    }, [order, parcel.id]);

    const completedActionList = useMemo(() => {
        if (parcel) {
            const parcelActionList = [];
            const combinedParcelList = [parcel, ...childParcels];

            _.forEach(combinedParcelList, (parcelItem) => {
                _.forEach(parcelItem.complete_actions, (actionId) => {
                    const completedAction = parcelItem.action_map[actionId];
                    const contentSecondary =
                        completedAction.status === ACTION_STATUS.canceled ? 'Action Canceled' : 'Action Completed';
                    parcelActionList.push({
                        id: actionId,
                        dotColor: 'action',
                        icon: <BoltIcon fontSize="small" />,
                        entityType: ENTITY_TYPE.action,
                        contentOpposite: `${formatISODate(completedAction.updated_datetime, 'P p')}`,
                        contentPrimary: completedAction.friendly_name,
                        contentSecondary: contentSecondary,
                        timestamp: completedAction.updated_datetime,
                        additionalData: completedAction.additional_data,
                        additionalDataSchema: completedAction.additional_data_schema,
                        sx: { bgcolor: 'action.light', color: 'action.main', boxShadow: 'none' },
                    });
                });
            });

            return parcelActionList;
        }

        return [];
    }, [parcel, childParcels]);

    const parcelStatusHistoryEvents = useMemo(() => {
        if (!parcelStatusHistory || !transactionType) {
            return [];
        }

        const parcelStatusTimelineEvents = _.map(parcelStatusHistory, (statusHistoryItem, index) => {
            if (index === 0) {
                return {
                    id: 'parcel-start',
                    dotColor: 'info',
                    icon: <FlagIcon fontSize="small" />,
                    entityType: ENTITY_TYPE.parcel,
                    contentOpposite: `${formatISODate(statusHistoryItem.created_datetime, 'P p')}`,
                    contentPrimary: _.startCase(statusHistoryItem.status),
                    contentSecondary: 'Status Initialized',
                    timestamp: statusHistoryItem.created_datetime,
                    sx: { bgcolor: `${transactionType}.light`, color: `${transactionType}.dark`, boxShadow: 'none' },
                };
            }

            const isTerminalStatus = _.includes(PARCEL_TERMINAL_STATUSES, statusHistoryItem.status);
            const isComplete = statusHistoryItem.status === PARCEL_STATUS.complete;
            const isCancelled = statusHistoryItem.status === PARCEL_STATUS.canceled;

            const color = isComplete ? 'primary' : isCancelled ? 'error' : transactionType;
            const icon = isComplete ? (
                <DoneIcon fontSize="small" />
            ) : isCancelled ? (
                <DoDisturbIcon fontSize="small" />
            ) : (
                <LabelIcon fontSize="small" />
            );
            return {
                id: statusHistoryItem.id,
                dotColor: color,
                icon: icon,
                entityType: ENTITY_TYPE.parcel,
                contentOpposite: `${formatISODate(statusHistoryItem.created_datetime, 'P p')}`,
                contentPrimary: _.startCase(statusHistoryItem.status),
                contentSecondary: 'Status Updated',
                timestamp: statusHistoryItem.created_datetime,
                sx: {
                    bgcolor: `${color}.light`,
                    color: `${color}.dark`,
                    boxShadow: 'none',
                },
            };
        });

        return parcelStatusTimelineEvents;
    }, [parcelStatusHistory, transactionType]);

    const parcelTimelineEvents = useMemo(() => {
        const combinedTimelineEvents = [...parcelStatusHistoryEvents, ...completedActionList];

        return _.sortBy(combinedTimelineEvents, ['timestamp']);
    }, [parcelStatusHistoryEvents, completedActionList]);

    return (
        <Paper variant="outlined">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ padding: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Timeline</Typography>
            </Stack>

            <Box
                sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    overflowY: 'auto',
                    maxHeight: '480px',
                }}
            >
                <Timeline
                    position="right"
                    sx={{
                        margin: 0,
                        padding: 0,
                        [`& .${timelineOppositeContentClasses.root}`]: {
                            paddingLeft: 0,
                            flex: 0.2,
                        },
                        [`& .${timelineContentClasses.root}`]: {
                            paddingRight: 0,
                        },
                    }}
                >
                    {_.map(parcelTimelineEvents, (timelineItem, index) => (
                        <ParcelTimelineItem
                            key={timelineItem.id}
                            timelineItem={timelineItem}
                            last={parcelTimelineEvents.length === index + 1}
                        />
                    ))}
                </Timeline>
            </Box>
        </Paper>
    );
}

ParcelTimeline.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default ParcelTimeline;
