import PropTypes from 'prop-types';

import MemberSelectField from 'components/Order/Members/components/MemberSelectField';

function DocumentAccessField({
    documentAccess,
    setDocumentAccess,
    orderMemberList,
    disabled = false,
    showLabel = true,
}) {
    if (!orderMemberList || orderMemberList.length === 0) {
        return null;
    }

    return (
        <MemberSelectField
            selectedOrderMembers={documentAccess}
            setSelectedOrderMembers={setDocumentAccess}
            orderMembers={orderMemberList}
            label="Document Access"
            showLabel={showLabel}
            TextFieldProps={{
                disabled: disabled,
                sx: {
                    width: '200px',
                },
            }}
        />
    );
}

DocumentAccessField.propTypes = {
    documentAccess: PropTypes.array.isRequired,
    setDocumentAccess: PropTypes.func.isRequired,
    orderMemberList: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    showLabel: PropTypes.bool,
};

export default DocumentAccessField;
