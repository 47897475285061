import { addBusinessDays, addDays, addMonths, addYears, differenceInMinutes, endOfMonth, endOfWeek, format, getUnixTime, isBefore, isWithinInterval, parseISO, startOfMonth, startOfWeek } from 'date-fns';
import _ from 'lodash';
import Config from '../config';
import { ACCESS_LOG_FILTER_TYPE, ASSIGNED_ACTION_FILTER_TYPE, DATE_RANGE_MAP, DOCUMENT_FILTER_TYPE, DOCUMENT_TYPE_NAME_OVERRIDE, EMAIL_LOG_FILTER_TYPE, EVENT_LOG_FILTER_TYPE, ORDER_FILTER_TYPE, PARCEL_STATUS, PARCEL_TYPE, SIGNING_METHOD_PARCEL_MAP, TRANSACTION_ORDER_TYPE, TRANSACTION_PARTY_TYPE, TRANSACTION_TYPE, TRANSACTION_TYPE_PARTY_MAP } from './constants';

export function requestDocumentDownload(documentId) {
    const url = `${Config.api_base}/document/${documentId}/file?attachment`;
    
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            window.open(url);
            resolve(true);
        }, 500);
    });
};

export function formatAddress({ street, street_additional, city, state, zipcode }, lineBreak = false) {
    // lineBreak: if true, will require rendering component to include "white-space: pre-line" css
    const streetStr = _.join(_.compact([street, street_additional]), ' ');
    const stateZipStr = _.join(_.compact([state, zipcode]), ' ');
    const cityStateZipStr = _.join(_.compact([city, stateZipStr]), ', ');

    if (lineBreak) {
        return `${streetStr}\n${cityStateZipStr}`;
    }
    return `${streetStr}, ${cityStateZipStr}`;
}

export function formatPhone(rawPhone) {
    // expected raw format: +12223334444
    // expected return format: +1 (222) 333-4444
    if (rawPhone.length !== 12) {
        console.error("invalid raw phone format provided");
        return rawPhone;
    }

    const countryCode = rawPhone.substring(0, 2);
    const areaCode = rawPhone.substring(2, 5);
    const prefix = rawPhone.substring(5, 8);
    const lineNumber = rawPhone.substring(8);
    return `${countryCode} (${areaCode}) ${prefix}-${lineNumber}`
}

export function unmaskPhone(maskedPhone) {
    // ex: +1 (222) 333-4444 -> +12223334444
    return _.replace(maskedPhone, /[\s()-]/g, '');
}

export function convertArrayCase(rawArray, convertCase = _.snakeCase) {
    return _.map(rawArray, (item) => {
        return convertPayloadCase(item, convertCase);
    });
}

export function convertDictCase(rawDict, convertCase = _.snakeCase) {
    const caseConvertedData = {};
    _.forEach(rawDict, (value, key) => {
        caseConvertedData[convertCase(key)] = convertPayloadCase(value, convertCase);
    });
    return caseConvertedData;
}

export function convertPayloadCase(rawPayload, convertCase = _.snakeCase) {
    if (_.isObject(rawPayload)) {
        if (_.isArray(rawPayload)) {
            return convertArrayCase(rawPayload, convertCase);
        }
        return convertDictCase(rawPayload, convertCase);
    } else {
        return rawPayload;
    }
}

export function findParcelInOrder(order, parcelId) {
    return _.find(order.parcels, function (o) {
        return o.id === parcelId;
    });
}

export function findChildParcelsInOrder(order, parcelId) {
    return _.filter(order.parcels, function (o) {
        return o.parent_parcel_id === parcelId;
    });
}

export function findOrderParcelByType(order, parcelType) {
    return _.find(order.parcels, function (o) {
        return o.type === parcelType;
    });
}

export function findActionInOrder(order, actionId) {
    let action = null;

    _.forEach(order.parcels, (parcel) => {
        const parcel_action = parcel.action_map[actionId];
        if (parcel_action) {
            action = parcel_action;
            // Break loop
            return false;
        }
    });

    return action;
}

export function findLienInOrder(order, lienId) {
    return _.find(order.liens, function (o) {
        return o.id === lienId;
    });
}

export function findPayoffInOrder(order, payoffId) {
    return _.find(order.payoffs, function (o) {
        return o.id === payoffId;
    });
}

function buildParcelLink(parcelChain, order, parcelId) {
    const parcel = findParcelInOrder(order, parcelId);
    if (!parcel) {
        return;
    }

    parcelChain.push(parcel);

    if (parcel.parent_parcel_id) {
        buildParcelLink(parcelChain, order, parcel.parent_parcel_id);
    }
}

export function getParcelChain(order, startParcelId, reverse = true) {
    const parcelChain = [];

    if (!order) {
        return parcelChain;
    }

    buildParcelLink(parcelChain, order, startParcelId);

    return reverse ? parcelChain.reverse() : parcelChain;
}

export function isEqualReturn(returnArg, compareArg, equalReturn = undefined) {
    return _.isEqual(returnArg, compareArg) ? equalReturn : returnArg;
}

export function generateUpdateObject(originalObject, compareObject, keyList, keyMap = null) {
    const updateObject = {};
    _.forEach(keyList, (key) => {
        let responseKey = key;
        if (keyMap && keyMap[key]) {
            responseKey = keyMap[key];
        }
        updateObject[responseKey] = isEqualReturn(_.get(compareObject, key), _.get(originalObject, key));
    });
    return updateObject;
}

export function evaluateConditional(param1, param2, operator) {
    switch (operator) {
        case 'equals':
            return param1 === param2;
        case 'not_equals':
            return param1 !== param2;
        default:
            console.error('Operator not handled', operator);
            return false;
    }
}


// TODO replace with date-fns function
export function calculateDateDifference(startDate, endDate) {
    const difference = endDate - startDate;
    // the difference is in milliseconds.
    // divide it by milliseconds in a day to get the difference in days
    const differenceInDays = Math.ceil(difference / (24 * 60 * 60 * 1000));

    return differenceInDays;
}

export function getInitials(firstName, lastName) {
    return `${firstName[0]}${lastName[0]}`
}


export function calculateDateRangePreset(dateRangeKey) {
    const today = new Date();

    switch(dateRangeKey) {
        case DATE_RANGE_MAP.this_week:
            return [startOfWeek(today), endOfWeek(today)];
        case DATE_RANGE_MAP.next_week:
            const nextWeekStart = addDays(startOfWeek(today), 7);
            return [startOfWeek(nextWeekStart), endOfWeek(nextWeekStart)];
        case DATE_RANGE_MAP.last_week:
            const lastWeekStart = addDays(startOfWeek(today), -7);
            return [startOfWeek(lastWeekStart), endOfWeek(lastWeekStart)];
        case DATE_RANGE_MAP.this_month:
            return [startOfMonth(today), endOfMonth(today)];
        case DATE_RANGE_MAP.next_month:
            const nextMonthStart = addMonths(today, 1);
            return [startOfMonth(nextMonthStart), endOfMonth(nextMonthStart)];
        case DATE_RANGE_MAP.last_month:
            const lastMonthStart = addMonths(today, -1);
            return [startOfMonth(lastMonthStart), endOfMonth(lastMonthStart)];
        default:
            return [null, null];
    }
}

export const convertDateRangeToTimestamp = (dateRange) => {
    const timestampRange = [];
    _.forEach(dateRange, (date) => {
        timestampRange.push(getUnixTime(date));
    });
    
    return timestampRange;
}

export function formatISODate(rawDate, to_format='MM/dd/yyyy') {
    return format(parseISO(rawDate), to_format)
}

export const copyToClipboard = (text) => {
    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
            navigator.clipboard.writeText(text);
        } else {
            alert("Clipboard access not granted!");
        }
    });
}


export const ORDER_FILTER_FUNC_MAP = {
    [ORDER_FILTER_TYPE.search]: (order, filterValue) => {
        if (
            !_.includes(order.id, filterValue) &&
            !_.includes(order.qualia_order_number, filterValue) &&
            !_.includes(_.toLower(formatAddress(order.property_address)), _.toLower(filterValue)) &&
            !_.includes(order.close_date, filterValue)
        ) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.transactionType]: (order, filterValue) => {
        if (order.transaction_type !== filterValue) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.orderType]: (order, filterValue) => {
        // Handles either a single order type or an array of order types
        if (_.isArray(filterValue)) {
            if (!_.includes(filterValue, order.order_type)) {
                return false;
            }
        } else if (order.order_type !== filterValue) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.workflowType]: (order, filterValue) => {
        if (order.title_workflow !== filterValue) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.orderStatus]: (order, filterValue) => {
        // Handles either a single order status or an array of order statuses
        const lowerCaseOrderStatus = _.lowerCase(order.status);

        if (_.isArray(filterValue)) {
            const matchLowercaseFilterValue = _.find(filterValue, (value) => _.lowerCase(value) === lowerCaseOrderStatus);
            if (!matchLowercaseFilterValue) {
                return false;
            }
        } else if (lowerCaseOrderStatus !== _.lowerCase(filterValue)) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.closeDateStatus]: (order, filterValue) => {
        // TODO rethink
        const nullValue = filterValue === 'null';
        if (nullValue) {
            if (order.close_date) {
                return false;
            }
        } else {
            const boolValue = filterValue === 'true';
            if (order.confirmed_close_date !== boolValue) {
                return false;
            }
            if (!boolValue && !order.close_date) {
                return false;
            }
        }

        return true;
    },
    [ORDER_FILTER_TYPE.closeDateGreaterThan]: (order, filterValue) => {
        // NOTE: filter value should be a date
        const closeDate = parseISO(order.close_date)

        // If close date is before provided date, remove order
        if (isBefore(closeDate, filterValue)) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.closeDateRange]: (order, filterValue) => {
        if (!filterValue || (!filterValue[0] && !filterValue[1])) {
            return;
        }

        const interval = {
            start: filterValue[0] || new Date(null),    // null will start at unix beginning in 1969
            end: filterValue[1] || addYears(new Date(), 10) // if no end date, add 10 years from now
        }
    
        // Ensure date is after the start date and before the end date
        if (!isWithinInterval(parseISO(order.close_date), interval)) {
            return false;
        }

        return true
    },
    [ORDER_FILTER_TYPE.disbursementDateRange]: (order, filterValue) => {
        if (!filterValue || (!filterValue[0] && !filterValue[1])) {
            return;
        }

        const interval = {
            start: filterValue[0] || new Date(null),    // null will start at unix beginning in 1969
            end: filterValue[1] || addYears(new Date(), 10) // if no end date, add 10 years from now
        }
    
        // Ensure date is after the start date and before the end date
        if (!isWithinInterval(parseISO(order.disbursement_date), interval) || !order.disbursement_date) {
            return false;
        }
    
        return true;
    },
};

// An array declared here will maintain a stable reference rather than be re-created again
const emptyOrdersArray = []

export const filterOrders = (orders, filters) => {
    // NOTE: This function should be memoized to prevent unnecessary re-renders
    // Can be done in a react component using useMemo
    return orders?.filter((order) => {
        let includeOrder = true;

        // For each order, iterate over each provided filter
        _.forEach(filters, (filterValue, localFilterType) => {
            // If filter value and filter function exist, run filter function against order
            if (!!filterValue && ORDER_FILTER_FUNC_MAP[localFilterType]) {
                if (_.isArray(filterValue) && _.isEmpty(filterValue)) {
                    // If filter value is an array and is empty, skip filter
                    return;
                }

                // Filter functions return true, false or nothing (incorrect values provided)
                const include = ORDER_FILTER_FUNC_MAP[localFilterType](order, filterValue);
                if (include === false) {
                    // If filter function return is false, set includeOrder to false
                    includeOrder = false;
                    // And return false to exit filter loop
                    return false;
                }
            }
        });


        return includeOrder;
     }) ?? emptyOrdersArray
}

export const DOCUMENT_FILTER_FUNC_MAP = {
    [DOCUMENT_FILTER_TYPE.search]: (document, filterValue) => {
        if (
            !_.includes(document.id, filterValue) &&
            !_.includes(_.toLower(document.name), _.toLower(filterValue)) &&
            !_.includes(document.content_type, filterValue)
        ) {
            return false;
        }

        return true;
    },
    [DOCUMENT_FILTER_TYPE.documentType]: (document, filterValue) => {
        if (document.type !== filterValue) {
            return false;
        }

        return true;
    },
    [DOCUMENT_FILTER_TYPE.access]: (document, filterValue) => {
        // Currently assumes filterValue is either empty string or single string uuid (order member id)
        // Search through array of access objects and check if any match the filterValue
        return !!_.find(document.access, (accessItem) => accessItem.order_member_id === filterValue);
    },
};

// An array declared here will maintain a stable reference rather than be re-created again
const emptyDocumentsArray = []

export const filterDocuments = (documents, filters) => {
    // NOTE: This function should be memoized to prevent unnecessary re-renders
    // Can be done in a react component using useMemo
    return documents?.filter((document) => {
        let includeDocument = true;

        // For each document, iterate over each provided filter
        _.forEach(filters, (filterValue, localFilterType) => {
            // If filter value and filter function exist, run filter function against document
            if (!!filterValue && DOCUMENT_FILTER_FUNC_MAP[localFilterType]) {
                // Filter functions return true, false or nothing (incorrect values provided)
                const include = DOCUMENT_FILTER_FUNC_MAP[localFilterType](document, filterValue);
                if (include === false) {
                    // If filter function return is false, set includeDocument to false
                    includeDocument = false;
                    // And return false to exit filter loop
                    return false;
                }
            }
        });


        return includeDocument;
     }) ?? emptyDocumentsArray
}


export const ASSIGNED_ACTION_FILTER_FUNC_MAP = {
    [ASSIGNED_ACTION_FILTER_TYPE.actionType]: (action, filterValue) => {
        if (action.type !== filterValue) {
            return false;
        }

        return true;
    },
    [ASSIGNED_ACTION_FILTER_TYPE.actionStatus]: (action, filterValue) => {
        if (action.status !== filterValue) {
            return false;
        }

        return true;
    },
    [ASSIGNED_ACTION_FILTER_TYPE.orderMemberId]: (action, filterValue) => {
        if (action.order_member_id !== filterValue) {
            return false;
        }

        return true;
    },
    [ASSIGNED_ACTION_FILTER_TYPE.linkedId]: (action, filterValue) => {
        if (action.linked_id !== filterValue) {
            return false;
        }

        return true;
    },
}

// An array declared here will maintain a stable reference rather than be re-created again
const emptyAssignedActionsArray = []

export const filterAssignedActions = (actions, filters) => {
    // NOTE: This function should be memoized to prevent unnecessary re-renders
    // Can be done in a react component using useMemo
    return actions?.filter((action) => {
        let includeAction = true;

        // For each email log, iterate over each provided filter
        _.forEach(filters, (filterValue, localFilterType) => {
            // If filter value and filter function exist, run filter function against email log
            if (!!filterValue && ASSIGNED_ACTION_FILTER_FUNC_MAP[localFilterType]) {
                // Filter functions return true, false or nothing (incorrect values provided)
                const include = ASSIGNED_ACTION_FILTER_FUNC_MAP[localFilterType](action, filterValue);
                if (include === false) {
                    // If filter function return is false, set includeEmailLog to false
                    includeAction = false;
                    // And return false to exit filter loop
                    return false;
                }
            }
        });


        return includeAction;
     }) ?? emptyAssignedActionsArray
}

export const EMAIL_LOG_FILTER_FUNC_MAP = {
    [EMAIL_LOG_FILTER_TYPE.search]: (emailLog, filterValue) => {
        if (
            !_.includes(emailLog.id, filterValue) &&
            !_.includes(_.toLower(_.get(emailLog, 'user.name', '')), _.toLower(filterValue)) &&  // TODO verify
            !_.includes(_.toLower(emailLog.recipient), filterValue)
        ) {
            return false;
        }

        return true;
    },
    [EMAIL_LOG_FILTER_TYPE.orderId]: (emailLog, filterValue) => {
        if (emailLog.order_id !== filterValue) {
            return false;
        }

        return true;
    },
    [EMAIL_LOG_FILTER_TYPE.userId]: (emailLog, filterValue) => {
        if (emailLog.user_id !== filterValue) {
            return false;
        }

        return true;
    },
    [EMAIL_LOG_FILTER_TYPE.messageId]: (emailLog, filterValue) => {
        if (emailLog.message_id !== filterValue) {
            return false;
        }

        return true;
    },
    [EMAIL_LOG_FILTER_TYPE.recipient]: (emailLog, filterValue) => {
        if (emailLog.recipient !== filterValue) {
            return false;
        }

        return true;
    },
    [EMAIL_LOG_FILTER_TYPE.emailType]: (emailLog, filterValue) => {
        if (emailLog.email_type !== filterValue) {
            return false;
        }

        return true;
    },
    [EMAIL_LOG_FILTER_TYPE.eventType]: (emailLog, filterValue) => {
        if (emailLog.event_type !== filterValue) {
            return false;
        }

        return true;
    },
};

// An array declared here will maintain a stable reference rather than be re-created again
const emptyEmailLogsArray = []

export const filterEmailLogs = (emailLogs, filters) => {
    // NOTE: This function should be memoized to prevent unnecessary re-renders
    // Can be done in a react component using useMemo
    return emailLogs?.filter((emailLog) => {
        let includeEmailLog = true;

        // For each email log, iterate over each provided filter
        _.forEach(filters, (filterValue, localFilterType) => {
            // If filter value and filter function exist, run filter function against email log
            if (!!filterValue && EMAIL_LOG_FILTER_FUNC_MAP[localFilterType]) {
                // Filter functions return true, false or nothing (incorrect values provided)
                const include = EMAIL_LOG_FILTER_FUNC_MAP[localFilterType](emailLog, filterValue);
                if (include === false) {
                    // If filter function return is false, set includeEmailLog to false
                    includeEmailLog = false;
                    // And return false to exit filter loop
                    return false;
                }
            }
        });


        return includeEmailLog;
     }) ?? emptyEmailLogsArray
}


export const ACCESS_LOG_FILTER_FUNC_MAP = {
    [ACCESS_LOG_FILTER_TYPE.search]: (accessLog, filterValue) => {
        if (
            !_.includes(accessLog.id, filterValue) &&
            !_.includes(_.toLower(_.get(accessLog, 'user.name', '')), _.toLower(filterValue))
        ) {
            return false;
        }

        return true;
    },
    [ACCESS_LOG_FILTER_TYPE.userId]: (accessLog, filterValue) => {
        if (accessLog.user_id !== filterValue) {
            return false;
        }

        return true;
    },

    [ACCESS_LOG_FILTER_TYPE.domainType]: (accessLog, filterValue) => {
        if (accessLog.event_domain !== filterValue) {
            return false;
        }

        return true;
    },
    [ACCESS_LOG_FILTER_TYPE.eventType]: (accessLog, filterValue) => {
        if (accessLog.event_type !== filterValue) {
            return false;
        }

        return true;
    },
};

// An array declared here will maintain a stable reference rather than be re-created again
const emptyAccessLogsArray = []

export const filterAccessLogs = (accessLogs, filters) => {
    // NOTE: This function should be memoized to prevent unnecessary re-renders
    // Can be done in a react component using useMemo
    return accessLogs?.filter((accessLog) => {
        let includeAccessLog = true;

        // For each email log, iterate over each provided filter
        _.forEach(filters, (filterValue, localFilterType) => {
            // If filter value and filter function exist, run filter function against access log
            if (!!filterValue && ACCESS_LOG_FILTER_FUNC_MAP[localFilterType]) {
                // Filter functions return true, false or nothing (incorrect values provided)
                const include = ACCESS_LOG_FILTER_FUNC_MAP[localFilterType](accessLog, filterValue);
                if (include === false) {
                    // If filter function return is false, set includeEmailLog to false
                    includeAccessLog = false;
                    // And return false to exit filter loop
                    return false;
                }
            }
        });


        return includeAccessLog;
     }) ?? emptyAccessLogsArray
}


export const EVENT_LOG_FILTER_FUNC_MAP = {
    [EVENT_LOG_FILTER_TYPE.search]: (eventLog, filterValue) => {
        if (
            !_.includes(eventLog.id, filterValue) &&
            !_.includes(eventLog.entity_id, filterValue)
            // TODO admin/user name search - difficult as we only store id on event log object
            // So we'd have to hydrate it with the user/admin object before filtering
        ) {
            return false;
        }

        return true;
    },
    [EVENT_LOG_FILTER_TYPE.orderId]: (eventLog, filterValue) => {
        if (eventLog.order_id !== filterValue) {
            return false;
        }

        return true;
    },
    [EVENT_LOG_FILTER_TYPE.entityId]: (eventLog, filterValue) => {
        if (eventLog.entity_id !== filterValue) {
            return false;
        }

        return true;
    },
    [EVENT_LOG_FILTER_TYPE.entityType]: (eventLog, filterValue) => {
        if (eventLog.entity_type !== filterValue) {
            return false;
        }

        return true;
    },
    [EVENT_LOG_FILTER_TYPE.eventType]: (eventLog, filterValue) => {
        if (eventLog.event_type !== filterValue) {
            return false;
        }

        return true;
    },
    [EVENT_LOG_FILTER_TYPE.adminId]: (eventLog, filterValue) => {
        if (eventLog.admin_id !== filterValue) {
            return false;
        }

        return true;
    },
    [EVENT_LOG_FILTER_TYPE.userId]: (eventLog, filterValue) => {
        if (eventLog.user_id !== filterValue) {
            return false;
        }

        return true;
    },
    [EVENT_LOG_FILTER_TYPE.transactionId]: (eventLog, filterValue) => {
        if (eventLog.transaction_id !== filterValue) {
            return false;
        }

        return true;
    }
};

// An array declared here will maintain a stable reference rather than be re-created again
const emptyEventLogsArray = []

export const filterEventLogs = (eventLogs, filters) => {
    // NOTE: This function should be memoized to prevent unnecessary re-renders
    // Can be done in a react component using useMemo
    return eventLogs?.filter((eventLog) => {
        let includeEventLog = true;

        // For each email log, iterate over each provided filter
        _.forEach(filters, (filterValue, localFilterType) => {
            // If filter value and filter function exist, run filter function against email log
            if (!!filterValue && EVENT_LOG_FILTER_FUNC_MAP[localFilterType]) {
                // Filter functions return true, false or nothing (incorrect values provided)
                const include = EVENT_LOG_FILTER_FUNC_MAP[localFilterType](eventLog, filterValue);
                if (include === false) {
                    // If filter function return is false, set includeEventLog to false
                    includeEventLog = false;
                    // And return false to exit filter loop
                    return false;
                }
            }
        });


        return includeEventLog;
     }) ?? emptyEventLogsArray
}

export const calculateDefaultFundDate = (transactionType, orderType, closeDate) => {
    // NOTE: assumes closeDate is a date object
    const orderTypeMap = TRANSACTION_ORDER_TYPE[transactionType];

    if (transactionType === TRANSACTION_TYPE.purchase) {
        switch(orderType) {
            case orderTypeMap.residential:
                return addBusinessDays(closeDate, 3);
            case orderTypeMap.wholesale:
                return closeDate;
            case orderTypeMap.commercial:
                return closeDate;
            default:
                console.error('Invalid order type provided for transaction type', orderType, transactionType);
                return addBusinessDays(closeDate, 3);
        }
    } else if (transactionType === TRANSACTION_TYPE.refinance) {
        switch(orderType) {
            case orderTypeMap.residential:
                return addBusinessDays(closeDate, 3);
            case orderTypeMap.cash_out:
                return addBusinessDays(closeDate, 3);
            case orderTypeMap.investment:
                return closeDate;
            case orderTypeMap.commercial:
                return closeDate;
            default:
                console.error('Invalid order type provided for transaction type', orderType, transactionType);
                return addBusinessDays(closeDate, 3);
        }
    } else {
        console.error('Invalid transaction type', transactionType);
        return addBusinessDays(closeDate, 3);
    }
}

export const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});


export const getRelevantClosingChildParcels = (order, closingParcel) => {
    // Get all closing child parcels
    let schedulingParcel = null;
    let signingParcel = null;
    const signingParcels = [];
    const additionalData = closingParcel.additional_data;

    _.forEach(order.parcels, (order_parcel) => {
        if (order_parcel.parent_parcel_id === closingParcel.id) {
            if (order_parcel.type === PARCEL_TYPE.scheduling) {
                schedulingParcel = order_parcel;
            } else if (_.includes(_.values(SIGNING_METHOD_PARCEL_MAP), order_parcel.type)) {
                // NOTE: could be multiple if rescheduled multiple times
                signingParcels.push(order_parcel);
            }
        }
    });

    if (_.get(schedulingParcel, 'current_status.status') === PARCEL_STATUS.complete) {
        // Scheduling is complete, implying that signing must exist in some form
        _.forEach(signingParcels, (signing_parcel) => {
            // Determine which signing parcel is relevent to current scheduling parameters
            const signingParcelAdditionalData = signing_parcel.additional_data;
            if (SIGNING_METHOD_PARCEL_MAP[additionalData.signing_method] !== signing_parcel.type) {
                // Ensure that the signing method matches the signing parcel type
                return;
            }

            if (
                additionalData.signing_time === signingParcelAdditionalData.signing_time
                &&
                _.isEqual(additionalData.signing_location, signingParcelAdditionalData.signing_location)
            ) {
                // Ensure that the signing time & location matches
                signingParcel = signing_parcel;
            }
        });
    }

    return {
        'scheduling': schedulingParcel,
        'signing': signingParcel,
    }
}

export const formatDocumentType = (documentType) => {
    const overrideName = _.get(DOCUMENT_TYPE_NAME_OVERRIDE, documentType)
    if (overrideName) {
        return overrideName;
    }
    
    return _.startCase(documentType);
}

export const getPartyColor = (party) => {
    switch (party) {
        case TRANSACTION_PARTY_TYPE.buyer:
            return 'purchase.main';
        case TRANSACTION_PARTY_TYPE.seller:
            return 'refinance.main';
        default:
            return '#C58930';
    }
}


export const pluralize = (text, count, pluralText = false) => {
    if (count === 1) {
        return text;
    }

    if (pluralText) {
        // ex: reply -> replies
        return pluralText;
    }

    return `${text}s`;
}

export const findPartyByRole = (order_transaction_type, role) => {
    let newParty = null;
    _.forEach(TRANSACTION_TYPE_PARTY_MAP[order_transaction_type], (roles, party) => {
        if (_.includes(roles, role)) {
            newParty = party;
        }
    });
    return newParty
}

export const generateRelativeTime = (eventDatetime) => {
    const now = new Date();
    const eventDate = new Date(eventDatetime);
    const minutesBetween = differenceInMinutes(now, eventDate);

    if (minutesBetween < 60) {
        // 1 hour - ex: 8 min
        return `${minutesBetween} min`;
    } else if (minutesBetween < 1440) {
        // 1 day - ex: 4:30 pm
        return format(eventDate, 'p');
    } else if (minutesBetween < 10080) {
        // 1 week - ex: Mon
        return format(eventDate, 'EEE');
    } else if (minutesBetween < 525600) {
        // 1 year - ex: Apr 20
        return format(eventDate, 'MMM d');
    }

    // over 1 year - ex: 4/20/21
    return format(eventDate, 'M/d/yy');
};