import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputAdornment, MenuItem, Stack, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';

import useFilterQueryParams from 'components/common/hooks/useFilterQueryParams';
import FilterTextField from 'components/common/styled/FilterTextField';
import { CONTACT_FILTER_TYPE, CONTACT_TYPE } from 'helpers/constants';

function ContactListFilters({ filters, setFilters, resetFilters, defaultFilters, hideFilters = [] }) {
    const searchInputRef = useRef('');

    const [syncFilterMap, searchParams] = useFilterQueryParams(filters, defaultFilters);

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string isn't valid for some filters
        };

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const changeHandler = (event) => {
        handleFilter(event);
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 300), []);

    const setSearchInput = (value) => {
        searchInputRef.current.value = value;
    };

    const resetAllFilters = () => {
        // Reset local uncontrolled search input
        setSearchInput('');

        // Reset parent filters
        resetFilters();
    };

    useEffect(() => {
        // Stop the invocation of the debounced function after unmounting
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    useEffect(() => {
        if (_.keys(syncFilterMap).length > 0) {
            const updatedFilterMap = {
                ...defaultFilters,
                ...syncFilterMap,
            };

            // Update local filters
            setFilters(updatedFilterMap);

            // Sync uncontrolled search input ref
            setSearchInput(_.get(updatedFilterMap, CONTACT_FILTER_TYPE.search, ''));
        }
    }, [syncFilterMap]);

    return (
        <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <FilterTextField
                    name={CONTACT_FILTER_TYPE.search}
                    label=""
                    type="search"
                    variant="outlined"
                    onChange={debouncedChangeHandler}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    inputRef={searchInputRef}
                    placeholder="Search contacts ..."
                    size="small"
                    sx={{
                        flexGrow: 1,
                        maxWidth: '480px',
                        ...(_.includes(hideFilters, CONTACT_FILTER_TYPE.search) && { display: 'none' }),
                    }}
                />

                <FilterTextField
                    name={CONTACT_FILTER_TYPE.contactType}
                    label="Contact Type"
                    variant="outlined"
                    onChange={handleFilter}
                    value={filters[CONTACT_FILTER_TYPE.contactType]}
                    size="small"
                    sx={{
                        minWidth: '160px',
                        ...(_.includes(hideFilters, CONTACT_FILTER_TYPE.contactType) && { display: 'none' }),
                    }}
                    select
                >
                    <MenuItem value="">All</MenuItem>
                    {_.map(CONTACT_TYPE, (contactTypeValue) => {
                        return (
                            <MenuItem key={contactTypeValue} value={contactTypeValue}>
                                {_.startCase(contactTypeValue)}
                            </MenuItem>
                        );
                    })}
                </FilterTextField>

                <Box flexGrow="1" />

                <Tooltip title="Reset Filters" placement="bottom" enterDelay={300}>
                    <IconButton
                        onClick={() => {
                            resetAllFilters();
                        }}
                    >
                        <RestartAltIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Stack>
    );
}

ContactListFilters.propTypes = {
    defaultFilters: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    hideFilters: PropTypes.array,
};

export default ContactListFilters;
