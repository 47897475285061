import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useMemo, useState } from 'react';

import { useGetAllLegalEntityMembersQuery, useGetLegalEntitiesQuery } from 'api/legalEntity';
import { useGetAllOrderMembersQuery } from 'api/order';
import { useGetUsersQuery } from 'api/user';
import CreateContactModal from 'components/Contact/components/CreateContactModal';
import { CONTACT_FILTER_TYPE, ENTITY_TYPE } from 'helpers/constants';
import ContactListFilters from './components/ContactListFilters';
import ContactListGrid from './components/ContactListGrid';

const DEFAULT_FILTER_STATE = {
    [CONTACT_FILTER_TYPE.search]: '',
    [CONTACT_FILTER_TYPE.contactType]: '',
};

const filterUsers = (users, filters) => {
    const filteredUsers = [];

    if (!_.includes([ENTITY_TYPE.user, ''], filters[CONTACT_FILTER_TYPE.contactType])) {
        // Exclude users - return early
        return filteredUsers;
    }

    _.forEach(users, (user) => {
        const searchFilter = filters[CONTACT_FILTER_TYPE.search];
        if (searchFilter.length > 3) {
            // Search by first name, last name, email, phone
            const compositeUserString = `
                ${user.name} ${_.get(user, 'contact.email', '')} ${_.get(user, 'contact.phone', '')}
            `;
            if (!_.includes(_.toLower(compositeUserString), _.toLower(searchFilter))) {
                return;
            }
        }

        filteredUsers.push(user);
    });

    return filteredUsers;
};

const filterLegalEntities = (legalEntities, filters) => {
    const filteredLegalEntities = [];

    if (!_.includes([ENTITY_TYPE.legal_entity, ''], filters[CONTACT_FILTER_TYPE.contactType])) {
        // Exclude legal entities - return early
        return filteredLegalEntities;
    }

    _.forEach(legalEntities, (legalEntity) => {
        const searchFilter = filters[CONTACT_FILTER_TYPE.search];
        if (searchFilter.length > 3) {
            // Search by name, email, phone
            const compositeLegalEntityString = `
                ${legalEntity.name} ${_.get(legalEntity, 'contact.email', '')} ${_.get(
                legalEntity,
                'contact.phone',
                ''
            )}
            `;
            if (!_.includes(_.toLower(compositeLegalEntityString), _.toLower(searchFilter))) {
                return;
            }
        }

        filteredLegalEntities.push(legalEntity);
    });

    return filteredLegalEntities;
};

const filterContacts = (users, legalEntities, filters) => {
    const filteredUsers = filterUsers(users, filters);
    const filteredLegalEntities = filterLegalEntities(legalEntities, filters);

    return [...filteredUsers, ...filteredLegalEntities];
};

const mapOrderMembersToEntity = (orderMembers) => {
    // Iterate once through order members and create a map of users -> user id & legal entities -> legal entity id
    const orderMembersMap = {
        [ENTITY_TYPE.user]: {}, // includes legal_entity_members mapped to the user as well
        [ENTITY_TYPE.legal_entity]: {},
    };

    _.forEach(orderMembers, (orderMember) => {
        const { member_type, member_id } = orderMember;

        if (_.includes([ENTITY_TYPE.user, ENTITY_TYPE.legal_entity], member_type)) {
            // Add member object to user/legal entity array if exists else create new array
            _.has(orderMembersMap[member_type], member_id)
                ? orderMembersMap[member_type][member_id].push(orderMember)
                : (orderMembersMap[member_type][member_id] = [orderMember]);
        } else if (member_type === ENTITY_TYPE.legal_entity_member) {
            // Get user id from legal entity member
            const userId = _.get(orderMember, 'member.user_id');

            // Add member object to user's array if exists else create new array
            _.has(orderMembersMap[ENTITY_TYPE.user], userId)
                ? orderMembersMap[ENTITY_TYPE.user][userId].push(orderMember)
                : (orderMembersMap[ENTITY_TYPE.user][userId] = [orderMember]);
        }
    });

    return orderMembersMap;
};

function ContactList() {
    // const theme = useTheme();

    const {
        data: legalEntities,
        isFetching: legalEntitiesFetching,
        refetch: refetchLegalEntities,
    } = useGetLegalEntitiesQuery();
    const {
        data: legalEntityMembers,
        isFetching: legalEntityMembersFetching,
        refetch: refetchLegalEntityMembers,
    } = useGetAllLegalEntityMembersQuery();
    const { data: users, isFetching: usersFetching, refetch: refetchUsers } = useGetUsersQuery();
    const { data: orderMembers, refetch: refetchOrderMembers } = useGetAllOrderMembersQuery();

    const [filters, setFilters] = useState({
        ...DEFAULT_FILTER_STATE,
    });

    const [openDialog, setOpenDialog] = useState(false);

    const filteredContacts = useMemo(() => filterContacts(users, legalEntities, filters), [
        users,
        legalEntities,
        filters,
    ]);

    const entityMemberMap = useMemo(() => mapOrderMembersToEntity(orderMembers), [orderMembers]);

    const augmentedContacts = useMemo(() => {
        return _.map(filteredContacts, (contact) => {
            const { id, entity_type } = contact;
            const orderMembership = _.get(entityMemberMap, `${entity_type}.${id}`, []);

            return {
                ...contact,
                orderMembership,
            };
        });
    }, [filteredContacts, entityMemberMap]);

    // const backgroundUrl = useMemo(
    //     () => generateBackgroundColorSvgUrl(theme.palette.contact.main, theme.palette.background.gray),
    //     [theme]
    // );

    const resetFilters = () => {
        setFilters({
            ...DEFAULT_FILTER_STATE,
        });
    };

    const resetData = () => {
        refetchLegalEntities();
        refetchLegalEntityMembers();
        refetchUsers();
        refetchOrderMembers();
    };

    const loading = usersFetching || legalEntitiesFetching || legalEntityMembersFetching;

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
                padding: (theme) => theme.spacing(3),
            }}
        >
            <Stack
                direction="column"
                spacing={3}
                sx={
                    {
                        // padding: (theme) => theme.spacing(3),
                        // paddingBottom: (theme) => theme.spacing(12),
                        // margin: (theme) => `${theme.spacing(-3, -3, 0, -3)} !important`,
                        // background: (theme) => theme.palette.background.dark,
                        // backgroundImage: `url(${backgroundUrl})`,
                        // backgroundPosition: 'top center',
                        // backgroundSize: 'cover',
                    }
                }
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h1">Contacts</Typography>

                    <Fab
                        color="primary"
                        aria-label="add contact"
                        onClick={() => setOpenDialog(true)}
                        size="small"
                        sx={{
                            zIndex: 0,
                        }}
                    >
                        <AddIcon />
                    </Fab>
                </Stack>

                <ContactListFilters
                    defaultFilters={DEFAULT_FILTER_STATE}
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                />
            </Stack>

            <Box>
                <Box
                    sx={
                        {
                            // marginTop: (theme) => theme.spacing(-12),
                        }
                    }
                >
                    <ContactListGrid contactList={augmentedContacts} loading={loading} refetch={resetData} />
                </Box>
            </Box>

            <CreateContactModal open={openDialog} handleClose={() => setOpenDialog(false)} />
        </Stack>
    );
}

export default ContactList;
