import { FormLabel, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { DOCUMENT_TYPE_DEFAULT_META_DATA } from 'helpers/constants';
import DocumentAccessEmailCheckbox from './DocumentAccessEmailCheckbox';
import DocumentAccessField from './DocumentAccessField';
import DocumentNameField from './DocumentNameField';
import DocumentTypeField from './DocumentTypeField';

/*
TODO: test seeding defaults 

tax_cert 
Naming: Default as Empty
Access: Buyer, Selling Agents, Sellers, Listing Agent
Send Email: No
Parcel: Tax Certificate

*/

export const INITIAL_DOCUMENT_META_DATA = {
    type: '',
    name: '',
    access: [], // uuid of order members
    associations: [], // object of entity_id ex: { parcel_id: ${uuid} }
    send_access_email: false,
};

const getFileNameFromBase64 = (documentData) => {
    // NOTE: documentData is base64 encoded file str
    const documentMeta = documentData.split(';base64')[0];
    const documentDataName = decodeURI(documentMeta.split(';name=')[1]);

    return documentDataName;
};

const generateDefaultDocumentTypeRoleAccess = (documentType, orderMembers) => {
    const defaultDocumentTypeData = _.get(DOCUMENT_TYPE_DEFAULT_META_DATA, documentType);
    if (!defaultDocumentTypeData || _.isEmpty(defaultDocumentTypeData.access)) {
        return [];
    }

    // If defaultDocumentTypeData.access is not empty, get list of all matching member ids
    const accessMemberIds = [];
    _.forEach(orderMembers, (orderMember) => {
        if (_.includes(defaultDocumentTypeData.access, orderMember.role)) {
            accessMemberIds.push(orderMember.id);
        }
    });

    return accessMemberIds;
};

const generateDefaultDocumentTypeSendAccessEmail = (documentType) => {
    const defaultDocumentTypeData = _.get(DOCUMENT_TYPE_DEFAULT_META_DATA, documentType);

    // Return false if no default send access email
    if (!defaultDocumentTypeData) {
        return false;
    }

    // Return default
    return !!defaultDocumentTypeData.send_access_email;
};

const generateDefaultDocumentTypeName = (documentType, documentData) => {
    // NOTE: documentData is base64 encoded file str
    const defaultDocumentTypeData = _.get(DOCUMENT_TYPE_DEFAULT_META_DATA, documentType);
    /*
        NOTE: defaultDocumentTypeData.name can be:
        - true: generate default name based on document data
        - string: use provided string as default name
        - null/falsey: no default name
        NOTE 2: it is absurd that I haven't converted all this to ts yet
    */
    // Return null if no default name
    if (!defaultDocumentTypeData || !defaultDocumentTypeData.name) {
        return null;
    }

    // Return generated default name
    if (defaultDocumentTypeData.name === true) {
        if (documentData) {
            // NOTE: this assumes the type & name will always be before the base64 data
            const newDocumentName = getFileNameFromBase64(documentData);
            return newDocumentName;
        }
    }

    // Return provided string
    return defaultDocumentTypeData.name;
};

// TODO build out
export const formatDocumentMetaPayload = (documentMetaData) => {
    const documentMetaPayload = {};
    let documentMetaEmpty = true;
    _.forEach(documentMetaData, (value, key) => {
        if (_.isNil(value) || value === '') {
            // If single field is empty assign null for field
            documentMetaPayload[key] = null;
        } else {
            documentMetaEmpty = false;
            documentMetaPayload[key] = value;
        }
    });

    // If all fields are empty return null
    if (documentMetaEmpty) {
        return null;
    }

    return documentMetaPayload;
};

export const generateInitialDocumentMeta = (formData, formContext) => {
    // Create with base initial values
    const newContext = {
        ...INITIAL_DOCUMENT_META_DATA,
    };

    // Include any valid form data
    if (formData) {
        _.forEach(formData, (value, key) => {
            if (!value) {
                return;
            }
            newContext[key] = value;
        });
    }

    // Include any default values based on document type
    const documentData = _.get(formContext, 'rootFormData.document_data');
    if (newContext.type) {
        // TODO - potentially extend instead of overwriting to preserve any existing access settings
        // Generate default for name  based on document type
        newContext['name'] = generateDefaultDocumentTypeName(newContext.type, documentData);

        // Generate defaults for access based on document type
        newContext['access'] = generateDefaultDocumentTypeRoleAccess(newContext.type, formContext.orderMembers);

        // Generate defaults for send access email based on document type
        newContext['send_access_email'] = generateDefaultDocumentTypeSendAccessEmail(newContext.type);
    }

    return newContext;
};

const DocumentMetaForm = ({
    documentMetaData,
    setDocumentMetaData,
    label = 'Document',
    showLabel = false,
    required = false,
    disabled = false,
    requiredFields = [],
    disabledFields = [],
    formContext = {},
}) => {
    const { orderMembers } = formContext;
    const [initialized, setInitialized] = useState(false);

    const seedData = (formData) => {
        // TODO: add seed logic for default access by document type
        // TODO: ensure reseed on document type change
        setDocumentMetaData(generateInitialDocumentMeta(formData, formContext, orderMembers));
        setInitialized(true);
    };

    // TODO standardize response params for all handle fn
    const handleDocumentType = ({ value }) => {
        handleDocumentTypeChange(value);
    };

    const handleDocumentAccess = (documentAccessList) => {
        setDocumentMetaData({
            ...documentMetaData,
            access: documentAccessList,
        });
    };

    const handleDocumentAssociations = (documentAssociationList) => {
        setDocumentMetaData({
            ...documentMetaData,
            associations: documentAssociationList,
        });
    };

    const handleDocumentName = (documentName) => {
        setDocumentMetaData({
            ...documentMetaData,
            name: documentName,
        });
    };

    const handleSendAccessEmail = (sendAccessEmail, documentId) => {
        // NOTE: documentId is not used in this function, but is passed in to match the signature of other field components
        // TODO: clean this up to remove unused parameter
        setDocumentMetaData({
            ...documentMetaData,
            send_access_email: sendAccessEmail,
        });
    };

    const handleDocumentTypeChange = (documentType) => {
        // NOTE: changing documentType will wipe/overwrite any existing access settings
        const accessMemberIds = generateDefaultDocumentTypeRoleAccess(documentType, orderMembers);

        setDocumentMetaData({
            ...documentMetaData,
            type: documentType,
            access: [...accessMemberIds],
        });
    };

    useEffect(() => {
        // Ensure we only initialize once we have the form data and document data
        const documentData = _.get(formContext, 'rootFormData.document_data');
        const formData = _.get(formContext, 'rootFormData.document_meta');
        if (
            !initialized &&
            formData &&
            documentData &&
            Object.keys(formData).length > 0 &&
            Object.keys(documentMetaData).length === 0
        ) {
            seedData(formData, formContext);
        }
    }, [documentMetaData, formContext]);

    // Wait for initialization before rendering
    const documentData = _.get(formContext, 'rootFormData.document_data');
    if (!initialized || !documentData || !documentMetaData || Object.keys(documentMetaData).length === 0) {
        return null;
    }

    return (
        <Stack
            direction="column"
            spacing={2}
            className="document-meta-form"
            sx={{
                // ml: 5
                p: 3,
                backgroundColor: (theme) => theme.palette.background.gray,
                border: (theme) => `solid 1px ${theme.palette.divider}`,
                borderRadius: '4px',

                '& .MuiTextField-root': {
                    backgroundColor: (theme) => theme.palette.background.paper,
                },
            }}
        >
            {showLabel && (
                <FormLabel id="document-meta-group-label" className="form-group-label" required={false}>
                    {label}
                </FormLabel>
            )}

            <Stack
                spacing={4}
                className="document-meta-form-fields"
                sx={{
                    '& > *': {
                        flex: 1,
                        width: '100%',
                    },
                }}
            >
                <DocumentNameField
                    documentName={documentMetaData.name}
                    setDocumentName={handleDocumentName}
                    // disabled={isSubmitting || !!submitStatus}
                    // TODO implement required in Field
                    required={required || requiredFields.includes('name')}
                    disabled={disabled || disabledFields.includes('name')}
                />

                <DocumentTypeField
                    documentType={documentMetaData.type}
                    setDocumentType={handleDocumentType}
                    // disabled={isSubmitting || !!submitStatus}
                    // showLabel={showLabel}
                    // TODO implement required in Field
                    required={required || requiredFields.includes('type')}
                    disabled={disabled || disabledFields.includes('type')}
                />

                {/* <DocumentAssociationField
                    documentAssociations={documentMetaData.associations}
                    setDocumentAssociations={handleDocumentAssociations}
                    // disabled={isSubmitting || !!submitStatus}
                    showLabel={showLabel}
                    // TODO implement required in Field
                    required={required || requiredFields.includes('associations')}
                    disabled={disabled || disabledFields.includes('associations')}
                /> */}

                <DocumentAccessField
                    documentAccess={documentMetaData.access}
                    setDocumentAccess={handleDocumentAccess}
                    orderMemberList={orderMembers}
                    // disabled={isSubmitting || !!submitStatus}
                    // showLabel={showLabel}
                    // TODO implement required in Field
                    required={required || requiredFields.includes('access')}
                    disabled={disabled || disabledFields.includes('access')}
                />

                <DocumentAccessEmailCheckbox
                    sendAccessEmail={_.get(documentMetaData, 'send_access_email', false)}
                    setSendAccessEmail={handleSendAccessEmail}
                    documentId={_.get(documentMetaData, 'id')}
                    // disabled={isSubmitting || !hasUserAccess}
                    // TODO implement required in Field
                    required={required || requiredFields.includes('send_access_email')}
                    disabled={disabled || disabledFields.includes('send_access_email')}
                />
            </Stack>
        </Stack>
    );
};

DocumentMetaForm.propTypes = {
    documentMetaData: PropTypes.object.isRequired,
    setDocumentMetaData: PropTypes.func.isRequired,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    requiredFields: PropTypes.array,
    disabledFields: PropTypes.array,
    formContext: PropTypes.object,
};

export default DocumentMetaForm;
