import { Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useGetLienStatusesQuery } from '../../../api/lien';
import { useGetOrderQuery } from '../../../api/order';
import LienBreadcrumbs from './components/LienBreadcrumbs';
import LienDetails from './components/LienDetails';
import LienHeader from './components/LienHeader';

// TODO make hook for lien status map
// TODO make hook for lien & payoff itself

function Lien({ orderId, lienId }) {
    // Grab order and lien data
    const { data: order, isLoading: loading } = useGetOrderQuery(orderId);
    const {
        data: lienStatusMap,
        isError: lienStatusMapError,
        isLoading: lienStatusMapLoading,
    } = useGetLienStatusesQuery();

    if (loading || !order) {
        return <Stack>Loading ...</Stack>;
    }

    const lien = _.find(order.liens, { id: lienId });

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
            }}
        >
            <LienBreadcrumbs lien={lien} />

            <LienHeader lien={lien} />

            <LienDetails lien={lien} order={order} />
        </Stack>
    );
}

Lien.propTypes = {
    orderId: PropTypes.string.isRequired,
    lienId: PropTypes.string.isRequired,
};

export default Lien;
