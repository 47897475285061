import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    navLinkContainer: {
        padding: '16px 0 16px 24px',
    },
    navLink: {
        textDecoration: 'none',
        color: '#3d3d3d',
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: 400,

        '&:hover': {
            textDecoration: 'underline',
        },

        '&.active': {
            fontWeight: 600,

            '&:hover': {
                textDecoration: 'none',
            },
        },
    },
}));

const TopNavLinks = [
    {
        title: 'Parcels',
        href: '/',
    },
    {
        title: 'Orders',
        href: '/orders',
    },
];

const TopbarNav = () => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root)}>
            {_.map(TopNavLinks, (linkItem) => {
                return (
                    <div key={linkItem.title} className={classes.navLinkContainer}>
                        <RouterLink to={linkItem.href} exact className={classes.navLink}>
                            {linkItem.title}
                        </RouterLink>
                    </div>
                );
            })}
        </div>
    );
};

export default TopbarNav;
