import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useCreateAssignmentMemberMutation } from 'api/assignment';
import AssignmentMemberSelectField from './AssignmentMemberSelectField';

import { LoadingButton } from 'components/common/styled';

const CreateAssignmentMemberForm = ({ assignment, assignmentMembers, orderMembers, handleSubmit }) => {
    const [createAssignmentMember, { isLoading }] = useCreateAssignmentMemberMutation();

    const [assignmentMemberData, setAssignmentMemberData] = useState([...assignmentMembers]);

    const handleAssignmentMembersChange = (members) => {
        setAssignmentMemberData([...members]);
    };

    const handleCreateAssignmentMember = (event) => {
        event.preventDefault();

        return submitCreateAssignmentMember().then((response) => {
            if (response) {
                handleSubmit();
            } else {
                // TODO handle error state
            }
        });
    };

    const submitCreateAssignmentMember = async () => {
        const { data } = await createAssignmentMember({
            orderId: assignment.order_id,
            assignmentId: assignment.id,
            assignmentMemberData: assignmentMemberData,
        });

        if (data) {
            return true;
        } else {
            console.warn(`Failed to create assignment member for assignment ${assignment.id}`);
            return false;
        }
    };

    console.log(assignmentMemberData);

    return (
        <form onSubmit={handleCreateAssignmentMember}>
            <Stack spacing={4}>
                <Stack spacing={1}>
                    <Typography variant="body1" color="text.secondary">
                        Select Order Members
                    </Typography>

                    <AssignmentMemberSelectField
                        assignmentMembers={assignmentMemberData}
                        setAssignmentMembers={handleAssignmentMembersChange}
                        orderMembers={orderMembers}
                    />
                </Stack>

                <Box>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%' }}
                    >
                        <Box />
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={isLoading}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                        >
                            Create
                        </LoadingButton>
                    </Stack>
                </Box>
            </Stack>
        </form>
    );
};

CreateAssignmentMemberForm.propTypes = {
    assignment: PropTypes.object.isRequired,
    assignmentMembers: PropTypes.array.isRequired,
    orderMembers: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default CreateAssignmentMemberForm;
