import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
    Checkbox,
    Chip,
    FormControlLabel,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import {
    useGetLegalEntitiesBySearchQuery,
    useGetLegalEntityMembersBySearchQuery,
    useGetLegalEntityQuery,
} from '../../../../api/legalEntity';
import { useCreateOrderMemberMutation } from '../../../../api/order';
import { useGetUserQuery, useGetUsersBySearchQuery } from '../../../../api/user';
import { ENTITY_TYPE, ORDER_ROLE_TYPE, TRANSACTION_TYPE_PARTY_MAP } from '../../../../helpers/constants';
import { findPartyByRole } from '../../../../helpers/utils';
import BaseDialog from '../../../common/BaseDialog';
import { Button, FilterTextField, LoadingButton, Tab, Tabs } from '../../../common/styled';
import NewLegalEntityForm from './NewLegalEntityForm';
import NewUserForm from './NewUserForm';
import { generateInitialOrderMemberState } from './OrderMemberForm';

const NewMemberForm = ({ order, handleSubmit, seededRole, switchToSearch }) => {
    const [memberType, setMemberType] = useState(ENTITY_TYPE.user);

    const handleChange = (event, newMemberType) => {
        setMemberType(newMemberType);
    };

    return (
        <Stack spacing={4} sx={{ width: '480px' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <Typography variant="h4">Add New Member</Typography>
            </Stack>

            <Stack>
                <Tabs value={memberType} onChange={handleChange} textColor="primary" indicatorColor="primary">
                    <Tab value={ENTITY_TYPE.user} label="User" />

                    <Tab value={ENTITY_TYPE.legal_entity} label="Organization" />
                </Tabs>
            </Stack>

            {memberType === ENTITY_TYPE.user && (
                <NewUserForm
                    order={order}
                    handleSubmit={handleSubmit}
                    seededRole={seededRole}
                    switchToSearch={switchToSearch}
                />
            )}
            {memberType === ENTITY_TYPE.legal_entity && (
                <NewLegalEntityForm
                    order={order}
                    handleSubmit={handleSubmit}
                    seededRole={seededRole}
                    switchToSearch={switchToSearch}
                />
            )}
        </Stack>
    );
};

const SearchResultListItemSecondaryActions = ({
    order,
    userId,
    memberDetailsMap,
    handleMemberDetailsChange,
    selected,
}) => {
    const [showTitleCheckbox, setShowTitleCheckbox] = useState(true);
    const [showLoanCheckbox, setShowLoanCheckbox] = useState(true);

    useEffect(() => {
        const orderMemberRole = _.get(memberDetailsMap, `${userId}.role`);
        if (orderMemberRole === ORDER_ROLE_TYPE.buyer) {
            setShowTitleCheckbox(true);
            setShowLoanCheckbox(true);
        } else if (orderMemberRole === ORDER_ROLE_TYPE.seller) {
            setShowTitleCheckbox(true);
            setShowLoanCheckbox(false);
            handleMemberDetailsChange(userId, { on_loan: false });
        } else {
            setShowTitleCheckbox(false);
            setShowLoanCheckbox(false);
            handleMemberDetailsChange(userId, { on_title: false, on_loan: false });
        }
    }, [_.get(memberDetailsMap, `${userId}.role`)]);

    const handleCheckboxChange = ({ target }) => {
        handleMemberDetailsChange(userId, { [target.name]: target.checked });
    };

    const updateRole = (role) => {
        const newParty = findPartyByRole(order.transaction_type, role);
        handleMemberDetailsChange(userId, {
            role,
            party: newParty,
        });
    };

    const handleRoleChange = ({ target }) => {
        const role = target.value;
        updateRole(role);
    };

    const userOrderDetails = _.get(memberDetailsMap, userId);

    if (!selected || !userOrderDetails) {
        return null;
    }

    return (
        <Stack direction="row" spacing={4} alignItems="center">
            <FormControlLabel
                label="Title"
                control={
                    <Checkbox name="on_title" checked={userOrderDetails.on_title} onChange={handleCheckboxChange} />
                }
                disabled={!showTitleCheckbox}
                sx={{ marginRight: '0' }}
            />

            <FormControlLabel
                label="Loan"
                control={<Checkbox name="on_loan" checked={userOrderDetails.on_loan} onChange={handleCheckboxChange} />}
                disabled={!showLoanCheckbox}
            />

            <TextField
                name="role"
                label="Role"
                value={userOrderDetails.role}
                onChange={handleRoleChange}
                sx={{ minWidth: '150px' }}
                size="small"
                select
                required
            >
                {_.map(TRANSACTION_TYPE_PARTY_MAP[order.transaction_type], (roles, party) => [
                    <ListSubheader key={party}>{_.startCase(party)}</ListSubheader>,
                    roles.map((role) => (
                        <MenuItem key={role} value={role}>
                            {_.startCase(role)}
                        </MenuItem>
                    )),
                ])}
            </TextField>
        </Stack>
    );
};

const UserListItem = ({ order, user, handleSelect, selected, memberDetailsMap, handleMemberDetailsChange }) => {
    const handleToggle = (event) => {
        event.preventDefault();
        handleSelect(user, !selected);
    };

    return (
        <ListItem
            disablePadding
            className={selected ? 'selected' : ''}
            secondaryAction={
                <SearchResultListItemSecondaryActions
                    order={order}
                    selected={selected}
                    userId={user.id}
                    memberDetailsMap={memberDetailsMap}
                    handleMemberDetailsChange={handleMemberDetailsChange}
                />
            }
            sx={{
                '&.selected > .MuiListItemButton-root': {
                    paddingRight: (theme) => theme.spacing(48),
                },
            }}
        >
            <ListItemButton onClick={handleToggle} dense>
                <ListItemIcon>
                    <Checkbox edge="start" checked={selected} disableRipple />
                </ListItemIcon>
                <ListItemText
                    primary={user.name}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={_.get(user, 'contact.email', null)}
                    secondaryTypographyProps={{ noWrap: true }}
                />
            </ListItemButton>
        </ListItem>
    );
};

const LegalEntityMemberListItem = ({
    order,
    legalEntityMember,
    handleSelect,
    selected,
    selectedMemberMap,
    memberDetailsMap,
    handleMemberDetailsChange,
    parentLegalEntity,
}) => {
    const { data: user } = useGetUserQuery(legalEntityMember.user_id);

    useEffect(() => {
        const parentMemberDetails = memberDetailsMap[parentLegalEntity.id];
        const childMemberDetails = memberDetailsMap[legalEntityMember.id];

        // If parent role has changed, sync with child legal entity member
        // and clear title & loan params for child
        if (parentMemberDetails && childMemberDetails && parentMemberDetails.role !== childMemberDetails.role) {
            handleMemberDetailsChange(legalEntityMember.id, {
                party: parentMemberDetails.party,
                role: parentMemberDetails.role,
                on_title: false,
                on_loan: false,
            });
        }
    }, [memberDetailsMap[parentLegalEntity.id]]);

    const handleToggle = (event) => {
        event.preventDefault();
        handleSelect(legalEntityMember, !selected);
    };

    const parentLegalEntitySelected = !!_.get(selectedMemberMap, parentLegalEntity.id);

    return (
        <ListItem
            disablePadding
            className={!parentLegalEntitySelected && selected ? 'selected' : ''}
            secondaryAction={
                <SearchResultListItemSecondaryActions
                    order={order}
                    selected={!parentLegalEntitySelected && selected}
                    userId={legalEntityMember.id} // TODO change to entity id to be generic
                    memberDetailsMap={memberDetailsMap}
                    handleMemberDetailsChange={handleMemberDetailsChange}
                />
            }
            sx={{
                paddingLeft: (theme) => theme.spacing(7),
                '&.selected > .MuiListItemButton-root': {
                    paddingRight: (theme) => theme.spacing(48),
                },
            }}
        >
            <ListItemButton onClick={handleToggle} dense>
                <ListItemIcon>
                    <Checkbox edge="start" checked={selected} disableRipple />
                </ListItemIcon>
                <ListItemText
                    primary={`${user.name}, ${_.startCase(legalEntityMember.role)}`}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={_.get(user, 'contact.email', null)}
                    secondaryTypographyProps={{ noWrap: true }}
                />
            </ListItemButton>
        </ListItem>
    );
};

const LegalEntityListItem = ({
    order,
    legalEntityId,
    handleSelect,
    selected,
    selectedMemberMap,
    memberDetailsMap,
    handleMemberDetailsChange,
}) => {
    const { data: legalEntity, isError: legalEntityError, isLoading: legalEntityLoading } = useGetLegalEntityQuery(
        legalEntityId
    );

    const handleToggle = (event) => {
        event.preventDefault();
        handleSelect(legalEntity, !selected);
    };

    const handleMemberSelect = (legalEntityMember, isSelected) => {
        handleSelect(legalEntityMember, isSelected);

        // If member is selected but parent legal entity is not, also select parent legal entity
        if (isSelected && !selected) {
            handleSelect(legalEntity, true);
        }
    };

    return (
        <div>
            <ListItem
                disablePadding
                className={selected ? 'selected' : ''}
                secondaryAction={
                    <SearchResultListItemSecondaryActions
                        order={order}
                        selected={selected}
                        userId={legalEntity.id}
                        memberDetailsMap={memberDetailsMap}
                        handleMemberDetailsChange={handleMemberDetailsChange}
                    />
                }
                sx={{
                    '&.selected > .MuiListItemButton-root': {
                        paddingRight: (theme) => theme.spacing(48),
                    },
                }}
            >
                <ListItemButton onClick={handleToggle} dense>
                    <ListItemIcon>
                        <Checkbox edge="start" checked={selected} disableRipple />
                    </ListItemIcon>
                    <ListItemText
                        primary={legalEntity.name}
                        primaryTypographyProps={{ noWrap: true }}
                        secondary={_.get(legalEntity, 'contact.email', null)}
                        secondaryTypographyProps={{ noWrap: true }}
                    />
                </ListItemButton>
            </ListItem>

            {_.map(legalEntity.members, (legalEntityMember) => {
                const legalEntityMemberSelected = !!_.get(selectedMemberMap, legalEntityMember.id);
                return (
                    <LegalEntityMemberListItem
                        key={legalEntityMember.id}
                        order={order}
                        legalEntityMember={legalEntityMember}
                        selected={legalEntityMemberSelected}
                        handleSelect={handleMemberSelect}
                        selectedMemberMap={selectedMemberMap}
                        memberDetailsMap={memberDetailsMap}
                        handleMemberDetailsChange={handleMemberDetailsChange}
                        parentLegalEntity={legalEntity}
                    />
                );
            })}
        </div>
    );
};

const GenericEntityListItem = ({
    order,
    genericEntity,
    handleSelect,
    selected,
    memberDetailsMap,
    handleMemberDetailsChange,
}) => {
    const handleToggle = (event) => {
        event.preventDefault();
        handleSelect(genericEntity, !selected);
    };

    const entityPrimaryText =
        genericEntity.entity_type === ENTITY_TYPE.legal_entity_member ? genericEntity.user.name : genericEntity.name;
    const entitySecondaryText =
        genericEntity.entity_type === ENTITY_TYPE.legal_entity_member
            ? _.startCase(genericEntity.role)
            : _.get(genericEntity, 'contact.email', null);

    return (
        <ListItem
            disablePadding
            secondaryAction={
                <SearchResultListItemSecondaryActions
                    order={order}
                    selected={selected}
                    userId={genericEntity.id}
                    memberDetailsMap={memberDetailsMap}
                    handleMemberDetailsChange={handleMemberDetailsChange}
                />
            }
        >
            <ListItemButton onClick={handleToggle} dense>
                <ListItemIcon>
                    <Checkbox edge="start" checked={selected} disableRipple />
                </ListItemIcon>
                <ListItemText primary={entityPrimaryText} secondary={entitySecondaryText} />
            </ListItemButton>
        </ListItem>
    );
};

const SearchResults = ({
    order,
    searchTerm,
    userSearchResults,
    legalEntityMemberSearchResults,
    legalEntitySearchResults,
    validSearchTerm,
    noSearchResults,
    selectedMemberMap,
    handleSelect,
    memberDetailsMap,
    handleMemberDetailsChange,
}) => {
    const [relatedLegalEntityIdList, setRelatedLegalEntityIdList] = useState([]);

    useEffect(() => {
        // Create a combined list of all legal entities by resolving legalEntitySearchResults & legalEntityMemberSearchResults
        const relatedLegalEntities = _.map(legalEntitySearchResults, (legalEntity) => {
            return legalEntity.id;
        });

        _.forEach(legalEntityMemberSearchResults, (legalEntityMember) => {
            if (!_.includes(relatedLegalEntities, legalEntityMember.legal_entity_id)) {
                relatedLegalEntities.push(legalEntityMember.legal_entity_id);
            }
        });

        setRelatedLegalEntityIdList(relatedLegalEntities);
    }, [legalEntitySearchResults, legalEntityMemberSearchResults]);

    if (!validSearchTerm || noSearchResults) {
        return null;
    }

    const sharedParams = {
        order,
        handleSelect,
        selectedMemberMap,
        memberDetailsMap,
        handleMemberDetailsChange,
    };

    const userSearchResultList = _.map(userSearchResults, (user) => {
        return (
            <UserListItem key={user.id} user={user} selected={!!_.get(selectedMemberMap, user.id)} {...sharedParams} />
        );
    });

    const legalEntitySearchResultList = _.map(relatedLegalEntityIdList, (legalEntityId) => {
        // NOTE: legal entity list item will display all legal entity members as well
        return (
            <LegalEntityListItem
                key={legalEntityId}
                legalEntityId={legalEntityId}
                selected={!!_.get(selectedMemberMap, legalEntityId)}
                {...sharedParams}
            />
        );
    });

    return (
        <Stack spacing={1}>
            <Typography variant="overline">Search Results</Typography>
            <List sx={{ width: '100%', maxHeight: '300px', overflow: 'auto' }}>
                {legalEntitySearchResultList}
                {userSearchResultList}
            </List>
        </Stack>
    );
};

const CurrentSelections = ({
    order,
    validSearchTerm,
    noSearchResults,
    selectedMemberMap,
    handleSelect,
    memberDetailsMap,
    handleMemberDetailsChange,
}) => {
    if (_.keys(selectedMemberMap).length === 0 || (validSearchTerm && !noSearchResults)) {
        return null;
    }

    // TODO need to group legal entity members with their legal entities

    const currentSelectionList = _.map(selectedMemberMap, (member) => {
        return (
            <GenericEntityListItem
                key={member.id}
                order={order}
                genericEntity={member}
                selected={!!_.get(selectedMemberMap, member.id)}
                handleSelect={handleSelect}
                memberDetailsMap={memberDetailsMap}
                handleMemberDetailsChange={handleMemberDetailsChange}
            />
        );
    });

    return (
        <Stack spacing={1}>
            <Typography variant="overline">Current Selections</Typography>
            <List sx={{ width: '100%', maxHeight: '300px', overflow: 'auto' }}>{currentSelectionList}</List>
        </Stack>
    );
};

const SearchForm = ({ order, handleSubmit, handleClose, seededRole, switchToAddNew }) => {
    const [selectedMemberMap, setSelectedMemberMap] = useState({});
    const [memberDetailsMap, setMemberDetailsMap] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        data: userSearchResults,
        isError: userSearchError,
        isLoading: userSearchLoading,
    } = useGetUsersBySearchQuery(searchTerm);
    const {
        data: legalEntityMemberSearchResults,
        isError: legalEntityMemberSearchError,
        isLoading: legalEntityMemberSearchLoading,
    } = useGetLegalEntityMembersBySearchQuery(searchTerm);
    const {
        data: legalEntitySearchResults,
        isError: legalEntitySearchError,
        isLoading: legalEntitySearchLoading,
    } = useGetLegalEntitiesBySearchQuery(searchTerm);

    const [createOrderMember, { isLoading: isOrderMemberLoading }] = useCreateOrderMemberMutation();

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelect = (selectedResult, selected) => {
        if (selected) {
            setSelectedMemberMap((prevSelectedMemberMap) => ({
                ...prevSelectedMemberMap,
                [selectedResult.id]: {
                    ...selectedResult,
                },
            }));
            // set baseline order details if doesn't already exist
            handleMemberDetailsChange(selectedResult.id, {});
        } else {
            const newMap = { ...selectedMemberMap };
            delete newMap[selectedResult.id];
            setSelectedMemberMap(newMap);
        }
    };

    const handleMemberDetailsChange = (userId, newMemberDetails = {}) => {
        setMemberDetailsMap((prevMemberDetailsMap) => ({
            ...prevMemberDetailsMap,
            [userId]: {
                ..._.get(prevMemberDetailsMap, userId, generateInitialOrderMemberState(order, seededRole)),
                ...newMemberDetails,
            },
        }));
    };

    const handleCreateMembers = (event) => {
        event.preventDefault();

        setIsSubmitting(true);

        const resultMap = {
            success: [],
            error: [],
        };

        // TODO for each selected user/entity, format and add them to the order
        const promiseList = _.map(selectedMemberMap, (selectedMember, selectedMemberId) => {
            return submitOrderMember(selectedMemberId, selectedMember).then(
                (response) => {
                    if (response) {
                        resultMap.success.push(selectedMemberId);
                    } else {
                        resultMap.error.push(selectedMemberId);
                    }
                    return response;
                },
                (error) => {
                    console.log(error);
                    resultMap.error.push(selectedMemberId);
                }
            );
        });

        Promise.all(promiseList).then((values) => {
            setIsSubmitting(false);

            if (resultMap.success.length > 0) {
                handleSubmit();
            }
        });
    };

    const submitOrderMember = async (selectedMemberId, selectedMember) => {
        const orderMemberDetails = _.get(memberDetailsMap, selectedMemberId);

        if (!orderMemberDetails) {
            console.warn(`${selectedMemberId} not found in memberDetailsMap`, memberDetailsMap);
            return false;
        }

        const orderMember = {
            order_id: order.id,
            member_id: selectedMemberId,
            member_type: selectedMember.entity_type,
            ...orderMemberDetails,
        };

        // Verify that role/party have been filled in else exit
        if (!orderMember.role || !orderMember.party) {
            console.warn(`${selectedMemberId} doesn't have an assigned role/party`);
            return false;
        }

        const { data: createOrderMemberData } = await createOrderMember({ orderId: order.id, memberData: orderMember });

        if (createOrderMemberData) {
            return true;
        } else {
            console.warn(`${selectedMemberId} failed to be added as member to order`);
            return false;
        }
    };

    const validSearchTerm = _.get(searchTerm, 'length') > 2;
    const noSearchResults =
        _.get(userSearchResults, 'length', 0) === 0 &&
        _.get(legalEntitySearchResults, 'length', 0) === 0 &&
        _.get(legalEntityMemberSearchResults, 'length', 0) === 0;

    return (
        <Stack spacing={4} sx={{ width: '640px' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <Typography variant="h4">Search</Typography>

                <Button
                    variant="outlined"
                    color="default"
                    onClick={switchToAddNew}
                    disableElevation
                    size="small"
                    startIcon={<AddIcon />}
                    sx={{ pl: 2, pr: 2 }}
                >
                    Add New
                </Button>
            </Stack>

            <Stack spacing={2}>
                <FilterTextField
                    name="search"
                    type="search"
                    placeholder="Search existing users/organizations ..."
                    variant="outlined"
                    onChange={handleSearch}
                    value={searchTerm}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    autoFocus
                    fullWidth
                    helperText={
                        validSearchTerm &&
                        noSearchResults && (
                            <Typography variant="caption">No results found for "{searchTerm}"</Typography>
                        )
                    }
                />

                <SearchResults
                    order={order}
                    searchTerm={searchTerm}
                    userSearchResults={userSearchResults}
                    legalEntityMemberSearchResults={legalEntityMemberSearchResults}
                    legalEntitySearchResults={legalEntitySearchResults}
                    validSearchTerm={validSearchTerm}
                    noSearchResults={noSearchResults}
                    selectedMemberMap={selectedMemberMap}
                    handleSelect={handleSelect}
                    memberDetailsMap={memberDetailsMap}
                    handleMemberDetailsChange={handleMemberDetailsChange}
                />

                <CurrentSelections
                    order={order}
                    searchTerm={searchTerm}
                    validSearchTerm={validSearchTerm}
                    noSearchResults={noSearchResults}
                    selectedMemberMap={selectedMemberMap}
                    handleSelect={handleSelect}
                    memberDetailsMap={memberDetailsMap}
                    handleMemberDetailsChange={handleMemberDetailsChange}
                />

                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Typography variant="overline" sx={{ fontWeight: '600', minWidth: '80px' }}>
                        {_.keys(selectedMemberMap).length} selected
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
                        {_.map(selectedMemberMap, (member) => {
                            const name =
                                member.entity_type === ENTITY_TYPE.legal_entity_member ? member.user.name : member.name;
                            return (
                                <Chip
                                    key={member.id}
                                    label={name}
                                    onDelete={(event) => handleSelect(member, false)}
                                    size="small"
                                />
                            );
                        })}
                    </Stack>
                </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" flexDirection="row" spacing={2}>
                <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={handleCreateMembers}
                    disableElevation
                    loading={isSubmitting}
                    disabled={_.keys(selectedMemberMap).length === 0}
                    sx={{
                        minWidth: '160px',
                    }}
                >
                    Add Selected
                </LoadingButton>
            </Stack>
        </Stack>
    );
};

const CreateMemberModal = ({ open, handleClose, order, seededRole }) => {
    const [searchMode, setSearchMode] = useState(true);

    const handleSubmit = () => {
        handleCloseLocal();
    };

    const handleCloseLocal = (open) => {
        setSearchMode(true);
        handleClose(open);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleCloseLocal(false)}
            DialogProps={{
                maxWidth: 'md',
                fullWidth: false,
            }}
        >
            {searchMode ? (
                <SearchForm
                    order={order}
                    handleSubmit={handleSubmit}
                    handleClose={handleCloseLocal}
                    seededRole={seededRole}
                    switchToAddNew={() => setSearchMode(false)}
                />
            ) : (
                <NewMemberForm
                    order={order}
                    handleSubmit={handleSubmit}
                    seededRole={seededRole}
                    switchToSearch={() => setSearchMode(true)}
                />
            )}
        </BaseDialog>
    );
};

CreateMemberModal.propTypes = {
    order: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    seededRole: PropTypes.string,
};

export default CreateMemberModal;
