import UserType from './type.js';
import { createEntityAdapter } from '@reduxjs/toolkit';

export const usersAdapter = createEntityAdapter();
const initialState = usersAdapter.getInitialState({
    loading: false,
    isAuthenticated: false,
    userDetails: {},
});

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserType.GET_USER_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case UserType.GET_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                userDetails: action.userDetails,
            };
        }
        case UserType.GET_USER_FAILURE: {
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
            };
        }

        case UserType.CREATE_USER_SESSION_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case UserType.CREATE_USER_SESSION_SUCCESS: {
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                userDetails: action.userDetails,
            };
        }
        case UserType.CREATE_USER_SESSION_FAILURE: {
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
            };
        }

        case UserType.LOGOUT_USER_SESSION_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case UserType.LOGOUT_USER_SESSION_SUCCESS: {
            return {
                ...initialState,
            };
        }
        case UserType.LOGOUT_USER_SESSION_FAILURE: {
            return {
                ...initialState,
            };
        }

        case UserType.GET_USERS_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case UserType.GET_USERS_LIST_SUCCESS: {
            return {
                ...state,
                ...usersAdapter.upsertMany(state, action.users),
                loading: false,
            };
        }
        case UserType.GET_USERS_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        default:
            return state;
    }
};

export default userReducer;
