import _ from 'lodash';
import { useMemo } from 'react';

import { useGetAdminUsersQuery } from 'api/adminUser';
import { useGetOrderNotesQuery } from 'api/note';
import { useGetOrderQuery } from 'api/order';
import { ENTITY_TYPE } from 'helpers/constants';
import { findParcelInOrder } from 'helpers/utils';

export function grabEntityName(order, entityType, entityId) {
    if (!entityType) {
        return null;
    }

    // TODO add other types here
    if (entityType === ENTITY_TYPE.parcel) {
        const parcel = findParcelInOrder(order, entityId);
        return parcel.name;
    } else if (entityType === ENTITY_TYPE.lien) {
        const lien = _.find(order.liens, (l) => l.id === entityId);
        return `${_.startCase(lien.type)} Lien`;
    } else if (entityType === ENTITY_TYPE.payoff) {
        const payoff = _.find(order.payoffs, (p) => p.id === entityId);
        const payoffLien = _.find(order.liens, (l) => l.id === payoff.lien_id);
        return `${_.startCase(payoffLien.type)} Payoff`;
    }

    return null;
}

export function grabEntities(order, entityType, entityId) {
    if (!entityType) {
        return null;
    }

    // TODO add other types here
    if (entityType === ENTITY_TYPE.parcel) {
        const parcel = findParcelInOrder(order, entityId);
        return [parcel];
    } else if (entityType === ENTITY_TYPE.lien) {
        const lien = _.find(order.liens, (l) => l.id === entityId);
        return [lien];
    } else if (entityType === ENTITY_TYPE.payoff) {
        const payoff = _.find(order.payoffs, (p) => p.id === entityId);
        const payoffLien = _.find(order.liens, (l) => l.id === payoff.lien_id);
        return [payoff, payoffLien];
    }

    return [null];
}

function useNoteData(note) {
    const { data: order } = useGetOrderQuery(note.order_id);
    const { data: notes } = useGetOrderNotesQuery(note.order_id);
    const { data: adminUsers } = useGetAdminUsersQuery();

    const replyNotes = useMemo(
        () => _.filter(notes, (n) => n.entity_type === ENTITY_TYPE.note && n.entity_id === note.id),
        [note, notes]
    );

    const users = useMemo(() => {
        const userIds = _.map([note, ...replyNotes], 'user_id');

        const userMap = {};
        _.forEach(userIds, (id) => {
            userMap[id] = _.find(adminUsers, (au) => au.id === id);
        });

        return userMap;
    }, [note, replyNotes, adminUsers]);

    const primaryUser = useMemo(() => {
        return users[note.user_id];
    }, [note, users]);

    const entityName = useMemo(() => grabEntityName(order, note.entity_type, note.entity_id), [note, order]);

    const entities = useMemo(() => grabEntities(order, note.entity_type, note.entity_id), [note, order]);

    return {
        order,
        replyNotes, // array
        users, // dict
        primaryUser,
        entities, // array
        entityName,
    };
}

export default useNoteData;
