import React from 'react';
import SchedulingList from '../../components/SchedulingList/SchedulingList';

function SchedulingListPage() {

    return (
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
            <SchedulingList />
        </div>
    );
}

export default SchedulingListPage;
