import DescriptionIcon from '@mui/icons-material/Description';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function DocumentListItem({ document }) {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <DescriptionIcon />

            <Stack>
                <Typography variant="body1">{document.name}</Typography>
                <Typography variant="body2">{document.type}</Typography>
            </Stack>
        </Stack>
    );
}

DocumentListItem.propTypes = {
    document: PropTypes.object.isRequired,
};

export default DocumentListItem;
