import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Chip, Stack, Typography } from '@mui/material';
import { differenceInDays, format, isBefore, parseISO } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    LIEN_STATUS,
    LIEN_TERMINAL_STATUSES,
    PARCEL_STATUS,
    PARCEL_TYPE,
    PAYOFF_STATUS,
} from '../../../helpers/constants';
import { findOrderParcelByType } from '../../../helpers/utils';

const styleMap = {
    info: 'info',
    warning: 'warning',
    error: 'error',
};

function BaseParcelButton({ buttonColor, buttonText, buttonLink }) {
    return (
        <Chip
            component={Link}
            to={buttonLink}
            // color={buttonColor}
            label={buttonText}
            clickable
            size="small"
            {...(buttonColor === styleMap.error && { color: buttonColor })}
            {...(buttonColor === styleMap.warning && { variant: 'outlined' })}
        />
    );
}

function getGenericParcelComponentData(order, parcel) {
    const capitalParcelNameList = [PARCEL_TYPE.cda];

    if (parcel.current_status.status === PARCEL_STATUS.complete) {
        return null;
    }

    return {
        buttonText: _.includes(capitalParcelNameList, parcel.type)
            ? _.upperCase(parcel.type)
            : _.startCase(parcel.type),
        buttonColor: styleMap.warning,
        buttonLink: `/order/${order.id}/parcel/${parcel.id}`,
    };
}

function getHOAParcelComponentData(order, parcel) {
    const parcelStatus = parcel.current_status.status;
    const parcelAdditionalData = parcel.additional_data;

    if (parcelStatus === PARCEL_STATUS.complete) {
        return null;
    }

    const buttonLink = `/order/${order.id}/parcel/${parcel.id}`;
    let buttonText = '';
    let buttonColor = styleMap.warning;

    if (parcelStatus === PARCEL_STATUS.ordered) {
        const etaDate = parseISO(parcelAdditionalData.hoa_eta);
        const formattedDate = format(etaDate, 'M/d');

        buttonText = `HOA: ETA ${formattedDate}`;

        const closeDate = parseISO(order.close_date);
        if (differenceInDays(closeDate, etaDate) <= 2) {
            buttonColor = styleMap.error;
        } else {
            buttonColor = styleMap.warning;
        }
    } else if (parcelStatus === PARCEL_STATUS.need_to_order) {
        buttonText = `HOA: Not Ordered`;
        buttonColor = styleMap.error;
    } else {
        buttonText = `HOA`;
        buttonColor = styleMap.warning;
    }

    return {
        buttonColor,
        buttonText,
        buttonLink,
    };
}

function getTitleExamParcelComponentData(order, parcel) {
    const parcelStatus = parcel.current_status.status;
    const parcelAdditionalData = parcel.additional_data;

    if (
        parcelStatus === PARCEL_STATUS.complete &&
        differenceInDays(new Date(), parseISO(parcelAdditionalData.title_exam_received_date)) < 30
    ) {
        return null;
    }

    const buttonLink = `/order/${order.id}/parcel/${parcel.id}`;
    let buttonText = '';
    let buttonColor = styleMap.warning;

    if (parcelStatus === PARCEL_STATUS.ordered) {
        const etaDate = parseISO(parcelAdditionalData.title_exam_eta);
        const formattedDate = format(etaDate, 'M/d');

        buttonText = `Title Exam: ETA ${formattedDate}`;

        const closeDate = parseISO(order.close_date);
        if (differenceInDays(closeDate, etaDate) <= 5) {
            buttonColor = styleMap.error;
        } else {
            buttonColor = styleMap.warning;
        }
    } else if (parcelStatus === PARCEL_STATUS.not_started) {
        buttonText = 'Title Exam: Not Ordered';
        buttonColor = styleMap.error;
    } else {
        buttonText = 'Title Exam';
        buttonColor = styleMap.warning;
    }

    return {
        buttonColor,
        buttonText,
        buttonLink,
    };
}

function getClosingParcelComponentData(order, parcel) {
    const parcelStatus = parcel.current_status.status;
    const parcelAdditionalData = parcel.additional_data;

    if (parcelStatus === PARCEL_STATUS.complete || !!parcelAdditionalData.signing_method) {
        return null;
    }

    const buttonText = `${_.startCase(parcelAdditionalData.signing_party_type)}: Not Scheduled`;
    const buttonColor = styleMap.warning;
    const buttonLink = `/order/${order.id}/parcel/${parcel.id}`;

    return {
        buttonColor,
        buttonText,
        buttonLink,
    };
}

function getTitleClearingComponentDataList(order, parcel) {
    const titleClearingComponentDataList = [];

    // Title clearing hasn't started yet
    if (parcel.current_status.status === PARCEL_STATUS.not_started) {
        titleClearingComponentDataList.push({
            buttonColor: styleMap.warning,
            buttonText: 'Title Clearing: Not Started',
            buttonLink: `/order/${order.id}/parcel/${parcel.id}`,
        });

        return titleClearingComponentDataList;
    }

    // Title clearing has started - iterate over each lien/payoff
    const lienPayoffMap = {};
    _.forEach(order.payoffs, (payoff) => {
        lienPayoffMap[payoff.lien_id] = payoff;
    });

    _.forEach(order.liens, (lien) => {
        let buttonColor = styleMap.warning;
        let buttonText = `${lien.name}: ${_.startCase(lien.status)}`;
        let buttonLink = `/order/${order.id}/lien/${lien.id}`;

        if (_.includes([LIEN_STATUS.waiting, ...LIEN_TERMINAL_STATUSES], lien.status)) {
            // Find payoff for this lien (if exists)
            const lienPayoff = lienPayoffMap[lien.id];

            if (lienPayoff) {
                const payoffName = `${_.startCase(lien.type)} Payoff`;
                buttonLink = `/order/${order.id}/payoff/${lienPayoff.id}`;
                const orderCloseDate = parseISO(order.close_date);

                if (lienPayoff.status === PAYOFF_STATUS.complete) {
                    const payoffGoodThroughDate = parseISO(lienPayoff.good_through_date);
                    if (isBefore(orderCloseDate, payoffGoodThroughDate)) {
                        return; // Don't show anything for this lien/payoff
                    }

                    buttonColor = styleMap.error;
                    buttonText = `${payoffName}: Expiring Before Close`;
                } else if (lienPayoff.status === PAYOFF_STATUS.waiting) {
                    const payoffETA = parseISO(lienPayoff.additional_data.payoff_eta);
                    buttonColor = isBefore(orderCloseDate, payoffETA) ? styleMap.error : styleMap.warning;
                    buttonText = `${payoffName}: ETA ${format(payoffETA, 'M/d')}`;
                } else {
                    buttonText = `${payoffName}: ${_.startCase(lienPayoff.status)}`;
                }
            } else if (_.includes(LIEN_TERMINAL_STATUSES, lien.status)) {
                // Lien is complete without a payoff
                return;
            }
        }

        titleClearingComponentDataList.push({
            buttonColor,
            buttonText,
            buttonLink,
        });
    });

    return titleClearingComponentDataList;
}

const parcelFunctionMap = {
    [PARCEL_TYPE.hoa]: getHOAParcelComponentData,
    [PARCEL_TYPE.earnest_money]: getGenericParcelComponentData,
    [PARCEL_TYPE.tax_cert]: getGenericParcelComponentData,
    [PARCEL_TYPE.title_exam]: getTitleExamParcelComponentData,
    [PARCEL_TYPE.survey]: getGenericParcelComponentData,
    [PARCEL_TYPE.home_warranty]: getGenericParcelComponentData,
    [PARCEL_TYPE.cda]: getGenericParcelComponentData,
    [PARCEL_TYPE.deed]: getGenericParcelComponentData,
    [PARCEL_TYPE.earnest_money]: getGenericParcelComponentData,
    [PARCEL_TYPE.closing_buyer]: getClosingParcelComponentData,
    [PARCEL_TYPE.closing_seller]: getClosingParcelComponentData,
    [PARCEL_TYPE.closing_borrower]: getClosingParcelComponentData,
    [PARCEL_TYPE.title_clearing]: getTitleClearingComponentDataList,
};

function getOutstandingParcelComponentData(order, parcelType) {
    const parcel = findOrderParcelByType(order, parcelType);

    if (!parcel) {
        return null;
    }

    const parcelFunction = parcelFunctionMap[parcelType];
    return parcelFunction(order, parcel);
}

function OutstandingParcelList({ order, closingParcel }) {
    const [outstandingParcelList, setOutstandingParcelList] = useState([]);

    useEffect(() => {
        const newOutstandingParcelList = [];

        _.forEach(_.keys(parcelFunctionMap), (parcelType) => {
            const parcelComponentData = getOutstandingParcelComponentData(order, parcelType);
            if (!parcelComponentData || _.isEmpty(parcelComponentData)) {
                return;
            }

            if (_.isArray(parcelComponentData)) {
                // Expand list into list
                newOutstandingParcelList.push(...parcelComponentData);
            } else {
                newOutstandingParcelList.push(parcelComponentData);
            }
        });

        setOutstandingParcelList(newOutstandingParcelList);
    }, [order]);

    if (outstandingParcelList.length === 0) {
        if (closingParcel?.current_status.status === PARCEL_STATUS.complete) {
            return (
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ height: '100%' }}>
                    <CheckCircleOutlineIcon fontSize="large" color="success" />
                    <Typography>Closed</Typography>
                </Stack>
            );
        }

        return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ height: '100%' }}>
                <CheckCircleOutlineIcon fontSize="large" color="success" />
                <Typography>Ready to Close</Typography>
            </Stack>
        );
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                flexWrap: 'wrap',
                gap: 1,
                '& > *': {
                    marginRight: (theme) => theme.spacing(1),
                    '&:last-child': {
                        marginRight: 0,
                    },
                },
            }}
        >
            {_.map(outstandingParcelList, (outstandingParcelData, index) => {
                return (
                    <BaseParcelButton
                        key={index}
                        buttonColor={outstandingParcelData.buttonColor}
                        buttonText={outstandingParcelData.buttonText}
                        buttonLink={outstandingParcelData.buttonLink}
                    />
                );
            })}
        </Stack>
    );
}

export default OutstandingParcelList;
