import React from 'react';
import OrderList from '../../components/OrderList/OrderList';

function OrderListPage() {

    return (
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
            <OrderList />
        </div>
    );
}

export default OrderListPage;
