import { Checkbox, FormControlLabel, ListSubheader, MenuItem, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { ORDER_ROLE_TYPE, TRANSACTION_TYPE_PARTY_MAP } from '../../../../helpers/constants';
import { findPartyByRole } from '../../../../helpers/utils';

export const INITIAL_ORDER_MEMBER_STATE = {
    party: '',
    role: '',
    on_title: false,
    on_loan: false,
};

export const generateInitialOrderMemberState = (order, seededRole) => {
    if (!seededRole) {
        return { ...INITIAL_ORDER_MEMBER_STATE };
    } else {
        return {
            ...INITIAL_ORDER_MEMBER_STATE,
            role: seededRole,
            party: findPartyByRole(order.transaction_type, seededRole),
        };
    }
};

const OrderMemberForm = ({ order, orderMemberData, setOrderMemberData }) => {
    const [showTitleCheckbox, setShowTitleCheckbox] = useState(false);
    const [showLoanCheckbox, setShowLoanCheckbox] = useState(false);

    useEffect(() => {
        if (_.includes([ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.borrower], orderMemberData.role)) {
            // Show title & loan options for buyer and default both to true
            setShowTitleCheckbox(true);
            setShowLoanCheckbox(true);
            setOrderMemberData({ ...orderMemberData, on_loan: true, on_title: true });
        } else if (orderMemberData.role === ORDER_ROLE_TYPE.seller) {
            // Show title but not loan options for seller and default title to true and loan to false
            setShowTitleCheckbox(true);
            setShowLoanCheckbox(false);
            setOrderMemberData({ ...orderMemberData, on_title: true, on_loan: false });
        } else {
            // Disabled title and loan options for all other roles and set both to false
            setShowTitleCheckbox(false);
            setShowLoanCheckbox(false);
            setOrderMemberData({ ...orderMemberData, on_title: false, on_loan: false });
        }
    }, [orderMemberData.role]);

    const handleCheckboxChange = ({ target }) => {
        setOrderMemberData({
            ...orderMemberData,
            [target.name]: target.checked,
        });
    };

    const updateRole = (role) => {
        const newParty = findPartyByRole(order.transaction_type, role);

        setOrderMemberData({
            ...orderMemberData,
            role,
            party: newParty,
        });
    };

    const handleRoleChange = ({ target }) => {
        const role = target.value;
        updateRole(role);
    };

    return (
        <Stack spacing={2}>
            <Typography variant="sectionHeader">Order Details</Typography>

            <Stack direction="row" spacing={2}>
                <TextField
                    name="role"
                    label="Role"
                    value={orderMemberData.role}
                    onChange={handleRoleChange}
                    style={{ minWidth: '150px' }}
                    size="small"
                    select
                    required
                >
                    {_.map(TRANSACTION_TYPE_PARTY_MAP[order.transaction_type], (roles, party) => [
                        <ListSubheader key={party}>{_.startCase(party)}</ListSubheader>,
                        roles.map((role) => (
                            <MenuItem key={role} value={role}>
                                {_.startCase(role)}
                            </MenuItem>
                        )),
                    ])}
                </TextField>
            </Stack>

            <Stack direction="row" spacing={2}>
                <FormControlLabel
                    label="Title"
                    control={
                        <Checkbox name="on_title" checked={orderMemberData.on_title} onChange={handleCheckboxChange} />
                    }
                    disabled={!showTitleCheckbox}
                />

                <FormControlLabel
                    label="Loan"
                    control={
                        <Checkbox name="on_loan" checked={orderMemberData.on_loan} onChange={handleCheckboxChange} />
                    }
                    disabled={!showLoanCheckbox}
                />
            </Stack>
        </Stack>
    );
};

OrderMemberForm.propTypes = {
    order: PropTypes.object.isRequired,
    orderMemberData: PropTypes.object.isRequired,
    setOrderMemberData: PropTypes.func.isRequired,
};

export default OrderMemberForm;
