import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useGetOrderMembersQuery } from 'api/order';
import NewAssignmentForm from './NewAssignmentForm';

const CreateAssignment = ({ order, handleSubmit }) => {
    const { data: orderMembers, isError: orderMembersError, isLoading: orderMembersLoading } = useGetOrderMembersQuery(
        order.id
    );

    return (
        <Stack spacing={4} sx={{ width: '480px' }}>
            <Stack direction="column" spacing={2}>
                <Typography variant="h5">Add Assignment Contract</Typography>
            </Stack>

            <NewAssignmentForm order={order} orderMembers={orderMembers} handleSubmit={handleSubmit} />
        </Stack>
    );
};

CreateAssignment.propTypes = {
    order: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default CreateAssignment;
