import { Slide, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { forwardRef } from 'react';

import { SNACKBAR_SEVERITY } from '../../helpers/constants';
import useSnackbar from './hooks/useSnackbar';

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} {...props} />;
});

function CustomSnackbar() {
    const { state, closeSnackbar } = useSnackbar();
    const { display, message, severity = SNACKBAR_SEVERITY.info, duration = 6000 } = state;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        closeSnackbar();
    };

    return (
        <Snackbar
            open={display}
            autoHideDuration={duration}
            TransitionComponent={TransitionUp}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                sx={{
                    width: '100%',
                }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
}

export default CustomSnackbar;
