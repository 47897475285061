import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetUserEmailLogsQuery } from '../../api/emailLog';
import { EMAIL_LOG_FILTER_TYPE } from '../../helpers/constants';
import EmailLogList from '../Log/EmailLogList';
import { EMAIL_LOG_LIST_GRID_COLUMN_TYPE } from '../Log/EmailLogList/components/EmailLogListGrid';

const USER_HIDE_FILTER_TYPE_LIST = [EMAIL_LOG_FILTER_TYPE.userId];
const USER_HIDE_COLUMN_TYPE_LIST = [EMAIL_LOG_LIST_GRID_COLUMN_TYPE.user];

function UserEmailLogList({ userId }) {
    const {
        data: userEmailLogs,
        isError: userEmailLogsError,
        isLoading: userEmailLogsLoading,
    } = useGetUserEmailLogsQuery(userId);

    return (
        <Box>
            <EmailLogList
                emailLogs={userEmailLogs || []}
                hideFilterTypeList={USER_HIDE_FILTER_TYPE_LIST}
                hideColumnTypeList={USER_HIDE_COLUMN_TYPE_LIST}
                loading={userEmailLogsLoading}
                pageTitle={null}
            />
        </Box>
    );
}

UserEmailLogList.propTypes = {
    userId: PropTypes.string.isRequired,
};

export default UserEmailLogList;
