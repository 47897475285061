import { FormLabel, Stack, TextField } from '@mui/material';
import PhoneFieldMask from 'components/common/mask/PhoneFieldMask';
import { CONTACT_TIER } from 'helpers/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ContactTierField from './ContactTierField';

// NOTE: confusing terminology as 'contact' was decided to be used on the frontend to refer to the users
// However, here contact refers to the contact details object used by multiple entities such as users, legal entities, etc.

export const CONTACT_FIELDS = {
    email: 'email',
    phone: 'phone',
    tier: 'tier',
};

export const INITIAL_CONTACT_DATA = {
    [CONTACT_FIELDS.email]: '',
    [CONTACT_FIELDS.phone]: '',
    [CONTACT_FIELDS.tier]: CONTACT_TIER.all,
};

export const formatContactPayload = (contactData) => {
    const contactPayload = {};
    let contactEmpty = true;
    _.forEach(contactData, (value, key) => {
        if (_.isNil(value) || value === '') {
            // If single field is empty assign null for field
            contactPayload[key] = null;
        } else {
            contactEmpty = false;
            contactPayload[key] = value;
        }
    });

    // If all fields are empty return null
    if (contactEmpty) {
        return null;
    }

    return contactPayload;
};

const ContactForm = ({
    contactData,
    setContactData,
    label = 'Contact',
    showLabel = false,
    required = false,
    disabled = false,
    requiredFields = [],
    disabledFields = [],
    hideFields = [CONTACT_FIELDS.preferences],
}) => {
    const handleChange = ({ target }) => {
        setContactData({
            ...contactData,
            [target.name]: target.value,
        });
    };

    return (
        <Stack direction="column" spacing={2}>
            {showLabel && (
                <FormLabel id="contact-group-label" className="form-group-label" required={false}>
                    {label}
                </FormLabel>
            )}

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <TextField
                    name={CONTACT_FIELDS.email}
                    label="Email"
                    onChange={handleChange}
                    value={contactData.email}
                    size="small"
                    sx={{
                        flex: 1,
                        ...(hideFields.includes(CONTACT_FIELDS.email) && { display: 'none' }),
                    }}
                    required={required || requiredFields.includes(CONTACT_FIELDS.email)}
                    disabled={disabled || disabledFields.includes(CONTACT_FIELDS.email)}
                />

                <TextField
                    name={CONTACT_FIELDS.phone}
                    label="Phone"
                    onChange={handleChange}
                    value={contactData.phone}
                    size="small"
                    InputProps={{
                        inputComponent: PhoneFieldMask,
                    }}
                    sx={{
                        flex: 1,
                        ...(hideFields.includes(CONTACT_FIELDS.phone) && { display: 'none' }),
                    }}
                    required={required || requiredFields.includes(CONTACT_FIELDS.phone)}
                    disabled={disabled || disabledFields.includes(CONTACT_FIELDS.phone)}
                />
            </Stack>

            {!hideFields.includes(CONTACT_FIELDS.tier) && (
                <ContactTierField
                    value={contactData.tier}
                    setValue={(value) => setContactData({ ...contactData, tier: value })}
                    required={required || requiredFields.includes(CONTACT_FIELDS.tier)}
                    disabled={disabled || disabledFields.includes(CONTACT_FIELDS.tier)}
                    hidden={hideFields.includes(CONTACT_FIELDS.tier)}
                />
            )}
        </Stack>
    );
};

ContactForm.propTypes = {
    contactData: PropTypes.object.isRequired,
    setContactData: PropTypes.func.isRequired,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    requiredFields: PropTypes.array,
    disabledFields: PropTypes.array,
    hideFields: PropTypes.array,
};

export default ContactForm;
