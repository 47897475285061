import { Divider, Stack, Typography } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers-pro';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import { useUpdateOrderMutation } from 'api/order';
import QuickEditField from 'components/common/form/order/QuickEditField';
import DollarFieldMask from 'components/common/mask/DollarFieldMask';
import { TRANSACTION_TYPE } from 'helpers/constants';
import CloseDateToggle from './CloseDateToggle';

function OrderMeta({ order }) {
    const [updateOrder, { isLoading: updateOrderLoading }] = useUpdateOrderMutation();

    const handleQuickEditChange = (name, value) => {
        // TODO will need to update when more types are added
        // if (parseFloat(order[name]) !== value) {
        handleOrderUpdate({
            [name]: value,
        });
        // }
    };

    const handleOrderUpdate = async (updatedOrderData) => {
        const updateOrderPayload = {
            orderId: order.id,
            orderData: updatedOrderData,
        };

        const { data } = await updateOrder(updateOrderPayload);
        return !!data;
    };

    return (
        <Stack direction="row" alignItems="flex-start" spacing={3}>
            {order.transaction_type === TRANSACTION_TYPE.purchase && (
                <Stack alignItems="center" spacing={1}>
                    <Typography variant="overline" fontSize="12px" fontWeight="400" textTransform="none">
                        Sales Price
                    </Typography>

                    <QuickEditField
                        name="purchase_price"
                        value={`${order.purchase_price || '0'}`}
                        onChange={handleQuickEditChange}
                        size="small"
                        InputProps={{
                            inputComponent: DollarFieldMask,
                            inputProps: {
                                style: {
                                    textAlign: 'center',
                                },
                            },
                        }}
                    />
                </Stack>
            )}

            <Stack alignItems="center" spacing={1}>
                <Typography variant="overline" fontSize="12px" fontWeight="400" textTransform="none">
                    Loan Price
                </Typography>
                <QuickEditField
                    name="loan_amount"
                    value={`${order.loan_amount || '0'}`}
                    onChange={handleQuickEditChange}
                    size="small"
                    InputProps={{
                        inputComponent: DollarFieldMask,
                        inputProps: {
                            style: {
                                textAlign: 'center',
                            },
                        },
                    }}
                />
            </Stack>

            <Divider orientation="vertical" flexItem sx={{ marginBottom: '8px !important' }} />

            <Stack alignItems="center" spacing={1}>
                <Typography variant="overline" fontSize="12px" fontWeight="400" textTransform="none">
                    Open Date
                </Typography>
                <MobileDatePicker
                    autoOk
                    closeOnSelect
                    openTo="day"
                    inputFormat="MM/dd/yyyy"
                    value={order.contract_start_date ? parseISO(order.contract_start_date) : null}
                    inputVariant="outlined"
                    onChange={(date) => {
                        // NOTE: not strict comparison due to issues with date error response object
                        if (date && date != 'Invalid Date') {
                            handleQuickEditChange('contract_start_date', format(date, 'yyyy-MM-dd'));
                        } else {
                            handleQuickEditChange('contract_start_date', null);
                        }
                    }}
                    renderInput={(params) => (
                        <QuickEditField
                            name="contract_start_date"
                            size="small"
                            value={params.inputProps.value}
                            onChange={params.inputProps.onChange}
                            type="text"
                            selectOnFocus={false}
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                inputProps: {
                                    ...params.InputProps.inputProps,
                                    style: {
                                        textAlign: 'center',
                                    },
                                },
                            }}
                        />
                    )}
                    clearable
                />
            </Stack>

            <Stack alignItems="center" spacing={1}>
                <Typography variant="overline" fontSize="12px" fontWeight="400" textTransform="none">
                    Fund Date
                </Typography>
                <MobileDatePicker
                    autoOk
                    closeOnSelect
                    openTo="day"
                    inputFormat="MM/dd/yyyy"
                    value={order.disbursement_date ? parseISO(order.disbursement_date) : null}
                    inputVariant="outlined"
                    onChange={(date) => {
                        // NOTE: not strict comparison due to issues with date error response object
                        if (date && date != 'Invalid Date') {
                            handleQuickEditChange('disbursement_date', format(date, 'yyyy-MM-dd'));
                        } else {
                            handleQuickEditChange('disbursement_date', null);
                        }
                    }}
                    renderInput={(params) => (
                        <QuickEditField
                            name="disbursement_date"
                            size="small"
                            value={params.inputProps.value}
                            onChange={params.inputProps.onChange}
                            type="text"
                            selectOnFocus={false}
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                inputProps: {
                                    ...params.InputProps.inputProps,
                                    style: {
                                        textAlign: 'center',
                                    },
                                },
                            }}
                        />
                    )}
                    clearable
                />
            </Stack>

            <Divider orientation="vertical" flexItem sx={{ marginBottom: '8px !important' }} />

            <Stack direction="row" alignItems="flex-end" spacing={1}>
                <Stack alignItems="center" spacing={1}>
                    <Typography variant="overline" fontSize="12px" fontWeight="400" textTransform="none">
                        Close Date
                    </Typography>
                    <MobileDatePicker
                        autoOk
                        closeOnSelect
                        openTo="day"
                        inputFormat="MM/dd/yyyy"
                        value={order.close_date ? parseISO(order.close_date) : null}
                        inputVariant="outlined"
                        onChange={(date) => {
                            // NOTE: not strict comparison due to issues with date error response object
                            if (date && date != 'Invalid Date') {
                                handleQuickEditChange('close_date', format(date, 'yyyy-MM-dd'));
                            } else {
                                handleQuickEditChange('close_date', null);
                            }
                        }}
                        renderInput={(params) => (
                            <QuickEditField
                                name="close_date"
                                size="small"
                                value={params.inputProps.value}
                                onChange={params.inputProps.onChange}
                                type="text"
                                selectOnFocus={false}
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    inputProps: {
                                        ...params.InputProps.inputProps,
                                        style: {
                                            textAlign: 'center',
                                        },
                                    },
                                }}
                            />
                        )}
                        clearable
                    />
                </Stack>

                <CloseDateToggle orderId={order.id} confirmedCloseDate={order.confirmed_close_date} />
            </Stack>
        </Stack>
    );
}

OrderMeta.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OrderMeta;
