import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Box, IconButton, Link as MULink, Stack } from '@mui/material';
import {
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    gridDetailPanelExpandedRowsContentCacheSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-pro';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import OrderTransactionChip from 'components/Order/components/OrderTransactionChip';
import { DataGrid } from 'components/common/styled';
import { formatAddress, formatISODate } from 'helpers/utils';
import ActionTable from './ActionTable';

function CustomDetailPanelToggle(props) {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton size="small" tabIndex={-1} disabled={!hasDetail} aria-label={isExpanded ? 'Close' : 'Open'}>
            <KeyboardArrowDownIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const BaseColumnProps = {};

const ActionListColumns = [
    {
        ...BaseColumnProps,
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
    },
    {
        field: 'qualia_order_number',
        headerName: 'Order #',
        minWidth: 150,
        renderCell: (params) => (
            <MULink
                component={Link}
                to={`/order/${params.row.id}`}
                underline="hover"
                sx={{
                    color: (theme) => theme.palette.text.link,
                }}
            >
                {params.value}
            </MULink>
        ),
    },
    {
        field: 'property_address',
        headerName: 'Address',
        flex: 1,
        minWidth: 200,
        // valueFormatter: ({ value }) => formatAddress(value),
        renderCell: (params) => {
            const address = formatAddress(params.value);
            return (
                <MULink
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`}
                    target="_blank"
                    underline="hover"
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                        // fontWeight: '500',
                        '& .MuiSvgIcon-root': {
                            visibility: 'hidden',
                        },
                        '&:hover .MuiSvgIcon-root': {
                            visibility: 'visible',
                        },
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <span>{address}</span>
                        <OpenInNewOutlinedIcon
                            sx={{
                                fontSize: '14px',
                            }}
                        />
                    </Stack>
                </MULink>
            );
        },
        sortable: false,
    },
    {
        field: 'transaction_type',
        headerName: 'Transaction Type',
        minWidth: 150,
        valueFormatter: ({ value }) => _.startCase(value),
        renderCell: (params) => <OrderTransactionChip transactionType={params.value} size="small" />,
    },
    {
        field: 'order_type',
        headerName: 'Order Type',
        minWidth: 150,
        valueFormatter: ({ value }) => _.startCase(value),
    },
    {
        field: 'close_date',
        type: 'date',
        headerName: 'Close Date',
        minWidth: 100,
        valueFormatter: ({ value }) => formatISODate(value),
    },
    {
        field: 'activeParcelActions',
        type: 'number',
        headerName: 'Actions',
        valueGetter: (params) => params.value.length,
        minWidth: 50,
    },
];

function ActionListDataGrid({ orderList, loading }) {
    const [pageSize, setPageSize] = useState(10);

    const getDetailPanelContent = React.useCallback(({ row }) => {
        return (
            <Box sx={{ p: 8 }}>
                <Box>
                    <ActionTable orderParcelActionList={row.activeParcelActions} />
                </Box>
            </Box>
        );
    }, []);

    return (
        <DataGrid
            columns={ActionListColumns}
            rows={orderList}
            loading={loading}
            getRowId={(row) => row.id}
            onRowClick={(params, event, details) => {
                details.api.toggleDetailPanel(params.id);
            }}
            autoHeight
            disableColumnMenu
            disableColumnResize
            disableSelectionOnClick
            density="comfortable"
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={({ row }) => 'auto'}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            pagination
            // hideFooter
            // autoPageSize
        />
    );
}

ActionListDataGrid.propTypes = {
    orderList: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};

export default ActionListDataGrid;
