import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BlockIcon from '@mui/icons-material/Block';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { Avatar, Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function LegalEntityHeaderAction({ legalEntity }) {
    return null;
}

function LegalEntityHeaderChipList({ legalEntity }) {
    // TODO - just a copy of user chip list atm
    const sharedProps = {
        size: 'small',
        variant: 'outlined',
        sx: {
            borderRadius: (theme) => theme.spacing(0.5),
        },
    };
    const accountChip = legalEntity.external_user_id ? (
        <Tooltip title="User has a portal account" placement="bottom" enterDelay={300}>
            <span style={{ display: 'inline-block' }}>
                <Chip label="Account" color="primary" {...sharedProps} icon={<VerifiedUserIcon />} />
            </span>
        </Tooltip>
    ) : null;
    const blockedChip = legalEntity.blocked ? (
        <Tooltip title="User is blocked from logging into the portal" placement="bottom" enterDelay={300}>
            <span style={{ display: 'inline-block' }}>
                <Chip label="Blocked" color="error" {...sharedProps} icon={<BlockIcon />} />
            </span>
        </Tooltip>
    ) : null;

    if (!accountChip && !blockedChip) {
        return null;
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {accountChip}
            {blockedChip}
        </Stack>
    );
}

function LegalEntityHeaderTitle({ legalEntity }) {
    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h1">{legalEntity.name}</Typography>
            {/* <LegalEntityHeaderChipList legalEntity={legalEntity} /> */}
        </Stack>
    );
}

function LegalEntityHeader({ legalEntity }) {
    return (
        <Stack direction="row" alignItems="center" spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar
                    sx={{
                        width: 64,
                        height: 64,
                        fontSize: 28,
                        bgcolor: 'contact.main',
                        color: 'contact.light',
                    }}
                >
                    <AccountBalanceIcon fontSize="large" />
                </Avatar>

                <LegalEntityHeaderTitle legalEntity={legalEntity} />
            </Stack>

            <Box sx={{ flexGrow: 1 }} />

            <LegalEntityHeaderAction legalEntity={legalEntity} />
        </Stack>
    );
}

LegalEntityHeader.propTypes = {
    legalEntity: PropTypes.object.isRequired,
};

export default LegalEntityHeader;
