import AddIcon from '@mui/icons-material/Add';
import { Chip, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useGetOrderQuery } from '../../../api/order';
import { grabEntities, grabEntityName } from '../../common/hooks/useNoteData';
import { Button } from '../../common/styled';
import TagDialog from './TagDialog';

function NoteTag({ orderId, tag, setTag, editable = false }) {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const [open, setOpen] = useState(false);
    const [tagEntity, setTagEntity] = useState(null);
    const [tagEntityName, setTagEntityName] = useState(null);

    useEffect(() => {
        if (tag && order) {
            const entityType = tag.entity_type;
            const entityId = tag.entity_id;
            const entities = grabEntities(order, entityType, entityId);
            const entityName = grabEntityName(order, entityType, entityId);

            setTagEntity(_.get(entities, 0, null));
            setTagEntityName(entityName);
        } else {
            setTagEntity(null);
            setTagEntityName(null);
        }
    }, [tag, order]);

    if (!tag && !editable) {
        return null;
    }

    const handleClose = (submit = false) => {
        setOpen(false);
    };

    const deleteProps = editable ? { onDelete: () => setTag(null) } : {};

    return (
        <Stack direction="row" spacing={1}>
            {!tag && editable && (
                <Button
                    onClick={() => setOpen(true)}
                    color="default"
                    variant="outlined"
                    size="small"
                    startIcon={<AddIcon />}
                    sx={{ minWidth: '80px' }}
                >
                    Tag
                </Button>
            )}

            {tag && tagEntity && (
                <Chip
                    label={tagEntityName}
                    // onClick={} // TODO navigate to entity
                    size="small"
                    {...deleteProps}
                />
            )}

            <TagDialog order={order} open={open} handleClose={handleClose} setTag={setTag} />
        </Stack>
    );
}

NoteTag.propTypes = {
    orderId: PropTypes.string.isRequired,
    tag: PropTypes.object,
    setTag: PropTypes.func.isRequired,
    editable: PropTypes.bool,
};

export default NoteTag;
