import { ACTION_TERMINAL_STATUSES } from 'helpers/constants';
import _ from 'lodash';
import { useMemo } from 'react';

export function getAssignedActions(order) {
    const assignedActions = [];

    // NOTE: handles undefined order with empty list
    _.forEach(_.get(order, 'parcels', []), (parcel) => {
        _.forEach(parcel.actions, (action) => {
            if (action.order_member_id) {
                assignedActions.push({
                    order_id: order.id, // NOTE: this is not provided on default action object
                    ...action,
                });
            }
        });
    });

    return assignedActions;
}

export function getActiveAssignedActions(order) {
    const assignedActions = getAssignedActions(order);

    return _.filter(assignedActions, (action) => {
        return !_.includes(ACTION_TERMINAL_STATUSES, action.status);
    });
}

function useGetOrderAssignedActions(order) {
    const assignedActions = useMemo(() => getAssignedActions(order), [order]);

    return assignedActions;
}

export default useGetOrderAssignedActions;
