import PropTypes from 'prop-types';
import React from 'react';
import BaseDialog from '../../common/BaseDialog';

import CreateNote from './CreateNote';

const CreateNoteDialog = ({ open, orderId, handleClose, handleSuccess, seedTag = null }) => {
    const handleLocalSuccess = (newNoteId) => {
        handleClose();
        handleSuccess(newNoteId);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleClose()}
            DialogProps={{
                maxWidth: 'md',
                fullWidth: true,
            }}
        >
            {open && <CreateNote orderId={orderId} handleSuccess={handleLocalSuccess} seedTag={seedTag} />}
        </BaseDialog>
    );
};

CreateNoteDialog.propTypes = {
    orderId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    seedTag: PropTypes.object,
};

export default CreateNoteDialog;
