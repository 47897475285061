import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, ListItem, ListItemText } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useDeleteLienMemberMutation } from '../../../../api/lien';

const LienMemberListItem = ({ lien, lienMember, orderMembers }) => {
    const [deleteLienMember, { isError: deleteError, isLoading: deleteLoading }] = useDeleteLienMemberMutation();

    const orderMember = useMemo(() => {
        return _.find(orderMembers, { id: lienMember.order_member_id });
    }, [lienMember, orderMembers]);

    const handleDeleteLienMember = async () => {
        const deleteLienMemberPayload = {
            lienId: lien.id,
            memberId: lienMember.id,
        };

        const { data } = await deleteLienMember(deleteLienMemberPayload);
        return !!data;
    };

    const handleDelete = (event) => {
        event.preventDefault();

        handleDeleteLienMember();
    };

    const name = orderMember ? _.get(orderMember, 'member.name') : lienMember.name;

    return (
        <ListItem
            secondaryAction={
                <IconButton onClick={handleDelete} aria-label="delete" disabled={deleteLoading} size="small">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            }
            disableGutters
        >
            <ListItemText primary={name} secondary={_.startCase(lienMember.type)} />
        </ListItem>
    );
};

LienMemberListItem.propTypes = {
    lien: PropTypes.object.isRequired,
    lienMember: PropTypes.object.isRequired,
    orderMembers: PropTypes.array.isRequired,
};

export default LienMemberListItem;
