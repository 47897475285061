import { Box, List, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import LegalEntityMembershipListItem from './LegalEntityMembershipListItem';

const LegalEntityMembershipList = ({ legalEntityMemberships }) => {
    if (!legalEntityMemberships || legalEntityMemberships.length === 0) {
        return (
            <Box sx={{ padding: 3, paddingTop: 0 }}>
                <Typography variant="body1" color="text.secondary" fontStyle="italic">
                    No current organization memberships
                </Typography>
            </Box>
        );
    }

    return (
        <List
            sx={{
                '& .legal-entity-membership-list-item': {
                    '& .MuiListItemSecondaryAction-root': {
                        right: (theme) => theme.spacing(3),
                    },
                },
                '& .legal-entity-membership-list-item-button': {
                    paddingLeft: 3,
                    paddingRight: 3,
                },
            }}
            disablePadding
        >
            {_.map(legalEntityMemberships, (legalEntityMembership) => {
                return (
                    <LegalEntityMembershipListItem
                        key={legalEntityMembership.id}
                        legalEntityMembership={legalEntityMembership}
                    />
                );
            })}
        </List>
    );
};

LegalEntityMembershipList.propTypes = {
    legalEntityMemberships: PropTypes.array.isRequired,
};

export default LegalEntityMembershipList;
