import AddIcon from '@mui/icons-material/Add';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import AddRelationshipModal from './AddRelationshipModal';
import EditRelationshipModal from './EditRelationshipModal';
import UserRelationshipList from './UserRelationshipList';

function UserDetailsRelationshipList({ user }) {
    const [openAddRelationshipModal, setOpenAddRelationshipModal] = useState(false);
    const [editRelationship, setEditRelationship] = useState(null);

    const handleAddRelationship = () => {
        setOpenAddRelationshipModal(true);
    };

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(0) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ padding: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Relationships</Typography>

                <IconButton onClick={() => handleAddRelationship()} size="small">
                    <AddIcon />
                </IconButton>
            </Stack>

            <UserRelationshipList
                relationships={user.relationships}
                handleEditRelationship={(relationship) => setEditRelationship(relationship)}
            />

            <AddRelationshipModal
                user={user}
                open={openAddRelationshipModal}
                handleClose={() => setOpenAddRelationshipModal(false)}
            />

            <EditRelationshipModal
                user={user}
                relationship={editRelationship}
                handleClose={() => setEditRelationship(null)}
            />
        </Paper>
    );
}

UserDetailsRelationshipList.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserDetailsRelationshipList;
