import EditIcon from '@mui/icons-material/Edit';
import { IconButton, ListItem, ListItemButton, ListItemText } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { USER_RELATIONSHIP_TYPE_INVERSE_MAP } from 'helpers/constants';

const UserRelationshipListItem = ({ relationship, handleEditRelationship }) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(`/contact/user/${relationship.related_user.id}`);
    };

    return (
        <ListItem
            className="user-relationship-list-item"
            secondaryAction={
                <IconButton aria-label="edit" onClick={() => handleEditRelationship(relationship)} size="small">
                    <EditIcon fontSize="small" />
                </IconButton>
            }
            disablePadding
        >
            <ListItemButton className="user-relationship-list-item-button" onClick={handleNavigate}>
                <ListItemText
                    primary={relationship.related_user.name}
                    secondary={_.startCase(
                        _.get(USER_RELATIONSHIP_TYPE_INVERSE_MAP, relationship.relation, relationship.relation)
                    )}
                />
            </ListItemButton>
        </ListItem>
    );
};

UserRelationshipListItem.propTypes = {
    relationship: PropTypes.object.isRequired,
    handleEditRelationship: PropTypes.func.isRequired,
};

export default UserRelationshipListItem;
