// import AttachEmailIcon from '@mui/icons-material/AttachEmail';
// import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
// import EmailIcon from '@mui/icons-material/Email';
import { Box, Checkbox, Chip, FormControlLabel, FormGroup, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { useBulkUpdateDocumentAccessMutation } from 'api/document';
import { generateOrderMemberRoleMap, getOrderMemberName } from 'components/Order/Members/utils';
import { Button, LoadingButton } from 'components/common/styled';

function DocumentAccessMemberItem({ orderMember, hasAccess, hasOriginalAccess, sendAccessEmail, handleChange }) {
    // Show email icon if new access is granted and send email is enabled
    const currentAccessRemoved = !hasAccess && hasOriginalAccess;
    const newAccessGranted = hasAccess && !hasOriginalAccess;
    const showAccessEmailIcon = sendAccessEmail && newAccessGranted;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <FormControlLabel
                control={<Checkbox checked={hasAccess} name={orderMember.id} onChange={handleChange} />}
                label={getOrderMemberName(orderMember)}
                sx={{ flexGrow: 1 }}
            />

            <Stack direction="row" alignItems="center" spacing={1}>
                {currentAccessRemoved && (
                    <Tooltip title="Current Access Revoked" placement="bottom" enterDelay={300}>
                        <Chip
                            label="Revoked"
                            color="warning"
                            size="small"
                            sx={{
                                borderRadius: '4px',
                                bgcolor: 'warning.light',
                                color: 'warning.dark',
                            }}
                        />
                    </Tooltip>
                )}

                {showAccessEmailIcon && (
                    <Tooltip title="May Receive Access Email" placement="bottom" enterDelay={300}>
                        <Chip
                            label="Email"
                            // icon={<ForwardToInboxIcon />}
                            color="default"
                            size="small"
                            sx={{
                                borderRadius: '4px',
                            }}
                        />
                    </Tooltip>
                )}

                {newAccessGranted && (
                    <Tooltip title="New Access Granted" placement="bottom" enterDelay={300}>
                        <Chip
                            label="Granted"
                            size="small"
                            sx={{
                                borderRadius: '4px',
                                bgcolor: 'success.light',
                                color: 'success.dark',
                            }}
                        />
                    </Tooltip>
                )}
            </Stack>
        </Stack>
    );
}

DocumentAccessMemberItem.propTypes = {
    orderMember: PropTypes.object.isRequired,
    hasAccess: PropTypes.bool.isRequired,
    hasOriginalAccess: PropTypes.bool.isRequired,
    sendAccessEmail: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
};

function DocumentAccessRoleGroup({
    role,
    roleOrderMembers,
    documentAccessUpdatedList,
    documentAccessOriginalList,
    sendAccessEmail,
    handleChange,
}) {
    return (
        <Stack direction="column" spacing={1} key={role} sx={{ marginBottom: (theme) => theme.spacing(3) }}>
            <Typography variant="sectionHeader" color="text.primary">
                {_.startCase(role)}
            </Typography>
            {_.map(roleOrderMembers, (orderMember) => {
                return (
                    <DocumentAccessMemberItem
                        key={orderMember.id}
                        orderMember={orderMember}
                        handleChange={handleChange}
                        hasAccess={_.includes(documentAccessUpdatedList, orderMember.id)}
                        hasOriginalAccess={_.includes(documentAccessOriginalList, orderMember.id)}
                        sendAccessEmail={sendAccessEmail}
                    />
                );
            })}
        </Stack>
    );
}

DocumentAccessRoleGroup.propTypes = {
    role: PropTypes.string.isRequired,
    roleOrderMembers: PropTypes.array.isRequired,
    documentAccessUpdatedList: PropTypes.array.isRequired,
    documentAccessOriginalList: PropTypes.array.isRequired,
    sendAccessEmail: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
};

function DocumentAccessForm({ orderId, document, orderMembers, handleSubmit }) {
    const [bulkUpdateDocumentAccess] = useBulkUpdateDocumentAccessMutation();

    const orderMemberRoleMap = useMemo(() => generateOrderMemberRoleMap(orderMembers), [orderMembers]);
    const documentAccessMemberIdMap = useMemo(
        () => (document?.access ? _.keyBy(document.access, 'order_member_id') : {}),
        [document]
    );
    const documentAccessOriginalList = useMemo(() => _.keys(documentAccessMemberIdMap).sort(), [
        documentAccessMemberIdMap,
    ]);

    const [documentAccessUpdatedList, setDocumentAccessUpdatedList] = useState([...documentAccessOriginalList]);
    const [sendAccessEmail, setSendAccessEmail] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const handleUpdateDocumentAccess = (event) => {
        event.preventDefault();

        // Create list of 'new' access in Updated list that are not in original list
        // Returns array of values that exists in the 'new' list but not in the 'original' list
        const createAccessOrderMembers = _.difference(documentAccessUpdatedList, documentAccessOriginalList);

        // Create list of 'removed' access in original list that are not in updated list
        // Returns array of values that exist in the 'original' list but not in the 'new' list
        const removeAccessOrderMembers = _.difference(documentAccessOriginalList, documentAccessUpdatedList);

        if (createAccessOrderMembers.length === 0 && removeAccessOrderMembers.length === 0) {
            // No changes - ignore submit request
            return null;
        }

        // Find document_access_id for each order_member_id in the remove access list
        const removeDocumentAccessIdList = _.map(removeAccessOrderMembers, (orderMemberId) => {
            const documentAccess = documentAccessMemberIdMap[orderMemberId];
            if (!!documentAccess) {
                return documentAccess.id;
            }
        });

        // Submit bulk request for to update access
        setSubmitting(true);

        return submitAccessUpdate({
            create: createAccessOrderMembers,
            remove: removeDocumentAccessIdList,
            send_access_email: sendAccessEmail,
        }).then((response) => {
            setSubmitting(false);
            handleSubmit();
        });
    };

    const submitAccessUpdate = async (updateData) => {
        const { data } = await bulkUpdateDocumentAccess({
            documentId: document.id,
            documentAccessData: updateData,
        });

        if (data) {
            return true;
        } else {
            console.warn(`Failed to update access for document ${document.id}`);
            return false;
        }
    };

    const handleChange = (event) => {
        const orderMemberId = event.target.name;
        const checked = event.target.checked;

        const alreadyContainsMember = _.includes(documentAccessUpdatedList, orderMemberId);
        if (alreadyContainsMember && checked) {
            // Do nothing
            return;
        }

        if (!alreadyContainsMember && !checked) {
            // Do nothing
            return;
        }

        if (alreadyContainsMember && !checked) {
            // Remove from list
            setDocumentAccessUpdatedList(_.filter(documentAccessUpdatedList, (id) => id !== orderMemberId).sort());
            return;
        }

        if (!alreadyContainsMember && checked) {
            // Add to list
            setDocumentAccessUpdatedList([...documentAccessUpdatedList, orderMemberId].sort());
        }
    };

    const handleSendAccessChange = ({ target }) => {
        const sendAccessEmail = target.checked;
        setSendAccessEmail(sendAccessEmail);
    };

    if (!orderId) {
        return null;
    }

    return (
        <form onSubmit={handleUpdateDocumentAccess}>
            <Stack spacing={4}>
                <FormGroup>
                    {_.map(orderMemberRoleMap, (roleOrderMembers, role) => {
                        return (
                            <DocumentAccessRoleGroup
                                key={role}
                                role={role}
                                roleOrderMembers={roleOrderMembers}
                                documentAccessUpdatedList={documentAccessUpdatedList}
                                documentAccessOriginalList={documentAccessOriginalList}
                                sendAccessEmail={sendAccessEmail}
                                handleChange={handleChange}
                            />
                        );
                    })}
                    <Stack direction="row" justifyContent="flex-end">
                        <Box
                            sx={{
                                border: (theme) => `1px solid ${theme.palette.divider}`,
                                borderRadius: (theme) => theme.spacing(1),
                                padding: (theme) => theme.spacing(0, 1),
                                background: (theme) => theme.palette.background.gray,
                            }}
                        >
                            <FormControlLabel
                                label="Send Email"
                                control={
                                    <Checkbox
                                        name="sendAccessEmail"
                                        checked={sendAccessEmail}
                                        onChange={handleSendAccessChange}
                                    />
                                }
                                disabled={false}
                            />
                        </Box>
                    </Stack>
                </FormGroup>

                <Stack direction="row" spacing={2} alignItems="center">
                    <Box flexGrow={1} />

                    <Button onClick={handleSubmit} color="default" disabled={submitting}>
                        Cancel
                    </Button>

                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        disableElevation
                        loading={submitting}
                        disabled={submitting || _.isEqual(documentAccessOriginalList, documentAccessUpdatedList)}
                        sx={{
                            minWidth: '160px',
                        }}
                    >
                        Update
                    </LoadingButton>
                </Stack>
            </Stack>
        </form>
    );
}

DocumentAccessForm.propTypes = {
    orderId: PropTypes.string.isRequired,
    document: PropTypes.object.isRequired,
    orderMembers: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default DocumentAccessForm;
