import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Chip, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { ACTION_STATUS, PARCEL_STATUS } from '../../../helpers/constants';

function OutstandingActionList({ order, closingParcel, fundingParcel }) {
    if (!closingParcel && !fundingParcel) {
        return null;
    }

    if (closingParcel.current_status.status !== PARCEL_STATUS.complete) {
        return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ height: '100%' }}>
                <AccessTimeIcon fontSize="large" color="warning" />
                <Typography>Waiting to Close</Typography>
            </Stack>
        );
    }

    if (fundingParcel.current_status.status === PARCEL_STATUS.complete) {
        return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ height: '100%' }}>
                <CurrencyExchangeIcon fontSize="large" color="success" />
                <Typography>Funded</Typography>
            </Stack>
        );
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                flexWrap: 'wrap',
                gap: 1,
                '& > *': {
                    marginRight: (theme) => theme.spacing(1),
                    '&:last-child': {
                        marginRight: 0,
                    },
                },
            }}
        >
            {_.map(fundingParcel.actions, (parcelAction) => {
                // Filter out complete actions
                if (parcelAction.status !== ACTION_STATUS.complete) {
                    const buttonLink = `/order/${order.id}/parcel/${fundingParcel.id}`;
                    const buttonText = parcelAction.friendly_name;
                    return (
                        <Chip
                            key={parcelAction.id}
                            component={Link}
                            to={buttonLink}
                            label={buttonText}
                            clickable
                            size="small"
                            variant="outlined"
                        />
                    );
                }
            })}
        </Stack>
    );
}

export default OutstandingActionList;
