import { Checkbox, ListItemText, MenuItem, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import {
    META_PARCEL_TYPE,
    PARCEL_ACTION_FILTER_TYPE,
    PARCEL_NAME_OVERRIDE,
    PARCEL_TYPE,
} from '../../../helpers/constants';
import useFilterQueryParams from '../../common/hooks/useFilterQueryParams';
import FilterTextField from '../../common/styled/FilterTextField';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
};

export const IGNORE_PARCEL_TYPES = [
    META_PARCEL_TYPE.title,
    META_PARCEL_TYPE.opening,
    META_PARCEL_TYPE.closing,
    PARCEL_TYPE.payoffs,
    // Closing child parcels are all included in their parent parcel -  TODO make a const for this
    PARCEL_TYPE.closing_borrower,
    PARCEL_TYPE.closing_buyer,
    PARCEL_TYPE.closing_seller,
    // Signing child parcels are all included in their parent parcel - TODO make a const for this 
    PARCEL_TYPE.signing_in_house,
    PARCEL_TYPE.signing_mobile_notary,
    PARCEL_TYPE.signing_electronic_notary,
    // Balancing child parcels are all included in their parent parcel - TODO make a const for this
    PARCEL_TYPE.balancing_contract,
    PARCEL_TYPE.balancing_earnest_money,
    PARCEL_TYPE.balancing_taxes,
    PARCEL_TYPE.balancing_hoa,
    PARCEL_TYPE.balancing_home_warranty,
    PARCEL_TYPE.balancing_commissions,
    PARCEL_TYPE.balancing_deed,
    PARCEL_TYPE.balancing_liens,
    PARCEL_TYPE.balancing_title_fees,
    PARCEL_TYPE.balancing_lender_fees,
    PARCEL_TYPE.balancing_other,
    PARCEL_TYPE.balancing_final_cd_hud,
];

export const FILTERED_PARCEL_TYPE_OPTIONS = _.filter(
    PARCEL_TYPE,
    (parcelType) => !_.includes(IGNORE_PARCEL_TYPES, parcelType)
);

const calculateParcelStatusAndActionOptions = (orderList, parcelTypeFilterList) => {
    const parcelStatusOptions = [];
    const parcelActionOptions = [];

    if (parcelTypeFilterList.length === 1 && orderList && orderList.length > 0) {
        // Note: this will not work if first order doesn't contain specified parcel
        // TODO rethink/improve
        // Find parcel with single type
        const parcelType = parcelTypeFilterList[0];
        const order = orderList[0];
        const parcel = _.find(order.parcels, (parcel) => parcel.type === parcelType);

        if (parcel && parcel.potential_statuses) {
            parcelStatusOptions.push(...parcel.potential_statuses);
        }

        if (parcel && parcel.potential_actions) {
            parcelActionOptions.push(...parcel.potential_actions);
        }
    }

    return { parcelStatusOptions, parcelActionOptions };
};

function ActionListFilters({ filters, defaultFilters, setFilters, orderList }) {
    const [syncFilterMap, searchParams] = useFilterQueryParams(filters, defaultFilters, {
        multiArgFilterTypes: [PARCEL_ACTION_FILTER_TYPE.parcelType],
    });

    const { parcelStatusOptions, parcelActionOptions } = useMemo(
        () => calculateParcelStatusAndActionOptions(orderList, filters[PARCEL_ACTION_FILTER_TYPE.parcelType]),
        [orderList, filters[PARCEL_ACTION_FILTER_TYPE.parcelType]]
    );

    useEffect(() => {
        // Update filters with new values from query params
        if (_.keys(syncFilterMap).length > 0) {
            const updatedFilterMap = {
                ...defaultFilters,
                ...syncFilterMap,
            };

            // Update local filters
            setFilters(updatedFilterMap);
        }
    }, [syncFilterMap]);

    const resetParcelType = (event) => {
        event.preventDefault();

        handleFilter({
            target: {
                name: PARCEL_ACTION_FILTER_TYPE.parcelType,
                value: defaultFilters[PARCEL_ACTION_FILTER_TYPE.parcelType],
            },
        });
    };

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string isn't valid for some filters
        };

        if (filterName === PARCEL_ACTION_FILTER_TYPE.parcelType) {
            // Parcel statuses and actions are related to parcel type
            // Reset parcel status and parcel actions filters if parcel type is changed
            filterUpdate[PARCEL_ACTION_FILTER_TYPE.parcelStatus] =
                defaultFilters[PARCEL_ACTION_FILTER_TYPE.parcelStatus];
            filterUpdate[PARCEL_ACTION_FILTER_TYPE.actionType] = defaultFilters[PARCEL_ACTION_FILTER_TYPE.actionType];
        }

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <FilterTextField
                name={PARCEL_ACTION_FILTER_TYPE.parcelType}
                label="Parcel Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters[PARCEL_ACTION_FILTER_TYPE.parcelType]}
                size="small"
                sx={{
                    minWidth: '200px',
                }}
                select
                SelectProps={{
                    renderValue: (selected) =>
                        _.map(selected, (item) => _.get(PARCEL_NAME_OVERRIDE, item, _.startCase(item))).join(', '),
                    multiple: true,
                    MenuProps,
                }}
            >
                {filters[PARCEL_ACTION_FILTER_TYPE.parcelType].length > 0 && (
                    <MenuItem onClick={resetParcelType}>Clear All</MenuItem>
                )}

                {_.map(FILTERED_PARCEL_TYPE_OPTIONS, (parcelTypeValue) => {
                    return (
                        <MenuItem key={parcelTypeValue} value={parcelTypeValue}>
                            <Checkbox
                                checked={_.includes(filters[PARCEL_ACTION_FILTER_TYPE.parcelType], parcelTypeValue)}
                            />
                            <ListItemText
                                primary={_.get(PARCEL_NAME_OVERRIDE, parcelTypeValue, _.startCase(parcelTypeValue))}
                            />
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <FilterTextField
                name={PARCEL_ACTION_FILTER_TYPE.parcelStatus}
                label="Parcel Status"
                variant="outlined"
                onChange={handleFilter}
                value={filters[PARCEL_ACTION_FILTER_TYPE.parcelStatus]}
                size="small"
                sx={{
                    minWidth: '200px',
                }}
                select
            >
                <MenuItem value="">All</MenuItem>
                {_.map(parcelStatusOptions, (parcelStatusOption) => {
                    return (
                        <MenuItem key={parcelStatusOption} value={parcelStatusOption}>
                            {_.startCase(parcelStatusOption)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <FilterTextField
                name={PARCEL_ACTION_FILTER_TYPE.actionType}
                label="Action Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters[PARCEL_ACTION_FILTER_TYPE.actionType]}
                size="small"
                sx={{
                    minWidth: '200px',
                }}
                select
            >
                <MenuItem value="">All</MenuItem>
                {_.map(parcelActionOptions, (parcelActionOption) => {
                    return (
                        <MenuItem key={parcelActionOption.type} value={parcelActionOption.type}>
                            {parcelActionOption.friendly_name}
                        </MenuItem>
                    );
                })}
            </FilterTextField>
        </Stack>
    );
}

ActionListFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    defaultFilters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    orderList: PropTypes.array.isRequired,
};

export default ActionListFilters;
