import DoneIcon from '@mui/icons-material/Done';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import StopIcon from '@mui/icons-material/Stop';
import { List, ListItem, ListItemText, Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useUpdateLienMutation } from '../../../../api/lien';
import { useUpdatePayoffMutation } from '../../../../api/payoff';
import { ENTITY_TYPE } from '../../../../helpers/constants';
import { getTriggers, useEvalTriggerConditions, useTriggers } from '../../../common/hooks/useTriggers';
import { LoadingButton } from '../../../common/styled';

// TODO unify these for all entities
const TRIGGER_ICON_MAP = {
    play: <PlayArrowIcon />,
    stop: <StopIcon />,
    pause: <PauseIcon />,
    restart: <RestartAltIcon />,
    done: <DoneIcon />,
    generate: <NoteAddIcon />,
    default: <DoneIcon />,
};

function usePayoffUpdateMutation() {
    const [updatePayoff, { isLoading }] = useUpdatePayoffMutation();

    const submitUpdate = async (payoff, updateData) => {
        const updatePayload = {
            payoffId: payoff.id,
            payoffData: updateData,
        };

        const { data } = await updatePayoff(updatePayload);
    };

    return [submitUpdate, { isLoading }];
}

function useLienUpdateMutation() {
    const [updateLien, { isLoading }] = useUpdateLienMutation();

    const submitUpdate = async (lien, updateData) => {
        const updatePayload = {
            lienId: lien.id,
            lienData: updateData,
        };

        const { data } = await updateLien(updatePayload);
    };

    return [submitUpdate, { isLoading }];
}

function useDataEntityUpdateMutation(dataEntityType) {
    switch (dataEntityType) {
        case ENTITY_TYPE.lien:
            return useLienUpdateMutation;
        case ENTITY_TYPE.payoff:
            return usePayoffUpdateMutation;
        default:
            return null;
    }
}

function useTriggerAction({ trigger, dataEntity, dataEntityType }) {
    const [submitUpdate, { isLoading }] = useDataEntityUpdateMutation(dataEntityType)();

    const handleUpdate = (event) => {
        event.preventDefault();

        // NOTE: currently we are ignoring the trigger type & action keys - assume update action
        const updateData = {};
        _.forEach(trigger.payload, (updateValue, updatePath) => {
            _.set(updateData, updatePath, updateValue);
        });

        submitUpdate(dataEntity, updateData);
    };

    return [handleUpdate, { isLoading }];
}

export const TriggerIconButton = ({ dataEntity, dataEntityType, trigger, icon = null, buttonProps = {} }) => {
    const [handleUpdate, { isLoading }] = useTriggerAction({ trigger, dataEntity, dataEntityType });

    const buttonIcon = icon || TRIGGER_ICON_MAP[_.get(trigger, 'button.icon', 'default')];
    const buttonVariant = buttonProps.variant || _.get(trigger, 'button.variant', 'contained');
    const buttonColor = buttonProps.color || _.get(trigger, 'button.color', 'default');

    return (
        <LoadingButton
            color={buttonColor}
            variant={buttonVariant}
            onClick={handleUpdate}
            loading={isLoading}
            disableElevation
            {...buttonProps}
            sx={{
                padding: (theme) => theme.spacing(1),
                minWidth: (theme) => theme.spacing(3),
                ..._.get(buttonProps, 'sx', {}),
            }}
        >
            {buttonIcon}
        </LoadingButton>
    );
};

TriggerIconButton.propTypes = {
    trigger: PropTypes.object.isRequired,
    dataEntity: PropTypes.object.isRequired,
    dataEntityType: PropTypes.string.isRequired,
    icon: PropTypes.node,
    buttonProps: PropTypes.object,
};

// TODO move to common area
function TriggerListItem({ dataEntity, dataEntityType, trigger }) {
    const showTrigger = useEvalTriggerConditions({ dataEntity, trigger });

    if (!showTrigger) {
        return null;
    }

    return (
        <ListItem
            disableGutters
            secondaryAction={
                <TriggerIconButton
                    dataEntity={dataEntity}
                    dataEntityType={dataEntityType}
                    trigger={trigger}
                    buttonProps={{ color: 'default', variant: 'outlined' }}
                />
            }
        >
            <ListItemText primary={trigger.text} secondary={trigger.description} />
        </ListItem>
    );
}

// TODO move to common area
const TriggerList = ({ dataEntity, dataEntityType }) => {
    const { triggers, activeTriggers } = useTriggers(dataEntity);

    if (!triggers || triggers.length === 0 || activeTriggers.length === 0) {
        return null;
    }

    return (
        <List disablePadding spacing={2}>
            {_.map(triggers, (trigger, triggerKey) => {
                return (
                    <TriggerListItem
                        key={triggerKey}
                        dataEntity={dataEntity}
                        dataEntityType={dataEntityType}
                        trigger={trigger}
                    />
                );
            })}
        </List>
    );
};

const LienTriggerList = ({ order, lien }) => {
    const payoff = _.find(order.payoffs, { lien_id: lien.id });

    const lienActiveTriggers = useMemo(() => {
        // Check the main lien and associated payoff for active triggers

        const { activeTriggers } = getTriggers(lien);

        return activeTriggers;
    }, [lien]);

    const payoffActiveTriggers = useMemo(() => {
        // Check the payoff for active triggers
        const { activeTriggers } = getTriggers(payoff);

        return activeTriggers;
    }, [payoff]);

    const hasActiveTriggers = lienActiveTriggers.length > 0 || payoffActiveTriggers.length > 0;

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Triggers</Typography>
            </Stack>

            {!hasActiveTriggers && (
                <Typography variant="body1" color="text.secondary" fontStyle="italic">
                    No active triggers
                </Typography>
            )}

            <TriggerList dataEntity={lien} dataEntityType={ENTITY_TYPE.lien} />

            {payoff && <TriggerList dataEntity={payoff} dataEntityType={ENTITY_TYPE.payoff} />}
        </Paper>
    );
};

LienTriggerList.propTypes = {
    order: PropTypes.object.isRequired,
    lien: PropTypes.object.isRequired,
};

export default LienTriggerList;
