import { Box, List, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import SearchResultListItem from './SearchResultListItem';

const SEARCH_RESULT_ITEM_HEIGHT = 67;
const SEARCH_RESULT_LIST_MAX_HEIGHT = SEARCH_RESULT_ITEM_HEIGHT * 8;

const SearchResultList = ({ orders, handleSelect }) => {
    if (!orders || orders.length === 0) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    No search results
                </Typography>
            </Box>
        );
    }

    return (
        <List
            disablePadding
            sx={{
                maxHeight: `${SEARCH_RESULT_LIST_MAX_HEIGHT}px`,
                overflowY: 'auto',
            }}
        >
            {orders.map((order) => (
                <SearchResultListItem key={order.id} order={order} handleSelect={handleSelect} />
            ))}
        </List>
    );
};

SearchResultList.propTypes = {
    orders: PropTypes.array.isRequired,
    handleSelect: PropTypes.func.isRequired,
};

export default SearchResultList;
