import { List, ListItem, ListItemText, Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// import Form from '@rjsf/mui';

import ActionButton from 'components/Action/ActionButton';
import { PARCEL_TYPE } from 'helpers/constants';

function ActionListItem({ action }) {
    const actionData = action.action;
    return (
        <ListItem disableGutters secondaryAction={<ActionButton action={actionData} orderId={action.orderId} />}>
            <ListItemText primary={actionData.friendly_name} secondary={actionData.description} />
        </ListItem>
    );
}

function ActionList({ actions }) {
    if (!actions || actions.length === 0) {
        return (
            <Typography variant="body1" color="text.secondary" fontStyle="italic">
                No active actions
            </Typography>
        );
    }

    return (
        <List disablePadding spacing={2}>
            {_.map(actions, (action) => {
                return <ActionListItem key={action.id} action={action} />;
            })}
        </List>
    );
}

function LienActionList({ order, lien }) {
    // Find title parcel
    // NOTE: all lien & payoff actions exist together on the title parcel
    const titleParcel = _.find(order.parcels, { type: PARCEL_TYPE.title_clearing });

    // Find payoff (if exists)
    const payoff = _.find(order.payoffs, { lien_id: lien.id });

    const actionList = useMemo(() => {
        const combinedActionList = [];

        // Check all actions in lien
        _.forEach(lien.actions, (lienAction) => {
            const lienActionId = lienAction.action_id;
            if (_.includes(titleParcel.active_actions, lienActionId)) {
                combinedActionList.push({
                    id: lienActionId,
                    orderId: titleParcel.order_id,
                    parcelId: titleParcel.id,
                    parcelName: titleParcel.name,
                    action: titleParcel.action_map[lienActionId],
                });
            }
        });

        if (payoff) {
            _.forEach(payoff.actions, (payoffAction) => {
                const payoffActionId = payoffAction.action_id;
                if (_.includes(titleParcel.active_actions, payoffActionId)) {
                    combinedActionList.push({
                        id: payoffActionId,
                        orderId: titleParcel.order_id,
                        parcelId: titleParcel.id,
                        parcelName: titleParcel.name,
                        action: titleParcel.action_map[payoffActionId],
                    });
                }
            });
        }

        return combinedActionList;
    }, [titleParcel, lien, payoff]);

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Actions</Typography>
            </Stack>

            <ActionList actions={actionList} />
        </Paper>
    );
}

LienActionList.propTypes = {
    order: PropTypes.object.isRequired,
    lien: PropTypes.object.isRequired,
};

export default LienActionList;
