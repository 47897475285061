import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import CreateAssignmentMemberForm from './CreateAssignmentMemberForm';

const CreateAssignmentMember = ({ assignment, assignmentMembers, orderMembers, handleSubmit }) => {
    return (
        <Stack spacing={4} sx={{ width: '480px' }}>
            <Stack direction="column" spacing={2}>
                <Typography variant="h5">Add Assignment Member</Typography>
            </Stack>

            <CreateAssignmentMemberForm
                assignment={assignment}
                assignmentMembers={assignmentMembers}
                orderMembers={orderMembers}
                handleSubmit={handleSubmit}
            />
        </Stack>
    );
};

CreateAssignmentMember.propTypes = {
    assignment: PropTypes.object.isRequired,
    assignmentMembers: PropTypes.array.isRequired,
    orderMembers: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default CreateAssignmentMember;
