import { Link as MUILink } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import { useGetOrderQuery } from '../../../../api/order';
import { formatISODate } from '../../../../helpers/utils';
import { DataGrid } from '../../../common/styled';

export const EMAIL_LOG_LIST_GRID_COLUMN_TYPE = {
    messageId: 'message_id',
    user: 'user',
    recipient: 'recipient',
    orderId: 'order_id',
    emailType: 'email_type',
    eventType: 'event_type',
    eventDatetime: 'event_datetime',
};

const OrderColumn = ({ orderId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/order/${orderId}`}
        >
            {_.get(order, 'qualia_order_number', '')}
        </MUILink>
    );
};

const UserColumn = ({ user }) => {
    return (
        <MUILink
            component={Link}
            underline="hover"
            sx={{ color: (theme) => theme.palette.text.link }}
            to={`/contact/user/${user.id}`}
        >
            {user.name}
        </MUILink>
    );
};

const generateEmailLogListColumns = (hideColumns) => {
    return [
        {
            field: EMAIL_LOG_LIST_GRID_COLUMN_TYPE.messageId,
            headerName: _.startCase(EMAIL_LOG_LIST_GRID_COLUMN_TYPE.messageId),
            minWidth: 160,
            hide: true || _.includes(hideColumns, EMAIL_LOG_LIST_GRID_COLUMN_TYPE.messageId),
            // valueFormatter: ({ value }) => _.startCase(value),
        },
        {
            field: EMAIL_LOG_LIST_GRID_COLUMN_TYPE.eventType,
            headerName: _.startCase(EMAIL_LOG_LIST_GRID_COLUMN_TYPE.eventType),
            minWidth: 160,
            hide: _.includes(hideColumns, EMAIL_LOG_LIST_GRID_COLUMN_TYPE.eventType),
            valueFormatter: ({ value }) => _.startCase(value),
        },
        {
            field: EMAIL_LOG_LIST_GRID_COLUMN_TYPE.emailType,
            headerName: _.startCase(EMAIL_LOG_LIST_GRID_COLUMN_TYPE.emailType),
            minWidth: 160,
            hide: _.includes(hideColumns, EMAIL_LOG_LIST_GRID_COLUMN_TYPE.emailType),
            valueFormatter: ({ value }) => _.startCase(value),
        },
        {
            field: EMAIL_LOG_LIST_GRID_COLUMN_TYPE.user,
            headerName: 'Contact',
            minWidth: 160,
            hide: _.includes(hideColumns, EMAIL_LOG_LIST_GRID_COLUMN_TYPE.user),
            // TODO sort?
            renderCell: (params) => <UserColumn user={params.value} />,
        },
        {
            field: EMAIL_LOG_LIST_GRID_COLUMN_TYPE.recipient,
            headerName: _.startCase(EMAIL_LOG_LIST_GRID_COLUMN_TYPE.recipient),
            minWidth: 240,
            hide: _.includes(hideColumns, EMAIL_LOG_LIST_GRID_COLUMN_TYPE.recipient),
            // valueFormatter: ({ value }) => _.startCase(value),
            //  TODO select to auto-filter?
        },
        {
            field: EMAIL_LOG_LIST_GRID_COLUMN_TYPE.orderId,
            headerName: 'Order',
            minWidth: 160,
            hide: _.includes(hideColumns, EMAIL_LOG_LIST_GRID_COLUMN_TYPE.orderId),
            renderCell: (params) => <OrderColumn orderId={params.value} />,
        },
        {
            field: EMAIL_LOG_LIST_GRID_COLUMN_TYPE.eventDatetime,
            headerName: 'Date/Time',
            type: 'date',
            minWidth: 150,
            hide: _.includes(hideColumns, EMAIL_LOG_LIST_GRID_COLUMN_TYPE.eventDatetime),
            valueFormatter: ({ value }) => formatISODate(value, 'P p'),
        },
    ];
};

function EmailLogListDataGrid({ emailLogs, loading, hideColumns = [], refetch }) {
    const [pageSize, setPageSize] = useState(10);

    const emailLogListColumns = useMemo(() => generateEmailLogListColumns(hideColumns), [hideColumns]);

    // TODO look into grouping rows around message_id - requires premium mui license for grouping

    return (
        <DataGrid
            columns={emailLogListColumns}
            rows={emailLogs}
            loading={loading}
            refetch={refetch}
            getRowId={(row) => row.id}
            autoHeight
            disableColumnMenu
            disableColumnResize
            disableSelectionOnClick
            density="comfortable"
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50, 100]}
            pagination
        />
    );
}

EmailLogListDataGrid.propTypes = {
    emailLogs: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    hideColumns: PropTypes.arrayOf(PropTypes.string),
    refetch: PropTypes.func,
};

export default EmailLogListDataGrid;
