import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import OrderEmailLogList from './OrderEmailLogList';
import OrderEventLogList from './OrderEventLogList';

import { Tab, Tabs } from 'components/common/styled';
import OrderBreadcrumbs from 'components/Order/OrderDetails/components/OrderBreadcrumbs';

const ORDER_LOG_TAB_TYPE = {
    event: 'event',
    email: 'email',
};

const ORDER_LOG_TAB_COMPONENT_MAP = {
    [ORDER_LOG_TAB_TYPE.event]: OrderEventLogList,
    [ORDER_LOG_TAB_TYPE.email]: OrderEmailLogList,
};

function OrderLogSectionTabContent({ orderId, activeTab }) {
    const TabContentComp = ORDER_LOG_TAB_COMPONENT_MAP[activeTab];
    return <TabContentComp orderId={orderId} />;
}

function OrderLogs({ orderId }) {
    const [activeTab, setActiveTab] = useState(ORDER_LOG_TAB_TYPE.event);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Stack spacing={3}>
            <OrderBreadcrumbs orderId={orderId} />

            <Typography variant="h1">Logs</Typography>
            <Box sx={{ width: '100%' }}>
                <Tabs value={activeTab} onChange={handleChange} textColor="primary" indicatorColor="primary">
                    <Tab value={ORDER_LOG_TAB_TYPE.event} label="Event Logs" />

                    <Tab value={ORDER_LOG_TAB_TYPE.email} label="Email Logs" />
                </Tabs>
            </Box>

            <OrderLogSectionTabContent orderId={orderId} activeTab={activeTab} />
        </Stack>
    );
}

OrderLogs.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderLogs;
