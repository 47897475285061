import { Box, Grid } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useGetOrderQuery } from '../../../api/order';
import { PARCEL_TYPE } from '../../../helpers/constants';
import ParcelCard from './components/ParcelCard';

// Array determines both which parcel types are allowed and the order in which they are displayed
const allowedParcelTypes = [
    PARCEL_TYPE.earnest_money,
    PARCEL_TYPE.tax_cert,
    PARCEL_TYPE.hoa,
    PARCEL_TYPE.assignment,
    PARCEL_TYPE.title_exam,
    PARCEL_TYPE.title_clearing,
    PARCEL_TYPE.home_warranty,
    PARCEL_TYPE.deed,
    PARCEL_TYPE.cda,
    PARCEL_TYPE.survey,
    PARCEL_TYPE.balancing,
    PARCEL_TYPE.closing_buyer,
    PARCEL_TYPE.closing_seller,
    PARCEL_TYPE.closing_borrower,
    PARCEL_TYPE.funding,
    PARCEL_TYPE.keys,
    PARCEL_TYPE.post_closing,
];

function ParcelList({ orderId }) {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const [parcelMap, setParcelMap] = useState({});

    useEffect(() => {
        const newParcelMap = {};
        if (order?.parcels) {
            _.forEach(order.parcels, (parcel) => {
                if (_.includes(allowedParcelTypes, parcel.type)) {
                    newParcelMap[parcel.type] = parcel;
                }
            });
        }

        setParcelMap(newParcelMap);
    }, [order]);

    if (orderLoading) {
        return <div>Loading...</div>;
    }

    if (orderError) {
        return <div>Error loading order</div>;
    }

    return (
        <Box>
            <Grid container spacing={3}>
                {_.map(allowedParcelTypes, (parcelType) => {
                    const parcel = parcelMap[parcelType];
                    if (!parcel) {
                        return null;
                    }

                    return (
                        <Grid item xs={12} md={6} lg={4} xl={3} key={parcel.id}>
                            <ParcelCard order={order} parcel={parcel} />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}

ParcelList.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default ParcelList;
