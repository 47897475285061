import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { useCreateAssignmentMutation } from 'api/assignment';
import AssignmentMemberSelectField from 'components/Assignment/components/AssignmentMemberSelectField';

import { LoadingButton } from 'components/common/styled';
import { ASSIGNMENT_MEMBER_TYPE, PARCEL_TYPE } from 'helpers/constants';

const initialAssignmentState = {
    members: [],
};

const NewAssignmentForm = ({ order, orderMembers, handleSubmit }) => {
    const [createAssignment, { isLoading }] = useCreateAssignmentMutation();

    const [assignmentData, setAssignmentData] = useState({ ...initialAssignmentState });

    const isValid = useMemo(() => {
        // Need at least 2 members to create an assignment
        if (assignmentData.members.length < 2) {
            return false;
        }

        let hasAssignor = false;
        let hasAssignee = false;
        for (const member of assignmentData.members) {
            if (member.type === ASSIGNMENT_MEMBER_TYPE.assignor) {
                hasAssignor = true;
            } else if (member.type === ASSIGNMENT_MEMBER_TYPE.assignee) {
                hasAssignee = true;
            }
        }

        // Needs at least one assignor and one assignee
        return hasAssignor && hasAssignee;
    }, [assignmentData]);

    const handleAssignmentMembersChange = (members) => {
        setAssignmentData((prevData) => {
            return {
                ...prevData,
                members: [...members],
            };
        });
    };

    const handleCreateAssignment = (event) => {
        event.preventDefault();

        if (!isValid) {
            console.warn('Cannot create assignment with invalid data');
            return;
        }

        return submitCreateAssignment(assignmentData).then((response) => {
            if (response) {
                handleSubmit();
            } else {
                // TODO handle error state
            }
        });
    };

    const submitCreateAssignment = async (createAssignmentData) => {
        // Reformat and add any additional data needed for the assignment
        const formattedAssignmentData = {
            parcel_seed_map: {
                [PARCEL_TYPE.assignment_contract]: {
                    members: createAssignmentData.members,
                },
            },
        };

        const { data } = await createAssignment({
            orderId: order.id,
            assignmentData: formattedAssignmentData,
        });

        if (data) {
            return true;
        } else {
            console.warn(`Failed to create assignment for order ${order.id}`);
            return false;
        }
    };

    return (
        <form onSubmit={handleCreateAssignment}>
            <Stack spacing={4}>
                <Stack spacing={1}>
                    <Typography variant="body1" color="text.secondary">
                        Select Contract Members
                    </Typography>

                    <AssignmentMemberSelectField
                        assignmentMembers={assignmentData.members}
                        setAssignmentMembers={handleAssignmentMembersChange}
                        orderMembers={orderMembers}
                    />
                </Stack>

                <Box>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%' }}
                    >
                        <Box />
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={isLoading}
                            disabled={!isValid}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                        >
                            Create
                        </LoadingButton>
                    </Stack>
                </Box>
            </Stack>
        </form>
    );
};

NewAssignmentForm.propTypes = {
    order: PropTypes.object.isRequired,
    orderMembers: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default NewAssignmentForm;
