import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetUserAccessLogsQuery } from '../../api/accessLog';
import { ACCESS_LOG_FILTER_TYPE } from '../../helpers/constants';
import AccessLogList from '../Log/AccessLogList';
import { ACCESS_LOG_LIST_GRID_COLUMN_TYPE } from '../Log/AccessLogList/components/AccessLogListGrid';

const USER_HIDE_FILTER_TYPE_LIST = [ACCESS_LOG_FILTER_TYPE.userId];
const USER_HIDE_COLUMN_TYPE_LIST = [ACCESS_LOG_LIST_GRID_COLUMN_TYPE.user];

function UserAccessLogList({ userId }) {
    const {
        data: userAccessLogs,
        isError: userAccessLogsError,
        isLoading: userAccessLogsLoading,
    } = useGetUserAccessLogsQuery(userId);

    return (
        <Box>
            <AccessLogList
                accessLogs={userAccessLogs || []}
                hideFilterTypeList={USER_HIDE_FILTER_TYPE_LIST}
                hideColumnTypeList={USER_HIDE_COLUMN_TYPE_LIST}
                loading={userAccessLogsLoading}
                pageTitle={null}
            />
        </Box>
    );
}

UserAccessLogList.propTypes = {
    userId: PropTypes.string.isRequired,
};

export default UserAccessLogList;
