import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import CreateContactModal from 'components/Contact/components/CreateContactModal';
import CreateOrderDialog from 'components/CreateOrder/CreateOrderDialog';
import { Button } from 'components/common/styled';
import CreateEntityPopover from './CreateEntityPopover';

const MODAL_TYPE = {
    order: 'order',
    contact: 'contact',
};

const CreateEntityIconButton = ({ open, setOpen }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(null);

    const handleOpenOrderModal = (isOpen) => {
        if (isOpen) {
            handleClose(false); // Close the popover
            setOpenModal(MODAL_TYPE.order); // Open the modal
        } else {
            setOpenModal(null);
        }
    };

    const handleOpenContactModal = (isOpen) => {
        if (isOpen) {
            handleClose(false); // Close the popover
            setOpenModal(MODAL_TYPE.contact); // Open the modal
        } else {
            setOpenModal(null);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <Box>
            <Button
                variant="contained"
                sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    minWidth: '36px',
                }}
                color="primary"
                onClick={handleClick}
            >
                <AddIcon />
            </Button>

            <CreateEntityPopover
                open={open}
                handleClose={handleClose}
                anchorEl={anchorEl}
                handleOpenOrderModal={handleOpenOrderModal}
                handleOpenContactModal={handleOpenContactModal}
            />

            <CreateOrderDialog open={openModal === MODAL_TYPE.order} handleClose={handleOpenOrderModal} />

            <CreateContactModal open={openModal === MODAL_TYPE.contact} handleClose={handleOpenContactModal} />
        </Box>
    );
};

CreateEntityIconButton.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default CreateEntityIconButton;
