import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { filterEmailLogs } from '../../../helpers/utils';
import EmailLogListFilters, { INITIAL_EMAIL_LOG_FILTERS } from './components/EmailLogListFilters';
import EmailLogListDataGrid from './components/EmailLogListGrid';

// TODO default filterTypeList and columnTypeList

const DEFAULT_HIDE_FILTER_TYPE_LIST = [];
const DEFAULT_HIDE_COLUMN_TYPE_LIST = [];
const DEFAULT_INITIAL_EMAIL_LOG_FILTERS = {
    ...INITIAL_EMAIL_LOG_FILTERS,
};

const EmailLogList = ({
    emailLogs,
    hideFilterTypeList = DEFAULT_HIDE_FILTER_TYPE_LIST,
    hideColumnTypeList = DEFAULT_HIDE_COLUMN_TYPE_LIST,
    defaultFilters = DEFAULT_INITIAL_EMAIL_LOG_FILTERS,
    pageTitle = 'Email Logs',
    loading = false,
    refetch,
}) => {
    const [filters, setFilters] = useState({
        ...DEFAULT_INITIAL_EMAIL_LOG_FILTERS,
    });

    const filteredEmailLogs = useMemo(() => filterEmailLogs(emailLogs, filters), [emailLogs, filters]);

    const resetFilters = () => {
        setFilters({
            ...DEFAULT_INITIAL_EMAIL_LOG_FILTERS, // TODO
        });
    };

    return (
        <Stack spacing={3}>
            {pageTitle && <Typography variant="h1">{pageTitle}</Typography>}

            <Box>
                <EmailLogListFilters
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                    defaultFilters={defaultFilters}
                    hideFilters={hideFilterTypeList}
                />
            </Box>

            <Box>
                <EmailLogListDataGrid
                    emailLogs={filteredEmailLogs}
                    hideColumns={hideColumnTypeList}
                    loading={loading}
                    refetch={refetch}
                />
            </Box>
        </Stack>
    );
};

EmailLogList.propTypes = {
    emailLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
    hideFilterTypeList: PropTypes.arrayOf(PropTypes.string),
    hideColumnTypeList: PropTypes.arrayOf(PropTypes.string),
    pageTitle: PropTypes.string,
    loading: PropTypes.bool,
    refetch: PropTypes.func,
};

export default EmailLogList;
