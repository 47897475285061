import PropTypes from 'prop-types';

import BaseDialog from 'components/common/BaseDialog';
import ManageDocumentAccess from './ManageDocumentAccess';

function DocumentAccessDialog({ open, handleClose, orderId, document }) {
    return (
        <BaseDialog
            open={open}
            setOpen={() => handleClose(false)}
            DialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
            }}
        >
            <ManageDocumentAccess orderId={orderId} document={document} handleClose={handleClose} />
        </BaseDialog>
    );
}

DocumentAccessDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    orderId: PropTypes.string.isRequired,
    document: PropTypes.object,
};

export default DocumentAccessDialog;
