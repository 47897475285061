import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Button, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { DOCUMENT_TYPE } from '../../helpers/constants';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2)
        }
    },
    dropzone: {
        border: '1px dashed #ccc',
    },
    dropzoneGutter: {
        padding: theme.spacing(4)
    },
    flexCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    documentTypeSelect: {
        width: '100%',
        maxWidth: '200px'
    },
    spacer: {
        marginBottom: theme.spacing(2)
    }
}));


function DocumentUploadArea(props) {
    const classes = useStyles();
    const { documentData, setDocumentData, documentType, setDocumentType, handleClear, handleSubmit, isSubmitting } = props;

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setDocumentData(file);
        }
    }, [])
    
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: ".xls, .xlsx, .pdf, .png, .jpg, .jpeg, .doc, .docx"
    })

    const handleDocumentType = ({ value }) => {
        setDocumentType(value);
    };

    return (
        <div className={classes.root}>
            <div className={classes.dropzone}>
                { documentData ? (
                    <div className={classes.dropzoneGutter}>
                        <div className={classes.flexCenter}>
                            <span>{documentData.name}</span>

                            <div className={classes.spacer} />

                            <TextField
                                name="documentType"
                                label="Document Type"
                                onChange={(event) => handleDocumentType(event.target)}
                                value={documentType}
                                variant="outlined"
                                className={classes.documentTypeSelect}
                                select
                            >
                                {_.map(DOCUMENT_TYPE, (optionsValue) => {
                                    return (
                                        <MenuItem key={optionsValue} value={optionsValue}>
                                            {_.startCase(optionsValue)}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>

                            <div className={classes.spacer} />
                            
                            <div>
                                <Button onClick={handleClear} disabled={isSubmitting}>Clear</Button>
                                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={isSubmitting} disableElevation>Upload</Button>
                            </div>
                            
                        </div>
                    </div>
                ) : (
                    <div {...getRootProps()} className={classes.dropzoneGutter}>
                        <input {...getInputProps()} />
                        <div className={classes.flexCenter}>
                            <NoteAddIcon />
                            <p>Drag and drop or click to browse your files</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

DocumentUploadArea.propTypes = {
    documentData: PropTypes.object.isRequired, // TODO describe shape of object
    setDocumentData: PropTypes.func.isRequired,
    documentType: PropTypes.string.isRequired, 
    setDocumentType: PropTypes.func.isRequired, 
    handleClear: PropTypes.func.isRequired, 
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired
};

export default DocumentUploadArea;