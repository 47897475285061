import { Stack, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import DollarFieldMask from '../common/mask/DollarFieldMask';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    spacer: {
        marginBottom: theme.spacing(4),
    },
}));

function PurchaseForm(props) {
    const classes = useStyles();

    const { fields, error, errorMessage } = props;
    const {
        salesPrice,
        setSalesPrice,
        loanAmount,
        setLoanAmount,
        earnestMoney,
        setEarnestMoney,
        optionFee,
        setOptionFee,
        optionPeriod,
        setOptionPeriod,
    } = fields;

    return (
        <Stack spacing={3}>
            <TextField
                label="Sales Price"
                value={salesPrice}
                onChange={({ target }) => setSalesPrice(target.value)}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                variant="outlined"
                size="small"
                InputProps={{
                    inputComponent: DollarFieldMask,
                }}
                autoFocus
                required
            />

            <TextField
                label="Loan Amount"
                value={loanAmount}
                onChange={({ target }) => setLoanAmount(target.value)}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                variant="outlined"
                size="small"
                InputProps={{
                    inputComponent: DollarFieldMask,
                }}
                required
            />

            <div className={classes.spacer} />

            <TextField
                label="Earnest Money"
                value={earnestMoney}
                onChange={({ target }) => setEarnestMoney(target.value)}
                onFocus={(event) => {
                    // NOTE: timeout to get around various browser event issues
                    const target = event.target;
                    setTimeout(() => target.select(), 0);
                }}
                variant="outlined"
                size="small"
                InputProps={{
                    inputComponent: DollarFieldMask,
                }}
                required
            />

            <Stack direction="row" spacing={2}>
                <TextField
                    label="Option Fee"
                    value={optionFee}
                    onChange={({ target }) => setOptionFee(target.value)}
                    onFocus={(event) => {
                        // NOTE: timeout to get around various browser event issues
                        const target = event.target;
                        setTimeout(() => target.select(), 0);
                    }}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        inputComponent: DollarFieldMask,
                    }}
                    sx={{ flexGrow: 1 }}
                />

                <TextField
                    label="Option Period"
                    value={optionPeriod}
                    onChange={({ target }) => setOptionPeriod(target.value)}
                    onFocus={(event) => {
                        // NOTE: timeout to get around various browser event issues
                        const target = event.target;
                        setTimeout(() => target.select(), 0);
                    }}
                    variant="outlined"
                    type="number"
                    size="small"
                    InputProps={{
                        endAdornment: <span>days</span>,
                        inputProps: { min: 0 },
                    }}
                    sx={{ flexGrow: 1 }}
                    required
                />
            </Stack>
        </Stack>
    );
}

PurchaseForm.propTypes = {
    fields: PropTypes.shape({
        salesPrice: PropTypes.string.isRequired,
        setSalesPrice: PropTypes.func.isRequired,
        loanAmount: PropTypes.string.isRequired,
        setLoanAmount: PropTypes.func.isRequired,
        earnestMoney: PropTypes.string.isRequired,
        setEarnestMoney: PropTypes.func.isRequired,
        optionFee: PropTypes.string.isRequired,
        setOptionFee: PropTypes.func.isRequired,
        optionPeriod: PropTypes.string.isRequired,
        setOptionPeriod: PropTypes.func.isRequired,
    }).isRequired,
    disableFields: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.object.isRequired,
};

export default PurchaseForm;
