import BlockIcon from '@mui/icons-material/Block';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Avatar, Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useCreateUserPortalAccessMutation, useUpdateUserPortalAccessMutation } from 'api/user';
import LoadingButton from 'components/common/styled/LoadingButton';

function UserHeaderActionTogglePortalAccess({ user }) {
    const [
        updateUserPortalAccess,
        { isLoading: isUpdateUserPortalAccessLoading },
    ] = useUpdateUserPortalAccessMutation();

    const handleUpdatePortalAccess = async () => {
        const { data } = await updateUserPortalAccess({
            userId: user.id,
            accessData: {
                blocked: !user.blocked,
            },
        });
        if (!data) {
            console.error('Error updating user portal access');
        }
    };

    return (
        <Tooltip
            title={user.blocked ? 'Allow Portal Access' : 'Prevent Portal Access'}
            placement="bottom"
            enterDelay={300}
        >
            <span style={{ display: 'inline-block' }}>
                <LoadingButton
                    variant="contained"
                    size="medium"
                    color="primary"
                    disableElevation
                    onClick={() => handleUpdatePortalAccess()}
                    loading={isUpdateUserPortalAccessLoading}
                    sx={{ minWidth: '160px' }}
                >
                    {user.blocked ? 'Unblock' : 'Block'} Account
                </LoadingButton>
            </span>
        </Tooltip>
    );
}

function UserHeaderActionCreatePortalAccess({ user }) {
    const [
        createUserPortalAccess,
        { isLoading: isCreateUserPortalAccessLoading },
    ] = useCreateUserPortalAccessMutation();

    const handleCreatePortalAccess = async () => {
        const { data } = await createUserPortalAccess({
            userId: user.id,
        });
        if (!data) {
            console.error('Error granting user portal access');
        }
    };

    const disabled = !_.get(user, 'contact.email');

    return (
        <Tooltip title={disabled ? 'Requires Email' : ''} placement="bottom" enterDelay={300}>
            <span style={{ display: 'inline-block' }}>
                <LoadingButton
                    variant="contained"
                    size="medium"
                    color="primary"
                    disableElevation
                    onClick={() => handleCreatePortalAccess()}
                    loading={isCreateUserPortalAccessLoading}
                    disabled={disabled}
                    sx={{ minWidth: '160px' }}
                >
                    Grant Portal Access
                </LoadingButton>
            </span>
        </Tooltip>
    );
}

function UserHeaderAction({ user }) {
    if (user.external_user_id) {
        return <UserHeaderActionTogglePortalAccess user={user} />;
    }

    return <UserHeaderActionCreatePortalAccess user={user} />;
}

function UserHeaderChipList({ user }) {
    const sharedProps = {
        size: 'small',
        variant: 'contained',
        sx: {
            borderRadius: (theme) => theme.spacing(0.5),
        },
    };

    const accountChip = user.external_user_id ? (
        <Tooltip title="Has Portal Account" placement="bottom" enterDelay={300}>
            <span style={{ display: 'inline-block' }}>
                <Chip
                    label="Account"
                    color="primary"
                    icon={<VerifiedUserIcon />}
                    {...sharedProps}
                    sx={{
                        ...sharedProps.sx,
                        bgcolor: 'primary.light',
                        color: 'primary.dark',
                    }}
                />
            </span>
        </Tooltip>
    ) : null;

    const blockedChip = user.blocked ? (
        <Tooltip title="Portal Access Blocked" placement="bottom" enterDelay={300}>
            <span style={{ display: 'inline-block' }}>
                <Chip
                    label="Blocked"
                    color="error"
                    icon={<BlockIcon />}
                    {...sharedProps}
                    sx={{
                        ...sharedProps.sx,
                        bgcolor: 'error.light',
                        color: 'error.dark',
                    }}
                />
            </span>
        </Tooltip>
    ) : null;

    if (!accountChip && !blockedChip) {
        return null;
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {accountChip}
            {blockedChip}
        </Stack>
    );
}

function UserHeader({ user }) {
    return (
        <Stack direction="column" spacing={0}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar
                        sx={{
                            width: 64,
                            height: 64,
                            fontSize: 28,
                            bgcolor: 'contact.main',
                            color: 'contact.light',
                        }}
                    >
                        <PersonIcon fontSize="large" />
                    </Avatar>

                    <Typography variant="h1">{user.name}</Typography>
                </Stack>

                <Box sx={{ flexGrow: 1 }} />

                <UserHeaderAction user={user} />
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2}>
                <Box sx={{ width: 64 }} />
                <UserHeaderChipList user={user} />
            </Stack>
        </Stack>
    );
}

UserHeader.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserHeader;
