import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, Stack, Tooltip } from '@mui/material';
import FilterTextField from 'components/common/styled/FilterTextField';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';

import { NOTE_FILTER_TYPE } from 'helpers/constants';

function NoteListFilters({ filters, defaultFilters, setFilters, resetFilters }) {
    const searchInputRef = useRef();

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string isn't valid for some filters
        };

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const changeHandler = (event) => {
        handleFilter(event);
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 300), []);

    const setSearchInput = (value) => {
        searchInputRef.current.value = value;
    };

    const resetAllFilters = () => {
        // Reset local uncontrolled search input
        setSearchInput('');

        // Reset parent filters
        resetFilters();
    };

    useEffect(() => {
        // Stop the invocation of the debounced function after unmounting
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <FilterTextField
                name={NOTE_FILTER_TYPE.search}
                label=""
                type="search"
                variant="outlined"
                placeholder="Search notes ..."
                onChange={debouncedChangeHandler}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                inputRef={searchInputRef}
                size="small"
                sx={{
                    flexGrow: 1,
                }}
            />

            <Tooltip title="Reset Filters" placement="bottom" enterDelay={300}>
                <IconButton
                    onClick={() => {
                        resetAllFilters();
                    }}
                >
                    <RestartAltIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

NoteListFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    defaultFilters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
};

export default NoteListFilters;
