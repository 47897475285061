import BaseDialog from 'components/common/BaseDialog';
import PropTypes from 'prop-types';

import AddAssignedAction from './AddAssignedAction';

const AddAssignedActionModalDialog = ({ open, handleClose, order, orderMembers }) => {
    const handleCloseLocal = (open) => {
        handleClose(open);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleCloseLocal(false)}
            DialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
            }}
        >
            <AddAssignedAction order={order} orderMembers={orderMembers} handleClose={() => handleCloseLocal(false)} />
        </BaseDialog>
    );
};

AddAssignedActionModalDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    order: PropTypes.object,
    orderMembers: PropTypes.array,
};

export default AddAssignedActionModalDialog;
