import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

function SummaryBox({
    mainCount,
    mainText,
    secondaryCount,
    secondaryText,
    navLink,
    activeColor,
    activeSecondaryColor,
    activeBackground,
    inactiveColor = 'text.primary',
    inactiveSecondaryColor = 'text.secondary',
    inactiveBackground = 'background.default',
    CardProps = {},
}) {
    const navigate = useNavigate();

    const handleNavigation = (event) => {
        if (navLink) {
            navigate(navLink);
        }
    };

    const active = mainCount && mainCount > 0;
    const color = active && activeColor ? activeColor : inactiveColor;
    const secondaryColor = active && activeSecondaryColor ? activeSecondaryColor : inactiveSecondaryColor;
    const background = active && activeBackground ? activeBackground : inactiveBackground;

    return (
        <Box>
            <Card
                variant="outlined"
                {...CardProps}
                sx={{
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: 0,
                    height: '180px',
                    width: '180px',
                    boxSizing: 'border-box',
                    border: 0,
                    borderRadius: '50%',
                    textAlign: 'center',
                    color: color,
                    bgcolor: background,
                    // boxShadow: (theme) => (active ? theme.shadows[2] : theme.shadows[0]),
                    ..._.get(CardProps, 'sx', {}),
                }}
            >
                <CardActionArea
                    sx={{
                        height: '100%',
                        padding: (theme) => theme.spacing(2),
                    }}
                    onClick={handleNavigation}
                >
                    <Stack alignItems="center" justifyContent="space-around">
                        <Stack direction="column" alignItems="center" spacing={1}>
                            <Typography variant="h1" color={color}>
                                {mainCount}
                            </Typography>
                            <Stack spacing={0.5}>
                                <Typography variant="body1" color={color} fontSize="16px">
                                    {mainText}
                                </Typography>
                                {secondaryText && (
                                    <Typography variant="body2" fontStyle="italic" color={secondaryColor}>
                                        {secondaryCount >= 0 && (
                                            <span style={{ fontWeight: 600, marginRight: '6px' }}>
                                                {secondaryCount}
                                            </span>
                                        )}
                                        {secondaryText}
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </CardActionArea>
            </Card>
        </Box>
    );
}

SummaryBox.propTypes = {
    mainCount: PropTypes.number.isRequired,
    mainText: PropTypes.string.isRequired,
    secondaryCount: PropTypes.number,
    secondaryText: PropTypes.string,
    navLink: PropTypes.oneOfType([
        PropTypes.string,
        /* ex: "/orders" */
        PropTypes.object,
        /*
         ex: 
            {
                pathname: '/posts',
                search: `?${createSearchParams(params)}`,
            }
        */
    ]),
};

export default SummaryBox;
