import AddIcon from '@mui/icons-material/Add';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import AddMemberModal from './AddMemberModal';
import EditMemberModal from './EditMemberModal';
import MembershipList from './MembershipList';

function LegalEntityDetailsMembershipList({ legalEntity }) {
    // NOTE: members included in base legalEntity query
    const [openAddMembershipModal, setOpenAddMembershipModal] = useState(false);
    const [editMembership, setEditMembership] = useState(null);

    const handleAddMembership = () => {
        setOpenAddMembershipModal(true);
    };

    const handleEditMembership = (membership) => {
        setEditMembership(membership);
    };

    // TODO handle loading and error states

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(0) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ padding: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Members</Typography>

                <IconButton size="small" onClick={() => handleAddMembership()}>
                    <AddIcon />
                </IconButton>
            </Stack>

            <MembershipList members={legalEntity.members || []} handleEditMembership={handleEditMembership} />

            <EditMemberModal
                legalEntity={legalEntity}
                member={editMembership}
                handleClose={() => handleEditMembership(null)}
            />

            <AddMemberModal
                legalEntity={legalEntity}
                open={openAddMembershipModal}
                handleClose={() => setOpenAddMembershipModal(false)}
            />
        </Paper>
    );
}

LegalEntityDetailsMembershipList.propTypes = {
    legalEntity: PropTypes.object.isRequired,
};

export default LegalEntityDetailsMembershipList;
