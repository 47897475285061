import PropTypes from 'prop-types';
import React from 'react';
import BaseDialog from '../../common/BaseDialog';

import EditRelationship from './EditRelationship';

const EditRelationshipModal = ({ user, relationship, handleClose }) => {
    return (
        <BaseDialog
            open={!!relationship}
            setOpen={() => handleClose()}
            DialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
            }}
        >
            {relationship && (
                <EditRelationship
                    user={user}
                    relationship={relationship}
                    handleCancel={() => handleClose()}
                    handleComplete={() => handleClose()}
                />
            )}
        </BaseDialog>
    );
};

EditRelationshipModal.propTypes = {
    user: PropTypes.object.isRequired,
    relationship: PropTypes.object, // not required as null value is used to close modal
    handleClose: PropTypes.func.isRequired,
};

export default EditRelationshipModal;
