import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { rootApi } from './root';

export const EMAIL_LOG_TAG = _.toUpper(ENTITY_TYPE.email_log);
export const ORDER_EMAIL_LOG_TAG = `ORDER_${EMAIL_LOG_TAG}`;
export const USER_EMAIL_LOG_TAG = `USER_${EMAIL_LOG_TAG}`;

export const emailLogApi = rootApi.injectEndpoints({
    reducerPath: 'emailLogApi',
    endpoints: (builder) => ({
        getEmailLogs: builder.query({
            query: () => `email/logs`,
            providesTags: [EMAIL_LOG_TAG],
        }),
        getOrderEmailLogs: builder.query({
            query: (orderId) => `order/${orderId}/email/logs`,
            providesTags: [ORDER_EMAIL_LOG_TAG],
        }),
        getUserEmailLogs: builder.query({
            query: (userId) => `user/external/${userId}/email/logs`,
            providesTags: [USER_EMAIL_LOG_TAG],
        }),
    }),
    overrideExisting: false,
})


export const { useGetEmailLogsQuery, useGetOrderEmailLogsQuery, useGetUserEmailLogsQuery } = emailLogApi