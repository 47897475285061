import { Box, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { useGetUserQuery } from 'api/user';
import UserDetailsCore from './UserDetailsCore';
import UserDetailsLegalEntityMembershipList from './UserDetailsLegalEntityMembershipList';
import UserDetailsOrderMembershipList from './UserDetailsOrderMembershipList';
import UserDetailsRelationshipList from './UserDetailsRelationshipList';

function UserDetails({ userId }) {
    const { data: user, isError: userError, isLoading: userLoading } = useGetUserQuery(userId);

    return (
        <Stack spacing={3}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                    <Grid item md={12} lg={5} xl={4}>
                        <UserDetailsCore user={user} />
                    </Grid>

                    <Grid item md={12} lg={7} xl={8}>
                        <Grid container spacing={3} sx={{ flexGrow: 1 }}>
                            <Grid item xs={12} lg={12} xl={6}>
                                <Stack spacing={3}>
                                    <UserDetailsRelationshipList user={user} />

                                    <UserDetailsLegalEntityMembershipList user={user} />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} lg={12} xl={6}>
                                <UserDetailsOrderMembershipList user={user} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
}

UserDetails.propTypes = {
    userId: PropTypes.string.isRequired,
};

export default UserDetails;
