import { Popover } from '@mui/material';
import PropTypes from 'prop-types';

import Search from './Search';

const SearchPopover = ({ open, handleClose, anchorEl }) => {
    return (
        <Popover
            id="search-popover"
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: -16,
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    sx: { width: '480px', maxWidth: '480px', borderRadius: (theme) => theme.spacing(1) },
                },
            }}
        >
            <Search handleSelect={() => handleClose()} />
        </Popover>
    );
};

SearchPopover.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.object,
};

export default SearchPopover;
