import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { PARCEL_TYPE } from 'helpers/constants';
import { formatISODate } from 'helpers/utils';

import ParcelActionList from 'components/Order/Parcel/components/ParcelActionList';
import ParcelDetails from 'components/Order/Parcel/components/ParcelDetails';
import ParcelDocumentList from 'components/Order/Parcel/components/ParcelDocumentList';
import ParcelNoteList from 'components/Order/Parcel/components/ParcelNoteList';
import ParcelStatusSelect from 'components/Order/Parcel/components/ParcelStatusSelect';
import ParcelTimeline from 'components/Order/Parcel/components/ParcelTimeline';
import ParcelTriggerList from 'components/Order/Parcel/components/ParcelTriggerList';

import useAssignmentData from 'components/common/hooks/useAssignmentData';
import AssignmentBreadcrumbs from './components/AssignmentBreadcrumbs';
import AssignmentMemberList from './components/AssignmentMemberList';

function Assignment({ orderId, assignmentId }) {
    const { order, parcel, childParcels, parentParcel, assignmentMembers, isLoading, isFetching } = useAssignmentData(
        orderId,
        assignmentId
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!parcel) {
        return (
            <div>
                Assignment ({assignmentId}) not found in order ({orderId})
            </div>
        );
    }

    // Hard redirects for non-assignment parcel types
    if (parcel.type !== PARCEL_TYPE.assignment_contract) {
        return <Navigate to={`/order/${orderId}/parcel/${assignmentId}`} />;
    }

    return (
        <Stack spacing={3}>
            <AssignmentBreadcrumbs assignment={parcel} assignmentParent={parentParcel} />

            <Stack spacing={0}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h1">{parcel.name}</Typography>

                    <Stack direction="row" spacing={2} alignItems="center">
                        {parcel.required_complete_date && (
                            <Typography variant="body2" color="text.primary" fontWeight={500}>
                                Due: {formatISODate(parcel.required_complete_date)}
                            </Typography>
                        )}

                        <ParcelStatusSelect parcel={parcel} />
                    </Stack>
                </Stack>
            </Stack>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                    <Grid item md={12} lg={5} xl={4}>
                        <Stack spacing={3}>
                            <ParcelDetails order={order} parcel={parcel} />
                        </Stack>
                    </Grid>

                    <Grid item md={12} lg={7} xl={8}>
                        <Grid container spacing={3} sx={{ flexGrow: 1 }}>
                            <Grid item md={12} lg={12} xl={6}>
                                <Stack spacing={3}>
                                    <ParcelActionList order={order} parcel={parcel} />

                                    <ParcelTriggerList order={order} parcel={parcel} />

                                    <AssignmentMemberList assignment={parcel} assignmentMembers={assignmentMembers} />

                                    <ParcelDocumentList order={order} parcel={parcel} />

                                    <ParcelNoteList order={order} parcel={parcel} />
                                </Stack>
                            </Grid>

                            <Grid item md={12} lg={12} xl={6}>
                                <ParcelTimeline order={order} parcel={parcel} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
}

Assignment.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default Assignment;
