import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import * as React from 'react';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import queryResponseMiddleware from './api/queryResponseMiddleware';
import { rootApi } from './api/root';
import Snackbar from './components/common/Snackbar';
import Config from './config';
import { getCookieValue } from './helpers/cookie';
import rootReducer from './stores';
import { UserAction } from './stores/user';
import theme from './theme';


LicenseInfo.setLicenseKey(
    'b5a4009c44006d9176668720638416d3T1JERVI6NDIyMjQsRVhQSVJZPTE2ODIxMDY3NzcwMDAsS0VZVkVSU0lPTj0x',
);


// TODO localize
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;




const store = configureStore({
    reducer: {
        ...rootReducer,
        [rootApi.reducerPath]: rootApi.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({immutableCheck: false, serializableCheck: false}).concat(rootApi.middleware, queryResponseMiddleware);
    }
});

// TODO remove axios once all api calls are converted to rtk-query
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
    function (config) {
        config.headers['X-CSRFToken'] = getCookieValue('csrftoken');
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.response.status === 403) {
            console.warn('403 status received from server');
            store.dispatch(UserAction.logoutSession());
        } else {
            throw err;
        }
    }
);

function App() {
    return (
        <GoogleOAuthProvider clientId={Config.google_client_id}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Provider store={store}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <AppRouter />
                            <Snackbar />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </Provider>
            </LocalizationProvider>
        </GoogleOAuthProvider>
    );
}

export default App;
