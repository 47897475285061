import { Breadcrumbs, Link as MUILink, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

function LegalEntityBreadcrumbs({ legalEntity }) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <MUILink component={Link} underline="hover" color="inherit" to="/contacts">
                Contacts
            </MUILink>
            <MUILink component={Link} underline="hover" color="inherit" to="/contacts?contactType=legal_entity">
                Organizations
            </MUILink>
            <Typography color="text.primary">{legalEntity.name}</Typography>
        </Breadcrumbs>
    );
}

LegalEntityBreadcrumbs.propTypes = {
    legalEntity: PropTypes.object.isRequired,
};

export default LegalEntityBreadcrumbs;
