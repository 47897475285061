import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useGetOrderMembersQuery } from 'api/order';
import { ENTITY_TYPE } from 'helpers/constants';
import DocumentAccessForm from './DocumentAccessForm';

function ManageDocumentAccess({ orderId, document, handleClose }) {
    const { data: orderMembers, isLoading: membersLoading } = useGetOrderMembersQuery(orderId);

    const orderMemberOptions = useMemo(() => {
        return _.filter(orderMembers, (member) => member.member_type !== ENTITY_TYPE.legal_entity);
    }, [orderMembers]);

    if (!orderId || !document) {
        return null;
    }

    return (
        <Stack spacing={4} sx={{ width: '100%' }}>
            <Stack spacing={1}>
                <Typography variant="h5">Manage Document Access</Typography>
                <Typography variant="body1" color="text.secondary">
                    {document.file_name}
                </Typography>
            </Stack>

            <DocumentAccessForm
                orderId={orderId}
                document={document}
                orderMembers={orderMemberOptions}
                handleSubmit={handleClose}
            />
        </Stack>
    );
}

ManageDocumentAccess.propTypes = {
    handleClose: PropTypes.func.isRequired,
    orderId: PropTypes.string.isRequired,
    document: PropTypes.object,
};

export default ManageDocumentAccess;
