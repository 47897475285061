import { Avatar, Stack, Typography } from '@mui/material';
import { getPartyColor } from 'helpers/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ActionOrderMember = ({ orderMember, user }) => {
    const name = user.name;
    const role = orderMember.role;
    const initials = user.name
        .split(' ')
        .map((n) => n[0])
        .join('');
    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={2} alignItems="center">
                <Avatar
                    key={orderMember.id}
                    sx={{
                        // width: '36px',
                        // height: '36px',
                        // fontSize: '16px',
                        bgcolor: getPartyColor(orderMember.party),
                    }}
                >
                    {initials}
                </Avatar>

                <Stack spacing={0}>
                    <Typography variant="body2" color="text.secondary">
                        Assigned to {_.startCase(role)}
                    </Typography>
                    <Typography variant="body1">{name}</Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

ActionOrderMember.propTypes = {
    orderMember: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default ActionOrderMember;
