import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useGetOrderQuery } from 'api/order';
import OrderAvatar from 'components/Order/components/OrderAvatar';
import OrderStatusChip from 'components/Order/components/OrderStatusChip';
import { formatAddress } from 'helpers/utils';

// TODO move this file outside of user as it is used in legal entities as well

const OrderMembershipListItem = ({ orderMembership }) => {
    const navigate = useNavigate();
    const { data: order } = useGetOrderQuery(orderMembership.order_id);

    const handleNavigate = () => {
        navigate(`/order/${orderMembership.order_id}`);
    };

    // Wait for order to load before rendering
    if (!order) {
        return null;
    }

    return (
        <ListItemButton className="order-membership-list-item" onClick={handleNavigate}>
            <ListItemAvatar>
                <OrderAvatar transactionType={order.transaction_type} />
            </ListItemAvatar>

            <ListItemText
                primary={order.qualia_order_number}
                secondary={`${_.startCase(orderMembership.role)} • ${formatAddress(order.property_address)}`}
                secondaryTypographyProps={{ noWrap: true }}
            />

            <OrderStatusChip status={order.status} size="small" sx={{ marginLeft: 2 }} />
        </ListItemButton>
    );
};

OrderMembershipListItem.propTypes = {
    orderMembership: PropTypes.object.isRequired,
};

export default OrderMembershipListItem;
