import LogoutIcon from '@mui/icons-material/Logout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserAvatar from '../../../../../../components/common/UserAvatar';
import { UserAction } from '../../../../../../stores/user';

const Profile = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const userStore = useSelector((store) => store.user);
    const { userDetails } = userStore;
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(UserAction.logoutSession());
    };

    return (
        <List disablePadding sx={{ position: 'relative' }}>
            <ListItem disablePadding disableGutters>
                <ListItemButton
                    onClick={handleClick}
                    sx={{
                        '& .MuiSvgIcon-root': {
                            color: (theme) => theme.palette.text.secondary,
                            marginLeft: (them) => them.spacing(1),
                        },
                        '&:hover': {
                            '& .MuiSvgIcon-root': {
                                color: (theme) => theme.palette.white,
                            },
                        },
                    }}
                    disableRipple
                >
                    <ListItemAvatar>
                        <UserAvatar user={userDetails} />
                    </ListItemAvatar>

                    <ListItemText
                        primary={`${userDetails.first_name} ${userDetails.last_name}`}
                        secondary={userDetails.email}
                        primaryTypographyProps={{
                            color: (theme) => theme.palette.white,
                            noWrap: true,
                        }}
                        secondaryTypographyProps={{
                            noWrap: true,
                        }}
                    />

                    <MoreVertIcon />
                </ListItemButton>
            </ListItem>

            <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorReference="anchorEl"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{ sx: { width: '100%', maxWidth: '208px' } }}
                keepMounted
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </List>
    );
};

export default Profile;
