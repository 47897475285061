import _ from 'lodash';
import { useMemo, useState } from 'react';

import ProfileForm, { INITIAL_PROFILE_DATA, formatProfilePayload } from '../../profile/ProfileForm';

const generateInitialProfile = (formData) => {
    if (!formData) {
        return { ...INITIAL_PROFILE_DATA };
    }

    return {
        ...INITIAL_PROFILE_DATA,
        ...formData,
    };
};

const CustomProfileField = (props) => {
    // NOTE: this field wraps the standard profile form and handles interoperability with the parent rjsf form
    // Because the profile interface is fairly standardized, we do not pass in much of the schema or uiSchema
    // formData can be null or object with profile fields
    // uses props.formData and props.onChange to update the parent form's profile data
    // uses props.schema.title for the label
    // uses props.uiSchema.ui:options.required to determine if the profile fields should be required
    const [profileData, setProfileData] = useState(generateInitialProfile(props.formData));

    const handleUpdateFormData = (newFormData) => {
        props.onChange(formatProfilePayload(newFormData));
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(handleUpdateFormData, 500), []);

    const handleUpdateProfile = (newProfileData) => {
        setProfileData(newProfileData);
        debouncedChangeHandler(newProfileData);
    };

    // Defaults to enforce required fields - must be explicitly set to false to disable
    const uiSchema = _.get(props, 'uiSchema', {});
    const required = _.get(uiSchema, 'ui:options.required', false);
    const label = _.get(props, 'schema.title');
    const disabled = _.get(props, 'readonly', false);
    const requiredFields = _.get(props, 'schema.required', []);
    const disabledFields = [];
    _.forEach(uiSchema, (value, key) => {
        if (_.get(value, 'ui:readonly', false)) {
            disabledFields.push(key);
        }
    });

    return (
        <ProfileForm
            profileData={profileData}
            setProfileData={handleUpdateProfile}
            label={label}
            showLabel={true}
            required={required}
            disabled={disabled}
            requiredFields={requiredFields}
            disabledFields={disabledFields}
        />
    );
};

export default CustomProfileField;
