import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
    $isListNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createHeadingNode, $isHeadingNode } from '@lexical/rich-text';
import { $isAtNodeEnd, $isParentElementRTL, $wrapNodes } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
    $createParagraphNode,
    $getSelection,
    $isRangeSelection,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    FORMAT_TEXT_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import NotesIcon from '@mui/icons-material/Notes';
import RedoIcon from '@mui/icons-material/Redo';
import TitleIcon from '@mui/icons-material/Title';
import UndoIcon from '@mui/icons-material/Undo';
import {
    Button,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Stack,
    ToggleButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    '&.MuiToggleButton-root': {
        border: '0 !important',
        '&.Mui-disabled': {
            border: '0 !important',
        },
    },
}));

const LowPriority = 1;

const BLOCK_TYPES = {
    paragraph: 'paragraph',
    h1: 'h1',
    h2: 'h2',
    ul: 'ul',
    ol: 'ol',
};

const supportedBlockTypes = new Set([
    BLOCK_TYPES.paragraph,
    BLOCK_TYPES.h1,
    BLOCK_TYPES.h2,
    BLOCK_TYPES.ul,
    BLOCK_TYPES.ol,
]);

const blockTypeToBlockName = {
    [BLOCK_TYPES.h1]: 'Large Heading',
    [BLOCK_TYPES.h2]: 'Small Heading',
    [BLOCK_TYPES.ol]: 'Numbered List',
    [BLOCK_TYPES.ul]: 'Bulleted List',
    [BLOCK_TYPES.paragraph]: 'Normal',
};

const FORMAT_TYPES = {
    bold: 'bold',
    italic: 'italic',
    underline: 'underline',
    strikethrough: 'strikethrough',
    link: 'link',
};

const blockTypeToBlockIcon = {
    h1: <TitleIcon />,
    h2: <TitleIcon fontSize="small" />,
    ol: <FormatListNumberedIcon />,
    ul: <FormatListBulletedIcon />,
    paragraph: <NotesIcon />,
};

function positionEditorElement(editor, rect) {
    if (rect === null) {
        editor.style.opacity = '0';
        editor.style.top = '-1000px';
        editor.style.left = '-1000px';
    } else {
        editor.style.opacity = '1';
        editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
        editor.style.left = `${rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2}px`;
    }
}

function FloatingLinkEditor({ editor }) {
    const editorRef = useRef(null);
    const inputRef = useRef(null);
    const mouseDownRef = useRef(false);
    const [linkUrl, setLinkUrl] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [lastSelection, setLastSelection] = useState(null);

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL());
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL());
            } else {
                setLinkUrl('');
            }
        }
        const editorElem = editorRef.current;
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        if (editorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();
        if (
            selection !== null &&
            !nativeSelection.isCollapsed &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const domRange = nativeSelection.getRangeAt(0);
            let rect;
            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild;
                }
                rect = inner.getBoundingClientRect();
            } else {
                rect = domRange.getBoundingClientRect();
            }

            if (!mouseDownRef.current) {
                positionEditorElement(editorElem, rect);
            }
            setLastSelection(selection);
        } else if (!activeElement || activeElement.className !== 'link-input') {
            positionEditorElement(editorElem, null);
            setLastSelection(null);
            setEditMode(false);
            setLinkUrl('');
        }

        return true;
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateLinkEditor();
                });
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateLinkEditor();
                    return true;
                },
                LowPriority
            )
        );
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditMode]);

    return (
        <div ref={editorRef} className="link-editor">
            {isEditMode ? (
                <input
                    ref={inputRef}
                    className="link-input"
                    value={linkUrl}
                    onChange={(event) => {
                        setLinkUrl(event.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            if (lastSelection !== null) {
                                if (linkUrl !== '') {
                                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                                }
                                setEditMode(false);
                            }
                        } else if (event.key === 'Escape') {
                            event.preventDefault();
                            setEditMode(false);
                        }
                    }}
                />
            ) : (
                <>
                    <div className="link-input">
                        <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                            {linkUrl}
                        </a>
                        <IconButton
                            className="link-edit"
                            role="button"
                            tabIndex={0}
                            onMouseDown={(event) => event.preventDefault()}
                            onClick={() => {
                                setEditMode(true);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                </>
            )}
        </div>
    );
}

function getSelectedNode(selection) {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
}

function BlockOptionsDropdownList({
    editor,
    blockType,
    toolbarRef,
    menuButtomRef,
    showBlockOptionsDropDown,
    setShowBlockOptionsDropDown,
}) {
    const dropDownRef = useRef(null);

    useEffect(() => {
        const toolbar = toolbarRef.current;
        const dropDown = dropDownRef.current;

        if (toolbar !== null && dropDown !== null) {
            const { top, left } = toolbar.getBoundingClientRect();
            dropDown.style.top = `${top + 40}px`;
            dropDown.style.left = `${left}px`;
        }
    }, [dropDownRef, toolbarRef]);

    useEffect(() => {
        const dropDown = dropDownRef.current;
        const toolbar = toolbarRef.current;

        if (dropDown !== null && toolbar !== null) {
            const handle = (event) => {
                const target = event.target;

                if (!dropDown.contains(target) && !toolbar.contains(target)) {
                    setShowBlockOptionsDropDown(false);
                }
            };
            document.addEventListener('click', handle);

            return () => {
                document.removeEventListener('click', handle);
            };
        }
    }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

    const formatParagraph = () => {
        if (blockType !== BLOCK_TYPES.paragraph) {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createParagraphNode());
                }
            });
        }
        setShowBlockOptionsDropDown(false);
    };

    const formatLargeHeading = () => {
        if (blockType !== BLOCK_TYPES.h1) {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createHeadingNode(BLOCK_TYPES.h1));
                }
            });
        }
        setShowBlockOptionsDropDown(false);
    };

    const formatSmallHeading = () => {
        if (blockType !== BLOCK_TYPES.h2) {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createHeadingNode(BLOCK_TYPES.h2));
                }
            });
        }
        setShowBlockOptionsDropDown(false);
    };

    const formatBulletList = () => {
        if (blockType !== BLOCK_TYPES.ul) {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
        setShowBlockOptionsDropDown(false);
    };

    const formatNumberedList = () => {
        if (blockType !== BLOCK_TYPES.ol) {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
        setShowBlockOptionsDropDown(false);
    };

    const blockTypeToFormatter = {
        [BLOCK_TYPES.paragraph]: formatParagraph,
        [BLOCK_TYPES.h1]: formatLargeHeading,
        [BLOCK_TYPES.h2]: formatSmallHeading,
        [BLOCK_TYPES.ul]: formatBulletList,
        [BLOCK_TYPES.ol]: formatNumberedList,
    };

    return (
        <Menu
            id="formatting-options-menu"
            className="dropdown"
            ref={dropDownRef}
            anchorEl={menuButtomRef.current}
            MenuListProps={{
                'aria-labelledby': 'formatting-options-button',
            }}
            open={showBlockOptionsDropDown}
            onClose={() => setShowBlockOptionsDropDown(false)}
        >
            {_.map(blockTypeToFormatter, (formatter, type) => (
                <MenuItem key={type} className="item" onClick={formatter} selected={blockType === type}>
                    <ListItemIcon>{blockTypeToBlockIcon[type]}</ListItemIcon>
                    <ListItemText>{blockTypeToBlockName[type]}</ListItemText>
                </MenuItem>
            ))}
        </Menu>
    );
}

function RichTextToolbar() {
    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef(null);
    const menuButtonRef = useRef(null);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [blockType, setBlockType] = useState(BLOCK_TYPES.paragraph);
    const [selectedElementKey, setSelectedElementKey] = useState(null);
    const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(false);
    const [isRTL, setIsRTL] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                    if (type === 'root') {
                        // Empty editor - ensure blocktype is paragraph to show full toolbar options
                        setBlockType(BLOCK_TYPES.paragraph);
                    } else {
                        setBlockType(type);
                    }
                }
            }
            // Update text format
            setIsBold(selection.hasFormat(FORMAT_TYPES.bold));
            setIsItalic(selection.hasFormat(FORMAT_TYPES.italic));
            setIsUnderline(selection.hasFormat(FORMAT_TYPES.underline));
            setIsStrikethrough(selection.hasFormat(FORMAT_TYPES.strikethrough));
            setIsRTL($isParentElementRTL(selection));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, newEditor) => {
                    updateToolbar();
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, updateToolbar]);

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
        } else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink]);

    return (
        <Paper
            className="toolbar"
            ref={toolbarRef}
            elevation={0}
            sx={{
                display: 'flex',
                borderRadius: (theme) => theme.spacing(0.5),
                flexWrap: 'wrap',
                padding: (theme) => theme.spacing(0.5),
            }}
        >
            <Stack direction="row" spacing={1} alignItems="center">
                <IconButton
                    disabled={!canUndo}
                    onClick={() => {
                        editor.dispatchCommand(UNDO_COMMAND);
                    }}
                    className="toolbar-item spaced"
                    aria-label="Undo"
                >
                    <UndoIcon className="format undo" />
                </IconButton>

                <IconButton
                    disabled={!canRedo}
                    onClick={() => {
                        editor.dispatchCommand(REDO_COMMAND);
                    }}
                    className="toolbar-item"
                    aria-label="Redo"
                >
                    <RedoIcon className="format redo" />
                </IconButton>
            </Stack>

            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

            <Button
                ref={menuButtonRef}
                id="formatting-options-button"
                color="default"
                variant="text"
                className="toolbar-item block-controls"
                onClick={() => setShowBlockOptionsDropDown(!showBlockOptionsDropDown)}
                aria-label="Formatting Options"
                aria-controls={showBlockOptionsDropDown ? 'formatting-options-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={showBlockOptionsDropDown ? 'true' : undefined}
                startIcon={blockTypeToBlockIcon[blockType]}
                sx={{
                    textTransform: 'none',
                    '& .MuiButton-startIcon': {
                        ml: 0,
                    },
                }}
            >
                {blockTypeToBlockName[blockType]}
                <ExpandMoreIcon sx={{ marginLeft: (theme) => theme.spacing(2) }} />
            </Button>

            <BlockOptionsDropdownList
                editor={editor}
                blockType={blockType}
                toolbarRef={toolbarRef}
                menuButtomRef={menuButtonRef}
                showBlockOptionsDropDown={showBlockOptionsDropDown}
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
            />

            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

            <Stack direction="row" spacing={1} alignItems="center">
                <StyledToggleButton
                    size="small"
                    value={FORMAT_TYPES.bold}
                    onChange={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, FORMAT_TYPES.bold);
                    }}
                    selected={isBold}
                    className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
                    aria-label="Format Bold"
                >
                    <FormatBoldIcon className="format bold" />
                </StyledToggleButton>

                <StyledToggleButton
                    size="small"
                    value={FORMAT_TYPES.italic}
                    onChange={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, FORMAT_TYPES.italic);
                    }}
                    selected={isItalic}
                    className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
                    aria-label="Format Italics"
                >
                    <FormatItalicIcon className="format italic" />
                </StyledToggleButton>

                <StyledToggleButton
                    size="small"
                    value={FORMAT_TYPES.underline}
                    onChange={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, FORMAT_TYPES.underline);
                    }}
                    selected={isUnderline}
                    className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
                    aria-label="Format Underline"
                >
                    <FormatUnderlinedIcon className="format underline" />
                </StyledToggleButton>

                {/* TODO - fix link modal and potentially having multiple link modals open at once across multiple editors */}
                {/* <StyledToggleButton
                    size="small"
                    value={FORMAT_TYPES.link}
                    onChange={insertLink}
                    selected={isLink}
                    // onClick={insertLink}
                    className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
                    aria-label="Insert Link"
                >
                    <InsertLinkIcon className="format link" />
                </StyledToggleButton>

                {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)} */}
            </Stack>
        </Paper>
    );
}

export default RichTextToolbar;
