import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Grid, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { USDollar, copyToClipboard, formatISODate } from '../../../../helpers/utils';
import useAdditionalDataItem from '../../../common/hooks/useAdditionalDataItem';
import EditPayoff from './EditPayoff';

// TODO move to common area
function SectionLabel({ label }) {
    return (
        <Typography
            variant="body2"
            sx={{
                fontWeight: '400',
                color: 'text.secondary',
            }}
        >
            {label}
        </Typography>
    );
}

// TODO move to common area
function SectionContent({ content }) {
    const [copyText, setCopyText] = useState('Copy');

    const handleCopy = (event, text) => {
        event.stopPropagation();
        event.preventDefault();
        copyToClipboard(text);
        setCopyText('Copied!');
    };

    return (
        <Box>
            {content ? (
                <Tooltip title={copyText} onClose={() => setCopyText('Copy')} placement="right" enterDelay={300}>
                    <Typography variant="body1" component="span" onClick={(event) => handleCopy(event, content)}>
                        {content || '—'}
                    </Typography>
                </Tooltip>
            ) : (
                <Typography variant="body1" component="span">
                    {'—'}
                </Typography>
            )}
        </Box>
    );
}

function PayoffDetailsSection({ label, content }) {
    return (
        <Grid item xs={12} md={6} lg={6} xl={4}>
            <SectionLabel label={label} />
            <SectionContent content={content} />
        </Grid>
    );
}

function PayoffDetailsAdditionalDataSection({ payoff, propertyKey }) {
    const { label, value } = useAdditionalDataItem(payoff, propertyKey);

    return <PayoffDetailsSection label={label} content={value} />;
}

function PayoffDetailsGrid({ payoff }) {
    if (!payoff) {
        return (
            <Typography variant="body1" color="text.secondary" fontStyle="italic">
                No current payoff
            </Typography>
        );
    }

    // const additionalDataChunks = _.get(payoff, 'additional_data_schema.ui_schema["ui:order"]', []);

    return (
        <Grid container alignItems="flex-start" spacing={2}>
            <PayoffDetailsSection label="Status" content={_.startCase(payoff.status)} />

            <PayoffDetailsSection label="Amount" content={payoff.amount ? USDollar.format(payoff.amount) : null} />

            <PayoffDetailsSection label="Loan Number" content={payoff.loan_number} />

            <PayoffDetailsSection label="Payee Name" content={payoff.payee_name} />

            <PayoffDetailsSection
                label="Ordered Date"
                content={payoff.ordered_date ? formatISODate(payoff.ordered_date) : null}
            />

            <PayoffDetailsSection
                label="Received Date"
                content={payoff.received_date ? formatISODate(payoff.received_date) : null}
            />

            <PayoffDetailsSection
                label="Good Through Date"
                content={payoff.good_through_date ? formatISODate(payoff.good_through_date) : null}
            />

            {/* {_.map(additionalDataChunks, (propertyKey) => (
                <PayoffDetailsAdditionalDataSection key={propertyKey} lien={lien} propertyKey={propertyKey} />
            ))} */}
        </Grid>
    );
}

function LienPayoff({ order, lien }) {
    const [editMode, setEditMode] = useState(false);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    // Find payoff (if exists)
    const payoff = _.find(order.payoffs, { lien_id: lien.id });

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Payoff</Typography>

                {!!payoff && (
                    <IconButton size="small" onClick={() => toggleEditMode()}>
                        {editMode ? <CloseIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                    </IconButton>
                )}
            </Stack>

            {editMode ? (
                <EditPayoff
                    payoff={payoff}
                    handleCancel={() => toggleEditMode()}
                    handleComplete={() => toggleEditMode()}
                />
            ) : (
                <PayoffDetailsGrid payoff={payoff} />
            )}
        </Paper>
    );
}

LienPayoff.propTypes = {
    order: PropTypes.object.isRequired,
    lien: PropTypes.object.isRequired,
};

export default LienPayoff;
