import { Box, Drawer, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ActionGroup, Profile, SidebarNav } from './components';

// TODO convert away from makeStyles
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 240,
        borderRight: 0,
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        [theme.breakpoints.up('lg')]: {
            height: '100%',
        },
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: '3',
    },
    nav: {
        paddingTop: theme.spacing(2),
    },
    search: {
        padding: '0px 10px',
        borderRadius: '0',
    },
    newOrder: {
        textDecoration: 'none',
    },
    logo: {
        // height: '38px',
        height: '38px',
    },
}));

const Sidebar = (props) => {
    const { open, variant, onClose, className } = props;

    const classes = useStyles();

    return (
        <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
            <Stack direction="column" sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Stack
                    direction="column"
                    alignItems="center"
                    spacing={3}
                    sx={{ padding: (theme) => theme.spacing(3, 3, 1, 3) }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <RouterLink to="/">
                            <img alt="Hudly Title Logo" src="/logo-white-transparent.png" className={classes.logo} />
                        </RouterLink>
                    </Box>

                    <Box>
                        <ActionGroup />
                    </Box>
                </Stack>

                <Box sx={{ flexGrow: 1, overflow: 'auto', colorScheme: 'dark' }}>
                    <SidebarNav className={classes.nav} />
                </Box>

                <Box sx={{ borderTop: (theme) => `solid 1px #696969` }}>
                    <Profile />
                </Box>
            </Stack>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
};

export default Sidebar;
