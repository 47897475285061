import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateOrderMutation } from '../../api/order';
import {
    ORDER_ROLE_TYPE,
    PARCEL_TYPE,
    TITLE_WORKFLOW,
    TRANSACTION_ORDER_TYPE,
    TRANSACTION_TYPE,
} from '../../helpers/constants';
import DocumentForm from './DocumentForm';
import PurchaseForm from './PurchaseForm';
import PurchaseFormCont from './PurchaseFormCont';
import RefinanceForm from './RefinanceForm';
import TransactionForm from './TransactionForm';

import { formatAddressPayload, INITIAL_ADDRESS_DATA } from '../common/form/address/AddressForm';
import { Button, LoadingButton } from '../common/styled';

const CREATE_ORDER_FORM_STAGE = {
    transactionForm: 'transactionForm',
    purchaseForm: 'purchaseForm',
    purchaseFormCont: 'purchaseFormCont',
    refinanceForm: 'refinanceForm',
    documentForm: 'documentForm',
};

const TRANSACTION_STAGE_ORDER = {
    [TRANSACTION_TYPE.purchase]: [CREATE_ORDER_FORM_STAGE.purchaseForm, CREATE_ORDER_FORM_STAGE.purchaseFormCont],
    [TRANSACTION_TYPE.refinance]: [CREATE_ORDER_FORM_STAGE.refinanceForm],
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '480px',
    },
    header: {
        marginBottom: theme.spacing(4),
    },
    content: {},
    navigation: {
        textAlign: 'right',
        '& > *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

function CreateOrder({ onClose }) {
    const navigate = useNavigate();
    const classes = useStyles();

    const [createOrder, { isLoading: isCreateOrderLoading }] = useCreateOrderMutation();

    // Form state data
    const [disableFields, setDisableFields] = useState(false);
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [stage, setStage] = useState(CREATE_ORDER_FORM_STAGE.transactionForm);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [orderId, setOrderId] = useState();

    // Order data
    const [qualiaOrderNumber, setQualiaOrderNumber] = useState('');
    const [transactionType, setTransactionType] = useState(TRANSACTION_TYPE.purchase);
    const [titleWorkflow, setTitleWorkflow] = useState(TITLE_WORKFLOW.hudly_purchase);
    const [orderType, setOrderType] = useState(TRANSACTION_ORDER_TYPE[transactionType].residential);
    const [address, setAddress] = useState({ ...INITIAL_ADDRESS_DATA });

    // Purchase data
    const [salesPrice, setSalesPrice] = useState('0');
    const [earnestMoney, setEarnestMoney] = useState('0');
    const [optionFee, setOptionFee] = useState('0');
    const [optionPeriod, setOptionPeriod] = useState('3');

    const [titlePolicyPayer, setTitlePolicyPayer] = useState(ORDER_ROLE_TYPE.seller);
    const [surveyException, setSurveyException] = useState('');
    const [titlePeriod, setTitlePeriod] = useState('20');
    const [survey, setSurvey] = useState('');
    const [surveyPayer, setSurveyPayer] = useState(''); // not
    const [surveyPeriod, setSurveyPeriod] = useState('15');
    const [objectionPeriod, setObjectionPeriod] = useState('3');
    const [hoa, setHoa] = useState('');
    const [hoaPeriod, setHoaPeriod] = useState('10');
    const [hoaCredit, setHoaCredit] = useState('0');
    const [homeWarrantyCredit, setHomeWarrantyCredit] = useState('0');
    const [openDate, setOpenDate] = useState(format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'));
    const [sellerCredit, setSellerCredit] = useState('0');

    // Refinance data
    const [loanNumber, setLoanNumber] = useState('');

    // Shared data
    const [loanAmount, setLoanAmount] = useState('0');
    const [closeDate, setCloseDate] = useState(null);
    const [confirmedCloseDate, setConfirmedCloseDate] = useState(false);
    const [fundDate, setFundDate] = useState(null);

    const STAGE_MAP = {
        [CREATE_ORDER_FORM_STAGE.transactionForm]: {
            title: 'New Order',
            subtitle: '',
            hasNextBtn: true,
        },
        [CREATE_ORDER_FORM_STAGE.purchaseForm]: {
            title: qualiaOrderNumber,
            subtitle: `${_.capitalize(transactionType)} (${_.startCase(orderType)})`,
            hasBackBtn: true,
            hasNextBtn: true,
        },
        [CREATE_ORDER_FORM_STAGE.purchaseFormCont]: {
            title: qualiaOrderNumber,
            subtitle: `${_.capitalize(transactionType)} (${_.startCase(orderType)})`,
            hasBackBtn: true,
            hasCreateBtn: true,
        },
        [CREATE_ORDER_FORM_STAGE.refinanceForm]: {
            title: qualiaOrderNumber,
            subtitle: `${_.capitalize(transactionType)} (${_.startCase(orderType)})`,
            hasBackBtn: true,
            hasCreateBtn: true,
        },
        [CREATE_ORDER_FORM_STAGE.documentForm]: {
            title: qualiaOrderNumber,
            subtitle: 'Upload Documents',
            hasDoneBtn: true,
        },
    };

    const handleBack = () => {
        const currentStageTrack = TRANSACTION_STAGE_ORDER[transactionType];
        const currentStageIndex = currentStageTrack.indexOf(stage);

        // If we're on the first stage of the transaction-specific stage track, go back to the initial form
        if (currentStageIndex <= 0) {
            setStage(CREATE_ORDER_FORM_STAGE.transactionForm);
        } else {
            // Otherwise, go back to the previous stage
            setStage(currentStageTrack[currentStageIndex - 1]);
        }
    };

    const handleNext = () => {
        if (stage === CREATE_ORDER_FORM_STAGE.transactionForm) {
            // Start transaction-specific stages
            setStage(TRANSACTION_STAGE_ORDER[transactionType][0]);
        } else {
            // Find position of current stage in the list of stages
            const currentStageTrack = TRANSACTION_STAGE_ORDER[transactionType];
            const currentStageIndex = _.findIndex(currentStageTrack, (stageTrackItem) => stageTrackItem === stage);

            // If current stage is the last one, submit the order
            if (currentStageIndex === currentStageTrack.length - 1) {
                // We are using the handleNext on the final stage to take advantage of the native form validation
                // Instead of calling createOrder directly
                handleCreateOrder();
            } else if (currentStageIndex < currentStageTrack.length - 1) {
                // Otherwise, go to the next stage
                setStage(currentStageTrack[currentStageIndex + 1]);
            } else {
                console.error(`Next stage after ${stage} not found in ${TRANSACTION_STAGE_ORDER[transactionType]}`);
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        handleNext();
    };

    const handleDone = () => {
        if (orderId) {
            navigate(`/order/${orderId}`);
        } else {
            navigate('/orders');
        }

        if (onClose) {
            onClose();
        }
    };

    const handleCreateOrder = () => {
        setDisableFields(true);

        // Baseline order data shared by all transactions
        const newOrder = {
            qualia_order_number: qualiaOrderNumber,
            transaction_type: transactionType,
            order_type: orderType,
            title_workflow: titleWorkflow,
            user_roles: [], // Empty for now as this is auto-filled by backend
            property_address: formatAddressPayload(address),
            contract_start_date: openDate,
            close_date: closeDate,
            confirmed_close_date: confirmedCloseDate,
            disbursement_date: fundDate,
            loan_amount: loanAmount ? parseFloat(loanAmount) : null,
        };

        if (transactionType === TRANSACTION_TYPE.purchase) {
            newOrder['purchase_price'] = salesPrice ? parseFloat(salesPrice) : null;

            // Survey data - survey_required field answers 2 questions:
            // * survey required (true/false/null)
            // * existing survey (true/false/null)
            const survey_required = survey === 'TBD' ? null : !!survey; // empty string equates to not required which is false | TBD equates to null
            const existing_survey = survey_required ? survey === 'existing' : null; // empty string equates to not required which is false | TBD equates to null
            const survey_payer = !!surveyPayer ? surveyPayer : null; // empty string equates (TBD) is falsey which equates to null

            newOrder['parcel_data'] = {
                [PARCEL_TYPE.hoa]: {
                    hoa_required: !!hoa, // empty string equates to not required which is false
                    hoa_payer: !!hoa ? hoa : null,
                    hoa_period: parseInt(hoaPeriod),
                    hoa_credit: hoaCredit ? parseFloat(hoaCredit) : null,
                },
                [PARCEL_TYPE.earnest_money]: {
                    earnest_money_amount: earnestMoney ? parseFloat(earnestMoney) : null,
                    option_fee_amount: optionFee ? parseFloat(optionFee) : null,
                    option_period: parseInt(optionPeriod),
                },
                [PARCEL_TYPE.title_exam]: {
                    title_policy_payer: titlePolicyPayer,
                    survey_exception: !!surveyException ? surveyException : null,
                    title_period: parseInt(titlePeriod),
                    objection_period: parseInt(objectionPeriod),
                },
                [PARCEL_TYPE.survey]: {
                    survey_required,
                    existing_survey,
                    survey_payer,
                    survey_period: parseInt(surveyPeriod),
                },
                [PARCEL_TYPE.home_warranty]: {
                    home_warranty_credit: homeWarrantyCredit ? parseFloat(homeWarrantyCredit) : null,
                },
                [PARCEL_TYPE.balancing]: {
                    seller_credit: sellerCredit ? parseFloat(sellerCredit) : null,
                },
            };
        } else if (transactionType === TRANSACTION_TYPE.refinance) {
            newOrder['parcel_data'] = {
                [PARCEL_TYPE.title_exam]: {
                    loan_number: loanNumber,
                    title_period: 10, // Hardcoded for refinance
                },
                [PARCEL_TYPE.hoa]: {
                    hoa_period: 10, // Hardcoded for refinance
                },
            };
        }

        return submitOrder(newOrder).then((response) => {
            setDisableFields(false);

            if (response) {
                setOrderId(response.order_id);

                // Transition to document upload stage
                setStage(CREATE_ORDER_FORM_STAGE.documentForm);
            } else {
                // TODO handle error state
            }
        });
    };

    const submitOrder = async (orderData) => {
        const { data: createOrderData } = await createOrder({ orderData });

        if (createOrderData) {
            return createOrderData;
        } else {
            console.error('Failed to create order', orderData);
            return false;
        }
    };

    const renderFormHeader = (formStage) => {
        const stageData = STAGE_MAP[formStage];

        return (
            <>
                <Typography variant="h4">{stageData.title}</Typography>
                {stageData.subtitle && <Typography variant="subtitle2">{stageData.subtitle}</Typography>}
            </>
        );
    };

    const renderFormNavigation = (formStage) => {
        const stageData = STAGE_MAP[formStage];
        const baseButtonProps = {
            disableElevation: true,
            // fontSize: 'large',
            disabled: isDuplicate || disableFields,
            sx: {
                // minWidth: '120px',
            },
        };

        const baseButtons = {
            back: (
                <Button onClick={handleBack} color="default" variant="text" {...baseButtonProps}>
                    Back
                </Button>
            ),
            next: (
                <Button
                    color="primary"
                    type="submit" // type submit to force current form stage to be 'validated' by native input rules before moving to next stage
                    variant="contained"
                    {...baseButtonProps}
                    sx={{
                        minWidth: '160px',
                    }}
                >
                    Next
                </Button>
            ),
            create: (
                <LoadingButton
                    color="primary"
                    type="submit"
                    variant="contained"
                    {...baseButtonProps}
                    loading={isCreateOrderLoading}
                    sx={{
                        minWidth: '160px',
                    }}
                >
                    Create
                </LoadingButton>
            ),
            done: (
                <Button
                    color="primary"
                    onClick={handleDone}
                    variant="contained"
                    {...baseButtonProps}
                    sx={{
                        minWidth: '160px',
                    }}
                >
                    Done
                </Button>
            ),
        };

        return (
            <div className={classes.navigation}>
                {stageData.hasBackBtn && baseButtons.back}
                {stageData.hasNextBtn && baseButtons.next}
                {stageData.hasCreateBtn && baseButtons.create}
                {stageData.hasDoneBtn && baseButtons.done}
            </div>
        );
    };

    const renderFormFields = (formStage) => {
        const baseFormProps = {
            disableFields,
            error,
            errorMessage,
        };
        const formProps = {
            fields: {},
            ...baseFormProps,
        };

        switch (formStage) {
            case CREATE_ORDER_FORM_STAGE.purchaseForm:
                formProps['fields'] = {
                    salesPrice,
                    setSalesPrice,
                    loanAmount,
                    setLoanAmount,
                    earnestMoney,
                    setEarnestMoney,
                    optionFee,
                    setOptionFee,
                    optionPeriod,
                    setOptionPeriod,
                };
                return <PurchaseForm {...formProps} />;
            case CREATE_ORDER_FORM_STAGE.purchaseFormCont:
                formProps['fields'] = {
                    titlePolicyPayer,
                    setTitlePolicyPayer,
                    surveyException,
                    setSurveyException,
                    titlePeriod,
                    setTitlePeriod,
                    survey,
                    setSurvey,
                    surveyPayer,
                    setSurveyPayer,
                    surveyPeriod,
                    setSurveyPeriod,
                    objectionPeriod,
                    setObjectionPeriod,
                    hoa,
                    setHoa,
                    hoaPeriod,
                    setHoaPeriod,
                    hoaCredit,
                    setHoaCredit,
                    homeWarrantyCredit,
                    setHomeWarrantyCredit,
                    openDate,
                    setOpenDate,
                    closeDate,
                    setCloseDate,
                    confirmedCloseDate,
                    setConfirmedCloseDate,
                    fundDate,
                    setFundDate,
                    sellerCredit,
                    setSellerCredit,
                };
                return <PurchaseFormCont {...formProps} />;
            case CREATE_ORDER_FORM_STAGE.refinanceForm:
                formProps['fields'] = {
                    loanAmount,
                    setLoanAmount,
                    loanNumber,
                    setLoanNumber,
                    openDate,
                    closeDate,
                    setCloseDate,
                    confirmedCloseDate,
                    setConfirmedCloseDate,
                    fundDate,
                    setFundDate,
                };
                return <RefinanceForm {...formProps} />;

            case CREATE_ORDER_FORM_STAGE.documentForm:
                const documentFormProps = {
                    ...baseFormProps,
                    orderId,
                };
                return <DocumentForm {...documentFormProps} />;
            default:
                // Transaction stage is default as it is the first form stage
                formProps['fields'] = {
                    isDuplicate,
                    setIsDuplicate,
                    qualiaOrderNumber,
                    setQualiaOrderNumber,
                    transactionType,
                    setTransactionType,
                    titleWorkflow,
                    setTitleWorkflow,
                    orderType,
                    setOrderType,
                    address,
                    setAddress,
                };
                return <TransactionForm {...formProps} />;
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>{renderFormHeader(stage)}</div>

            <div className={classes.content}>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Stack spacing={6}>
                        {renderFormFields(stage)}

                        {renderFormNavigation(stage)}
                    </Stack>
                </form>
            </div>
        </div>
    );
}

export default CreateOrder;
