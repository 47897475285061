import { Stack } from '@mui/material';
import ActionList from '../../components/ActionList';

function ActionListPage() {

    return (
        <Stack style={{height: '100%'}}>
            <ActionList />
        </Stack>
    );
}

export default ActionListPage;
