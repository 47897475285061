import { Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import { useUpdateLegalEntityMutation } from '../../../api/legalEntity';
import { generateUpdateObject } from '../../../helpers/utils';
import { Button, LoadingButton } from '../../common/styled';
import LegalEntityForm from './LegalEntityForm';

const EditLegalEntity = ({ legalEntity, handleCancel, handleComplete }) => {
    const [legalEntityCopy, setLegalEntityCopy] = useState({
        ...legalEntity,
        contact: {
            email: legalEntity.contact?.email || '',
            phone: legalEntity.contact?.phone || '',
        },
        address: {
            street: _.get(legalEntity, 'address.street', ''),
            street_additional: _.get(legalEntity, 'address.street_additional', ''),
            city: _.get(legalEntity, 'address.city', ''),
            state: _.get(legalEntity, 'address.state', ''),
            zipcode: _.get(legalEntity, 'address.zipcode', ''),
            country: _.get(legalEntity, 'address.country', ''),
        },
    });
    const [updatePayload, setUpdatePayload] = useState();
    const [updateLegalEntity, { isLoading: isLegalEntitySaving }] = useUpdateLegalEntityMutation();

    const handleEditLegalEntity = (event) => {
        event.preventDefault();

        handleUpdateLegalEntity();
    };

    const formatLegalEntityUpdatePayload = () => {
        const formattedUpdateData = { ...updatePayload };

        _.forEach(updatePayload, (value, key) => {
            if (key === 'address') {
                // Verify address has been added/changed/removed
                const validOriginalAddress = !!legalEntity.address;
                const validCurrentAddress = !!legalEntityCopy.address.street;

                if (validOriginalAddress && !validCurrentAddress) {
                    // remove address
                    formattedUpdateData['address'] = null; // TODO verify this will remove
                } else if (!validOriginalAddress && validCurrentAddress) {
                    // add new address
                    formattedUpdateData['address'] = {
                        ...legalEntityCopy.address,
                        street_additional: legalEntityCopy.address.street_additional || null,
                    };
                } else if (validOriginalAddress && validCurrentAddress) {
                    // update address
                    formattedUpdateData['address'] = {
                        ...legalEntityCopy.address,
                        street_additional: legalEntityCopy.address.street_additional || null,
                    };
                } else if (!validOriginalAddress && !validCurrentAddress) {
                    // do nothing
                }
            } else if (key === 'contact') {
                // Verify contact has been added/changed/removed
                const validCurrentEmail = !!legalEntityCopy.contact.email;
                const validCurrentPhone = !!legalEntityCopy.contact.phone;

                if (validCurrentEmail || validCurrentPhone) {
                    // update existing contact details
                    formattedUpdateData['contact'] = {
                        ...(validCurrentEmail && { email: legalEntityCopy.contact.email }),
                        ...(validCurrentPhone && { phone: legalEntityCopy.contact.phone }),
                    };
                } else if (!validCurrentEmail && !validCurrentPhone) {
                    formattedUpdateData['contact'] = {
                        email: null,
                        phone: null,
                    };
                }
            } else if (value === '') {
                // Convert empty strigns to null
                formattedUpdateData[key] = null;
            }
        });

        return { legalEntityId: legalEntity.id, legalEntityData: formattedUpdateData };
    };

    const handleUpdateLegalEntity = async () => {
        const { data } = await updateLegalEntity(formatLegalEntityUpdatePayload());
        if (data) {
            handleComplete();
        } else {
            console.error('Error updating legal entity');
        }
    };

    const generateUpdatePayload = (originalLegalEntity, updatedLegalEntity) => {
        // Compare original user data with local form state
        const baseUpdateObject = generateUpdateObject(
            originalLegalEntity,
            updatedLegalEntity,
            _.keys(originalLegalEntity)
        );

        // Analyzing address and contact separately as these are nested object
        // Using userCopy to generate key list as base user contact/address may be null
        baseUpdateObject['address'] = generateUpdateObject(
            originalLegalEntity.address,
            updatedLegalEntity.address,
            _.keys(updatedLegalEntity.address)
        );
        baseUpdateObject['contact'] = generateUpdateObject(
            originalLegalEntity.contact,
            updatedLegalEntity.contact,
            _.keys(updatedLegalEntity.contact)
        );

        // json stringify will automatically remove undefined values from objects
        // however, we want to do that preimptively to calculate if an update should be submitted
        _.forEach(baseUpdateObject, (value, key) => {
            if (value === undefined) {
                delete baseUpdateObject[key];
            } else if (_.includes(['address', 'contact'], key)) {
                _.forEach(value, (childValue, childKey) => {
                    // Remove undefined values from nested objects
                    if (childValue === undefined) {
                        delete baseUpdateObject[key][childKey];
                    } else if (
                        childValue === '' &&
                        (originalLegalEntity[key] === null || originalLegalEntity[key][childKey] === null)
                    ) {
                        // Empty string is used in userCopy to properly mark an input as controlled while still being empty
                        // However, the backend expects null for empty values
                        // Therefore, we need to remove all instances where an empty string is used in userCopy when the original value was null
                        delete baseUpdateObject[key][childKey];
                    }
                });

                // If the address or contact object is empty, we need to remove it from the update payload
                if (_.isEmpty(baseUpdateObject[key])) {
                    delete baseUpdateObject[key];
                }
            }
        });

        return baseUpdateObject;
    };

    const handleUpdatePayload = (originalLegalEntity, updatedLegalEntity) => {
        const newUpdatePayload = generateUpdatePayload(originalLegalEntity, updatedLegalEntity);
        setUpdatePayload(newUpdatePayload);
    };

    const debouncedHandleUpdatePayload = useMemo(
        () =>
            _.debounce(
                (originalLegalEntity, updatedLegalEntity) =>
                    handleUpdatePayload(originalLegalEntity, updatedLegalEntity),
                300
            ),
        []
    );

    useEffect(() => {
        if (legalEntityCopy) {
            debouncedHandleUpdatePayload(legalEntity, legalEntityCopy);
        }

        // Stop the invocation of the debounced function
        // after unmounting
        return () => {
            debouncedHandleUpdatePayload.cancel();
        };
    }, [legalEntity, legalEntityCopy]);

    return (
        <Stack alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
            <form onSubmit={handleEditLegalEntity}>
                <Stack spacing={4} sx={{ maxWidth: '800px', paddingTop: (theme) => theme.spacing(2) }}>
                    <LegalEntityForm legalEntityData={legalEntityCopy} setLegalEntityData={setLegalEntityCopy} />

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={2}
                        sx={{ width: '100%' }}
                    >
                        <Button variant="text" color="default" onClick={() => handleCancel()}>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={isLegalEntitySaving}
                            disabled={_.keys(updatePayload).length === 0}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                        >
                            Update
                        </LoadingButton>
                    </Stack>
                </Stack>
            </form>
        </Stack>
    );
};

EditLegalEntity.propTypes = {
    legalEntity: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleComplete: PropTypes.func.isRequired,
};

export default EditLegalEntity;
