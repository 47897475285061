import PropTypes from 'prop-types';

import { useGetOrderEmailLogsQuery } from 'api/emailLog';
import EmailLogList from 'components/Log/EmailLogList';
import { EMAIL_LOG_LIST_GRID_COLUMN_TYPE } from 'components/Log/EmailLogList/components/EmailLogListGrid';
import { EMAIL_LOG_FILTER_TYPE } from 'helpers/constants';

const ORDER_HIDE_FILTER_TYPE_LIST = [EMAIL_LOG_FILTER_TYPE.orderId];
const ORDER_HIDE_COLUMN_TYPE_LIST = [EMAIL_LOG_LIST_GRID_COLUMN_TYPE.orderId];

const OrderEmailLogList = ({ orderId }) => {
    const { data: orderEmailLogs, isFetching, refetch } = useGetOrderEmailLogsQuery(orderId);

    const refetchData = () => {
        refetch();
    };

    return (
        <EmailLogList
            emailLogs={orderEmailLogs || []}
            hideFilterTypeList={ORDER_HIDE_FILTER_TYPE_LIST}
            hideColumnTypeList={ORDER_HIDE_COLUMN_TYPE_LIST}
            loading={isFetching}
            pageTitle=""
            refetch={refetchData}
        />
    );
};

OrderEmailLogList.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderEmailLogList;
