import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, Chip, IconButton, MenuItem, Stack, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import FilterTextField from 'components/common/styled/FilterTextField';
import { ACTION_STATUS, ASSIGNED_ACTION_FILTER_TYPE } from 'helpers/constants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
};

const actionStatusOptions = Object.values(ACTION_STATUS);

function AssignedActionListFilters({ filters, setFilters, resetFilters, actionTypeOptions = [], orderMembers = [] }) {
    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string isn't valid for some filters
        };

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const clearLinkedChip = () => {
        setFilters((prevValue) => ({
            ...prevValue,
            [ASSIGNED_ACTION_FILTER_TYPE.linkedId]: '',
        }));
    };

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <FilterTextField
                name={ASSIGNED_ACTION_FILTER_TYPE.actionType}
                label="Action Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters[ASSIGNED_ACTION_FILTER_TYPE.actionType]}
                size="small"
                sx={{
                    minWidth: '200px',
                }}
                select
            >
                <MenuItem value="">All</MenuItem>
                {_.map(actionTypeOptions, (actionTypeOption) => {
                    return (
                        <MenuItem key={actionTypeOption.type} value={actionTypeOption.type}>
                            {_.startCase(actionTypeOption.friendly_name)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>
            <FilterTextField
                name={ASSIGNED_ACTION_FILTER_TYPE.actionStatus}
                label="Action Status"
                variant="outlined"
                onChange={handleFilter}
                value={filters[ASSIGNED_ACTION_FILTER_TYPE.actionStatus]}
                size="small"
                sx={{
                    minWidth: '200px',
                }}
                select
            >
                <MenuItem value="">All</MenuItem>
                {_.map(actionStatusOptions, (actionStatusOption) => {
                    return (
                        <MenuItem key={actionStatusOption} value={actionStatusOption}>
                            {_.startCase(actionStatusOption)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>
            <FilterTextField
                name={ASSIGNED_ACTION_FILTER_TYPE.orderMemberId}
                label="Order Member"
                variant="outlined"
                onChange={handleFilter}
                value={filters[ASSIGNED_ACTION_FILTER_TYPE.orderMemberId]}
                size="small"
                sx={{
                    minWidth: '200px',
                }}
                select
            >
                <MenuItem value="">All</MenuItem>
                {_.map(orderMembers, (orderMember) => {
                    return (
                        <MenuItem key={orderMember.id} value={orderMember.id}>
                            {orderMember.member.name}
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            {filters[ASSIGNED_ACTION_FILTER_TYPE.linkedId] && (
                <Chip
                    label="Linked"
                    // variant="outlined"
                    color="default"
                    size="medium"
                    onDelete={clearLinkedChip}
                    deleteIcon={<CloseIcon />}
                />
            )}

            <Box flexGrow="1" />

            <Tooltip title="Reset Filters" placement="bottom" enterDelay={300}>
                <IconButton
                    onClick={() => {
                        resetFilters();
                    }}
                >
                    <RestartAltIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

AssignedActionListFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    orderMembers: PropTypes.array,
    actionTypeOptions: PropTypes.array,
};

export default AssignedActionListFilters;
