import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { $getRoot } from 'lexical';

import { Box } from '@mui/material';

import BaseTheme from './components/BaseTheme';
import EditTogglePlugin from './components/EditTogglePlugin';
import RichTextToolbar from './components/RichTextToolbar';
// import FocusPlugin from './components/FocusPlugin';

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
    console.error(error);
}

const initialConfig = {
    namespace: 'RichTextEditor',
    theme: BaseTheme,
    onError,
    // Any custom nodes go here
    nodes: [HeadingNode, ListNode, ListItemNode, LinkNode],
};

function RichTextEditor({
    initialContent,
    setRichTextContent,
    setPlainTextContent,
    editable = true,
    disabled = false,
    updatedDate = null,
}) {
    // const [isFocused, setIsFocused] = useState(false);

    const onChange = (editorState) => {
        editorState.read(() => {
            const root = $getRoot();
            const jsonContent = editorState.toJSON();
            const plaintextContent = root.getTextContent();
            setRichTextContent(JSON.stringify(jsonContent)); // TODO re-evaluate if/when we are able to pass objects in directly
            setPlainTextContent(plaintextContent);
        });
    };

    const debounceOnChange = useMemo(() => _.debounce(onChange, 350), []);

    return (
        <LexicalComposer
            initialConfig={{
                ...initialConfig,
                editable,
                editorState: initialContent,
            }}
        >
            <Box
                className={`
                    editor-container 
                    ${editable ? 'editable' : 'readOnly'} 
                    ${/*isFocused ? 'focused' : 'unfocused'*/ ''}
                `}
                sx={{
                    '&.editable': {
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        borderRadius: (theme) => theme.spacing(0.5),

                        '&:hover': {
                            borderColor: (theme) => 'rgba(0, 0, 0, 0.23)',
                        },

                        '&.focused': {
                            borderWidth: '2px',
                            borderColor: (theme) => theme.palette.primary.main,
                            margin: '-1px',
                        },

                        '& .editor-input': {
                            outline: 0,
                            padding: (theme) => theme.spacing(2),

                            '& > *:first-child': {
                                marginTop: 0,
                            },
                            '& > *:last-child': {
                                marginBottom: 0,
                            },
                        },
                    },
                    '&.readOnly': {
                        '& .editor-input': {
                            '& > *:first-child': {
                                marginTop: 0,
                            },
                            '& > *:last-child': {
                                marginBottom: 0,
                            },
                        },
                    },

                    // Manually set styles
                    // NOTE: some are automatic like bold & italic
                    '& .editor-text-underline': {
                        textDecoration: 'underline',
                    },

                    '& .link-editor': {
                        position: 'absolute',
                        zIndex: '100',
                        top: '-10000px',
                        left: '-10000px',
                        marginTop: '-6px',
                        maxWidth: '300px',
                        width: '100%',
                        opacity: '0',
                        backgroundColor: '#fff',
                        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
                        borderRadius: '8px',
                        transition: 'opacity 0.5s',

                        '& .link-input': {
                            display: 'block',
                            width: 'calc(100% - 24px)',
                            boxSizing: 'border-box',
                            margin: '8px 12px',
                            padding: '8px 12px',
                            borderRadius: '15px',
                            backgroundColor: '#eee',
                            fontSize: '15px',
                            color: 'rgb(5, 5, 5)',
                            border: '0',
                            outline: '0',
                            position: 'relative',
                            fontFamily: 'inherit',
                        },
                    },
                }}
            >
                {editable && <RichTextToolbar disabled={disabled} />}
                <Box className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<ContentEditable className={'editor-input'} />}
                        placeholder={null}
                        // placeholder={<div>Enter some text...</div>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <OnChangePlugin onChange={debounceOnChange} />
                    <HistoryPlugin />
                    <ListPlugin />
                    <LinkPlugin />
                    <ClearEditorPlugin />
                    <EditTogglePlugin initialContent={initialContent} editable={editable} updatedDate={updatedDate} />
                    {/* <FocusPlugin isFocused={isFocused} setIsFocused={setIsFocused} /> */}
                </Box>
            </Box>
        </LexicalComposer>
    );
}

RichTextEditor.propTypes = {
    initialContent: PropTypes.string,
    setRichTextContent: PropTypes.func.isRequired,
    setPlainTextContent: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default RichTextEditor;
