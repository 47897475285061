import AddIcon from '@mui/icons-material/Add';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useGetLegalEntityMembersByUserQuery } from '../../../api/legalEntity';
import LegalEntityMembershipList from './LegalEntityMembershipList';

// view contact - determine if contact is user or legal entity and render appropriate component

function UserDetailsLegalEntityMembershipList({ user }) {
    const { data: legalEntityMemberships } = useGetLegalEntityMembersByUserQuery(user.id);

    // TODO handle loading and error states

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(0) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ padding: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Organizations</Typography>

                <IconButton size="small" disabled>
                    <AddIcon />
                </IconButton>
            </Stack>

            <LegalEntityMembershipList legalEntityMemberships={legalEntityMemberships || []} />
        </Paper>
    );
}

UserDetailsLegalEntityMembershipList.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserDetailsLegalEntityMembershipList;
