import { Box, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useUpdatePayoffMutation } from '../../../../api/payoff';
import { generateUpdateObject } from '../../../../helpers/utils';
import { Button, LoadingButton } from '../../../common/styled';
// import EditPayoffAdditionalData from './EditPayoffAdditionalData';
import EditPayoffCoreData from './EditPayoffCoreData';

const EditPayoff = ({ payoff, handleCancel, handleComplete }) => {
    const [updatePayoff, { isLoading }] = useUpdatePayoffMutation();
    const [payoffCoreData, setPayoffCoreData] = useState();
    const [payoffAdditionalData, setPayoffAdditionalData] = useState();
    const [updateCoreDataPayload, setUpdateCoreDataPayload] = useState({});
    const [updateAdditionalDataPayload, setUpdateAdditionalDataPayload] = useState({});

    useEffect(() => {
        // Listen to form field changes and generate update payload object
        if (payoffAdditionalData) {
            setUpdateAdditionalDataPayload(generateUpdatePayload(payoff.additional_data, payoffAdditionalData));
        }
    }, [payoffAdditionalData, payoff.additional_data]);

    useEffect(() => {
        // Listen to form field changes and generate update payload object
        if (payoffCoreData) {
            setUpdateCoreDataPayload(generateUpdatePayload(payoff, payoffCoreData));
        }
    }, [payoffCoreData, payoff]);

    const generateUpdatePayload = (baseEntity, updateEntity) => {
        // Compare original payoff additional data with local form state
        const baseUpdateObject = generateUpdateObject(baseEntity, updateEntity, _.keys(updateEntity));

        // json stringify will automatically remove undefined values from objects
        // however, we want to do that preimptively to calculate if an update should be submitted
        _.forEach(baseUpdateObject, (value, key) => {
            if (value === undefined) {
                delete baseUpdateObject[key];
            }
        });
        return baseUpdateObject;
    };

    const handlePayoffUpdate = async (updatedPayoffData) => {
        const updatePayoffPayload = {
            payoffId: payoff.id,
            payoffData: updatedPayoffData,
        };

        const { data } = await updatePayoff(updatePayoffPayload);
        return !!data;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const willUpdateCoreData = _.keys(updateCoreDataPayload).length > 0;
        // const willUpdateAdditionalData = _.keys(updateAdditionalDataPayload).length > 0;

        if (willUpdateCoreData) {
            // Only includes fields that have changed
            let updatePayoffData = {};

            updatePayoffData = {
                ...updatePayoffData,
                ...updateCoreDataPayload,
            };

            // if (willUpdateAdditionalData) {
            //     updatePayoffData = {
            //         ...updatePayoffData,
            //         additional_data: {
            //             ...updateAdditionalDataPayload,
            //         },
            //     };
            // }

            handlePayoffUpdate(updatePayoffData).then((success) => {
                if (success) {
                    handleComplete();
                }
            });
        }
    };

    const changedCoreData = _.keys(updateCoreDataPayload).length > 0;
    const changedAdditionalData = _.keys(updateAdditionalDataPayload).length > 0;
    const canUpdate = changedCoreData || changedAdditionalData;

    return (
        <Stack container="row" alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
            <Box sx={{ maxWidth: '480px' }}>
                <form onSubmit={handleSubmit}>
                    <EditPayoffCoreData
                        payoff={payoff}
                        payoffCoreData={payoffCoreData}
                        setPayoffCoreData={setPayoffCoreData}
                    />

                    {/* <EditPayoffAdditionalData
                        payoff={payoff}
                        payoffAdditionalData={payoffAdditionalData}
                        setPayoffAdditionalData={setPayoffAdditionalData}
                    /> */}

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={2}
                        sx={{ width: '100%', paddingTop: (theme) => theme.spacing(3) }}
                    >
                        <Button variant="text" color="default" onClick={() => handleCancel()} disableElevation>
                            Cancel
                        </Button>

                        <LoadingButton
                            color="primary"
                            type="submit"
                            variant="contained"
                            sx={{
                                minWidth: '120px',
                            }}
                            loading={isLoading}
                            disabled={!canUpdate}
                            disableElevation
                        >
                            Update
                        </LoadingButton>
                    </Stack>
                </form>
            </Box>
        </Stack>
    );
};

EditPayoff.propTypes = {
    payoff: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleComplete: PropTypes.func.isRequired,
};

export default EditPayoff;
