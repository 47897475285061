import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import NewLienForm from './NewLienForm';

const CreateLien = ({ order, handleSubmit }) => {
    return (
        <Stack spacing={4} sx={{ width: '480px' }}>
            <Stack direction="column" spacing={2}>
                <Typography variant="h5">Add Title Issue / Lien</Typography>
            </Stack>

            <NewLienForm order={order} handleSubmit={handleSubmit} />
        </Stack>
    );
};

CreateLien.propTypes = {
    order: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default CreateLien;
