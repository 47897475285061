import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { ORDER_TAG } from './order';
import { rootApi } from './root';

export const PARCEL_TAG = _.toUpper(ENTITY_TYPE.parcel);
export const PARCEL_STATUS_HISTORY_TAG = _.toUpper('parcel_status_history');

export const parcelApi = rootApi.injectEndpoints({
    reducerPath: 'parcelApi',
    endpoints: (builder) => ({
        updateParcel: builder.mutation({
            query: ({parcelId, parcelData}) => ({
                url: `parcel/${parcelId}`,
                method: 'PUT',
                body: parcelData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [PARCEL_TAG, ORDER_TAG, PARCEL_STATUS_HISTORY_TAG],
        }),
        getParcelStatusHistory: builder.query({
            query: (parcelId) => `parcel/${parcelId}/status`,
            providesTags: [PARCEL_STATUS_HISTORY_TAG],
        }),
        createParcelAction: builder.mutation({
            query: ({parcelId, actionData}) => ({
                url: `parcel/${parcelId}/actions`,
                method: 'POST',
                body: actionData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [PARCEL_TAG, ORDER_TAG, PARCEL_STATUS_HISTORY_TAG],
        }),
    }),
    overrideExisting: false,
})



export const { useUpdateParcelMutation, useGetParcelStatusHistoryQuery, useCreateParcelActionMutation } = parcelApi