import { Box, List, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import UserRelationshipListItem from './UserRelationshipListItem';

const UserRelationshipList = ({ relationships, handleEditRelationship }) => {
    if (!relationships || relationships.length === 0) {
        return (
            <Box sx={{ padding: 3, paddingTop: 0 }}>
                <Typography variant="body1" color="text.secondary" fontStyle="italic">
                    No current relationships
                </Typography>
            </Box>
        );
    }

    return (
        <List
            sx={{
                '& .user-relationship-list-item': {
                    '& .MuiListItemSecondaryAction-root': {
                        right: (theme) => theme.spacing(3),
                    },
                },
                '& .user-relationship-list-item-button': {
                    paddingLeft: 3,
                    paddingRight: 3,
                },
            }}
            disablePadding
        >
            {_.map(relationships, (relationship) => {
                return (
                    <UserRelationshipListItem
                        key={relationship.id}
                        relationship={relationship}
                        handleEditRelationship={handleEditRelationship}
                    />
                );
            })}
        </List>
    );
};

UserRelationshipList.propTypes = {
    relationships: PropTypes.array.isRequired,
    handleEditRelationship: PropTypes.func.isRequired,
};

export default UserRelationshipList;
