import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { filterAccessLogs } from '../../../helpers/utils';
import AccessLogListFilters, { INITIAL_ACCESS_LOG_FILTERS } from './components/AccessLogListFilters';
import AccessLogListDataGrid from './components/AccessLogListGrid';

const DEFAULT_HIDE_FILTER_TYPE_LIST = [];
const DEFAULT_HIDE_COLUMN_TYPE_LIST = [];
const DEFAULT_INITIAL_ACCESS_LOG_FILTERS = {
    ...INITIAL_ACCESS_LOG_FILTERS,
};

const AccessLogList = ({
    accessLogs,
    hideFilterTypeList = DEFAULT_HIDE_FILTER_TYPE_LIST,
    hideColumnTypeList = DEFAULT_HIDE_COLUMN_TYPE_LIST,
    defaultFilters = DEFAULT_INITIAL_ACCESS_LOG_FILTERS,
    pageTitle = 'Access Logs',
    loading = false,
}) => {
    const [filters, setFilters] = useState({
        ...DEFAULT_INITIAL_ACCESS_LOG_FILTERS,
    });

    const filteredAccessLogs = useMemo(() => filterAccessLogs(accessLogs, filters), [accessLogs, filters]);

    const resetFilters = () => {
        setFilters({
            ...DEFAULT_INITIAL_ACCESS_LOG_FILTERS, // TODO
        });
    };

    return (
        <Stack spacing={3}>
            {pageTitle && <Typography variant="h1">{pageTitle}</Typography>}

            <Box>
                <AccessLogListFilters
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                    defaultFilters={defaultFilters}
                    hideFilters={hideFilterTypeList}
                />
            </Box>

            <Box>
                <AccessLogListDataGrid
                    accessLogs={filteredAccessLogs}
                    hideColumns={hideColumnTypeList}
                    loading={loading}
                />
            </Box>
        </Stack>
    );
};

AccessLogList.propTypes = {
    accessLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
    hideFilterTypeList: PropTypes.arrayOf(PropTypes.string),
    hideColumnTypeList: PropTypes.arrayOf(PropTypes.string),
    pageTitle: PropTypes.string,
    loading: PropTypes.bool,
};

export default AccessLogList;
