import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import BaseNote from './BaseNote';


function ActiveNoteReply({ note, users }) {
    // Style wrapper around the base note

    return (
        <Box
            sx={{
                marginLeft: (theme) => `${theme.spacing(7)} !important`
            }}
        >
            <BaseNote note={note} users={users} options={{allowPin: false}} />
        </Box>
    )
}

ActiveNoteReply.propTypes = {
    note: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
}

export default ActiveNoteReply;