import { Box, List, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MembershipListItem from './MembershipListItem';

const MembershipList = ({ members, handleEditMembership }) => {
    if (!members || members.length === 0) {
        return (
            <Box sx={{ padding: 3, paddingTop: 0 }}>
                <Typography variant="body1" color="text.secondary" fontStyle="italic">
                    No current members
                </Typography>
            </Box>
        );
    }

    return (
        <List
            sx={{
                '& .legal-entity-membership-list-item': {
                    '& .MuiListItemSecondaryAction-root': {
                        right: (theme) => theme.spacing(3),
                    },
                },
                '& .legal-entity-membership-list-item-button': {
                    paddingLeft: 3,
                    paddingRight: 3,
                },
            }}
            disablePadding
        >
            {_.map(members, (member) => {
                return (
                    <MembershipListItem key={member.id} member={member} handleEditMembership={handleEditMembership} />
                );
            })}
        </List>
    );
};

MembershipList.propTypes = {
    members: PropTypes.array.isRequired,
    handleEditMembership: PropTypes.func.isRequired,
};

export default MembershipList;
