import { Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// import LoadingButton from '../../common/styled/LoadingButton';

/*
    TODO chips for following:
    'cleared' / 'expired'
    'payoff'

    Action button for following:
    'restart'
    'order payoff'
*/

function LienHeaderTitle({ lien }) {
    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h1">{lien.name}</Typography>
        </Stack>
    );
}

function LienHeader({ lien }) {
    return (
        <Stack direction="row" alignItems="center" spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <LienHeaderTitle lien={lien} />
            </Stack>

            <Box sx={{ flexGrow: 1 }} />

            {/* <UserHeaderAction lien={lien} /> */}
        </Stack>
    );
}

LienHeader.propTypes = {
    lien: PropTypes.object.isRequired,
};

export default LienHeader;
