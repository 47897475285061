import { Breadcrumbs, Link as MUILink, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

function UserBreadcrumbs({ user }) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <MUILink component={Link} underline="hover" color="inherit" to="/contacts">
                Contacts
            </MUILink>
            <MUILink component={Link} underline="hover" color="inherit" to="/contacts?contactType=user">
                Users
            </MUILink>
            <Typography color="text.primary">{user.name}</Typography>
        </Breadcrumbs>
    );
}

UserBreadcrumbs.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserBreadcrumbs;
