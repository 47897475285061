import AddIcon from '@mui/icons-material/Add';
import { Box, Chip, Fab, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useGetLienStatusesQuery } from 'api/lien';
import { useGetOrderQuery } from 'api/order';
import ParcelBreadcrumbs from 'components/Order/Parcel/components/ParcelBreadcrumbs';
import { PARCEL_STATUS, PARCEL_TYPE } from 'helpers/constants';
import { findOrderParcelByType, formatISODate } from 'helpers/utils';
import CreateLienModal from '../../Liens/CreateLienModal';
import OrderTitleContent from './components/OrderTitleContent';

const TitleParcelStatusColorMap = {
    [PARCEL_STATUS.complete]: 'primary',
    [PARCEL_STATUS.issues_found]: 'warning',
    [PARCEL_STATUS.not_started]: 'default',
};

function OrderTitle({ orderId }) {
    const { data: order, isLoading: loading } = useGetOrderQuery(orderId);
    const {
        data: lienStatusMap,
        isError: lienStatusMapError,
        isLoading: lienStatusMapLoading,
    } = useGetLienStatusesQuery();

    const [titleParcel, setTitleParcel] = useState();
    const [titleIssues, setTitleIssues] = useState([]);
    const [clearedCount, setClearedCount] = useState(0);
    const [openLienModel, setOpenLienModal] = useState(false);

    useEffect(() => {
        if (order) {
            const parcel = findOrderParcelByType(order, PARCEL_TYPE.title_clearing);

            setTitleParcel(parcel);
        }
    }, [order]);

    useEffect(() => {
        if (!loading && order) {
            // Grab all liens and group with any relevant payoffs
            const lienMap = {};
            const terminalLienStatuses = _.get(lienStatusMap, '_terminal');
            let newClearedCount = 0;

            order.liens.forEach((lien) => {
                lienMap[lien.id] = {
                    ...lien,
                };

                if (terminalLienStatuses && _.includes(terminalLienStatuses, lien.status)) {
                    lienMap[lien.id].cleared = true;
                    newClearedCount++;
                } else {
                    lienMap[lien.id].cleared = false;
                }
            });

            order.payoffs.forEach((payoff) => {
                // All payoffs are associated with an original lien
                lienMap[payoff.lien_id]['payoff'] = payoff;
            });

            setTitleIssues(_.values(lienMap));
            setClearedCount(newClearedCount);
        }
    }, [order, titleParcel, loading, lienStatusMap]);

    if (!order && loading) {
        return <div>Loading...</div>;
    }

    if (!titleParcel) {
        return <div>No title parcel found</div>;
    }

    return (
        <Stack spacing={3}>
            <ParcelBreadcrumbs parcel={titleParcel} />

            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h1">Title</Typography>

                <Stack direction="row" alignItems="center" spacing={2}>
                    <Fab color="primary" aria-label="add" onClick={() => setOpenLienModal(true)} size="small">
                        <AddIcon />
                    </Fab>
                </Stack>
            </Stack>

            <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                    {/* <Chip
                        size="small"
                        variant="outlined"
                        sx={{ borderRadius: (theme) => theme.spacing(0.5) }}
                        label={`Due: ${formatISODate(titleParcel.required_complete_date)}`}
                        color="default"
                    /> */}
                    {titleParcel.required_complete_date && (
                        <Typography variant="body2" fontWeight="500">
                            Due: {formatISODate(titleParcel.required_complete_date)}
                        </Typography>
                    )}

                    <Chip
                        sx={{ borderRadius: (theme) => theme.spacing(0.5) }}
                        label={_.startCase(titleParcel.current_status.status)}
                        color={_.get(TitleParcelStatusColorMap, titleParcel.current_status.status, 'default')}
                    />
                </Stack>

                <OrderTitleContent order={order} titleIssues={titleIssues} loading={loading} />

                <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="body2" fontWeight="500">
                        {clearedCount} of {titleIssues.length} cleared
                    </Typography>
                </Box>
            </Stack>

            <CreateLienModal order={order} open={openLienModel} handleClose={() => setOpenLienModal(false)} />
        </Stack>
    );
}

OrderTitle.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderTitle;
