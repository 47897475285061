import { ListItemText, MenuItem, Stack, TextField } from '@mui/material';
import { CONTACT_TIER } from 'helpers/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';

export const FIELD_NAME = 'tier';

const CONTACT_TIER_HELPER_TEXT = {
    [CONTACT_TIER.all]: 'Receive all notifications (default)',
    [CONTACT_TIER.mandatory]: 'Receive legally-required notifications only',
    [CONTACT_TIER.executive]: 'Receive important order milestone notifications only',
    [CONTACT_TIER.none]: 'Never receive any notifications',
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 240,
        },
    },
};

const ContactTierField = ({ value, setValue, required = false, disabled = false }) => {
    const handleChange = ({ target }) => {
        setValue(target.value);
    };

    return (
        <Stack direction="row" alignItems="center">
            <TextField
                name={FIELD_NAME}
                label="Notification Tier"
                onChange={handleChange}
                value={value}
                size="small"
                select
                SelectProps={{
                    MenuProps,
                    renderValue: (selected) => _.startCase(selected),
                }}
                sx={{ flexGrow: 1, minWidth: '180px' }}
                required={required}
                disabled={disabled}
            >
                {_.map(CONTACT_TIER, (tierOption) => (
                    <MenuItem key={tierOption} value={tierOption}>
                        <ListItemText
                            primary={_.startCase(tierOption)}
                            secondary={CONTACT_TIER_HELPER_TEXT[tierOption]}
                        />
                    </MenuItem>
                ))}
            </TextField>
        </Stack>
    );
};

ContactTierField.propTypes = {
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default ContactTierField;
