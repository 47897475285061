import { Checkbox, Chip, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { useUpdateActionMutation } from 'api/action';
import { DataGrid } from 'components/common/styled';
import { ACTION_STATUS, PARCEL_STATUS, PARCEL_TYPE } from 'helpers/constants';
import BalancingParcelCustomDisplay from './BalancingParcelCustomDisplay';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
    },
    {
        field: 'friendly_name',
        headerName: 'Action',
        width: 300,
        sortable: false,
    },
    {
        field: 'description',
        cellClassName: 'balancing-item-description',
        headerName: 'Notes',
        flex: 1,
        sortable: false,
    },
    {
        field: 'status',
        headerName: '',
        align: 'left',
        headerAlign: 'left',
        width: 50,
        sortable: false,
        resizable: false,
    },
];

function ParcelCheckbox({ params, parcel, saveParcel, localLoading }) {
    // By checking - mark the child parcel as complete which will complete all actions
    // By unchecking - 'restart' the child parcel which should restart all actions

    const [checked, setChecked] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setChecked(parcel.current_status.status === PARCEL_STATUS.complete);
    }, [parcel]);

    const handleChange = (event) => {
        handleClose();

        const isChecked = event.target.checked;
        const isComplete = parcel.current_status.status === PARCEL_STATUS.complete;
        setChecked(isChecked);

        if (isChecked !== isComplete) {
            const updatedStatus = isChecked ? PARCEL_STATUS.complete : PARCEL_STATUS.in_progress;
            saveParcel(parcel.id, updatedStatus);
        }
    };

    return (
        <Tooltip
            title={checked ? 'Restart All Actions' : 'Complete All Actions'}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            placement="left"
            enterDelay={300}
        >
            <span style={{ display: 'inline-block' }}>
                <Checkbox checked={checked} onChange={handleChange} disabled={localLoading} size="small" />
            </span>
        </Tooltip>
    );
}

function ActionCheckbox({ params, saveAction }) {
    const { row } = params; // row is the action object

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(row.status === ACTION_STATUS.complete);
    }, [row.status]);

    const handleChange = (event) => {
        const isChecked = event.target.checked;
        const isComplete = row.status === ACTION_STATUS.complete;
        setChecked(isChecked);

        if (isChecked !== isComplete) {
            const updatedStatus = isChecked ? ACTION_STATUS.complete : ACTION_STATUS.not_started;
            saveAction(row.id, updatedStatus);
        }
    };

    return <Checkbox checked={checked} onChange={handleChange} disabled={row.localLoading} size="small" />;
}

function BalancingParcelListItem({ order, parentParcel, parcel, handleParcelUpdate, loading }) {
    const [updateAction] = useUpdateActionMutation();

    const [trimmedParcelName, setTrimmedParcelName] = useState();
    const [modifiedActions, setModifiedActions] = useState([]);
    const [localLoading, setLocalLoading] = useState(false);

    useEffect(() => {
        if (parcel) {
            setTrimmedParcelName(_.trim(parcel.name.split('Balancing')[1]));
        }
    }, [parcel]);

    useEffect(() => {
        // Append local loading reference to action object
        setModifiedActions(
            parcel.actions.map((action) => {
                const modifiedAction = {
                    ...action,
                    localLoading,
                };

                // TODO hacky - rethink and consolidate
                if (parcel.type === PARCEL_TYPE.balancing_liens) {
                    const lien_id = action.additional_data.lien_id;
                    const associated_lien = _.find(order.liens, (l) => {
                        return l.id === lien_id;
                    });

                    modifiedAction['friendly_name'] = `Verify ${associated_lien.name}`;
                    modifiedAction['description'] = 'TODO pull from lien type map';
                }

                return modifiedAction;
            })
        );
    }, [parcel, localLoading]);

    const saveParcel = (parcelId, status) => {
        setLocalLoading(true);

        const updateData = {
            status: status,
        };

        handleParcelUpdate(parcelId, updateData).finally(() => {
            setLocalLoading(false);
        });
    };

    const handleActionUpdate = async (actionId, updatedActionData) => {
        const updateActionPayload = {
            orderId: order.id,
            parcelId: parcel.id,
            actionId: actionId,
            actionData: updatedActionData,
        };

        const { data } = await updateAction(updateActionPayload);
        return !!data;
    };

    const saveAction = (actionId, status) => {
        setLocalLoading(true);

        const updateData = {
            status: status,
        };

        handleActionUpdate(actionId, updateData).finally(() => {
            setLocalLoading(false);
        });
    };

    const hydratedColumns = useMemo(
        () =>
            _.map(columns, (column) => {
                if (column.field === 'status') {
                    // Inject custom references
                    return {
                        ...column,
                        renderHeader: (params) => (
                            <ParcelCheckbox
                                params={params}
                                parcel={parcel}
                                saveParcel={saveParcel}
                                localLoading={localLoading}
                            />
                        ),
                        renderCell: (params) => <ActionCheckbox params={params} saveAction={saveAction} />,
                    };
                }

                return column;
            }),
        [columns, parcel, saveParcel, saveAction]
    );

    const isParcelComplete = parcel.current_status.status === PARCEL_STATUS.complete;

    return (
        <Stack spacing={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="sectionHeader">{trimmedParcelName}</Typography>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <BalancingParcelCustomDisplay order={order} parcel={parcel} />

                    <Chip
                        label={_.startCase(parcel.current_status.status)}
                        color="default"
                        size="small"
                        sx={{
                            borderRadius: (theme) => theme.spacing(0.5),
                            ...(isParcelComplete
                                ? {
                                      backgroundColor: (theme) => theme.palette.primary.light,
                                      color: (theme) => theme.palette.primary.dark,
                                  }
                                : {}),
                        }}
                    />
                </Stack>
            </Stack>

            <DataGrid
                rows={modifiedActions}
                columns={hydratedColumns}
                // loading={loading}
                // loading={localLoading}
                // density="compact"
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnReorder
                autoHeight
                hideFooter
                sx={{
                    '& .balancing-item-description': {
                        color: (theme) => theme.palette.text.secondary,
                    },
                }}
            />
        </Stack>
    );
}

BalancingParcelListItem.propTypes = {
    order: PropTypes.object.isRequired,
    parentParcel: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
    handleParcelUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default BalancingParcelListItem;
