import PropTypes from 'prop-types';

import BaseDialog from 'components/common/BaseDialog';

import CreateAssignmentMember from './CreateAssignmentMember';

const CreateAssignmentMemberModal = ({ assignment, assignmentMembers, orderMembers, open, handleClose }) => {
    const handleSubmit = () => {
        handleCloseLocal(false);
    };

    const handleCloseLocal = (open) => {
        handleClose(open);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleCloseLocal(false)}
            DialogProps={{
                maxWidth: 'md',
                fullWidth: false,
            }}
        >
            <CreateAssignmentMember
                assignment={assignment}
                assignmentMembers={assignmentMembers}
                orderMembers={orderMembers}
                handleSubmit={handleSubmit}
            />
        </BaseDialog>
    );
};

CreateAssignmentMemberModal.propTypes = {
    assignment: PropTypes.object.isRequired,
    assignmentMembers: PropTypes.array.isRequired,
    orderMembers: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CreateAssignmentMemberModal;
