import AddIcon from '@mui/icons-material/Add';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

// import AddRelationshipModal from './AddRelationshipModal';
// import EditRelationshipModal from './EditRelationshipModal';

// TODO implement organization -> organization relationships

function LegalEntityDetailsRelationshipList({ legalEntity }) {
    const [openAddRelationshipModal, setOpenAddRelationshipModal] = useState(false);
    const [editRelationship, setEditRelationship] = useState(null);

    const handleAddRelationship = () => {
        setOpenAddRelationshipModal(true);
    };

    const handleEditRelationship = (relationship) => {
        setEditRelationship(relationship);
    };

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Relationships</Typography>

                <IconButton onClick={() => handleAddRelationship()} size="small" disabled>
                    <AddIcon />
                </IconButton>
            </Stack>

            {/* <RelationshipList
                relationships={user.relationships}
                handleEditRelationship={(relationship) => setEditRelationship(relationship)}
            /> */}

            <Typography variant="body1" color="text.secondary" fontStyle="italic">
                Not currently implemented
            </Typography>
        </Paper>
    );
}

LegalEntityDetailsRelationshipList.propTypes = {
    legalEntity: PropTypes.object.isRequired,
};

export default LegalEntityDetailsRelationshipList;
