import { Box, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useCreateLegalEntityMutation } from '../../../api/legalEntity';
import { formatAddressPayload } from '../../common/form/address/AddressForm';
import { formatContactPayload } from '../../common/form/contact/ContactForm';
import { LoadingButton } from '../../common/styled';
import LegalEntityForm, { INITIAL_LEGAL_ENTITY_STATE } from './LegalEntityForm';

const NewLegalEntityForm = ({ handleSubmit }) => {
    const [createLegalEntity, { isLoading: isLegalEntityLoading }] = useCreateLegalEntityMutation();
    const [legalEntityData, setLegalEntityData] = useState({ ...INITIAL_LEGAL_ENTITY_STATE });

    const handleCreateLegalEntity = (event) => {
        event.preventDefault();

        const submitted = submitLegalEntity();
        if (submitted) {
            handleSubmit();
        }
    };

    const formatLegalEntityPayload = () => {
        // Form legal entity data payload that strips out any empty/null values
        const legalEntityPayload = {};
        _.forEach(legalEntityData, (value, key) => {
            // contact/address are nested objects that need to be handled explicitly
            if (key === 'contact') {
                legalEntityPayload[key] = formatContactPayload(value);
            } else if (key === 'address') {
                legalEntityPayload[key] = formatAddressPayload(value);
            } else if (!_.isNil(value) && value !== '') {
                legalEntityPayload[key] = value;
            }
        });
        return legalEntityPayload;
    };

    const submitLegalEntity = async () => {
        const { data } = await createLegalEntity(formatLegalEntityPayload());
        return !!data;
    };

    return (
        <form onSubmit={handleCreateLegalEntity}>
            <Stack spacing={4}>
                <LegalEntityForm legalEntityData={legalEntityData} setLegalEntityData={setLegalEntityData} />

                <Box>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%' }}
                    >
                        <div />
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={isLegalEntityLoading}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                        >
                            Create
                        </LoadingButton>
                    </Stack>
                </Box>
            </Stack>
        </form>
    );
};

NewLegalEntityForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export default NewLegalEntityForm;
