import BaseDialog from 'components/common/BaseDialog';
import PropTypes from 'prop-types';
import CreateOrder from './CreateOrder';

const CreateOrderDialog = ({ open, handleClose }) => {
    const handleCloseLocal = (open) => {
        handleClose(open);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleCloseLocal(false)}
            DialogProps={{
                maxWidth: 'md',
                fullWidth: false,
            }}
        >
            <CreateOrder onClose={() => handleCloseLocal(false)} />
        </BaseDialog>
    );
};

CreateOrderDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CreateOrderDialog;
