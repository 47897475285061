import PropTypes from 'prop-types';
import React from 'react';

import BaseDialog from '../../../common/BaseDialog';
import CreateLienMember from './CreateLienMember';

const CreateLienMemberModal = ({ lien, open, handleClose }) => {
    return (
        <BaseDialog
            open={open}
            setOpen={() => handleClose(false)}
            DialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
            }}
        >
            <CreateLienMember
                lien={lien}
                handleCancel={() => handleClose(false)}
                handleComplete={() => handleClose(false)}
            />
        </BaseDialog>
    );
};

CreateLienMemberModal.propTypes = {
    lien: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CreateLienMemberModal;
