import { Stack, Typography } from '@mui/material';
import { isAfter, parseISO } from 'date-fns';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useGetOrdersQuery } from '../../api/order';
import { ORDER_FILTER_TYPE, ORDER_STATUS, PAYOFF_STATUS } from '../../helpers/constants';
import { filterOrders } from '../../helpers/utils';
import PayoffListGrid from './components/PayoffListGrid';

const DEFAULT_FILTER_STATE = {
    [ORDER_FILTER_TYPE.orderStatus]: ORDER_STATUS.open,
};

function PayoffList() {
    const { data: orders, isError: orderError, isLoading: orderLoading } = useGetOrdersQuery();

    const [filters, setFilters] = useState({ ...DEFAULT_FILTER_STATE });
    const [payoffList, setPayoffList] = useState([]);

    const filteredOrders = useMemo(() => filterOrders(orders, filters), [orders, filters]);

    const resetFilters = () => {
        setFilters({
            ...DEFAULT_FILTER_STATE,
        });
    };

    useEffect(() => {
        const newPayoffList = [];

        _.forEach(filteredOrders, (order) => {
            _.forEach(order.payoffs, (payoff) => {
                // NOTE: currently if payoff is complete & valid, we don't show it in the list
                // Eventually this will be set by default payoff filters similar to action list
                const isPayoffCompleteAndStillValid =
                    payoff.status === PAYOFF_STATUS.complete &&
                    isAfter(parseISO(payoff.good_through_date), parseISO(order.close_date));
                if (isPayoffCompleteAndStillValid) {
                    return;
                }

                newPayoffList.push({
                    ...payoff,
                    order,
                });
            });
        });

        setPayoffList(newPayoffList);
    }, [filteredOrders]);

    return (
        <Stack spacing={3} sx={{ height: '100%', padding: (theme) => theme.spacing(3) }}>
            <Typography variant="h1">Payoffs</Typography>
            {/* <PayoffListFilters /> */}
            <PayoffListGrid payoffList={payoffList} loading={orderLoading} />
        </Stack>
    );
}

export default PayoffList;
