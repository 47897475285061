import UserType from './type.js';
import UserService from './service';

const getUser = () => (dispatch) => {
    dispatch({
        type: UserType.GET_USER_REQUEST,
    });

    return UserService.getUser().then((response) => {
        if (response.success) {
            return dispatch({
                type: UserType.GET_USER_SUCCESS,
                userDetails: response.data,
            });
        } else {
            return dispatch({
                type: UserType.GET_USER_FAILURE,
            });
        }
    });
};

const createSession = ({ code }) => (dispatch) => {
    dispatch({
        type: UserType.CREATE_USER_SESSION_REQUEST,
        data: code,
    });

    return UserService.createSession(code).then((response) => {
        if (response.success) {
            return dispatch({
                type: UserType.CREATE_USER_SESSION_SUCCESS,
                userDetails: response.data,
            });
        } else {
            return dispatch({
                type: UserType.CREATE_USER_SESSION_FAILURE,
                error: response,
            });
        }
    });
};

const logoutSession = () => (dispatch) => {
    dispatch({
        type: UserType.LOGOUT_USER_SESSION_REQUEST,
    });

    return UserService.logoutSession().then((response) => {
        if (response.success) {
            return dispatch({
                type: UserType.LOGOUT_USER_SESSION_SUCCESS,
            });
        } else {
            return dispatch({
                type: UserType.LOGOUT_USER_SESSION_FAILURE,
                error: response,
            });
        }
    });
};

const getUsersList = () => (dispatch) => {
    dispatch({
        type: UserType.GET_USERS_LIST_REQUEST,
    });

    return UserService.getUsersList().then((response) => {
        if (response.success) {
            return dispatch({
                type: UserType.GET_USERS_LIST_SUCCESS,
                users: response.data,
            });
        } else {
            return dispatch({
                type: UserType.GET_USERS_LIST_FAILURE,
            });
        }
    });
};

const userAction = {
    getUser,
    createSession,
    logoutSession,
    getUsersList,
};

export default userAction;
