import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useCreateDocumentMutation } from 'api/document';
import { useGetOrderMembersQuery } from 'api/order';
import { DOCUMENT_TYPE, DOCUMENT_TYPE_DEFAULT_ROLE_ACCESS } from 'helpers/constants';
import DocumentUploadArea from './DocumentUploadArea';

const DocumentUpload = ({ orderId }) => {
    const [
        createDocument,
        { loading: createDocumentLoading, error: createDocumentError },
    ] = useCreateDocumentMutation();

    const { data: orderMemberList, isError: membersError, isLoading: membersLoading } = useGetOrderMembersQuery(
        orderId
    );

    const [documentType, setDocumentType] = useState(DOCUMENT_TYPE.other); // TODO move state to be managed at lower-level
    const [documentDataList, setDocumentDataList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatusMap, setSubmitStatusMap] = useState({});

    const handleClear = (documentId) => {
        if (documentId) {
            // Remove item with specific id from documentDataList
            setDocumentDataList(documentDataList.filter((documentData) => documentData.id !== documentId));
        } else {
            // No documentId provided, clear all
            setDocumentDataList([]);
            setSubmitStatusMap({});
            setDocumentType(DOCUMENT_TYPE.other); // reset document type
        }
    };

    const handleDocumentSendAccessEmailChange = (sendAccessEmail, documentId = null) => {
        if (documentId) {
            const documentData = documentDataList.find((documentData) => documentData.id === documentId);
            documentData.sendAccessEmail = sendAccessEmail;
            setDocumentDataList([...documentDataList]);
        } else {
            // No documentId provided, update sendAccessEmail for all documents
            setDocumentDataList(
                _.map(documentDataList, (documentData) => ({
                    ...documentData,
                    sendAccessEmail,
                }))
            );
        }
    };

    const handleDocumentNameChange = (documentName, documentId) => {
        // Update document name for specific document
        const documentData = documentDataList.find((documentData) => documentData.id === documentId);
        documentData.name = documentName;
        setDocumentDataList([...documentDataList]);
    };

    const handleDocumentTypeChange = (documentType, documentId = null) => {
        // NOTE: changing documentType will wipe/overwrite any existing access settings
        const defaultDocumentTypeRoleAccess = _.get(DOCUMENT_TYPE_DEFAULT_ROLE_ACCESS, documentType, []);

        // If defaultDocumentTypeRoleAccess is not empty, get list of all matching member ids
        const accessMemberIds = [];
        _.forEach(orderMemberList, (orderMember) => {
            if (_.includes(defaultDocumentTypeRoleAccess, orderMember.role)) {
                accessMemberIds.push(orderMember.id);
            }
        });

        // TODO - handle defaults for send access email based on document type

        if (documentId) {
            // Update documentType for specific document
            const documentData = documentDataList.find((documentData) => documentData.id === documentId);
            documentData.documentType = documentType;
            documentData.access = [...accessMemberIds];
            // TODO - set send access email based on document type
            setDocumentDataList([...documentDataList]);
        } else {
            // No documentId provided, update documentType for all documents
            setDocumentType(documentType);
            setDocumentDataList(
                _.map(documentDataList, (documentData) => ({
                    ...documentData,
                    documentType,
                    access: [...accessMemberIds],
                }))
            );
        }
    };

    const handleDocumentAccessChange = (documentAccessList, documentId = null) => {
        _.forEach(documentDataList, (documentData) => {
            // No documentId provided, update access for all documents
            // documentId provided, update only matched document
            if (!documentId || documentData.id === documentId) {
                documentData.access = [...documentAccessList];
            }
        });

        setDocumentDataList([...documentDataList]);
    };

    const setDocumentSubmitStatus = (documentId, submitStatus) => {
        setSubmitStatusMap((prevValue) => ({
            ...prevValue,
            [documentId]: submitStatus, // one of 'submitting', 'success', 'error'
        }));
    };

    const handleSubmit = (event) => {
        setIsSubmitting(true);

        submitAllDocuments(documentDataList).finally(() => {
            setIsSubmitting(false);
        });
    };

    const submitAllDocuments = async (documents) => {
        // Syncronously submit each document in the list as a chain of promises
        // Can potentially be done in parallel using Promise.all() but impacts ux/design
        const response = await documents.reduce(async (promiseChain, currentDocument, index) => {
            await promiseChain;

            setDocumentSubmitStatus(currentDocument.id, 'submitting');
            return submitDocument(currentDocument).then((response) => {
                if (response) {
                    setDocumentSubmitStatus(currentDocument.id, 'success');
                } else {
                    setDocumentSubmitStatus(currentDocument.id, 'error');
                }

                return response;
            });
        }, Promise.resolve());
        return response;
    };

    const submitDocument = async (documentData) => {
        // Generate document form data
        const formData = new FormData();
        formData.append('file', documentData.document);
        formData.append('type', documentData.documentType);
        formData.append('order_id', orderId);
        formData.append('access', JSON.stringify(documentData.access)); // Note: array must be json serialized for FormData api request
        formData.append('send_access_email', documentData.sendAccessEmail);

        // Conditionally include document name
        const documentName = _.get(documentData, 'name', null);
        if (documentName && documentName.length > 0) {
            formData.append('name', documentName);
        }

        const { data } = await createDocument({
            orderId,
            documentData: formData,
        });

        return !!data;
    };

    return (
        <DocumentUploadArea
            documentDataList={documentDataList}
            setDocumentDataList={setDocumentDataList}
            orderMemberList={orderMemberList || []}
            documentType={documentType}
            setDocumentType={handleDocumentTypeChange}
            setDocumentAccess={handleDocumentAccessChange}
            setDocumentName={handleDocumentNameChange}
            setDocumentSendAccessEmail={handleDocumentSendAccessEmailChange}
            handleClear={handleClear}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submitStatusMap={submitStatusMap}
        />
    );
};

DocumentUpload.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default DocumentUpload;
