import {
    FormControl, FormLabel, MenuItem, Stack, TextField
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useGetOrderByQualiaNumberQuery } from '../../../../api/order';
import { QUALIA_ORDER_BASE } from '../../../../helpers/constants';


function QualiaOrderNumberField({qualiaOrderNumber, setQualiaOrderNumber, isDuplicate, setIsDuplicate}) {

    const [qualiaOrderBase, setQualiaOrderBase] = useState(QUALIA_ORDER_BASE.AUS);
    const [qualiaOrderYear, setQualiaOrderYear] = useState(new Date().getFullYear());
    const [qualiaOrderId, setQualiaOrderId] = useState('');
    // const [ isDuplicate, setIsDuplicate ] = useState(false);

    const { data: orderData, isError: orderError, isLoading: orderLoading } = useGetOrderByQualiaNumberQuery(qualiaOrderNumber);

    useEffect(() => {
        // If qualia order number is provided on initial render
        // We must split it apart and set the appropriate local state
        if (qualiaOrderNumber) {
            const orderNumberSections = qualiaOrderNumber.split('-');
            if (orderNumberSections.length === 3) {
                setQualiaOrderBase(orderNumberSections[0]);
                setQualiaOrderYear(orderNumberSections[1]);
                setQualiaOrderId(orderNumberSections[2]);
            }
        }
    }, []);

    useEffect(() => {
        // Only update order number if order id is expected 4 digits
        if (qualiaOrderId.length === 4) {
            setQualiaOrderNumber(`${qualiaOrderBase}-${qualiaOrderYear}-${qualiaOrderId}`);
        }
    }, [qualiaOrderBase, qualiaOrderYear, qualiaOrderId]);

    useEffect(() => {
        // Look up qualia order number and call out duplicates
        if (orderData) {
            setIsDuplicate(true);
        } else {
            setIsDuplicate(false);
        }
    }, [orderData])

    return (
        <FormControl component="fieldset">
            <Stack spacing={1}>
                <FormLabel id="order-number-group-label">Order Number</FormLabel>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <TextField
                        select
                        label=""
                        value={qualiaOrderBase}
                        onChange={({ target }) => setQualiaOrderBase(target.value)}
                        variant='outlined'
                        size="small"
                        required
                    >
                        {_.map(QUALIA_ORDER_BASE, (qualiaOrderBaseValue) => {
                            return (
                                <MenuItem key={qualiaOrderBaseValue} value={qualiaOrderBaseValue}>
                                    {qualiaOrderBaseValue}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                    <span> — </span>
                    <TextField
                        select
                        label=""
                        value={qualiaOrderYear}
                        onChange={({ target }) => setQualiaOrderYear(target.value)}
                        variant='outlined'
                        size="small"
                        required
                    >
                        {_.times(2, (index) => {
                            const year = new Date().getFullYear() - index;
                            return (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            )
                        })}
                        
                    </TextField>
                    <span> — </span>
                    <TextField
                        label=""
                        inputProps={{pattern: "[0-9]{4}", maxLength: 4}}
                        value={qualiaOrderId}
                        onChange={({ target }) => setQualiaOrderId(target.value)}
                        variant='outlined'
                        size="small"
                        error={isDuplicate}
                        // helperText={isDuplicate ? 'Duplicate order number' : ''}
                        autoFocus
                        required
                    />
                </Stack>
            </Stack>
        </FormControl>
    );
}

QualiaOrderNumberField.propTypes = {
    qualiaOrderNumber: PropTypes.string.isRequired,
    setQualiaOrderNumber: PropTypes.func.isRequired,
    isDuplicate: PropTypes.bool.isRequired,
    setIsDuplicate: PropTypes.func.isRequired,
};

export default QualiaOrderNumberField;
