import AddLinkIcon from '@mui/icons-material/AddLink';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
    Avatar,
    AvatarGroup,
    Box,
    Chip,
    CircularProgress,
    IconButton,
    Link,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Config from '../../../config';

import { useUpdateDocumentMutation } from 'api/document';
import { useGetOrderMembersQuery, useGetOrderQuery } from 'api/order';
import { generateOrderMemberIdMap, getOrderMemberName } from 'components/Order/Members/utils';
import { DataGrid } from 'components/common/styled';
import {
    findLienInOrder,
    findParcelInOrder,
    findPayoffInOrder,
    formatDocumentType,
    formatISODate,
    getPartyColor,
} from 'helpers/utils';

const DocumentAccessGroup = ({ orderId, documentId, documentAccessList, handleUpdateAccess }) => {
    const { data: orderMemberList, isError: membersError, isLoading: membersLoading } = useGetOrderMembersQuery(
        orderId
    );

    const orderMemberIdMap = useMemo(() => generateOrderMemberIdMap(orderMemberList), [orderMemberList]);

    const openAccessDialog = () => {
        handleUpdateAccess(documentId);
    };

    if (!documentAccessList || documentAccessList.length === 0) {
        return (
            <Tooltip title="Add Access" placement="bottom" enterDelay={300}>
                <IconButton onClick={() => openAccessDialog()}>
                    <PersonAddIcon />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <Tooltip title="Manage Access" placement="bottom" enterDelay={300}>
            <AvatarGroup
                max={4}
                // spacing="small"
                onClick={() => openAccessDialog()}
                sx={{
                    cursor: 'pointer',
                    '& .MuiAvatar-root': {
                        width: '36px',
                        height: '36px',
                        fontSize: '16px',
                        color: 'lightWhite',
                    },
                }}
                slotProps={{ size: 'small' }}
            >
                {_.map(documentAccessList, (orderMemberAccess) => {
                    const orderMember = orderMemberIdMap[orderMemberAccess.order_member_id];

                    if (!orderMember) {
                        return null;
                    }

                    const orderMemberName = getOrderMemberName(orderMember);
                    const orderMemberInitials = orderMemberName
                        .split(' ')
                        .map((n) => n[0])
                        .join('');

                    return (
                        <Avatar
                            key={orderMember.id}
                            sx={{
                                width: '36px',
                                height: '36px',
                                fontSize: '16px',
                                bgcolor: getPartyColor(orderMember.party),
                                color: 'lightWhite',
                            }}
                        >
                            {orderMemberInitials}
                        </Avatar>
                    );
                })}
            </AvatarGroup>
        </Tooltip>
    );
};

const DocumentAssociationGroup = ({ orderId, documentId, documentAssociationList, handleUpdateAssociation }) => {
    const navigate = useNavigate();
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const openAssociationDialog = () => {
        handleUpdateAssociation(documentId);
    };

    const handleDelete = (associatedEntity) => {
        // TODO
        console.log('Delete', associatedEntity);
    };

    const handleNavigate = (associatedEntity) => {
        // TODO only supports parcel for now
        navigate(`/order/${orderId}/parcel/${associatedEntity.id}`);
    };

    if (!order) {
        return null;
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                }}
                component="ul"
            >
                {_.map(documentAssociationList, (documentAssociation) => {
                    // Determine if association is parcel, lien, or payoff
                    // TODO combine logic into hook?
                    const associatedParcel = documentAssociation.parcel_id
                        ? findParcelInOrder(order, documentAssociation.parcel_id)
                        : null;
                    const associatedLien = documentAssociation.lien_id
                        ? findLienInOrder(order, documentAssociation.lien_id)
                        : null;
                    const associatedPayoff = documentAssociation.payoff_id
                        ? findPayoffInOrder(order, documentAssociation.payoff_id)
                        : null;

                    // Get entity name & url
                    const associatedEntity = associatedParcel || associatedLien || associatedPayoff;
                    const entityName = associatedEntity.name || _.startCase(associatedEntity.type);

                    return (
                        <Chip
                            key={documentAssociation.id}
                            size="small"
                            label={entityName}
                            deleteIcon={<CloseIcon />}
                            onClick={() => handleNavigate(associatedEntity)}
                            onDelete={() => handleDelete(associatedEntity)}
                        />
                    );
                })}
            </Box>

            {(!documentAssociationList || documentAssociationList.length === 0) && (
                <Tooltip title="Add Association" placement="bottom" enterDelay={300}>
                    <IconButton onClick={() => openAssociationDialog()}>
                        <AddLinkIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Stack>
    );
};

const DocumentNameCell = ({ params }) => {
    const [updateDocument, { isLoading: isUpdateDocumentLoading }] = useUpdateDocumentMutation();
    const [editMode, setEditMode] = useState(false);
    const [customDocumentName, setCustomDocumentName] = useState('');
    const [documentNameSegments, setDocumentNameSegments] = useState([]);

    const splitDocumentNameSegments = () => {
        // Remove extension
        const documentName = params.value;
        const extensionIndex = documentName.lastIndexOf('.'); // ex: blah.pdf

        // Split full document name in segments (qualia id, document type, custom name)
        const documentNameSegments = documentName.slice(0, extensionIndex).split(' - ');
        if (documentNameSegments.length <= 3) {
            return documentNameSegments;
        }

        // If larger than 3 segments, join all segments after document type
        // To handle ' - ' in custom names, join all segments after document type
        return [documentNameSegments[0], documentNameSegments[1], documentNameSegments.slice(2).join(' - ')];
    };

    const handleUpdateName = (event) => {
        setCustomDocumentName(event.target.value);
    };

    const handleSave = () => {
        submitUpdate({
            name: customDocumentName.length > 0 ? customDocumentName : null,
        }).then((success) => {
            if (success) {
                toggleEditMode();
            }
        });
    };

    const submitUpdate = async (updatePayload) => {
        const updateDocumentPayload = {
            documentId: params.row.id,
            documentData: updatePayload,
        };

        const { data } = await updateDocument(updateDocumentPayload);
        if (data) {
            return true;
        } else {
            console.warn(`Failed to be update document name ${params.row.id}`);
            return false;
        }
    };

    const resetSegments = () => {
        const segments = splitDocumentNameSegments();
        setDocumentNameSegments(segments);
        setCustomDocumentName(segments.length < 3 ? '' : documentNameSegments[2]);
    };

    const toggleEditMode = () => {
        // Entering/exiting edit mode - determine editable segment of full document name
        resetSegments();

        setEditMode(!editMode);
    };

    if (editMode) {
        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="body2">
                    {documentNameSegments[0]} - {documentNameSegments[1]} -
                </Typography>
                <TextField
                    value={customDocumentName}
                    onChange={handleUpdateName}
                    size="small"
                    onKeyDown={(event) => event.stopPropagation()}
                    autoFocus
                    disabled={isUpdateDocumentLoading}
                    inputProps={{
                        maxLength: 64,
                    }}
                    sx={{
                        width: '280px',
                    }}
                />
                <IconButton onClick={handleSave} size="small" disabled={isUpdateDocumentLoading}>
                    {isUpdateDocumentLoading ? (
                        <CircularProgress color="inherit" size={20} />
                    ) : (
                        <DoneIcon
                            sx={{
                                fontSize: '20px',
                            }}
                        />
                    )}
                </IconButton>
                <IconButton onClick={() => toggleEditMode()} size="small" disabled={isUpdateDocumentLoading}>
                    <CloseIcon
                        sx={{
                            fontSize: '20px',
                        }}
                    />
                </IconButton>
            </Stack>
        );
    }

    if (documentNameSegments.length === 0) {
        // First load
        resetSegments();
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{
                cursor: 'pointer',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            <Link
                href={`${Config.api_base}/document/${params.row.id}/file`}
                target="_blank"
                underline="hover"
                sx={{
                    color: (theme) => theme.palette.text.link,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {params.value}
            </Link>
            <Tooltip title="Edit Name" placement="bottom" enterDelay={300}>
                <IconButton onClick={() => toggleEditMode()} size="small" className="document-name-edit-button">
                    <EditIcon
                        sx={{
                            fontSize: '20px',
                        }}
                    />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

const generateDocumentListColumns = (
    handleUpdateAccess,
    handleUpdateAssociation,
    handleDelete,
    handleAccessNotification
) => {
    return [
        {
            field: 'type',
            headerName: 'Document Type',
            flex: 1,
            minWidth: 200,
            maxWidth: 240,
            valueFormatter: ({ value }) => formatDocumentType(value),
        },
        {
            field: 'file_name',
            headerName: 'Document Name',
            flex: 3,
            minWidth: 480,
            renderCell: (params) => <DocumentNameCell params={params} />,
        },
        {
            field: 'associations',
            headerName: 'Associations',
            minWidth: 160,
            flex: 2,
            // maxWidth: 240,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => (
                <DocumentAssociationGroup
                    orderId={params.row.order_id}
                    documentId={params.row.id}
                    documentAssociationList={params.value}
                    handleUpdateAssociation={handleUpdateAssociation}
                />
            ),
        },
        {
            field: 'access',
            headerName: 'Access',
            minWidth: 80,
            flex: 1,
            maxWidth: 160,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <DocumentAccessGroup
                    orderId={params.row.order_id}
                    documentId={params.row.id}
                    documentAccessList={params.value}
                    handleUpdateAccess={handleUpdateAccess}
                />
            ),
        },
        {
            field: 'created_datetime',
            headerName: 'Date/Time',
            type: 'date',
            minWidth: 150,
            // valueFormatter: ({ value }) => formatISODate(value, 'P p'),
            renderCell: (params) => (
                <Typography variant="body2" color="text.secondary">
                    {formatISODate(params.value, 'P p')}
                </Typography>
            ),
        },
        {
            field: 'notification',
            headerName: '',
            width: 60,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Manage Notifications" placement="bottom" enterDelay={300}>
                    <span style={{ display: 'inline-block' }}>
                        <IconButton
                            onClick={() => handleAccessNotification(params.row.id)}
                            size="small"
                            disabled={params.row.access.length === 0}
                        >
                            <ForwardToInboxIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'download',
            headerName: '',
            width: 60,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Download" placement="bottom" enterDelay={300}>
                    <IconButton href={`${Config.api_base}/document/${params.row.id}/file?attachment`} size="small">
                        <FileDownloadOutlinedIcon />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            field: 'delete',
            headerName: '',
            width: 60,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Delete" placement="bottom" enterDelay={300}>
                    <IconButton onClick={() => handleDelete(params.row.id)} size="small">
                        <DeleteForeverIcon />
                    </IconButton>
                </Tooltip>
            ),
        },
    ];
};

function DocumentListDataGrid({
    documentList,
    setSelecetedDocumentIdList,
    loading,
    refetch,
    handleDelete,
    handleUpdateAccess,
    handleAccessNotification,
}) {
    const [pageSize, setPageSize] = useState(10);

    const handleSelectionChange = (selectedDocumentIdList) => {
        setSelecetedDocumentIdList(selectedDocumentIdList);
    };

    const documentlistColumns = useMemo(
        () =>
            generateDocumentListColumns(
                handleUpdateAccess,
                () => console.log('todo'),
                handleDelete,
                handleAccessNotification
            ),
        [handleDelete, handleUpdateAccess, handleAccessNotification]
    );

    return (
        <DataGrid
            columns={documentlistColumns}
            rows={documentList}
            loading={loading}
            refetch={refetch}
            getRowId={(row) => row.id}
            autoHeight
            disableColumnMenu
            disableColumnResize
            onSelectionModelChange={handleSelectionChange}
            checkboxSelection
            disableSelectionOnClick
            density="comfortable"
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            pagination
            sx={{
                '& .MuiDataGrid-cellCheckbox:focus': {
                    outline: 'none !important',
                },

                '& .MuiDataGrid-columnHeaders': {
                    borderBottomColor: (theme) => theme.palette.border,
                    outline: 'none !important',
                },
                '& .MuiDataGrid-cell': {
                    borderBottomColor: (theme) => theme.palette.border,
                    outline: 'none !important',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none !important',
                },

                // column-specific
                '& .MuiDataGrid-row': {
                    '& .document-name-edit-button': {
                        visibility: 'hidden',
                        opacity: 0,
                        transition: 'visibility 0.1s ease-in, opacity 0.1s ease-in',
                    },

                    '&:hover .document-name-edit-button': {
                        visibility: 'visible',
                        opacity: 1,
                    },
                },
            }}
        />
    );
}

DocumentListDataGrid.propTypes = {
    documentList: PropTypes.array.isRequired,
    setSelecetedDocumentIdList: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleUpdateAccess: PropTypes.func.isRequired,
    handleAccessNotification: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    refetch: PropTypes.func,
};

export default DocumentListDataGrid;
