import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { Button } from 'components/common/styled';

const ShowAllButton = ({ showAll, setShowAll }) => {
    return (
        <Button
            onClick={() => setShowAll(!showAll)}
            color="default"
            variant="text"
            startIcon={showAll ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
        >
            <Typography
                variant="body2"
                sx={{
                    color: 'inherit',
                    fontWeight: '500',
                }}
            >
                {showAll ? 'Show Less' : `Show More`}
            </Typography>
        </Button>
    );
};

ShowAllButton.propTypes = {
    showAll: PropTypes.bool.isRequired,
    setShowAll: PropTypes.func.isRequired,
};

export default ShowAllButton;
