import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Badge, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useGetNotificationEventLogCountQuery } from '../../api/eventLog';
import { Button } from '../common/styled';
import NotificationPopover from './NotificationPopover';

// TODO allow button props to be passed in
// TODO reload notification event logs on refocus

const LOCAL_STORAGE_NOTIFICATION_KEY = 'notificationCount';

function getLocalNotificationCount() {
    const localNotificationCount = localStorage.getItem(LOCAL_STORAGE_NOTIFICATION_KEY);
    if (!localNotificationCount) {
        return 0;
    }

    // Local storage values are stored as strings so we need to convert it to an integer
    return parseInt(localNotificationCount);
}

function setLocalNotificationCount(count) {
    localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_KEY, count);
}

const NotificationIconButton = ({ open, setOpen }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const {
        data: notificationEventLogCount,
        isError: notificationEventLogCountError,
        isLoading: notificationEventLogCountLoading,
    } = useGetNotificationEventLogCountQuery();

    const localNotificationCount = getLocalNotificationCount();
    const unreadNotificationCount = notificationEventLogCount ? notificationEventLogCount - localNotificationCount : 0;

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);

        // Mark notifications as 'read' by saving the notification count in local storage
        if (notificationEventLogCount && notificationEventLogCount !== localNotificationCount) {
            setLocalNotificationCount(notificationEventLogCount);
        }
    };

    return (
        <Box>
            <Button
                variant="contained"
                sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    minWidth: '36px',
                }}
                color="primary"
                onClick={handleClick}
            >
                <Badge color="error" badgeContent={notificationEventLogCount ? unreadNotificationCount : 0} max={10}>
                    <NotificationsNoneIcon />
                </Badge>
            </Button>

            <NotificationPopover
                open={open}
                handleClose={handleClose}
                anchorEl={anchorEl}
                unreadCount={unreadNotificationCount}
            />
        </Box>
    );
};

NotificationIconButton.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default NotificationIconButton;
