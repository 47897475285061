import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const DEFAULT_DIALOG_PROPS = {
    maxWidth: 'md',
    fullWidth: false,
};

const BaseDialog = (props) => {
    const { open, setOpen, children, DialogProps = { ...DEFAULT_DIALOG_PROPS }, containerProps = {} } = props;

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            // disable closing modal on backdrop click
            return;
        }

        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} {...DialogProps}>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <Stack
                alignItems="center"
                {...containerProps}
                sx={{
                    padding: (theme) => theme.spacing(6, 8),
                    ..._.get(containerProps, 'sx', {}),
                }}
            >
                {children}
            </Stack>
        </Dialog>
    );
};

BaseDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    DialogProps: PropTypes.shape({
        maxWidth: PropTypes.string, // default: "md"
        fullWidth: PropTypes.bool, // default: false
    }),
    containerProps: PropTypes.object,
};

export default BaseDialog;
